import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Modal, Tab, Col, Nav, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import preFunction from "../component/common/CommonFunction";
import { months } from "../component/common/CommonArray";
import CustomText from "../component/common/CustomText";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import AuthContext from "../auth/context";

import ConfigGeneral from "../assests/svg/config_general.svg";
import ConfigPayment from "../assests/svg/config_payment.svg";
import ReactSelectField from "./../component/FormField/ReactSelectField";
import string from "../string";

const prefixerror = "DoctorRegisterOTP";

function NewAppointment() {
  //#region const
  const { heightConfig, weightConfig, bloodPressureConfig, gMeetConfig } =
    useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [inputs, setInputs] = useState();
  const [patientData, setPatientData] = useState([]);
  const [patientId, setPatientId] = useState();
  const [time, setTime] = useState();
  const [slot, setSlots] = useState();
  const [appdate, setAppDate] = useState();
  const [date, setDate] = useState();
  const [message, setMessage] = useState("");
  const [role, setRole] = useState();
  const [load, setLoad] = useState(false);
  const [showAppt, setShowAppt] = useState(false);
  const [showAppDate, setShowAppDate] = useState(false);
  const [open, setOpen] = useState(false);
  const [nameError, setNameError] = useState(false);
  const [doctorError, setDoctorError] = useState(false);
  const [openModel, setOpenModal] = useState(false);
  const [showSlotError, setShowSlotError] = useState(false);
  const [bookNowError, setBookNowError] = useState(false);
  const [showLeaveMessage, setShowLeaveMessage] = useState(false);
  const [appointmentId, setAppointmentId] = useState();
  const [data, setData] = useState();
  const [weight, setWeight] = useState();
  const [height, setHeight] = useState();
  const [bmi, setBMI] = useState();
  // const [bp, setBP] = useState();
  const [bpSystolic, setBpSystolic] = useState();
  const [bpDiastolic, setBpDiastolic] = useState();
  const [tempreture, setTempreture] = useState();
  const [patientSel, setPatientSel] = useState([]);
  const [appointmentType, setAppointmentType] = useState("Walk In");
  const DateCustomInput = preFunction.DateCustomInput;
  const [bmiNote, setBMINote] = useState("");
  const [selectedPatient, setSelectedPatient] = useState(0);
  const [doctorList, setDoctorList] = useState([]);
  const [doctorId, setDoctorId] = useState();
  const [oldDoctorId, setOldDoctorId] = useState();
  const today = new Date();
  const currentHr = today.getHours();
  const currentDate = moment(new Date()).format("yyyy-MM-DD");
  const years = preFunction.range(
    today.getFullYear(),
    today.getFullYear() + 1,
    1
  );
  const { whatsAppConfig } = useContext(AuthContext);
  // var gapi = window.gapi;
  //#endregion

  const handleWhatsApp = async (appRes) => {
    try {
      const doctorName = await storage.getUser();
      const companyFullName = await storage.getCompanyFullName();
      const message = `Hello, You have an appointment with Dr. ${doctorName} by ${moment(
        appRes.appointment_date
      ).format("DD-MM-yyyy")} ${
        appRes.appointment_time
      } at ${companyFullName}. Thank you.  - MYMEDS`;

      console.log("message---", message);
      const msgRes = await doctorApi.sendWhatAppMessage(
        selectedPatient.mobile,
        message,
        ""
      );
      console.log("msgRes----", msgRes);
      if (!msgRes.ok) {
        ErrorLog(
          prefixerror + "handleWhatsApp " + "sendWhatAppMessage",
          JSON.stringify(msgRes),
          "We are facing some issues.Please try again after sometime.",
          "mobile:" + selectedPatient.mobile + "message:" + message
        );
        return;
      }
    } catch (error) {
      ErrorLog(
        prefixerror + " handleWhatsApp ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSelectPatient = (e) => {
    console.log(e, "e");
    // for (let i = 0; i < e.length; i++) {
    //   e[i].full_name = e[i].full_name.split("-")[0];
    // }
    setPatientSel(e);
    setInputs(e[0].name);
    setSelectedPatient(e[0]);
    document.getElementById("patient_input").blur();
    document.getElementById("patientWalkIn_input").blur();
  };

  const handleBMI = (height, weight) => {
    if (height > 0 && weight > 0) {
      const afterHight = height / 100;
      const calculate = weight / (afterHight * afterHight);
      console.log("BMi", bmi, calculate.toFixed(1).toString() + "-" + "Normal");
      if (calculate < 18.5) {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Under Weight");
      } else if (calculate >= 18.5 && calculate <= 24.9) {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Normal");
      } else {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Over Weight");
      }
    }
  };

  const handleSearchPatient = async () => {
    setPatientSel([]);
    setInputs();
    setSelectedPatient([]);
  };

  const handleFilterDate = async (dt) => {
    try {
      closeErrors();
      if (!doctorId) {
        setDoctorError(true);
        return;
      }
      setLoad(true);
      setAppDate(dt);
      setDate(moment(dt).format("yyyy-MM-DD"));
      const doctorID = doctorId.value; //await storage.getID();
      const dateAvail = moment(dt).format("yyyy-MM-DD");

      const availableSlotRes = await doctorApi.availableSlotWithLeaveDetails(
        doctorID,
        dateAvail
      );
      console.log("availableSlotRes---------------", availableSlotRes);
      if (!availableSlotRes.ok) {
        ErrorLog(
          prefixerror + "handleFilterDate " + "availableSlotWithLeaveDetails",
          JSON.stringify(availableSlotRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID + "dateAvail:" + dateAvail
        );
        return;
      }

      setSlots([]);
      setShowLeaveMessage(false);
      setMessage("");
      console.log("slot_details----", availableSlotRes.data.message);
      if (availableSlotRes.data.message.error) {
        setShowLeaveMessage(true);
        setMessage(availableSlotRes.data.message.error);
      } else {
        setSlots(availableSlotRes.data.message);
      }

      setAppDate(dt);
      setTime();
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleFilterDate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
    setLoad(false);
  };

  const handleClick = async (time) => {
    setShowAppt(false);
    console.log(time);
    setTime(time);
  };

  const handleNewAppointment = () => {
    navigate("/newappointment");
    window.location.reload();
    return;
  };

  const handleVitalSigns = async () => {
    try {
      console.log("data-------", data);
      const res = await doctorApi.addVitalSigns(
        appointmentId,
        data.patient,
        data.patient_name,
        data.company,
        tempreture,
        bmiNote,
        height,
        weight,
        bmi,
        bpSystolic,
        bpDiastolic,
        ""
      );
      console.log("addVitalSigns----", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "handleVitalSigns " + "addVitalSigns",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "appointmentId:" +
            appointmentId +
            "patient:" +
            data.patient +
            "patient_name:" +
            data.patient_name +
            "company:" +
            data.company +
            "tempreture:" +
            tempreture +
            "bmiNote:" +
            bmiNote +
            "height:" +
            height +
            "weight:" +
            weight +
            "bmi:" +
            bmi +
            "bpSystolic:" +
            bpSystolic +
            "bpDiastolic:" +
            bpDiastolic
        );
        return;
      }

      role === "Practitioner Permission"
        ? navigate("/startconsultation", {
            state: { appointmentID: appointmentId },
          })
        : navigate("/upcomingappointment");

      return;
    } catch (error) {
      ErrorLog(
        prefixerror + " handleVitalSigns ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleBookNow = async () => {
    try {
      closeErrors();
      setNameError(false);
      if (!patientId) {
        setNameError(true);
        document.getElementById("patient").focus();
        return;
      }
      setLoad(true);
      const doctorID = await storage.getID();
      const company = await storage.getCompany();
      const serviceUnit = await storage.getServiceUnit();
      const today = new Date();
      const availableSlotRes = await doctorApi.availableSlotWithLeaveDetails(
        doctorID,
        moment(today).format("yyyy-MM-DD")
      );
      console.log("availableSlotRes---------------", availableSlotRes);
      if (!availableSlotRes.ok) {
        ErrorLog(
          prefixerror + "handleFilterDate " + "availableSlotWithLeaveDetails",
          JSON.stringify(availableSlotRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" +
            doctorID +
            "dateAvail:" +
            moment(today).format("yyyy-MM-DD")
        );
        return;
      }
      if (availableSlotRes.data.message.error) {
        setShowLeaveMessage(true);
        setMessage(availableSlotRes.data.message.error);
        setLoad(false);
        return;
      }

      const hrs = String(today.getHours()).padStart(2, "0");
      const min = "00"; // String(today.getMinutes()).padStart(2, "0");
      const sec = "00"; //String(today.getSeconds()).padStart(2, "0");

      const currentDate = moment(today).format("yyyy-MM-DD");
      const currentTime = hrs + ":" + min + ":" + sec;

      console.log("serviceUnit---", serviceUnit);

      createAppointment(
        patientId.value,
        doctorId.value,
        currentDate,
        currentTime,
        "Walk In",
        company
      );

      // createAppointment(
      //   inputs,
      //   doctorID,
      //   currentDate,
      //   currentTime,
      //   "Walk In",
      //   company,
      //   serviceUnit,
      //   ""
      // );
      console.log("currentTime---", currentDate, currentTime);
      setLoad(false);
      return;
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + " handleBookNow ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const closeErrors = () => {
    setNameError(false);
    setShowAppDate(false);
    setShowSlotError(false);
    setShowAppt(false);
    setDoctorError(false);
    setBookNowError(false);
    setShowLeaveMessage(false);
    setOpen(false);
  };

  const createAppointment = async (
    inputs,
    doctorID,
    date,
    time,
    appointmentType,
    company,
    // serviceUnit,
    gmeetlink = ""
  ) => {
    try {
      setLoad(true);
      console.log("selectedPatient---", selectedPatient);

      const paidStatus = 0;
      console.log(
        patientId,
        inputs,
        doctorID,
        date,
        time,
        appointmentType,
        company,
        // serviceUnit,
        gmeetlink,
        paidStatus,
        selectedPatient.mobile
      );

      const appRes = await doctorApi.newAppointmentWithSMS(
        patientId.value,
        doctorID,
        date,
        time,
        appointmentType,
        company,
        gmeetlink,
        selectedPatient.mobile
      );
      console.log("appRes---------", appRes);

      if (appRes.data.message.error) {
        setMessage(appRes.data.message.error);
        setOpen(true);
        setBookNowError(true);
        setLoad(false);
        // setTimeout(() => {
        // setBookNowError(false);
        // setOpen(false);
        // }, 3000);
        return;
      }

      // return;

      // const res = await doctorApi.newAppointment(
      //   patientId.value,
      //   doctorID,
      //   date,
      //   time,
      //   appointmentType,
      //   company,
      //   serviceUnit,
      //   gmeetlink,
      //   paidStatus
      // );
      // console.log("appointment res----", res);
      // if (!res.ok) {
      //   if (res.data.exc_type == "OverlapError") {
      //     setMessage(
      //       "Appointment not booked. You already booked appointment in this slot"
      //     );
      //   } else {
      //     setMessage(
      //       "Appointment not booked. Reached number of patients allowed in a slot. Please choose some other time slot"
      //     );
      //   }
      //   setOpen(true);
      //   setBookNowError(true);
      //   setLoad(false);
      //   setTimeout(() => {
      //     setBookNowError(false);
      //     setOpen(false);
      //   }, 3000);
      //   ErrorLog(
      //     prefixerror + "createAppointment " + "newAppointment",
      //     JSON.stringify(res),
      //     "We are facing some issues.Please try again after sometime.",
      //     "inputs:" +
      //       inputs +
      //       "doctorID:" +
      //       doctorID +
      //       "date:" +
      //       date +
      //       "time:" +
      //       time +
      //       "appointmentType:" +
      //       appointmentType +
      //       "company" +
      //       company +
      //       "serviceUnit:" +
      //       serviceUnit +
      //       "gmeetlink" +
      //       gmeetlink
      //   );
      //   return;
      // }

      // const company_name = await storage.getCompanyFullName();
      // const msgRes = await doctorApi.sendAAppointmentMsg(
      //   selectedPatient.mobile,
      //   company_name,
      //   res.data.data.practitioner_name,
      //   res.data.data.appointment_datetime
      // );
      // console.log("msgRes---", msgRes);
      // if (!msgRes.ok) {
      //   ErrorLog(
      //     prefixerror + " createAppointment " + "sendAAppointmentMsg",
      //     JSON.stringify(msgRes),
      //     "We are facing some issues.Please try again after sometime.",
      //     "mobile:" +
      //       selectedPatient.mobile +
      //       "company:" +
      //       company +
      //       "practitioner_name:" +
      //       res.data.data.practitioner_name +
      //       "appointment_datetime:" +
      //       res.data.data.appointment_datetime
      //   );
      //   return;
      // }

      // if (whatsAppConfig) {
      //   handleWhatsApp(res.data.data);
      // }
      setMessage("Appointment booked successfully");
      setData(appRes.data.message.data);
      setAppointmentId(appRes.data.message.data.name);
      setOpenModal(true);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " createAppointment ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleSubmit = async () => {
    closeErrors();
    setOpen(false);
    console.log(patientId, doctorId, "patientId");
    let err = false;
    if (!doctorId) {
      setDoctorError(true);
      document.getElementById("doctorId").className += " form-control-error";
      document.getElementById("doctorId").focus();
      err = true;
    }

    if (!patientId) {
      setNameError(true);
      document.getElementById("patient").className += " form-control-error";
      document.getElementById("patient").focus();
      err = true;
    }
    if (!appdate) {
      setShowAppDate(true);
      document.getElementById("dobButton").className += " form-control-error";
      err = true;
    }
    if (!time) {
      setShowAppt(true);
      err = true;
    }
    if (err) {
      return;
    }
    try {
      setShowAppt(false);
      setLoad(true);
      if (location.state && location.state.appointmentID) {
        console.log("App id", location.state.appointmentID);
        console.log("inputs", inputs);
        console.log("date", date);
        console.log("time", time);
        console.log("oldDoctorId", oldDoctorId);
        console.log("doctorId", doctorId);

        if (doctorId.value === oldDoctorId.value) {
          const res = await doctorApi.rescheduleAppointment(
            location.state.appointmentID,
            date,
            time,
            appointmentType
          );
          console.log("rescheduleAppointment--", res);
          if (!res.ok) {
            console.log("here-------  ");
            if (res.data.exc_type === "OverlapError") {
              setMessage(
                "Appointment not booked. You already booked appointment in this slot"
              );
              setOpen(true);
              setLoad(false);
              setBookNowError(true);
            }
            return;
          }
        } else {
          const cancelAppointmentRes = await doctorApi.cancelAppointment(
            location.state.appointmentID
          );
          console.log("cancelAppointmentRes------", cancelAppointmentRes);

          const company = await storage.getCompany();
          createAppointment(
            patientId.value,
            doctorId.value,
            date,
            time,
            appointmentType,
            company
          );
        }
        navigate("/upcomingappointment", { state: { date } });
        setLoad(false);
        return;
      }
      const doctorID = doctorId.value; //await storage.getID();
      const company = await storage.getCompany();
      // const serviceUnit = await storage.getServiceUnit();
      // console.log("serviceUnit---", serviceUnit);
      setLoad(false);
      console.log("gMeetConfig---", gMeetConfig);
      // if (gMeetConfig && appointmentType == "Online") {
      //   const company = await storage.getCompany();
      //   let email = await storage.getEmail();
      //   const addressRes = await doctorApi.getCompanyAddress(company);
      //   if (!addressRes.ok) {
      //     ErrorLog(
      //       prefixerror + " handleSubmit " + "getCompanyAddress",
      //       JSON.stringify(addressRes),
      //       "We are facing some issues.Please try again after sometime.",
      //       "company:" + company
      //     );
      //     return;
      //   }
      //   console.log("addressRes---", addressRes);
      //   console.log("date---", date);
      //   console.log("time---", time);
      //   const timesplit = time.split(":");
      //   const t = parseInt(timesplit[0]) + 1;
      //   const endtime =
      //     date + "T" + t + ":" + timesplit[1] + ":" + timesplit[2];
      //   const starttime = date + "T" + time;
      //   console.log("starttime--", starttime);
      //   console.log("endtime--", endtime);
      //   const company_name = await storage.getCompanyFullName();
      //   let addr = company_name;
      //   if (addressRes.data.data.length > 0) {
      //     addr +=
      //       ", " +
      //       addressRes.data.data[0].address_line1 +
      //       ", " +
      //       addressRes.data.data[0].address_line2 +
      //       ", " +
      //       addressRes.data.data[0].city +
      //       " - " +
      //       addressRes.data.data[0].pincode;
      //   }
      //   console.log("addr---", addr);

      //   const patientRes = await doctorApi.getPatientById(inputs);
      //   if (!patientRes.ok) {
      //     ErrorLog(
      //       prefixerror + " handleSubmit " + "getDetilsBasicInfo",
      //       JSON.stringify(patientRes),
      //       "We are facing some issues.Please try again after sometime.",
      //       "inputs:" + inputs
      //     );
      //     return;
      //   }
      //   let patientEmail = patientRes.data.data.email;

      //   // email = "jsumalatha85@gmail.com";
      //   // patientEmail = "sumalatha@mvit.edu.in";

      //   console.log("doctor Email--", email);
      //   console.log("patientEmail--", patientEmail);

      //   gapi.client.setApiKey(string.API_KEY);
      //   gapi.client
      //     .load(
      //       "https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest"
      //     )
      //     .then(
      //       function () {
      //         console.log("GAPI client loaded for API");
      //       },
      //       function (err) {
      //         console.error("Error loading GAPI client for API", err);
      //       }
      //     );
      //   gapi.auth2
      //     .getAuthInstance()
      //     .signIn()
      //     .then((res1) => {
      //       console.log(res1, "res1");
      //       var request = gapi.client.calendar.events.insert({
      //         calendarId: email,
      //         resource: {
      //           summary: string.websiteName + " Appointment",
      //           location: addr,
      //           description: "Booked appointment with " + company_name,
      //           start: {
      //             dateTime: starttime,
      //             timeZone: "Asia/Kolkata",
      //           },
      //           end: {
      //             dateTime: endtime,
      //             timeZone: "Asia/Kolkata",
      //           },
      //           // recurrence: ["RRULE:FREQ=DAILY;COUNT=1"],
      //           attendees: [{ email: email }, { email: patientEmail }],

      //           conferenceData: {
      //             createRequest: {
      //               requestId: "sample232",
      //               conferenceSolutionKey: {
      //                 type: "hangoutsMeet",
      //               },
      //             },
      //           },
      //           reminders: {
      //             useDefault: false,
      //             overrides: [
      //               { method: "email", minutes: 1 * 60 },
      //               { method: "popup", minutes: 1 * 60 },
      //               { method: "popup", minutes: 1 * 30 },
      //             ],
      //           },
      //         },
      //         conferenceDataVersion: 1,
      //         // sendUpdates: "all",
      //         // anyoneCanAddSelf: false,
      //       });

      //       request.execute(function (event) {
      //         console.log(event, "event");
      //         console.log(event.hangoutLink, "----------event.hangoutLink");

      //         createAppointment(
      //           inputs,
      //           doctorID,
      //           date,
      //           time,
      //           appointmentType,
      //           company,
      //           serviceUnit,
      //           event.hangoutLink
      //         );
      //       });
      //     })
      //     .catch((e) => {
      //       console.log(e);
      //       if (e.error == "popup_closed_by_user") {
      //         setMessage(
      //           "Appointment booked successfully. Google meet not created"
      //         );
      //       }
      //       createAppointment(
      //         inputs,
      //         doctorID,
      //         date,
      //         time,
      //         appointmentType,
      //         company,
      //         serviceUnit
      //       );
      //     });
      // } else {
      // if (whatsAppConfig) {
      //   handleWhatsApp(res.data.data);
      // }
      // setMessage("Appointment booked successfully");
      // setData(res.data.data);
      // setAppointmentId(res.data.data.name);
      // setOpenModal(true);

      createAppointment(
        patientId.value,
        doctorID,
        date,
        time,
        appointmentType,
        company
        // serviceUnit
      );
      // }
      setLoad(false);
      return;
    } catch (error) {
      console.log("Error", error);
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  // const initializeGapiClient = async () => {
  //   await gapi.client.init({
  //     clientId: string.CLIENT_ID,
  //     scope: string.SCOPE,
  //     plugin_name: string.websiteName + " Appointment",
  //     apikey: string.API_KEY,
  //   });
  // };

  const getPatient = async () => {
    try {
      setLoad(true);
      let doctorArr = [];
      const staffName = await storage.getStaffName();
      const role = await storage.getRole();
      console.log("staffName---------------", staffName);
      console.log("role---------------", role);
      setRole(role);
      const clinicId = await storage.getCompany();
      console.log("clinicId--------", clinicId);

      if (role != string.DOCTOR_ROLE) {
        const getDoctorsByHospital = await doctorApi.getDoctorByHospital(
          clinicId
        );
        console.log("getDoctorsByHospital------", getDoctorsByHospital);
        for (let i = 0; i < getDoctorsByHospital.data.data.length; i++) {
          doctorArr.push({
            label: getDoctorsByHospital.data.data[i].practitioner_name,
            value: getDoctorsByHospital.data.data[i].name,
          });
        }
        setDoctorList(doctorArr);
      } else {
        const name = await storage.getName();
        const doc = await storage.getID();
        setDoctorId({ label: name, value: doc });
        setOldDoctorId({ label: name, value: doc });
      }
      console.log("gMeetConfig----", gMeetConfig);

      const res = await doctorApi.patient(clinicId);
      console.log("patient res----", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getPatient " + "patient",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }

      // if (location.state && location.state.patientId) {
      //   for (let i = 0; i < res.data.data.length; i++) {
      //     if (res.data.data[i].name == location.state.patientId) {
      //       setSelectedPatient(res.data.data[i]);
      //     }
      //   }
      // }

      for (let i = 0; i < res.data.data.length; i++) {
        res.data.data[i].value = res.data.data[i].name;
        res.data.data[i].label =
          res.data.data[i].patient_name +
          " - " +
          moment().diff(res.data.data[i].dob, "years") +
          " years - " +
          res.data.data[i].mobile;

        if (
          location.state &&
          location.state.patientId == res.data.data[i].value
        ) {
          setPatientId(res.data.data[i]);
          setSelectedPatient(res.data.data[i]);
        }
      }
      setPatientData(res.data.data);
      if (res.data.data.length > 0) {
        const role = await storage.getRole();
        setRole(role);
        if (location.state && location.state.appointmentID) {
          console.log("heeeeeeeeerrrrrr");
          const appointmentres = await doctorApi.getPatientAppointment(
            location.state.appointmentID
          );
          if (!appointmentres.ok) {
            ErrorLog(
              prefixerror + " getPatient " + "getPatientAppointment",
              JSON.stringify(appointmentres),
              "We are facing some issues.Please try again after sometime.",
              "appointmentID:" + location.state.appointmentID
            );
            return;
          }
          console.log("appointmentres---", appointmentres);
          setInputs(appointmentres.data.data[0].patient);
          for (let i = 0; i < res.data.data.length; i++) {
            // if (res.data.data[i].name == appointmentres.data.data[0].patient) {
            //   setSelectedPatient(res.data.data[i]);
            //   setPatientId({
            //     label: res.data.data[i].patient_name,
            //     value: res.data.data[i].patient,
            //   });
            // }

            if (appointmentres.data.data[0].patient == res.data.data[i].value) {
              console.log("setPatientId----", res.data.data[i]);
              setPatientId(res.data.data[i]);
              setSelectedPatient(res.data.data[i]);
            }

            if (staffName) {
              for (let i = 0; i < doctorArr.length; i++) {
                if (
                  appointmentres.data.data[0].practitioner ===
                  doctorArr[i].value
                ) {
                  setDoctorId(doctorArr[i]);
                  setOldDoctorId(doctorArr[i]);
                }
              }
            }
          }
          setPatientSel([
            {
              id: appointmentres.data.data[0].patient,
              full_name: appointmentres.data.data[0].patient_name,
            },
          ]);
        }
        if (location.state && location.state.patientId) {
          const patientId = location.state ? location.state.patientId : "";
          setInputs(patientId);
        }
        const doctorID = await storage.getID();
        await storage.storeScheduleName(doctorID + " Schedule");
      }
      setDate(moment(appdate).format("yyyy-MM-DD"));
      setLoad(false);
      // document.getElementById("appointmentType").checked = true;
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " getPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  // const gapiLoad = () => {
  //   gapi.load("client:auth2", initializeGapiClient);
  // };

  useEffect(() => {
    getPatient();
    // gapiLoad();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 75,
          alignSelf: "center",
        }}
        visible={load}
      />
      <>
        <section>
          <div className="row">
            <div className="col-lg-2 p-0">
              <DoctorNavBar id="DoctorNavBar" />
            </div>

            <div className="col-lg-10">
              <DoctorHeader />
              <div className="row no-gutters" onClick={preFunction.hideNavbar}>
                <div className="pd mb-4">
                  <div className="row">
                    <h1>
                      {location.state && location.state.appointmentID
                        ? "Reschedule"
                        : "New"}{" "}
                      Appointment
                    </h1>
                  </div>
                  <div className="row no-gutters">
                    {patientData.length > 0 ? (
                      <div className="row border no-gutters mb-4">
                        <Tab.Container defaultActiveKey="GeneralKey">
                          <Row className="no-gutters">
                            <Col
                              sm={3}
                              md={4}
                              className="pt-5 pb-5"
                              style={{ backgroundColor: "#F2F6FF" }}
                            >
                              <Nav variant="tabs" className="flex-column">
                                <Nav.Item>
                                  <Nav.Link
                                    eventKey="GeneralKey"
                                    onClick={(e) => closeErrors()}
                                  >
                                    <img
                                      src={ConfigGeneral}
                                      width={26}
                                      height={26}
                                      style={{ paddingRight: "10px" }}
                                    />
                                    Book Appointment
                                  </Nav.Link>
                                </Nav.Item>
                                {location.state &&
                                location.state.appointmentID ? null : (
                                  <Nav.Item>
                                    <Nav.Link
                                      eventKey="PaymentKey"
                                      onClick={(e) => closeErrors()}
                                    >
                                      <img
                                        src={ConfigPayment}
                                        width={26}
                                        height={26}
                                        style={{ paddingRight: "10px" }}
                                      />
                                      Walk In
                                    </Nav.Link>
                                  </Nav.Item>
                                )}
                              </Nav>
                            </Col>
                            <Col
                              sm={9}
                              md={8}
                              className="p-5"
                              style={{ backgroundColor: "#fff" }}
                            >
                              <Tab.Content>
                                <Tab.Pane
                                  eventKey="GeneralKey"
                                  title="Book Appointment"
                                >
                                  <div className="row">
                                    {location.state &&
                                    location.state.appointmentID ? null : (
                                      <div className="alignRight mb-4">
                                        <button
                                          title="Add Patient"
                                          className="btn-2"
                                          onClick={(e) => {
                                            navigate("/newpatient");
                                          }}
                                        >
                                          <span className="icofont icofont-plus"></span>
                                          Add Patient
                                        </button>
                                      </div>
                                    )}
                                    <div>
                                      {role != string.DOCTOR_ROLE ? (
                                        <div className="row no-gutters form-group">
                                          <label className="control-label col-lg-3 mt-2">
                                            <span className="star">*</span>
                                            Doctor :
                                          </label>
                                          <div className="col-lg-6">
                                            <ReactSelectField
                                              inputId="doctorId"
                                              mandatory={1}
                                              value={doctorId}
                                              placeholder="Doctor"
                                              options={doctorList}
                                              style={{ width: "70%" }}
                                              onChange={(text) => {
                                                closeErrors();
                                                setDoctorId(text);
                                                setAppDate("");
                                                setDate("");
                                                setSlots();
                                              }}
                                            />
                                            <ErrorMessage
                                              error={
                                                "Please select the Doctor Name"
                                              }
                                              visible={doctorError}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                      {location.state &&
                                      location.state.appointmentID ? (
                                        <div className="row no-gutters form-group">
                                          <label className="control-label col-lg-3 mt-2">
                                            <span className="star">*</span>
                                            Patient name :
                                          </label>
                                          <div className="col-lg-6 mt-3">
                                            {patientId?.label}
                                          </div>
                                        </div>
                                      ) : (
                                        <div className="row no-gutters form-group">
                                          <label className="control-label col-lg-3 mt-2">
                                            <span className="star">*</span>
                                            Patient name :
                                          </label>
                                          <div className="col-lg-6">
                                            <ReactSelectField
                                              inputId="patientId"
                                              mandatory={1}
                                              value={patientId}
                                              placeholder="Patient"
                                              options={patientData}
                                              onChange={(text) => {
                                                closeErrors();
                                                setPatientId(text);
                                                setSelectedPatient(text);
                                              }}
                                            />
                                            <ErrorMessage
                                              error={
                                                "Please select the Patient Name"
                                              }
                                              visible={nameError}
                                            />
                                          </div>
                                        </div>
                                      )}
                                      <div className="row no-gutters form-group">
                                        <label className="control-label col-sm-3">
                                          <span className="star">*</span> Date :
                                        </label>
                                        <div className="col-sm-7">
                                          <DatePicker
                                            name="appdate"
                                            id="appdate"
                                            selected={appdate}
                                            minDate={new Date()}
                                            maxDate={
                                              new Date(
                                                today.getFullYear() +
                                                  1 +
                                                  "-12-31"
                                              )
                                            }
                                            onChange={(date) => {
                                              handleFilterDate(date);
                                            }}
                                            dateFormat="dd-MM-yyyy"
                                            customInput={<DateCustomInput />}
                                            className="form-control datepicker"
                                            renderCustomHeader={({
                                              date,
                                              changeYear,
                                              changeMonth,
                                            }) => (
                                              <div
                                                style={{
                                                  margin: 10,
                                                  display: "flex",
                                                  justifyContent: "center",
                                                }}
                                              >
                                                <select
                                                  style={{ marginRight: "3px" }}
                                                  value={date.getFullYear()}
                                                  onChange={({
                                                    target: { value },
                                                  }) => changeYear(value)}
                                                >
                                                  {years.map((option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  ))}
                                                </select>
                                                <select
                                                  value={
                                                    months[date.getMonth()]
                                                  }
                                                  onChange={({
                                                    target: { value },
                                                  }) =>
                                                    changeMonth(
                                                      months.indexOf(value)
                                                    )
                                                  }
                                                >
                                                  {months.map((option) => (
                                                    <option
                                                      key={option}
                                                      value={option}
                                                    >
                                                      {option}
                                                    </option>
                                                  ))}
                                                </select>
                                              </div>
                                            )}
                                          />
                                          <ErrorMessage
                                            error={
                                              "Please select the valid date"
                                            }
                                            visible={showAppDate}
                                          />
                                        </div>
                                      </div>
                                      {/* {gMeetConfig ? (
                                  <div className="row no-gutters form-group">
                                    <label className="control-label col-sm-3">
                                      <span className="star">*</span>{" "}
                                      Consultation Type :
                                    </label>
                                    <div className="col-lg-2">
                                      <RadioInput
                                        name="appointmentType"
                                        id="appointmentType"
                                        data={appointmentTypes}
                                        className="radio-input"
                                        onChange={(e) => {
                                          console.log(e.target.value);
                                          setAppointmentType(e.target.value);
                                        }}
                                      />
                                    </div>
                                  </div>
                                      ) : null} */}
                                      <>
                                        {slot ? (
                                          <>
                                            <div className="row no-gutters form-group">
                                              <label className="control-label col-sm-3">
                                                <span className="star">*</span>{" "}
                                                Available Slots :
                                              </label>
                                              <div className="row no-gutters col-sm-9">
                                                {showLeaveMessage ? (
                                                  <CustomText
                                                    style={{
                                                      color: "red",
                                                    }}
                                                    className="row col-sm-7 mt-2"
                                                  >
                                                    {message}
                                                  </CustomText>
                                                ) : slot.length == 0 ? (
                                                  <CustomText
                                                    style={{
                                                      color: "red",
                                                    }}
                                                    className="row col-sm-7 mt-2"
                                                  >
                                                    No slots available
                                                  </CustomText>
                                                ) : (
                                                  slot.map((item, index) => (
                                                    <div
                                                      className="col-lg-3 m-1 avltimelbl text-center pointer nowrapWhiteSpace"
                                                      style={{
                                                        backgroundColor:
                                                          time ===
                                                          item.from_time
                                                            ? "#b7ce63"
                                                            : (currentDate ==
                                                                date &&
                                                                item.to_time.split(
                                                                  ":"
                                                                )[0] <=
                                                                  currentHr) ||
                                                              !item.status
                                                            ? "#E8E8E8"
                                                            : "#D7E6E7",
                                                        color:
                                                          (currentDate ===
                                                            date &&
                                                            item.to_time.split(
                                                              ":"
                                                            )[0] <=
                                                              currentHr) ||
                                                          !item.status
                                                            ? "#fff"
                                                            : "",
                                                      }}
                                                      onClick={() =>
                                                        (currentDate === date &&
                                                          item.to_time.split(
                                                            ":"
                                                          )[0] <= currentHr) ||
                                                        !item.status
                                                          ? null
                                                          : handleClick(
                                                              item.from_time
                                                            )
                                                      }
                                                    >
                                                      {preFunction.displayTimeFormat(
                                                        item.from_time
                                                      )}

                                                      {" - "}
                                                      {preFunction.displayTimeFormat(
                                                        item.to_time
                                                      )}
                                                      <br />
                                                      {(currentDate == date &&
                                                        item.to_time.split(
                                                          ":"
                                                        )[0] <= currentHr) ||
                                                      !item.status ? (
                                                        item.count ? (
                                                          <span className="badge badge-secondary">
                                                            {item.count}
                                                          </span>
                                                        ) : (
                                                          <br />
                                                        )
                                                      ) : item.count ? (
                                                        <span className="badge badge-success">
                                                          {item.count}
                                                        </span>
                                                      ) : (
                                                        <br />
                                                      )}
                                                    </div>
                                                  ))
                                                )}

                                                <ErrorMessage
                                                  error={
                                                    "Please select the Time Slot"
                                                  }
                                                  visible={showAppt}
                                                />
                                                <ErrorMessage
                                                  error={message}
                                                  visible={open}
                                                />
                                              </div>
                                            </div>
                                            {showSlotError ? (
                                              <div className="row no-gutters form-group">
                                                <label className="control-label col-sm-3"></label>
                                                <div className="row col-sm-7">
                                                  <ErrorMessage
                                                    visible={showSlotError}
                                                    error={"No slots available"}
                                                  ></ErrorMessage>
                                                </div>
                                              </div>
                                            ) : (
                                              <div className="row no-gutters">
                                                <label className="col-sm-3"></label>
                                                <div className="col-lg-7">
                                                  <div className="row mt-2 mb-3 text-center">
                                                    <div className="col-lg-4 mb-1">
                                                      <div className="squaresel squ">
                                                        <span className="m-4 w-wrap">
                                                          Selected
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-4 mb-1">
                                                      <div className="squarefull squ">
                                                        <span className="m-4 w-wrap">
                                                          Booked
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <div className="col-sm-4 col-md-4 col-lg-2 mb-1">
                                                      <div className="squareavl squ">
                                                        <span className="m-4 w-wrap">
                                                          Available
                                                        </span>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )}
                                          </>
                                        ) : appdate ? (
                                          <div>
                                            <h5 className="text-center text-danger">
                                              No Available Slots
                                            </h5>
                                          </div>
                                        ) : null}
                                        <div className="form-group">
                                          <label className="control-label col-sm-3"></label>
                                          <button
                                            className="btn mb-3"
                                            type="submit"
                                            title={
                                              location.state &&
                                              location.state.appointmentID
                                                ? "Reschedule"
                                                : "Book Appointment"
                                            }
                                            onClick={handleSubmit}
                                          >
                                            <span className="icofont icofont-plus"></span>
                                            {location.state &&
                                            location.state.appointmentID
                                              ? "Reschedule"
                                              : "Book Appointment"}
                                          </button>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </Tab.Pane>
                                <Tab.Pane eventKey="PaymentKey" title="Walk-in">
                                  {location.state &&
                                  location.state.appointmentID ? null : (
                                    <div className="row">
                                      <div className="alignRight mb-4">
                                        <button
                                          title="Add Patient"
                                          className="btn-2"
                                          onClick={(e) => {
                                            navigate("/newpatient");
                                          }}
                                        >
                                          <span className="icofont icofont-plus"></span>
                                          Add Patient
                                        </button>
                                      </div>
                                    </div>
                                  )}
                                  {doctorList.length > 1 ? (
                                    <div className="row no-gutters form-group">
                                      <label className="control-label col-lg-3 mt-2">
                                        <span className="star">*</span>
                                        Doctor :
                                      </label>
                                      <div className="col-lg-6">
                                        <ReactSelectField
                                          inputId="doctorId"
                                          mandatory={1}
                                          value={doctorId}
                                          placeholder="Doctor"
                                          options={doctorList}
                                          style={{ width: "70%" }}
                                          onChange={(text) => {
                                            closeErrors();
                                            setDoctorId(text);
                                            setAppDate("");
                                            setDate("");
                                            setSlots();
                                          }}
                                        />
                                        <ErrorMessage
                                          error={
                                            "Please select the Doctor Name"
                                          }
                                          visible={doctorError}
                                        />
                                      </div>
                                    </div>
                                  ) : null}
                                  <div className="row p-2 no-gutters form-group">
                                    <label className="control-label col-lg-3 mt-2">
                                      <span className="star">*</span> Patient
                                      name :
                                    </label>
                                    <div className="col-lg-6">
                                      <ReactSelectField
                                        inputId="patientWalkIn"
                                        mandatory={1}
                                        value={patientId}
                                        placeholder="Patient"
                                        options={patientData}
                                        onChange={(text) => {
                                          closeErrors();
                                          setPatientId(text);
                                          setSelectedPatient(text);
                                        }}
                                      />

                                      <ErrorMessage
                                        error={message}
                                        visible={showLeaveMessage}
                                      />

                                      {/* <Multiselect
                                        name="patientWalkIn"
                                        id="patientWalkIn"
                                        options={patientData}
                                        showArrow={true}
                                        selectedValues={patientSel}
                                        onSelect={handleSelectPatient}
                                        placeholder={"Patient Name"}
                                        displayValue="full_name"
                                        customCloseIcon={" "}
                                        selectionLimit={1}
                                        hidePlaceholder={true}
                                        avoidHighlightFirstOption={true}
                                        customArrow={
                                          <span
                                            className="icofont-search"
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          ></span>
                                        }
                                        onSearch={(e) => {
                                          closeErrors();
                                          handleSearchPatient();
                                        }}
                                        emptyRecordMsg={
                                          document.getElementById(
                                            "patientWalkIn_input"
                                          ) &&
                                          document.getElementById(
                                            "patientWalkIn_input"
                                          ).value.length > 2 ? (
                                            "No Patients Available"
                                          ) : (
                                            <div className="notFoundSearch">
                                              Type to search
                                            </div>
                                          )
                                        }
                                      /> */}
                                      <ErrorMessage
                                        error={"Please select the Patient Name"}
                                        visible={nameError}
                                      />
                                    </div>
                                  </div>

                                  {bookNowError && (
                                    <div className="row no-gutters">
                                      <div className="form-group">
                                        <label className="control-label col-sm-3"></label>
                                        <ErrorMessage
                                          error={message}
                                          visible={bookNowError}
                                        />
                                      </div>
                                    </div>
                                  )}
                                  <div className="row no-gutters">
                                    <div className="form-group">
                                      <label className="control-label col-sm-3"></label>
                                      <button
                                        className="btn"
                                        type="submit"
                                        title=""
                                        onClick={handleBookNow}
                                      >
                                        <span className="icofont icofont-plus"></span>
                                        Walk in
                                      </button>
                                    </div>
                                  </div>
                                </Tab.Pane>
                              </Tab.Content>
                            </Col>
                          </Row>
                        </Tab.Container>

                        <>
                          <Modal className="modalwidth" show={openModel}>
                            <Modal.Header>
                              {!heightConfig &&
                              !weightConfig &&
                              !bloodPressureConfig ? (
                                <Modal.Title>Success</Modal.Title>
                              ) : (
                                <Modal.Title>Vitals</Modal.Title>
                              )}
                              <span
                                className="icofont-close-line pointer"
                                style={{ fontSize: "25px", fontWeight: "bold" }}
                                onClick={(e) => {
                                  navigate("/upcomingappointment");
                                }}
                              ></span>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="row" style={{ width: "100%" }}>
                                {!heightConfig &&
                                !weightConfig &&
                                !bloodPressureConfig ? (
                                  <div className="form-horizontal">
                                    <h6>Appointment Booked Successfully</h6>
                                  </div>
                                ) : (
                                  <div className="form-horizontal">
                                    {heightConfig ? (
                                      <div className="row ">
                                        <label className="control-label col-lg-5">
                                          Height (cm) :
                                        </label>
                                        <div className="form-group col-lg-2 p-0">
                                          <input
                                            className="form-control"
                                            type="number"
                                            name="Height"
                                            id="Height"
                                            value={height}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value < 0
                                                  ? ""
                                                  : e.target.value.length > 3
                                                  ? e.target.value.slice(0, 3)
                                                  : e.target.value;
                                              setHeight(e.target.value);
                                              handleBMI(e.target.value, weight);
                                            }}
                                            onKeyUp={(e) =>
                                              e.keyCode == 13 &&
                                              handleVitalSigns()
                                            }
                                            placeholder="Height in cm"
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    {weightConfig ? (
                                      <div className="row ">
                                        <label className="control-label col-lg-5">
                                          Weight (kg) :
                                        </label>
                                        <div className="form-group col-lg-2 p-0">
                                          <input
                                            className="form-control"
                                            type="number"
                                            name="Weight"
                                            value={weight}
                                            onChange={(e) => {
                                              e.target.value =
                                                e.target.value < 0
                                                  ? ""
                                                  : e.target.value.length > 3
                                                  ? e.target.value.slice(0, 3)
                                                  : e.target.value;
                                              setWeight(e.target.value);
                                              handleBMI(height, e.target.value);
                                            }}
                                            onKeyUp={(e) =>
                                              e.keyCode == 13 &&
                                              handleVitalSigns()
                                            }
                                            placeholder="Weight in kg"
                                          />
                                        </div>
                                      </div>
                                    ) : null}
                                    {bmi && weight && height && (
                                      <div className="row mb-4">
                                        <label className="control-label col-lg-5">
                                          BMI :
                                        </label>
                                        <div className="col-lg-5 mt-2 p-0">
                                          {bmi} ({bmiNote})
                                        </div>
                                      </div>
                                    )}
                                    {bloodPressureConfig ? (
                                      <>
                                        <div className="form-group row">
                                          <label className="control-label col-lg-5">
                                            Temperature (°F) :
                                          </label>
                                          <div className="col-lg-2 p-0">
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="Tempreture"
                                              value={tempreture}
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.length > 0
                                                    ? e.target.value.slice(0, 3)
                                                    : e.target.value;
                                                setTempreture(e.target.value);
                                              }}
                                              placeholder="Tempreture"
                                              onKeyUp={(e) =>
                                                e.keyCode == 13 &&
                                                handleVitalSigns()
                                              }
                                            />
                                          </div>
                                        </div>

                                        <div className="form-group row ">
                                          <label className="control-label col-lg-5">
                                            Blood Pressure :
                                          </label>
                                          <div className="col-lg-2 p-0">
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="Blood Pressure"
                                              value={bpSystolic}
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.length > 0
                                                    ? e.target.value.slice(0, 3)
                                                    : e.target.value;
                                                setBpSystolic(e.target.value);
                                              }}
                                              placeholder="Systolic"
                                              onKeyUp={(e) =>
                                                e.keyCode == 13 &&
                                                handleVitalSigns()
                                              }
                                            />
                                          </div>
                                          <div
                                            className="col-lg-1 mt-2 ms-1"
                                            style={{
                                              fontSize: "35px",
                                            }}
                                          >
                                            /
                                          </div>
                                          <div className="col-lg-2 p-0">
                                            <input
                                              className="form-control"
                                              type="text"
                                              name="Blood Pressure"
                                              value={bpDiastolic}
                                              onChange={(e) => {
                                                e.target.value =
                                                  e.target.value.length > 0
                                                    ? e.target.value.slice(0, 2)
                                                    : e.target.value;
                                                setBpDiastolic(e.target.value);
                                              }}
                                              placeholder="Diastolic"
                                              onKeyUp={(e) =>
                                                e.keyCode == 13 &&
                                                handleVitalSigns()
                                              }
                                            />
                                          </div>
                                        </div>
                                      </>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <button
                                className="btn"
                                onClick={handleVitalSigns}
                              >
                                <span className="icofont-arrow-right"></span>
                                {role === "Practitioner Permission"
                                  ? "Start Consultation"
                                  : "Save Vitals"}
                              </button>
                              <button
                                className="btn"
                                title="New Appointment"
                                onClick={handleNewAppointment}
                              >
                                <span className="icofont icofont-plus"></span>
                                New Appointment
                              </button>
                            </Modal.Footer>
                          </Modal>
                        </>
                      </div>
                    ) : (
                      <div className="row">
                        <div className="mt-4 mb-4">
                          <button
                            title="Add First Patient"
                            className="btn-2"
                            onClick={(e) => {
                              navigate("/newpatient");
                            }}
                          >
                            <span className="icofont icofont-plus"></span>
                            Add First Patient
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}
export default NewAppointment;
