import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import storage from "../auth/storage";
import DoctorNavBar from "./DoctorNavBar";
import pen from "../assests/svg/pen.svg";
import string from "../string";
import { useNavigate } from "react-router-dom";
const prefixerror = "LabTestList";

function LabTestList() {
  //#region const
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedLabList, setselectedLabList] = useState([]);
  const [lab, setLabTest] = useState([]);
  const [limitStart, setLimitStart] = useState(0);
  const [searchString, setSearchString] = useState("[]");
  const [addLabModal, setAddLabModal] = useState(false);
  const [labTestNameToAdd, setLabTestNameToAdd] = useState("");
  const [labTestCodeToAdd, setLabTestCodeToAdd] = useState("");
  const [labTestNameError, setLabTestNameError] = useState(false);
  const [labTestCodeError, setLabTestCodeError] = useState(false);
  const [labTestSuccess, setlabTestSuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [practitioner, setPractitioner] = useState();
  const [labTestId, setlabTestId] = useState();
  const [message, setMessage] = useState();
  const [showFailure, setShowFailure] = useState(false);
  const [role, setRole] = useState("");
  let sno = limitStart + 1;
  //#endregion

  const closeErrors = () => {
    setLabTestNameError(false);
    setLabTestCodeError(false);
    setlabTestSuccess(false);
    document.getElementById("labTestName").className = " form-control";
    document.getElementById("labTestCode").className = " form-control";
  };

  const handleMove = async (labTestId) => {
    try {
      setLoad(true);
      console.log("labTestId---", labTestId);
      const editRes = await doctorApi.editLabOwner(labTestId);
      if (!editRes.ok) {
        ErrorLog(
          prefixerror + "handleMove " + "editLabOwner",
          JSON.stringify(editRes),
          "We are facing some issues.Please try again after sometime.",
          "labTestId:" + labTestId
        );
        return;
      }
      console.log("editRes---", editRes);
      setShowSuccess(true);
      setMessage("Lab test moved to Global list successfully");
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      getLabTestList(0, "[]");
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleMove ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleDeleteLabTest = async (name) => {
    try {
      setLoad(true);
      console.log("name--", name);
      const delRes = await doctorApi.deleteLabTest(name);
      console.log("delRes---", delRes);
      if (!delRes.ok) {
        ErrorLog(
          prefixerror + "handleDeleteLabTest " + "deleteLabTest",
          JSON.stringify(delRes),
          "We are facing some issues.Please try again after sometime.",
          "name:" + name
        );
        setShowFailure(true);
        setTimeout(() => {
          setShowFailure(false);
        }, 3000);
        setLoad(false);
        return;
      }

      setMessage("Lab Test deleted successfully");
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      setShowSuccess(true);
      getLabTestList(0, "[]");
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDeleteLabTest ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getLabTestList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      setRole(role);
      console.log("role---", role);
      if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
        navigate("/");
        return;
      }
      setSearchString(searchString);
      const pagesize = await storage.getPageSize();
      const practitioner = await storage.getID();
      setPractitioner(practitioner);
      setPageSize(pagesize);
      console.log("searchString----", searchString);
      let or_filters = "[]";
      if (role != "SuperAdmin") {
        or_filters = `[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]`;
      }
      const allRes = await doctorApi.getTotalCountByDocType(
        "Lab Test Template",
        searchString,
        or_filters
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + "getLabTestList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString + "or_filters:" + or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);
      const res = await doctorApi.getLabTest(
        practitioner,
        searchString,
        or_filters,
        limitStart,
        pageSize
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getLabTestList " + "getLabTest",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "practitioner:" +
            practitioner +
            "searchString" +
            searchString +
            "or_filters:" +
            or_filters +
            "limitStart" +
            limitStart +
            "pageSize" +
            pageSize
        );
        return;
      }
      console.log("data==", res.data.data);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getLabTestList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getLabTestList(lmtst, searchString);
  };

  const handleLabTestAdd = async () => {
    try {
      closeErrors();
      let err = false;
      if (labTestCodeToAdd == "") {
        setLabTestCodeError(true);
        document.getElementById("labTestCode").className +=
          " form-control-error";
        document.getElementById("labTestCode").focus();
        err = true;
      }
      if (labTestNameToAdd == "") {
        setLabTestNameError(true);
        document.getElementById("labTestName").className +=
          " form-control-error";
        document.getElementById("labTestName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      console.log("labTestId---", labTestId);
      let Res;
      if (labTestId && labTestId != "") {
        Res = await doctorApi.editLab(
          labTestId,
          labTestNameToAdd,
          labTestCodeToAdd
        );
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleLabTestAdd " + "editLab",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "labTestId:" +
              labTestId +
              "labTestNameToAdd:" +
              labTestNameToAdd +
              "labTestCodeToAdd:" +
              labTestCodeToAdd
          );
          return;
        }
        setMessage("Lab Test updated successfully");
      } else {
        Res = await doctorApi.createLabTest(labTestNameToAdd, labTestCodeToAdd);
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleLabTestAdd " + "createLabTest",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "labTestNameToAdd:" +
              labTestNameToAdd +
              "labTestCodeToAdd:" +
              labTestCodeToAdd
          );
          return;
        }
        setMessage("Lab Test added successfully");
      }
      console.log("Res---", Res);
      setlabTestSuccess(true);
      getLabTestList(0, "[]");
      setTimeout(() => {
        setlabTestId("");
        setLabTestNameToAdd("");
        setLabTestCodeToAdd("");
        setAddLabModal(false);
        setlabTestSuccess(false);
      }, 3000);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleLabTestAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSelectLab = (e) => {
    console.log("e---", e);
    if (e && e.length > 0) {
      console.log("e[0].name----", e[0].name);
      setselectedLabList(e);
      getLabTestList(limitStart, '[["name","=","' + e[0].name + '"]]');
    } else {
      setselectedLabList();
    }
    setLabTest([]);
  };

  const handleSearchLab = async (value) => {
    try {
      console.log("value---", value);
      setLoad(true);
      setLabTest([]);
      if (value.length > 2) {
        const res = await doctorApi.searchLab(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchLab " + "searchLab",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("lab--", res.data.message);
        if (res.data.message.length > 0) {
          for (let i = 0; i < res.data.message.length; i++) {
            res.data.message[i].lab_test_name =
              res.data.message[i].lab_test_code +
              " - " +
              res.data.message[i].lab_test_name;
          }
          setLabTest(res.data.message);
        }
      } else if (value == "" && value.length == 0) {
        setSearchString("[]");
        getLabTestList(limitStart, "[]");
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSearchLab ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  useEffect(() => {
    getLabTestList(0, "[]");
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Lab Test List</h1>
                </div>
                <div className="row form-group">
                  <div className="col-lg-4">
                    <Multiselect
                      name="labtest"
                      id="labtest"
                      options={lab}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      selectedValues={selectedLabList}
                      placeholder={"Lab Test Name"}
                      onSelect={handleSelectLab}
                      onRemove={(e) => {
                        getLabTestList(0, "[]");
                        return;
                      }}
                      displayValue="lab_test_name"
                      selectionLimit={1}
                      customCloseIcon={" "}
                      hidePlaceholder={true}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      emptyRecordMsg={
                        document.getElementById("labtest_input") &&
                        document.getElementById("labtest_input").value.length >
                          2 ? (
                          <div className="notFoundAdd">
                            Do you want to add?&nbsp;&nbsp;&nbsp;
                            <button
                              className="btn-2"
                              title="Add"
                              onClick={(e) => {
                                setAddLabModal(true);
                                closeErrors();
                              }}
                            >
                              <span
                                className="icofont icofont-plus mr-1"
                                style={{ fontSize: "1em" }}
                              ></span>
                              Add
                            </button>
                          </div>
                        ) : (
                          <div className="notFoundSearch">Type to search</div>
                        )
                      }
                      onSearch={(e) => {
                        handleSearchLab(
                          document.getElementById("labtest_input").value
                        );
                        setLabTestNameToAdd(
                          document.getElementById("labtest_input").value
                        );
                        handleSelectLab();
                      }}
                    />
                  </div>
                </div>
                <ShowMessage view={showSuccess} Message={message} />
                <ErrorMessage
                  error={
                    "Couldn't delete because its already prescribed to a patient"
                  }
                  visible={showFailure}
                />
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} lab test identified.
                  </div>
                  {data.length > 0 && (
                    <div id="report">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th width="15%">Code</th>
                              <th>Lab Test Name</th>
                              {role == "SuperAdmin" && (
                                <>
                                  <th width="12%">Created By</th>
                                  <th width="12%">Move to Global list</th>
                                </>
                              )}
                              <th width="5%">Edit</th>
                              <th width="5%">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{sno++}</td>
                                  <td className="nowrapWhiteSpace">
                                    {item.lab_test_code}
                                  </td>
                                  <td>{item.lab_test_name}</td>

                                  {role == "SuperAdmin" && (
                                    <>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail
                                          ? item.owner
                                          : ""}
                                      </td>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail && (
                                          <a
                                            onClick={(e) => {
                                              handleMove(item.name);
                                            }}
                                          >
                                            Move
                                          </a>
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          setlabTestId(item.name);
                                          setLabTestNameToAdd(
                                            item.lab_test_name
                                          );
                                          setLabTestCodeToAdd(
                                            item.lab_test_code
                                          );
                                          setAddLabModal(true);
                                          closeErrors();
                                        }}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Edit
                                      </span>
                                    )}
                                  </td>
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          handleDeleteLabTest(item.name);
                                          setselectedLabList(0);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize ? (
                        <table
                          style={{
                            borderBottom: 0,
                            backgroundColor: "#f6f4f4",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-4">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  )}
                </div>
                <Modal show={addLabModal}>
                  <Modal.Header>
                    <Modal.Title>Lab Test</Modal.Title>
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        setAddLabModal(false);
                        getLabTestList(0, "[]");
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body style={{ width: "100%" }}>
                    <div id="modelElement">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-4">
                          <span className="star">*</span> Lab Test Code :
                        </label>
                        <div className="col-lg-6">
                          <CustomTextInput
                            type="text"
                            name="labTestCode"
                            id="labTestCode"
                            maxlength="50"
                            className="form-control"
                            value={labTestCodeToAdd}
                            onChange={(e) => {
                              setLabTestCodeError(false);
                              setLabTestNameError(false);
                              setLabTestCodeToAdd(e.target.value);
                            }}
                            placeholder="lab Test Code"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleLabTestAdd()
                            }
                          />
                          <ErrorMessage
                            error={"Please enter the lab Test Code"}
                            visible={labTestCodeError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-4">
                          <span className="star">*</span> Lab Test Name:
                        </label>
                        <div className="col-lg-6">
                          <CustomTextInput
                            type="text"
                            name="labTestName"
                            id="labTestName"
                            maxlength="140"
                            className="form-control"
                            value={labTestNameToAdd}
                            onChange={(e) => {
                              setLabTestNameError(false);
                              setLabTestCodeError(false);
                              setLabTestNameToAdd(e.target.value);
                            }}
                            placeholder="lab Test Name"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleLabTestAdd()
                            }
                          />
                          <ErrorMessage
                            error={"Please enter lab Test Name"}
                            visible={labTestNameError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters App">
                        <ShowMessage view={labTestSuccess} Message={message} />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn"
                      title="Close"
                      onClick={() => {
                        handleLabTestAdd();
                        setselectedLabList(0);
                      }}
                    >
                      <span className="icofont-save"></span>
                      Save
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default LabTestList;
