import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import PdfComponent from "../component/PdfComponent";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import string from "../string";
import AuthContext from "../auth/context";
import storage from "../auth/storage";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";

import doctorApi from "../api/doctorApi";

const prefixerror = "EndConsultation";

function EndConsultation() {
  //#region const
  const location = useLocation();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [html, setHtml] = useState(false);
  const [whatsapp, setWhatsapp] = useState();
  const [invoiceButton, setInvoiceButton] = useState();
  const [docTitle, setDocTitle] = useState();
  const [downloadButton, setDownloadButton] = useState("");
  const {
    // whatsAppConfig,
    prescriptionHeightConfig,
    prescriptionWeightConfig,
    prescriptionBMIConfig,
    prescriptionBPConfig,
    prescriptionSymptomConfig,
    prescriptionSymptomDurationConfig,
    prescriptionDiagnosisConfig,
    prescriptionLabTestConfig,
    prescriptionScanConfig,
    prescriptionPatientNotesConfig,
    prescriptionDoctorNotesConfig,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  //#endregion

  // const handleWhatsApp = async (patient, pdfUrl) => {
  //   setLoad(true);
  //   const patientRes = await doctorHomePageApi.getPatientById(patient);
  //   // console.log("patientRes---", patientRes);
  //   const msgRes = await doctorHomePageApi.sendWhatAppMessage(
  //     patientRes.data.data.mobile, // "9791826001", //
  //     "Prescription from Cloud Clinic",
  //     pdfUrl
  //   );
  //   // console.log("msgRes----", msgRes);
  //   setShowSuccess(true);
  //   setLoad(false);
  //   setTimeout(() => {
  //     setShowSuccess(false);
  //   }, 3000);
  // };

  const consultationSummary = async () => {
    try {
      setLoad(true);
      const consultationName = location.state.consultationName;
      setDocTitle(consultationName.replace("HLC-ENC-", "Prescription-"));
      const res = await doctorApi.consultationPrescription(consultationName);
      console.log("consultationres------------", res);
      console.log("esign------------", res.data.message[0].esign_url);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "consultationSummary " + "consultationPrescription",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "consultationName:" + consultationName
        );
        return;
      }
      setData(res.data.message[0]);

      const pdfCont = (
        <div className="row">
          <div className="col-lg-2"></div>
          <div className="col-lg-6">
            <div className="border mb-4 text-center printwidth">
              <div style={{ backgroundColor: "rgb(255, 255, 255)" }}>
                <img
                  alt={string.websiteName + " Prescription"}
                  src={string.testbaseUrl + res.data.message[0].letter_image}
                  className="letter-upload"
                />
              </div>
              <div className="text-center p-2">
                <div className="mt-2">
                  <h4 className="text-center">
                    {res.data.message[0].company_name}
                  </h4>
                </div>
                <div className="row no-gutters">
                  <div className="alignRight" style={{ marginRight: "4px" }}>
                    <b>
                      {res.data.message[0].practitioner_name} /{" "}
                      {res.data.message[0].registered_practitioner_number}
                    </b>
                    <br />
                    {res.data.message[0].company_address_line1 != "" ? (
                      <>
                        {res.data.message[0].company_address_line1},{" "}
                        {res.data.message[0].company_address_line2},{" "}
                        {res.data.message[0].company_city} -{" "}
                        {res.data.message[0].company_pincode}.
                      </>
                    ) : null}
                    <hr style={{ margin: "1% 0px" }} />
                    {moment(res.data.message[0].encounter_date).format(
                      "DD-MM-yyyy"
                    )}
                  </div>
                </div>
                <div className="row ">
                  <div style={{ textAlign: "left" }}>
                    <label className="con_sum_lbl">Patient Name :</label>
                    <label className="ms-1">
                      {res.data.message[0].patient_name}
                    </label>
                    <label className="ms-5 ps-1">
                      {res.data.message[0].patient_sex} /
                    </label>
                    <label className="">
                      {res.data.message[0].patient_age}
                    </label>
                  </div>
                </div>
                <div className="row ">
                  {prescriptionHeightConfig &&
                  res.data.message[0].height > 0 ? (
                    <div className="col-lg-4" style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Height:</label>
                      <label className="ms-1">
                        {res.data.message[0].height + " cm"}
                      </label>
                    </div>
                  ) : null}
                  <div className="col-lg-4" style={{ textAlign: "left" }}>
                    {prescriptionWeightConfig &&
                    res.data.message[0].weight > 0 ? (
                      <>
                        <label className="con_sum_lbl">Weight:</label>
                        <label className="ms-1">
                          {res.data.message[0].weight + " kg"}
                        </label>
                      </>
                    ) : null}
                  </div>
                  <div className="col-lg-4" style={{ textAlign: "left" }}>
                    {prescriptionBMIConfig && res.data.message[0].bmi > 0 ? (
                      <>
                        <label className="con_sum_lbl">BMI:</label>
                        <label className="ms-1">
                          {res.data.message[0].bmi} ({" "}
                          {res.data.message[0].nutrition_note} )
                        </label>
                      </>
                    ) : null}
                  </div>
                </div>
                <div className="row ">
                  {prescriptionBPConfig && res.data.message[0].bp_diastolic ? (
                    <div className="col-lg-4" style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Blood Pressure:</label>
                      <label className="ms-1">
                        {res.data.message[0].bp_systolic} /{" "}
                        {res.data.message[0].bp_diastolic}
                      </label>
                    </div>
                  ) : null}
                  {prescriptionBPConfig && res.data.message[0].temperature ? (
                    <div className="col-lg-4" style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Temperature (°F) :</label>
                      <label className="ms-1">
                        {res.data.message[0].temperature}
                      </label>
                    </div>
                  ) : null}
                </div>
                <div className="row ">
                  {prescriptionSymptomConfig ? (
                    <div style={{ textAlign: "left", lineHeight: "12px" }}>
                      <label className="con_sum_lbl me-1">Symptoms:</label>
                      {res.data.message[0].symptoms &&
                      res.data.message[0].symptoms.length > 0
                        ? res.data.message[0].symptoms.map((item, index) =>
                            index == 0
                              ? item.complaintName
                              : ", " + item.complaintName
                          )
                        : null}
                    </div>
                  ) : null}
                </div>
                {prescriptionSymptomDurationConfig &&
                res.data.message[0]._duration_in_symptoms &&
                res.data.message[0]._duration_in_symptoms != "" ? (
                  <div className="row  mt-2">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl me-1">Duration:</label>
                      {res.data.message[0]._duration_in_symptoms}
                    </div>
                  </div>
                ) : null}
                {prescriptionDiagnosisConfig ? (
                  <div className="row ">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl me-1">
                        Disease / Conditions:
                      </label>
                      {res.data.message[0].diagnosis &&
                      res.data.message[0].diagnosis.length > 0
                        ? res.data.message[0].diagnosis.map((item) => {
                            return item.diagnosisName;
                          })
                        : null}
                    </div>
                  </div>
                ) : null}

                <div style={{ textAlign: "left" }} className="row mt-2">
                  <label className="con_sum_lbl">Prescribed Medicines</label>
                </div>
                <div className="row">
                  <div className="mt-1 table-responsive">
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th width="5%">No.</th>
                          <th>Medicine</th>
                          <th>Consumption</th>
                          <th>Before / After</th>
                          <th>Duration</th>
                          <th>Instruction</th>
                        </tr>
                      </thead>
                      <tbody>
                        {res.data.message[0].drug_prescription.map(
                          (item, index) => {
                            return (
                              <tr>
                                <td>{index + 1}</td>
                                <td>{item.drug_name}</td>
                                <td>{item.dosage}</td>
                                <td> {item.comment}</td>
                                <td>{item.period}</td>
                                <td>{item.dosage_instruction}</td>
                              </tr>
                            );
                          }
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {prescriptionLabTestConfig &&
                res.data.message[0].lab_test_prescription &&
                res.data.message[0].lab_test_prescription.length > 0 ? (
                  <div className="row" style={{ textAlign: "left" }}>
                    <label className="mt-1 con_sum_lbl">Lab Test</label>
                    <div className="mt-1 mb-2 table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th width="5%">No.</th>
                            <th>Lab Tests</th>
                            <th>Comment</th>
                          </tr>
                        </thead>
                        <tbody>
                          {res.data.message[0].lab_test_prescription.map(
                            (item, index) => {
                              return (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.lab_test_name}</td>
                                  <td> {item.lab_test_comment}</td>
                                </tr>
                              );
                            }
                          )}{" "}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ) : null}
                {prescriptionScanConfig && res.data.message[0].scan ? (
                  <div className="row">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl me-1">Scan :</label>
                      {res.data.message[0].scan}
                    </div>
                  </div>
                ) : null}
                {res.data.message[0].revisit_date ? (
                  <div className="row">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Revisit Date :</label>
                      <label className="ms-1">
                        {moment(
                          new Date(res.data.message[0].revisit_date)
                        ).format("DD-MM-yyyy")}
                      </label>
                    </div>
                  </div>
                ) : null}
                {prescriptionPatientNotesConfig &&
                res.data.message[0].notes_for_patient ? (
                  <div className="row">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Patient Notes:</label>
                      <label className="ms-1">
                        {res.data.message[0].notes_for_patient}
                      </label>
                    </div>
                  </div>
                ) : null}
                {prescriptionDoctorNotesConfig &&
                res.data.message[0].notes_for_doctor ? (
                  <div className="row">
                    <div style={{ textAlign: "left" }}>
                      <label className="con_sum_lbl">Doctor Notes:</label>
                      <label className="ms-1">
                        {res.data.message[0].notes_for_doctor}
                      </label>
                    </div>
                  </div>
                ) : null}
                <div className=" mb-3 alignRight">
                  <label className="con_sum_lbl"> Signature</label>
                  {res.data.message[0].esign_url &&
                  res.data.message[0].esign_url != "" ? (
                    <>
                      <br />
                      <img
                        alt="Signature"
                        src={string.testbaseUrl + res.data.message[0].esign_url}
                        className="eSign"
                      />
                    </>
                  ) : (
                    <>
                      <br />
                      <br />
                      <br />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      );
      setHtml(pdfCont);
      let pdfUrl = "";
      if (res.data.message[0].encounter_pdf != "") {
        pdfUrl = string.testbaseUrl + res.data.message[0].encounter_pdf;
      } else {
        const pdfRes = await doctorApi.prescriptionPdfCreation(
          consultationName
        );
        console.log("pdfRes----", pdfRes);
        if (pdfRes.ok) pdfUrl = string.testbaseUrl + pdfRes.data.message;
      }
      console.log("pdfUrl---", pdfUrl);
      const whatsapp = null;
      // const whatsapp = whatsAppConfig ? (
      //   <button
      //     type="submit"
      //     className="btn"
      //     title="Send Whatsapp"
      //     onClick={() => handleWhatsApp(res.data.data.patient, pdfUrl)}
      //   >
      //     <span className="icofont-brand-whatsapp"></span>
      //     Send to Whatsapp
      //   </button>
      // ) : null;
      setWhatsapp(whatsapp);

      const role = await storage.getRole();
      const invoiceButton =
        !res.data.message[0].invoiced &&
        (role == "Billing" || role == "Practitioner Permission") ? (
          <button
            type="submit"
            className="btn"
            title="Add Invoice"
            onClick={() =>
              handleAddInvoice(
                res.data.message[0].patient,
                res.data.message[0].patient_name,
                consultationName,
                res.data.message[0].practitioner
              )
            }
          >
            <span className="icofont-pay"></span>
            Generate Invoice
          </button>
        ) : null;
      setInvoiceButton(invoiceButton);

      if (pdfUrl != "") {
        const downloadButton = (
          <a
            href={pdfUrl}
            rel="noreferrer"
            target="_blank"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            <button className="btn">
              <span className="icofont-download"></span>Download
            </button>
          </a>
        );

        setDownloadButton(downloadButton);
      }
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      setLoad(false);
      ErrorLog(
        prefixerror + "consultationSummary ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleAddInvoice = async (
    patientId,
    fullName,
    consultationName,
    practitioner
  ) => {
    console.log("practitioner--", practitioner);
    navigate("/invoice", {
      state: { patientId, fullName, consultationName, practitioner },
    });
  };

  useEffect(() => {
    consultationSummary();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10 mb-5">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <PdfComponent
                  docTitle={docTitle}
                  data={html}
                  whatsapp={whatsapp}
                  invoiceButton={invoiceButton}
                  downloadButton={downloadButton}
                  print={true}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default EndConsultation;
