export default {
  //Testing
  // testbaseUrl: "https://mmserpnext.mymedicalshop.com",
  // websiteURL: "http://localhost:3000",
  CLIENT_ID:
    "1018834380654-jg8hrphvbkc5rdv70nfqo2s5a9cf3dhk.apps.googleusercontent.com",
  API_KEY: "AIzaSyBV23hqQLncXY_dNO_tBGqbhK_ATufCnqg",

  //Live
  testbaseUrl: "https://mmscloudclinic.mymedicalshop.com",
  websiteURL: "https://myclinichealth.in",

  // Dont change
  adminEmail: "test@gmail.com",
  senderEmail: "contact@myclinichealth.in",
  mailLogo: "https://mmscloudclinic.mymedicalshop.com/files/Cloud_Clinic.png",
  SCOPE: "https://www.googleapis.com/auth/calendar.events",
  // SCOPE: "https://www.googleapis.com/auth/userinfo.email",
  WhatsAppFrom: "+917540000261",
  version: "V-001.000.001",
  websiteName: "My Clinic ",
  doctorAppName: "My Clinic ",
  patientAppName: "My Health ",
  DOCTOR_ROLE: "Practitioner Permission",
  SUPERADMIN_ROLE: "SuperAdmin",
};
