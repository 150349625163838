import React, { useEffect, useState } from "react";
// import { Tab, Tabs } from "react-bootstrap";
import { Tab, Col, Nav, Row } from "react-bootstrap";
import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";
import ErrorMessage from "../component/common/ErrorMessage";

import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";

import adminApi from "../api/adminApi";
import ShowMessage from "../component/common/ShowMessage";

import ConfigGeneral from "../assests/svg/config_general.svg";
import ConfigPayment from "../assests/svg/config_payment.svg";

const prefixerror = "AdminConfiguration";

function AdminConfiguration() {
  //#region const
  const [smartAssistant, setSmartAssistant] = useState(0);
  const [load, setLoad] = useState(false);
  const [toEmail, setToEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  //#endregion

  const getEmailConfig = async () => {
    try {
      setLoad(true);
      const Res = await adminApi.getAdminConf();
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "getOverLap " + "getAdminConf",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setSmartAssistant(Res.data.message[0]);

      const mailReceiver = await adminApi.getEmailRecipient();
      console.log("mailReceiver---", mailReceiver);
      if (!mailReceiver.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "getAdminConfig",
          JSON.stringify(mailReceiver),
          "We are facing some issues.Please try again after sometime.",
          ""
        );
        return;
      }
      setToEmail(mailReceiver.data.data[0].value);
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getOverLap ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    return;
  };

  const handleUpdate = async () => {
    console.log("toemail--", toEmail);
    if (toEmail == "") {
      setEmailError(true);
      return;
    }
    try {
      setLoad(true);
      const updateEmail = await adminApi.setEmailRecipient(toEmail);
      console.log("updateEmail----", updateEmail);
      if (!updateEmail.ok) {
        ErrorLog(
          prefixerror + "handleUpdate " + "setEmailRecipient",
          JSON.stringify(updateEmail),
          "We are facing some issues.Please try again after sometime.",
          "toEmail:" + toEmail
        );
        return;
      }
      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      setLoad(false);
    }
  };

  const handleSubmit = async (parameter) => {
    try {
      setLoad(true);
      console.log("parameter--", parameter);
      const generalUpdateRes = await adminApi.setAdminConf(parameter);
      console.log("generalUpdateRes---", generalUpdateRes);
      if (!generalUpdateRes.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "setAdminConf",
          JSON.stringify(generalUpdateRes),
          "We are facing some issues.Please try again after sometime.",
          "parameter:" + parameter
        );
        return;
      }

      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    return;
  };

  useEffect(() => {
    getEmailConfig();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="mb-4 p-lg-5" onClick={preFunction.hideNavbar}>
            <h1>Configuration</h1>
            <div className="border">
              <Tab.Container defaultActiveKey="GeneralKey">
                <Row className="no-gutters">
                  <Col
                    sm={3}
                    className="pt-5 pb-5"
                    style={{ backgroundColor: "#F2F6FF" }}
                  >
                    <Nav variant="tabs" className="flex-column">
                      <Nav.Item>
                        <Nav.Link eventKey="GeneralKey">
                          <img
                            src={ConfigGeneral}
                            width={26}
                            height={26}
                            style={{ paddingRight: "10px" }}
                          />
                          General
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="EmailKey">
                          <img
                            src={ConfigPayment}
                            width={26}
                            height={26}
                            style={{ paddingRight: "10px" }}
                          />
                          Email Recipient
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </Col>
                  <Col
                    sm={9}
                    className="p-5"
                    style={{ backgroundColor: "#fff" }}
                  >
                    <Tab.Content>
                      <Tab.Pane eventKey="GeneralKey" title="General">
                        <div className="row p-4">
                          <div className="col-1"></div>
                          <div className="col-11 ">
                            <label className="switch">
                              <input
                                type="checkbox"
                                checked={smartAssistant ? "checked" : ""}
                                onClick={(e) => {
                                  handleSubmit(!smartAssistant);
                                  setSmartAssistant(!smartAssistant);
                                }}
                              />
                              <span className="slider round"></span>
                            </label>
                            <label>Smart Assistant</label>
                          </div>
                          <div className="col-1"></div>
                        </div>
                      </Tab.Pane>
                      <Tab.Pane eventKey="EmailKey" title="Email Recipient">
                        <ShowMessage
                          view={showSuccess}
                          Message="Email Recipient Updated"
                        />
                        <div className="row mt-3">
                          <label className="control-label col-lg-3">
                            Email Recipient :
                          </label>
                          <div className="col-lg-4 p-0">
                            <div className="form-group">
                              <textarea
                                name="toEmail"
                                id="toEmail"
                                rows="4"
                                cols="70"
                                className="textArea"
                                value={toEmail}
                                onChange={(e) => {
                                  setEmailError(false);
                                  setToEmail(e.target.value);
                                }}
                                placeholder="Description"
                              />
                              <ErrorMessage
                                error={"Please enter Email Recipient"}
                                visible={emailError}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <label className="control-label col-lg-3"></label>
                          <div className="col-lg-4 p-0">
                            <div className="form-group">
                              <button className="btn" onClick={handleUpdate}>
                                <span className="icofont icofont-save"></span>
                                Update Recipient
                              </button>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane>
                    </Tab.Content>
                  </Col>
                </Row>
              </Tab.Container>
              {/* <Tabs
                defaultActiveKey="General"
                id="uncontrolled-tab-example"
                className="mb-2"
                fill
              >
                <Tab eventKey="General" title="General">
                  <div className="row p-4">
                    <div className="col-1"></div>
                    <div className="col-11 ">
                      <label className="switch">
                        <input
                          type="checkbox"
                          checked={smartAssistant ? "checked" : ""}
                          onClick={(e) => {
                            handleSubmit(!smartAssistant);
                            setSmartAssistant(!smartAssistant);
                          }}
                        />
                        <span className="slider round"></span>
                      </label>
                      <label>Smart Assistant</label>
                    </div>
                    <div className="col-1"></div>
                  </div>
                </Tab>
                <Tab eventKey="Email " title="Email Recipient">
                  <ShowMessage
                    view={showSuccess}
                    Message="Email Recipient Updated"
                  />
                  <div className="row mt-3">
                    <label className="control-label col-lg-3">
                      Email Recipient :
                    </label>
                    <div className="col-lg-4 p-0">
                      <div className="form-group">
                        <textarea
                          name="toEmail"
                          id="toEmail"
                          rows="4"
                          cols="70"
                          className="textArea"
                          value={toEmail}
                          onChange={(e) => {
                            setEmailError(false);
                            setToEmail(e.target.value);
                          }}
                          placeholder="Description"
                        />
                        <ErrorMessage
                          error={"Please enter Email Recipient"}
                          visible={emailError}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <label className="control-label col-lg-3"></label>
                    <div className="col-lg-4 p-0">
                      <div className="form-group">
                        <button className="btn" onClick={handleUpdate}>
                          <span className="icofont icofont-save"></span>
                          Update Recipient
                        </button>
                      </div>
                    </div>
                  </div>
                </Tab>
              </Tabs> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminConfiguration;
