import React from "react";

import preFunction from "../component/common/CommonFunction";

import string from "../string";
import PageHeader from "./pageHeader";

const ContactUs = () => {
  return (
    <div className="row no-gutters">
      <PageHeader />
      <div className="row projectbg" onClick={preFunction.hideNavbar}>
        <div className="col-lg-2"></div>
        <div className="col-lg-8 pageStatic">
          <div className="row p-3">
            <h4>CONTACT US</h4>
            <h5>SMV Healthcare Private Limited.</h5>
            <div className="row">
              TS-126, ARS Tower, <br />
              II Floor, Thiru.Vi.Ka lndustrial Estate
              <br />
              Ekkaduthangal, <br />
              Chennai - Tamilnadu - India.
              <br />
              Pin Code - 600032
              <br />
              Phone: {string.WhatsAppFrom}
              <br />
              Email : {string.senderEmail}
            </div>
          </div>
        </div>
        <div className="col-lg-2"></div>
      </div>
    </div>
  );
};

export default ContactUs;
