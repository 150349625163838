import React from "react";
import { Link, useLocation } from "react-router-dom";

function LinkGroup({ to, className, title, onClick, src, ...otherProps }) {
  const location = useLocation();
  // console.log(location.pathname, "useLocation");
  return (
    <ul className="list-group">
      <Link
        to={to}
        className={
          location.pathname === to
            ? "list-group-item list-group-item-action active"
            : "list-group-item list-group-item-action "
        }
        aria-current="true"
        onClick={onClick}
        {...otherProps}
      >
        {src ? (
          <img
            src={src}
            width={26}
            height={26}
            style={{ paddingRight: "10px" }}
          />
        ) : (
          <span className={className}></span>
        )}

        {title}
      </Link>
    </ul>
  );
}

export default LinkGroup;
