import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import Select from "../component/common/select";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import { comment } from "../component/common/CommonArray";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
const prefixerror = "AddTreatmentTemplate";

function AddTreatmentTemplate() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [symptom, setSymptom] = useState([]);
  const [diag, setDiagnosis] = useState([]);
  const [symptomSel, setSymptomSel] = useState([]);
  const [diagnosisSel, setDiagnosisSel] = useState([]);
  const [drugs, setMedi] = useState([]);
  const [medName, setMedName] = useState();
  const [medCode, setMedCode] = useState();
  const [genericName, setGenericName] = useState();
  const [consump, setConsump] = useState();
  const [intakes, setIntake] = useState();
  const [period, setPeriod] = useState();
  const [load, setLoad] = useState(false);
  const [labtestName, setLabTestName] = useState("");
  const [comments, setComments] = useState();
  const [consumption, setConsumption] = useState([]);
  const [periodOfDays, setPeriodOfDays] = useState([]);
  const [labTestCode, setLabTestCode] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [medicineSel, setMedicineSel] = useState([]);
  const [labList, setlabList] = useState([]);
  const [lab, setLabTest] = useState([]);
  const [errorSymptom, setErrorSymptom] = useState(false);
  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  const [showMedicine, setShowMedicine] = useState(false);
  const [showConsump, setShowConsump] = useState(false);
  const [showIntake, setShowIntake] = useState(false);
  const [showPeriod, setShowPeriod] = useState(false);
  const [showLab, setShowLab] = useState(false);
  const [showAddMedicine, setShowAddMedicine] = useState(false);
  const [templateNameError, setTemplateNameError] = useState(false);
  const [showAddLab, setShowAddLab] = useState(false);
  const [patientAgeFromError, setPatientAgeFromError] = useState(false);
  const [patientAgeToError, setPatientAgeToError] = useState(false);
  const [description, setDescription] = useState();
  const [open, setOpen] = useState(false);
  const [selectedLabList, setselectedLabList] = useState([]);
  const [templateName, setTemplateName] = useState("");
  const [patientAgeFrom, setPatientAgeFrom] = useState("");
  const [patientAgeTo, setPatientAgeTo] = useState("");
  const [message, setMessage] = useState("");
  const [dosageInstruction, setDosageInstruction] = useState();
  const [addMedicineModal, setAddMedicineModal] = useState(false);
  const [medicineNameToAdd, setMedicineNameToAdd] = useState("");
  const [genericNameToAdd, setGenericNameToAdd] = useState("");
  const [medicineNameError, setMedicineNameError] = useState(false);
  const [genericNameError, setGenericNameError] = useState(false);
  const [medicineSuccess, setMedicineSuccess] = useState(false);
  const [addLabModal, setAddLabModal] = useState(false);
  const [labTestNameToAdd, setLabTestNameToAdd] = useState("");
  const [labTestCodeToAdd, setLabTestCodeToAdd] = useState("");
  const [labTestNameError, setLabTestNameError] = useState(false);
  const [labTestCodeError, setLabTestCodeError] = useState(false);
  const [labTestSuccess, setlabTestSuccess] = useState(false);
  const [labName, setLabName] = useState("");
  //#endregion

  const handleLabTestAdd = async () => {
    try {
      closeErrors();
      let err = false;
      if (labTestCodeToAdd == "") {
        setLabTestCodeError(true);
        document.getElementById("labTestCode").className +=
          " form-control-error";
        document.getElementById("labTestCode").focus();
        err = true;
      }
      if (labTestNameToAdd == "") {
        setLabTestNameError(true);
        document.getElementById("labTestName").className +=
          " form-control-error";
        document.getElementById("labTestName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      let Res = await doctorApi.createLabTest(
        labTestNameToAdd,
        labTestCodeToAdd
      );
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "handleLabTestAdd " + "createLabTest",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "labTestNameToAdd:" +
            labTestNameToAdd +
            "labTestCodeToAdd:" +
            labTestCodeToAdd
        );
        return;
      }
      setMessage("Lab Test added successfully");
      console.log("Res---", Res);
      setlabTestSuccess(true);
      Res.data.data.lab_test_name =
        Res.data.data.lab_test_code + " - " + Res.data.data.lab_test_name;
      handleSelectLab([Res.data.data]);
      setTimeout(() => {
        setLabTestNameToAdd("");
        setLabTestCodeToAdd("");
        setAddLabModal(false);
        setlabTestSuccess(false);
      }, 3000);
    } catch (error) {
      ErrorLog(
        prefixerror + "handleLabTestAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleMedicineAdd = async () => {
    closeErrors();
    try {
      let err = false;
      if (genericNameToAdd == "") {
        setGenericNameError(true);
        document.getElementById("genericName").className +=
          " form-control-error";
        document.getElementById("genericName").focus();
        err = true;
      }
      if (medicineNameToAdd == "") {
        setMedicineNameError(true);
        document.getElementById("medicineName").className +=
          " form-control-error";
        document.getElementById("medicineName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      const Res = await doctorApi.createMedicine(
        medicineNameToAdd,
        genericNameToAdd
      );
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "handleMedicineAdd " + "createMedicine",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "medicineNameToAdd:" +
            medicineNameToAdd +
            "genericNameToAdd:" +
            genericNameToAdd
        );
        return;
      }
      setMessage("Medicine added successfully");
      console.log("Res---", Res);
      setMedicineSuccess(true);
      handleSelectMedicine([Res.data.data]);
      setTimeout(() => {
        setMedicineNameToAdd("");
        setGenericNameToAdd("");
        setAddMedicineModal(false);
        setMedicineSuccess(false);
      }, 3000);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleMedicineAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSelectSymptom = (e) => {
    console.log("symptoms -------", symptomSel, e);
    setSymptomSel(e);
  };

  const handleSelectDiagnosis = (e) => {
    setDiagnosisSel(e);
  };

  const handleSelectMedicine = (e) => {
    setShowMedicine(false);
    console.log("e---", e);
    if (e && e.length > 0) {
      console.log("len--", e[0].item_name.length);
      e[0].item_name =
        e[0].item_name.length > 30
          ? e[0].item_name.substring(0, 30) + "..."
          : e[0].item_name;
      setMedicineSel(e);
      setMedName(e[0].item_name);
      setMedCode(e[0].name);
      setGenericName(e[0].generic_name);
      document.getElementById("medicine_input").blur();
    } else {
      setMedicineSel();
      setMedName();
      setMedCode();
      setGenericName();
    }
    setMedi([]);
  };

  const handleSelectLab = (e) => {
    setShowLab(false);
    console.log("lab---", e);
    if (e && e.length > 0) {
      setLabTestName(e[0].lab_test_name.split(" - ")[1]);
      e[0].lab_test_name =
        e[0].lab_test_name.length > 40
          ? e[0].lab_test_name.substring(0, 40) + "..."
          : e[0].lab_test_name;
      setselectedLabList(e);
      setLabName(e[0].name);
      setLabTestCode(e[0].lab_test_code);

      document.getElementById("labtest_input").value = "";
      document.getElementById("labtest_input").blur();
    } else {
      setselectedLabList();
      setLabTestName("");
      setLabTestCode("");
    }
    setLabTest([]);
  };

  const handleSearchMedicine = async (value) => {
    closeErrors();
    try {
      setMedi([]);
      console.log("value---", value);
      const practitioner = await storage.getID();
      if (value.length > 2) {
        const res = await doctorApi.searchMedicine(practitioner, value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchMedicine " + "searchMedicine",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "practitioner:" + practitioner + "value:" + value
          );
          return;
        }
        console.log("medicines--", res.data.data);
        setMedi(res.data.data);
      }
      setMedName("");
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchMedicine ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleMedicine = async (e) => {
    closeErrors();
    let err = false;
    if (!medName) {
      setShowMedicine(true);
      err = true;
    } else {
      setShowMedicine(false);
    }
    if (!consump) {
      setShowConsump(true);
      err = true;
    } else {
      setShowConsump(false);
    }
    if (!intakes) {
      setShowIntake(true);
      err = true;
    } else {
      setShowIntake(false);
    }
    if (!period) {
      setShowPeriod(true);
      err = true;
    } else {
      setShowPeriod(false);
    }
    if (err) {
      return;
    }
    let medicine = [
      ...medicines,
      {
        id: medicines.length + 1,
        drug_code: medCode,
        drug_name: medName,
        genericName: genericName,
        dosage: consump,
        comment: intakes,
        period: period,
        dosage_form: "Tablets",
        dosage_instruction: dosageInstruction,
      },
    ];

    const obj = [
      ...new Map(
        medicine.map((item) => [JSON.stringify(item.drug_name), item])
      ).values(),
    ];

    setMedicines(obj);
    setMedName("");
    setGenericName("");
    setConsump("");
    setIntake("");
    setPeriod("");
    setDosageInstruction("");
    setMedicineSel([]);
  };

  const handleSearchLab = async (value) => {
    closeErrors();
    try {
      setLabTest([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchLab(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchLab " + "searchLab",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("lab--", res);
        for (let i = 0; i < res.data.message.length; i++) {
          res.data.message[i].lab_test_name =
            res.data.message[i].lab_test_code +
            " - " +
            res.data.message[i].lab_test_name;
        }
        setLabTest(res.data.message);
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchLab ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleLabTest = () => {
    // setselectedLabList([]);
    console.log(selectedLabList, "test");
    if (!labtestName) {
      setShowLab(true);
      return;
    } else setShowLab(false);
    console.log("labtestName---", labtestName);
    console.log("labName---", labName);
    // const labtest = labtestName.split(" - ")[1];
    console.log("labTestCode---", labTestCode);
    let lab = [
      ...labList,
      {
        id: labList.length + 1,
        name: labName,
        lab_test_code: labTestCode,
        lab_test_name: labtestName,
        lab_test_comment: comments,
      },
    ];

    const obj = [
      ...new Map(
        lab.map((item) => [JSON.stringify(item.lab_test_name), item])
      ).values(),
    ];
    console.log("obj---", obj);
    setlabList(obj);
    setLabTestName("");
    setLabTestCode("");
    handleSelectLab("");
    setComments("");
  };

  const handleDeleteMedicine = (item) => {
    // console.log(item);
    const data = medicines.filter((m) => m !== item);
    setMedicines(data);
  };

  const handleDeleteLab = (item) => {
    // console.log(item);
    const data = labList.filter((m) => m !== item);
    setlabList(data);
  };

  const getDetails = async () => {
    try {
      setLoad(true);
      // const role = await storage.getRole();
      if (location.state) {
        console.log("location.state----", location.state);
        const treatRes = await doctorApi.getTreatmentTemplateByName(
          location.state.treatmentName
        );
        if (!treatRes.ok) {
          ErrorLog(
            prefixerror + "getDetails " + "getTreatmentTemplateByName",
            JSON.stringify(treatRes),
            "We are facing some issues.Please try again after sometime.",
            "treatmentName:" + location.state.treatmentName
          );
          return;
        }
        console.log("treatRes---", treatRes);
        setTemplateName(treatRes.data.data.template_name);
        setDescription(treatRes.data.data.description);
        setPatientAgeFrom(treatRes.data.data.patient_age_from);
        setPatientAgeTo(treatRes.data.data.patient_age_to);

        console.log("------------", treatRes.data.data.complaints);
        if (treatRes.data.data.complaints.length > 0) {
          let filter = "[";
          for (let i = 0; i < treatRes.data.data.complaints.length; i++) {
            if (i > 0) {
              filter += `,`;
            }
            filter += `["name","=","${treatRes.data.data.complaints[i].complaint}"]`;
          }
          filter += "]";
          console.log("filter---", filter);

          const symptomRes = await doctorApi.getSymptomByArray(filter);
          if (!symptomRes.ok) {
            ErrorLog(
              prefixerror + "getDetails " + "getSymptomByArray",
              JSON.stringify(symptomRes),
              "We are facing some issues.Please try again after sometime.",
              "filter:" + filter
            );
            return;
          }
          console.log("symptomRes---", symptomRes);

          let symptomSel = [];
          for (let i = 0; i < symptomRes.data.data.length; i++) {
            var myObject = {
              complaint: symptomRes.data.data[i].name,
              complaintName: symptomRes.data.data[i].complaints,
            };
            symptomSel.push(myObject);
          }
          console.log("symptomSel---", symptomSel);
          setSymptomSel(symptomSel);
          setSymptom(symptomSel);
        }

        console.log("------------", treatRes.data.data.diagnosis);
        if (treatRes.data.data.diagnosis.length > 0) {
          let filter = "[";
          for (let i = 0; i < treatRes.data.data.diagnosis.length; i++) {
            if (i > 0) {
              filter += `,`;
            }
            filter += `["name","=","${treatRes.data.data.diagnosis[i].diagnosis}"]`;
          }
          filter += "]";
          console.log("filter---", filter);

          const diagnosisRes = await doctorApi.getDiagnosisByArray(filter);
          if (!diagnosisRes.ok) {
            ErrorLog(
              prefixerror + "getDetails " + "getDiagnosisByArray",
              JSON.stringify(diagnosisRes),
              "We are facing some issues.Please try again after sometime.",
              "filter:" + filter
            );
            return;
          }
          console.log("diagnosisRes---", diagnosisRes);

          let diagnosisSel = [];
          for (let i = 0; i < diagnosisRes.data.data.length; i++) {
            var myObject = {
              diagnosis: diagnosisRes.data.data[i].name,
              diagnosisName: diagnosisRes.data.data[i].diagnosis,
            };
            diagnosisSel.push(myObject);
          }
          console.log("diagnosisSel---", diagnosisSel);
          setDiagnosisSel(diagnosisSel);
          setDiagnosis(diagnosisSel);
        }
        console.log("treatRes.data.data.drugs---", treatRes.data.data.drugs);
        let drugsel = [];
        for (let i = 0; i < treatRes.data.data.drugs.length; i++) {
          let myObject = {
            id: i + 1,
            drug_code: treatRes.data.data.drugs[i].drug_code,
            drug_name: treatRes.data.data.drugs[i].drug_name,
            genericName: treatRes.data.data.drugs[i].drug_name,
            dosage: treatRes.data.data.drugs[i].dosage,
            comment: treatRes.data.data.drugs[i].comment,
            period: treatRes.data.data.drugs[i].period,
            dosage_form: treatRes.data.data.drugs[i].dosage_form,
            dosage_instruction: treatRes.data.data.drugs[i].dosage_instruction,
          };
          drugsel.push(myObject);
        }
        console.log("drugsel---", drugsel);
        setMedicines(drugsel);

        console.log("------------", treatRes.data.data.items);
        if (treatRes.data.data.items.length > 0) {
          let filter = "[";
          for (let i = 0; i < treatRes.data.data.items.length; i++) {
            if (i > 0) {
              filter += `,`;
            }
            filter += `["name","=","${treatRes.data.data.items[i].template}"]`;
          }
          filter += "]";
          const labRes = await doctorApi.getLabTestByArray(filter);
          if (!labRes.ok) {
            ErrorLog(
              prefixerror + "getDetails" + "getLabTestByArray",
              JSON.stringify(labRes),
              "We are facing some issues.Please try again after sometime.",
              "filter:" + filter
            );
            return;
          }
          console.log("labRes---", labRes);
          for (let i = 0; i < labRes.data.data.length; i++) {
            labRes.data.data[i].lab_test_comment =
              treatRes.data.data.items[i].instructions;
          }
          console.log("labRes.data.data---", labRes.data.data);
          setlabList(labRes.data.data);
        }
      }
      const res1 = await doctorApi.getConsumption();
      if (!res1.ok) {
        ErrorLog(
          prefixerror + "getDetails " + "getConsumption",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setConsumption(res1.data.data);
      const res2 = await doctorApi.getDuration();
      if (!res2.ok) {
        ErrorLog(
          prefixerror + "getDetails " + "getDuration",
          JSON.stringify(res2),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setPeriodOfDays(res2.data.data);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "getDetails ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSearchSymptoms = async (value) => {
    try {
      console.log("value-- ", value);
      setSymptom([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchSymptoms(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchSymptoms " + "searchSymptoms",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res--", res);
        let i,
          symptomSel = [];
        if (res.data.message.length > 0) {
          for (i = 0; i < res.data.message.length; i++) {
            var myObject = {
              complaint: res.data.message[i].name,
              complaintName: res.data.message[i].complaints,
            };
            symptomSel.push(myObject);
          }
          setSymptom(symptomSel);
        }
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchSymptoms ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSearchDiagnosis = async (value) => {
    console.log("value---", value);
    try {
      setDiagnosis([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchDiagnosis(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchDiagnosis " + "searchDiagnosis",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res--", res);
        let i,
          arr = [];
        if (res.data.message.length > 0) {
          for (i = 0; i < res.data.message.length; i++) {
            var myObject = {
              diagnosis: res.data.message[i].name,
              diagnosisName: res.data.message[i].diagnosis,
            };
            arr.push(myObject);
          }
          setDiagnosis(arr);
        }
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchDiagnosis ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSymptomsAdd = async (symptomsName) => {
    try {
      setLoad(true);
      const Res = await doctorApi.createSymptoms(symptomsName);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "handleSymptomsAdd " + "createSymptoms",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "symptomsName:" + symptomsName
        );
        return;
      }
      console.log("Res----", Res.data.data);
      let newSymptoms = [
        ...symptomSel,
        { complaint: Res.data.data.name, complaintName: symptomsName },
      ];
      setSymptomSel(newSymptoms);
      document.getElementById("search_input").value = "";
      document.getElementById("search_input").blur();
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSymptomsAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleDiagnosisAdd = async (diagnosisName) => {
    setLoad(true);
    try {
      const Res = await doctorApi.createDiagnosis(diagnosisName);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "handleDiagnosisAdd " + "createDiagnosis",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "diagnosisName:" + diagnosisName
        );
        return;
      }
      console.log("Res----", Res.data.data);
      let newDiag = [
        ...diagnosisSel,
        { diagnosis: Res.data.data.name, diagnosisName: diagnosisName },
      ];
      setDiagnosisSel(newDiag);
      document.getElementById("diagnosis_cons_input").value = "";
      document.getElementById("diagnosis_cons_input").blur();
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDiagnosisAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const validate = () => {
    let err = false;
    if (labList.length == 0 && labtestName != "") {
      setShowAddLab(true);
      err = true;
      document.getElementById("labtest_input").focus();
    } else {
      setShowLab(false);
    }
    if (medicines.length == 0) {
      setShowAddMedicine(true);
      err = true;
      document.getElementById("medicine_input").focus();
    } else {
      setShowMedicine(false);
    }
    if (diagnosisSel.length < 1) {
      setErrorDiagnosis(true);
      err = true;
      document.getElementById("diagnosis_cons_input").focus();
    } else {
      setErrorDiagnosis(false);
    }
    if (symptomSel.length == 0) {
      setErrorSymptom(true);
      document.getElementById("search_input").focus();
      err = true;
    } else {
      setErrorSymptom(false);
    }
    if (patientAgeTo === "") {
      setPatientAgeToError(true);
      document.getElementById("patientAgeTo").focus();
      err = true;
    }
    console.log("patientAgeFrom---", patientAgeFrom);
    if (patientAgeFrom === "") {
      setPatientAgeFromError(true);
      document.getElementById("patientAgeFrom").focus();
      err = true;
    }
    if (parseInt(patientAgeTo) < parseInt(patientAgeFrom)) {
      setPatientAgeToError(true);
      document.getElementById("patientAgeTo").focus();
      err = true;
    }
    if (templateName == "") {
      setTemplateNameError(true);
      document.getElementById("templateName").focus();
      err = true;
    } else {
      setTemplateNameError(false);
    }
    return err;
  };

  const handleSubmit = async () => {
    closeErrors();
    try {
      if (validate()) {
        return;
      }
      setLoad(true);
      let practitioners = [];
      const role = await storage.getRole();
      if (role != "SuperAdmin") {
        practitioners = [
          {
            practitioner: await storage.getID(),
          },
        ];
      }
      let items = [];
      console.log("labList---", labList);
      for (let i = 0; i < labList.length; i++) {
        items.push({
          type: "Lab Test Template",
          template: labList[i].name,
          qty: 1,
          instructions: labList[i].lab_test_comment,
        });
      }
      console.log("items----", items);
      let drugs = [];
      for (let i = 0; i < medicines.length; i++) {
        drugs.push({
          drug_code: medicines[i].drug_code,
          drug_name: medicines[i].drug_name,
          dosage: medicines[i].dosage,
          period: medicines[i].period,
          comment: medicines[i].comment,
          dosage_form: "Tablets",
          dosage_instruction: medicines[i].dosage_instruction,
        });
      }

      if (location.state) {
        const editRes = await doctorApi.editTreatmentTemplate(
          location.state.treatmentName,
          templateName,
          description,
          symptomSel,
          diagnosisSel,
          items,
          drugs,
          parseInt(patientAgeFrom),
          parseInt(patientAgeTo)
        );
        if (!editRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "editTreatmentTemplate",
            JSON.stringify(editRes),
            "We are facing some issues.Please try again after sometime.",
            "treatmentName:" +
              location.state.treatmentName +
              "templateName:" +
              templateName +
              "description:" +
              description +
              "symptomSel:" +
              symptomSel +
              "diagnosisSel:" +
              diagnosisSel +
              "items:" +
              items +
              "drugs:" +
              drugs +
              "patientAgeFrom:" +
              patientAgeFrom +
              "patientAgeTo:" +
              patientAgeTo
          );
          return;
        }
        console.log("editRes--------", editRes);
        setOpen(true);
        if (editRes.status != 200) {
          setMessage(
            JSON.parse(JSON.parse(editRes.data._server_messages)[0]).message
          );
          setLoad(false);
          return;
        }
      } else {
        const addRes = await doctorApi.addTreatmentTemplate(
          templateName,
          description,
          practitioners,
          symptomSel,
          diagnosisSel,
          items,
          drugs,
          parseInt(patientAgeFrom),
          parseInt(patientAgeTo)
        );
        console.log("addRes--------", addRes);
        if (!addRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "addTreatmentTemplate",
            JSON.stringify(addRes),
            "We are facing some issues.Please try again after sometime.",
            "templateName:" +
              templateName +
              "practitioners:" +
              practitioners +
              "symptomSel:" +
              symptomSel +
              "diagnosisSel:" +
              diagnosisSel +
              "items:" +
              items +
              "drugs:" +
              drugs +
              "patientAgeFrom:" +
              patientAgeFrom +
              "patientAgeTo:" +
              patientAgeTo
          );
          return;
        }

        setOpen(true);
        if (addRes.status != 200) {
          setMessage(
            JSON.parse(JSON.parse(addRes.data._server_messages)[0]).message
          );
          setLoad(false);
          return;
        }
      }
      setMessage("Saved Successfully");
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
        navigate("/treatmentTemplatelist");
      }, 3000);
      setLoad(false);
      return;
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const closeErrors = () => {
    setTemplateNameError(false);
    setErrorDiagnosis(false);
    setErrorSymptom(false);
    setShowAddMedicine(false);
    setShowAddLab(false);
    setShowConsump(false);
    setShowIntake(false);
    setShowPeriod(false);
    setShowLab(false);
    setPatientAgeFromError(false);
    setPatientAgeToError(false);
  };

  useEffect(() => {
    getDetails();
    document.getElementById("templateName") &&
      document.getElementById("templateName").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10 mb-5">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <h1 className="content-heading">Treatment Template</h1>
                <div className="row no-gutters">
                  <div
                    className="col-lg-9 border p-3"
                    style={{ borderRadius: "6px" }}
                  >
                    <div className="mt-3 row border p-2">
                      <div className="row no-gutters mt-3 form-group">
                        <label className="control-label col-lg-3">
                          <span className="star">*</span> Template Name :
                        </label>

                        <div className="col-lg-3">
                          <CustomTextInput
                            type="text"
                            name="templateName"
                            id="templateName"
                            maxlength="140"
                            className="form-control"
                            value={templateName}
                            onChange={(e) => {
                              closeErrors();
                              setTemplateName(e.target.value);
                            }}
                            placeholder="Template Name"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                          <ErrorMessage
                            error={"Please enter the Template Name"}
                            visible={templateNameError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-3">
                          Description :
                        </label>
                        <div className="col-lg-3">
                          <textarea
                            name="description"
                            id="description"
                            maxLength={140}
                            rows="4"
                            cols="70"
                            className="textArea"
                            value={description}
                            onChange={(e) => {
                              closeErrors();
                              setDescription(e.target.value);
                            }}
                            placeholder="Description"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-3">
                          <span className="star">*</span> Patient Age From :
                        </label>
                        <div className="col-lg-9">
                          <div className="row no-gutters">
                            <div className="col-lg-1">
                              <CustomTextInput
                                className="form-control"
                                type="number"
                                name="patientAgeFrom"
                                id="patientAgeFrom"
                                maxlength="140"
                                value={patientAgeFrom}
                                onChange={(e) => {
                                  closeErrors();
                                  e.target.value =
                                    e.target.value < 0
                                      ? ""
                                      : e.target.value > 100
                                      ? 100
                                      : e.target.value;
                                  setPatientAgeFrom(e.target.value);
                                }}
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleSubmit()
                                }
                                placeholder="Age"
                              />
                            </div>
                          </div>
                          <div className="row no-gutters">
                            <ErrorMessage
                              error={"Please enter the valid Patient Age From"}
                              visible={patientAgeFromError}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-3">
                          <span className="star">*</span> Patient Age To :
                        </label>
                        <div className="col-lg-9">
                          <div className="row no-gutters">
                            <div className="col-lg-1">
                              <CustomTextInput
                                type="number"
                                name="patientAgeTo"
                                id="patientAgeTo"
                                className="form-control"
                                value={patientAgeTo}
                                onChange={(e) => {
                                  closeErrors();
                                  e.target.value =
                                    e.target.value < 0
                                      ? ""
                                      : e.target.value > 100
                                      ? 100
                                      : e.target.value;
                                  setPatientAgeTo(e.target.value);
                                }}
                                placeholder="Age"
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleSubmit()
                                }
                              />
                            </div>
                          </div>
                          <div className="row no-gutters">
                            <ErrorMessage
                              error={"Please enter the valid Patient Age To"}
                              visible={patientAgeToError}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 row border p-2">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-3">
                          <span className="star">*</span> Symptoms :
                        </label>
                        <div className="col-lg-7">
                          <Multiselect
                            name="symptom"
                            options={symptom}
                            showArrow={true}
                            placeholder={"Symptom"}
                            selectedValues={symptomSel}
                            onSelect={handleSelectSymptom}
                            onRemove={handleSelectSymptom}
                            avoidHighlightFirstOption={true}
                            displayValue="complaintName"
                            customCloseIcon={
                              <span
                                className="icofont-close-circled"
                                style={{
                                  fontSize: "16px",
                                  marginTop: "3px",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            }
                            customArrow={
                              <span
                                className="icofont-search"
                                style={{
                                  fontSize: "15px",
                                }}
                              ></span>
                            }
                            emptyRecordMsg={
                              document.getElementById("search_input") &&
                              document.getElementById("search_input").value
                                .length > 2 ? (
                                <div>
                                  Do you want to add?&nbsp;&nbsp;&nbsp;
                                  <button
                                    className="btn-2"
                                    title="Add"
                                    onClick={(e) => {
                                      handleSymptomsAdd(
                                        document.getElementById("search_input")
                                          .value
                                      );
                                    }}
                                  >
                                    <span className="icofont icofont-plus"></span>
                                    Add
                                  </button>
                                </div>
                              ) : (
                                <div className="notFoundSearch">
                                  Type to search
                                </div>
                              )
                            }
                            onSearch={(e) => {
                              closeErrors();
                              handleSearchSymptoms(
                                document.getElementById("search_input").value
                              );
                            }}
                            showCheckbox={true}
                          />
                          <ErrorMessage
                            error={"Please enter the Symptom"}
                            visible={errorSymptom}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-3">
                          <span className="star">*</span> Disease / Condition :
                        </label>
                        <div className="col-lg-7">
                          <Multiselect
                            name="diagnosis"
                            id="diagnosis_cons"
                            options={diag}
                            showArrow={true}
                            placeholder={"Disease / Condition"}
                            selectedValues={diagnosisSel}
                            avoidHighlightFirstOption={true}
                            onSelect={handleSelectDiagnosis}
                            onRemove={handleSelectDiagnosis}
                            displayValue="diagnosisName"
                            customCloseIcon={
                              <span
                                className="icofont-close-circled"
                                style={{
                                  fontSize: "16px",
                                  marginTop: "3px",
                                  marginLeft: "5px",
                                }}
                              ></span>
                            }
                            customArrow={
                              <span
                                className="icofont-search"
                                style={{
                                  fontSize: "15px",
                                }}
                              ></span>
                            }
                            emptyRecordMsg={
                              document.getElementById("diagnosis_cons_input") &&
                              document.getElementById("diagnosis_cons_input")
                                .value.length > 2 ? (
                                <div>
                                  Do you want to add?&nbsp;&nbsp;&nbsp;
                                  <button
                                    className="btn-2"
                                    title="Add"
                                    onClick={(e) => {
                                      handleDiagnosisAdd(
                                        document.getElementById(
                                          "diagnosis_cons_input"
                                        ).value
                                      );
                                    }}
                                  >
                                    <span className="icofont icofont-plus"></span>
                                    Add
                                  </button>
                                </div>
                              ) : (
                                <div className="notFoundSearch">
                                  Type to search
                                </div>
                              )
                            }
                            onSearch={(e) => {
                              closeErrors();
                              handleSearchDiagnosis(
                                document.getElementById("diagnosis_cons_input")
                                  .value
                              );
                            }}
                            showCheckbox={true}
                          />
                          <ErrorMessage
                            error={"Please enter the Disease / Conditions"}
                            visible={errorDiagnosis}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="mt-4 row border p-2">
                      <div className="row no-gutters">
                        <h5>
                          <span className="star">*</span> Prescribed Medicines
                        </h5>
                        <div className="mb-2 pb-1">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th width="1%">No.</th>
                                <th>Medicine</th>
                                <th width="15%">Frequency</th>
                                <th width="15%">Before / After</th>
                                <th width="15%">Duration</th>
                                <th width="15%">Instruction</th>
                                <th width="5%">Update</th>
                              </tr>
                            </thead>
                            <tbody>
                              {medicines.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{item.drug_name}</td>
                                    <td>{item.dosage}</td>
                                    <td>{item.comment}</td>
                                    <td>{item.period}</td>
                                    <td>{item.dosage_instruction}</td>
                                    <td>
                                      <a
                                        className="pointer"
                                        onClick={() =>
                                          handleDeleteMedicine(item)
                                        }
                                      >
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td>{medicines.length + 1}</td>
                                <td>
                                  <Multiselect
                                    name="medicine"
                                    id="medicine"
                                    options={drugs}
                                    avoidHighlightFirstOption={true}
                                    placeholder={"Medicine"}
                                    showArrow={true}
                                    onSelect={handleSelectMedicine}
                                    onRemove={handleSelectMedicine}
                                    selectedValues={medicineSel}
                                    displayValue="item_name"
                                    selectionLimit={1}
                                    customCloseIcon={" "}
                                    hidePlaceholder={true}
                                    customArrow={
                                      <span
                                        className="icofont-search"
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      ></span>
                                    }
                                    emptyRecordMsg={
                                      document.getElementById(
                                        "medicine_input"
                                      ) &&
                                      document.getElementById("medicine_input")
                                        .value.length > 2 ? (
                                        <div className="notFoundAdd">
                                          Do you want to add?&nbsp;&nbsp;&nbsp;
                                          <button
                                            className="btn-2"
                                            title="Add"
                                            onClick={(e) => {
                                              setAddMedicineModal(true);
                                              closeErrors();
                                            }}
                                          >
                                            <span
                                              className="icofont icofont-plus mr-1"
                                              style={{ fontSize: "1em" }}
                                            ></span>
                                            Add
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="notFoundSearch">
                                          Type to search
                                        </div>
                                      )
                                    }
                                    onSearch={(e) => {
                                      closeErrors();
                                      setMedicineNameToAdd(
                                        document.getElementById(
                                          "medicine_input"
                                        ).value
                                      );
                                      handleSearchMedicine(
                                        document.getElementById(
                                          "medicine_input"
                                        ).value
                                      );
                                      handleSelectMedicine();
                                    }}
                                  />
                                  <ErrorMessage
                                    error={"Please enter Medicine"}
                                    visible={showMedicine}
                                  />
                                </td>
                                <td>
                                  <Select
                                    type="text"
                                    name="consumption"
                                    id="consumption"
                                    className={
                                      consump
                                        ? "form-control select-control"
                                        : "form-control select-control opacity"
                                    }
                                    options={consumption}
                                    value={consump}
                                    onChange={(e) => {
                                      closeErrors();
                                      setConsump(e.target.value);
                                    }}
                                    placeholder="Frequency"
                                  />
                                  <ErrorMessage
                                    error={"Please select Frequency"}
                                    visible={showConsump}
                                  />
                                </td>
                                <td>
                                  <Select
                                    type="text"
                                    name="intake"
                                    id="intake"
                                    className={
                                      intakes
                                        ? "form-control select-control"
                                        : "form-control select-control opacity"
                                    }
                                    options={comment}
                                    value={intakes}
                                    onChange={(e) => {
                                      closeErrors();
                                      setIntake(e.target.value);
                                    }}
                                    placeholder="Before / After"
                                  />
                                  <ErrorMessage
                                    error={"Please select Before / After"}
                                    visible={showIntake}
                                  />
                                </td>
                                <td>
                                  <Select
                                    type="text"
                                    name="period"
                                    id="period"
                                    className={
                                      period
                                        ? "form-control select-control"
                                        : "form-control select-control opacity"
                                    }
                                    options={periodOfDays}
                                    value={period}
                                    onChange={(e) => {
                                      closeErrors();
                                      setPeriod(e.target.value);
                                    }}
                                    placeholder="Duration"
                                  />
                                  <ErrorMessage
                                    error={"Please select Duration"}
                                    visible={showPeriod}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="dosage_instruction"
                                    id="dosage_instruction"
                                    maxLength={120}
                                    className="form-control"
                                    value={dosageInstruction}
                                    onChange={(e) => {
                                      closeErrors();
                                      setDosageInstruction(e.target.value);
                                    }}
                                    placeholder="Instruction"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleMedicine()
                                    }
                                  />
                                </td>
                                <td align="center">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={handleMedicine}
                                  >
                                    <span className="icofont icofont-plus"></span>
                                    Add
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {showAddMedicine && (
                            <ErrorMessage
                              error={"Please add Medicine to list"}
                              visible={showAddMedicine}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="mt-3 row border p-2">
                      <div className="row no-gutters">
                        <h5>Lab Tests</h5>
                        <div className="mb-2 ">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th width="1%">No.</th>
                                <th>Lab Tests</th>
                                <th width="45%">Comments</th>
                                <th width="5%">Update</th>
                              </tr>
                            </thead>
                            <tbody>
                              {labList.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>
                                      {item.lab_test_code} -{" "}
                                      {item.lab_test_name}
                                    </td>
                                    <td>{item.lab_test_comment}</td>
                                    <td>
                                      <a
                                        className="pointer"
                                        onClick={() => handleDeleteLab(item)}
                                      >
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td>{labList.length + 1}</td>
                                <td>
                                  <Multiselect
                                    name="labtest"
                                    id="labtest"
                                    options={lab}
                                    showArrow={true}
                                    avoidHighlightFirstOption={true}
                                    selectedValues={selectedLabList}
                                    placeholder={"Lab Test Name"}
                                    onSelect={handleSelectLab}
                                    onRemove={handleSelectLab}
                                    displayValue="lab_test_name"
                                    selectionLimit={1}
                                    customCloseIcon={" "}
                                    hidePlaceholder={true}
                                    customArrow={
                                      <span
                                        className="icofont-search"
                                        style={{
                                          fontSize: "15px",
                                        }}
                                      ></span>
                                    }
                                    emptyRecordMsg={
                                      document.getElementById(
                                        "labtest_input"
                                      ) &&
                                      document.getElementById("labtest_input")
                                        .value.length > 2 ? (
                                        <div className="notFoundAdd">
                                          Do you want to add?&nbsp;&nbsp;&nbsp;
                                          <button
                                            className="btn-2"
                                            title="Add"
                                            onClick={(e) => {
                                              setAddLabModal(true);
                                              closeErrors();
                                            }}
                                          >
                                            <span
                                              className="icofont icofont-plus mr-1"
                                              style={{
                                                fontSize: "1em",
                                              }}
                                            ></span>
                                            Add
                                          </button>
                                        </div>
                                      ) : (
                                        <div className="notFoundSearch">
                                          Type to search
                                        </div>
                                      )
                                    }
                                    onSearch={(e) => {
                                      closeErrors();
                                      setLabTestNameToAdd(
                                        document.getElementById("labtest_input")
                                          .value
                                      );
                                      handleSearchLab(
                                        document.getElementById("labtest_input")
                                          .value
                                      );
                                      handleSelectLab();
                                    }}
                                  />
                                  <ErrorMessage
                                    error={"Please enter Labtest Name"}
                                    visible={showLab}
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    name="patient"
                                    id="patient"
                                    maxLength={120}
                                    className="form-control"
                                    value={comments}
                                    onChange={(e) => {
                                      closeErrors();
                                      setComments(e.target.value);
                                    }}
                                    placeholder="Comments"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleSubmit()
                                    }
                                  />
                                </td>
                                <td align="center">
                                  <button
                                    type="button"
                                    className="btn"
                                    onClick={handleLabTest}
                                  >
                                    <span className="icofont icofont-plus"></span>
                                    Add
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                          {showAddLab && (
                            <div>
                              <ErrorMessage
                                error={"Please add Lab test to list"}
                                visible={showAddLab}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <ShowMessage view={open} Message={message} />
                    <div className="row mt-4">
                      <div className="text-center">
                        <button
                          className="btn ms-4"
                          title="Finish Consultation"
                          onClick={handleSubmit}
                        >
                          <span className="icofont-save"></span>Save Template
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <Modal show={addMedicineModal}>
                    <Modal.Header>
                      <Modal.Title>Add Medicine</Modal.Title>
                      <span
                        className="icofont-close-line pointer"
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                        onClick={(e) => {
                          setAddMedicineModal(false);
                        }}
                      ></span>
                    </Modal.Header>
                    <Modal.Body style={{ width: "550px" }}>
                      <div id="modelElement">
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-4">
                            <span className="star">*</span> Medicine Name:
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="medicineName"
                              id="medicineName"
                              maxlength="140"
                              className="form-control"
                              value={medicineNameToAdd}
                              onChange={(e) => {
                                setGenericNameError(false);
                                setMedicineNameError(false);
                                setMedicineNameToAdd(e.target.value);
                              }}
                              placeholder="Medicine Name"
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleMedicineAdd()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter Medicine Name"}
                              visible={medicineNameError}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-4">
                            <span className="star">*</span> Generic Name :
                          </label>
                          <div className="col-lg-8">
                            <CustomTextInput
                              type="text"
                              name="genericName"
                              id="genericName"
                              maxlength="140"
                              className="form-control"
                              value={genericNameToAdd}
                              onChange={(e) => {
                                setGenericNameError(false);
                                setMedicineNameError(false);
                                setGenericNameToAdd(e.target.value);
                              }}
                              placeholder="Medicine Name"
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleMedicineAdd()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter Generic Name"}
                              visible={genericNameError}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters App">
                          <ShowMessage
                            view={medicineSuccess}
                            Message={message}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn"
                        title="Close"
                        onClick={handleMedicineAdd}
                      >
                        <span className="icofont-save"></span>
                        Save
                      </button>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={addLabModal}>
                    <Modal.Header>
                      <Modal.Title>Lab Test</Modal.Title>
                      <span
                        className="icofont-close-line pointer"
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                        onClick={(e) => {
                          setAddLabModal(false);
                        }}
                      ></span>
                    </Modal.Header>
                    <Modal.Body style={{ width: "550px" }}>
                      <div id="modelElement">
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-4">
                            <span className="star">*</span> Lab Test Code :
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="labTestCode"
                              maxlength="50"
                              id="labTestCode"
                              className="form-control"
                              value={labTestCodeToAdd}
                              onChange={(e) => {
                                setLabTestCodeError(false);
                                setLabTestNameError(false);
                                setLabTestCodeToAdd(e.target.value);
                              }}
                              placeholder="lab Test Code"
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleLabTestAdd()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter lab Test Code"}
                              visible={labTestCodeError}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-4">
                            <span className="star">*</span> Lab Test Name:
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="labTestName"
                              maxlength="140"
                              id="labTestName"
                              className="form-control"
                              value={labTestNameToAdd}
                              onChange={(e) => {
                                setLabTestNameError(false);
                                setLabTestCodeError(false);
                                setLabTestNameToAdd(e.target.value);
                              }}
                              placeholder="lab Test Name"
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleLabTestAdd()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter lab Test Name"}
                              visible={labTestNameError}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters App">
                          <ShowMessage
                            view={labTestSuccess}
                            Message={message}
                          />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn"
                        title="Close"
                        onClick={handleLabTestAdd}
                      >
                        <span className="icofont-save"></span>
                        Save
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddTreatmentTemplate;
