import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";

import doctorRegApi from "../api/doctorRegApi";
import adminApi from "../api/adminApi";

import preFunction from "../component/common/CommonFunction";
import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import string from "../string";
import DoctorRegistration from "./DoctorRegistration";
import fileUpload from "../assests/svg/file_upload.svg";
// import pdfLogo from "../assests/jpg/pdflogo.jpg";
import DegreeCertificateIcon from "../assests/svg/degreecertificateicon.svg";
import MedicalCouncilIcon from "../assests/svg/medical_council.svg";
import SignatureIcon from "../assests/svg/signature.svg";
import IdcardIcon from "../assests/svg/idcard.svg";
import PlusCertificateIcon from "../assests/svg/pluscertificateicon.svg";

import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
import moment from "moment";
let base64data;
const prefixerror = "Education";

function Education() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [load, setLoad] = useState(false);
  const [selectedFiles1, setSelectedFiles1] = useState(fileUpload);
  const [selectedFiles2, setSelectedFiles2] = useState(fileUpload);
  const [selectedFiles3, setSelectedFiles3] = useState(fileUpload);
  const [selectedFiles4, setSelectedFiles4] = useState(fileUpload);
  const [showData1, setShowData1] = useState(false);
  const [showData2, setShowData2] = useState(false);
  const [showData3, setShowData3] = useState(false);
  const [showData4, setShowData4] = useState(false);
  const [fileType1, setFileType1] = useState();
  const [fileType3, setFileType3] = useState();
  const [fileType4, setFileType4] = useState();
  const [data1, setData1] = useState();
  const [data2, setData2] = useState();
  const [data3, setData3] = useState();
  const [data4, setData4] = useState();
  const [base64, setBase64] = useState(["", "", "", ""]);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [displayImage, setDisplayImage] = useState(SignatureIcon);
  const [CropOpen, setCropOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 280,
    height: 70,
    minwidth: 280,
    minheight: 70,
    aspect: 4 / 1,
  });
  const [image, setImage] = useState(null);
  const [openLetterModal, setOpenLetterModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [doctorData, setDoctorData] = useState([]);
  const [noOfFiles, setNoOfFiles] = useState(0);
  //#endregion

  const imageHandler = async (e, value) => {
    try {
      console.log(e.target.files[0].type);
      if (
        e.target.files[0].type !== "image/png" &&
        e.target.files[0].type !== "image/jpeg" &&
        e.target.files[0].type !== "application/pdf"
      ) {
        setOpen(true);
        setMessage("Kindly upload images and pdf files");
        setTimeout(() => {
          setOpen(false);
        }, 3000);
        return;
      }
      console.log(e.target.files[0].type, " upload");

      const filesArray = Array.from(e.target.files).map((file) => ({
        file: URL.createObjectURL(file),
        name: e.target.files[0].name.split(".")[1],
      }));

      const reader = new FileReader();
      var response;
      Array.from(e.target.files).map(async (file) => {
        response = await fetch(URL.createObjectURL(file));
        const imageBlob = await response.blob();
        reader.readAsDataURL(imageBlob);
      });

      reader.onloadend = async () => {
        base64data = reader.result;
        base64[value - 1] = base64data;
        console.log("base64--", base64);
        setBase64(base64);
        console.log("base64--", base64);
        let fileExtension = base64data.split(";")[0].split("/")[1];
        let fileName;
        if (fileExtension === "pdf") {
          fileName = "test.pdf";
          console.log(fileName);
        } else fileName = "test.jpg";
      };

      value === 1
        ? setSelectedFiles1(filesArray)
        : value === 2
        ? setSelectedFiles2(filesArray)
        : value === 3
        ? setSelectedFiles3(filesArray)
        : setSelectedFiles4(filesArray);

      Array.from(e.target.files).map(
        (file) => URL.revokeObjectURL(file) // avoid memory leak
      );

      value === 1
        ? setFileType1(e.target.files[0].type.split("/")[1])
        : value === 3
        ? setFileType3(e.target.files[0].type.split("/")[1])
        : setFileType4(e.target.files[0].type.split("/")[1]);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const selectImage = (file) => {
    console.log("---------file.type--", file.type);
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setShowData2(true);
      return;
    }
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      console.log("img.width---", img.width);
      console.log("img.height---", img.height);
      if (img.width < 200 || img.height < 70) {
        setShowData2(true);
        return;
      }
      setShowData2(false);
      setSrc(URL.createObjectURL(file));
      setOpenLetterModal(true);
    };
  };

  const cropImageNow = () => {
    if (crop.width === 0 || crop.height === 0) {
      setCropOpen(true);
      return;
    }
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setSelectedFiles2(base64Image);
    base64[1] = base64Image;
    setBase64(base64);
    setDisplayImage(base64Image);
    setOpenLetterModal(false);
  };

  const handleAddress = async (email, hospital, phone) => {
    try {
      const doctorAddressRes = await doctorRegApi.getAddress(email);
      console.log("doctorAddressRes--", doctorAddressRes);
      if (doctorAddressRes.data.data.length > 0) {
        const addressRes = await adminApi.getAddress(hospital);
        if (addressRes.data.data.length == 0) {
          const addAddressRes = await adminApi.addAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          if (!addAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "addAddress",
              JSON.stringify(addAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "phone:" +
                phone +
                "email:" +
                email +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
          console.log("addAddressRes--", addAddressRes);
        } else {
          const updateAddressRes = await adminApi.updateAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          if (!updateAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "updateAddress",
              JSON.stringify(updateAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "phone:" +
                phone +
                "email:" +
                email +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
          console.log("updateAddressRes--", updateAddressRes);
        }
      }
      return true;
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "handleAddress ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleServiceUnit = async (doctorid, hospital) => {
    console.log("handleServiceUnit doctorid---", doctorid);
    console.log("hospital---", hospital);
    let serviceUnitRes = await adminApi.getServiceUnit(doctorid);
    console.log("serviceUnitRes---", serviceUnitRes);
    let serviceUnitName;
    if (serviceUnitRes.data.data.length == 0) {
      console.log(hospital, doctorid);
      const createServiceUnit = await adminApi.createServiceUnit(
        hospital,
        doctorid
      );
      console.log("createServiceUnit---", createServiceUnit);
      if (!createServiceUnit.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "createServiceUnit",
          JSON.stringify(createServiceUnit),
          "We are facing some issues.Please try again after sometime.",
          "hospital:" + hospital + "doctorid:" + doctorid
        );
        return false;
      }
      serviceUnitName = createServiceUnit.data.data.name;
      const assignSchedule = await doctorRegApi.assignSchedule(
        doctorid,
        doctorid + " Schedule",
        serviceUnitName
      );
      console.log("assignSchedule---", assignSchedule);
      if (!assignSchedule.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "assignSchedule",
          JSON.stringify(assignSchedule),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
        );
        return false;
      }
      return true;
    } else {
      serviceUnitName = serviceUnitRes.data.data[0].name;
      const assignSchedule = await doctorRegApi.assignSchedule(
        doctorid,
        doctorid + " Schedule",
        serviceUnitName
      );
      if (!assignSchedule.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "assignSchedule",
          JSON.stringify(assignSchedule),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
        );
        return false;
      }
      console.log("assignSchedule---", assignSchedule);
      return true;
    }
    return false;
  };

  const handleActivate = async (data) => {
    try {
      setLoad(true);
      let service = false;
      console.log("data---", data);
      console.log("hospital---", data.hospital);
      let companyName = data.hospital;
      console.log("hospital---", data.hospital.indexOf("HLC-COM-"));
      if (data.hospital.indexOf("HLC-COM-") == -1) {
        console.log("here-----------");
        const companyRes = await doctorRegApi.createCompany(
          data.hospital,
          data.email
        );
        console.log("createcompanyRes---", companyRes);
        if (!companyRes.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "createCompany",
            JSON.stringify(companyRes),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + data.hospital
          );
          return false;
        }
        companyName = companyRes.data.data.name;
        if (companyRes.ok) {
          const fileres = await doctorRegApi.attachImage(data.email);
          console.log("certificateRes--", fileres);

          let letterhead = "";
          if (fileres.data.data.length > 0) {
            fileres.data.data.map((item, index) => {
              if (item.file_name.indexOf("-letterhead") != -1) {
                letterhead = fileres.data.data[index].file_url;
              }
            });
            if (letterhead != "") {
              const letterheadRes = await doctorRegApi.createLetterHead(
                companyName,
                letterhead
              );
              console.log("letterheadRes--", letterheadRes);
            }
          }

          service = handleServiceUnit(data.email, companyName);
        }
        const updateDoctor = await doctorRegApi.updateCompanyDoctor(
          data.email,
          companyName
        );
        console.log("updateDoctor---", updateDoctor);

        handleAddress(data.email, companyName, data.office_phone);

        const res1 = await doctorRegApi.userPermission(data.email, companyName);
        if (!res1.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "userPermission",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "email:" + data.email + "companyName:" + companyName
          );
          return;
        }
      } else {
        service = handleServiceUnit(location.state.id, data.hospital);
      }
      if (service) {
        handleStatus(data.mobile_phone);
        setShowSuccess(true);
        setLoad(false);
        setTimeout(() => {
          navigate("/doctorlist");
        }, 3000);
      }
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "handleActivate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleStatus = async (phone) => {
    try {
      const doctorid = location.state.id;
      console.log(" handleStatus doctorData---", doctorData);
      let approvalOn;
      if (doctorData.approval_on && doctorData.approval_on != "")
        approvalOn = doctorData.approval_on;
      else approvalOn = moment(new Date()).format("yyyy-MM-DD");
      console.log("approvalOn---", approvalOn);
      const changeres = await adminApi.changeStatus(
        doctorid,
        "Active",
        approvalOn
      );
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleStatus " + "changeStatus",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid
        );
        return;
      }
      console.log("changeres--", changeres);
      setMessage(`Activated Successfully`);
      console.log("phone--", phone);
      const messageRes = await adminApi.sendMessageToMobile(phone);
      if (!messageRes.ok) {
        ErrorLog(
          prefixerror + "handleStatus " + "sendMessageToMobile",
          JSON.stringify(messageRes),
          "We are facing some issues.Please try again after sometime.",
          "phone:" + phone
        );
        return;
      }
      console.log("mobile messageRes", messageRes);
      return;
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "handleStatus ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);
      const doctorID =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      console.log(doctorID);
      console.log("base64---", base64);
      let fileName;
      let noOfFilesLocal = noOfFiles;
      for (let index = 0; index < base64.length; index++) {
        if (base64[index] != "") {
          noOfFilesLocal++;
          fileName =
            index === 0
              ? doctorID +
                "-idproof." +
                base64[index].split(";")[0].split("/")[1]
              : index === 1
              ? doctorID + "-esign." + base64[index].split(";")[0].split("/")[1]
              : index === 2
              ? doctorID +
                "-degree." +
                base64[index].split(";")[0].split("/")[1]
              : index === 3
              ? doctorID +
                "-council." +
                base64[index].split(";")[0].split("/")[1]
              : "";
          console.log("fileName--", fileName);

          const response = await doctorRegApi.imageUpload(
            doctorID,
            fileName,
            base64[index]
          );
          console.log("imageUpload---", response);
          if (!response.ok) {
            ErrorLog(
              prefixerror + "handleSubmit " + "imageUpload",
              JSON.stringify(response),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" +
                doctorID +
                "fileName:" +
                fileName +
                "base64[index]:" +
                base64[index]
            );
            return;
          }
        }
      }
      setLoad(false);
      // }
      let prof = 84 + noOfFilesLocal * 4;
      console.log("prof---", prof);

      console.log("doctorData----", doctorData);
      if (!location.state || !location.state.id)
        await storage.storeProfileComplete(prof);
      let documentSubmitOn = "";
      if (noOfFilesLocal > noOfFiles) {
        if (
          doctorData.document_submit_on &&
          doctorData.document_submit_on != ""
        )
          documentSubmitOn = doctorData.document_submit_on;
        else documentSubmitOn = moment(new Date()).format("yyyy-MM-DD");
      }

      const profileCompRes = await doctorRegApi.updateProfileComplete(
        doctorID,
        prof,
        documentSubmitOn
      );
      console.log("profileCompRes---------------", profileCompRes);
      if (!profileCompRes.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "updateProfileComplete",
          JSON.stringify(profileCompRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID
        );
        return;
      }

      const status = profileCompRes.data.data.status;

      if (location.state && location.state.id) {
        if (profileCompRes.data.data.status === "Active") {
          setShowSuccess(true);
          setLoad(false);
          setTimeout(() => {
            navigate("/doctorlist");
          }, 3000);
        } else {
          handleActivate(profileCompRes.data.data);
        }
      } else {
        if (status !== "Active") {
          const mailRes = await doctorRegApi.sendApprovalEmail(name, doctorID);
          console.log("mailRes---", mailRes);
          if (!mailRes.ok) {
            ErrorLog(
              prefixerror + "handleSubmit " + "sendApprovalEmail",
              JSON.stringify(mailRes),
              "We are facing some issues.Please try again after sometime.",
              "name:" + name
            );
            return;
          }
        }
        setShowSuccess(true);
        setTimeout(() => {
          status === "Active"
            ? navigate("/doctordashboard")
            : navigate("/success");
        }, 3000);
      }
    } catch (error) {
      console.log(error, "Error");
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const getCertificates = async () => {
    try {
      const role = await storage.getRole();
      if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
        navigate("/");
        return;
      }
      console.log("base64---", base64);
      const doctorID =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      console.log("doctorID---", doctorID);
      setEmail(doctorID);
      const name = await storage.getName();
      setName(name);
      const res = await doctorRegApi.getDetilsBasicInfo(doctorID);
      console.log("doctor res-----------", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getBasicInformation " + "getDetilsBasicInfo",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID
        );
        return;
      }
      setDoctorData(res.data.data);
      const fileres = await doctorRegApi.attachImage(doctorID);
      if (!fileres.ok) {
        ErrorLog(
          prefixerror + "getCertificates " + "attachImage",
          JSON.stringify(fileres),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID
        );
        return;
      }
      console.log("fileres-----------------", fileres.data.data);
      let noOfFiles = 0;
      if (fileres.data.data.length > 0) {
        fileres.data.data.map((item, index) => {
          console.log("item==", item.file_name);
          if (item.file_name.indexOf("idproof") != -1) {
            noOfFiles++;
            console.log("id proof==", item);
            setData1(fileres.data.data[index].file_url);
            setFileType1(
              fileres.data.data[index].file_name.split(".")[
                fileres.data.data[index].file_name.split(".").length - 1
              ]
            );
          }
          if (item.file_name.indexOf("esign") != -1) {
            noOfFiles++;
            setData2(fileres.data.data[index].file_url);
          }
          if (item.file_name.indexOf("degree") != -1) {
            noOfFiles++;
            setData3(fileres.data.data[index].file_url);
            setFileType3(
              fileres.data.data[index].file_name.split(".")[
                fileres.data.data[index].file_name.split(".").length - 1
              ]
            );
          }
          if (item.file_name.indexOf("council") != -1) {
            noOfFiles++;
            setData4(fileres.data.data[index].file_url);
            setFileType4(
              fileres.data.data[index].file_name.split(".")[
                fileres.data.data[index].file_name.split(".").length - 1
              ]
            );
          }
        });
      }
      setNoOfFiles(noOfFiles);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "getCertificates ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getCertificates();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10 mb-5">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd">
                <div className="row mb-5">
                  <h1>Certificate Information</h1>
                </div>
                <div className="row">
                  <div className="col-lg-3 border">
                    <DoctorRegistration />
                  </div>
                  <div className="col-lg-9 border">
                    <div className="row p-lg-5">
                      <ShowMessage view={open} Message={message} />
                      <div className="mt-3 text-center imageupload">
                        {data1 ? (
                          <div
                            className="row no-gutters pointer"
                            onClick={() =>
                              window.open(string.testbaseUrl + data1, "_blank")
                            }
                          >
                            <div className="col-lg-2">
                              <img
                                src={
                                  fileType1 === "pdf"
                                    ? IdcardIcon
                                    : string.testbaseUrl + data1
                                }
                                alt={string.websiteName + " Files"}
                                id="img"
                                height="50"
                                width="120"
                              />
                            </div>
                            <div className="col-lg-8 pt-4 text-left">
                              <b>ID Proof (Aadhar/PAN)</b>
                            </div>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file1"
                              accept="image/*, .pdf"
                              onChange={(e) => {
                                imageHandler(e, 1);
                                setShowData1(false);
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="file1" className="row text-center">
                              <div className="row no-gutters">
                                <div className="col-2">
                                  <div className="app image">
                                    <div className="label-holder">
                                      <img
                                        src={
                                          fileType1 === "pdf"
                                            ? IdcardIcon
                                            : selectedFiles1[0].file
                                            ? selectedFiles1[0].file
                                            : IdcardIcon
                                        }
                                        alt={string.websiteName + " Files"}
                                        id="img"
                                        className="mb-3 text-center img"
                                        htmlFor="input"
                                        height="50"
                                        width="120"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 text-left pt-4 ps-3">
                                  <b>ID Proof (Aadhar/PAN)</b>
                                  <br />
                                  <ErrorMessage
                                    error={"Upload valid ID Proof (jpg/pdf)"}
                                    visible={showData1}
                                  />
                                </div>
                                <div className="col-2 pt-3">
                                  {fileType1 ? (
                                    <button className="btn-3">
                                      <span className="icofont-pencil"></span>
                                      Edit
                                    </button>
                                  ) : (
                                    <img src={PlusCertificateIcon} />
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      </div>
                      <div className="mt-3 text-center imageupload">
                        {data3 ? (
                          <div
                            className="row no-gutters pointer"
                            onClick={() =>
                              window.open(string.testbaseUrl + data3, "_blank")
                            }
                          >
                            <div className="col-lg-2">
                              <img
                                src={
                                  fileType3 === "pdf"
                                    ? DegreeCertificateIcon
                                    : string.testbaseUrl + data3
                                }
                                alt={string.websiteName + " Files"}
                                id="img"
                                height="50"
                                width="120"
                              />
                            </div>
                            <div className="col-lg-8 pt-4 text-left">
                              <b>Degree Certificate</b>
                            </div>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file3"
                              accept="image/*, .pdf"
                              onChange={(e) => {
                                imageHandler(e, 3);
                                setShowData3(false);
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="file3" className="row text-center">
                              <div className="row no-gutters">
                                <div className="col-2">
                                  <div className="app image">
                                    <div className="label-holder">
                                      <img
                                        src={
                                          fileType3 === "pdf"
                                            ? DegreeCertificateIcon
                                            : selectedFiles3[0].file
                                            ? selectedFiles3[0].file
                                            : DegreeCertificateIcon
                                        }
                                        alt={string.websiteName + " Files"}
                                        id="img"
                                        className="mb-3 text-center img"
                                        htmlFor="input"
                                        height="50"
                                        width="120"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 text-left pt-4 ps-3">
                                  <b>Degree Certificate</b>
                                  <br />
                                  <ErrorMessage
                                    error={
                                      "Upload valid Degree Certificate (jpg/pdf)"
                                    }
                                    visible={showData3}
                                  />
                                </div>
                                <div className="col-2 pt-3">
                                  {fileType3 ? (
                                    <button className="btn-3">
                                      <span className="icofont-pencil"></span>
                                      Edit
                                    </button>
                                  ) : (
                                    <img src={PlusCertificateIcon} />
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      </div>
                      <div className="mt-3 text-center imageupload">
                        {data4 ? (
                          <div
                            className="row no-gutters pointer"
                            onClick={() =>
                              window.open(string.testbaseUrl + data4, "_blank")
                            }
                          >
                            <div className="col-lg-2">
                              <img
                                src={
                                  fileType4 === "pdf"
                                    ? MedicalCouncilIcon
                                    : string.testbaseUrl + data4
                                }
                                alt={string.websiteName + " Files"}
                                id="img"
                                height="50"
                                width="120"
                              />
                            </div>
                            <div className="col-lg-9 text-left pt-4">
                              <b>Medical Council Registration Certificate</b>
                            </div>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file4"
                              accept="image/*, .pdf"
                              onChange={(e) => {
                                imageHandler(e, 4);
                                setShowData4(false);
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="file4" className="row text-center">
                              <div className="row no-gutters">
                                <div className="col-2">
                                  <div className="app image">
                                    <div className="label-holder">
                                      <img
                                        src={
                                          fileType4 === "pdf"
                                            ? MedicalCouncilIcon
                                            : selectedFiles4[0].file
                                            ? selectedFiles4[0].file
                                            : MedicalCouncilIcon
                                        }
                                        alt={string.websiteName + " Files"}
                                        id="img"
                                        className="mb-3 text-center img"
                                        htmlFor="input"
                                        height="50"
                                        width="120"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 text-left pt-4 ps-3">
                                  <b>
                                    Medical Council Registration Certificate
                                  </b>
                                  <br />
                                  <ErrorMessage
                                    error={
                                      "Upload valid Medical Council Registration Certificateis (jpg/pdf)"
                                    }
                                    visible={showData4}
                                  />
                                </div>
                                <div className="col-2 pt-3">
                                  {fileType4 ? (
                                    <button className="btn-3">
                                      <span className="icofont-pencil"></span>
                                      Edit
                                    </button>
                                  ) : (
                                    <img src={PlusCertificateIcon} />
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      </div>
                      <div className="mt-3 text-center imageupload">
                        {data2 ? (
                          <div
                            className="row no-gutters pointer"
                            onClick={() =>
                              window.open(string.testbaseUrl + data2, "_blank")
                            }
                          >
                            <div className="col-lg-2">
                              <img
                                src={string.testbaseUrl + data2}
                                alt={string.websiteName + " Files"}
                                id="img"
                                height="50"
                                width="120"
                              />
                            </div>
                            <div className="col-lg-8 pt-4 text-left">
                              <b>Signature</b>
                              <br />
                              <ErrorMessage
                                error={
                                  "Upload valid Signature (280X70)(jpg/pdf)"
                                }
                                visible={showData2}
                              />
                            </div>
                          </div>
                        ) : (
                          <>
                            <input
                              type="file"
                              id="file2"
                              accept="image/*, .pdf"
                              onChange={(e) => {
                                selectImage(e.target.files[0]);
                              }}
                              onClick={(e) => {
                                e.target.value = "";
                              }}
                              style={{ display: "none" }}
                            />
                            <label htmlFor="file2" className="row text-center">
                              <div className="row no-gutters">
                                <div className="col-2">
                                  <div className="app image">
                                    <div className="">
                                      <div
                                        className="label-holder"
                                        style={{ verticalAlign: "middle" }}
                                      >
                                        <img
                                          src={displayImage}
                                          alt={string.websiteName + " Files"}
                                          id="img"
                                          className="mb-3 img"
                                          htmlFor="input"
                                          height="50"
                                          width="120"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-8 pt-4 ps-3 text-left">
                                  <b>Signature</b>
                                  <br />
                                  <ErrorMessage
                                    error={
                                      "Upload valid Signature (280X70)(jpg/pdf)"
                                    }
                                    visible={showData2}
                                  />
                                </div>

                                <div className="col-2 pt-3">
                                  {displayImage == SignatureIcon ? (
                                    <img src={PlusCertificateIcon} />
                                  ) : (
                                    <button className="btn-3">
                                      <span className="icofont-pencil"></span>
                                      Edit
                                    </button>
                                  )}
                                </div>
                              </div>
                            </label>
                          </>
                        )}
                      </div>
                    </div>
                    <ShowMessage
                      view={showSuccess}
                      Message={"Updated successfully"}
                    />
                    <div className="text-center mt-4 mb-5">
                      <button
                        className="btn"
                        type="submit"
                        title=" Submit"
                        onClick={handleSubmit}
                      >
                        <span className="icofont-save"></span>Save
                      </button>
                    </div>
                  </div>
                </div>
                <div className="row"></div>
              </div>
            </div>
            <>
              <Modal className="modalwidth" show={openLetterModal}>
                <Modal.Body>
                  <div style={{ width: "800px", height: "100%" }}>
                    {src && (
                      <div>
                        <ReactCrop
                          src={src}
                          onImageLoaded={setImage}
                          crop={crop}
                          onChange={setCrop}
                          minHeight={70}
                          minWidth={280}
                          // maxHeight={70}
                          // maxWidth={200}
                          keepSelection={true}
                          onComplete={(crop, pixel) => {
                            console.log(crop, pixel);
                          }}
                        />
                        <br />
                      </div>
                    )}
                  </div>
                  <ErrorMessage
                    error={"Click on image to crop"}
                    visible={CropOpen}
                  />
                </Modal.Body>
                <Modal.Footer>
                  <button className="btn" onClick={cropImageNow} title="Crop">
                    <span className="icofont-crop"></span>Crop
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          </div>
        </div>
      </section>
    </>
  );
}

export default Education;
