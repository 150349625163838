import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import CustomTextInput from "../component/common/CustomTextInput";
import ShowMessage from "../component/common/ShowMessage";
import preFunction from "../component/common/CommonFunction";
import ErrorMessage from "../component/common/ErrorMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import Select from "../component/common/select";

const prefixerror = "NewPatient";
const statusArr = [
  {
    id: "In Progress",
    name: "In Progress",
  },
  {
    id: "Completed",
    name: "Completed",
  },
  {
    id: "Cancelled",
    name: "Cancelled",
  },
];

function UpdateDemo() {
  //#region const
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [load, setLoad] = useState(false);
  const [name, setName] = useState("");
  const [status, setStatus] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [showMobile, setShowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  //#endregion

  const getDemo = async () => {
    try {
      if (location.state && location.state.id !== "") {
        setLoad(true);
        const res1 = await adminApi.getDemoById(location.state.id);
        if (!res1.ok) {
          ErrorLog(
            prefixerror + " getDemo " + "getPatientById",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "id:" + location.state.id
          );
          return;
        }
        console.log("res1---", res1);
        setName(res1.data.data.names);
        setEmail(res1.data.data.email);
        setMobile(res1.data.data.mobile_number);
        setComment(res1.data.data.comments);
        setStatus(res1.data.data.status);
      }
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getDemo ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSubmit = async () => {
    try {
      setShowEmail(false);
      setShowMobile(false);
      let err = false;
      if (email && email != "" && !preFunction.isEmail(email)) {
        setShowEmail(true);
        err = true;
      }
      if (mobile && mobile != "" && !preFunction.isMobile(mobile)) {
        setShowMobile(true);
        err = true;
      }
      if (err) return;

      const updateRes = await adminApi.updateDemoRequest(
        location.state.id,
        name,
        email,
        mobile,
        status,
        comment
      );
      console.log("updateRes---", updateRes);

      if (!updateRes.ok) {
        ErrorLog(
          prefixerror + " handleSubmit " + "updateDemoRequest",
          JSON.stringify(updateRes),
          "We are facing some issues.Please try again after sometime.",
          "id:" +
            location.state.id +
            "name:" +
            name +
            "email:" +
            email +
            "mobile:" +
            mobile +
            "status:" +
            status +
            "comment:" +
            comment
        );
        return;
      }
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getDemo();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row mb-4" onClick={preFunction.hideNavbar}>
            <div className="pd mb-4">
              <div className="row">
                <h1>Update Demo Request</h1>
              </div>
              <div className="row" id="">
                <div className="col-lg-9">
                  <div className="">
                    <ShowMessage
                      view={open}
                      Message="Demo Request updated Successfully"
                    />
                    <div id="formId" className="showDiv">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">Name :</label>
                        <div className="col-sm-4">
                          <CustomTextInput
                            type="text"
                            name="firstname"
                            id="firstname"
                            maxLength={40}
                            className="form-control"
                            value={name}
                            onChange={(e) => {
                              setName(
                                preFunction.capitalizeFirst(e.target.value)
                              );
                            }}
                            placeholder="Name"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          Mobile :
                        </label>
                        <div className="col-sm-3">
                          <CustomTextInput
                            type="tel"
                            name="mobile"
                            id="mobile"
                            maxlength="10"
                            value={mobile}
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                            className="form-control"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            placeholder="Mobile"
                            onClick={() => setOpen(false)}
                          />
                          <ErrorMessage
                            error={"Please enter the valid Mobile number"}
                            visible={showMobile}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">Email:</label>
                        <div className="col-sm-4">
                          <CustomTextInput
                            type="email"
                            name="email"
                            id="email"
                            maxLength={80}
                            className="form-control"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            placeholder="Email "
                            onClick={() => setOpen(false)}
                          />
                          <ErrorMessage
                            error={"Please enter the valid Email"}
                            visible={showEmail}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          Comment :
                        </label>
                        <div className="col-sm-4">
                          <textarea
                            name="comment"
                            id="comment"
                            rows="4"
                            cols="80"
                            className="textArea"
                            value={comment}
                            onChange={(e) => {
                              setComment(e.target.value);
                            }}
                            placeholder="Comment"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          Status :
                        </label>
                        <div className="col-sm-4">
                          <Select
                            name="regStatus"
                            id="regstatus"
                            options={statusArr}
                            value={status}
                            placeholder="Status"
                            className={
                              status
                                ? "form-control select-control"
                                : "form-control select-control opacity"
                            }
                            onChange={(e) => {
                              setStatus(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters mb-4">
                        <span className="col-sm-3"></span>
                        <div className="col-sm-9">
                          <button
                            type="submit"
                            onClick={(e) => handleSubmit()}
                            className="btn ms-3 mt-2"
                            title="Save"
                          >
                            <span className="icofont-save"></span>
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdateDemo;
