import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import { months } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";
import Select from "../component/common/select";

import storage from "../auth/storage";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";

import "react-datepicker/dist/react-datepicker.css";

const prefixerror = "ReportDoctor";
const statusArr = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "Active",
    name: "Active",
  },
  {
    id: "Disabled",
    name: "Disabled",
  },
];
const regStatusArr = [
  {
    id: "All",
    name: "All",
  },
  {
    id: "In Progress",
    name: "In Progress",
  },
  {
    id: "Completed",
    name: "Completed",
  },
  {
    id: "Cancelled",
    name: "Cancelled",
  },
];

function ReportDoctor() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const todyaDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todyaDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todyaDate);
  const [csvData, setCsvData] = useState([]);
  const [status, setStatus] = useState("All");
  const [regStatus, setRegStatus] = useState("All");
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear() + 2, 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const getDoctorList = async () => {
    try {
      setLoad(true);
      setData([]);
      const role = await storage.getRole();
      if (role !== "SuperAdmin") {
        navigate("/logout");
        return;
      }
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      console.log("From:" + from + " to:" + to);
      const res = await adminApi.getDoctorReport(from, to, regStatus, status);
      console.log("res==", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDoctorList " + "getDoctorReport",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + "limitStart:0" + "pageSize:30"
        );
        return;
      }
      let csvData = [
        [
          "No",
          "Doctor Name",
          "Clinic Id",
          "Clinic Name",
          "Place",
          "Registered Date",
          "Document Submitted Date",
          "Profile %",
          "Status",
          "Registration Status",
          "Approval On",
          "Approval/Cancel Days",
          "First Appointment On",
          "No of Appointment",
          "No of Appointment Completed",
          "Comment",
        ],
      ];
      if (res.data.message.length > 0) {
        res.data.message.map((item, index) => {
          csvData[index + 1] = [
            index + 1,
            item.practitioner_name,
            item.hospital && item.hospital.indexOf("HLC-") != -1
              ? item.hospital
              : "",
            item.company_name,
            item.state,
            item.creation && moment(item.creation).format("DD-MM-yyyy"),
            item.document_submit_on &&
              moment(item.document_submit_on).format("DD-MM-yyyy"),
            item.profile_completeness,
            item.status,
            item.registration_status,
            item.approval_on && moment(item.approval_on).format("DD-MM-yyyy"),
            item.approval_days,
            item.first_appointment &&
              moment(item.first_appointment).format("DD-MM-yyyy"),
            item.no_of_appointment,
            item.appointment_completed,
            item.comments,
          ];
        });
        setCsvData(csvData);
      }
      setData(res.data.message);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getDoctorList ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
      console.log(error);
    }
  };

  const handleViewDoctor = (doctorid) => {
    navigate("/viewdoctor", { state: { doctorid } });
  };

  useEffect(() => {
    getDoctorList();
  }, [fromDate, toDate, status, regStatus]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="row no-gutters">
              <div className="mb-4">
                <div className="pd">
                  <div>
                    <h1 className="content-heading mb-4">Doctor Report</h1>
                  </div>
                  <div className="row mb-5">
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">From :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="fromDate"
                          id="fromDate"
                          openToDate={fromDate}
                          selected={fromDate}
                          onChange={(date) => {
                            setFromDate(date);
                            // getDoctorList(date, toDate);
                          }}
                          minDate={new Date("2022-01-01")}
                          maxDate={toDate}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">To :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="toDate"
                          id="toDate"
                          openToDate={toDate}
                          selected={toDate}
                          onChange={(date) => {
                            setToDate(date);
                            // getDoctorList(fromDate, date);
                          }}
                          minDate={fromDate}
                          maxDate={new Date(date.getFullYear() + 2 + "-12-31")}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <label className="col-lg-3 mt-2 p-0">Status :</label>
                      <div className="col-lg-9 p-0">
                        <Select
                          type="text"
                          name="Status"
                          id="status"
                          options={statusArr}
                          value={status}
                          placeholder="Status"
                          className={
                            status
                              ? "form-control select-control"
                              : "form-control select-control opacity"
                          }
                          onChange={(e) => {
                            setStatus(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3">
                      <label className="col-lg-4 mt-2 p-0">Reg. Status :</label>
                      <div className="col-lg-8 p-0">
                        <Select
                          name="regStatus"
                          id="regstatus"
                          options={regStatusArr}
                          value={regStatus}
                          placeholder="Status"
                          className={
                            regStatus
                              ? "form-control select-control"
                              : "form-control select-control opacity"
                          }
                          onChange={(e) => {
                            setRegStatus(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    {data.length > 0 && (
                      <div
                        className="col-lg-2 p-0"
                        style={{ textAlign: "right" }}
                      >
                        <br />
                        <CSVLink
                          filename={
                            "Doctor Report " +
                            moment(fromDate).format("DD-MM-yyyy") +
                            " to " +
                            moment(toDate).format("DD-MM-yyyy") +
                            ".csv"
                          }
                          data={csvData}
                        >
                          <button className="btn-2">
                            <span className="icofont icofont-download"></span>
                            Export Report
                          </button>
                        </CSVLink>
                      </div>
                    )}
                  </div>
                  <div id="report" className="mt-4">
                    <div className="row border p-3">
                      <div className="row totcntstyle">
                        A total of {data.length} doctor identified.
                      </div>
                      {data.length > 0 ? (
                        <div className="table-responsive">
                          <table className="table table-bordered ">
                            <thead>
                              <tr>
                                <th width="1%">No</th>
                                <th>Name</th>
                                <th>Clinic ID</th>
                                <th>Clinic Name</th>
                                <th>Place</th>
                                <th>Reg. On</th>
                                <th>Doc. Sub. On</th>
                                <th>Profile %</th>
                                <th>Status</th>
                                <th>Reg.Status</th>
                                <th>Approval On</th>
                                <th>Days to Approval/Cancel</th>
                                <th>Ist Appointment On</th>
                                <th>No of Appointment</th>
                                <th>No of Appointment Completed</th>
                                <th>Comment</th>
                                <th>View</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.practitioner_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.hospital &&
                                      item.hospital.indexOf("HLC-") != -1
                                        ? item.hospital
                                        : ""}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.company_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.state}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {moment(item.creation).format(
                                        "DD-MM-yyyy"
                                      )}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.document_submit_on &&
                                        moment(item.document_submit_on).format(
                                          "DD-MM-yyyy"
                                        )}
                                    </td>
                                    <td>{item.profile_completeness}</td>
                                    <td>{item.status}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.registration_status}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.approval_on &&
                                        moment(item.approval_on).format(
                                          "DD-MM-yyyy"
                                        )}
                                    </td>
                                    <td>{item.approval_days}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.first_appointment &&
                                        moment(item.first_appointment).format(
                                          "DD-MM-yyyy"
                                        )}
                                    </td>
                                    <td>{item.no_of_appointment}</td>
                                    <td>{item.appointment_completed}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.comments}
                                    </td>
                                    <td>
                                      <button
                                        type="submit"
                                        className="btn-3"
                                        title="View Prescription"
                                        onClick={() =>
                                          handleViewDoctor(item.name)
                                        }
                                      >
                                        <span className="icofont-prescription"></span>
                                        View
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportDoctor;
