import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import moment from "moment";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import Select from "../component/common/select";
import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";
import preFunction from "../component/common/CommonFunction";

import DoctorNavBar from "./DoctorNavBar";
import DoctorHeader from "./DoctorHeader";
import storage from "../auth/storage";

const prefixerror = "Invoice";

function Invoice() {
  //#region const
  const location = useLocation();
  const navigate = useNavigate();
  const [patient, setPatient] = useState([]);
  const [load, setLoad] = useState(false);
  const [inputs, setInputs] = useState();
  const [fees, setFees] = useState();
  const [showFee, setShowFee] = useState(false);
  const [appdate, setAppDate] = useState();
  const [invoices, setInvoices] = useState([]);
  const [open, setOpen] = useState(false);
  const [showErrorItem, setShowErrorItem] = useState(false);
  const [message, setMessage] = useState("");
  const [searchPatient, setSearchPatient] = useState("");
  const [nameError, setNameError] = useState(false);
  const [consultationName, setConsultationName] = useState();
  const [patientSel, setPatientSel] = useState([]);
  const [itemArr, setItemArr] = useState([]);
  const [item, setItem] = useState();
  const [showAddError, setShowAddError] = useState(false);
  const [total, setTotal] = useState(0);
  // const date = new Date();
  const [errorOpen, setErrorOpen] = useState(false);
  const [totalArray, setTotalArray] = useState([]);
  //#endregion

  const handleDeleteInvoice = (item) => {
    setLoad(true);
    const tot = parseInt(total) - parseInt(item.itemFees);
    setTotal(tot);
    const data = invoices.filter((m) => m !== item);
    let arr = totalArray.filter((e) => e.item_name == item.itemName);
    let newList = itemArr;
    newList[itemArr.length] = arr[0];
    let i, j;
    for (i = 0; i < newList.length; i++) {
      for (j = 1; j < newList.length; j++) {
        if (parseInt(newList[i].position) > parseInt(newList[j].position)) {
          let temp = newList[i];
          newList[i] = newList[j];
          newList[j] = temp;
        }
      }
    }
    setItemArr(newList);
    setInvoices(data);
    setLoad(false);
  };

  const handleSelectPatient = (e) => {
    setPatient([]);
    console.log(e, "e");
    for (let i = 0; i < e.length; i++) {
      e[i].patient = e[i].patient.split("-")[0];
    }
    setPatientSel(e);
    setInputs(e[0].id);
    setSearchPatient(e[0].id);
    document.getElementById("patient_input").blur();
  };

  const handleSearchPatient = async (value) => {
    try {
      closeErrors();
      console.log("value---", value);
      setLoad(true);
      setPatient([]);
      if (value.length > 2) {
        const res = await doctorApi.getSearchPatient(value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchPatient " + "getSearchPatient",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        console.log("searchRes--", res.data.data);

        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              patient:
                res.data.data[i].patient_name +
                " - " +
                moment().diff(res.data.data[i].dob, "years") +
                " years - " +
                res.data.data[i].mobile,
            };
            myObject["id"] = res.data.data[i].name;
            arr.push(myObject);
          }
          setPatient(arr);
        }
      } else {
        setPatient([]);
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSearchPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const closeErrors = () => {
    setNameError(false);
    setShowFee(false);
    setShowErrorItem(false);
    setShowAddError(false);
    setShowErrorItem(false);
  };

  const handleOutPatientCharge = async (value) => {
    try {
      const doctorEmail = location.state.practitioner; // await storage.getOwner();
      console.log("item-----", value);
      setFees("");
      if (value == "Out-Patient Consulting charge") {
        const itemRes = await doctorRegApi.getDetilsBasicInfoByAdmin(
          doctorEmail
        );
        if (!itemRes.ok) {
          ErrorLog(
            prefixerror + "handleOutPatientCharge " + "getDetilsBasicInfo",
            JSON.stringify(itemRes),
            "We are facing some issues.Please try again after sometime.",
            "doctorEmail:" + doctorEmail
          );
          return;
        }
        console.log("itemRes----", itemRes);
        setFees(itemRes.data.data.op_consulting_charge);
      }
    } catch (error) {
      ErrorLog(
        prefixerror + "handleOutPatientCharge ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleAdd = async () => {
    console.log("item---", item);
    console.log("fee---", fees);

    closeErrors();
    let err = false;
    if (!item) {
      setShowErrorItem(true);
      err = true;
    }
    if (!fees && fees == 0) {
      setShowFee(true);
      err = true;
    }
    if (err) {
      return;
    }
    const tot = parseInt(total) + parseInt(fees);
    setTotal(tot);
    let inv = [
      ...invoices,
      {
        itemName: item,
        itemFees: fees,
      },
    ];
    let arr = itemArr.filter((e) => e.item_name != item);
    setItemArr(arr);
    console.log("inv---", arr, inv);
    setInvoices(inv);
    setItem("");
    setFees("");
    document.getElementById("item").focus();
  };

  const handleSubmit = async () => {
    closeErrors();
    console.log("handleSubmit", inputs, appdate, fees);
    let err = false;
    if (!inputs) {
      setNameError(true);
      document.getElementById("patient").className += " form-control-error";
      document.getElementById("patient").focus();
      err = true;
    } else {
      setNameError(false);
    }
    if (invoices.length == 0) {
      setShowAddError(true);
      err = true;
    } else {
      setShowAddError(false);
    }
    if (err) {
      return;
    }

    try {
      setLoad(true);
      const company = await storage.getCompany();
      const doctorEmail = location.state.practitioner; //await storage.getOwner();
      console.log("company---------", company);
      console.log("invoices---------", invoices);
      const companyRes = await doctorApi.getCompanyInfo(company);
      console.log("companyRes---", companyRes);

      if (!companyRes.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "getCompanyInfo",
          JSON.stringify(companyRes),
          "We are facing some issues.Please try again after sometime.",
          "company:" + company
        );
        return;
      }

      let items = [];
      for (let i = 0; i < invoices.length; i++) {
        var obj = {
          item_code: invoices[i].itemName,
          qty: "1",
          rate: invoices[i].itemFees,
          amount: invoices[i].itemFees,
          cost_center: companyRes.data.data.round_off_cost_center,
        };
        items.push(obj);
      }
      console.log("items-----", items);

      const invoiceRes = await doctorApi.createInvoice(
        searchPatient,
        company,
        inputs,
        doctorEmail,
        items,
        consultationName
        // moment(new Date()).format("yyyy-MM-DD")
      );
      console.log("invoiceRes--", invoiceRes);
      if (!invoiceRes.ok) {
        setMessage("There is problem with invoice creation");
        setErrorOpen(true);
        setTimeout(() => {
          setErrorOpen(false);
        }, 3000);
        setLoad(false);

        ErrorLog(
          prefixerror + "handleSubmit " + "createInvoice",
          JSON.stringify(invoiceRes),
          "We are facing some issues.Please try again after sometime.",
          "searchPatient:" +
            searchPatient +
            "company:" +
            company +
            "inputs:" +
            inputs +
            "doctorEmail:" +
            doctorEmail +
            "items:" +
            items +
            "consultationName:" +
            consultationName
        );
        return;
      }
      const payRes = await doctorApi.createPaymentEntry(
        company,
        company,
        searchPatient,
        parseInt(total),
        invoiceRes.data.data.name,
        companyRes.data.data.default_cash_account
      );
      console.log("payRes--", payRes);
      if (!payRes.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "createPaymentEntry",
          JSON.stringify(payRes),
          "We are facing some issues.Please try again after sometime.",
          "company:" +
            company +
            "searchPatient:" +
            searchPatient +
            "invoiceRes name:" +
            invoiceRes.data.data.name +
            "default_cash_account:" +
            companyRes.data.data.default_cash_account
        );
        return;
      }

      if (consultationName) {
        const consultationRes = await doctorApi.savePatientConsultation(
          consultationName
        );
        console.log("consultationRes---", consultationRes);
        if (!consultationRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "savePatientConsultation",
            JSON.stringify(consultationRes),
            "We are facing some issues.Please try again after sometime.",
            "consultationName:" + consultationName
          );
          return;
        }
      }

      navigate("/viewInvoice", {
        state: { invoiceId: invoiceRes.data.data.name },
      });
      return;
    } catch (error) {
      console.log("exception", error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getPatient = async () => {
    try {
      console.log(location.state, "inputs");

      setLoad(true);
      setAppDate(preFunction.getTodayDate());

      if (location.state && location.state.fullName !== "") {
        console.log(location.state);
        setSearchPatient(location.state.patientId);
        setInputs(location.state.patientId);
        setConsultationName(location.state.consultationName);
        var obj = [
          {
            id: location.state.patientId,
            patient: location.state.fullName,
          },
        ];
        setPatientSel(obj);
      }
      const itemRes = await doctorApi.getInvoiceItems();
      if (!itemRes.ok) {
        ErrorLog(
          prefixerror + "getPatient " + "getInvoiceItems",
          JSON.stringify(itemRes),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      for (let i = 0; i < itemRes.data.data.length; i++) {
        itemRes.data.data[i].position = i + 1;
      }
      console.log("itemRes.data.data---", itemRes.data.data);
      setItemArr(itemRes.data.data);

      setTotalArray(itemRes.data.data);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + "getPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getPatient();
    const r =
      location.state && location.state.patientId
        ? ""
        : document.getElementById("patient_input") &&
          document.getElementById("patient_input").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row">
                  <h1>Invoice</h1>
                </div>
                <div className="row">
                  <div className="col-lg-10">
                    <div className="row no-gutters">
                      <ShowMessage view={open} Message={message} />
                      <ErrorMessage visible={errorOpen} error={message} />
                      <div className="row no-gutters form-group">
                        <label className="col-lg-1"></label>
                        <div className="col-lg-6">
                          {location.state && location.state.patientId ? (
                            <div className="row no-gutters">
                              <label className="con_sum_lbl">
                                Name : {location.state.fullName}
                              </label>
                            </div>
                          ) : (
                            <>
                              <Multiselect
                                name="patient"
                                id="patient"
                                options={patient}
                                placeholder={"Patient Name"}
                                showArrow={true}
                                selectedValues={patientSel}
                                onSelect={handleSelectPatient}
                                displayValue="patient"
                                customCloseIcon={" "}
                                selectionLimit={1}
                                hidePlaceholder={true}
                                avoidHighlightFirstOption={true}
                                customArrow={
                                  <span
                                    className="icofont-search"
                                    style={{
                                      fontSize: "15px",
                                    }}
                                  ></span>
                                }
                                onSearch={(e) => {
                                  handleSearchPatient(
                                    document.getElementById("patient_input")
                                      .value
                                  );
                                  setPatientSel("");
                                }}
                                emptyRecordMsg={
                                  <div className="notFoundSearch">
                                    Type to search
                                  </div>
                                }
                              />
                              <ErrorMessage
                                error={"Please enter the Patient Name"}
                                visible={nameError}
                              />
                            </>
                          )}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <div className="col-lg-1"></div>
                        <div className="col-lg-10">
                          <div className="table-responsive mb-3">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th width="1%">No.</th>
                                  <th>Item</th>
                                  <th width="10%">Fee</th>
                                  <th width="5%">Delete</th>
                                </tr>
                              </thead>
                              <tbody>
                                {invoices.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td className="nowrapWhiteSpace">
                                        {item.itemName}
                                      </td>
                                      <td align="right">{item.itemFees}</td>
                                      <td align="center">
                                        <a
                                          className="pointer"
                                          onClick={() => {
                                            handleDeleteInvoice(item);
                                          }}
                                        >
                                          Delete
                                        </a>
                                      </td>
                                    </tr>
                                  );
                                })}
                                {itemArr.length > 0 && (
                                  <tr>
                                    <td>{invoices.length + 1}</td>
                                    <td>
                                      <Select
                                        name="item"
                                        id="item"
                                        style={{ width: "100%" }}
                                        options={itemArr}
                                        placeholder="Item"
                                        value={item}
                                        className={
                                          item
                                            ? "form-control select-control"
                                            : "form-control select-control opacity"
                                        }
                                        onChange={(e) => {
                                          setItem(e.target.value);
                                          handleOutPatientCharge(
                                            e.target.value
                                          );
                                          closeErrors();
                                        }}
                                      />
                                      <ErrorMessage
                                        error={"Select an item"}
                                        visible={showErrorItem}
                                      />
                                    </td>
                                    <td align="right">
                                      <CustomTextInput
                                        type="number"
                                        id="paidfees"
                                        maxlength="5"
                                        className="form-control"
                                        name="fees"
                                        style={{
                                          width: "100px",
                                          textAlign: "right",
                                        }}
                                        placeholder="Fee"
                                        value={fees}
                                        onChange={(e) => {
                                          e.target.value =
                                            e.target.value < 0
                                              ? 0
                                              : e.target.value.length > 5
                                              ? e.target.value.slice(0, 5)
                                              : e.target.value;
                                          setFees(e.target.value);
                                          closeErrors();
                                        }}
                                        onKeyUp={(e) =>
                                          e.keyCode == 13 && handleAdd()
                                        }
                                      />
                                      <ErrorMessage
                                        error={"Please enter valid Fee"}
                                        visible={showFee}
                                      />
                                    </td>
                                    <td align="center">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={handleAdd}
                                      >
                                        <span className="icofont icofont-plus"></span>
                                        Add
                                      </button>
                                    </td>
                                  </tr>
                                )}
                                <tr>
                                  <td colSpan="2" align="right">
                                    Total
                                  </td>
                                  <td align="right">{total}</td>
                                </tr>
                              </tbody>
                            </table>
                            <div>
                              <ErrorMessage
                                error={"Please add Items to the list"}
                                visible={showAddError}
                              />
                            </div>
                          </div>
                          <div className="text-center btn_center">
                            <button
                              type="submit"
                              onClick={handleSubmit}
                              className="btn mt-2"
                              title="Generate Invoice"
                            >
                              <span className="icofont-pay"></span>
                              Generate Invoice
                            </button>
                          </div>
                        </div>
                        <div className="col-lg-1"></div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Invoice;
