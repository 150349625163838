import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
const prefixerror = "ViewPatient";

function ViewPatient() {
  //#region const
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [labCode, setLabCode] = useState([]);
  const [symptom, setSymptom] = useState([]);
  const [diagnosis, setDiagnosis] = useState([]);
  //#endregion

  const getTreatmentTemplate = async () => {
    try {
      console.log(
        "location.state.treatmentName ---",
        location.state.treatmentName
      );
      if (!location.state && location.state.treatmentName == "") {
        return;
      }
      setLoad(true);
      const res1 = await doctorApi.getTreatmentTemplateByName(
        location.state.treatmentName
      );
      if (!res1.ok) {
        ErrorLog(
          prefixerror + " getTreatmentTemplate " + "getTreatmentTemplateByName",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime.",
          "treatmentName:" + location.state.treatmentName
        );
        return;
      }

      console.log(res1, "---res1");
      setData(res1.data.data);
      if (res1.data.data.complaints.length > 0) {
        let filter = "[";
        for (let i = 0; i < res1.data.data.complaints.length; i++) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${res1.data.data.complaints[i].complaint}"]`;
        }
        filter += "]";
        console.log("filter---", filter);

        const symptomRes = await doctorApi.getSymptomByArray(filter);
        if (!symptomRes.ok) {
          ErrorLog(
            prefixerror + " getTreatmentTemplate " + "getSymptomByArray",
            JSON.stringify(symptomRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log("symptomRes---", symptomRes);

        let symptomSel = [];
        for (let i = 0; i < symptomRes.data.data.length; i++) {
          var myObject = {
            complaint: symptomRes.data.data[i].name,
            complaintName: symptomRes.data.data[i].complaints,
          };
          symptomSel.push(myObject);
        }
        console.log("symptomSel---", symptomSel);
        // setSymptomSel(symptomSel);
        setSymptom(symptomSel);
      }
      if (res1.data.data.diagnosis.length > 0) {
        let filter = "[";
        for (let i = 0; i < res1.data.data.diagnosis.length; i++) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${res1.data.data.diagnosis[i].diagnosis}"]`;
        }
        filter += "]";
        console.log("filter---", filter);

        const diagnosisRes = await doctorApi.getDiagnosisByArray(filter);
        if (!diagnosisRes.ok) {
          ErrorLog(
            prefixerror + " getTreatmentTemplate " + "getDiagnosisByArray",
            JSON.stringify(diagnosisRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log("diagnosisRes---", diagnosisRes);

        let diagnosisSel = [];
        for (let i = 0; i < diagnosisRes.data.data.length; i++) {
          var myObject = {
            diagnosis: diagnosisRes.data.data[i].name,
            diagnosisName: diagnosisRes.data.data[i].diagnosis,
          };
          diagnosisSel.push(myObject);
        }
        console.log("diagnosisSel---", diagnosisSel);
        setDiagnosis(diagnosisSel);
      }
      if (res1.data.data.items.length > 0) {
        let filter = "[";
        for (let i = 0; i < res1.data.data.items.length; i++) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${res1.data.data.items[i].template}"]`;
        }
        filter += "]";
        const labRes = await doctorApi.getLabTestByArray(filter);
        if (!labRes.ok) {
          ErrorLog(
            prefixerror + " getTreatmentTemplate " + "getLabTestByArray",
            JSON.stringify(labRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log(labRes, "---res1");
        setLabCode(labRes.data.data);
      }

      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getTreatmentTemplate();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row mb-4" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="row no-gutters">
                      <h1>{data.template_name}</h1>
                    </div>
                    <div>
                      <label className="con_sum_lbl pe-1">Patient Age :</label>
                      {data.patient_age_from} - {data.patient_age_to}
                    </div>
                    {data.description && (
                      <div>
                        <label className="con_sum_lbl me-1">Description:</label>
                        {data.description}
                      </div>
                    )}
                    <div style={{ textAlign: "left", lineHeight: "normal" }}>
                      <label className="con_sum_lbl me-1">Symptoms:</label>

                      {symptom && symptom.length > 0
                        ? symptom.map((item, index) =>
                            index != symptom.length - 1
                              ? item.complaintName + ", "
                              : item.complaintName
                          )
                        : null}
                    </div>
                    <div>
                      <label className="con_sum_lbl me-1">
                        Disease / Conditions:
                      </label>
                      {diagnosis && diagnosis.length > 0
                        ? diagnosis.map((item, index) =>
                            index != diagnosis.length - 1
                              ? item.diagnosisName + ", "
                              : item.diagnosisName
                          )
                        : null}
                    </div>
                    <div style={{ textAlign: "left" }} className="mt-2">
                      <label className="con_sum_lbl">
                        Prescribed Medicines
                      </label>
                    </div>
                    <div className="mt-1 table-responsive">
                      <table className="table table-bordered">
                        <thead>
                          <tr>
                            <th width="5%">No.</th>
                            <th width="25%">Medicine</th>
                            <th width="10%">Consumption</th>
                            <th width="15%">Before / After</th>
                            <th width="10%">Duration</th>
                            <th>Instruction</th>
                          </tr>
                        </thead>
                        {data.drugs && data.drugs.length > 0
                          ? data.drugs.map((item, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.drug_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.dosage}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.comment}
                                    </td>
                                    <td>{item.period}</td>
                                    <td>{item.dosage_instruction}</td>
                                  </tr>
                                </tbody>
                              );
                            })
                          : null}
                      </table>
                    </div>
                    {labCode.length > 0 && (
                      <div style={{ textAlign: "left" }}>
                        <label className="con_sum_lbl">Lab Test</label>
                        <div className="mt-1 mb-2 table-responsive">
                          <table className="table table-bordered">
                            <thead>
                              <tr>
                                <th width="5%">No.</th>
                                <th width="30%">Lab Test Code</th>
                                <th>Lab Tests</th>
                              </tr>
                            </thead>
                            {labCode && labCode.length > 0
                              ? labCode.map((item, index) => {
                                  return (
                                    <tbody>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{item.lab_test_code}</td>
                                        <td> {item.lab_test_name}</td>
                                      </tr>
                                    </tbody>
                                  );
                                })
                              : null}
                          </table>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewPatient;
