import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import ReactCrop from "react-image-crop";
import DatePicker from "react-datepicker";

import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import preFunction from "../component/common/CommonFunction";
import Select from "../component/common/select";
import CustomTextInput from "../component/common/CustomTextInput";
import RadioInput from "../component/common/RadioInput";
import { months, genderOption, YOE } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "../doctorHome/DoctorHeader";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorRegistration from "./DoctorRegistration";
import photo from "../assests/png/camera.png";
import blankProfile from "../assests/png/blank-profile-picture.png";
import ShowMessage from "./../component/common/ShowMessage";
import storage from "../auth/storage";
import string from "../string";
import AuthContext from "../auth/context";
import "react-image-crop/dist/ReactCrop.css";
import "react-datepicker/dist/react-datepicker.css";

const prefixerror = "BasicInformation";

function BasicInformation() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const { email, setName, setLName, setEmail, setScheduleName } =
    useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const [medicalDepartmentOption, setMedicalDepartmentOption] = useState([]);
  const [displayImage, setDisplayImage] = useState(blankProfile);
  const [showImg, setShowImg] = useState(false);
  const [showDept, setShowDept] = useState(false);
  const [showYoe, setShowYoe] = useState(false);
  const [showPract, setShowpract] = useState(false);
  const [showDegree, setShowDegree] = useState(false);
  const [practNum, setPractNum] = useState();
  const [gender, setGender] = useState();
  const [showimg, setshowimg] = useState("");
  const [degree, setDegree] = useState();
  const [workExp, setWorkExp] = useState();
  const [dob, setDOB] = useState("");
  const [onChangeImage, setonChangeImage] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showSuccessMail, setShowSuccessMail] = useState(false);
  const [url, setUrl] = useState();
  const [medicalDepartment, setMedicalDepartment] = useState();
  const [firstname, setFirstname] = useState();
  const [lastname, setLastname] = useState();
  const [openModel, setOpenModal] = useState(false);
  const [CropOpen, setCropOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    minwidth: 150,
    minheight: 150,
    aspect: 1,
  });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const date = new Date();
  const years = preFunction.range(
    date.getFullYear() - 100,
    date.getFullYear() - 17,
    1
  );
  const [showDOB, setshowDOB] = useState(false);
  const [showGender, setShowGender] = useState(false);
  const [openDate, setOpenDate] = useState();
  const [emailVerified, setEmailVerified] = useState(false);
  const [profileComplete, setProfileComplete] = useState(100);
  const [status, setStatus] = useState("");
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const selectImage = (file) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setShowImg(true);
      return;
    }
    setShowImg(false);
    setSrc(URL.createObjectURL(file));
    setOpenModal(true);
  };

  const cropImageNow = () => {
    if (crop.width === 0 || crop.height === 0) {
      setCropOpen(true);
      return;
    }
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
    setDisplayImage(base64Image);
    setOpenModal(false);
    setonChangeImage(true);
  };

  const department = async () => {
    const resDepartment = await doctorRegApi.selectDepartment();
    if (!resDepartment.ok) {
      ErrorLog(
        prefixerror + "department " + "selectDepartment",
        JSON.stringify(resDepartment),
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
    console.log("resDepartment", resDepartment.data.data);
    setMedicalDepartmentOption(resDepartment.data.data);
  };

  const closeErrors = () => {
    setShowImg(false);
    setshowDOB(false);
    setShowYoe(false);
    setShowpract(false);
    setShowDegree(false);
    setShowDept(false);
    setShowGender(false);
    document.getElementById("input").className = " form-control";
    document.getElementById("dobButton").className = " form-control datepicker";
    document.getElementById("yoe").className =
      " form-control datepicker select-control";
    document.getElementById("practnum").className = " form-control";
    document.getElementById("degreeid").className = " form-control";
    document.getElementById("medicalDepartment").className =
      " form-control select-control";
  };

  const handleSubmit = async () => {
    try {
      let err = false;
      if (displayImage === blankProfile) {
        setShowImg(true);
        document.getElementById("input").className += " form-control-error";
        document.getElementById("input").focus();
        err = true;
      } else {
        setShowImg(false);
      }
      const dt = !dob ? "" : moment(dob).format("yyyy-MM-DD");
      // const dtdoj = !doj ? "" : moment(doj).format("yyyy-MM-DD");
      if (
        dt == "" ||
        dt > preFunction.disableFutureDate() ||
        dt < preFunction.disablePastDateBy100()
      ) {
        setshowDOB(true);
        document.getElementById("dobButton").className += " form-control-error";
        err = true;
      } else {
        setshowDOB(false);
      }
      if (workExp == undefined) {
        setShowYoe(true);
        document.getElementById("yoe").className += " form-control-error";
        document.getElementById("yoe").focus();
        err = true;
      } else {
        setShowYoe(false);
      }
      if (!practNum) {
        setShowpract(true);
        document.getElementById("practnum").className += " form-control-error";
        document.getElementById("practnum").focus();
        err = true;
      } else {
        setShowpract(false);
      }
      if (!degree) {
        setShowDegree(true);
        document.getElementById("degreeid").className += " form-control-error";
        document.getElementById("degreeid").focus();
        err = true;
      } else {
        setShowDegree(false);
      }
      if (!medicalDepartment) {
        setShowDept(true);
        document.getElementById("medicalDepartment").className +=
          " form-control-error";
        document.getElementById("medicalDepartment").focus();
        err = true;
      } else {
        setShowDept(false);
      }
      if (!gender) {
        setShowGender(true);
        err = true;
      } else {
        setShowGender(false);
      }

      if (err) {
        return;
      }

      console.log(degree, workExp, gender, practNum);
      console.log("department--", medicalDepartment);
      // console.log("dob--", dob);

      setLoad(true);
      const id =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      const fileName = "profile";
      if (onChangeImage) {
        const response = await doctorRegApi.imageUploadProfile(
          fileName,
          displayImage
        );
        if (!response.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "imageUploadProfile",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "fileName:" + fileName + "displayImage:" + displayImage
          );
          return;
        }
        if (response.data.message) {
          console.log("Res", response);
          if (!location.state || !location.state.id)
            await storage.storeDp(
              string.testbaseUrl + response.data.message.file_url
            );

          setUrl(response.data.message.file_url);

          const res1 = await doctorRegApi.updateFile(
            id,
            response.data.message.file_url
          );
          if (!res1.ok) {
            ErrorLog(
              prefixerror + "handleSubmit " + "updateFile",
              JSON.stringify(res1),
              "We are facing some issues.Please try again after sometime.",
              "id:" + id + "file_url:" + response.data.message.file_url
            );
            return;
          }
          console.log(res1);
        }
      }
      let profComp = 100;
      console.log("profileComplete----", profileComplete);
      if (profileComplete < 50) {
        profComp = 45;
        if (!location.state || !location.state.id)
          await storage.storeProfileComplete(45);
      }
      console.log("profileComplete----", profComp);

      const res = await doctorRegApi.updatebasicInformation(
        id,
        practNum,
        gender,
        dt,
        degree,
        medicalDepartment,
        url,
        workExp,
        profComp
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "updatebasicInformation",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "id:" +
            id +
            "practNum:" +
            practNum +
            "gender:" +
            gender +
            "dt:" +
            dt +
            "degree:" +
            degree +
            "medicalDepartment:" +
            medicalDepartment +
            "url:" +
            url +
            "workExp:" +
            workExp +
            "profComp:" +
            profComp
        );
        return;
      }
      console.log(res, "-------------res");
      setShowSuccess(true);
      setMessage("Updated Successfully");
      setTimeout(() => {
        if (location.state && location.state.id)
          navigate("/appointment", { state: { id: location.state.id } });
        else navigate("/appointment");
      }, 3000);

      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleVerifyEmail = async () => {
    try {
      setLoad(true);
      const mail =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      const recipientName = firstname + " " + lastname;
      const mailRes = await doctorRegApi.sendEmailVerification(
        recipientName,
        mail
      );
      if (!mailRes.ok) {
        ErrorLog(
          prefixerror + "handleVerifyEmail " + "sendEmailVerification",
          JSON.stringify(mailRes),
          "We are facing some issues.Please try again after sometime.",
          "recipientName:" + recipientName + "mail:" + mail
        );
        return;
      }
      console.log("mailRes---", mailRes);
      setMessage(
        `To gain approval, please check your email and complete the verification process.`
      );
      setShowSuccessMail(true);
      setTimeout(() => {
        setShowSuccessMail(false);
      }, 3000);
      setLoad(false);
      return;
    } catch (error) {
      console.log("error--", error);
      ErrorLog(
        prefixerror + "handleVerifyEmail ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const getBasicInformation = async () => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
        navigate("/");
        return;
      }
      const id =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      console.log("id----", id);
      const res = await doctorRegApi.getDetilsBasicInfo(id);
      console.log(res, "--------------------BasicInformation");
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getBasicInformation " + "getDetilsBasicInfo",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "id:" + id
        );
        return;
      }
      if (res.data.data.profile_completeness != 100) {
        console.log(
          "profile_completeness----",
          res.data.data.profile_completeness
        );
        const prof = res.data.data.profile_completeness
          ? res.data.data.profile_completeness
          : 0;
        setProfileComplete(prof);
        if (!location.state || !location.state.id)
          await storage.storeProfileComplete(prof);
      }
      if (res.data.data.practitioner_schedules.length > 0) {
        setScheduleName(res.data.data.practitioner_schedules[0].schedule);
        if (!location.state || !location.state.id)
          await storage.storeScheduleName(
            res.data.data.practitioner_schedules[0].schedule
          );
      }
      // setData(res.data.data);
      setFirstname(res.data.data.first_name);
      setName(res.data.data.first_name);
      setLastname(res.data.data.last_name);
      setLName(res.data.data.last_name);
      setEmail(res.data.data.user_id);
      setMobile(res.data.data.mobile_phone);
      setStatus(res.data.data.status);
      await storage.storeStaus(res.data.data.status);
      if (res.data.data.image) {
        setshowimg(string.testbaseUrl + res.data.data.image);
        setDisplayImage(string.testbaseUrl + res.data.data.image);
      }
      await storage.storeDp(string.testbaseUrl + res.data.data.image);
      setPractNum(res.data.data.registered_practitioner_number);
      setDegree(res.data.data.degree);
      if (res.data.data.work_experience === "0") {
        setWorkExp(0);
      } else {
        setWorkExp(res.data.data.work_experience);
      }
      var today = new Date();
      var opendate = new Date(today.getFullYear() - 30, 0, 1);
      setOpenDate(opendate);
      console.log("openDate--", opendate);
      setDOB(
        res.data.data.date_of_birth ? new Date(res.data.data.date_of_birth) : ""
      );
      setGender(res.data.data.gender);
      if (res.data.data.gender) {
        document.getElementById(res.data.data.gender).checked = true;
      }
      setMedicalDepartment(res.data.data.department);
      setEmailVerified(res.data.data.email_verification);
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "getBasicInformation ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getBasicInformation();
    department();
    // document.getElementById("dobButton").focus();
  }, []);

  return (
    <div>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>
        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="pd">
              <div className="row mb-5">
                <h1>Basic Information</h1>
              </div>
              <div className="row border">
                {/* <div className="row">
                <DoctorRegistration />
              </div> */}
                <div className="col-lg-3 border">
                  <DoctorRegistration />
                </div>
                <div className="col-lg-7">
                  <div className="row">
                    <ShowMessage view={showSuccessMail} Message={message} />
                    <div className="row p-lg-5">
                      <div className="col-lg-3 mt-2"></div>
                      <div className="col-lg-5 mt-2">
                        <div className="row no-gutters">
                          <label>
                            <b>Name :</b> {firstname} {lastname}
                          </label>
                        </div>
                        <div className="row no-gutters">
                          <label>
                            <b>Email :</b> {email} ({" "}
                            {!emailVerified && (
                              <a
                                className="pointer"
                                style={{ textDecoration: "underline" }}
                                onClick={handleVerifyEmail}
                              >
                                Verify Now
                              </a>
                            )}{" "}
                            )
                          </label>
                        </div>
                        <div className="row no-gutters">
                          <label>
                            <b>Mobile :</b> {mobile}
                          </label>
                        </div>
                        <div className="row no-gutters">
                          <label>
                            <b>Status :</b>{" "}
                            <span
                              style={{ color: "#0469ad", fontWeight: "bold" }}
                            >
                              {status == "Active"
                                ? "Approved"
                                : "Waiting for Approval"}
                            </span>
                          </label>
                        </div>
                      </div>
                      <div
                        className="col-lg-4 text-center mb-3"
                        style={{ marginTop: "-2%" }}
                      >
                        <div style={{ borderRadius: "50%" }}>
                          <input
                            type="file"
                            accept="image/*"
                            name=""
                            id="input"
                            onChange={(e) => {
                              selectImage(e.target.files[0]);
                              closeErrors();
                            }}
                            onClick={(e) => {
                              e.target.value = "";
                            }}
                            style={{ display: "none" }}
                          />
                          <img
                            src={
                              output
                                ? output
                                : onChangeImage
                                ? displayImage
                                : showimg
                                ? showimg
                                : displayImage
                            }
                            alt={string.websiteName + " Profile Picture"}
                            id="img"
                            className="text-center img-thumbnail"
                            htmlFor="input"
                            style={{ borderRadius: "50%" }}
                          />
                          <label htmlFor="input">
                            <img
                              src={photo}
                              alt={string.websiteName + " Profile Picture"}
                              height="40"
                              style={{
                                position: "absolute",
                                marginTop: 40,
                                marginLeft: -30,
                                cursor: "pointer",
                              }}
                            />
                          </label>

                          <ErrorMessage
                            error={"Please select your Profile Picture"}
                            visible={showImg}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Date of Birth :
                        </label>
                        <div className="col-lg-5">
                          <DatePicker
                            name="dob"
                            id="dob"
                            maxDate={moment().subtract(18, "years")._d}
                            minDate={moment().subtract(100, "years")._d}
                            openToDate={openDate}
                            selected={dob}
                            onChange={(date) => {
                              setDOB(date);
                              closeErrors();
                            }}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            error={"Please select the Date of Birth"}
                            visible={showDOB}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters mt-1 mb-1">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Gender :
                        </label>
                        <div className="col-lg-5 mb-2">
                          <RadioInput
                            name="gender"
                            data={genderOption}
                            className="radio-input"
                            onChange={(e) => {
                              closeErrors();
                              setGender(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            style={{
                              marginBottom: "3px",
                              marginTop: "-5px",
                            }}
                            error={"Please select your Gender"}
                            visible={showGender}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Total
                          Experience(years) :
                        </label>
                        <div className="col-lg-5">
                          <Select
                            type="text"
                            name="yoe"
                            id="yoe"
                            options={YOE}
                            value={workExp}
                            placeholder="Year of Experience"
                            className={
                              workExp
                                ? "form-control datepicker select-control"
                                : "form-control datepicker select-control opacity"
                            }
                            onChange={(e) => {
                              setWorkExp(e.target.value);
                              closeErrors();
                            }}
                          />
                          <ErrorMessage
                            error={"Please enter your Total Experience"}
                            visible={showYoe}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Registered Practioner
                          Number :
                        </label>
                        <div className=" col-lg-5">
                          <CustomTextInput
                            type="text"
                            name="practnum"
                            id="practnum"
                            maxLength={50}
                            value={practNum}
                            className="form-control"
                            placeholder="Registered Practitioner Number"
                            onChange={(e) => {
                              setPractNum(e.target.value);
                              closeErrors();
                            }}
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                          <ErrorMessage
                            error={
                              "Please enter your Registered Practitioner Number"
                            }
                            visible={showPract}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Degree :
                        </label>
                        <div className="col-lg-5">
                          <CustomTextInput
                            type="text"
                            name="degree"
                            id="degreeid"
                            maxLength={120}
                            value={degree}
                            className="form-control"
                            placeholder="Degree"
                            onChange={(e) => {
                              setDegree(e.target.value);
                              closeErrors();
                            }}
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                          <ErrorMessage
                            error={"Please enter your Degree"}
                            visible={showDegree}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-7">
                          <span className="star">*</span> Department :
                        </label>
                        <div className="col-lg-5">
                          <Select
                            type="text"
                            name="medicalDepartment"
                            id="medicalDepartment"
                            options={medicalDepartmentOption}
                            value={medicalDepartment}
                            placeholder="Department"
                            className={
                              medicalDepartment
                                ? "form-control select-control"
                                : "form-control select-control opacity"
                            }
                            onChange={(e) => {
                              setMedicalDepartment(e.target.value);
                              closeErrors();
                            }}
                          />
                          <ErrorMessage
                            error={"Please select your Medical Department"}
                            visible={showDept}
                          />
                        </div>
                      </div>
                      {showSuccess && (
                        <div className="row no-gutters">
                          <label className="control-label col-lg-7"></label>
                          <div className="col-lg-5">
                            <ShowMessage view={showSuccess} Message={message} />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="row no-gutters form-group mb-5 mt-3">
                    <label className="control-label col-lg-7"></label>
                    <div className="col-lg-5">
                      <button
                        className="btn"
                        type="submit"
                        title="Next"
                        onClick={handleSubmit}
                      >
                        <span className="icofont-arrow-right"></span>Next
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <Modal className="modalwidth" show={openModel}>
              <Modal.Body>
                <div>
                  {src && (
                    <div>
                      <ReactCrop
                        src={src}
                        onImageLoaded={setImage}
                        crop={crop}
                        onChange={setCrop}
                        minHeight={50}
                        minWidth={50}
                        // maxHeight={300}
                        // maxWidth={300}
                        keepSelection={true}
                        onComplete={(crop, pixel) => {
                          console.log(crop, pixel);
                        }}
                      />
                      <br />
                    </div>
                  )}
                </div>
                <ErrorMessage
                  error={"Click on image to crop"}
                  visible={CropOpen}
                />
              </Modal.Body>
              <Modal.Footer>
                <button className="btn" onClick={cropImageNow} title="Crop">
                  <span className="icofont-crop"></span>Crop
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </div>
      </div>
    </div>
  );
}

export default BasicInformation;
