import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import { months } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import "react-datepicker/dist/react-datepicker.css";
import string from "../string";
import storage from "../auth/storage";
const prefixerror = "InvoiceReport";

function InvoiceReport() {
  //#region const
  const [role, setRole] = useState();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const todayDate = new Date();
  const todyaDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todyaDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todyaDate);
  const [csvData, setCsvData] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  let total = 0;
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear(), 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const getInvoiceList = async () => {
    try {
      setLoad(true);
      const r = await storage.getRole();
      setRole(r);
      console.log(fromDate, toDate);
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      let res;
      const role = await storage.getRole();
      if (role != string.DOCTOR_ROLE) {
        const company = await storage.getCompany();
        res = await doctorApi.getAllInvoiceWithDateByCompany(company, from, to);
        console.log("res--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getAllInvoiceWithDate",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to" + to
          );
          return;
        }
      } else {
        res = await doctorApi.getAllInvoiceWithDate(from, to);
        console.log("res--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getAllInvoiceWithDate",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to" + to
          );
          return;
        }
      }
      let csvData = [
        ["No", "Invoice Number", "Name", "Date", "Status", "Amount"],
      ];
      let tot = 0,
        i;
      if (res.data.data.length > 0) {
        setData(res.data.data);

        for (i = 0; i < res.data.data.length; i++) {
          csvData[i + 1] = [
            i + 1,
            res.data.data[i].name,
            res.data.data[i].patient_name,
            moment(res.data.data[i].posting_date).format("DD-MM-yyyy"),
            res.data.data[i].status,
            res.data.data[i].grand_total,
          ];
          tot += res.data.data[i].grand_total;
        }
        csvData[i + 1] = ["", "", "", "", "Total", tot];
      } else {
        setData([]);
      }
      setCsvData(csvData);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getInvoiceList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = (invoiceId) => {
    navigate("/viewInvoice", { state: { invoiceId } });
  };

  const setDateByDash = async (dash) => {
    console.log("dash----", dash);
    var fromDate;
    var toDate = new Date();
    if (dash == "day") {
      fromDate = new Date();
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    } else if (dash == "week") {
      fromDate = new Date(moment().startOf("week"));
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    } else if (dash == "month") {
      fromDate = new Date(moment().startOf("month"));
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    }

    const from = moment(fromDate).format("yyyy-MM-DD");
    const to = moment(toDate).format("yyyy-MM-DD");
    const res = await doctorApi.getAllInvoiceWithDate(from, to);
    if (!res.ok) {
      ErrorLog(
        prefixerror + "getInvoiceList " + "getAllInvoiceWithDate",
        JSON.stringify(res),
        "We are facing some issues.Please try again after sometime.",
        "from:" + from + "to" + to
      );
      return;
    }
    console.log("res--", res);
    let csvData = [
      ["No", "Invoice Number", "Name", "Date", "Status", "Amount"],
    ];
    let tot = 0,
      i;
    if (res.data.data.length > 0) {
      setData(res.data.data);

      for (i = 0; i < res.data.data.length; i++) {
        csvData[i + 1] = [
          i + 1,
          res.data.data[i].name,
          res.data.data[i].patient_name,
          moment(res.data.data[i].posting_date).format("DD-MM-yyyy"),
          res.data.data[i].status,
          res.data.data[i].grand_total,
        ];
        tot += res.data.data[i].grand_total;
      }
      csvData[i + 1] = ["", "", "", "", "Total", tot];
    } else {
      setData([]);
    }
    setCsvData(csvData);
  };

  useEffect(() => {
    if (location.state && location.state.dash && location.state.dash != "all") {
      setDateByDash(location.state.dash);
    } else {
      getInvoiceList();
    }
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Payment Report</h1>
                </div>
                <div className="row form-group">
                  <div className="col-lg-3 p-0 mt-2">
                    <div className="row">
                      <label
                        className="col-lg-1 mt-2 pe-0"
                        style={{ width: "65px" }}
                      >
                        <span className="star">*</span> From :
                      </label>
                      <div className="col-lg-2 p-0" style={{ width: "154px" }}>
                        <DatePicker
                          name="fromDate"
                          id="fromDate"
                          openToDate={fromDate}
                          selected={fromDate}
                          onChange={(date) => {
                            setFromDate(date);
                          }}
                          minDate={new Date("2022-01-01")}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 p-0 mt-2">
                    <div className="row">
                      <label
                        className="col-lg-1 pe-0 mt-2"
                        style={{ width: "48px" }}
                      >
                        <span className="star">*</span> To :
                      </label>
                      <div className="col-lg-2 p-0" style={{ width: "170px" }}>
                        <DatePicker
                          name="toDate"
                          id="toDate"
                          openToDate={toDate}
                          selected={toDate}
                          onChange={(date) => {
                            setToDate(date);
                          }}
                          minDate={fromDate}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4  mt-2"></div>
                  {data.length > 0 && (
                    <div className="col-lg-2 mt-2 text-right">
                      <CSVLink
                        filename={
                          "payment-" +
                          moment(fromDate).format("DD-MM-yyyy") +
                          " to " +
                          moment(toDate).format("DD-MM-yyyy") +
                          ".csv"
                        }
                        data={csvData}
                      >
                        <button className="btn-2">
                          <span className="icofont icofont-download"></span>
                          Export Report
                        </button>
                      </CSVLink>
                    </div>
                  )}
                </div>
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {data.length} payment identified.
                  </div>
                  <div className="table-responsive mt-1">
                    {data.length > 0 ? (
                      <>
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th width="10%">Invoice Date</th>
                              <th width="15%">Invoice No</th>
                              <th>Patient Name</th>
                              {/* <th width="10%">Status</th> */}
                              <th width="10%">Paid Amount (₹)</th>
                              <th width="10%">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              total += item.grand_total;
                              return (
                                <tr key={item.name}>
                                  <td>{index + 1}</td>

                                  <td className="nowrapWhiteSpace">
                                    {moment(item.posting_date).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                  <td className="nowrapWhiteSpace">
                                    {item.name}
                                  </td>
                                  <td className="nowrapWhiteSpace">
                                    {item.patient_name}
                                  </td>
                                  {/* <td>{item.status}</td> */}
                                  <td align="right">{item.grand_total}</td>
                                  <td>
                                    <button
                                      className="btn-3"
                                      onClick={() =>
                                        navigate("/viewInvoice", {
                                          state: { invoiceId: item.name },
                                        })
                                      }
                                    >
                                      <span className="icofont icofont-prescription"></span>
                                      View Invoice
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                            <tr>
                              <td colSpan="4" className="alignRight">
                                Total
                              </td>
                              <td align="right">{total}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InvoiceReport;
