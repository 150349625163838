import { create } from "apisauce";
import storage from "../auth/storage";
import string from "../string";

const apiClient = create({
  baseURL: string.testbaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
apiClient.addAsyncRequestTransform(async (request) => {
  const authToken = await storage.getToken();
  const admintoken = await storage.getSuperAdminToken();
  // console.log("admintoken--", admintoken);

  let token = authToken ? authToken : admintoken;
  // console.log("token---", token);
  if (window.location.href.indexOf("reportutmparam") == -1)
    request.headers["Authorization"] = "token " + token;
  else request.headers["Authorization"] = "token " + admintoken;
});

export default apiClient;
