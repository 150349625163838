import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../auth/useAuth";
import storage from "../auth/storage";

import ErrorLog from "../component/common/ErrorLog";

import registerApi from "../api/register";
const prefixerror = "Logout";

function Logout() {
  //#region const
  const authLogin = useAuth();
  const navigate = useNavigate();
  //#endregion

  const getHomePage = async () => {
    try {
      const doctorID = await storage.getID();
      await storage.removeId();
      authLogin.logOut();
      if (doctorID) {
        const logoutRes = await registerApi.logout();
        if (!logoutRes.ok) {
          ErrorLog(
            prefixerror + "Logout " + "logout",
            JSON.stringify(logoutRes),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
        console.log("logoutRes---", logoutRes);
      }
      navigate("/");
    } catch (error) {
      ErrorLog(
        prefixerror + " getHomePage ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };
  useEffect(() => {
    getHomePage();
  }, []);
}

export default Logout;
