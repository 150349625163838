import React from "react";
import ReactSelect from "react-select";
import string from "../../string";
import searchIcon from "../../assests/svg/search.svg";
import arrowIcon from "../../assests/svg/react-select-down-arrow.svg";

const customSearchIndicator = (props) => {
  return (
    <div
      {...props.innerProps}
      className="m-1"
      style={{
        padding: "5px",
        borderRadius: "5px",
        background: "#f6f4f4",
      }}
    >
      <img src={searchIcon} />
    </div>
  );
};

const customDropdownIndicator = (props) => {
  return (
    <div
      {...props.innerProps}
      className="m-1"
      style={{
        padding: "5px",
        borderRadius: "5px",
        background: "#E4EAF8",
      }}
    >
      <img src={arrowIcon} />
    </div>
  );
};

const ReactSelectField = ({
  inputId,
  placeholder,
  label,
  error,
  onChange,
  onBlur,
  touched,
  mandatory,
  options,
  errors,
  value,
  style,
  isTable = false,
  onInputChange,
  disabled = false,
  search = true,
  clear = true,
  searchIcon = true,
  ...otherProps
}) => {
  return (
    <div className={`row no-gutters mt-2`}>
      {label && label != "" && (
        <div className="col-lg-5 text-right pe-3 mt-2">
          <label>{label && label} :</label>
        </div>
      )}
      <div className={label ? "col-lg-7" : "col-lg-12"}>
        <div style={style}>
          <ReactSelect
            styles={{
              control: (baseStyles, state) => ({
                ...baseStyles,
                backgroundColor: mandatory ? "#f6f4f4" : "",
                // border: "0px",
                // borderRadius: "6px",
              }),
              option: (baseStyles, state) => ({
                ...baseStyles,
                zIndex: 1999,
              }),
            }}
            className="custom-select-control" // Apply the control class
            classNamePrefix="custom-select"
            inputId={inputId}
            placeholder={placeholder}
            options={options}
            error={errors}
            touched={touched}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
            isDisabled={disabled}
            onInputChange={onInputChange}
            {...otherProps}
            isClearable={clear ? true : false}
            isSearchable={search ? true : false}
            components={{
              DropdownIndicator:
                search && searchIcon
                  ? customSearchIndicator
                  : customDropdownIndicator,
            }}
          />
        </div>
        {error && touched && <p className="error-message">{error}</p>}
      </div>
    </div>
  );
};

export default ReactSelectField;
