import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";

import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import { months } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";

import "react-datepicker/dist/react-datepicker.css";

const prefixerror = "ReportDoctor";

function AdminDashboard() {
  //#region const
  const [data, setData] = useState([]);
  const [appresData, setAppresData] = useState([]);
  const [dateWiseArr, setDateWiseArr] = useState([]);
  const [cancelledCount, setCancelledCount] = useState(0);
  const [activatedCount, setActivatedCount] = useState(0);
  const [disabledCount, setDisabledCount] = useState(0);
  const [inProgressCount, setInProgressCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [openCount, setOpenCount] = useState(0);
  const [closedCount, setClosedCount] = useState(0);
  const [appCancelledCount, setAppCancelledCount] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  // const [demoData, setDemoData] = useState([]);
  // const [labTestData, setLabTestData] = useState([]);
  // const [symptomData, setSymptomData] = useState([]);
  // const [diagnosisData, setDiagnosisData] = useState([]);
  // const [demoInProgressCount, setDemoInProgressCount] = useState(0);
  // const [demoClosedCount, setDemoClosedCount] = useState(0);
  // const [demoCancelledCount, setDemoCancelledCount] = useState(0);
  // const [templateData, setTemplateData] = useState([]);
  // const [medicineData, setMedicineData] = useState([]);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const todayDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todayDate.getTime() - 6 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todayDate);
  const years = preFunction.range(2022, todayDate.getFullYear(), 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const getDoctorList = async () => {
    try {
      setLoad(true);
      setData([]);
      const role = await storage.getRole();
      if (role !== "SuperAdmin") {
        navigate("/logout");
        return;
      }
      const dateArr = [];
      let plusOne;
      for (let i = 0; i < 7; i++) {
        plusOne = new Date();
        plusOne.setTime(todayDate.getTime() - i * 24 * 60 * 60 * 1000);
        dateArr.push(moment(plusOne).format("yyyy-MM-DD"));
      }
      console.log("dateArr--------", dateArr);

      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");

      const res = await adminApi.getDoctorReport(from, to, "All", "All");
      console.log("res==", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDoctorList " + "getDoctorReport",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + "limitStart:0" + "pageSize:30"
        );
        return;
      }
      setData(res.data.message);
      let dtArr = [];
      for (let i = 0; i < dateArr.length; i++) {
        dtArr[dateArr[i]] = {
          totDoc: 0,
          cancelledCount: 0,
          activatedCount: 0,
          disabledCount: 0,
          inProgressCount: 0,
          completedCount: 0,
          openCount: 0,
          closedCount: 0,
          appCancelledCount: 0,
          requestCount: 0,
        };
      }
      console.log("dtArr--------", dtArr);
      let cancelledCount = 0,
        activatedCount = 0,
        disabledCount = 0,
        inProgressCount = 0,
        completedCount = 0;

      for (let i = 0; i < res.data.message.length; i++) {
        console.log("creation----", res.data.message[i].creation);
        if (res.data.message[i].registration_status == "Cancelled") {
          dtArr[res.data.message[i].creation].cancelledCount++;
          cancelledCount++;
        }
        if (res.data.message[i].registration_status == "In Progress") {
          dtArr[res.data.message[i].creation].inProgressCount++;
          inProgressCount++;
        }
        if (res.data.message[i].registration_status == "Completed") {
          dtArr[res.data.message[i].creation].completedCount++;
          completedCount++;
        }
        if (res.data.message[i].status == "Active") {
          dtArr[res.data.message[i].creation].activatedCount++;
          activatedCount++;
        }
        if (res.data.message[i].status == "Disabled") {
          dtArr[res.data.message[i].creation].disabledCount++;
          disabledCount++;
        }
      }

      setCancelledCount(cancelledCount);
      setActivatedCount(activatedCount);
      setDisabledCount(disabledCount);
      setInProgressCount(inProgressCount);
      setCompletedCount(completedCount);

      const appRes = await adminApi.getAllAppointment(from, to);
      console.log("appRes----", appRes);

      let openCount = 0,
        closedCount = 0,
        appCancelledCount = 0,
        requestCount = 0;
      for (let i = 0; i < appRes.data.message.length; i++) {
        console.log(
          "appointment_date----",
          appRes.data.message[i].appointment_date
        );
        if (appRes.data.message[i].status == "Open") {
          dtArr[appRes.data.message[i].appointment_date].openCount++;
          openCount++;
        }
        if (appRes.data.message[i].status == "Requested") {
          dtArr[appRes.data.message[i].appointment_date].requestCount++;
          requestCount++;
        }
        if (appRes.data.message[i].status == "Completed") {
          dtArr[appRes.data.message[i].appointment_date].closedCount++;
          closedCount++;
        }
        if (appRes.data.message[i].status == "Cancelled") {
          dtArr[appRes.data.message[i].appointment_date].appCancelledCount++;
          appCancelledCount++;
        }
      }
      setOpenCount(openCount);
      setClosedCount(closedCount);
      setAppCancelledCount(appCancelledCount);
      setRequestCount(requestCount);
      setAppresData(appRes.data.message);

      console.log("dtArr--------", dtArr);
      setDateWiseArr(dtArr);

      // const demoRes = await adminApi.getAllDemoRequest(from, to);
      // console.log("demoRes----", demoRes);

      // let demoInProgressCount = 0,
      //   demoClosedCount = 0,
      //   demoCancelledCount = 0;
      // for (let i = 0; i < demoRes.data.data.length; i++) {
      //   if (demoRes.data.data[i].status == "In Progress") {
      //     demoInProgressCount++;
      //   }
      //   if (demoRes.data.data[i].status == "Completed") {
      //     demoClosedCount++;
      //   }
      //   if (demoRes.data.data[i].status == "Cancelled") {
      //     demoCancelledCount++;
      //   }
      // }
      // setDemoInProgressCount(demoInProgressCount);
      // setDemoClosedCount(demoClosedCount);
      // setDemoCancelledCount(demoCancelledCount);

      // setDemoData(demoRes.data.data);

      // const templateRes = await adminApi.getAllTreatmentTemplate(from, to);
      // console.log("templateRes----", templateRes);
      // setTemplateData(templateRes.data.data);

      // const medicineRes = await adminApi.getAllMedicine(from, to);
      // console.log("medicineRes----", medicineRes);
      // setMedicineData(medicineRes.data.data);

      // const labTestRes = await adminApi.getAllLabTest(from, to);
      // console.log("labTestRes----", labTestRes);
      // setLabTestData(labTestRes.data.data);

      // const symptomRes = await adminApi.getAllSymptom(from, to);
      // console.log("symptomRes----", symptomRes);
      // setSymptomData(symptomRes.data.data);

      // const diagnosisRes = await adminApi.getAllDiagnosis(from, to);
      // console.log("diagnosisRes----", diagnosisRes);
      // setDiagnosisData(diagnosisRes.data.data);

      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getDoctorList ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getDoctorList();
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="row no-gutters">
              <div className="mb-4">
                <div className="pd">
                  <div>
                    <h1 className="content-heading mb-4">Dashboard</h1>
                  </div>
                  <div className="row mb-5">
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">From :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="fromDate"
                          id="fromDate"
                          openToDate={fromDate}
                          selected={fromDate}
                          onChange={(date) => {
                            setFromDate(date);
                          }}
                          minDate={new Date("2022-01-01")}
                          maxDate={toDate}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">To :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="toDate"
                          id="toDate"
                          openToDate={toDate}
                          selected={toDate}
                          onChange={(date) => {
                            setToDate(date);
                          }}
                          minDate={fromDate}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="mt-4">
                    <div>
                      <b>
                        Report from {moment(fromDate).format("DD-MM-yyyy")} to{" "}
                        {moment(toDate).format("DD-MM-yyyy")} -{" "}
                        {/* {dateWiseArr.length} */}
                      </b>
                    </div>
                    {dateWiseArr.map((item, index) => (
                      <>
                        <div className="mt-3">
                          <b>Doctor</b>
                        </div>
                        <div>
                          No. of Doctor: {data.length} - {index}
                        </div>
                        <div>No. of Doctor Activated: {activatedCount}</div>
                        <div>No. of Doctor Disabled: {disabledCount}</div>
                        <div className="mt-2">
                          No. of Doctor Registeration Completed:{" "}
                          {completedCount}
                        </div>
                        <div>
                          No. of Doctor Registeration In Progress:{" "}
                          {inProgressCount}
                        </div>
                        <div>
                          No. of Doctor Registeration Cancelled:{" "}
                          {cancelledCount}
                        </div>
                        <div className="mt-3">
                          <b>Appointment</b>
                        </div>
                        <div>No. of Appointment: {appresData.length}</div>
                        <div>No. of Open Appointment: {openCount}</div>
                        <div>No. of Requested Appointment: {requestCount}</div>
                        <div>No. of Closed Appointment: {closedCount}</div>
                        <div>
                          No. of Cancelled Appointment: {appCancelledCount}
                        </div>
                      </>
                    ))}

                    {/* <div className="mt-3">
                      <b>Demo</b>
                    </div>
                    <div>No. of Demo Request: {demoData.length}</div>
                    <div>
                      No. of In Progres Demo Request: {demoInProgressCount}
                    </div>
                    <div>No. of Completed Demo Request: {demoClosedCount}</div>
                    <div>
                      No. of Cancelled Demo Request: {demoCancelledCount}
                    </div>
                    <div className="mt-3">
                      <b>Treatment Plan Template</b>
                    </div>
                    <div>
                      No. of Treatment Template added: {templateData.length}
                    </div>
                    <div className="mt-3">
                      <b>Medicine</b>
                    </div>
                    <div>No. of Medicine added: {medicineData.length}</div>
                    <div className="mt-3">
                      <b>Lab Test</b>
                    </div>
                    <div>No. of Lab Test added: {labTestData.length}</div>
                    <div className="mt-3">
                      <b>Symptom</b>
                    </div>
                    <div>No. of Symptom added: {symptomData.length}</div>
                    <div className="mt-3">
                      <b>Disease / Condition</b>
                    </div>
                    <div>
                      No. of Disease / Condition added: {diagnosisData.length}
                    </div> */}
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminDashboard;
