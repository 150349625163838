import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import ShowMessage from "./../component/common/ShowMessage";
import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import string from "../string";
import pen from "../assests/svg/pen.svg";
import storage from "../auth/storage";
const prefixerror = "TreatmentTemplateList";

function TreatmentTemplateList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [treatmentTemplate, settreatmentTemplate] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [searchString, setSearchString] = useState("[]");
  const [practitioner, setPractitioner] = useState("");
  const [message, setMessage] = useState("");
  const navigate = useNavigate();
  let sno = limitStart + 1;
  const [role, setRole] = useState("");
  //#endregion

  const handleMove = async (tempateId) => {
    try {
      setLoad(true);
      const editRes = await doctorApi.editTemplateOwner(tempateId);
      if (!editRes.ok) {
        ErrorLog(
          prefixerror + " handleMove " + "editTemplateOwner",
          JSON.stringify(editRes),
          "We are facing some issues.Please try again after sometime.",
          "tempateId:" + tempateId
        );
        return;
      }
      console.log("editRes---", editRes);
      setShowSuccess(true);
      setMessage("Template moved to global list successfully");
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      getTreatmentTemplateList(0, `[]`);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleMove ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleDeleteTreatment = async (treatmentName) => {
    try {
      setLoad(true);
      const delRes = await doctorApi.deleteTreatmentTemplate(treatmentName);
      if (!delRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteTreatment " + "deleteTreatmentTemplate",
          JSON.stringify(delRes),
          "We are facing some issues.Please try again after sometime.",
          "treatmentName:" + treatmentName
        );
        return;
      }
      console.log("delRes---", delRes);
      setShowSuccess(true);
      getTreatmentTemplateList(0, "[]");
      setMessage("Treatment Template deleted successfully");
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " handleDeleteTreatment ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleEditTreatment = async (treatmentName) => {
    navigate("/AddTreatmentTemplate", { state: { treatmentName } });
  };

  const getTreatmentTemplateList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      setRole(role);

      if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
        navigate("/");
        return;
      }
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const practitioner = await storage.getID();
      setPractitioner(practitioner);
      let or_filters = "[]";
      if (role != "SuperAdmin") {
        or_filters = `[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]`;
      }
      const allRes = await doctorApi.getTotalCountByDocType(
        "Treatment Plan Template",
        searchString,
        or_filters
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + " getTreatmentTemplateList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "Treatment Plan Template: searchString:" +
            searchString +
            "or_filters:" +
            or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);
      console.log("searchString---", searchString);
      const res = await doctorApi.getAllTreatmentTemplate(
        searchString,
        or_filters,
        limitStart,
        pageSize
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror +
            " getTreatmentTemplateList " +
            "getAllTreatmentTemplate",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" +
            searchString +
            "or_filters:" +
            or_filters +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      console.log("data==", res);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " getTreatmentTemplateList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    console.log("page change searchString==", searchString);
    getTreatmentTemplateList(lmtst, searchString);
  };

  const handleClick = (treatmentName) => {
    console.log("------", treatmentName);
    navigate("/viewtreatmenttemplate", { state: { treatmentName } });
  };

  const handleSelectTreatmentTemplate = async (e) => {
    console.log(e, "e");
    if (e && e.length > 0) {
      settreatmentTemplate(e);
      console.log(`[["name","=","${e[0].name}"]]`);
      setSearchString(`[["name","=","${e[0].name}"]]`);
      getTreatmentTemplateList(limitStart, `[["name","=","${e[0].name}"]]`);
    } else {
      getTreatmentTemplateList(limitStart, `[]`);
      settreatmentTemplate();
    }
    setTreatment([]);
  };

  const handleSearchTreatmentTemplate = async (value) => {
    try {
      setLoad(true);
      console.log("value---", value);
      setTreatment([]);
      const practitioner = await storage.getID();
      if (value.length > 2) {
        const res = await doctorApi.searchTreatmentTemplate(
          value,
          practitioner
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror +
              " handleSearchTreatmentTemplate " +
              "searchTreatmentTemplate",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res--", res.data.data);
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].template_name =
            res.data.data[i].template_name +
            " ( " +
            res.data.data[i].patient_age_from +
            " - " +
            res.data.data[i].patient_age_to +
            " ) ";
        }
        setTreatment(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log("ex--", error);
      ErrorLog(
        prefixerror + " handleSearchTreatmentTemplate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getTreatmentTemplateList(0, `[]`);
  }, []);
  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd">
                <div className="row">
                  <h1>Treatment Template</h1>
                </div>
                <div className="row no-gutters">
                  <div className="col-lg-4 mt-2">
                    <div className="row">
                      <Multiselect
                        name="treatmentTemplate"
                        id="treatmentTemplate"
                        options={treatment}
                        showArrow={true}
                        selectedValues={treatmentTemplate}
                        placeholder={"Template Name"}
                        onSelect={handleSelectTreatmentTemplate}
                        onRemove={handleSelectTreatmentTemplate}
                        displayValue="template_name"
                        customCloseIcon={" "}
                        hidePlaceholder={true}
                        selectionLimit={1}
                        avoidHighlightFirstOption={true}
                        customArrow={
                          <span
                            className="icofont-search"
                            style={{
                              fontSize: "15px",
                            }}
                          ></span>
                        }
                        onSearch={(e) => {
                          handleSearchTreatmentTemplate(
                            document.getElementById("treatmentTemplate_input")
                              .value
                          );
                          handleSelectTreatmentTemplate("");
                        }}
                        emptyRecordMsg={
                          <div className="notFoundSearch">Type to search</div>
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-6"></div>
                  <div className="form-group col-lg-2 text-right">
                    <button
                      title="Add Template"
                      className="btn-2 m-2"
                      onClick={(e) => {
                        navigate("/addtreatmenttemplate");
                      }}
                    >
                      <span className="icofont icofont-plus"></span>
                      Add Template
                    </button>
                  </div>
                </div>
                <ShowMessage view={showSuccess} Message={message} />
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} treatment template identified.
                  </div>
                  {data.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th>Template Name</th>
                              <th width="10%">Age From</th>
                              <th width="10%">Age To</th>
                              {role == string.SUPERADMIN_ROLE && (
                                <>
                                  <th width="12%">Created By</th>
                                  <th width="12%">Move to Global list</th>
                                </>
                              )}
                              <th width="5%">Update</th>
                              <th width="5%">View</th>
                              <th width="5%">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{sno++}</td>
                                  <td className="nowrapWhiteSpace">
                                    {item.template_name}
                                  </td>
                                  <td>{item.patient_age_from}</td>
                                  <td>{item.patient_age_to}</td>
                                  {role == "SuperAdmin" && (
                                    <>
                                      <td>
                                        {item.owner != string.adminEmail
                                          ? item.owner
                                          : ""}
                                      </td>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail ? (
                                          <a
                                            onClick={(e) => {
                                              handleMove(item.name);
                                            }}
                                          >
                                            Move
                                          </a>
                                        ) : null}
                                      </td>
                                    </>
                                  )}
                                  <td className="pointer text-center">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() =>
                                          handleEditTreatment(item.name)
                                        }
                                      >
                                        <img
                                          src={pen}
                                          alt="Treatment Template"
                                        />
                                      </a>
                                    ) : null}
                                  </td>
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn-3"
                                      title="View Prescription"
                                      onClick={() => handleClick(item.name)}
                                    >
                                      <span className="icofont-prescription"></span>
                                      View
                                    </button>
                                  </td>
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() =>
                                          handleDeleteTreatment(item.name)
                                        }
                                      >
                                        Delete
                                      </a>
                                    ) : null}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <table
                          style={{
                            borderBottom: 0,
                            backgroundColor: "#f6f4f4",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-4">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default TreatmentTemplateList;
