import React, { useEffect, useState } from "react";
import { Route, Routes, useNavigate, Navigate } from "react-router-dom";

import Footer from "./component/Footer";
import ErrorLog from "./component/common/ErrorLog";

import AuthContext from "./auth/context";
import storage from "./auth/storage";

import Register from "./pages/Register";
import TermsandCondition from "./pages/TermsandCondition";
import AboutUs from "./pages/AboutUs";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import FAQ from "./pages/FAQ";
import Contact from "./pages/Contact";
import Welcome from "./pages/Welcome";
import Demo from "./pages/Demo";

import Appointment from "./doctorRegistration/Appointment";
import BasicInformation from "./doctorRegistration/BasicInformation";
import Education from "./doctorRegistration/Education";
import BankInformation from "./doctorRegistration/BankInformation";
import DoctorMobileOTP from "./doctorRegistration/DoctorMobileOTP";
import Success from "./doctorRegistration/Success";

import ConsultationHistory from "./doctorHome/ConsultationHistory";
import DoctorHeader from "./doctorHome/DoctorHeader";
import DoctorDashBoard from "./doctorHome/DoctorDashBoard";
import EndConsultation from "./doctorHome/EndConsultation";
import NewAppointment from "./doctorHome/NewAppointment";
import NewPatient from "./doctorHome/NewPatient";
import PatientHealthRecord from "./doctorHome/PatientHealthRecord";
import PatientList from "./doctorHome/PatientList";
import StartConsultation from "./doctorHome/StartConsultation";
import AddTreatmentTemplate from "./doctorHome/AddTreatmentTemplate";
import UpcomingAppointments from "./doctorHome/UpcomingAppointment";
import TreatmentTemplateList from "./doctorHome/TreatmentTemplateList";
import MedicineList from "./doctorHome/MedicineList";
import LabTestList from "./doctorHome/LabTestList";
import Invoice from "./doctorHome/Invoice";
import InvoiceList from "./doctorHome/InvoiceList";
import NewUser from "./doctorHome/NewUser";
import ViewInvoice from "./doctorHome/ViewInvoice";
import UserList from "./doctorHome/UserList";
import AddReport from "./doctorHome/AddReport";
import ViewPatient from "./doctorHome/ViewPatient";
import InvoiceReport from "./doctorHome/InvoiceReport";
import SymptomsList from "./doctorHome/SymptomsList";
import DiagnosisList from "./doctorHome/DiagnosisList";
import Configuration from "./doctorHome/Configuration";
import Logout from "./doctorHome/logout";
import VerifyEmail from "./doctorHome/VerifyEmail";
import ApproveDoctor from "./doctorRegistration/ApproveDoctor";
import SmartAssistant from "./doctorHome/SmartAssistant";
import ViewTreatmentTemplate from "./doctorHome/ViewTreatmentTemplate";
import OnlineInvoiceReport from "./doctorHome/OnlineInvoiceReport";

import ReportAppointment from "./admin/ReportAppointment";
import ReportUTMParam from "./admin/ReportUTMParam";
import ReportDoctor from "./admin/ReportDoctor";
import AdminDashboard from "./admin/AdminDashboard";
import AllUserList from "./admin/AllUserList";
import PatientUserList from "./admin/PatientUserList";
import DoctorList from "./admin/DoctorList";
import CompanyList from "./admin/CompanyList";
import ViewDoctor from "./admin/ViewDoctor";
import AllPatient from "./admin/AllPatient";
import AdminConfiguration from "./admin/AdminConfiguration";
import AllOnlineInvoiceReport from "./admin/AllOnlineInvoiceReport";
import AllOnlineInvoiceReturnReport from "./admin/AllOnlineInvoiceReturnReport";
import DemoList from "./admin/DemoList";
import UpdateDemo from "./admin/UpdateDemo";

import DoctorRegisterOTP from "./doctorRegistration/DoctorRegisterOTP";

import doctorApi from "./api/doctorApi";
import doctorRegApi from "./api/doctorRegApi";

import "./App.css";

const prefixerror = "App";

function App() {
  //#region const
  const [adminToken, setAdminToken] = useState("");
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [lname, setLName] = useState();
  const [doctorID, setDoctorID] = useState();
  const [scheduleName, setScheduleName] = useState();
  const [profile, setProfile] = useState(0);
  const [field, setField] = useState(0);
  const [role, setRole] = useState();
  const [owner, setOwner] = useState();
  const [staffName, setStaffName] = useState();
  const [company, setCompany] = useState();
  const [companyFullName, setCompanyFullName] = useState();
  const [serviceUnit, setServiceUnit] = useState();
  const [dp, setDp] = useState();
  const [staffdp, setStaffDp] = useState();
  const [ownerSecretKey, setOwnerSecretKey] = useState();
  const [status, setStatus] = useState();
  const [heightConfig, setHeightConfig] = useState(0);
  const [weightConfig, setWeightConfig] = useState(0);
  const [bloodPressureConfig, setBloodPressureConfig] = useState(0);
  const [durationConfig, setDurationConfig] = useState(0);
  const [pastHistoryConfig, setPastHistoryConfig] = useState(0);
  const [examinationConfig, setExaminationConfig] = useState(0);
  const [personalHabitConfig, setPersonalHabitConfig] = useState(0);
  const [labTestConfig, setLabTestConfig] = useState(0);
  const [scanConfig, setScanConfig] = useState(0);
  const [patientNoteConfig, setPatientNoteConfig] = useState(0);
  const [doctorNoteConfig, setDoctorNoteConfig] = useState(0);
  const [paymentConfig, setPaymentConfig] = useState(0);
  const [whatsAppConfig, setWhatsAppConfig] = useState(0);
  const [attachLabReportConfig, setAttachLabReportConfig] = useState(0);
  const [otpPatientConfig, setOtpPatientConfig] = useState(0);
  const [prescriptionHeightConfig, setPrescriptionHeightConfig] = useState(0);
  const [prescriptionWeightConfig, setPrescriptionWeightConfig] = useState(0);
  const [prescriptionBMIConfig, setPrescriptionBMIConfig] = useState(0);
  const [prescriptionBPConfig, setPrescriptionBPConfig] = useState(0);
  const [prescriptionSymptomConfig, setPrescriptionSymptomConfig] = useState(0);
  const [
    prescriptionSymptomDurationConfig,
    setPrescriptionSymptomDurationConfig,
  ] = useState(0);
  const [prescriptionDiagnosisConfig, setPrescriptionDiagnosisConfig] =
    useState(0);
  const [prescriptionPastHistoryConfig, setPrescriptionPastHistoryConfig] =
    useState(0);
  const [prescriptionExaminationConfig, setPrescriptionExaminationConfig] =
    useState(0);
  const [prescriptionPersonalHabitConfig, setPrescriptionPersonalHabitConfig] =
    useState(0);
  const [prescriptionLabTestConfig, setPrescriptionLabTestConfig] = useState(0);
  const [prescriptionScanConfig, setPrescriptionScanConfig] = useState(0);
  const [gMeetConfig, setGMeetConfig] = useState(0);
  const [prescriptionPatientNotesConfig, setPrescriptionPatientNotesConfig] =
    useState(0);
  const [prescriptionDoctorNotesConfig, setPrescriptionDoctorNotesConfig] =
    useState(0);
  const [smartAssistant, setSmartAssistant] = useState(0);
  const [patientAddressConfig, setPatientAddressConfig] = useState(0);
  const [patientEmailConfig, setPatientEmailConfig] = useState(0);
  const navigate = useNavigate();
  //#endregion

  const getDoctor = async () => {
    try {
      const doctorID = await storage.getID();
      console.log("doctorID---", doctorID);
      const role = await storage.getRole();
      console.log("role---", role);
      if (doctorID && role != "SuperAdmin" && role != "Ad Manager") {
        const doctorRes = await doctorRegApi.getDetilsBasicInfo(doctorID);
        if (!doctorRes.ok) {
          ErrorLog(
            prefixerror + "getDoctor " + "getDetilsBasicInfo",
            JSON.stringify(doctorRes),
            "We are facing some issues.Please try again after sometime.",
            "doctorID:" + doctorID
          );
          return;
        }
        console.log("doctorRes----", doctorRes);
        if (doctorRes.data.data.status != "Active") {
          navigate("/basicinformation");
          return;
        }
      }
      // const role = await storage.getRole();
      setRole(role);
      setDoctorID(doctorID);
      const status = await storage.getStatus();
      // console.log("Doctor Status", status);
      setStatus(status);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "getDoctor",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };
  console.log = function () {};

  const getConfiguration = async () => {
    try {
      const doctorEmail = await storage.getID();
      const role = await storage.getRole();
      console.log("role here---", role);
      if (doctorEmail && role != "SuperAdmin" && role != "Ad Manager") {
        const generalRes = await doctorApi.getConfiguration(doctorEmail);
        console.log("generalRes---", generalRes);
        if (!generalRes.ok) {
          ErrorLog(
            prefixerror + "getConfiguration " + "getDetilsBasicInfo",
            JSON.stringify(generalRes),
            "We are facing some issues.Please try again after sometime.",
            "doctorEmail:" + doctorEmail
          );
          return;
        }

        if (generalRes.data.data.length == 0) {
          const generalPostRes = await doctorApi.createConfiguration(
            doctorEmail
          );
          if (!generalPostRes.ok) {
            ErrorLog(
              prefixerror + "getConfiguration " + "createConfiguration",
              JSON.stringify(generalPostRes),
              "We are facing some issues.Please try again after sometime.",
              "doctorEmail:" + doctorEmail
            );
            return;
          }
          console.log("generalPostRes---", generalPostRes);
        } else {
          setOtpPatientConfig(generalRes.data.data[0].otp_patient);
          setPaymentConfig(generalRes.data.data[0].payment);
          setWhatsAppConfig(generalRes.data.data[0].whatsapp_prescription);
          setGMeetConfig(generalRes.data.data[0].google_meet);
          setAttachLabReportConfig(generalRes.data.data[0].attach_lab_report);
          setHeightConfig(generalRes.data.data[0].height);
          setWeightConfig(generalRes.data.data[0].weight);
          setBloodPressureConfig(generalRes.data.data[0].blood_pressure);
          setDurationConfig(generalRes.data.data[0].duration_in_symptom);
          setPastHistoryConfig(generalRes.data.data[0].past_history);
          setExaminationConfig(generalRes.data.data[0].examination);
          setPersonalHabitConfig(generalRes.data.data[0].personal_habit);
          setLabTestConfig(generalRes.data.data[0].lab_test);
          setScanConfig(generalRes.data.data[0].scan);
          setPatientNoteConfig(generalRes.data.data[0].note_for_patient);
          setDoctorNoteConfig(generalRes.data.data[0].note_for_doctor);
          setPrescriptionHeightConfig(
            generalRes.data.data[0].prescription_height
          );
          setPrescriptionWeightConfig(
            generalRes.data.data[0].prescription_weight
          );
          setPrescriptionBMIConfig(generalRes.data.data[0].prescription_bmi);
          setPrescriptionBPConfig(
            generalRes.data.data[0].prescription_blood_pressure
          );
          setPrescriptionSymptomConfig(
            generalRes.data.data[0].prescription_symptom
          );
          setPrescriptionSymptomDurationConfig(
            generalRes.data.data[0].prescription_symptom_duration
          );
          setPrescriptionDiagnosisConfig(
            generalRes.data.data[0].prescription_diagnosis
          );
          setPrescriptionPastHistoryConfig(
            generalRes.data.data[0].prescription_past_history
          );
          setPrescriptionExaminationConfig(
            generalRes.data.data[0].prescription_examination
          );
          setPrescriptionPersonalHabitConfig(
            generalRes.data.data[0].prescription_personal_habit
          );
          setPrescriptionLabTestConfig(
            generalRes.data.data[0].prescription_labt_test
          );
          setPrescriptionScanConfig(generalRes.data.data[0].prescription_scan);
          setPrescriptionPatientNotesConfig(
            generalRes.data.data[0].prescription_patient_note
          );
          setPrescriptionDoctorNotesConfig(
            generalRes.data.data[0].prescription_doctor_note
          );
          setSmartAssistant(generalRes.data.data[0].smart_assistant);
          setPatientAddressConfig(generalRes.data.data[0].patient_address);
          setPatientEmailConfig(generalRes.data.data[0].patient_email);
        }
      }
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getConfiguration",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getAdminToken = async () => {
    const tkn = await storage.getSuperAdminToken();
    console.log(tkn, "tkn");
    setAdminToken(tkn);
    if (!tkn) {
      const keyRes = await doctorApi.getSuperAdminKey();
      console.log("keyRes-----", keyRes);
      if (!keyRes.ok) {
        ErrorLog(
          prefixerror + "getAdminToken " + "getSuperAdminKey",
          JSON.stringify(keyRes),
          "We are facing some issues.Please try again after sometime.",
          ""
        );
        return;
      }
      await storage.storeSuperAdminToken(keyRes.data.message);
      setAdminToken(keyRes.data.message);
    }
  };

  useEffect(() => {
    getAdminToken();
    getConfiguration();
    getDoctor();
  }, []);

  return (
    <>
      <AuthContext.Provider
        value={{
          name,
          setName,
          lname,
          setLName,
          email,
          setEmail,
          doctorID,
          setDoctorID,
          scheduleName,
          setScheduleName,
          profile,
          setProfile,
          field,
          setField,
          role,
          setRole,
          owner,
          setOwner,
          ownerSecretKey,
          setOwnerSecretKey,
          staffName,
          setStaffName,
          company,
          setCompany,
          companyFullName,
          setCompanyFullName,
          serviceUnit,
          setServiceUnit,
          dp,
          setDp,
          staffdp,
          setStaffDp,
          heightConfig,
          setHeightConfig,
          weightConfig,
          setWeightConfig,
          bloodPressureConfig,
          setBloodPressureConfig,
          durationConfig,
          setDurationConfig,
          pastHistoryConfig,
          setPastHistoryConfig,
          examinationConfig,
          setExaminationConfig,
          personalHabitConfig,
          setPersonalHabitConfig,
          labTestConfig,
          setLabTestConfig,
          scanConfig,
          setScanConfig,
          patientNoteConfig,
          setPatientNoteConfig,
          doctorNoteConfig,
          setDoctorNoteConfig,
          paymentConfig,
          setPaymentConfig,
          whatsAppConfig,
          setWhatsAppConfig,
          attachLabReportConfig,
          setAttachLabReportConfig,
          otpPatientConfig,
          setOtpPatientConfig,
          prescriptionHeightConfig,
          setPrescriptionHeightConfig,
          prescriptionWeightConfig,
          setPrescriptionWeightConfig,
          prescriptionBMIConfig,
          setPrescriptionBMIConfig,
          prescriptionBPConfig,
          setPrescriptionBPConfig,
          prescriptionSymptomConfig,
          setPrescriptionSymptomConfig,
          prescriptionSymptomDurationConfig,
          setPrescriptionSymptomDurationConfig,
          prescriptionDiagnosisConfig,
          setPrescriptionDiagnosisConfig,
          prescriptionPastHistoryConfig,
          setPrescriptionPastHistoryConfig,
          prescriptionExaminationConfig,
          setPrescriptionExaminationConfig,
          prescriptionPersonalHabitConfig,
          setPrescriptionPersonalHabitConfig,
          prescriptionLabTestConfig,
          setPrescriptionLabTestConfig,
          prescriptionScanConfig,
          setPrescriptionScanConfig,
          gMeetConfig,
          setGMeetConfig,
          prescriptionPatientNotesConfig,
          setPrescriptionPatientNotesConfig,
          prescriptionDoctorNotesConfig,
          setPrescriptionDoctorNotesConfig,
          smartAssistant,
          setSmartAssistant,
          patientAddressConfig,
          setPatientAddressConfig,
          patientEmailConfig,
          setPatientEmailConfig,
        }}
      >
        <Routes>
          <Route path="*" element={<Navigate to="/" />} />
          <Route
            path="/"
            element={
              role == "SuperAdmin" ? (
                <DoctorList />
              ) : role == "Ad Manager" ? (
                <ReportUTMParam />
              ) : doctorID !== null && status === "Active" ? (
                <DoctorDashBoard />
              ) : (
                <Welcome />
              )
            }
          />
          <Route
            exact
            path="/doctorregisterotp"
            element={<DoctorRegisterOTP />}
          />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/doctormobileotp" element={<DoctorMobileOTP />} />
          <Route path="/smartassistant" element={<SmartAssistant />} />
          <Route path="/basicinformation" element={<BasicInformation />} />
          {/* <Route path="/googlemeet" element={<GoogleMeet />} /> */}
          <Route path="/appointment" element={<Appointment />} />
          <Route path="/education" element={<Education />} />
          <Route path="/doctorhome" element={<DoctorHeader />} />
          <Route path="/doctordashboard" element={<DoctorDashBoard />} />
          <Route path="/labtestlist" element={<LabTestList />} />
          <Route path="/medicinelist" element={<MedicineList />} />
          <Route path="/newpatient" element={<NewPatient />} />
          <Route path="/newuser" element={<NewUser />} />
          <Route path="/patientlist" element={<PatientList />} />
          <Route path="/newappointment" element={<NewAppointment />} />
          <Route path="/patientrecord" element={<PatientHealthRecord />} />
          <Route path="/invoice" element={<Invoice />} />
          <Route path="/invoicelist" element={<InvoiceList />} />
          <Route path="/invoicereport" element={<InvoiceReport />} />
          <Route path="/success" element={<Success />} />
          <Route path="/bankinformation" element={<BankInformation />} />
          <Route path="/symptomslist" element={<SymptomsList />} />
          <Route path="/diagnosislist" element={<DiagnosisList />} />
          <Route
            path="/onlineinvoicereport"
            element={<OnlineInvoiceReport />}
          />

          <Route
            path="/treatmenttemplatelist"
            element={<TreatmentTemplateList />}
          />
          <Route
            path="/viewtreatmenttemplate"
            element={<ViewTreatmentTemplate />}
          />
          <Route
            path="/upcomingappointment"
            element={<UpcomingAppointments />}
          />
          <Route path="/reportappointment" element={<ReportAppointment />} />
          <Route path="/reportutmparam" element={<ReportUTMParam />} />
          <Route path="/reportdoctor" element={<ReportDoctor />} />
          <Route path="/admindashboard" element={<AdminDashboard />} />
          <Route path="/alluserlist" element={<AllUserList />} />
          <Route path="/patientuserlist" element={<PatientUserList />} />
          <Route path="/allpatient" element={<AllPatient />} />
          <Route
            path="/consultationhistory"
            element={<ConsultationHistory />}
          />
          <Route path="/startconsultation" element={<StartConsultation />} />
          <Route
            path="/addtreatmenttemplate"
            element={<AddTreatmentTemplate />}
          />
          <Route path="/endconsultation" element={<EndConsultation />} />
          <Route path="/doctorlist" element={<DoctorList />} />
          <Route path="/companylist" element={<CompanyList />} />
          <Route path="/viewdoctor" element={<ViewDoctor />} />
          <Route path="/approvedoctor" element={<ApproveDoctor />} />
          <Route path="/viewinvoice" element={<ViewInvoice />} />
          <Route path="/userlist" element={<UserList />} />
          <Route path="/addreport" element={<AddReport />} />
          <Route path="/viewpatient" element={<ViewPatient />} />
          <Route path="/configuration" element={<Configuration />} />
          <Route path="/verifyemail" element={<VerifyEmail />} />

          <Route path="/adminconfiguration" element={<AdminConfiguration />} />
          <Route
            path="/allonlineinvoicereport"
            element={<AllOnlineInvoiceReport />}
          />
          <Route
            path="/allonlineinvoicereturnreport"
            element={<AllOnlineInvoiceReturnReport />}
          />
          <Route path="/demolist" element={<DemoList />} />
          <Route path="/updatedemo" element={<UpdateDemo />} />
          <Route path="/termsandcondition" element={<TermsandCondition />} />
          <Route path="/aboutus" element={<AboutUs />} />
          <Route path="/privacypolicy" element={<PrivacyPolicy />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/welcome" element={<Welcome />} />
          <Route path="/demo" element={<Demo />} />
          <Route path="/logout" element={<Logout />} />
        </Routes>
      </AuthContext.Provider>
    </>
  );
}

export default App;
