import log from "../../api/log";
import storage from "../../auth/storage";
import string from "../../string";

const ErrorLog = async (
  url,
  rawErrorResponse,
  errorMessage,
  parameter = ""
) => {
  try {
    const emailId = await storage.getID();
    const res = await log.sendError(
      string.version + " " + url,
      parameter,
      "Web",
      emailId ? emailId : "guest",
      JSON.stringify(rawErrorResponse),
      JSON.stringify(errorMessage)
    );
    console.log("errorlogRes----------", res);
  } catch (error) {
    console.log("Error---", error);
  }
};
export default ErrorLog;
