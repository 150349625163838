import { create } from "apisauce";
import storage from "../auth/storage";
import string from "../string";

const apiClient = create({
  baseURL: string.testbaseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});
apiClient.addAsyncRequestTransform(async (request) => {
  const admintoken = await storage.getSuperAdminToken();
  console.log("admintoken--", admintoken);
  request.headers["Authorization"] = "token " + admintoken;
});

export default apiClient;
