import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import Select from "../component/common/select";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import { months, comment } from "../component/common/CommonArray";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
import AuthContext from "../auth/context";
import "react-datepicker/dist/react-datepicker.css";
const prefixerror = "StartConsultation";

function StartConsultation() {
  //#region const
  const {
    durationConfig,
    pastHistoryConfig,
    examinationConfig,
    personalHabitConfig,
    labTestConfig,
    scanConfig,
    patientNoteConfig,
    doctorNoteConfig,
  } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [symptom, setSymptom] = useState([]);
  const [diag, setDiagnosis] = useState([]);
  const [symptomSel, setSymptomSel] = useState([]);
  const [diagnosisSel, setDiagnosisSel] = useState([]);
  const [drugs, setMedi] = useState([]);
  const [medName, setMedName] = useState();
  const [medCode, setMedCode] = useState();
  const [genericName, setGenericName] = useState();
  const [consump, setConsump] = useState();
  const [intakes, setIntake] = useState();
  const [period, setPeriod] = useState();
  const [load, setLoad] = useState(false);
  const [labtestName, setLabTestName] = useState("");
  const [comments, setComments] = useState();
  const [data, setData] = useState([]);
  const [consumption, setConsumption] = useState([]);
  const [periodOfDays, setPeriodOfDays] = useState([]);
  const [labTestCode, setLabTestCode] = useState("");
  const [medicines, setMedicines] = useState([]);
  const [medicineSel, setMedicineSel] = useState([]);
  const [labList, setlabList] = useState([]);
  const [lab, setLabTest] = useState([]);
  const [errorSymptom, setErrorSymptom] = useState(false);
  const [errorDiagnosis, setErrorDiagnosis] = useState(false);
  const [showMedicine, setShowMedicine] = useState(false);
  const [showConsump, setShowConsump] = useState(false);
  const [showIntake, setShowIntake] = useState(false);
  const [showPeriod, setShowPeriod] = useState(false);
  const [showLab, setShowLab] = useState(false);
  const [showAddMedicine, setShowAddMedicine] = useState(false);
  const [showAddLab, setShowAddLab] = useState(false);
  const [patientNotes, setPatientNotes] = useState();
  const [doctorNotes, setDoctorNotes] = useState();
  const [dosageInstruction, setDosageInstruction] = useState();
  const [scan, setScan] = useState();
  const [openModel, setOpenModal] = useState(false);
  const [saveOpenModal, setSaveOpenModal] = useState(false);
  const [consultationHistory, setConsultationHistory] = useState([]);
  const [durationSymptoms, setDurationSymptoms] = useState();
  const [pastHistory, setPastHistory] = useState();
  const [examination, setExamination] = useState();
  const [personalHabits, setPersonalHabits] = useState();
  const [selectedLabList, setselectedLabList] = useState([]);
  const [vitalData, setVitalData] = useState([]);
  const [appdate, setAppDate] = useState();
  const dte = new Date();
  const years = preFunction.range(dte.getFullYear(), dte.getFullYear() + 1, 1);
  const DateCustomInput = preFunction.DateCustomInput;
  const [templateName, setTemplateName] = useState("");
  const [templateNameError, setTemplateNameError] = useState(false);
  const [templateSuccess, setTemplateSuccess] = useState(false);
  const [description, setDescription] = useState();
  const [patientAgeFrom, setPatientAgeFrom] = useState("");
  const [patientAgeTo, setPatientAgeTo] = useState("");
  const [patientAgeFromError, setPatientAgeFromError] = useState(false);
  const [patientAgeToError, setPatientAgeToError] = useState(false);
  const [treatmentTemplate, settreatmentTemplate] = useState([]);
  const [treatment, setTreatment] = useState([]);
  const [addMedicineModal, setAddMedicineModal] = useState(false);
  const [medicineNameToAdd, setMedicineNameToAdd] = useState("");
  const [genericNameToAdd, setGenericNameToAdd] = useState("");
  const [medicineNameError, setMedicineNameError] = useState(false);
  const [genericNameError, setGenericNameError] = useState(false);
  const [medicineSuccess, setMedicineSuccess] = useState(false);
  const [message, setMessage] = useState();
  const [addLabModal, setAddLabModal] = useState(false);
  const [labTestNameToAdd, setLabTestNameToAdd] = useState("");
  const [labTestCodeToAdd, setLabTestCodeToAdd] = useState("");
  const [labTestNameError, setLabTestNameError] = useState(false);
  const [labTestCodeError, setLabTestCodeError] = useState(false);
  const [labTestSuccess, setlabTestSuccess] = useState(false);
  const [item, setItem] = useState();
  //#endregion

  const handleLabTestAdd = async () => {
    try {
      closeErrors();
      let err = false;
      if (labTestCodeToAdd == "") {
        setLabTestCodeError(true);
        document.getElementById("labTestCode").className +=
          " form-control-error";
        document.getElementById("labTestCode").focus();
        err = true;
      }
      if (labTestNameToAdd == "") {
        setLabTestNameError(true);
        document.getElementById("labTestName").className +=
          " form-control-error";
        document.getElementById("labTestName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      let Res = await doctorApi.createLabTest(
        labTestNameToAdd,
        labTestCodeToAdd
      );
      if (!Res.ok) {
        ErrorLog(
          prefixerror + " handleLabTestAdd " + "createLabTest",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "labTestNameToAdd:" +
            labTestNameToAdd +
            "labTestCodeToAdd:" +
            labTestCodeToAdd
        );
        return;
      }
      setMessage("Lab Test added successfully");
      console.log("Res---", Res);
      setlabTestSuccess(true);
      Res.data.data.name =
        Res.data.data.lab_test_code + " - " + Res.data.data.lab_test_name;
      handleSelectLab([Res.data.data]);
      setTimeout(() => {
        setLabTestNameToAdd("");
        setLabTestCodeToAdd("");
        setAddLabModal(false);
        setlabTestSuccess(false);
      }, 3000);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleLabTestAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleMedicineAdd = async () => {
    try {
      closeErrors();
      let err = false;
      if (genericNameToAdd == "") {
        setGenericNameError(true);
        document.getElementById("genericName").className +=
          " form-control-error";
        document.getElementById("genericName").focus();
        err = true;
      }
      if (medicineNameToAdd == "") {
        setMedicineNameError(true);
        document.getElementById("medicineName").className +=
          " form-control-error";
        document.getElementById("medicineName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      const Res = await doctorApi.createMedicine(
        medicineNameToAdd,
        genericNameToAdd
      );
      if (!Res.ok) {
        ErrorLog(
          prefixerror + " handleMedicineAdd " + "createMedicine",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "medicineNameToAdd:" +
            medicineNameToAdd +
            "genericNameToAdd:" +
            genericNameToAdd
        );
        return;
      }
      setMessage("Medicine added successfully");
      console.log("Res---", Res);
      setMedicineSuccess(true);
      handleSelectMedicine([Res.data.data]);
      setTimeout(() => {
        setMedicineNameToAdd("");
        setGenericNameToAdd("");
        setAddMedicineModal(false);
        setMedicineSuccess(false);
      }, 3000);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleMedicineAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSelectTreatmentTemplate = async (e) => {
    console.log(e, "e");
    if (e && e.length > 0) {
      settreatmentTemplate(e);
      console.log(`[["name","=","${e[0].name}"]]`);
      handleTreatMentPlan(e[0].name);
    } else {
      handleTreatMentPlan("");
      settreatmentTemplate();
    }
    setTreatment([]);
  };

  const handleSearchTreatmentTemplate = async (value) => {
    try {
      console.log("value---", value);
      setTreatment([]);
      const practitioner = await storage.getID();
      if (value.length > 2) {
        const res = await doctorApi.searchTreatmentTemplate(
          value,
          practitioner
        );
        console.log("res--", res.data.data);
        if (!res.ok) {
          ErrorLog(
            prefixerror +
              " handleSearchTreatmentTemplate " +
              "searchTreatmentTemplate",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        for (let i = 0; i < res.data.data.length; i++) {
          res.data.data[i].template_name =
            res.data.data[i].template_name +
            " ( " +
            res.data.data[i].patient_age_from +
            " - " +
            res.data.data[i].patient_age_to +
            " ) ";
        }
        setTreatment(res.data.data);
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchTreatmentTemplate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSaveTemplate = async () => {
    try {
      closeErrors();
      let err = false;
      if (patientAgeTo === "") {
        setPatientAgeToError(true);
        document.getElementById("patientAgeTo").focus();
        err = true;
      }
      console.log("patientAgeFrom---", patientAgeFrom);
      if (patientAgeFrom === "") {
        setPatientAgeFromError(true);
        document.getElementById("patientAgeFrom").focus();
        err = true;
      }
      if (parseInt(patientAgeTo) < parseInt(patientAgeFrom)) {
        setPatientAgeToError(true);
        document.getElementById("patientAgeTo").focus();
        err = true;
      }
      if (templateName == "") {
        setTemplateNameError(true);
        document.getElementById("templateName").focus();
        err = true;
      } else {
        setTemplateNameError(false);
      }
      if (err) {
        return;
      }
      const practitioners = [
        {
          practitioner: await storage.getID(),
        },
      ];
      let items = [];
      for (let i = 0; i < labList.length; i++) {
        items.push({
          type: "Lab Test Template",
          template: labList[i].lab_test_code,
          qty: 1,
          instructions: labList[i].lab_test_comment,
        });
      }
      console.log("items----", items);
      let drugs = [];
      for (let i = 0; i < medicines.length; i++) {
        drugs.push({
          drug_code: medicines[i].drug_code,
          drug_name: medicines[i].drug_name,
          dosage: medicines[i].dosage,
          period: medicines[i].period,
          comment: medicines[i].comment,
          dosage_form: "Tablets",
          dosage_instruction: medicines[i].dosage_instruction,
        });
      }
      console.log("drugs---", drugs);

      const addRes = await doctorApi.addTreatmentTemplate(
        templateName,
        description,
        practitioners,
        symptomSel,
        diagnosisSel,
        items,
        drugs,
        parseInt(patientAgeFrom),
        parseInt(patientAgeTo)
      );
      if (!addRes.ok) {
        ErrorLog(
          prefixerror + " handleSaveTemplate " + "addTreatmentTemplate",
          JSON.stringify(addRes),
          "We are facing some issues.Please try again after sometime.",
          "templateName:" +
            templateName +
            "description:" +
            description +
            "practitioners:" +
            practitioners +
            "symptomSel:" +
            symptomSel +
            "diagnosisSel:" +
            diagnosisSel +
            "items:" +
            items +
            "drugs:" +
            drugs +
            "patientAgeFrom:" +
            patientAgeFrom +
            "patientAgeTo:" +
            patientAgeTo
        );
        return;
      }
      console.log("addRes--------", addRes);
      setTemplateSuccess(true);
      setTemplateName("");
      setDescription("");
      setTimeout(() => {
        setTemplateSuccess(false);
        setSaveOpenModal(false);
      }, 3000);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSaveTemplate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSaveAsTemplate = async () => {
    setSaveOpenModal(true);
  };

  const handleSelectSymptom = (e) => {
    console.log("symptoms -------", symptomSel, e);
    setSymptomSel(e);
  };

  const handleSelectDiagnosis = (e) => {
    setDiagnosisSel(e);
  };

  const handleSelectMedicine = (e) => {
    setShowMedicine(false);
    console.log("e---", e);
    if (e && e.length > 0) {
      e[0].name =
        e[0].name.length > 40 ? e[0].name.substring(0, 40) + "..." : e[0].name;
      setMedicineSel(e);
      setMedName(e[0].item_name);
      setMedCode(e[0].name);
      setGenericName(e[0].generic_name);
      document.getElementById("medicine_input").blur();
    } else {
      setMedicineSel();
      setMedName();
      setMedCode();
      setGenericName();
    }
    setMedi([]);
  };

  const handleSelectLab = (e) => {
    setShowLab(false);
    console.log("e---", e);
    if (e && e.length > 0) {
      e[0].name =
        e[0].name.length > 40 ? e[0].name.substring(0, 40) + "..." : e[0].name;
      setselectedLabList(e);
      setLabTestName(e[0].name);
      setLabTestCode(e[0].lab_test_code);
      document.getElementById("labtest_input").value = "";
      document.getElementById("labtest_input").blur();
    } else {
      setselectedLabList();
      setLabTestName();
      setLabTestCode();
    }
    setLabTest([]);
  };

  const handleSearchMedicine = async (value) => {
    try {
      closeErrors();
      setMedi([]);
      console.log("value---", value);
      const practitioner = await storage.getID();
      if (value.length > 2) {
        const res = await doctorApi.searchMedicine(practitioner, value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchMedicine " + "searchMedicine",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "practitioner:" + practitioner + "value:" + value
          );
          return;
        }
        console.log("medicines--", res);
        setMedi(res.data.data);
      }
      setMedName("");
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchMedicine ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSearchLab = async (value) => {
    try {
      closeErrors();
      setLabTest([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchLab(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchLab " + "searchLab",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("lab--", res);
        for (let i = 0; i < res.data.message.length; i++) {
          res.data.message[i].name =
            res.data.message[i].lab_test_code +
            " - " +
            res.data.message[i].lab_test_name;
        }
        setLabTest(res.data.message);
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchLab ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleMedicine = async (e) => {
    closeErrors();
    let err = false;
    if (!medName) {
      setShowMedicine(true);
      err = true;
    } else {
      setShowMedicine(false);
    }
    if (!consump) {
      setShowConsump(true);
      err = true;
    } else {
      setShowConsump(false);
    }
    if (!intakes) {
      setShowIntake(true);
      err = true;
    } else {
      setShowIntake(false);
    }
    if (!period) {
      setShowPeriod(true);
      err = true;
    } else {
      setShowPeriod(false);
    }
    if (err) {
      return;
    }
    console.log("medicines---", medicines);
    let medicine = [
      ...medicines,
      {
        id: medicines.length + 1,
        drug_code: medCode,
        drug_name: medName,
        genericName: genericName,
        dosage: consump,
        comment: intakes,
        period: period,
        dosage_form: "Tablets",
        dosage_instruction: dosageInstruction,
      },
    ];
    const obj = [
      ...new Map(
        medicine.map((item) => [JSON.stringify(item.drug_name), item])
      ).values(),
    ];
    console.log("obj---", obj);
    setMedicines(obj);
    setMedName("");
    setGenericName("");
    setConsump("");
    setIntake("");
    setPeriod("");
    setDosageInstruction("");
    setMedicineSel([]);
  };

  const handleLabTest = () => {
    console.log(selectedLabList, "test");
    if (!labtestName) {
      setShowLab(true);
      return;
    } else setShowLab(false);
    console.log("labtestName---", labtestName);
    const labtest = labtestName.split(" - ")[1];
    console.log("labtestName---", labtest);
    let lab = [
      ...labList,
      {
        id: labList.length + 1,
        lab_test_code: labTestCode,
        lab_test_name: labtest,
        lab_test_comment: comments,
      },
    ];

    const obj = [
      ...new Map(
        lab.map((item) => [JSON.stringify(item.lab_test_name), item])
      ).values(),
    ];
    setlabList(obj);
    setLabTestName("");
    setLabTestCode("");
    handleSelectLab("");
    setComments("");
  };

  const handleDeleteMedicine = (item) => {
    const data = medicines.filter((m) => m !== item);
    setMedicines(data);
  };

  const handleDeleteLab = (item) => {
    const data = labList.filter((m) => m !== item);
    setlabList(data);
  };

  const handleClose = () => {
    setOpenModal(false);
    setSaveOpenModal(false);
  };

  const handleConsultationHistory = async (item) => {
    setItem(item);
    setOpenModal(true);
  };

  const getPatientDetails = async () => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      if (role === "SuperAdmin") {
        navigate("/doctorlist");
        return;
      }
      if (location.state === null) {
        setLoad(false);
        navigate("/upcomingappointment");
        return;
      }

      if (location.state && location.state.consultationID) {
        const consultationRes = await doctorApi.consultationPrescription(
          location.state.consultationID
        );
        // const consultationRes = await doctorApi.consultationSummary(
        //   location.state.consultationID
        // );
        console.log("consultationRes---", consultationRes);
        // return;
        if (!consultationRes.ok) {
          ErrorLog(
            prefixerror + " getPatientDetails " + "consultationSummary",
            JSON.stringify(consultationRes),
            "We are facing some issues.Please try again after sometime.",
            "consultationID:" + location.state.consultationID
          );
          return;
        }

        setData(consultationRes.data.message[0]);

        const resPatientRecord = await doctorApi.getPatientHealthRecord([
          [consultationRes.data.message[0].patient],
        ]);
        if (!resPatientRecord.ok) {
          ErrorLog(
            prefixerror + " getPatientDetails " + "getPatientHealthRecord",
            JSON.stringify(resPatientRecord),
            "We are facing some issues.Please try again after sometime.",
            "patient:" + consultationRes.data.message[0].patient
          );
          return;
        }
        console.log("resPatientRecord---", resPatientRecord);
        setConsultationHistory(resPatientRecord.data.message);
        setScan(consultationRes.data.message[0].scan);
        setSymptomSel(consultationRes.data.message[0].symptoms);
        setSymptom(consultationRes.data.message[0].symptoms);
        setDurationSymptoms(
          consultationRes.data.message[0]._duration_in_symptoms
        );
        setDiagnosisSel(consultationRes.data.message[0].diagnosis);
        setDiagnosis(consultationRes.data.message[0].diagnosis);
        consultationRes.data.message[0].past_history &&
          setPastHistory(consultationRes.data.message[0].past_history);

        consultationRes.data.message[0].examination &&
          setExamination(consultationRes.data.message[0].examination);

        consultationRes.data.message[0].personal_habits &&
          setPersonalHabits(consultationRes.data.message[0].personal_habits);

        consultationRes.data.message[0].revisit_date &&
          consultationRes.data.message[0].revisit_date != "" &&
          setAppDate(new Date(consultationRes.data.message[0].revisit_date));
        if (consultationRes.data.message[0].drug_prescription) {
          let drugsel = [];
          for (
            let i = 0;
            i < consultationRes.data.message[0].drug_prescription.length;
            i++
          ) {
            let myObject = {
              id: i + 1,
              drug_code:
                consultationRes.data.message[0].drug_prescription[i].drug_code,
              drug_name:
                consultationRes.data.message[0].drug_prescription[i].drug_name,
              genericName:
                consultationRes.data.message[0].drug_prescription[i].drug_name,
              dosage:
                consultationRes.data.message[0].drug_prescription[i].dosage,
              comment:
                consultationRes.data.message[0].drug_prescription[i].comment,
              period:
                consultationRes.data.message[0].drug_prescription[i].period,
              dosage_form:
                consultationRes.data.message[0].drug_prescription[i]
                  .dosage_form,
              dosage_instruction:
                consultationRes.data.message[0].drug_prescription[i]
                  .dosage_instruction,
            };
            drugsel.push(myObject);
          }
          console.log("drugsel---", drugsel);
          setMedicines(drugsel);
        }
        if (consultationRes.data.message[0].lab_test_prescription) {
          let labtestSel = [];
          for (
            let i = 0;
            i < consultationRes.data.message[0].lab_test_prescription.length;
            i++
          ) {
            let myObject = {
              id: i + 1,
              lab_test_code:
                consultationRes.data.message[0].lab_test_prescription[i]
                  .lab_test_code,
              lab_test_name:
                consultationRes.data.message[0].lab_test_prescription[i]
                  .lab_test_name,
              comment:
                consultationRes.data.message[0].lab_test_prescription[i]
                  .comment,
              lab_test_comment:
                consultationRes.data.message[0].lab_test_prescription[i]
                  .lab_test_comment,
            };
            labtestSel.push(myObject);
          }
          console.log("labtestSel---", labtestSel);
          setlabList(labtestSel);
        }
        consultationRes.data.message[0].notes_for_patient &&
          setPatientNotes(consultationRes.data.message[0].notes_for_patient);

        consultationRes.data.message[0].notes_for_doctor &&
          setDoctorNotes(consultationRes.data.message[0].notes_for_doctor);
      } else if (location.state.appointmentID) {
        const appointmentID = location.state.appointmentID;
        const res = await doctorApi.getAppointmentById(appointmentID);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " getPatientDetails " + "getAppointmentById",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "appointmentID:" + appointmentID
          );
          return;
        }
        console.log("appointment----", res);
        setData(res.data.data);

        const resPatientRecord = await doctorApi.getPatientHealthRecord([
          [res.data.data.patient],
        ]);
        console.log("resPatientRecord----------", resPatientRecord);
        if (!resPatientRecord.ok) {
          ErrorLog(
            prefixerror + " getPatientDetails " + "getPatientHealthRecord",
            JSON.stringify(resPatientRecord),
            "We are facing some issues.Please try again after sometime.",
            "patient:" + res.data.data.patient
          );
          return;
        }

        setConsultationHistory(resPatientRecord.data.message);

        const vitalRes = await doctorApi.getVitalSigns(
          location.state.appointmentID
        );
        if (!vitalRes.ok) {
          ErrorLog(
            prefixerror + " getPatientDetails " + "getVitalSigns",
            JSON.stringify(vitalRes),
            "We are facing some issues.Please try again after sometime.",
            "appointmentID:" + location.state.appointmentID
          );
          return;
        }
        console.log("vitalRes----", vitalRes);
        setVitalData(vitalRes.data.data.length > 0 ? vitalRes.data.data : "");
      }
      const res1 = await doctorApi.getConsumption();
      console.log("res1--------", res1);
      if (!res1.ok) {
        ErrorLog(
          prefixerror + " getPatientDetails " + "getConsumption",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setConsumption(res1.data.data);
      const res2 = await doctorApi.getDuration();
      if (!res2.ok) {
        ErrorLog(
          prefixerror + " getPatientDetails " + "getDuration",
          JSON.stringify(res2),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setPeriodOfDays(res2.data.data);

      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " getPatientDetails ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSearchSymptoms = async (value) => {
    try {
      console.log("value-- ", value);
      setSymptom([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchSymptoms(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchSymptoms " + "searchSymptoms",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner" + practitioner
          );
          return;
        }
        console.log("res--", res);
        let i,
          symptomSel = [];
        if (res.data.message.length > 0) {
          for (i = 0; i < res.data.message.length; i++) {
            var myObject = {
              complaint: res.data.message[i].name,
              complaintName: res.data.message[i].complaints,
            };
            symptomSel.push(myObject);
          }
          setSymptom(symptomSel);
        }
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchSymptoms ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleTreatMentPlan = async (value) => {
    try {
      console.log("value---", value);
      if (value == "") {
        setSymptomSel([]);
        setDiagnosisSel([]);
        setMedicines([]);
        setlabList([]);
        return;
      }
      setLoad(true);
      const treatmentTemplateRes = await doctorApi.getTreatmentTemplateByName(
        value
      );
      if (!treatmentTemplateRes.ok) {
        ErrorLog(
          prefixerror + " handleTreatMentPlan " + "getTreatmentTemplateByName",
          JSON.stringify(treatmentTemplateRes),
          "We are facing some issues.Please try again after sometime.",
          "value:" + value
        );
        return;
      }
      console.log("treatmentTemplateRes---", treatmentTemplateRes);
      if (treatmentTemplateRes.data.data.complaints.length > 0) {
        let filter = "[";
        for (
          let i = 0;
          i < treatmentTemplateRes.data.data.complaints.length;
          i++
        ) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${treatmentTemplateRes.data.data.complaints[i].complaint}"]`;
        }
        filter += "]";
        console.log("filter---", filter);

        const symptomRes = await doctorApi.getSymptomByArray(filter);
        if (!symptomRes.ok) {
          ErrorLog(
            prefixerror + " handleTreatMentPlan " + "getSymptomByArray",
            JSON.stringify(symptomRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log("symptomRes---", symptomRes);

        let symptomSel = [];
        for (let i = 0; i < symptomRes.data.data.length; i++) {
          var myObject = {
            complaint: symptomRes.data.data[i].name,
            complaintName: symptomRes.data.data[i].complaints,
          };
          symptomSel.push(myObject);
        }
        console.log("symptomSel---", symptomSel);
        setSymptomSel(symptomSel);
        setSymptom(symptomSel);
      }

      console.log("------------", treatmentTemplateRes.data.data.diagnosis);
      if (treatmentTemplateRes.data.data.diagnosis.length > 0) {
        let filter = "[";
        for (
          let i = 0;
          i < treatmentTemplateRes.data.data.diagnosis.length;
          i++
        ) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${treatmentTemplateRes.data.data.diagnosis[i].diagnosis}"]`;
        }
        filter += "]";
        console.log("filter---", filter);

        const diagnosisRes = await doctorApi.getDiagnosisByArray(filter);
        if (!diagnosisRes.ok) {
          ErrorLog(
            prefixerror + " handleTreatMentPlan " + "getDiagnosisByArray",
            JSON.stringify(diagnosisRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log("diagnosisRes---", diagnosisRes);

        let diagnosisSel = [];
        for (let i = 0; i < diagnosisRes.data.data.length; i++) {
          var myObject = {
            diagnosis: diagnosisRes.data.data[i].name,
            diagnosisName: diagnosisRes.data.data[i].diagnosis,
          };
          diagnosisSel.push(myObject);
        }
        console.log("diagnosisSel---", diagnosisSel);
        setDiagnosisSel(diagnosisSel);
        setDiagnosis(diagnosisSel);
      }

      let medicines = [];
      for (let i = 0; i < treatmentTemplateRes.data.data.drugs.length; i++) {
        let myObject = {
          id: i + 1,
          drug_code: treatmentTemplateRes.data.data.drugs[i].drug_code,
          drug_name: treatmentTemplateRes.data.data.drugs[i].drug_name,
          genericName: treatmentTemplateRes.data.data.drugs[i].drug_name,
          dosage: treatmentTemplateRes.data.data.drugs[i].dosage,
          comment: treatmentTemplateRes.data.data.drugs[i].comment,
          period: treatmentTemplateRes.data.data.drugs[i].period,
          dosage_form: treatmentTemplateRes.data.data.drugs[i].dosage_form,
          dosage_instruction:
            treatmentTemplateRes.data.data.drugs[i].dosage_instruction,
        };

        medicines.push(myObject);
      }
      setMedicines(medicines);
      // console.log("medicines---", medicines);
      if (treatmentTemplateRes.data.data.items.length > 0) {
        let filter = "[";
        for (let i = 0; i < treatmentTemplateRes.data.data.items.length; i++) {
          if (i > 0) {
            filter += `,`;
          }
          filter += `["name","=","${treatmentTemplateRes.data.data.items[i].template}"]`;
        }
        filter += "]";
        const labRes = await doctorApi.getLabTestByArray(filter);
        if (!labRes.ok) {
          ErrorLog(
            prefixerror + " handleTreatMentPlan " + "getLabTestByArray",
            JSON.stringify(labRes),
            "We are facing some issues.Please try again after sometime.",
            "filter:" + filter
          );
          return;
        }
        console.log("labRes------", labRes);
        let labtestSel = [];
        for (let i = 0; i < labRes.data.data.length; i++) {
          let myObject = {
            id: i + 1,
            lab_test_code: labRes.data.data[i].lab_test_code,
            lab_test_name: labRes.data.data[i].lab_test_name,
            comment: labRes.data.data[i].comment,
            lab_test_comment:
              treatmentTemplateRes.data.data.items[i].instructions,
          };
          labtestSel.push(myObject);
        }
        // console.log("labtestSel---", labtestSel);
        setlabList(labtestSel);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleTreatMentPlan ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSearchDiagnosis = async (value) => {
    try {
      console.log("value---", value);
      setDiagnosis([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchDiagnosis(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchDiagnosis " + "searchDiagnosis",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res--", res);
        let i,
          arr = [];
        if (res.data.message.length > 0) {
          for (i = 0; i < res.data.message.length; i++) {
            var myObject = {
              diagnosis: res.data.message[i].name,
              diagnosisName: res.data.message[i].diagnosis,
            };
            arr.push(myObject);
          }
          setDiagnosis(arr);
        }
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchDiagnosis ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSymptomsAdd = async (symptomsName) => {
    setLoad(true);
    const Res = await doctorApi.createSymptoms(symptomsName);
    if (!Res.ok) {
      ErrorLog(
        prefixerror + " handleSymptomsAdd " + "createSymptoms",
        JSON.stringify(Res),
        "We are facing some issues.Please try again after sometime.",
        "symptomsName:" + symptomsName
      );
      return;
    }
    console.log("Res----", Res.data.data);
    let newSymptoms = [
      ...symptomSel,
      { complaint: Res.data.data.name, complaintName: symptomsName },
    ];
    setSymptomSel(newSymptoms);
    document.getElementById("search_input").value = "";
    document.getElementById("search_input").blur();
    setLoad(false);
  };

  const handleDiagnosisAdd = async (diagnosisName) => {
    try {
      setLoad(true);
      const Res = await doctorApi.createDiagnosis(diagnosisName);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + " handleDiagnosisAdd " + "createDiagnosis",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "diagnosisName:" + diagnosisName
        );
        return;
      }
      console.log("Res----", Res.data.data);
      let newDiag = [
        ...diagnosisSel,
        { diagnosis: Res.data.data.name, diagnosisName: diagnosisName },
      ];
      setDiagnosisSel(newDiag);
      document.getElementById("diagnosis_cons_input").value = "";
      document.getElementById("diagnosis_cons_input").blur();
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleDiagnosisAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const validate = () => {
    let err = false;
    if (labList.length == 0 && labtestName != "") {
      setShowAddLab(true);
      err = true;
      document.getElementById("labtest_input").focus();
    } else {
      setShowLab(false);
    }
    if (medicines.length == 0) {
      setShowAddMedicine(true);
      // setShowMedicine(true);
      err = true;
      document.getElementById("medicine_input").focus();
    } else {
      setShowMedicine(false);
    }
    if (diagnosisSel.length < 1) {
      setErrorDiagnosis(true);
      err = true;
      document.getElementById("diagnosis_cons_input").focus();
    } else {
      setErrorDiagnosis(false);
    }
    if (symptomSel.length == 0) {
      setErrorSymptom(true);
      document.getElementById("search_input").focus();
      err = true;
    } else {
      setErrorSymptom(false);
    }
    return err;
  };

  const handleFinishConsultation = async () => {
    try {
      closeErrors();
      let consultationName;
      if (validate()) {
        return;
      }
      setLoad(true);

      const doctorData = await doctorRegApi.getDetilsBasicInfo(
        data.practitioner
      );
      if (!doctorData.ok) {
        ErrorLog(
          prefixerror + " handleFinishConsultation " + "getDetilsBasicInfo",
          JSON.stringify(doctorData),
          "We are facing doctorData issues.Please try again after sometime.",
          "practitioner:" + data.practitioner
        );
        return;
      }
      let res, appId;
      if (location.state && location.state.consultationID) {
        console.log("update------", data);
        appId = data.appointment;
        consultationName = location.state.consultationID;
        const revisitDate = appdate ? moment(appdate).format("yyyy-MM-DD") : "";

        res = await doctorApi.editPatientConsultation(
          consultationName,
          symptomSel,
          durationSymptoms,
          diagnosisSel,
          medicines,
          labList,
          pastHistory,
          examination,
          personalHabits,
          patientNotes,
          doctorNotes,
          scan,
          revisitDate,
          true
        );
        console.log("editres---", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror +
              " handleFinishConsultation " +
              "editPatientConsultation",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "consultationID:" +
              consultationName +
              "symptomSel:" +
              symptomSel +
              "durationSymptoms:" +
              durationSymptoms +
              "diagnosisSel:" +
              diagnosisSel +
              "medicines:" +
              medicines +
              "labList:" +
              labList +
              "pastHistory:" +
              pastHistory +
              "examination:" +
              examination +
              "personalHabits:" +
              personalHabits +
              "patientNotes:" +
              patientNotes +
              "doctorNotes:" +
              doctorNotes +
              "scan:" +
              scan +
              "revisitDate:" +
              revisitDate
          );
          return;
        }
      } else {
        appId = location.state.appointmentID;
        res = await doctorApi.patientConsultation(
          data.company,
          location.state.appointmentID,
          data.patient,
          data.patient_name,
          data.patient_sex,
          moment().diff(data.dob, "years"),
          data.practitioner,
          data.practitioner_name,
          symptomSel,
          durationSymptoms,
          diagnosisSel,
          medicines,
          labList,
          pastHistory,
          examination,
          personalHabits,
          patientNotes,
          doctorNotes,
          scan,
          doctorData.data.data.department,
          appdate ? moment(appdate).format("yyyy-MM-DD") : "",
          true
        );
        console.log("patientConsultation res---", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleFinishConsultation " + "patientConsultation",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "appointmentID:" +
              location.state.appointmentID +
              "patient:" +
              data.patient +
              "patient_name:" +
              data.patient_name +
              "patient_sex:" +
              data.patient_sex +
              "age:" +
              moment().diff(data.dob, "years") +
              "practitioner:" +
              data.practitioner +
              "practitioner_name:" +
              data.practitioner_name +
              "symptomSel:" +
              symptomSel +
              "durationSymptoms:" +
              durationSymptoms +
              "diagnosisSel:" +
              diagnosisSel +
              "medicines:" +
              medicines +
              "labList:" +
              labList +
              "pastHistory:" +
              pastHistory +
              "examination:" +
              examination +
              "personalHabits:" +
              personalHabits +
              "patientNotes:" +
              patientNotes +
              "doctorNotes:" +
              doctorNotes +
              "scan:" +
              scan +
              "department:" +
              doctorData.data.data.department +
              "revisitDate:" +
              appdate
              ? moment(appdate).format("yyyy-MM-DD")
              : ""
          );
          return;
        }

        consultationName = res.data.data.name;
      }
      console.log("consultationName---", consultationName);
      console.log("appointment---", appId);
      const invRes = await doctorApi.getInvoiceByAppointment(appId);
      console.log("invRes---", invRes);

      if (invRes.data.data.length > 0) {
        const updInvRes = await doctorApi.updateInvoice(
          invRes.data.data[0].name,
          consultationName
        );
        console.log("updInvRes---", updInvRes);
        if (!updInvRes.ok) {
          ErrorLog(
            prefixerror + "handleFinishConsultation " + "updateInvoice",
            JSON.stringify(updInvRes),
            "We are facing some issues.Please try again after sometime.",
            "invoceName:" +
              invRes.data.data[0].name +
              "consultationName:" +
              consultationName
          );
          return;
        }

        const consultationRes = await doctorApi.savePatientConsultation(
          consultationName
        );
        console.log("consultationRes---", consultationRes);
        if (!consultationRes.ok) {
          ErrorLog(
            prefixerror +
              "handleFinishConsultation " +
              "savePatientConsultation",
            JSON.stringify(consultationRes),
            "We are facing some issues.Please try again after sometime.",
            "consultationName:" + consultationName
          );
          return;
        }
      }
      if (vitalData.length > 0) {
        const vitalDatares = await doctorApi.submitVitalSigns(
          vitalData[0].name
        );
        if (!vitalDatares.ok) {
          ErrorLog(
            prefixerror + " handleFinishConsultation " + "submitVitalSigns",
            JSON.stringify(vitalDatares),
            "We are facing some issues.Please try again after sometime.",
            "name:" + vitalData[0].name
          );
          return;
        }
        console.log("vitalDatares---", vitalDatares);
      }
      console.log("res---", res);
      setLoad(false);
      navigate("/endconsultation", { state: { consultationName } });
      return;
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleFinishConsultation ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const closeErrors = () => {
    setShowMedicine(false);
    setErrorDiagnosis(false);
    setErrorSymptom(false);
    setShowAddMedicine(false);
    setShowAddLab(false);
    setShowConsump(false);
    setShowIntake(false);
    setShowPeriod(false);
    setShowLab(false);
    setPatientAgeFromError(false);
    setPatientAgeToError(false);
  };

  const handleSubmit = async () => {
    try {
      setLoad(true);
      if (location.state && location.state.consultationID) {
        const revisitDate = appdate ? moment(appdate).format("yyyy-MM-DD") : "";
        const res = await doctorApi.editPatientConsultation(
          location.state.consultationID,
          symptomSel,
          durationSymptoms,
          diagnosisSel,
          medicines,
          labList,
          pastHistory,
          examination,
          personalHabits,
          patientNotes,
          doctorNotes,
          scan,
          revisitDate,
          false
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "editPatientConsultation",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "consultationID:" +
              location.state.consultationID +
              "symptomSel:" +
              symptomSel +
              "durationSymptoms:" +
              durationSymptoms +
              "diagnosisSel:" +
              diagnosisSel +
              "medicines:" +
              medicines +
              "labList:" +
              labList +
              "pastHistory:" +
              pastHistory +
              "examination:" +
              examination +
              "personalHabits:" +
              personalHabits +
              "patientNotes:" +
              patientNotes +
              "doctorNotes:" +
              doctorNotes +
              "scan:" +
              scan +
              "revisitDate:" +
              revisitDate
          );
          return;
        }
        console.log("editres---", res);
        console.log(
          "date---",
          moment(data.encounter_date).format("DD-MM-yyyy")
        );
        navigate("/upcomingappointment", {
          state: { date: moment(data.encounter_date).format("yyyy-MM-DD") },
        });
        // window.location.reload();
        return;
      }
      console.log("data--", data);
      const doctorRes = await doctorRegApi.getDetilsBasicInfo(
        data.practitioner
      );
      if (!doctorRes.ok) {
        ErrorLog(
          prefixerror + " handleSubmit " + "getDetilsBasicInfo",
          JSON.stringify(doctorRes),
          "We are facing some issues.Please try again after sometime.",
          "practitioner:" + data.practitioner
        );
        return;
      }
      console.log("doctorRes---", doctorRes);
      const department = doctorRes.data.data.department;

      const res = await doctorApi.patientConsultation(
        data.company,
        location.state.appointmentID,
        data.patient,
        data.patient_name,
        data.patient_sex,
        moment().diff(data.dob, "years"),
        data.practitioner,
        data.practitioner_name,
        symptomSel,
        durationSymptoms,
        diagnosisSel,
        medicines,
        labList,
        pastHistory,
        examination,
        personalHabits,
        patientNotes,
        doctorNotes,
        scan,
        department,
        appdate ? moment(appdate).format("yyyy-MM-DD") : "",
        false
      );
      console.log("res---", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " handleSubmit " + "patientConsultation",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "appointmentID:" +
            location.state.appointmentID +
            "patient:" +
            data.patient +
            "patient_name:" +
            data.patient_name +
            "patient_sex:" +
            data.patient_sex +
            "age:" +
            moment().diff(data.dob, "years") +
            "practitioner:" +
            data.practitioner +
            "practitioner_name:" +
            data.practitioner_name +
            "symptomSel:" +
            symptomSel +
            "durationSymptoms:" +
            durationSymptoms +
            "diagnosisSel:" +
            diagnosisSel +
            "medicines:" +
            medicines +
            "labList:" +
            labList +
            "pastHistory:" +
            pastHistory +
            "examination:" +
            examination +
            "personalHabits:" +
            personalHabits +
            "patientNotes:" +
            patientNotes +
            "doctorNotes:" +
            doctorNotes +
            "scan:" +
            scan +
            "department:" +
            department +
            "revisitDate:" +
            appdate
            ? moment(appdate).format("yyyy-MM-DD")
            : ""
        );
        return;
      }

      navigate("/upcomingappointment", {
        state: {
          date: moment(res.data.data.encounter_date).format("yyyy-MM-DD"),
        },
      });
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getPatientDetails();
    document.getElementById("search_input") &&
      document.getElementById("search_input").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <>
        <section>
          <div className="row">
            <div className="col-lg-2 p-0">
              <DoctorNavBar id="DoctorNavBar" />
            </div>

            <div className="col-lg-10 mb-5">
              <DoctorHeader />
              <div className="row" onClick={preFunction.hideNavbar}>
                <div className="pd mb-4">
                  <div className="row">
                    <h1 className="content-heading">Patient Consultation</h1>
                  </div>
                  <div className="row no-gutters">
                    <div className="col-lg-10 border p-lg-3">
                      {data ? (
                        <div className="row no-gutters m-1 border">
                          <div className="col-lg-5 mt-4">
                            <div className="row">
                              <div className="col-lg-10">
                                <label className="control-label con_sum_lbl">
                                  Name :
                                </label>
                                <label className="control-label ">
                                  {data.patient_name}
                                </label>
                                <label className="control-label ms-3 ps-1">
                                  {data.patient_sex} /
                                </label>
                                <label className="control-label">
                                  {moment().diff(data.dob, "years")}
                                </label>
                              </div>
                            </div>
                            {vitalData.length > 0 ? (
                              <>
                                {vitalData[0].height ? (
                                  <div className="row">
                                    <div className="col-9">
                                      <label className="p-2 control-label con_sum_lbl">
                                        Height (cm) :
                                      </label>
                                      <label className="control-label">
                                        {vitalData[0].height}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                {vitalData[0].weight ? (
                                  <div className="row">
                                    <div className="col-9">
                                      <label className="p-2 control-label con_sum_lbl">
                                        Weight (kg) :
                                      </label>
                                      <label className="control-label">
                                        {vitalData[0].weight}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                {vitalData[0].bmi ? (
                                  <div className="row">
                                    <div className="col-9">
                                      <label className="p-2 control-label con_sum_lbl">
                                        BMI :
                                      </label>
                                      <label className="control-label">
                                        {vitalData[0].bmi} (
                                        {vitalData[0].nutrition_note})
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                {vitalData[0].bp_systolic ? (
                                  <div className="row">
                                    <div className="col-9">
                                      <label className="p-2 control-label con_sum_lbl">
                                        Blood Pressure :
                                      </label>
                                      <label className="control-label">
                                        {vitalData[0].bp_systolic} /{" "}
                                        {vitalData[0].bp_diastolic}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                                {vitalData.temperature ? (
                                  <div className="row">
                                    <div className="col-9">
                                      <label className="p-2 control-label con_sum_lbl">
                                        Temperature (°F) :
                                      </label>
                                      <label className="control-label">
                                        {data.temperature}
                                      </label>
                                    </div>
                                  </div>
                                ) : null}
                              </>
                            ) : null}
                          </div>
                          {consultationHistory.length > 0 ? (
                            <div className="col-lg-7">
                              <div className="App">
                                <h5>Consultation History</h5>
                              </div>
                              <div className="row no-gutters mb-2 mt-2">
                                <div
                                  className={
                                    consultationHistory.length > 6
                                      ? "historyHeight row table-responsive"
                                      : "row table-responsive"
                                  }
                                >
                                  {consultationHistory.map((item, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="col-4 gridHeight nowrapWhiteSpace"
                                        onClick={() => {
                                          handleConsultationHistory(item);
                                        }}
                                      >
                                        <br />
                                        <a>{item.encounter_date}</a>
                                        <br />
                                      </div>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      ) : null}
                      <div className="mt-3 row border pt-4 p-2">
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-2">
                            Treatment Template :
                          </label>
                          <div className="col-lg-4">
                            <Multiselect
                              name="treatmentTemplate"
                              id="treatmentTemplate"
                              options={treatment}
                              showArrow={true}
                              selectedValues={treatmentTemplate}
                              placeholder={"Template Name"}
                              onSelect={handleSelectTreatmentTemplate}
                              onRemove={handleSelectTreatmentTemplate}
                              displayValue="template_name"
                              customCloseIcon={" "}
                              hidePlaceholder={true}
                              selectionLimit={1}
                              avoidHighlightFirstOption={true}
                              customArrow={
                                <span
                                  className="icofont-search"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                ></span>
                              }
                              onSearch={(e) => {
                                closeErrors();
                                handleSearchTreatmentTemplate(
                                  document.getElementById(
                                    "treatmentTemplate_input"
                                  ).value
                                );
                                handleSelectTreatmentTemplate("");
                              }}
                              emptyRecordMsg={
                                <div className="notFoundSearch">
                                  Type to search
                                </div>
                              }
                            />
                          </div>

                          <div className="col-lg-6 mt-2 text-end">
                            {medicines.length > 0 &&
                            diagnosisSel.length > 0 &&
                            symptomSel.length > 0 ? (
                              <button
                                className="btn me-4"
                                title="Save as Template"
                                onClick={handleSaveAsTemplate}
                              >
                                <span className="icofont-save"></span>Save as
                                Template
                              </button>
                            ) : null}
                          </div>
                        </div>

                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-2">
                            <span className="star">*</span> Symptoms :
                          </label>
                          <div className="col-lg-7">
                            <Multiselect
                              name="symptom"
                              options={symptom}
                              showArrow={true}
                              placeholder={"Symptom"}
                              selectedValues={symptomSel}
                              onSelect={handleSelectSymptom}
                              onRemove={handleSelectSymptom}
                              displayValue="complaintName"
                              avoidHighlightFirstOption={true}
                              customCloseIcon={
                                <span
                                  className="icofont-close-circled"
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "3px",
                                    marginLeft: "5px",
                                  }}
                                ></span>
                              }
                              customArrow={
                                <span
                                  className="icofont-search"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                ></span>
                              }
                              emptyRecordMsg={
                                document.getElementById("search_input") &&
                                document.getElementById("search_input").value
                                  .length > 2 ? (
                                  <div>
                                    Do you want to add?&nbsp;&nbsp;&nbsp;
                                    <button
                                      className="btn-2"
                                      title="Add"
                                      onClick={(e) => {
                                        handleSymptomsAdd(
                                          document.getElementById(
                                            "search_input"
                                          ).value
                                        );
                                      }}
                                    >
                                      <span className="icofont icofont-plus"></span>
                                      Add
                                    </button>
                                  </div>
                                ) : (
                                  <div className="notFoundSearch">
                                    Type to search
                                  </div>
                                )
                              }
                              onSearch={(e) => {
                                closeErrors();
                                handleSearchSymptoms(
                                  document.getElementById("search_input").value
                                );
                              }}
                              showCheckbox={true}
                            />
                            <ErrorMessage
                              error={"Please enter the Symptom"}
                              visible={errorSymptom}
                            />
                          </div>
                        </div>
                        {!durationConfig ? null : (
                          <div className="row no-gutters form-group">
                            <label className="control-label col-lg-2">
                              Duration :
                            </label>
                            <div className="col-lg-2">
                              <Select
                                type="text"
                                name="durationsymptoms"
                                id="durationsymptoms"
                                className={
                                  durationSymptoms
                                    ? "form-control select-control"
                                    : "form-control select-control opacity"
                                }
                                options={periodOfDays}
                                value={durationSymptoms}
                                onChange={(e) => {
                                  closeErrors();
                                  setDurationSymptoms(e.target.value);
                                }}
                                placeholder="Duration"
                              />
                            </div>
                          </div>
                        )}
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-2">
                            <span className="star">*</span> Disease / Condition
                            :
                          </label>
                          <div className="col-lg-7">
                            <Multiselect
                              name="diagnosis"
                              id="diagnosis_cons"
                              options={diag}
                              showArrow={true}
                              placeholder={"Disease / Condition"}
                              selectedValues={diagnosisSel}
                              onSelect={handleSelectDiagnosis}
                              onRemove={handleSelectDiagnosis}
                              displayValue="diagnosisName"
                              avoidHighlightFirstOption={true}
                              customCloseIcon={
                                <span
                                  className="icofont-close-circled"
                                  style={{
                                    fontSize: "16px",
                                    marginTop: "3px",
                                    marginLeft: "5px",
                                  }}
                                ></span>
                              }
                              customArrow={
                                <span
                                  className="icofont-search"
                                  style={{
                                    fontSize: "15px",
                                  }}
                                ></span>
                              }
                              emptyRecordMsg={
                                document.getElementById(
                                  "diagnosis_cons_input"
                                ) &&
                                document.getElementById("diagnosis_cons_input")
                                  .value.length > 2 ? (
                                  <div>
                                    Do you want to add?&nbsp;&nbsp;&nbsp;
                                    <button
                                      className="btn-2"
                                      title="Add"
                                      onClick={(e) => {
                                        handleDiagnosisAdd(
                                          document.getElementById(
                                            "diagnosis_cons_input"
                                          ).value
                                        );
                                      }}
                                    >
                                      <span className="icofont icofont-plus"></span>
                                      Add
                                    </button>
                                  </div>
                                ) : (
                                  <div className="notFoundSearch">
                                    Type to search
                                  </div>
                                )
                              }
                              onSearch={(e) => {
                                closeErrors();
                                handleSearchDiagnosis(
                                  document.getElementById(
                                    "diagnosis_cons_input"
                                  ).value
                                );
                              }}
                              showCheckbox={true}
                            />
                            <ErrorMessage
                              error={"Please enter the Disease / Conditions"}
                              visible={errorDiagnosis}
                            />
                          </div>
                        </div>
                      </div>
                      {pastHistoryConfig ||
                      examinationConfig ||
                      personalHabitConfig ? (
                        <div className="mt-3 row border pt-4 p-2">
                          {!pastHistoryConfig ? null : (
                            <div className="row no-gutters form-group">
                              <label className="control-label col-lg-2">
                                Past History :
                              </label>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  name="patientdoctor"
                                  id="patientdoctor"
                                  maxLength={120}
                                  className="form-control"
                                  value={pastHistory}
                                  onChange={(e) => {
                                    closeErrors();
                                    setPastHistory(e.target.value);
                                  }}
                                  placeholder="Past History"
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 &&
                                    handleFinishConsultation()
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {!examinationConfig ? null : (
                            <div className="row no-gutters form-group">
                              <label className="control-label col-lg-2">
                                Examination :
                              </label>
                              <div className="col-lg-6">
                                <textarea
                                  name="examination"
                                  id="scan"
                                  rows="3"
                                  className="textArea"
                                  value={examination}
                                  onChange={(e) => {
                                    closeErrors();
                                    setExamination(e.target.value);
                                  }}
                                  placeholder="Examination"
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 &&
                                    handleFinishConsultation()
                                  }
                                />
                              </div>
                            </div>
                          )}
                          {!personalHabitConfig ? null : (
                            <div className="row no-gutters form-group">
                              <label className="control-label col-lg-2">
                                Personal Habits :
                              </label>
                              <div className="col-lg-6">
                                <input
                                  type="text"
                                  name="patientdoctor"
                                  maxLength={120}
                                  className="form-control"
                                  value={personalHabits}
                                  onChange={(e) => {
                                    closeErrors();
                                    setPersonalHabits(e.target.value);
                                  }}
                                  placeholder="Personal Habits"
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 &&
                                    handleFinishConsultation()
                                  }
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      ) : null}
                      <div className="mt-4 row border pt-2">
                        <div className="row no-gutters p-2">
                          <h5>Prescribed Medicines</h5>
                          <>
                            <div className="mb-2 pb-1">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th width="1%">No.</th>
                                    <th>Medicine</th>
                                    <th width="15%">Frequency</th>
                                    <th width="15%">Before / After</th>
                                    <th width="15%">Duration</th>
                                    <th width="15%">Instruction</th>
                                    <th width="5%">Update</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {medicines.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{item.drug_name}</td>
                                        {/* <td>{item.genericName}</td> */}
                                        <td>{item.dosage}</td>
                                        <td>{item.comment}</td>
                                        <td>{item.period}</td>
                                        <td>
                                          {item.dosage_instruction &&
                                          item.dosage_instruction.length > 40
                                            ? item.dosage_instruction.substring(
                                                0,
                                                40
                                              )
                                            : item.dosage_instruction}
                                        </td>
                                        <td>
                                          <a
                                            className="pointer"
                                            onClick={() =>
                                              handleDeleteMedicine(item)
                                            }
                                          >
                                            Delete
                                          </a>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                  <tr>
                                    <td>{medicines.length + 1}</td>
                                    <td>
                                      <Multiselect
                                        name="medicine"
                                        id="medicine"
                                        options={drugs}
                                        placeholder={"Medicine"}
                                        showArrow={true}
                                        onSelect={handleSelectMedicine}
                                        onRemove={handleSelectMedicine}
                                        selectedValues={medicineSel}
                                        avoidHighlightFirstOption={true}
                                        displayValue="item_name"
                                        selectionLimit={1}
                                        customCloseIcon={" "}
                                        hidePlaceholder={true}
                                        customArrow={
                                          <span
                                            className="icofont-search"
                                            style={{
                                              fontSize: "15px",
                                            }}
                                          ></span>
                                        }
                                        emptyRecordMsg={
                                          document.getElementById(
                                            "medicine_input"
                                          ) &&
                                          document.getElementById(
                                            "medicine_input"
                                          ).value.length > 2 ? (
                                            <div className="notFoundAdd">
                                              Do you want to
                                              add?&nbsp;&nbsp;&nbsp;
                                              <button
                                                className="btn-2"
                                                title="Add"
                                                onClick={(e) => {
                                                  setAddMedicineModal(true);
                                                  closeErrors();
                                                }}
                                              >
                                                <span
                                                  className="icofont icofont-plus mr-1"
                                                  style={{ fontSize: "1em" }}
                                                ></span>
                                                Add
                                              </button>
                                            </div>
                                          ) : (
                                            <div className="notFoundSearch">
                                              Type to search
                                            </div>
                                          )
                                        }
                                        onSearch={(e) => {
                                          closeErrors();
                                          setMedicineNameToAdd(
                                            document.getElementById(
                                              "medicine_input"
                                            ).value
                                          );
                                          handleSearchMedicine(
                                            document.getElementById(
                                              "medicine_input"
                                            ).value
                                          );
                                          handleSelectMedicine();
                                        }}
                                      />
                                      <ErrorMessage
                                        error={"Please enter Medicine"}
                                        visible={showMedicine}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        type="text"
                                        name="consumption"
                                        id="consumption"
                                        className={
                                          consump
                                            ? "form-control select-control"
                                            : "form-control select-control opacity"
                                        }
                                        options={consumption}
                                        value={consump}
                                        onChange={(e) => {
                                          closeErrors();
                                          setConsump(e.target.value);
                                          setShowConsump(false);
                                        }}
                                        placeholder="Frequency"
                                      />
                                      <ErrorMessage
                                        error={"Please select Frequency"}
                                        visible={showConsump}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        type="text"
                                        name="intake"
                                        id="intake"
                                        className={
                                          intakes
                                            ? "form-control select-control"
                                            : "form-control select-control opacity"
                                        }
                                        options={comment}
                                        value={intakes}
                                        onChange={(e) => {
                                          closeErrors();
                                          setIntake(e.target.value);
                                          setShowIntake(false);
                                        }}
                                        placeholder="Before / After"
                                      />
                                      <ErrorMessage
                                        error={"Please select Before / After"}
                                        visible={showIntake}
                                      />
                                    </td>
                                    <td>
                                      <Select
                                        type="text"
                                        name="period"
                                        id="period"
                                        className={
                                          period
                                            ? "form-control select-control"
                                            : "form-control select-control opacity"
                                        }
                                        options={periodOfDays}
                                        value={period}
                                        onChange={(e) => {
                                          closeErrors();
                                          setPeriod(e.target.value);
                                          setShowPeriod(false);
                                        }}
                                        placeholder="Duration"
                                      />
                                      <ErrorMessage
                                        error={"Please select Duration"}
                                        visible={showPeriod}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        name="dosage_instruction"
                                        id="dosage_instruction"
                                        maxLength={120}
                                        className="form-control"
                                        value={dosageInstruction}
                                        onChange={(e) => {
                                          closeErrors();
                                          setDosageInstruction(e.target.value);
                                        }}
                                        placeholder="Instruction"
                                        onKeyUp={(e) =>
                                          e.keyCode == 13 && handleMedicine()
                                        }
                                      />
                                    </td>
                                    <td align="center">
                                      <button
                                        type="button"
                                        className="btn"
                                        onClick={handleMedicine}
                                      >
                                        <span className="icofont icofont-plus"></span>
                                        Add
                                      </button>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              {showAddMedicine ? (
                                <ErrorMessage
                                  error={"Please add Medicine to list"}
                                  visible={showAddMedicine}
                                />
                              ) : null}
                            </div>
                          </>
                        </div>
                      </div>
                      {!labTestConfig ? null : (
                        <div className="mt-3 row border pt-2">
                          <div className="row no-gutters p-2">
                            <h5>Lab Tests</h5>
                            <>
                              <div className="mb-2">
                                <table className="table table-bordered">
                                  <thead>
                                    <tr>
                                      <th width="1%">No.</th>
                                      <th>Lab Tests</th>
                                      <th width="45%">Comments</th>
                                      <th width="5%">Update</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {labList.map((item, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{index + 1}</td>
                                          <td>
                                            {item.lab_test_code} -{" "}
                                            {item.lab_test_name}
                                          </td>
                                          <td>{item.lab_test_comment}</td>
                                          <td>
                                            <a
                                              className="pointer"
                                              onClick={() =>
                                                handleDeleteLab(item)
                                              }
                                            >
                                              Delete
                                            </a>
                                          </td>
                                        </tr>
                                      );
                                    })}
                                    <tr>
                                      <td>{labList.length + 1}</td>
                                      <td>
                                        <Multiselect
                                          name="labtest"
                                          id="labtest"
                                          options={lab}
                                          showArrow={true}
                                          selectedValues={selectedLabList}
                                          placeholder={"Lab Test Name"}
                                          onSelect={handleSelectLab}
                                          avoidHighlightFirstOption={true}
                                          onRemove={handleSelectLab}
                                          displayValue="name"
                                          selectionLimit={1}
                                          customCloseIcon={" "}
                                          hidePlaceholder={true}
                                          customArrow={
                                            <span
                                              className="icofont-search"
                                              style={{
                                                fontSize: "15px",
                                              }}
                                            ></span>
                                          }
                                          emptyRecordMsg={
                                            document.getElementById(
                                              "labtest_input"
                                            ) &&
                                            document.getElementById(
                                              "labtest_input"
                                            ).value.length > 2 ? (
                                              <div className="notFoundAdd">
                                                Do you want to
                                                add?&nbsp;&nbsp;&nbsp;
                                                <button
                                                  className="btn-2"
                                                  title="Add"
                                                  onClick={(e) => {
                                                    setAddLabModal(true);
                                                    closeErrors();
                                                  }}
                                                >
                                                  <span
                                                    className="icofont icofont-plus mr-1"
                                                    style={{
                                                      fontSize: "1em",
                                                    }}
                                                  ></span>
                                                  Add
                                                </button>
                                              </div>
                                            ) : (
                                              <div className="notFoundSearch">
                                                Type to search
                                              </div>
                                            )
                                          }
                                          onSearch={(e) => {
                                            closeErrors();
                                            setLabTestNameToAdd(
                                              document.getElementById(
                                                "labtest_input"
                                              ).value
                                            );
                                            handleSearchLab(
                                              document.getElementById(
                                                "labtest_input"
                                              ).value
                                            );
                                            handleSelectLab();
                                          }}
                                        />
                                        <ErrorMessage
                                          error={"Please enter Labtest Name"}
                                          visible={showLab}
                                        />
                                      </td>
                                      <td>
                                        <input
                                          type="text"
                                          name="patient"
                                          id="patient"
                                          maxLength={120}
                                          className="form-control"
                                          value={comments}
                                          onChange={(e) => {
                                            closeErrors();
                                            setComments(e.target.value);
                                          }}
                                          placeholder="Comments"
                                          onKeyUp={(e) =>
                                            e.keyCode == 13 &&
                                            handleFinishConsultation()
                                          }
                                        />
                                      </td>
                                      <td align="center">
                                        <button
                                          type="button"
                                          className="btn"
                                          onClick={handleLabTest}
                                        >
                                          <span className="icofont icofont-plus"></span>
                                          Add
                                        </button>
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                                {showAddLab ? (
                                  <div>
                                    <ErrorMessage
                                      error={"Please add Lab test to list"}
                                      visible={showAddLab}
                                    />
                                  </div>
                                ) : null}
                              </div>
                            </>
                          </div>
                        </div>
                      )}

                      <div className="mt-3 row border pt-2">
                        {!scanConfig ? null : (
                          <div className="row mt-3 p-2 no-gutters">
                            <label className="control-label col-lg-2">
                              Scan :
                            </label>
                            <div className="form-group col-lg-7">
                              <textarea
                                name="scan"
                                id="scan"
                                rows="3"
                                className="textArea"
                                value={scan}
                                onChange={(e) => {
                                  closeErrors();
                                  setScan(e.target.value);
                                }}
                                placeholder="Scan"
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleFinishConsultation()
                                }
                              />
                            </div>
                          </div>
                        )}

                        <div className="row no-gutters p-2">
                          <label className="control-label col-lg-2">
                            Revisit Date :
                          </label>
                          <div className="form-group col-lg-7">
                            <DatePicker
                              name="appdate"
                              id="appdate"
                              minDate={new Date()}
                              maxDate={
                                new Date(dte.getFullYear() + 1 + "-12-31")
                              }
                              selected={appdate}
                              onChange={(date) => {
                                closeErrors();
                                setAppDate(date);
                              }}
                              dateFormat="dd-MM-yyyy"
                              customInput={<DateCustomInput />}
                              className="form-control datepicker"
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <select
                                    style={{ marginRight: "3px" }}
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            />
                          </div>
                        </div>
                        {!patientNoteConfig ? null : (
                          <div className="row no-gutters p-2">
                            <label className="control-label col-lg-2">
                              Notes for Patient :
                            </label>
                            <div className="form-group col-lg-7 pe-2">
                              <textarea
                                name="patientnotes"
                                id="patientnotes"
                                rows="3"
                                className="textArea"
                                value={patientNotes}
                                onChange={(e) => {
                                  closeErrors();
                                  setPatientNotes(e.target.value);
                                }}
                                placeholder="Notes for Patient"
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleFinishConsultation()
                                }
                              />
                            </div>
                          </div>
                        )}
                        {!doctorNoteConfig ? null : (
                          <div className="row no-gutters p-2">
                            <label className="control-label col-lg-2">
                              Notes for Doctor :
                            </label>
                            <div className="form-group col-lg-7 pe-2">
                              <textarea
                                name="doctornotes"
                                id="doctornotes"
                                rows="3"
                                className="textArea"
                                value={doctorNotes}
                                onChange={(e) => {
                                  closeErrors();
                                  setDoctorNotes(e.target.value);
                                }}
                                placeholder="Notes for Doctor"
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleFinishConsultation()
                                }
                              />
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="row form-group mt-2">
                        <div className="col-lg-3"></div>
                        <div className="col-lg-3 text-center">
                          <button
                            className="btn mt-2"
                            title="Save Consultation"
                            onClick={handleSubmit}
                          >
                            <span className="icofont-save"></span>Save
                            Consultation
                          </button>
                        </div>
                        <div className="col-lg-3 text-center">
                          <button
                            className="btn mt-2"
                            title="Finish Consultation"
                            onClick={handleFinishConsultation}
                          >
                            <span className="icofont-save"></span>Finish
                            Consultation
                          </button>
                        </div>
                        <div className="col-lg-3"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <>
            <Modal show={openModel}>
              <Modal.Header>
                <Modal.Title>Consultation Summary</Modal.Title>
                <span
                  className="icofont-close-line pointer"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                  onClick={(e) => {
                    setOpenModal(false);
                  }}
                ></span>
              </Modal.Header>
              <Modal.Body style={{ width: "500px" }}>
                {item ? (
                  <div
                    id="section1"
                    className="row"
                    style={{
                      backgroundColor: "#fff",
                      width: "100%",
                    }}
                  >
                    <div>
                      <label className="control-label ">Visit Date :</label>
                      {item.encounter_date}
                    </div>
                    <div>
                      <label className="control-label ">Practitioner :</label>
                      {item.practitioner_name}
                    </div>
                    <div>
                      <label className="control-label ">Symptoms :</label>
                      {item.symptoms}
                    </div>
                    <div>
                      <label className="control-label">Diagnosis :</label>
                      {item.diagnosis}
                    </div>
                    <div>
                      <label className="control-label ">
                        Prescribed Medicines :
                      </label>
                      <div className="table-responsive">
                        <table className="table table-bordered" width="100%">
                          <thead>
                            <tr>
                              <th>No.</th>
                              <th>Medicine</th>
                              <th>Consumption</th>
                              <th>Before / After</th>
                              <th>Duration</th>
                            </tr>
                          </thead>
                          {item.drug_prescription.map((item, index) => {
                            return (
                              <tbody>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td>{item.drug_name}</td>
                                  <td>{item.dosage}</td>
                                  <td> {item.comment}</td>
                                  <td>{item.period}</td>
                                </tr>
                              </tbody>
                            );
                          })}
                        </table>
                      </div>
                    </div>
                    {item.lab_test_prescription.length > 0 ? (
                      <div>
                        <label className="control-label ">Lab Test :</label>
                        <div className="table-responsive ms-1 me-1">
                          <table className="table table-bordered" width="100%">
                            <thead>
                              <tr>
                                <th>No.</th>
                                <th>Lab Tests</th>
                                <th>Comment</th>
                              </tr>
                            </thead>
                            {item.lab_test_prescription.map((item, index) => {
                              return (
                                <tbody>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{item.lab_test_name}</td>
                                    <td>{item.lab_test_comment}</td>
                                  </tr>
                                </tbody>
                              );
                            })}
                          </table>
                        </div>
                      </div>
                    ) : null}
                    {item.notes_for_patient ? (
                      <div className="col-11">
                        <label className="control-label">Patient Notes :</label>
                        {item.notes_for_patient}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </Modal.Body>
              <Modal.Footer>
                <button className="btn" title="Close" onClick={handleClose}>
                  <span className="icofont-close"></span>
                  Close
                </button>
              </Modal.Footer>
            </Modal>
            <Modal show={saveOpenModal}>
              <Modal.Header>
                <Modal.Title>Save as Template</Modal.Title>
                <span
                  className="icofont-close-line pointer"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                  onClick={(e) => {
                    setSaveOpenModal(false);
                  }}
                ></span>
              </Modal.Header>
              <Modal.Body style={{ width: "550px" }}>
                <div id="modelElement">
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Template Name:
                    </label>
                    <div className="col-lg-6">
                      <CustomTextInput
                        type="text"
                        name="templateName"
                        maxlength="140"
                        id="templateName"
                        className="form-control"
                        value={templateName}
                        onChange={(e) => {
                          setTemplateNameError(false);
                          setTemplateName(e.target.value);
                        }}
                        placeholder="Template Name"
                        onKeyUp={(e) => e.keyCode == 13 && handleSaveTemplate()}
                      />
                      <ErrorMessage
                        error={"Please enter Template Name"}
                        visible={templateNameError}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      Description :
                    </label>
                    <div className="col-lg-8">
                      <textarea
                        name="description"
                        id="description"
                        maxLength={140}
                        rows="4"
                        cols="40"
                        className="textArea"
                        value={description}
                        onChange={(e) => {
                          setTemplateNameError(false);
                          setDescription(e.target.value);
                        }}
                        placeholder="Description"
                      />
                    </div>
                  </div>
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Patient Age From :
                    </label>
                    <div className="col-lg-8">
                      <div className="row no-gutters">
                        <div className="col-2">
                          <CustomTextInput
                            className="form-control"
                            type="number"
                            name="patientAgeFrom"
                            id="patientAgeFrom"
                            value={patientAgeFrom}
                            onChange={(e) => {
                              closeErrors();
                              e.target.value =
                                e.target.value < 0
                                  ? ""
                                  : e.target.value > 100
                                  ? 100
                                  : e.target.value;
                              setPatientAgeFrom(e.target.value);
                            }}
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleSaveTemplate()
                            }
                            placeholder="Age"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <ErrorMessage
                          error={"Please enter Patient Age From"}
                          visible={patientAgeFromError}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Patient Age To :
                    </label>
                    <div className="col-lg-8">
                      <div className="row no-gutters">
                        <div className="col-2">
                          <CustomTextInput
                            type="number"
                            name="patientAgeTo"
                            id="patientAgeTo"
                            className="form-control"
                            value={patientAgeTo}
                            onChange={(e) => {
                              closeErrors();
                              e.target.value =
                                e.target.value < 0
                                  ? ""
                                  : e.target.value > 100
                                  ? 100
                                  : e.target.value;
                              setPatientAgeTo(e.target.value);
                            }}
                            placeholder="Age"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleSaveTemplate()
                            }
                          />
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <ErrorMessage
                          error={"Please enter valid Patient Age To"}
                          visible={patientAgeToError}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row no-gutters App">
                    <ShowMessage
                      view={templateSuccess}
                      Message={"Template added successfully"}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn"
                  title="Close"
                  onClick={handleSaveTemplate}
                >
                  <span className="icofont-save"></span>
                  Save
                </button>
              </Modal.Footer>
            </Modal>
            <Modal show={addMedicineModal}>
              <Modal.Header>
                <Modal.Title>Add Medicine</Modal.Title>
                <span
                  className="icofont-close-line pointer"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                  onClick={(e) => {
                    setAddMedicineModal(false);
                  }}
                ></span>
              </Modal.Header>
              <Modal.Body style={{ width: "550px" }}>
                <div id="modelElement">
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Medicine Name:
                    </label>
                    <div className="col-lg-6">
                      <CustomTextInput
                        type="text"
                        name="medicineName"
                        id="medicineName"
                        maxlength="140"
                        className="form-control"
                        value={medicineNameToAdd}
                        onChange={(e) => {
                          setGenericNameError(false);
                          setMedicineNameError(false);
                          setMedicineNameToAdd(e.target.value);
                        }}
                        placeholder="Medicine Name"
                        onKeyUp={(e) => e.keyCode == 13 && handleMedicineAdd()}
                      />
                      <ErrorMessage
                        error={"Please enter Medicine Name"}
                        visible={medicineNameError}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Generic Name :
                    </label>
                    <div className="col-lg-8">
                      <CustomTextInput
                        type="text"
                        name="genericName"
                        id="genericName"
                        className="form-control"
                        maxlength="140"
                        value={genericNameToAdd}
                        onChange={(e) => {
                          setGenericNameError(false);
                          setMedicineNameError(false);
                          setGenericNameToAdd(e.target.value);
                        }}
                        placeholder="Medicine Name"
                        onKeyUp={(e) => e.keyCode == 13 && handleMedicineAdd()}
                      />
                      <ErrorMessage
                        error={"Please enter Generic Name"}
                        visible={genericNameError}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters App">
                    <ShowMessage view={medicineSuccess} Message={message} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn"
                  title="Close"
                  onClick={handleMedicineAdd}
                >
                  <span className="icofont-save"></span>
                  Save
                </button>
              </Modal.Footer>
            </Modal>
            <Modal show={addLabModal}>
              <Modal.Header>
                <Modal.Title>Lab Test</Modal.Title>
                <span
                  className="icofont-close-line pointer"
                  style={{ fontSize: "25px", fontWeight: "bold" }}
                  onClick={(e) => {
                    setAddLabModal(false);
                  }}
                ></span>
              </Modal.Header>
              <Modal.Body style={{ width: "550px" }}>
                <div id="modelElement">
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Lab Test Code :
                    </label>
                    <div className="col-lg-6">
                      <CustomTextInput
                        type="text"
                        name="labTestCode"
                        id="labTestCode"
                        maxlength="50"
                        className="form-control"
                        value={labTestCodeToAdd}
                        onChange={(e) => {
                          setLabTestCodeError(false);
                          setLabTestNameError(false);
                          setLabTestCodeToAdd(e.target.value);
                        }}
                        placeholder="lab Test Code"
                        onKeyUp={(e) => e.keyCode == 13 && handleLabTestAdd()}
                      />
                      <ErrorMessage
                        error={"Please enter lab Test Code"}
                        visible={labTestCodeError}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters form-group">
                    <label className="control-label col-lg-4">
                      <span className="star">*</span> Lab Test Name:
                    </label>
                    <div className="col-lg-6">
                      <CustomTextInput
                        type="text"
                        name="labTestName"
                        id="labTestName"
                        maxlength="140"
                        className="form-control"
                        value={labTestNameToAdd}
                        onChange={(e) => {
                          setLabTestNameError(false);
                          setLabTestCodeError(false);
                          setLabTestNameToAdd(e.target.value);
                        }}
                        placeholder="lab Test Name"
                        onKeyUp={(e) => e.keyCode == 13 && handleLabTestAdd()}
                      />
                      <ErrorMessage
                        error={"Please enter lab Test Name"}
                        visible={labTestNameError}
                      />
                    </div>
                  </div>
                  <div className="row no-gutters App">
                    <ShowMessage view={labTestSuccess} Message={message} />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn"
                  title="Close"
                  onClick={handleLabTestAdd}
                >
                  <span className="icofont-save"></span>
                  Save
                </button>
              </Modal.Footer>
            </Modal>
          </>
        </section>
      </>
    </>
  );
}

export default StartConsultation;
