import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import EmptyComponent from "../component/common/EmptyComponent";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
const prefixerror = "DoctorList";

function DoctorList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const navigate = useNavigate();
  let sno = limitStart + 1;
  //#endregion

  const getCompanyList = async (limitStart) => {
    try {
      setLoad(true);
      setData([]);
      console.log("pageSize----", pageSize);
      const role = await storage.getRole();
      console.log("role--", role);
      if (role !== "SuperAdmin") {
        navigate("/");
        // window.location.reload();
        return;
      }
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const allres = await adminApi.getAllCompanyDetails();
      console.log("allres==", allres);
      if (!allres.ok) {
        ErrorLog(
          prefixerror + "getCompanyList " + "getAllCompanyDetails",
          JSON.stringify(allres),
          "We are facing some issues.Please try again after sometime.",
          "limitStart:" + limitStart + "pageSize:" + pageSize
        );
        return;
      }
      setTotalCount(allres.data.data.length);

      const res = await adminApi.getCompanyDetails(pageSize, limitStart);
      console.log("res==", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getCompanyList " + "getCompanyDetails",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "limitStart:" + limitStart + "pageSize:" + pageSize
        );
        return;
      }

      const doctorres = await adminApi.getAllDoctor("", 0, "None");
      console.log("doctorres---", doctorres);

      for (let i = 0; i < doctorres.data.data.length; i++) {
        for (let j = 0; j < res.data.data.length; j++) {
          if (res.data.data[j].name == doctorres.data.data[i].hospital) {
            if (res.data.data[j].doctor)
              res.data.data[j].doctor += "<br/>" + doctorres.data.data[i].name;
            else res.data.data[j].doctor = doctorres.data.data[i].name;
            break;
          }
        }
      }
      console.log("res--", res);
      setData(res.data.data);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getDoctorList ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getCompanyList(lmtst);
  };

  useEffect(() => {
    getCompanyList(limitStart, "");
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="mb-4">
              <div className="pd">
                <h1 className="content-heading mb-4">Clinic List</h1>
                <div id="report" className="mt-4">
                  {data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table table-bordered ">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th width="15%">Clinic ID</th>
                              <th>Clinic</th>
                              <th width="25%">Doctors</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td align="center">{sno++}</td>
                                  <td>{item.name}</td>
                                  <td>{item.company_name}</td>
                                  <td>
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: item.doctor,
                                      }}
                                    ></div>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  className="pointer"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </>
                  ) : (
                    <EmptyComponent title="No Clinic added" />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorList;
