import React from "react";
import moment from "moment";

import string from "../string";

const PageFooter = () => {
  return (
    <>
      <div className="row mt-5">
        <div className="welcomefooterbg">
          <div className="row footermargin">
            <div className="col-lg-3">
              <div className="row mb-3 mt-4">
                <div className="whitelogo">
                  <img
                    src={require("../assests/png/Cloud_Clinic_White.png")}
                    alt={string.websiteName + " Home"}
                  />
                </div>
              </div>
              <div className="row footertext">
                Enhances the electronic patient record keeping platform,
                providing a comprehensive professional clinic management suite.
              </div>
              <div className="row no-gutters footerspacemt3">
                <div>
                  <a
                    rel="noreferrer"
                    href="https://play.google.com/store/apps/details?id=com.smv.myclinic"
                    target="_blank"
                  >
                    <img
                      src={require("../assests/png/playstore.png")}
                      alt={string.websiteName}
                    />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    rel="noreferrer"
                    href="https://onelink.to/4mg3n8"
                    target="_blank"
                  >
                    <img
                      src={require("../assests/png/appstore.png")}
                      alt={string.websiteName}
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="mb-3 footerspace">
                <span className="contacttexthead">Contact</span>
              </div>
              <div className="contacttext">
                <b>SMV HealthCare Private Limited,</b>
                <br />
                TS-126, ARS Tower, 2nd Floor, <br />
                Thiru.Vi.Ka industrial Estate, Ekkaduthangal,
                <br />
                Chennai, Tamilnadu, India - 600032 <br /> Phone: +91 75400 00261{" "}
                <br /> Email : contact@myclinichealth.in
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footerspace"></div>
              <div className="linkstext">
                <div className="footerspace">
                  <a href="/aboutus" className="welcomelinks">
                    About Us
                  </a>
                </div>
                <div className="footermt4">
                  <a href="/privacypolicy" className="welcomelinks">
                    Privacy Policy
                  </a>
                </div>
                <div className="footermt4">
                  <a href="/faq" className="welcomelinks">
                    FAQ
                  </a>
                </div>
                <div className="footermt4">
                  <a href="/termsandcondition" className="welcomelinks">
                    Terms and Conditions
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3" style={{ textAlign: "center" }}>
              {/* <div className="iconlinks">
                <a href="javascript:void(0)" className="ms-3">
                  <img
                    src={require("../assests/png/Facebook.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
                <a href="javascript:void(0)" className="ms-3">
                  <img
                    src={require("../assests/png//Instagram.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
                <a href="javascript:void(0)" className="ms-3">
                  <img
                    src={require("../assests/png/Twitter.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
                <a href="javascript:void(0)" className="ms-3">
                  <img
                    src={require("../assests/png/youtube.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
              </div> */}
              <div style={{ marginTop: "15%" }}>
                <img
                  src={require("../assests/png/LegitScript.png")}
                  alt="LegitScript approved"
                />
              </div>
            </div>
          </div>
          <div>
            <hr style={{ color: "#fff" }} />
          </div>
          <div className="copirighttext m-3">
            Copyright © {moment().format("yyyy")} SMV HealthCare Private Limited{" "}
            {string.version}
          </div>
        </div>
      </div>
    </>
  );
};

export default PageFooter;
