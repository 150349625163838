import AsyncStorage from "@react-native-async-storage/async-storage";

const tokenkey = "TOKEN_ID_DOC";
const superAdminTokenKey = "SUPER_ADMIN_TOKEN_ID_DOC";
const staffTokenkey = "STAFF_TOKEN_ID_DOC";
const namekey = "NAME_DOC";
const lnamekey = "LASTNAME_DOC";
const emailkey = "EMAIL_DOC";
const docID = "DOCTORID_DOC";
const companyName = "COMPANY_DOC";
const companyFullName = "COMPANY_FULLNAME_DOC";
const serviceUnitName = "SERVICE_UNIT_DOC";
const displayPicture = "Dp_DOC";
const staffDisplayPicture = "staffdp_DOC";
const schName = "SCHEDULENAME_DOC";
const profilekey = "PROFILECOMPLETE_DOC";
const fieldkey = "FIELD_DOC";
const userRole = "ROLE_DOC";
const userOwner = "OWNER_DOC";
const userOwnerSecretKey = "OWNERSECRETKEY_DOC";
const userStaffName = "STAFF_NAME_DOC";
const docStatus = "STATUS_DOC";
const oldUserKey = "OLD_USER";
const leadSource = "LEAD_SOURCE";

const storeField = async (field) => {
  try {
    await AsyncStorage.setItem(fieldkey, field);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const storeId = async (
  id,
  name,
  lname,
  email,
  role,
  owner,
  ownerSecretKey,
  staffName,
  company,
  company_name,
  serviceUnit,
  dp,
  staffdp
) => {
  try {
    await AsyncStorage.setItem(docID, id);
    await AsyncStorage.setItem(namekey, name + " " + lname);
    await AsyncStorage.setItem(lnamekey, lname);
    await AsyncStorage.setItem(emailkey, email);
    await AsyncStorage.setItem(userRole, role);
    await AsyncStorage.setItem(userOwner, owner);
    await AsyncStorage.setItem(userOwnerSecretKey, ownerSecretKey);
    await AsyncStorage.setItem(userStaffName, staffName);
    await AsyncStorage.setItem(companyName, company);
    await AsyncStorage.setItem(companyFullName, company_name);
    await AsyncStorage.setItem(serviceUnitName, serviceUnit);
    await AsyncStorage.setItem(displayPicture, dp);
    await AsyncStorage.setItem(staffDisplayPicture, staffdp);
  } catch (error) {
    console.log("Error at store customerid", error);
  }
};

const getLeadSource = async () => {
  const ls = await AsyncStorage.getItem(leadSource);
  return ls;
};

const getOldUser = async () => {
  const oldUser = await AsyncStorage.getItem(oldUserKey);
  return oldUser;
};

const storeCompany = async (value) => {
  try {
    await AsyncStorage.setItem(companyName, value);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const storeCompanyFullName = async (value) => {
  try {
    await AsyncStorage.setItem(companyFullName, value);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const storeLeadSource = async (value) => {
  try {
    await AsyncStorage.setItem(leadSource, value);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const storeOldUser = async (value) => {
  try {
    await AsyncStorage.setItem(oldUserKey, value);
  } catch (error) {
    console.log("Error storing the Field", error);
  }
};

const storeProfileComplete = async (profile) => {
  try {
    await AsyncStorage.setItem(profilekey, profile);
  } catch (error) {
    console.log("Error storing the ProfileComplete", error);
  }
};

const storeRole = async (role) => {
  try {
    await AsyncStorage.setItem(userRole, role);
  } catch (error) {
    console.log("Error storing the Role", error);
  }
};

const storeStaus = async (status) => {
  try {
    await AsyncStorage.setItem(docStatus, status);
  } catch (error) {
    console.log("Error storing the Doctor Status", error);
  }
};

const storeOwner = async (owner) => {
  try {
    await AsyncStorage.setItem(userOwner, owner);
  } catch (error) {
    console.log("Error storing the Owner", error);
  }
};

const storeStaffName = async (staffname) => {
  try {
    await AsyncStorage.setItem(userStaffName, staffname);
  } catch (error) {
    console.log("Error storing the Staff Name", error);
  }
};

const storeOwnerSecretKey = async (ownerSecretKey) => {
  try {
    await AsyncStorage.setItem(userOwnerSecretKey, ownerSecretKey);
  } catch (error) {
    console.log("Error storing the Owner SecretKey", error);
  }
};

const storeScheduleName = async (schedule) => {
  try {
    await AsyncStorage.setItem(schName, schedule);
  } catch (error) {
    console.log("Error storing the ScheduleName", error);
  }
};

const storeStaffToken = async (token) => {
  try {
    await AsyncStorage.setItem(staffTokenkey, token);
  } catch (error) {
    console.log("Error storing the staff token", error);
  }
};

const storeToken = async (token) => {
  try {
    await AsyncStorage.setItem(tokenkey, token);
  } catch (error) {
    console.log("Error storing the token", error);
  }
};

const storeSuperAdminToken = async (token) => {
  try {
    await AsyncStorage.setItem(superAdminTokenKey, token);
  } catch (error) {
    console.log("Error storing the super admin token", error);
  }
};

const getEmail = async () => {
  const email = await AsyncStorage.getItem(emailkey);
  return email ? email : null;
};

const getName = async () => {
  const name = await AsyncStorage.getItem(namekey);
  return name ? name : null;
};

const getField = async () => {
  const field = await AsyncStorage.getItem(fieldkey);
  return field ? field : null;
};

const getID = async () => {
  const id = await AsyncStorage.getItem(docID);
  return id ? id : null;
};

const getCompany = async () => {
  const companyy = await AsyncStorage.getItem(companyName);
  return companyy ? companyy : null;
};

const getCompanyFullName = async () => {
  const companyName = await AsyncStorage.getItem(companyFullName);
  return companyName ? companyName : null;
};

const getServiceUnit = async () => {
  const res = await AsyncStorage.getItem(serviceUnitName);
  return res ? res : null;
};

const getDp = async () => {
  const ddp = await AsyncStorage.getItem(displayPicture);
  return ddp ? ddp : null;
};
const storeDp = async (dp) => {
  try {
    await AsyncStorage.setItem(displayPicture, dp);
  } catch (error) {
    console.log("Error storing the Role", error);
  }
};
const getStaffDp = async () => {
  const ddp = await AsyncStorage.getItem(staffDisplayPicture);
  return ddp ? ddp : null;
};
const storeStaffDp = async (dp) => {
  try {
    await AsyncStorage.setItem(staffDisplayPicture, dp);
  } catch (error) {
    console.log("Error storing the Role", error);
  }
};
const getPageSize = async () => {
  const pageSize = 30;
  return pageSize;
};

const getProfileComplete = async () => {
  const profile = await AsyncStorage.getItem(profilekey);
  return profile ? profile : null;
};

const getRole = async () => {
  const role = await AsyncStorage.getItem(userRole);
  return role ? role : null;
};

const getOwner = async () => {
  const owner = await AsyncStorage.getItem(userOwner);
  return owner ? owner : null;
};

const getStaffName = async () => {
  const staffName = await AsyncStorage.getItem(userStaffName);
  return staffName ? staffName : null;
};

const getOwnerSecretKey = async () => {
  const ownerSecretKey = await AsyncStorage.getItem(userOwnerSecretKey);
  return ownerSecretKey ? ownerSecretKey : null;
};

const getScheduleName = async () => {
  const schedule = await AsyncStorage.getItem(schName);
  return schedule ? schedule : null;
};

const getStatus = async () => {
  const status = await AsyncStorage.getItem(docStatus);
  return status ? status : null;
};

const getToken = async () => {
  const token = await AsyncStorage.getItem(tokenkey);
  return token ? token : null;
};

const getSuperAdminToken = async () => {
  const token = await AsyncStorage.getItem(superAdminTokenKey);
  return token ? token : null;
};

const getStaffToken = async () => {
  const staffToken = await AsyncStorage.getItem(staffTokenkey);
  return staffToken ? staffToken : null;
};

const getUser = async () => {
  const name = await AsyncStorage.getItem(namekey);
  const lname = await AsyncStorage.getItem(lnamekey);
  return name ? name + " " + lname : null;
};

const removeField = async () => {
  try {
    await AsyncStorage.removeItem(fieldkey);
  } catch (error) {
    console.log("Error removing the Field", error);
  }
};

const removeId = async () => {
  try {
    await AsyncStorage.removeItem(tokenkey);
    await AsyncStorage.removeItem(staffTokenkey);
    await AsyncStorage.removeItem(namekey);
    await AsyncStorage.removeItem(lnamekey);
    await AsyncStorage.removeItem(emailkey);
    await AsyncStorage.removeItem(docID);
    await AsyncStorage.removeItem(companyName);
    await AsyncStorage.removeItem(companyFullName);
    await AsyncStorage.removeItem(serviceUnitName);
    await AsyncStorage.removeItem(displayPicture);
    await AsyncStorage.removeItem(staffDisplayPicture);
    await AsyncStorage.removeItem(schName);
    await AsyncStorage.removeItem(profilekey);
    await AsyncStorage.removeItem(fieldkey);
    await AsyncStorage.removeItem(userRole);
    await AsyncStorage.removeItem(userOwner);
    await AsyncStorage.removeItem(userOwnerSecretKey);
    await AsyncStorage.removeItem(userStaffName);
    await AsyncStorage.removeItem(docStatus);
    await AsyncStorage.removeItem(superAdminTokenKey);
  } catch (error) {
    console.log("Error at delete customerid", error);
  }
};

const removeProfileComplete = async () => {
  try {
    await AsyncStorage.removeItem(profilekey);
  } catch (error) {
    console.log("Error removing the ProfileComplete", error);
  }
};

const removeScheduleName = async () => {
  try {
    await AsyncStorage.removeItem(schName);
  } catch (error) {
    console.log("Error removing the ScheduleName", error);
  }
};

const removeToken = async () => {
  try {
    await AsyncStorage.removeItem(tokenkey);
  } catch (error) {
    console.log("Error removing the token", error);
  }
};

const removeRole = async () => {
  try {
    await AsyncStorage.removeItem(userRole);
  } catch (error) {
    console.log("Error removing the role", error);
  }
};

const removeStaffToken = async () => {
  try {
    await AsyncStorage.removeItem(staffTokenkey);
  } catch (error) {
    console.log("Error removing the token", error);
  }
};

export default {
  storeField,
  storeId,
  storeProfileComplete,
  storeRole,
  storeStaus,
  storeOwner,
  storeStaffName,
  getOwner,
  getStaffName,
  storeOwnerSecretKey,
  getOwnerSecretKey,
  storeScheduleName,
  storeToken,
  storeSuperAdminToken,
  storeStaffToken,
  getEmail,
  getName,
  getField,
  getID,
  getCompany,
  getCompanyFullName,
  getServiceUnit,
  getDp,
  storeDp,
  getProfileComplete,
  getScheduleName,
  getStatus,
  getToken,
  getSuperAdminToken,
  getStaffToken,
  getUser,
  removeField,
  removeId,
  removeProfileComplete,
  removeScheduleName,
  removeToken,
  removeStaffToken,
  getPageSize,
  getRole,
  removeRole,
  getStaffDp,
  storeStaffDp,
  storeOldUser,
  getOldUser,
  storeCompanyFullName,
  storeCompany,
  getLeadSource,
  storeLeadSource,
};
