import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";

import LinkGroup from "../component/common/LinkGroup";
import UserIcon from "../assests/svg/users.svg";
import ConfigIcon from "../assests/svg/settings.svg";
import LogoutIcon from "../assests/svg/logout.svg";

import storage from "../auth/storage";
import ChatBotJson from "../assests/json/chat-bot.json";
import AuthContext from "../auth/context";
import string from "../string";

function DoctorHeader() {
  //#region const
  const navigate = useNavigate();
  const [role, setRole] = useState();
  const [dp, setDp] = useState();
  const [profile, setProfile] = useState("");
  const [status, setStatus] = useState("Disabled");
  const { smartAssistant } = useContext(AuthContext);
  //#endregion

  const handleUser = async () => {
    const staff = await storage.getEmail();
    console.log("getEmail---", staff);
    navigate("/newUser", { state: { staff } });
    return;
  };

  const getInfo = async () => {
    try {
      const user = await storage.getUser();
      const role = await storage.getRole();
      const dp =
        role === string.DOCTOR_ROLE
          ? await storage.getDp()
          : await storage.getStaffDp();
      const profile = await storage.getProfileComplete();
      const status = await storage.getStatus();
      setStatus(status);
      setProfile(profile);
      console.log("status--------", status);
      console.log("dp--------", dp);
      console.log("role--------", role);
      setDp(dp);
      if (!user) {
        navigate("/");
      }
      setRole(role);
    } catch (error) {
      console.log("Error", error);
    }
  };

  const handleSubmit1 = () => {
    document.getElementById("dropdownId").style.display =
      document.getElementById("dropdownId").style.display == "block"
        ? "none"
        : "block";
    return;
  };

  const handleMenu = () => {
    document.getElementById("DoctorNavBar").className = "navbarvertical";
  };

  useEffect(() => {
    getInfo();
  }, []);
  return (
    <>
      {/* {profile == 100 || role == "SuperAdmin" ? ( */}
      <section>
        <div className="row mt-3 mb-3">
          <div className="menushow">
            <div className="col-2 mt-2 ps-1">
              <button
                className="navbar-toggler mt-2"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#mynavbar"
                onClick={handleMenu}
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="col-10">
              <img
                src={require("../assests/png/Cloud_Clinic.png")}
                alt={string.websiteName + " Home"}
              />
            </div>
          </div>
          <div className="row no-gutters mt-2 ps-5">
            <div className="col-9"></div>
            <div className="col-3">
              {status == "Active" || role == string.DOCTOR_ROLE ? (
                <table
                  style={{
                    backgroundColor: "inherit",
                    float: "right",
                    borderBottom: "0px",
                  }}
                >
                  <tr>
                    <td className="topRestDp"></td>
                    {/* {smartAssistant && role != "SuperAdmin" ? (
                      <td width="5%" style={{ padding: 0 }}>
                        <div
                          style={{
                            width: "40px",
                            height: "40px",
                            marginTop: "-5px",
                          }}
                        >
                          <a href="/smartassistant">
                            <Lottie animationData={ChatBotJson} loop={true} />
                          </a>
                        </div>
                      </td>
                    ) : null} */}
                    {role != string.SUPERADMIN_ROLE && (
                      <td className="topDp">
                        <div
                          className="mr-1"
                          style={{
                            float: "right",
                            cursor: "pointer",
                          }}
                          onClick={handleSubmit1}
                        >
                          <img
                            src={dp}
                            alt={string.websiteName}
                            className="userimg"
                          />
                        </div>
                      </td>
                    )}
                  </tr>
                </table>
              ) : null}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8"></div>
          <div id="dropdownId" className="col-lg-2 usermenu">
            <div className="usermenu-menu">
              <div className="usermenu-menu-item">
                {role === string.DOCTOR_ROLE ? (
                  <LinkGroup
                    to="/basicinformation"
                    title="Edit Account"
                    // className="icon icofont-live-messenger"
                    src={UserIcon}
                  />
                ) : (
                  <LinkGroup
                    to="#"
                    title="Edit Account"
                    // className="icon icofont-live-messenger"
                    src={UserIcon}
                    onClick={handleUser}
                  />
                )}
              </div>
              <div className="usermenu-menu-item">
                {role == "Practitioner Permission" && (
                  <LinkGroup
                    to="/configuration"
                    title="Configuration"
                    // className="icon icofont-settings-alt"
                    src={ConfigIcon}
                  />
                )}
              </div>
              <div className="usermenu-menu-item">
                <LinkGroup
                  to="/logout"
                  title="Logout"
                  // className="icon icofont-logout"
                  src={LogoutIcon}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* ) : (
        <section>
          <div className="row mt-3 mb-4"></div>
        </section>
      )} */}
    </>
  );
}

export default DoctorHeader;
