import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import { months } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import string from "../string";
import storage from "../auth/storage";
import AuthContext from "../auth/context";
import "react-datepicker/dist/react-datepicker.css";
const prefixerror = "UpcomingAppointment";

function UpcomingAppointment() {
  //#region const
  const { heightConfig, weightConfig, bloodPressureConfig, gMeetConfig } =
    useContext(AuthContext);
  const [upcomingApp, setUpcomingApp] = useState([]);
  const [requestedApp, setRequestedApp] = useState([]);
  const [show, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [openModel, setOpenModal] = useState(false);
  const [role, setRole] = useState();
  const [companyId, setCompanyId] = useState();
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [appdate, setAppDate] = useState();
  const [consultationDraftData, setConsultationDraftData] = useState([]);
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear() + 2, 1);
  let todayDate = preFunction.getTodayDate();
  const DateCustomInput = preFunction.DateCustomInput;
  const [weight, setWeight] = useState("");
  const [height, setHeight] = useState("");
  const [bmi, setBMI] = useState("");
  const [bpSystolic, setBpSystolic] = useState("");
  const [bpDiastolic, setBpDiastolic] = useState("");
  const [temperature, setTemperature] = useState("");
  const [bmiNote, setBMINote] = useState("");
  const [appointmentId, setAppointmentId] = useState("");
  const [vitalId, setVitalId] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [reqCount, setReqCount] = useState(0);
  const [consultationTotal, setConsultationTotal] = useState(0);
  const [dash, setDash] = useState("all");
  var gapi = window.gapi;
  //#endregion

  const updateAppointment = async (name, gmeetLink) => {
    const updateAppointmentRes = await doctorApi.updateAAppointment(
      name,
      gmeetLink
    );
    console.log("updateAppointmentRes---", updateAppointmentRes);
    if (!updateAppointmentRes.ok) {
      ErrorLog(
        prefixerror + " updateAppointment " + "updateAAppointment",
        JSON.stringify(updateAppointmentRes),
        "We are facing some issues.Please try again after sometime.",
        "name:" + name + "gmeetLink" + gmeetLink
      );
      return;
    }

    getAppointmentList();
  };

  const handleConfirm = async (appointmentres) => {
    try {
      setLoad(true);
      const companyID = await storage.getCompany();
      console.log("appointmentres---", appointmentres);

      const patientRes = await doctorApi.checkPatientByCompany(
        appointmentres.patient,
        companyID
      );
      console.log("patientRes---", patientRes);

      if (patientRes.data.data.length > 0) {
        if (gMeetConfig && appointmentres.consultation_type == "Online") {
          await gmeet(appointmentres, patientRes);
        }
        const confirmRes = await doctorApi.confirmAppointment(
          appointmentres.name
        );
        if (!confirmRes.ok) {
          ErrorLog(
            prefixerror + " handleConfirm " + "confirmAppointment",
            JSON.stringify(confirmRes),
            "We are facing some issues.Please try again after sometime.",
            "appId:" + appointmentres.name
          );
          return;
        }
        const company = await storage.getCompanyFullName();
        console.log("confirmRes--", confirmRes);
        const msgRes = await doctorApi.sendAAppointmentMsg(
          patientRes.data.data[0].mobile,
          company,
          confirmRes.data.data.practitioner_name,
          confirmRes.data.data.appointment_datetime
        );
        console.log("msgRes---", msgRes);
        if (!msgRes.ok) {
          ErrorLog(
            prefixerror + " handleConfirm " + "sendAAppointmentMsg",
            JSON.stringify(msgRes),
            "We are facing some issues.Please try again after sometime.",
            "mobile:" +
              patientRes.data.data[0].mobile +
              "company:" +
              company +
              "practitioner_name:" +
              confirmRes.data.data.practitioner_name +
              "appointment_datetime:" +
              confirmRes.data.data.appointment_datetime
          );
          return;
        }
        getAppointmentList();
      } else {
        const newPatientRes = await doctorApi.createPatientFromPatient(
          appointmentres.patient,
          companyID
        );
        console.log("newPatientRes---", newPatientRes);
        if (!newPatientRes.ok) {
          ErrorLog(
            prefixerror + " handleConfirm " + "createPatientFromPatient",
            JSON.stringify(newPatientRes),
            "We are facing some issues.Please try again after sometime.",
            "patient:" + appointmentres.patient
          );
          return;
        }
        const gmeetlink = "";
        const appRes = await doctorApi.newAppointmentWithSMS(
          newPatientRes.data.message[0],
          appointmentres.practitioner,
          appointmentres.appointment_date,
          appointmentres.appointment_time,
          appointmentres.consultation_type,
          companyID,
          gmeetlink,
          newPatientRes.data.message[2]
        );
        console.log("newAppointmentWithSMS---------", appRes);

        if (!appRes.ok) {
          ErrorLog(
            prefixerror + " handleConfirm " + "newAppointment",
            JSON.stringify(appRes),
            "We are facing some issues.Please try again after sometime.",
            "patient:" +
              newPatientRes.data.message[0] +
              "practitioner:" +
              appointmentres.practitioner +
              "appointment_date:" +
              appointmentres.appointment_date +
              "appointment_time:" +
              appointmentres.appointment_time +
              "consultation_type:" +
              appointmentres.consultation_type +
              "companyId:" +
              companyID +
              "gmeetlink:" +
              gmeetlink +
              "mobile: " +
              newPatientRes.data.message[2]
          );
          return;
        }

        if (gMeetConfig && appointmentres.consultation_type == "Online") {
          await gmeet(appRes.data.data, newPatientRes);

          const invRes = await doctorApi.getInvoiceByAppointment(
            appointmentres.name
          );
          console.log("invRes---", invRes);
          if (!invRes.ok) {
            ErrorLog(
              prefixerror + "handleConfirm " + "getInvoiceByAppointment",
              JSON.stringify(invRes),
              "We are facing some issues.Please try again after sometime.",
              "appointment:" + appointmentres.name
            );
            return;
          }

          if (invRes.data.data.length > 0) {
            const updInvRes = await doctorApi.updateInvoiceAppointmentId(
              invRes.data.data[0].name,
              appRes.data.data.name,
              newPatientRes.data.message[0]
            );
            console.log("updInvRes---", updInvRes);
            if (!updInvRes.ok) {
              ErrorLog(
                prefixerror + "handleConfirm " + "updateInvoiceAppointmentId",
                JSON.stringify(updInvRes),
                "We are facing some issues.Please try again after sometime.",
                "invoceName:" +
                  invRes.data.data[0].name +
                  "appointment:" +
                  appRes.data.data.name
              );
              return;
            }
          }
        }
        const delAppointmentres = await doctorApi.deleteAppointmentById(
          appointmentres.name
        );
        console.log("delAppointmentres---", delAppointmentres);
        if (!delAppointmentres.ok) {
          ErrorLog(
            prefixerror + " handleConfirm " + "deleteAppointmentById",
            JSON.stringify(delAppointmentres),
            "We are facing some issues.Please try again after sometime.",
            "appId:" + appointmentres.name
          );
          return;
        }

        getAppointmentList();
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " handleConfirm ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const gmeet = async (appointmentres, patientRes) => {
    console.log("patientRes----", patientRes);
    const company_name = await storage.getCompanyFullName();
    let email = await storage.getID();
    // const email = "jsumalatha85@gmail.com";
    const addressRes = await doctorApi.getCompanyAddress(companyId);
    if (!addressRes.ok) {
      ErrorLog(
        prefixerror + " gmeet " + "getCompanyAddress",
        JSON.stringify(addressRes),
        "We are facing some issues.Please try again after sometime.",
        "companyId:" + companyId
      );
      return;
    }
    console.log("addressRes---", addressRes);
    const timesplit = appointmentres.appointment_time.split(":");
    const t = parseInt(timesplit[0]) + 1;
    const endtime =
      appointmentres.appointment_date +
      "T" +
      t +
      ":" +
      timesplit[1] +
      ":" +
      timesplit[2];
    const starttime =
      appointmentres.appointment_date + "T" + appointmentres.appointment_time;
    console.log("starttime--", starttime);
    console.log("endtime--", endtime);
    let addr = company_name;
    if (addressRes.data.data.length > 0) {
      addr +=
        ", " +
        addressRes.data.data[0].address_line1 +
        ", " +
        addressRes.data.data[0].address_line2 +
        ", " +
        addressRes.data.data[0].city +
        " - " +
        addressRes.data.data[0].pincode;
    }
    let patientEmail = patientRes.data.message
      ? patientRes.data.message[1]
      : patientRes.data.data[0].email;
    gapi.client.setApiKey(string.API_KEY);
    gapi.client
      .load("https://content.googleapis.com/discovery/v1/apis/calendar/v3/rest")
      .then(
        function () {
          console.log("GAPI client loaded for API");
        },
        function (err) {
          console.error("Error loading GAPI client for API", err);
        }
      );
    gapi.auth2
      .getAuthInstance()
      .signIn()
      .then((googleUser) => {
        var profile = googleUser.getBasicProfile();
        let baseemail = profile.getEmail();
        var request = gapi.client.calendar.events.insert({
          calendarId: baseemail,
          resource: {
            summary: string.websiteName + " Appointment",
            location: addr,
            description: "Booked appointment with " + company_name,
            start: {
              dateTime: starttime,
              timeZone: "Asia/Kolkata",
            },
            end: {
              dateTime: endtime,
              timeZone: "Asia/Kolkata",
            },
            attendees: [{ email: email }, { email: patientEmail }],

            conferenceData: {
              createRequest: {
                requestId: "sample232",
                conferenceSolutionKey: {
                  type: "hangoutsMeet",
                },
              },
            },
            reminders: {
              useDefault: false,
              overrides: [
                { method: "email", minutes: 1 * 60 },
                { method: "popup", minutes: 1 * 60 },
                { method: "popup", minutes: 1 * 30 },
              ],
            },
          },
          conferenceDataVersion: 1,
        });
        request.execute(function (event) {
          console.log(event, "event");
          console.log(event.hangoutLink, "event.hangoutLink");
          setMessage("Appointment confirmed successfully");
          updateAppointment(appointmentres.name, event.hangoutLink);
          return true;
        });
      })
      .catch((e) => {
        console.log(e);
        if (e.error == "popup_closed_by_user") {
          setMessage(
            "Appointment confirmed successfully. Video consulatation not created"
          );
        }
        return false;
      });
    return true;
  };

  const handleVitalSigns = async () => {
    try {
      console.log("here");
      let bp = bpSystolic + "/" + bpDiastolic;

      if (vitalId == "") {
        const appointmentRes = await doctorApi.getAppointmentById(
          appointmentId
        );
        console.log("appointmentRes---", appointmentRes);
        if (!appointmentRes.ok) {
          ErrorLog(
            prefixerror + " handleVitalSigns " + "getAppointmentById",
            JSON.stringify(appointmentRes),
            "We are facing some issues.Please try again after sometime.",
            "appointmentId:" + appointmentId
          );
          return;
        }
        const res = await doctorApi.addVitalSigns(
          appointmentId,
          appointmentRes.data.data.patient,
          appointmentRes.data.data.patient_name,
          appointmentRes.data.data.company,
          temperature,
          bmiNote,
          height,
          weight,
          bmi,
          bpSystolic,
          bpDiastolic,
          bp
        );
        console.log("addVitalSigns---", res);

        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleVitalSigns " + "addVitalSigns",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "appointmentId:" +
              appointmentId +
              "patient:" +
              appointmentRes.data.data.patient +
              "patient_name:" +
              appointmentRes.data.data.patient_name +
              "company:" +
              appointmentRes.data.data.company +
              "temperature:" +
              temperature +
              "bmiNote:" +
              bmiNote +
              "height:" +
              height +
              "weight:" +
              weight +
              "bmi:" +
              bmi +
              "bpSystolic:" +
              bpSystolic +
              "bpDiastolic:" +
              bpDiastolic +
              "bp:" +
              bp
          );
          return;
        }
        console.log("res---", res);
      } else {
        const editRes = await doctorApi.editVitalSigns(
          vitalId,
          temperature,
          bmiNote,
          height,
          weight,
          bmi,
          bpSystolic,
          bpDiastolic,
          bp
        );
        if (!editRes.ok) {
          ErrorLog(
            prefixerror + " handleVitalSigns " + "editVitalSigns",
            JSON.stringify(editRes),
            "We are facing some issues.Please try again after sometime.",
            "vitalId:" +
              vitalId +
              "temperature:" +
              temperature +
              "bmiNote:" +
              bmiNote +
              "height:" +
              height +
              "weight:" +
              weight +
              "bmi:" +
              bmi +
              "bpSystolic:" +
              bpSystolic +
              "bpDiastolic:" +
              bpDiastolic +
              "bp:" +
              bp
          );
          return;
        }
        console.log("editRes---", editRes);
      }
      setOpenModal(false);
      return;
    } catch (error) {
      ErrorLog(
        prefixerror + " handleVitalSigns ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleBMI = (height, weight) => {
    if (height > 0 && weight > 0) {
      const afterHight = height / 100;
      const calculate = weight / (afterHight * afterHight);
      console.log("BMi", bmi, calculate.toFixed(1).toString() + "-" + "Normal");
      if (calculate < 18.5) {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Under Weight");
      } else if (calculate >= 18.5 && calculate <= 24.9) {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Normal");
      } else {
        setBMI(calculate.toFixed(1).toString());
        setBMINote("Over Weight");
      }
    }
  };

  const handleUpdateVitals = async (appId) => {
    try {
      console.log("appid----", appId);
      setAppointmentId(appId);
      const vitalRes = await doctorApi.getVitalSigns(appId);
      if (!vitalRes.ok) {
        ErrorLog(
          prefixerror + " handleUpdateVitals " + "getVitalSigns",
          JSON.stringify(vitalRes),
          "We are facing some issues.Please try again after sometime.",
          "appId:" + appId
        );
        return;
      }
      console.log("vitalRes---", vitalRes);
      setHeight("");
      setWeight("");
      setTemperature("");
      setBpSystolic("");
      setBpDiastolic("");
      setBMI("");
      setBMINote("");
      setVitalId("");

      if (vitalRes.data.data.length > 0) {
        setHeight(vitalRes.data.data[0].height);
        setWeight(vitalRes.data.data[0].weight);
        setTemperature(vitalRes.data.data[0].temperature);
        setBpSystolic(vitalRes.data.data[0].bp_systolic);
        setBpDiastolic(vitalRes.data.data[0].bp_diastolic);
        setBMI(vitalRes.data.data[0].bmi);
        setBMINote(vitalRes.data.data[0].nutrition_note);
        setVitalId(vitalRes.data.data[0].name);
      }
      setOpenModal(true);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleUpdateVitals ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleFilterDate = async (val) => {
    setLoad(true);
    const value = moment(val).format("yyyy-MM-DD");
    navigate("/upcomingappointment", { state: { date: value } });
    window.location.reload();
  };

  const handleClick = async (appointmentID) => {
    navigate("/startconsultation", { state: { appointmentID } });
  };

  const handleResumeConsultation = async (consultationID) => {
    navigate("/startconsultation", { state: { consultationID } });
  };

  const handleCancel = async (appointmentID) => {
    try {
      setLoad(true);
      const res = await doctorApi.cancelAppointment(appointmentID);
      console.log("cancel res--", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " handleCancel " + "cancelAppointment",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "appointmentID:" + appointmentID
        );
        return;
      }

      //cancel message

      setOpen(true);
      setMessage("Appointment cancelled");
      getAppointmentList();
      setLoad(false);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " handleCancel ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleReschedule = async (appointmentID) => {
    //console.log("appointmentID", appointmentID);
    navigate("/newappointment", { state: { appointmentID } });
  };

  const getAppointmentListDate = async (dash) => {
    try {
      setLoad(true);
      setUpcomingApp([]);
      setRequestedApp([]);
      setConsultationDraftData([]);
      setAppDate(new Date(date));

      const doctorID = await storage.getID();
      const companyId = await storage.getCompany();
      const role = await storage.getRole();
      let res;
      let consultationRes;
      setShow(true);

      if (dash == "day") {
        const date = todayDate;
        console.log("date--", date);
        console.log("role--", role);
        if (role != "Practitioner Permission") {
          res = await doctorApi.getAppointmentByClinic(companyId, date);
          console.log("getAppointmentByClinic---", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror + " getAppointmentList " + "getAppointmentByClinic",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" + companyId + "date:" + date
            );
            return;
          }

          consultationRes = await doctorApi.pausedConsultationByCompany(
            companyId,
            date
          );
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultationByCompany",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" + companyId + "date:" + date
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        } else {
          res = await doctorApi.filterDateConsultation(doctorID, date);
          console.log("filterDateConsultation----", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror + " getAppointmentList " + "filterDateConsultation",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }

          consultationRes = await doctorApi.pausedConsultation(doctorID, date);
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultation",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        }
      } else if (dash == "week") {
        var fromDate = moment().startOf("week").format("yyyy-MM-DD");
        var toDate = moment().endOf("week").format("yyyy-MM-DD");

        if (role != "Practitioner Permission") {
          res = await doctorApi.appointmentByDatesByCompany(
            companyId,
            fromDate,
            toDate
          );
          console.log("appointmentByDatesByCompany---", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror +
                " getAppointmentList " +
                "appointmentByDatesByCompany",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" +
                companyId +
                "fromDate:" +
                fromDate +
                "toDate:" +
                toDate
            );
            return;
          }

          consultationRes =
            await doctorApi.pausedConsultationWithDatesByCompany(
              companyId,
              fromDate,
              toDate
            );
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultationByCompany",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" +
                companyId +
                "fromDate:" +
                fromDate +
                "toDate:" +
                toDate
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        } else {
          res = await doctorApi.appointmentByDates(doctorID, fromDate, toDate);
          console.log("res----", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror + " getAppointmentList " + "filterDateConsultation",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }

          setShow(true);

          consultationRes = await doctorApi.pausedConsultationWithDates(
            doctorID,
            fromDate,
            toDate
          );
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultation",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        }
      } else if (dash == "month") {
        var fromDate = moment().startOf("month").format("yyyy-MM-DD");
        var toDate = moment().endOf("month").format("yyyy-MM-DD");

        if (role != "Practitioner Permission") {
          res = await doctorApi.appointmentByDatesByCompany(
            companyId,
            fromDate,
            toDate
          );
          console.log("appointmentByDatesByCompany---", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror +
                " getAppointmentList " +
                "appointmentByDatesByCompany",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" +
                companyId +
                "fromDate:" +
                fromDate +
                "toDate:" +
                toDate
            );
            return;
          }

          consultationRes =
            await doctorApi.pausedConsultationWithDatesByCompany(
              companyId,
              fromDate,
              toDate
            );
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultationByCompany",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "companyId:" +
                companyId +
                "fromDate:" +
                fromDate +
                "toDate:" +
                toDate
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        } else {
          res = await doctorApi.appointmentByDates(doctorID, fromDate, toDate);
          console.log("res----", res);
          if (!res.ok) {
            ErrorLog(
              prefixerror + " getAppointmentList " + "filterDateConsultation",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }

          setShow(true);

          consultationRes = await doctorApi.pausedConsultationWithDates(
            doctorID,
            fromDate,
            toDate
          );
          if (!consultationRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "pausedConsultation",
              JSON.stringify(consultationRes),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID + "date:" + date
            );
            return;
          }
          console.log("consultationRes---", consultationRes);
        }
      }

      let requestedAppointment = [];
      let openAppointment = [];
      for (let i = 0; i < res.data.data.length; i++) {
        if (
          res.data.data[i].requested &&
          (res.data.data[i].consultation_type == "Walk In" ||
            (res.data.data[i].consultation_type == "Online" &&
              res.data.data[i].paid_status))
        ) {
          requestedAppointment = [...requestedAppointment, res.data.data[i]];
        } else if (
          res.data.data[i].consultation_type == "Walk In" ||
          (res.data.data[i].consultation_type == "Online" &&
            res.data.data[i].paid_status)
        )
          openAppointment = [...openAppointment, res.data.data[i]];
      }
      console.log("requestedAppointment--- ", requestedAppointment);
      console.log("openAppointment--- ", openAppointment);
      setUpcomingApp(openAppointment);
      setTotalCount(openAppointment.length);
      setReqCount(requestedAppointment.length);
      setRequestedApp(requestedAppointment);

      if (consultationRes.data.data.length > 0) {
        setConsultationDraftData(consultationRes.data.data);
        setConsultationTotal(consultationRes.data.data.length);
      }
      setLoad(false);
    } catch (error) {
      console.log("error---", error);
    }
  };

  const getAppointmentList = async () => {
    try {
      const company = await storage.getCompany();
      console.log("company---", company);
      setCompanyId(company);
      const role = await storage.getRole();
      console.log("role-------------------", role);
      setRole(role);
      const doctorID = await storage.getID();
      if (!doctorID) {
        navigate("/logout");
        return;
      }

      if (location.state && location.state.dash) {
        var dash = location.state.dash;
        console.log("location.state--", location.state);
        setDash(dash);
        getAppointmentListDate(dash, role);
        return;
      }

      const date =
        location.state && location.state.date
          ? moment(new Date(location.state.date)).format("yyyy-MM-DD")
          : todayDate;
      setLoad(true);
      console.log("date----", date);
      setUpcomingApp([]);
      setRequestedApp([]);
      setConsultationDraftData([]);
      setAppDate(new Date(date));
      if (!date) {
        setShow(false);
        setLoad(false);
        return;
      }
      let res = "";
      if (role != "Practitioner Permission") {
        res = await doctorApi.getAppointmentByClinic(company, date);
        console.log("res---------------------", res);
      } else {
        res = await doctorApi.filterDateConsultation(doctorID, date);
        console.log("res----", res);
      }
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getAppointmentList " + "filterDateConsultation",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID + "date:" + date
        );
        return;
      }

      setShow(true);
      let requestedAppointment = [];
      let openAppointment = [];
      for (let i = 0; i < res.data.data.length; i++) {
        if (
          res.data.data[i].requested &&
          (res.data.data[i].consultation_type == "Walk In" ||
            (res.data.data[i].consultation_type == "Online" &&
              res.data.data[i].paid_status))
        ) {
          requestedAppointment = [...requestedAppointment, res.data.data[i]];
        } else if (
          res.data.data[i].consultation_type == "Walk In" ||
          (res.data.data[i].consultation_type == "Online" &&
            res.data.data[i].paid_status)
        )
          openAppointment = [...openAppointment, res.data.data[i]];
      }
      console.log("requestedAppointment--- ", requestedAppointment);
      console.log("openAppointment--- ", openAppointment);
      setUpcomingApp(openAppointment);
      setTotalCount(openAppointment.length);
      setReqCount(requestedAppointment.length);
      setRequestedApp(requestedAppointment);
      const consultationRes = await doctorApi.pausedConsultation(
        doctorID,
        date
      );
      if (!consultationRes.ok) {
        ErrorLog(
          prefixerror + " handleSubmit " + "pausedConsultation",
          JSON.stringify(consultationRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID + "date:" + date
        );
        return;
      }
      console.log("consultationRes---", consultationRes);
      if (consultationRes.data.data.length > 0) {
        setConsultationDraftData(consultationRes.data.data);
        setConsultationTotal(consultationRes.data.data.length);
      }

      setLoad(false);
      return;
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " getAppointmentList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const initializeGapiClient = async () => {
    try {
      await gapi.client.init({
        clientId: string.CLIENT_ID,
        scope: string.SCOPE,
        plugin_name: (string.websiteName = " Event"),
        apikey: string.API_KEY,
      });
    } catch (error) {
      console.log("Gapi Exception---", error);
    }
  };

  const gapiLoad = () => {
    gapi.load("client:auth2", initializeGapiClient);
  };

  const handleGmeet = async (item) => {
    const gmeetRes = await doctorApi.sendPushNotification(item.name);
    console.log("gmeetRes----", gmeetRes);
    window.open(item.online_link);
  };

  useEffect(() => {
    getAppointmentList();
    gapiLoad();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />

      <section>
        <div className="row no-gutters">
          <div className="col-lg-2">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row no-gutters" onClick={preFunction.hideNavbar}>
              <div className="pd">
                <div className="row no-gutters pageTitle">Appointment</div>
                <div className="row no-gutters mt-3 form-group">
                  <div className="col-lg-2 mt-3 p-0" style={{ width: "65px" }}>
                    <label>
                      <span className="star">*</span> Date :
                    </label>
                  </div>
                  <div className="col-lg-2 p-0 mt-2" style={{ width: "155px" }}>
                    <DatePicker
                      name="appdate"
                      id="appdate"
                      selected={appdate}
                      minDate={new Date("2022-01-01")}
                      maxDate={new Date(date.getFullYear() + 2 + "-12-31")}
                      onChange={(date) => {
                        setAppDate(date);
                        handleFilterDate(date);
                      }}
                      dateFormat="dd-MM-yyyy"
                      customInput={<DateCustomInput />}
                      className="form-control datepicker"
                      renderCustomHeader={({
                        date,
                        changeYear,
                        changeMonth,
                      }) => (
                        <div
                          style={{
                            margin: 10,
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <select
                            style={{ marginRight: "3px" }}
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) =>
                              changeYear(value)
                            }
                          >
                            {years.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                          <select
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) =>
                              changeMonth(months.indexOf(value))
                            }
                          >
                            {months.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                      )}
                    />
                  </div>
                  <div className="col-lg-2 mt-2">
                    <button
                      title="Book Appointment"
                      className="btn-2"
                      onClick={(e) => {
                        navigate("/newappointment");
                      }}
                    >
                      <span className="icofont icofont-plus"></span>
                      Book Appointment
                    </button>
                  </div>
                </div>
                <ErrorMessage error={message} visible={open} />
                <div className="row no-gutters mt-5">
                  {dash == "all" && location.state && location.state.date && (
                    <div
                      className="col-lg-2 pointer border p-2 text-center"
                      style={{
                        backgroundColor: "#FAA835",
                      }}
                    >
                      <span className="dashhead">
                        {moment(location.state.date).format("DD-MM-yyyy")}
                      </span>
                    </div>
                  )}
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "day" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getAppointmentListDate("day");
                      setDash("day");
                    }}
                  >
                    <span className="dashhead">Today</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "week" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getAppointmentListDate("week");
                      setDash("week");
                    }}
                  >
                    <span className="dashhead">This Week</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "month" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getAppointmentListDate("month");
                      setDash("month");
                    }}
                  >
                    {dash == "month" ? (
                      <span className="dashhead">This Month</span>
                    ) : (
                      <span className="dashhead">This Month</span>
                    )}
                  </div>
                </div>
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} appointment identified.
                  </div>
                  {upcomingApp.length > 0 ? (
                    <div id="report" className="table-responsive">
                      <table className="table mt-1">
                        <thead>
                          <tr>
                            <th width="1%">No</th>
                            <th width="10%">Practitioner Name</th>
                            <th width="10%">Date</th>
                            <th width="10%">Time</th>
                            <th>Patient Name</th>
                            <th width="10%">Type</th>
                            <th width="10%">Vitals</th>
                            <th width="10%">Consultation</th>
                            <th width="10%">Video Consultation</th>
                            <th width="10%">Reschedule</th>
                            <th width="10%">Cancel</th>
                          </tr>
                        </thead>
                        <tbody>
                          {upcomingApp.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.practitioner_name}</td>
                              <td className="nowrapWhiteSpace">
                                {moment(item.appointment_date).format(
                                  "DD-MM-yyyy"
                                )}
                              </td>
                              <td className="nowrapWhiteSpace">
                                {preFunction.displayTimeFormat(
                                  item.appointment_time
                                )}
                              </td>
                              <td className="nowrapWhiteSpace">
                                {item.patient_name}
                              </td>
                              <td className="nowrapWhiteSpace">
                                {item.consultation_type}
                              </td>
                              <td>
                                {item.status == "Open" && (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Reschedule"
                                    onClick={() =>
                                      handleUpdateVitals(item.name)
                                    }
                                  >
                                    <span className="icofont icofont-pencil"></span>
                                    Update Vitals
                                  </button>
                                )}
                              </td>
                              <td>
                                {item.status == "Closed" ? (
                                  <button
                                    type="button"
                                    className="btnGreen"
                                    title="Completed"
                                  >
                                    <span className="icofont-tick-mark"></span>
                                    Completed
                                  </button>
                                ) : item.status == "Cancelled" ? (
                                  <button
                                    type="button"
                                    className="btnGreen bg-pink-dark"
                                  >
                                    <span className="icofont-ui-block"></span>
                                    Cancelled
                                  </button>
                                ) : item.status !== "Open" ||
                                  role !== "Practitioner Permission" ? (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Scheduled"
                                  >
                                    <span className="icofont-clock-time"></span>
                                    Scheduled
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Start Counsultation"
                                    onClick={() => handleClick(item.name)}
                                  >
                                    <span className="icofont-arrow-right"></span>
                                    Start Counsultation
                                  </button>
                                )}
                              </td>
                              <td align="center" valign="middle">
                                {role === string.DOCTOR_ROLE &&
                                item.online_link &&
                                item.online_link != "" ? (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Start Counsultation"
                                    onClick={() => {
                                      handleGmeet(item);
                                    }}
                                  >
                                    <span className="icofont-video-cam"></span>
                                    Start GMeet
                                  </button>
                                ) : null}
                              </td>
                              <td>
                                {item.status != "Closed" && (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Reschedule"
                                    onClick={() => handleReschedule(item.name)}
                                  >
                                    <span className="icofont-exchange"></span>
                                    Reschedule
                                  </button>
                                )}
                              </td>
                              <td>
                                {item.status == "Open" && (
                                  <button
                                    type="button"
                                    className="btn bg-pink-dark"
                                    title="Cancel"
                                    onClick={() => {
                                      handleCancel(item.name);
                                    }}
                                  >
                                    <span className="icofont-close"></span>
                                    Cancel
                                  </button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                  {requestedApp.length > 0 ? (
                    <>
                      <div className="row no-gutters ms-3 mt-2">
                        <h5>Requested Appointments</h5>
                      </div>
                      <div className="row totcntstyle">
                        A total of {reqCount} appointment request identified.
                      </div>
                      <div id="report" className="table-responsive">
                        <table className="table mt-1">
                          <thead>
                            <tr>
                              <th width="10%">Date</th>
                              <th width="10%">Time Slot</th>
                              <th>Patient Name</th>
                              <th width="10%">Type</th>
                              <th width="10%">Confirm</th>
                              <th width="10%">Cancel</th>
                            </tr>
                          </thead>
                          <tbody>
                            {requestedApp.map((item, index) => (
                              <tr key={index}>
                                <td>
                                  {moment(item.appointment_date).format(
                                    "DD-MM-yyyy"
                                  )}
                                </td>
                                <td>
                                  {preFunction.displayTimeFormat(
                                    item.appointment_time
                                  )}
                                </td>
                                <td>{item.patient_name}</td>
                                <td>{item.consultation_type}</td>
                                <td>
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Reschedule"
                                    onClick={() => handleConfirm(item)}
                                  >
                                    <span className="icofont-save"></span>
                                    Confirm
                                  </button>
                                </td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn bg-pink-dark"
                                    title="Cancel"
                                    onClick={() => {
                                      handleCancel(item.name);
                                    }}
                                  >
                                    <span className="icofont-close"></span>
                                    Cancel
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </>
                  ) : null}
                  {consultationDraftData.length > 0 ? (
                    <div className="row no-gutters mt-5">
                      <h4 className="row">Paused Consultation</h4>
                      <div className="row totcntstyle">
                        A total of {consultationTotal} paused consultation
                        identified.
                      </div>
                      <div className="row table-responsive mt-1">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="10%">Date</th>
                              <th width="10%">Time</th>
                              <th>Name</th>
                              <th width="10%">Status</th>
                            </tr>
                          </thead>
                          <tbody>
                            {consultationDraftData.map((item, index) => {
                              const time = item.encounter_time.split(":");
                              const selectedTime = time[0] + ":" + time[1];
                              return (
                                <tr key={index}>
                                  <td className="nowrapWhiteSpace">
                                    {moment(item.encounter_date).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                  <td>{selectedTime}</td>
                                  <td className="nowrapWhiteSpace">
                                    {item.patient_name}
                                  </td>
                                  <td>
                                    {role !== "Practitioner Permission" ? (
                                      <button
                                        type="submit"
                                        className="btn"
                                        title="Scheduled"
                                      >
                                        <span className="icofont-clock-time"></span>
                                        Scheduled
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() =>
                                          handleResumeConsultation(item.name)
                                        }
                                        className="btn"
                                      >
                                        <span className="icofont icofont-pencil"></span>
                                        Resume Consultation
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
              <>
                <Modal className="modalwidth" show={openModel}>
                  <Modal.Header>
                    {!heightConfig && !weightConfig && !bloodPressureConfig ? (
                      <Modal.Title>Success</Modal.Title>
                    ) : (
                      <Modal.Title>Vitals</Modal.Title>
                    )}
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        setOpenModal(false);
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="row" style={{ width: "100%" }}>
                      {!heightConfig &&
                      !weightConfig &&
                      !bloodPressureConfig ? (
                        <div className="form-horizontal">
                          <h6>Appointment Booked Successfully</h6>
                        </div>
                      ) : (
                        <div className="form-horizontal">
                          {heightConfig ? (
                            <div className="row ">
                              <label className="control-label col-lg-5">
                                Height (cm) :
                              </label>
                              <div className="form-group col-lg-2 p-0">
                                <input
                                  className="form-control"
                                  type="number"
                                  name="Height"
                                  id="Height"
                                  value={height > 0 ? height : ""}
                                  onChange={(e) => {
                                    e.target.value =
                                      e.target.value < 0
                                        ? ""
                                        : e.target.value.length > 3
                                        ? e.target.value.slice(0, 3)
                                        : e.target.value;
                                    setHeight(e.target.value);
                                    handleBMI(e.target.value, weight);
                                  }}
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 && handleVitalSigns()
                                  }
                                  placeholder="Height in cm"
                                />
                              </div>
                            </div>
                          ) : null}
                          {weightConfig ? (
                            <div className="row ">
                              <label className="control-label col-lg-5">
                                Weight (kg) :
                              </label>
                              <div className="form-group col-lg-2 p-0">
                                <input
                                  className="form-control"
                                  type="number"
                                  name="Weight"
                                  value={weight > 0 ? weight : ""}
                                  onChange={(e) => {
                                    e.target.value =
                                      e.target.value < 0
                                        ? ""
                                        : e.target.value.length > 3
                                        ? e.target.value.slice(0, 3)
                                        : e.target.value;
                                    setWeight(e.target.value);
                                    handleBMI(height, e.target.value);
                                  }}
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 && handleVitalSigns()
                                  }
                                  placeholder="Weight in kg"
                                />
                              </div>
                            </div>
                          ) : null}
                          {bmi && weight && height ? (
                            <div className="row mb-4">
                              <label className="control-label col-lg-5">
                                BMI :
                              </label>
                              <div className="col-lg-5 mt-2 p-0">
                                {bmi} ({bmiNote})
                              </div>
                            </div>
                          ) : null}
                          {bloodPressureConfig ? (
                            <>
                              <div className="form-group row">
                                <label className="control-label col-lg-5">
                                  Temperature (°F) :
                                </label>
                                <div className="col-lg-2 p-0">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="temperature"
                                    value={temperature}
                                    onChange={(e) => {
                                      e.target.value =
                                        e.target.value.length > 0
                                          ? e.target.value.slice(0, 3)
                                          : e.target.value;
                                      setTemperature(e.target.value);
                                    }}
                                    placeholder="Temperature"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleVitalSigns()
                                    }
                                  />
                                </div>
                              </div>
                              <div className="form-group row ">
                                <label className="control-label col-lg-5">
                                  Blood Pressure :
                                </label>
                                <div className="col-lg-2 p-0">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="Blood Pressure"
                                    value={bpSystolic}
                                    onChange={(e) => {
                                      e.target.value =
                                        e.target.value.length > 0
                                          ? e.target.value.slice(0, 3)
                                          : e.target.value;
                                      setBpSystolic(e.target.value);
                                    }}
                                    placeholder="Systolic"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleVitalSigns()
                                    }
                                  />
                                </div>
                                <div
                                  className="col-lg-1 mt-2 ms-1"
                                  style={{
                                    fontSize: "35px",
                                  }}
                                >
                                  /
                                </div>
                                <div className="col-lg-2 p-0">
                                  <input
                                    className="form-control"
                                    type="text"
                                    name="Blood Pressure"
                                    value={bpDiastolic}
                                    onChange={(e) => {
                                      e.target.value =
                                        e.target.value.length > 0
                                          ? e.target.value.slice(0, 2)
                                          : e.target.value;
                                      setBpDiastolic(e.target.value);
                                    }}
                                    placeholder="Diastolic"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleVitalSigns()
                                    }
                                  />
                                </div>
                              </div>
                            </>
                          ) : null}
                        </div>
                      )}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn"
                      title={"Save Vitals"}
                      onClick={handleVitalSigns}
                    >
                      <span className="icofont-arrow-right"></span>
                      Save Vitals
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default UpcomingAppointment;
