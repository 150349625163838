import React from "react";

import preFunction from "../component/common/CommonFunction";

import string from "../string";
import PageHeader from "./pageHeader";

const AboutUs = () => {
  return (
    <>
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="outerpadding">
              <h4>ABOUT {string.websiteName.toUpperCase()}</h4>
              <p>
                <b>{string.websiteName}</b>&nbsp;is a unit of SMV Healthcare
                Private Limited.
              </p>
              <p>
                <b>What is&nbsp;{string.websiteName}?</b>
              </p>
              <p>
                <b>{string.websiteName}</b> is a cloud-based healthcare platform
                that provides secure, convenient access to medical records and
                services for patients, doctors, and other healthcare
                professionals. It enables users to manage their health
                information in one place, securely share it with providers, and
                receive personalized care from anywhere.
              </p>
              <p>
                <b>{string.websiteName}</b> works by connecting patients with
                their healthcare providers through an online portal where they
                can view their medical records, schedule appointments,
                communicate with their doctor or nurse practitioner via video
                chat or messaging service, refill prescriptions electronically,
                pay bills online and more. The platform also allows providers to
                store patient data securely in the cloud so that it can be
                accessed anytime from any device.
              </p>
              <p>
                <b>Why should you choose {string.websiteName}?</b>
              </p>
              <p>
                An online consultation app is a platform that enables patients
                to connect with doctors or other healthcare professionals
                virtually for medical consultations and advice.
              </p>
              <p>
                The benefits of using an online consultation app include
                convenience, accessibility, cost savings, and the ability to
                receive medical advice and treatment from the comfort of your
                own home.
              </p>
              <p>
                <b>What are the benefits of {string.websiteName}?</b>
              </p>
              <ul
                className="ptext"
                style={{ marginLeft: "30px", fontSize: "15px" }}
              >
                <li>
                  You can access your health information from any device with an
                  internet connection. {string.websiteName} allows you to
                  securely store, access, and share your health information from
                  anywhere.
                </li>
                <li>
                  Video-based telemedicine is a type of telemedicine that allows
                  patients and providers to communicate via video conference.
                </li>
                <li>
                  Improved access to healthcare, increased efficiency, cost
                  savings, and improved quality of care through Telemedicine
                </li>
                <li>
                  {string.websiteName} takes data security very seriously and
                  has implemented several layers of protection including
                  encryption technology , two factor authentication , role based
                  access control , audit trails & logging capabilities etc.,to
                  ensure that sensitive patient data remains safe & secure at
                  all times
                </li>
                <li>
                  Select a suitable payment option (cash, credit/debit card,
                  Online Banking, UPI).
                </li>
                <li>
                  All data stored on the {string.websiteName} platform is
                  encrypted using industry-standard security protocols so your
                  personal information remains private and secure at all times.
                </li>
              </ul>

              <p>
                <b>{string.websiteName}</b> is one of the top online pharmacy
                apps in India that empowers people to Consult Doctors, buy
                medicines and other healthcare services at the convenience of
                home.&nbsp; It offers a complete healthcare solution for its
                customers. Headquartered at Chennai, <b>{string.websiteName}</b>{" "}
                provides its services across India and is able to connect
                Doctors and Patients at over 26000+ Pin codes across India
                swiftly.
              </p>
              <p>
                Various services offered by <b>{string.websiteName} Doctor</b>{" "}
                App are as follows.
              </p>
              <ul
                className="ptext"
                style={{ marginLeft: "30px", fontSize: "15px" }}
              >
                <li>Patient Consultation (chat, Voice, and Video)</li>
                <li>Appointment Scheduling and Rescheduling.</li>
                <li>
                  Organize and view Consultations &amp; Patient Histories.
                </li>
                <li>Organize and view Patient Fees payment Histories.</li>
                <li>Maintain clinical notes.</li>
                <li>Prescribe Medicines and Lab tests Online.</li>
                <li>Give Consultation to Patients across India Online</li>
              </ul>
              <p>
                Thank you for choosing <b>{string.websiteName}</b> , and please
                don't hesitate to contact us if you have any questions or
                concerns. We're here to help you live a healthier, happier life.
              </p>
            </div>
            <div className="outerpadding">
              <h4>ABOUT {string.patientAppName.toUpperCase()}</h4>
              <p>
                {" "}
                <b>{string.patientAppName}</b>&nbsp;is a unit of SMV Healthcare
                Private Limited.
              </p>
              <p>
                <b>What is&nbsp;{string.patientAppName}?</b>
              </p>
              <p>
                <b>{string.patientAppName}</b>, your convenient and secure
                online platform for accessing healthcare services from the
                comfort of your own home. Our team is dedicated to making
                healthcare more accessible, affordable, and convenient for
                everyone.
              </p>
              <p>
                <b>Why should you choose {string.patientAppName} ?</b>
              </p>
              <p>
                It can be challenging to find time to see a doctor, especially
                when you're not feeling well. That's why we created{" "}
                <b>{string.patientAppName}</b>, a user-friendly app that allows
                you to schedule appointments with qualified healthcare providers
                of <b>{string.websiteName}</b>, have virtual consultations, and
                get the medical attention you need, all from the convenience of
                your smartphone or computer.
              </p>
              <p>
                Whether you're looking for a primary care physician, a
                specialist, or mental health support, we've got you covered. Our
                goal is to make healthcare more accessible, affordable, and
                convenient for everyone, and we're excited to have you as a part
                of our community.
              </p>
              <p>
                <b>What are the benefits of {string.patientAppName} ?</b>
              </p>
              <ul
                className="ptext"
                style={{ marginLeft: "30px", fontSize: "15px" }}
              >
                <li>
                  You can access your health information from any device with an
                  internet connection. {string.patientAppName} allows you to
                  securely store, access, and share your health information from
                  anywhere.
                </li>
                <li>
                  Video-based telemedicine is a type of telemedicine that allows
                  patients and providers to communicate via video conference.
                </li>
                <li>
                  Improved access to healthcare, increased efficiency, cost
                  savings, and improved quality of care through Telemedicine
                </li>
                <li>
                  {string.patientAppName} takes data security very seriously and
                  has implemented several layers of protection including
                  encryption technology , two factor authentication , role based
                  access control , audit trails & logging capabilities etc.,to
                  ensure that sensitive patient data remains safe & secure at
                  all times
                </li>
                <li>
                  Select a suitable payment option (cash, credit/debit card,
                  Online Banking, UPI).
                </li>
                <li>
                  All data stored on the {string.patientAppName} platform is
                  encrypted using industry-standard security protocols so your
                  personal information remains private and secure at all times.
                </li>
              </ul>

              <p>
                Various services offered by <b>{string.patientAppName}</b> are
                as follows.
              </p>
              <ul
                className="ptext"
                style={{ marginLeft: "30px", fontSize: "15px" }}
              >
                <li>Appointment Scheduling and Rescheduling and Cancelling.</li>
                <li>Doctor Consultation (chat, Voice, and Video)</li>
                <li>View and download Prescriptions any time.</li>
                <li>View Fees payment Histories any time.</li>
                <li>View patient health records any time.</li>
              </ul>
              <p>
                Thank you for choosing <b>{string.patientAppName}</b>, and
                please don't hesitate to contact us if you have any questions or
                concerns. We're here to help you live a healthier, happier life.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutUs;
