import React from "react";

function RadioInput({ data, name, onchange, placeholder, ...otherProps }) {
  return (
    <>
      <div>
        {data.map((item) => {
          return (
            <>
              <label for={item.id} className="radio-wrapper">
                <div
                  className="border p-2"
                  style={{ minWidth: "100px", borderRadius: "6px" }}
                >
                  <input
                    type="radio"
                    id={item.id}
                    name={name}
                    className="radio-input"
                    value={item.id}
                    onChange={onchange}
                    placeholder={placeholder}
                    {...otherProps}
                  />
                  <span>{item.value}</span>
                </div>
              </label>
            </>
          );
        })}
      </div>
    </>
  );
}

export default RadioInput;
