import React, { useEffect, useContext, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Select from "../component/common/select";
import CustomTextInput from "../component/common/CustomTextInput";
import ShowMessage from "../component/common/ShowMessage";
import preFunction from "../component/common/CommonFunction";
import ErrorMessage from "../component/common/ErrorMessage";
import RadioInput from "../component/common/RadioInput";
import ErrorLog from "../component/common/ErrorLog";
import {
  stateArray,
  patientBloodGroupOption,
  genderOption,
  months,
  relationShip,
} from "../component/common/CommonArray";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import "react-datepicker/dist/react-datepicker.css";
import AuthContext from "../auth/context";
import storage from "../auth/storage";

const prefixerror = "NewPatient";

function NewPatient() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openRelationModel, setOpenRelationModel] = useState(false);
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [firstname, setFirstname] = useState("");
  const [relFirstname, setRelFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [relLastname, setRelLastname] = useState("");
  const [dob, setDOB] = useState("");
  const [reldob, setRelDOB] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [relgender, setRelGender] = useState("");
  const [bloodGroup, setBloodGroup] = useState("");
  const [relBloodGroup, setRelBloodGroup] = useState("");
  const [openModel, setOpenModal] = useState(false);
  const [patientId, setPatientId] = useState("");
  const [patientName, setPatientName] = useState("");
  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [relation, setRelation] = useState("");
  const [showPincode, setShowPincode] = useState(false);
  const [areaArray, setAreaArray] = useState([]);
  const [relationArr, setRelationArr] = useState([]);
  const [showFirstName, setShowFirstName] = useState(false);
  const [showRelFirstName, setShowRelFirstName] = useState(false);
  const [showLastName, setShowLastName] = useState(false);
  const [showRelLastName, setShowRelLastName] = useState(false);
  const [showMobile, setshowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showDOB, setshowDOB] = useState(false);
  const [showRelDOB, setshowRelDOB] = useState(false);
  const [showGender, setshowGender] = useState(false);
  const [showRelGender, setshowRelGender] = useState(false);
  const [showAddress, setshowAddress] = useState(false);
  const [showAreaError, setshowAreaError] = useState(false);
  const [relIsOpen, setRelIsOpen] = useState(false);
  const [showState, setShowState] = useState(false);
  const [showCity, setShowCity] = useState(false);
  // const [showOTP, setShowOTP] = useState(false);
  // const [otpCheck, setOtpCheck] = useState();
  // const [showOtpMsg, setShowOtpMsg] = useState(false);
  const [addPatientSuccess, setAddPatientSuccess] = useState(false);
  const [showRelationError, setShowRelationError] = useState(false);
  const [age, setAge] = useState("");
  const date = new Date();
  const years = preFunction.range(
    date.getFullYear() - 100,
    date.getFullYear(),
    1
  );
  const { patientAddressConfig, patientEmailConfig } = useContext(AuthContext);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const handleDOBAge = (date) => {
    const today = new Date();
    const ageInMilliseconds = today - date;
    const ageDate = new Date(ageInMilliseconds);
    const years = Math.abs(ageDate.getUTCFullYear() - 1970);
    console.log("years", years);
    setAge(years);
  };

  const handleDOB = (age) => {
    console.log("age", age);
    const currentYear = new Date().getFullYear();
    console.log("currentYear", currentYear);
    const birthYear = currentYear - age;
    const calculatedBirthdate = new Date(birthYear, 0, 1);
    console.log("calculatedBirthdate", calculatedBirthdate);
    setDOB(new Date(calculatedBirthdate));
  };

  const getPincodeInfo = async (code) => {
    if (code.length !== 6) {
      setShowPincode(true);
      return;
    }
    try {
      const pincodeRes = await doctorRegApi.getPincodeDetail(code);
      if (!pincodeRes.ok) {
        ErrorLog(
          prefixerror + " getPincodeInfo " + "getPincodeDetail",
          JSON.stringify(pincodeRes),
          "We are facing some issues.Please try again after sometime.",
          "code:" + code
        );
        return;
      }
      console.log("pincodeRes--", pincodeRes.data.message.results);
      if (pincodeRes.data.message.results.length == 0) {
        setShowPincode(true);
        return false;
      }
      setShowPincode(false);
      pincodeRes.data.message.results[0].address_components.forEach(
        (component) => {
          if (component.types.includes("locality")) {
            setCity(component.long_name);
          }
          if (component.types.includes("administrative_area_level_1")) {
            setState(component.long_name);
          }
        }
      );
      console.log(pincodeRes.data.message.results[0].postcode_localities);
      let i;
      let area = [];
      for (
        i = 0;
        i < pincodeRes.data.message.results[0].postcode_localities.length;
        i++
      ) {
        var myObject = {
          id: i,
          name: pincodeRes.data.message.results[0].postcode_localities[i],
        };
        area.push(myObject);
      }
      console.log("area---", area);
      setAreaArray(area);
      return true;
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + " getPincodeInfo ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleViewPatient = (id) => {
    navigate("/viewpatient", { state: { id } });
  };

  const handleBookAppointment = () => {
    setOpenModal(false);
    navigate("/newappointment", {
      state: { patientId, fullName: patientName },
    });
  };

  const handleNewPatient = () => {
    setOpenModal(false);
    navigate("/newpatient");
    window.location.reload();
  };

  const getPatient = async () => {
    try {
      if (location.state && location.state.id !== "") {
        setLoad(true);
        const searchString =
          '&filters=[["name","=","' + location.state.id + '"]]';
        const res1 = await doctorApi.getPatientById(location.state.id);
        if (!res1.ok) {
          ErrorLog(
            prefixerror + " getPatient " + "getPatientById",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "id:" + location.state.id
          );
          return;
        }
        console.log("res1---", res1);
        const addressRes = await doctorApi.getPatientAddress(location.state.id);
        if (!addressRes.ok) {
          ErrorLog(
            prefixerror + " getPatient " + "getPatientAddress",
            JSON.stringify(addressRes),
            "We are facing some issues.Please try again after sometime.",
            "id:" + location.state.id
          );
          return;
        }
        if (addressRes.data.data.length > 0) {
          setAddressLine1(addressRes.data.data[0].address_line1);
          setAddressLine2(addressRes.data.data[0].address_line2);
          setCity(addressRes.data.data[0].city);
          setState(addressRes.data.data[0].state);
          setPincode(addressRes.data.data[0].pincode);
          let area = [];
          var myObject = {};
          myObject["id"] = 1;
          myObject["name"] = addressRes.data.data[0].address_line2;
          area.push(myObject);
          setAreaArray(area);
        }
        document.getElementById(res1.data.data.sex).checked = true;
        setFirstname(res1.data.data.first_name);
        setLastname(res1.data.data.last_name ? res1.data.data.last_name : "");
        setDOB(new Date(res1.data.data.dob));
        setEmail(res1.data.data.email ? res1.data.data.email : "");
        setMobile(res1.data.data.mobile);
        setBloodGroup(res1.data.data.blood_group);
        setGender(res1.data.data.sex);
        let relationArr = [];
        if (res1.data.data.patient_relation.length > 0) {
          let i;
          console.log("rel---", res1.data.data.patient_relation);
          for (i = 0; i < res1.data.data.patient_relation.length; i++) {
            var myObject = {};
            myObject["patient"] = res1.data.data.patient_relation[i].patient;
            console.log("rell---", res1.data.data.patient_relation[i].relation);
            myObject["relation"] = res1.data.data.patient_relation[i].relation;
            const respatientRelation = await doctorApi.getPatientById(
              res1.data.data.patient_relation[i].patient
            );
            if (!respatientRelation.ok) {
              ErrorLog(
                prefixerror + " getPatient " + "getPatientById",
                JSON.stringify(respatientRelation),
                "We are facing some issues.Please try again after sometime.",
                "patient:" + res1.data.data.patient_relation[i].patient
              );
              return;
            }
            console.log("respatientRelation---", respatientRelation);
            myObject["name"] = respatientRelation.data.data.patient_name;
            relationArr.push(myObject);
          }

          setRelationArr(relationArr);
        }
        console.log("relationArr---", relationArr);
      }
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const closeErrors = () => {
    setShowPincode(false);
    setShowFirstName(false);
    setShowLastName(false);
    setshowMobile(false);
    if (patientEmailConfig) {
      setShowEmail(false);
    }
    setshowDOB(false);
    setshowGender(false);
    if (patientAddressConfig) {
      setshowAddress(false);
      setshowAreaError(false);
      setShowCity(false);
      setShowState(false);
    }
    document.getElementById("firstname").className = "form-control";
    document.getElementById("lastname").className = "form-control";
    document.getElementById("mobile").className = "form-control";
    document.getElementById("dobButton").className = "form-control datepicker";
    if (patientEmailConfig) {
      document.getElementById("email").className = "form-control";
    }
    if (patientAddressConfig) {
      document.getElementById("addressLine1").className = "form-control";
      document.getElementById("addressLine2").className =
        "form-control select-control";
      document.getElementById("pincode").className = "form-control";
      document.getElementById("city").className = "form-control";
      document.getElementById("state").className =
        "form-control select-control";
    }
  };

  const closeModalErrors = () => {
    setShowRelFirstName(false);
    setShowRelLastName(false);
    setshowRelGender(false);
    setshowRelDOB(false);
    setShowRelationError(false);
    document.getElementById("relfirstname").className = "form-control";
    document.getElementById("rellastname").className = "form-control";
    document.getElementById("reldobButton").className =
      "form-control datepicker";
    return;
  };

  // const handleOTP = async () => {
  //   try {
  //     console.log("mobile--", mobile);
  //     const res = await doctorHomePageApi.mobileOtpPatient(mobile);
  //     console.log("otpres---", res);
  //     setShowOTP(true);
  //     setOTP(res.data.message.otp);
  //     console.log("otp--", res.data.message.otp);
  //   } catch (error) {
  //     console.log("Error", error);
  //     setLoad(false);
  //   }
  // };

  const createPatient = async (addrelation) => {
    try {
      // console.log(otpCheck, otp);
      const company = await storage.getCompany();
      setLoad(true);
      let relationArr = [];
      const res = await doctorApi.newPatient(
        company,
        firstname,
        lastname,
        moment(dob).format("yyyy-MM-DD"),
        gender,
        email,
        mobile,
        bloodGroup,
        relationArr
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + " createPatient " + "newPatient",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "firstname:" +
            firstname +
            "lastname:" +
            lastname +
            "dob:" +
            moment(dob).format("yyyy-MM-DD") +
            "gender:" +
            gender +
            "email:" +
            email +
            "mobile:" +
            mobile +
            "bloodGroup:" +
            bloodGroup +
            "mobrelationArrile:" +
            relationArr
        );
        return;
      }
      console.log("res---", res);

      const Res = await doctorApi.newCustomer(res.data.data.name);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + " createPatient " + "newCustomer",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "name:" + res.data.data.name
        );
        setOpen(true);
        setLoad(false);
        if (res.data.message) {
          setMessage(res.data.message);
        } else {
          setMessage(
            JSON.parse(JSON.parse(res.data._server_messages)[0]).message
          );
        }
        return;
      }
      console.log("Res---", Res.data.data.name);

      if (addressLine1 != "") {
        const addressRes = await doctorApi.addPatientAddress(
          res.data.data.name,
          email,
          mobile,
          addressLine1,
          addressLine2,
          city,
          state,
          pincode
        );
        if (!addressRes.ok) {
          ErrorLog(
            prefixerror + " createPatient " + "addPatientAddress",
            JSON.stringify(addressRes),
            "We are facing some issues.Please try again after sometime.",
            "name:" +
              res.data.data.name +
              "email:" +
              email +
              "mobile:" +
              mobile +
              "addressLine1:" +
              addressLine1 +
              "addressLine2:" +
              addressLine2 +
              "city:" +
              city +
              "state:" +
              state +
              "pincode:" +
              pincode
          );
          return;
        }
      }
      setPatientId(res.data.data.name);
      setPatientName(res.data.data.patient_name);
      if (addrelation) {
        setLoad(false);
        setAddPatientSuccess(true);
        setTimeout(() => {
          setAddPatientSuccess(false);
        }, 3000);
        setOpenRelationModel(true);

        return;
      }
      setMessage("New Patient added Succesfully");
      setOpenModal(true);
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " createPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  // const handleOTPSubmit = async (addrelation) => {
  //   try {
  //     console.log(otpCheck, otp);
  //     if (!otpCheck) {
  //       setShowOtpMsg(true);
  //       return;
  //     } else {
  //       setShowOtpMsg(false);
  //     }
  //     setLoad(true);
  //     if (otpCheck == otp) {
  //       console.log(
  //         firstname,
  //         lastname,
  //         moment(dob).format("yyyy-MM-DD"),
  //         gender,
  //         email,
  //         mobile,
  //         bloodGroup
  //       );

  //       createPatient(addrelation);
  //       setLoad(false);
  //     } else {
  //       setShowOtpMsg(true);
  //     }
  //   } catch (error) {
  //     console.log("Error", error);
  //   }
  // };

  const validate = () => {
    let err = false;
    if (patientAddressConfig) {
      if (state == "") {
        setShowState(true);
        document.getElementById("state").className += " form-control-error";
        document.getElementById("state").focus();
        err = true;
      } else {
        setShowState(false);
      }
      if (city == "") {
        setShowCity(true);
        document.getElementById("city").className += " form-control-error";
        document.getElementById("city").focus();
        err = true;
      } else {
        setShowCity(false);
      }
      if (addressLine2 == "") {
        setshowAreaError(true);
        document.getElementById("addressLine2").className +=
          " form-control-error";
        document.getElementById("addressLine2").focus();
        err = true;
      } else {
        setshowAreaError(false);
      }
      if (
        pincode == "" ||
        pincode.length != 6 ||
        isNaN(pincode) ||
        areaArray.length == 0
      ) {
        setShowPincode(true);
        document.getElementById("pincode").className += " form-control-error";
        document.getElementById("pincode").focus();
        err = true;
      } else {
        setShowPincode(false);
      }
      if (addressLine1 == "") {
        setshowAddress(true);
        document.getElementById("addressLine1").className +=
          " form-control-error";
        document.getElementById("addressLine1").focus();
        err = true;
      } else {
        setshowAddress(false);
      }
    }
    if (patientEmailConfig) {
      console.log("email----", email);
      if (email == "" || (email != "" && !email.match(/.+@.+/))) {
        setShowEmail(true);
        document.getElementById("email").className += " form-control-error";
        document.getElementById("email").focus();
        err = true;
      } else {
        setShowEmail(false);
      }
    }
    if (gender == "") {
      setshowGender(true);
      err = true;
    } else {
      setshowGender(false);
    }
    const dt = !dob ? "" : moment(dob).format("yyyy-MM-DD");
    if (
      dt == "" ||
      dt > preFunction.disableFutureDate() ||
      dt < preFunction.disablePastDateBy100()
    ) {
      setshowDOB(true);
      document.getElementById("dobButton").className += " form-control-error";
      document.getElementById("dobButton").focus();
      err = true;
    } else {
      setshowDOB(false);
    }
    if (mobile == "" || mobile.length != 10 || isNaN(mobile)) {
      setshowMobile(true);
      document.getElementById("mobile").className += " form-control-error";
      document.getElementById("mobile").focus();
      err = true;
    } else {
      setshowMobile(false);
    }
    // if (lastname == "" || lastname.length > 100) {
    //   setShowLastName(true);
    //   document.getElementById("lastname").className += " form-control-error";
    //   document.getElementById("lastname").focus();
    //   err = true;
    // } else {
    //   setShowLastName(false);
    // }
    if (firstname == "" || firstname.length < 3 || firstname.length > 100) {
      setShowFirstName(true);
      document.getElementById("firstname").className += " form-control-error";
      document.getElementById("firstname").focus();
      err = true;
    } else {
      setShowFirstName(false);
    }
    return err;
  };

  const handleRelationSubmit = async () => {
    let err = false;
    if (relation == "") {
      setShowRelationError(true);
      document.getElementById("relation").className += " form-control-error";
      err = true;
    }
    if (relgender == "") {
      setshowRelGender(true);
      err = true;
    } else {
      setshowRelGender(false);
    }
    const dt = !reldob ? "" : moment(reldob).format("yyyy-MM-DD");
    if (
      dt == "" ||
      dt > preFunction.disableFutureDate() ||
      dt < preFunction.disablePastDateBy100()
    ) {
      setshowRelDOB(true);
      document.getElementById("reldobButton").className +=
        " form-control-error";
      document.getElementById("reldobButton").focus();
      err = true;
    } else {
      setshowRelDOB(false);
    }
    if (relLastname == "" || relLastname.length > 100) {
      setShowRelLastName(true);
      document.getElementById("rellastname").className += " form-control-error";
      document.getElementById("rellastname").focus();
      err = true;
    } else {
      setShowRelLastName(false);
    }
    if (
      relFirstname == "" ||
      relFirstname.length < 3 ||
      relFirstname.length > 100
    ) {
      setShowRelFirstName(true);
      document.getElementById("relfirstname").className +=
        " form-control-error";
      document.getElementById("relfirstname").focus();
      err = true;
    } else {
      setShowRelFirstName(false);
    }
    if (err) {
      return;
    }

    try {
      setLoad(true);
      console.log("patientId---", patientId);
      const relationArr = [];
      const company = await storage.getCompany();
      const res = await doctorApi.newPatient(
        company,
        relFirstname,
        relLastname,
        moment(reldob).format("yyyy-MM-DD"),
        relgender,
        email,
        mobile,
        relBloodGroup,
        relationArr
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + " handleRelationSubmit " + "newPatient",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "relFirstname:" +
            relFirstname +
            "relLastname:" +
            relLastname +
            "reldob:" +
            moment(reldob).format("yyyy-MM-DD") +
            "relgender:" +
            relgender +
            "email:" +
            email +
            "mobile:" +
            mobile +
            "relBloodGroup:" +
            relBloodGroup +
            "relationArr:" +
            relationArr
        );

        setOpen(true);
        setLoad(false);
        if (res.data.message) {
          setMessage(res.data.message);
        } else {
          setMessage(
            JSON.parse(JSON.parse(res.data._server_messages)[0]).message
          );
        }
        return;
      }
      console.log("res---", res);

      const customerRes = await doctorApi.newCustomer(res.data.data.name);
      if (!customerRes.ok) {
        ErrorLog(
          prefixerror + " handleRelationSubmit " + "newCustomer",
          JSON.stringify(customerRes),
          "We are facing some issues.Please try again after sometime.",
          "name:" + res.data.data.name
        );
        return;
      }
      console.log("customerRes---", customerRes);

      let relationArr1 = [];
      const res1 = await doctorApi.getPatientById(patientId);
      if (!res1.ok) {
        ErrorLog(
          prefixerror + " handleRelationSubmit " + "getPatientById",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime.",
          "patientId:" + patientId
        );
        return;
      }
      console.log("res1---", res1);
      if (res1.data.data.patient_relation.length > 0) {
        res1.data.data.patient_relation.map((val) => {
          console.log("val---", val);
          var myObject = {};
          myObject["patient"] = val.patient;
          myObject["relation"] = val.relation;
          relationArr1.push(myObject);
        });
      }

      var myObject = {};
      myObject["patient"] = res.data.data.name;
      myObject["relation"] = relation;
      relationArr1.push(myObject);
      console.log("relationArr---", relationArr1);
      const resupdate = await doctorApi.updatePatientRelation(
        patientId,
        relationArr1
      );
      console.log("resupdate------", resupdate);
      if (!resupdate.ok) {
        ErrorLog(
          prefixerror + " handleRelationSubmit " + "updatePatientRelation",
          JSON.stringify(resupdate),
          "We are facing some issues.Please try again after sometime.",
          "patientId:" + patientId + "relationArr:" + relationArr1
        );
        return;
      }
      if (addressLine1 != "") {
        const addressRes = await doctorApi.addPatientAddress(
          res.data.data.name,
          email,
          mobile,
          addressLine1,
          addressLine2,
          city,
          state,
          pincode
        );
        if (!addressRes.ok) {
          ErrorLog(
            prefixerror + " handleRelationSubmit " + "addPatientAddress",
            JSON.stringify(addressRes),
            "We are facing some issues.Please try again after sometime.",
            "name:" +
              res.data.data.name +
              "email:" +
              email +
              "mobile:" +
              mobile +
              "addressLine1:" +
              addressLine1 +
              "addressLine2:" +
              addressLine2 +
              "city:" +
              city +
              "state:" +
              state +
              "pincode:" +
              pincode
          );
          return;
        }
      }
      setOpenRelationModel(false);

      navigate("/newpatient", { state: { id: patientId } });
      window.location.reload();
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleRelationSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
    return;
  };

  const handleSubmit = async (addrelation) => {
    try {
      //#region validation
      let err = validate();
      if (err) {
        return;
      }
      //#endregion
      setLoad(true);
      const dt = !dob ? "" : moment(dob).format("yyyy-MM-DD");
      if (location.state && location.state.id !== "") {
        const res = await doctorApi.updatePatient(
          location.state.id,
          firstname,
          lastname,
          dt,
          gender,
          email,
          mobile,
          bloodGroup
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "updatePatient",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "id:" +
              location.state.id +
              "firstname:" +
              firstname +
              "lastname:" +
              lastname +
              "dt:" +
              dt +
              "gender:" +
              gender +
              "email:" +
              email +
              "mobile:" +
              mobile +
              "bloodGroup:" +
              bloodGroup
          );
          return;
        }
        console.log("updateres---", res);
        if (addressLine1 != "") {
          const addressRes = await doctorApi.getPatientAddress(
            location.state.id
          );
          if (!addressRes.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "getPatientAddress",
              JSON.stringify(addressRes),
              "We are facing some issues.Please try again after sometime.",
              "id:" + location.state.id
            );
            return;
          }
          if (addressRes.data.data.length > 0) {
            const addressRes = await doctorApi.updatePatientAddress(
              location.state.id,
              email,
              mobile,
              addressLine1,
              addressLine2,
              city,
              state,
              pincode
            );
            if (!addressRes.ok) {
              ErrorLog(
                prefixerror + " handleSubmit " + "updatePatientAddress",
                JSON.stringify(addressRes),
                "We are facing some issues.Please try again after sometime.",
                "id:" +
                  location.state.id +
                  "email:" +
                  email +
                  "mobile:" +
                  mobile +
                  "addressLine1:" +
                  addressLine1 +
                  "addressLine2:" +
                  addressLine2 +
                  "city:" +
                  city +
                  "state:" +
                  state +
                  "pincode:" +
                  pincode
              );
              return;
            }
          } else {
            const addressRes = await doctorApi.addPatientAddress(
              res.data.data.name,
              email,
              mobile,
              addressLine1,
              addressLine2,
              city,
              state,
              pincode
            );
            if (!addressRes.ok) {
              ErrorLog(
                prefixerror + " handleSubmit " + "addPatientAddress",
                JSON.stringify(addressRes),
                "We are facing some issues.Please try again after sometime.",
                "name:" +
                  res.data.data.name +
                  "email:" +
                  email +
                  "mobile:" +
                  mobile +
                  "addressLine1:" +
                  addressLine1 +
                  "addressLine2:" +
                  addressLine2 +
                  "city:" +
                  city +
                  "state:" +
                  state +
                  "pincode:" +
                  pincode
              );
              return;
            }
          }
        }
        setPatientId(res.data.data.name);
        setPatientName(res.data.data.patient_name);
        setLoad(false);
        console.log("addrelation----", addrelation);
        if (addrelation) {
          setOpenRelationModel(true);
          return;
        }
        setMessage("Patient details updated successfully");
        setOpenModal(true);
        return;
      }
      const res1 = await doctorApi.checkOldPatient(
        firstname + " " + lastname,
        mobile
      );
      if (!res1.ok) {
        ErrorLog(
          prefixerror + " handleSubmit " + "checkOldPatient",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime.",
          "name:" + firstname + " " + lastname + "mobile:" + mobile
        );
        return;
      }
      if (res1.data.data.length > 0) {
        setOpen(true);
        setLoad(false);
        setMessage("Already Registered Patient");
        return;
      }
      setLoad(false);
      // console.log("otpPatientConfig---", otpPatientConfig);
      // if (otpPatientConfig) {
      //   handleOTP();
      // } else {
      createPatient(addrelation);
      // }
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getPatient();
    document.getElementById("firstname") &&
      document.getElementById("firstname").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row mb-4" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row">
                  <h1>Patient Registration</h1>
                </div>
                <div className="row" id="">
                  <div
                    className="col-lg-8 border p-3"
                    style={{ borderRadius: "6px" }}
                  >
                    <div className="">
                      <ShowMessage view={open} Message={message} />
                      <div id="formId" className="showDiv">
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            <span className="star">*</span> First Name :
                          </label>
                          <div className="col-sm-7">
                            <CustomTextInput
                              type="text"
                              name="firstname"
                              id="firstname"
                              maxLength={40}
                              className="form-control"
                              value={firstname}
                              onChange={(e) => {
                                closeErrors();
                                setFirstname(
                                  preFunction.capitalizeFirst(e.target.value)
                                );
                              }}
                              placeholder="First Name"
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please enter the First Name"}
                              visible={showFirstName}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            Last Name :
                          </label>
                          <div className="col-sm-7">
                            <CustomTextInput
                              type="text"
                              name="lastname"
                              id="lastname"
                              maxLength={40}
                              value={lastname}
                              onChange={(e) => {
                                closeErrors();
                                setLastname(e.target.value);
                              }}
                              className="form-control"
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                              placeholder="Last Name"
                              onClick={() => setOpen(false)}
                            />
                            <ErrorMessage
                              error={"Please enter the Last Name"}
                              visible={showLastName}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            <span className="star">*</span> Mobile :
                          </label>
                          <div className="col-sm-4">
                            <CustomTextInput
                              type="tel"
                              name="mobile"
                              id="mobile"
                              maxlength="10"
                              value={mobile}
                              onChange={(e) => {
                                closeErrors();
                                setMobile(e.target.value);
                              }}
                              className="form-control"
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                              placeholder="Mobile"
                              onClick={() => setOpen(false)}
                            />
                            <ErrorMessage
                              error={"Please enter the valid Mobile number"}
                              visible={showMobile}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            <span className="star">*</span> Date of Birth :
                          </label>
                          <div className="col-sm-7">
                            <DatePicker
                              name="dob"
                              id="dob"
                              selected={dob}
                              maxDate={new Date()}
                              onChange={(date) => {
                                setDOB(date);
                                closeErrors();
                                handleDOBAge(date);
                              }}
                              dateFormat="dd-MM-yyyy"
                              customInput={<DateCustomInput />}
                              className="form-control datepicker"
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <select
                                    style={{ marginRight: "3px" }}
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                            />
                            <ErrorMessage
                              error={"Please select the Date of Birth"}
                              visible={showDOB}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <label className="control-label col-sm-3"></label>
                          <div className="col-sm-7">-or-</div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            <span className="star">*</span> Age :
                          </label>
                          <div className="col-sm-1">
                            <CustomTextInput
                              type="number"
                              name="age"
                              id="age"
                              maxlength="3"
                              value={age}
                              onChange={(e) => {
                                e.target.value =
                                  e.target.value < 0
                                    ? 0
                                    : e.target.value > 150
                                    ? 150
                                    : e.target.value;
                                setAge(e.target.value);
                                handleDOB(e.target.value);
                              }}
                              className="form-control"
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                              placeholder="Age"
                              onClick={() => setOpen(false)}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters mb-2">
                          <label className="control-label col-sm-3">
                            <span className="star">*</span> Gender :
                          </label>
                          <div className="col-sm-9">
                            <RadioInput
                              name="gender"
                              data={genderOption}
                              className="radio-input"
                              onChange={(e) => {
                                closeErrors();
                                setGender(e.target.value);
                              }}
                            />
                            <ErrorMessage
                              style={{
                                marginBottom: "5px",
                                marginTop: "-5px",
                              }}
                              error={"Please select the Gender"}
                              visible={showGender}
                            />
                          </div>
                        </div>
                        {patientEmailConfig ? (
                          <div className="row no-gutters form-group">
                            <label className="control-label col-sm-3">
                              <span className="star">*</span> Email:
                            </label>
                            <div className="col-sm-7">
                              <CustomTextInput
                                type="email"
                                name="email"
                                id="email"
                                maxLength={80}
                                className="form-control"
                                value={email}
                                onChange={(e) => {
                                  closeErrors();
                                  setEmail(e.target.value);
                                }}
                                onKeyUp={(e) =>
                                  e.keyCode == 13 && handleSubmit()
                                }
                                placeholder="Email "
                                onClick={() => setOpen(false)}
                              />
                              <ErrorMessage
                                error={"Please enter the valid Email"}
                                visible={showEmail}
                              />
                            </div>
                          </div>
                        ) : null}
                        {patientAddressConfig ? (
                          <>
                            <div className="row no-gutters form-group">
                              <label className="control-label col-sm-3">
                                <span className="star">*</span> Address :
                              </label>
                              <div className="col-sm-7">
                                <CustomTextInput
                                  type="text"
                                  name="addressLine1"
                                  id="addressLine1"
                                  maxLength={120}
                                  value={addressLine1}
                                  onKeyUp={(e) =>
                                    e.keyCode == 13 && handleSubmit()
                                  }
                                  onChange={(e) => {
                                    closeErrors();
                                    setAddressLine1(e.target.value);
                                  }}
                                  className="form-control"
                                  placeholder="Address"
                                  onClick={() => setOpen(false)}
                                />
                                <ErrorMessage
                                  error={"Please enter the Address"}
                                  visible={showAddress}
                                />
                              </div>
                            </div>
                            <div className="row no-gutters form-group">
                              <label className="control-label col-sm-3">
                                <span className="star">*</span> Pincode :
                              </label>
                              <div className="col-sm-7">
                                <div className="col-sm-3">
                                  <CustomTextInput
                                    type="number"
                                    name="pincode"
                                    id="pincode"
                                    onKeyUp={(e) =>
                                      e.keyCode == 13 && handleSubmit()
                                    }
                                    maxlength="6"
                                    className="form-control"
                                    placeholder="Pincode"
                                    value={pincode}
                                    onChange={(e) => {
                                      closeErrors();
                                      e.target.value =
                                        e.target.value < 0
                                          ? 0
                                          : e.target.value.length > 6
                                          ? e.target.value.slice(0, 6)
                                          : e.target.value;
                                      setPincode(e.target.value);
                                      getPincodeInfo(e.target.value);
                                    }}
                                    onClick={() => setOpen(false)}
                                  />
                                </div>
                                <ErrorMessage
                                  error={"Please enter the valid Pincode"}
                                  visible={showPincode}
                                />
                              </div>
                            </div>
                            <div className="row no-gutters form-group">
                              <label className="control-label col-sm-3">
                                <span className="star">*</span> Area :
                              </label>
                              <div className="col-sm-7">
                                <Select
                                  name="addressLine2"
                                  id="addressLine2"
                                  value={addressLine2}
                                  className={
                                    addressLine2
                                      ? "form-control select-control "
                                      : "form-control select-control opacity"
                                  }
                                  placeholder="Area"
                                  onChange={(item) => {
                                    closeErrors();
                                    setAddressLine2(item.target.value);
                                  }}
                                  options={areaArray}
                                />
                                <ErrorMessage
                                  error={"Please select the Area"}
                                  visible={showAreaError}
                                />
                              </div>
                            </div>
                            <div className="row no-gutters form-group">
                              <label className="control-label col-sm-3">
                                <span className="star">*</span> City :
                              </label>
                              <div className="col-sm-7">
                                <CustomTextInput
                                  type="text"
                                  name="city"
                                  id="city"
                                  maxlength="140"
                                  value={city}
                                  onChange={(e) => {
                                    closeErrors();
                                    setCity(e.target.value);
                                  }}
                                  className="form-control"
                                  placeholder="City"
                                  onClick={() => setOpen(false)}
                                />
                                <ErrorMessage
                                  error={"Please enter the City"}
                                  visible={showCity}
                                />
                              </div>
                            </div>
                            <div className="row no-gutters form-group">
                              <label className="control-label col-sm-3">
                                <span className="star">*</span> State :
                              </label>
                              <div className="col-sm-7">
                                <Select
                                  id="state"
                                  name="state"
                                  className={
                                    state
                                      ? "form-control select-control "
                                      : "form-control select-control opacity"
                                  }
                                  placeholder="State"
                                  value={state}
                                  options={stateArray}
                                  onChange={(item) => {
                                    closeErrors();
                                    setState(item.target.value);
                                  }}
                                />
                                <ErrorMessage
                                  error={"Please select the State"}
                                  visible={showState}
                                />
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="row no-gutters form-group">
                          <label className="control-label col-sm-3">
                            Blood Group :
                          </label>
                          <div className="col-sm-3">
                            <Select
                              type="text"
                              name="Blood Group"
                              id="BloodGroup"
                              options={patientBloodGroupOption}
                              value={bloodGroup}
                              placeholder="Blood Group"
                              className={
                                bloodGroup
                                  ? "form-control select-control"
                                  : "form-control select-control opacity"
                              }
                              onChange={(e) => {
                                closeErrors();
                                e.target.value == "Select"
                                  ? setBloodGroup("")
                                  : setBloodGroup(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        {relationArr.length > 0 && (
                          <>
                            <div className="row no-gutters mb-3">
                              <label className="control-label col-sm-3">
                                Relations :
                              </label>
                              <div className="col-sm-7">
                                <table className="table ml-2">
                                  <thead className="tableHead">
                                    <tr>
                                      <th width="1%">No</th>
                                      <th width="40%">Name</th>
                                      <th>Relationship</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {relationArr.map((item, index) => (
                                      <tr key={item.name}>
                                        <td>{index + 1}</td>
                                        <td>
                                          <a
                                            className="pointer"
                                            onClick={() =>
                                              handleViewPatient(item.patient)
                                            }
                                          >
                                            {item.name}
                                          </a>
                                        </td>
                                        <td>{item.relation}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </>
                        )}
                        <div className="row no-gutters mb-4">
                          <span className="col-sm-3"></span>
                          <div className="col-sm-9">
                            <button
                              type="submit"
                              onClick={(e) => {
                                handleSubmit(true);
                              }}
                              className="btn-2 ms-3 mt-2"
                              title="Add Family Member"
                            >
                              <span className="icofont icofont-plus"></span>
                              Add Family Member
                            </button>
                            <button
                              type="submit"
                              onClick={(e) => handleSubmit()}
                              className="btn ms-3 mt-2"
                              title="Save"
                            >
                              <span className="icofont-save"></span>
                              Save
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-7"></div>
                </div>
              </div>
              <>
                <Modal className="modalwidth" show={openModel}>
                  <Modal.Header>
                    <Modal.Title>{patientName}</Modal.Title>
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        navigate("/patientlist");
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body>
                    <div id="modelElement">{message}</div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn"
                      title="Book Appointment"
                      onClick={handleBookAppointment}
                    >
                      <span className="icofont icofont-plus"></span>
                      Book Appointment
                    </button>
                    <button
                      className="btn-2"
                      title="Add New Patient"
                      onClick={handleNewPatient}
                    >
                      <span className="icofont icofont-plus"></span>
                      Add Patient
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
              <>
                <Modal show={openRelationModel}>
                  <Modal.Header>
                    <Modal.Title>{patientName}</Modal.Title>
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        window.location.reload();
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body>
                    <div style={{ width: "100%" }}>
                      <ShowMessage
                        view={addPatientSuccess}
                        Message={patientName + " added successfully"}
                      />
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> First Name :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="text"
                            name="relfirstname"
                            id="relfirstname"
                            maxlength="140"
                            className="form-control"
                            value={relFirstname}
                            onChange={(e) => {
                              closeModalErrors();
                              console.log("here");
                              setRelFirstname(e.target.value);
                            }}
                            placeholder="First Name"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                          <ErrorMessage
                            error={"Please enter the First Name"}
                            visible={showRelFirstName}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Last Name :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="text"
                            name="rellastname"
                            id="rellastname"
                            value={relLastname}
                            maxlength="140"
                            onChange={(e) => {
                              closeModalErrors();
                              setRelLastname(e.target.value);
                            }}
                            className="form-control"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            placeholder="Last Name"
                            onClick={() => setOpen(false)}
                          />
                          <ErrorMessage
                            error={"Please enter the Last Name"}
                            visible={showRelLastName}
                          />
                        </div>
                      </div>

                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Date of Birth :
                        </label>
                        <div className="col-sm-7">
                          <button
                            className="form-control datepicker"
                            id="reldobButton"
                            onClick={(e) => {
                              e.preventDefault();
                              setRelIsOpen(!relIsOpen);
                            }}
                          >
                            {!reldob
                              ? "DD-MM-YYYY"
                              : moment(reldob).format("DD-MM-yyyy")}
                            <span className="col-1 icofont-calendar ps-3 calSize"></span>
                          </button>
                          {relIsOpen && (
                            <DatePicker
                              dateFormat="dd-MM-yyyy"
                              inline
                              name="reldob"
                              id="reldob"
                              minDate={
                                new Date(date.getFullYear() - 100 + "-01-01")
                              }
                              maxDate={new Date()}
                              closeOnScroll={() => setRelIsOpen(false)}
                              className="form-control datepicker"
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <select
                                    style={{ marginRight: "3px" }}
                                    value={date.getFullYear()}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {years.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                  <select
                                    value={months[date.getMonth()]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              selected={reldob}
                              onChange={(date1) => {
                                setshowRelDOB(false);
                                setRelDOB(date1);
                                setRelIsOpen(false);
                                closeModalErrors();
                              }}
                              onBlur={() => setRelIsOpen(false)}
                            />
                          )}
                          <ErrorMessage
                            error={"Please select the Date of Birth"}
                            visible={showRelDOB}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters mb-3">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Gender :
                        </label>
                        <div className="col-sm-9">
                          <RadioInput
                            name="relgender"
                            id="relgender"
                            data={genderOption}
                            className="radio-input"
                            onChange={(e) => {
                              closeModalErrors();
                              setRelGender(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            error={"Please select the Gender"}
                            visible={showRelGender}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          Blood Group :
                        </label>
                        <div className="col-sm-4">
                          <Select
                            type="text"
                            name="Blood Group"
                            id="BloodGroup"
                            options={patientBloodGroupOption}
                            value={relBloodGroup}
                            placeholder="Blood Group"
                            className={
                              relBloodGroup
                                ? "form-control select-control"
                                : "form-control select-control opacity"
                            }
                            onChange={(e) => {
                              closeModalErrors();
                              setRelBloodGroup(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Relation :
                        </label>
                        <div className="col-sm-4">
                          <Select
                            type="text"
                            name="relation"
                            id="relation"
                            options={relationShip}
                            value={relation}
                            placeholder="Relationship"
                            className={
                              relation
                                ? "form-control select-control"
                                : "form-control select-control opacity"
                            }
                            onChange={(e) => {
                              closeModalErrors();
                              setRelation(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            error={"Please select the Relationship"}
                            visible={showRelationError}
                          />
                        </div>
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="submit"
                      onClick={handleRelationSubmit}
                      className="btn"
                      title="Save"
                    >
                      <span className="icofont-save"></span>Save
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewPatient;
