import React from "react";
import { useNavigate } from "react-router-dom";

import preFunction from "../component/common/CommonFunction";

import string from "../string";
import PageHeader from "./pageHeader";

const TermsandCondions = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="outerpadding">
              <h4>TERMS & CONDITIONS</h4>
              <p>
                The following are the terms and conditions of using{" "}
                <b>{string.websiteName}</b> ("The Site"),{" "}
                <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App") and
                constitute a legally binding agreement, between you ("the
                Customer" or the "User") and SMVHPL
              </p>
              <p>
                In terms of the Information Technology Act, 2000, this document
                is an electronic record. Being generated by a computer system it
                does not require any physical or digital signature.
              </p>
              <p>
                This document is published in accordance with the provisions of
                Rule 3 (1) of the Information Technology Rules, 2011 that
                require publishing the rules and regulations, Privacy Policy,
                and Terms of Use for access or usage of{" "}
                <b>{string.websiteName}</b> ("The Site"),{" "}
                <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App")
              </p>
              <p>
                <b>USE OF CONTENT</b>
              </p>
              <p>
                All logos, brands, marks headings, names, signatures, or any
                combinations thereof, appearing in this site or app, except as
                otherwise noted, are properties either owned or used under
                license, by the business and/or its associate entities who
                feature on this Website and app. The use of these properties or
                any other content on this site and app, except as provided in
                these terms and conditions or the site content, is strictly
                prohibited.
              </p>
              <p>
                <b>ACCEPTABLE WEBSITE USE</b>
              </p>
              <p>
                <b>(A) Security Rules</b>
              </p>
              <p>
                Visitors are prohibited from violating or attempting to violate
                the security of the Website or app, including, without
                limitation, (1) accessing data not intended for such user or
                logging into a server or account which the user is not
                authorized to access, (2) attempting to probe, scan or test the
                vulnerability of a system or network or to breach security or
                authentication measures without proper authorization, (3)
                attempting to interfere with service to any user, host or
                network, including, without limitation, via means of submitting
                a virus or "Trojan horse" to the Website or app, overloading,
                "flooding", "mailbombing" or "crashing", or (4) sending
                unsolicited electronic mail, including promotions and/or
                advertising of products or services. Violations of system or
                network security may result in civil or criminal liability. The
                business and/or its associate entities will have the right to
                investigate occurrences that they suspect as involving such
                violations and will have the right to involve and cooperate
                with, law enforcement authorities in prosecuting users who are
                involved in such violations.
              </p>
              <p>
                <b>(B) General Rules</b>
              </p>
              <p>
                Visitors may not use the WebSite or app to transmit, distribute,
                or store (a) that could constitute or encourage conduct that
                would be considered a criminal offense or violate any applicable
                law or regulation, (b) in a manner that will infringe the
                copyright, trademark, trade secret or other intellectual
                property rights of others or violate the privacy or publicity of
                other personal rights of others, or (c) that is libelous,
                defamatory, pornographic, profane, obscene, threatening, abusive
                or hateful.
              </p>
              <p>
                By visiting our website or by installing the app, you are bound
                to the terms of service. You are requested to read the document
                carefully before proceeding to use our services.
              </p>
              <div className="row">
                <p>
                  <b>Account Creation</b>
                </p>
                <p>
                  Using our website or app need an account with{" "}
                  <b>{string.websiteName}</b> ("The Site and App") as doctor or
                  employee of a doctor. To set up an account you required to
                  give your personal, professional and other needed information.
                  All information provided by you is protected by us.
                </p>
                <p>
                  Using our patient app need an account with{" "}
                  <b>{string.patientAppName}</b> ("The Patient App") as patient.
                  To set up an account you required to give your personal and
                  other needed information. All information provided by you is
                  protected by us.
                </p>
                <p>
                  You represent and warrant that the information provided by you
                  during the registration is accurate. {string.websiteName} or{" "}
                  {string.patientAppName} is not responsible for any damages or
                  losses because of your failure to maintain the confidentiality
                  of your account.
                </p>
                <p>
                  Your Account, Password and other details in the account are
                  personal to you and you may not transfer your account to
                  anyone else.
                </p>
                <p>
                  Users must be 18+ years to register an account with{" "}
                  <b>{string.websiteName}</b> ("The Site"),{" "}
                  <b>{string.doctorAppName}</b> ("The App") and{" "}
                  <b>{string.patientAppName}</b> ("The Patient App")
                </p>
                <p>
                  <b>Privacy Policy</b>
                </p>
                <p>
                  We understand the privacy of the information provided by you
                  is personal to you. We take the most care to handle your
                  information responsibly. We do not share your information with
                  anyone (individual or organization) other than our business
                  partners. We may share your information with the business
                  partner only to fulfil the service.
                </p>
                <p>
                  <b>Communication</b>
                </p>
                <p>
                  By using our service (Website or App), you agree to be
                  communicated by SMVHPL or its partners to communicate with you
                  for service, promotion, or anything else. Communication may be
                  in the form of Telephone calls, SMS, E-mail, Whats app, or
                  other mediums.
                </p>
                <p>
                  <b>Payment Security Policy</b>
                </p>
                <p>
                  All your transactions and information when placing an order
                  with us will be encrypted before passing on over the internet,
                  which ensures the privacy and high level of security of all
                  your information.
                </p>
                <p>
                  <b>Pricing</b>
                </p>
                <p>
                  All the transactions on the website will be free unless
                  otherwise specified. SMVHPL reserves the right to change the
                  price of any transactions without notice.
                </p>
                <p>
                  <b>Cancellation</b>
                </p>
                <p>
                  Patients can cancel their appointment with doctor at any time
                  if they do not need it. In such a case if any payment
                  collected, we will refund any payments already made by you for
                  the order. In case of any fraudulent transaction by any
                  customer or any transaction which defies the terms and
                  conditions of using the website or app, we at our sole
                  discretion could cancel such transactionss. We will maintain a
                  negative list of all fraudulent transactions and customers and
                  would deny access to them or cancel any orders placed by them.
                </p>
                <p>
                  <b>Colour</b>
                </p>
                <p>
                  We have made every effort to display the colors that appear on
                  the Website and app as accurately as possible. However, as the
                  actual colors you see will depend on your monitor, we cannot
                  guarantee that your monitor's display of any color will be
                  accurate.
                </p>
                <p>
                  <b>Modification of Terms &amp; Conditions of Service</b>
                </p>
                <p>
                  SMVHPL reserves the right to modify anytime the Terms and
                  conditions of use of website and App without any prior
                  notification to you. You can access the latest version of
                  these Terms and conditions at any given time on the Site. You
                  must regularly review the Terms and conditions on the Site. In
                  the event the modified Terms and conditions are not acceptable
                  to you, you should discontinue using the Service. However, if
                  you continue to use the Service you shall be deemed to have
                  agreed to accept and abide by the modified Terms Services of
                  Use of this Site.
                </p>
                <p>
                  SMVHPL is not liable for any damages and losses that may occur
                  due to the change of terms and condition policies.
                </p>
                <p>
                  <b>Termination</b>
                </p>
                <p>
                  This User Agreement is valid unless and until terminated by
                  you or SMVHPL, in either case, SMVHPL will not be liable for
                  any damages or losses that may arise.
                </p>
                <p>
                  You may terminate this user agreement at any time by deleting
                  your account and discontinuing any further use of the website
                  or app. And you are required to destroy all the downloaded and
                  obtained contents from the website or app.
                </p>
                <p>
                  <b>{string.websiteName}</b> ("The Site"),{" "}
                  <b>{string.doctorAppName}</b> ("The App") and{" "}
                  <b>{string.patientAppName}</b> ("The Patient App") reserves
                  the right to terminate this user agreement anytime without any
                  notice or liability. And may or may not deny access and
                  services to the website.
                </p>
                <p>
                  <b>{string.websiteName}</b> ("The Site"),{" "}
                  <b>{string.doctorAppName}</b> ("The App") and{" "}
                  <b>{string.patientAppName}</b> ("The Patient App") reserves
                  the right to moderate, delete, and keep any comment or content
                  posted by a user before, during, or after this user agreement.
                </p>
                <p>
                  Any termination of the user agreement will not cancel the
                  obligation to pay for the product ordered from the website and
                  does not affect the user liability arisen under this user
                  agreement.
                </p>
              </div>
              <p>
                SMV Healthcare Private Limited, on the behalf of the brands
                owned, group, and affiliated companies under the name of{" "}
                <b>{string.websiteName}</b> ("The Site"),{" "}
                <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App"). SMV Health
                Care owns and operates the services provided through the website
                and the mobile application. <br />
                <br />
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsandCondions;
