import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import { months } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import adminApi from "../api/adminApi";

import storage from "../auth/storage";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import "react-datepicker/dist/react-datepicker.css";

import pen from "../assests/svg/pen.svg";
import string from "../string";

const prefixerror = "DemoList";

function DemoList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const todayDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todayDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todayDate);
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear() + 2, 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const getDemoList = async () => {
    try {
      const role = await storage.getRole();
      if (role != "SuperAdmin") {
        navigate("/logout");
        return;
      }
      setLoad(true);
      setData([]);
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      const res = await adminApi.getAllDemoRequest(from, to);
      console.log("res----", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDemoList " + "getAllDemoRequest",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "from:" + from + " to:" + to
        );
        return;
      }
      setData(res.data.data);
      setLoad(false);
      return;
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getDemo ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleDelete = async (item) => {
    console.log("item---", item);
    try {
      const res = await adminApi.deleteDemoRequest(item.name);
      console.log("res---", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "handleDelete " + "deleteDemoRequest",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "id:" + item.name
        );
        return;
      }
      getDemoList();
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleDelete ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getDemoList();
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="mb-4 pd" onClick={preFunction.hideNavbar}>
            <h1 className="content-heading mb-4">Demo Request</h1>
            <div className="row mb-5">
              <div className="col-lg-2">
                <label className="col-lg-3 mt-2 p-0">From :</label>
                <div className="col-lg-2 p-0">
                  <DatePicker
                    name="fromDate"
                    id="fromDate"
                    openToDate={fromDate}
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                    }}
                    minDate={new Date("2022-01-01")}
                    maxDate={toDate}
                    dateFormat="dd-MM-yyyy"
                    customInput={<DateCustomInput />}
                    className="form-control datepicker"
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          style={{ marginRight: "3px" }}
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <label className="col-lg-3 mt-2 p-0">To :</label>
                <div className="col-lg-2 p-0">
                  <DatePicker
                    name="toDate"
                    id="toDate"
                    openToDate={toDate}
                    selected={toDate}
                    onChange={(date) => {
                      setToDate(date);
                    }}
                    minDate={fromDate}
                    maxDate={new Date()}
                    dateFormat="dd-MM-yyyy"
                    customInput={<DateCustomInput />}
                    className="form-control datepicker"
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          style={{ marginRight: "3px" }}
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row border p-3">
                <div className="row totcntstyle">
                  A total of {data.length} demo request identified.
                </div>
                {data.length > 0 ? (
                  <div id="report" className="table-responsive">
                    <table className="table mt-1">
                      <thead>
                        <tr>
                          <th width="1%">No</th>
                          <th width="10%">Date</th>
                          <th width="10%">Name</th>
                          <th width="15%">Email</th>
                          <th width="10%">Mobile</th>
                          <th width="10%">Status</th>
                          <th>Comment</th>
                          <th width="7%">Update</th>
                          {/* <th width="7%">Delete</th> */}
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item, index) => (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td>
                              {moment(item.creation).format("DD-MM-yyyy")}
                            </td>
                            <td>{item.names}</td>
                            <td>{item.email}</td>
                            <td>{item.mobile_number}</td>
                            <td>{item.status}</td>
                            <td>{item.comments}</td>
                            <td>
                              <img
                                className="pointer"
                                src={pen}
                                alt={string.websiteName + "  Demo"}
                                onClick={() =>
                                  navigate("/updatedemo", {
                                    state: {
                                      id: item.name,
                                    },
                                  })
                                }
                              />
                            </td>
                            {/* <td>
                            <button
                              className="btnAdmin"
                              onClick={(e) => handleDelete(item)}
                            >
                              Delete
                            </button>
                          </td> */}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoList;
