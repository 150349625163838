import { create } from "apisauce";

const medicineClient = create({
  baseURL: "https://api.openai.com",
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer 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",
  },
});
// console.log("medicineClient---", medicineClient);

export default medicineClient;
