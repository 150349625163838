import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import PageHeader from "./pageHeader";

const sendConversionEvent = () => {
  window.gtag("event", "conversion", {
    send_to: "AW-828206494/V7LpCI6wq-EYEJ7b9YoD",
    value: 1.0,
    currency: "INR",
  });
};

function DemoSuccess() {
  const navigate = useNavigate();
  useEffect(() => {
    sendConversionEvent();
  }, []);
  return (
    <>
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8 mb-5">
              <div className="row login-right">
                <div className="col-lg-7 p-0">
                  <div className="loginbluebgbox">
                    <div className="loginlefttext">
                      Revolutionize <br />
                      your clinical experience <br />
                      with MyClinic!
                    </div>
                    <div className="streamtext">
                      Streamlines electronic patient
                      <br /> record keeping.
                    </div>
                    <div className="row no-gutters">
                      <div className="col-lg-4 mt-4">
                        <button
                          className="aboutbut"
                          type="submit"
                          title="About Us"
                          onClick={(e) => navigate("/aboutus")}
                        >
                          About Us
                        </button>
                      </div>
                      <div className="col-lg-5 mt-4">
                        <button
                          className="privacybut"
                          type="submit"
                          title="Privacy Policy"
                          onClick={(e) => navigate("/privacypolicy")}
                        >
                          Privacy Policy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 p-0">
                  <div className="logbox">
                    <h3 className="mt-2 text-center">
                      Thank you for your Demo Request!
                    </h3>
                    <h6>
                      We will be in touch shortly to schedule a time for your
                      demo.
                    </h6>
                    <h6>
                      In the meantime, please feel free to explore our website
                      to learn more about our services.
                    </h6>
                    <div className="mt-3 text-center">
                      <button
                        className="welcomebut"
                        onClick={(e) => navigate("/")}
                      >
                        Back to homepage
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DemoSuccess;
