import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
const prefixerror = "ViewPatient";

function ViewPatient() {
  //#region const
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [dob, setDob] = useState([]);
  const [addressData, setAddressData] = useState([]);
  const [relationArr, setRelationArr] = useState([]);
  const [role, setRole] = useState("");
  //#endregion

  const handleClick = (patientId, fullName) => {
    navigate("/newappointment", { state: { patientId, fullName } });
  };

  const handleViewPatient = (id) => {
    navigate("/viewpatient", { state: { id } });
    window.location.reload();
  };

  const handleHealthRecord = (patientId, fullName) => {
    navigate("/patientrecord", { state: { patientId, fullName } });
  };

  const getPatient = async () => {
    try {
      if (!location.state || !location.state.id) {
        navigate("/patientlist");
        return;
      }
      const role = await storage.getRole();
      setRole(role);
      console.log("location.state.id--", location.state.id);
      setLoad(true);
      const res1 = await doctorApi.getPatientById(location.state.id);
      if (!res1.ok) {
        ErrorLog(
          prefixerror + " getPatient " + "getPatientById",
          JSON.stringify(res1),
          "We are facing some issues.Please try again after sometime.",
          "id:" + location.state.id
        );
        return;
      }
      console.log(res1, "---res1");
      const dateofbirth = res1.data.data.dob.split("-");
      setDob(dateofbirth[2] + "-" + dateofbirth[1] + "-" + dateofbirth[0]);
      setData(res1.data.data);

      const addressRes = await doctorApi.getPatientAddress(location.state.id);
      if (!addressRes.ok) {
        ErrorLog(
          prefixerror + " getPatient " + "getPatientAddress",
          JSON.stringify(addressRes),
          "We are facing some issues.Please try again after sometime.",
          "id:" + location.state.id
        );
        return;
      }
      console.log("addressRes--", addressRes);
      if (addressRes.data.data.length > 0) {
        setAddressData(addressRes.data.data[0]);
      }
      let relationArr = [];
      if (res1.data.data.patient_relation.length > 0) {
        let i;
        console.log("rel---", res1.data.data.patient_relation);
        for (i = 0; i < res1.data.data.patient_relation.length; i++) {
          var myObject = {};
          myObject["patient"] = res1.data.data.patient_relation[i].patient;
          console.log("rell---", res1.data.data.patient_relation[i].relation);
          myObject["relation"] = res1.data.data.patient_relation[i].relation;
          const respatientRelation = await doctorApi.getPatientById(
            res1.data.data.patient_relation[i].patient
          );
          if (!respatientRelation.ok) {
            ErrorLog(
              prefixerror + " getPatient " + "getPatientById",
              JSON.stringify(respatientRelation),
              "We are facing some issues.Please try again after sometime.",
              "patient:" + res1.data.data.patient_relation[i].patient
            );
            return;
          }
          console.log("respatientRelation---", respatientRelation);
          myObject["name"] = respatientRelation.data.data.patient_name;
          relationArr.push(myObject);
        }

        setRelationArr(relationArr);
      }
      console.log("relationArr---", relationArr);

      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + " getPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getPatient();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row">
                  <div className="col-6">
                    <div className="row no-gutters">
                      <h4>
                        {data.first_name} {data.last_name ? data.last_name : ""}
                      </h4>
                    </div>

                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">Name :</label>
                      <div className="control-label-text col-lg-8">
                        {data.first_name} {data.last_name ? data.last_name : ""}
                      </div>
                    </div>
                    {data.email != "" && (
                      <div className="row no-gutters">
                        <label className="control-label col-lg-4">
                          Email :
                        </label>{" "}
                        <div className="control-label-text col-lg-8">
                          {data.email}
                        </div>
                      </div>
                    )}
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">Mobile :</label>
                      <div className="control-label-text col-lg-8">
                        {data.mobile}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">
                        Blood group :
                      </label>
                      <div className="control-label-text col-lg-8">
                        {data.blood_group ? data.blood_group : "Not Mentioned"}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">DOB :</label>
                      <div className="control-label-text col-lg-8">{dob}</div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">Gender :</label>
                      <div className="control-label-text col-lg-8">
                        {data.sex}
                      </div>
                    </div>
                    {addressData && addressData.address_line1 && (
                      <div className="row no-gutters">
                        <label className="control-label col-lg-4">
                          Address :
                        </label>
                        <div className="control-label-text col-lg-8">
                          <div>
                            {addressData.address_line1}
                            {addressData.address_line2 != ""
                              ? ", " + addressData.address_line2
                              : ""}
                            <br />
                            {addressData.city}
                            <br />
                            {addressData.state} - {addressData.pincode}
                          </div>
                        </div>
                      </div>
                    )}
                    {relationArr.length > 0 && (
                      <>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-4">
                            Relations :
                          </label>
                          <div className="col-sm-7">
                            <table className="table ml-2">
                              <thead className="tableHead">
                                <tr>
                                  <th width="1%">No</th>
                                  <th width="40%">Name</th>
                                  <th>Relationship</th>
                                </tr>
                              </thead>
                              <tbody>
                                {relationArr.map((item, index) => (
                                  <tr key={item.name}>
                                    <td>{index + 1}</td>
                                    <td>
                                      <a
                                        className="pointer"
                                        onClick={() =>
                                          handleViewPatient(item.patient)
                                        }
                                      >
                                        {item.name}
                                      </a>
                                    </td>
                                    <td>{item.relation}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </>
                    )}
                    {role != "SuperAdmin" && (
                      <div className="text-center mt-4 mb-5">
                        <button
                          title="Book Appointment"
                          className="btn"
                          onClick={() =>
                            handleClick(data.name, data.patient_name)
                          }
                        >
                          <span className="icofont icofont-plus"></span>
                          Book Appointment
                        </button>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                          type="submit"
                          className="btn mb-3 mt-3"
                          title="View Health Record"
                          onClick={() =>
                            handleHealthRecord(data.name, data.patient_name)
                          }
                        >
                          <span className="icofont-prescription"></span>View
                          Health Record
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewPatient;
