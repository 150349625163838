import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
import storage from "../auth/storage";
import pen from "../assests/svg/pen.svg";
import moment from "moment";
const prefixerror = "UserList";

function AllUserList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  //#endregion

  const navigate = useNavigate();
  let sno = limitStart + 1;

  const handleDeleteUser = async (userId) => {
    try {
      setLoad(true);
      const userRes = await doctorApi.getStaff(userId);
      if (!userRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteUser " + "getStaff",
          JSON.stringify(userRes),
          "We are facing some issues.Please try again after sometime.",
          "userId:" + userId
        );
        return;
      }
      console.log("userRes---", userRes);
      if (userRes.ok) {
        const fileRes = await doctorApi.getReport(userId);
        if (!fileRes.ok) {
          ErrorLog(
            prefixerror + " handleDeleteUser " + "getReport",
            JSON.stringify(fileRes),
            "We are facing some issues.Please try again after sometime.",
            "userId:" + userId
          );
          return;
        }
        console.log("fileRes---", fileRes);

        for (let i = 0; i < fileRes.data.data.length; i++) {
          console.log("id---", fileRes.data.data[i].name);

          const delfileRes = await adminApi.deleteFromDoctype(
            "File",
            fileRes.data.data[i].name
          );
          if (!delfileRes.ok) {
            ErrorLog(
              prefixerror + " handleDeleteUser " + "deleteFromDoctype",
              JSON.stringify(delfileRes),
              "We are facing some issues.Please try again after sometime.",
              "file name:" + fileRes.data.data[i].name
            );
            return;
          }
          console.log("delfileRes---", delfileRes);
        }

        const deluserRes = await adminApi.deleteFromDoctype(
          "User",
          userRes.data.data.name
        );
        console.log("deluserRes---", deluserRes);
        if (!deluserRes.ok) {
          setLoad(false);
          ErrorLog(
            prefixerror + " handleDeleteUser " + "deleteFromDoctype",
            JSON.stringify(deluserRes),
            "We are facing some issues.Please try again after sometime.",
            "name:" + userRes.data.data.name
          );
          return;
        }

        setOpen(true);
        setMessage("User deleted successfully");
        setTimeout(() => {
          setOpen(false);
        }, 3000);
      }
      setData([]);
      getUsersList(0);
      setLoad(false);
      return;
    } catch (error) {
      setLoad(false);
      console.log("ex---", error);
      ErrorLog(
        prefixerror + " handleDeleteUser ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getUsersList = async (limitStart) => {
    try {
      setLoad(true);
      const doctorID = await storage.getID();
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const or_filters = `[["role_profile_name","=","Billing"],["role_profile_name","=","Receptionist"], ["role_profile_name","=","Admin"]]`;
      const allRes = await doctorApi.getTotalCountByDocType("User", or_filters);
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + " getUsersList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "or_filters:" + or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);

      const res = await doctorApi.getAllUserList(limitStart, pageSize);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getUsersList " + "getUserList",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" +
            doctorID +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }

      console.log("data.length==", res);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " getUsersList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getUsersList(lmtst);
  };

  const handleEditUser = (staff) => {
    navigate("/newuser", { state: { staff: staff } });
  };

  useEffect(() => {
    getUsersList(0);
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row">
                  <h1>User List</h1>
                </div>
                <div className="row">
                  <div className="form-group">
                    <button
                      title="Add User"
                      className="btn-2"
                      onClick={(e) => {
                        navigate("/newuser");
                      }}
                    >
                      <span className="icofont icofont-plus"></span>
                      Add User
                    </button>
                  </div>
                  <ShowMessage view={open} Message={message} />
                  {data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table ">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th>Name</th>
                              <th>Role</th>
                              <th>DOB</th>
                              <th>Mobile</th>
                              <th>Email</th>
                              <th>Update</th>
                              <th>Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td align="center">{sno++}</td>
                                  <td className="nowrapWhiteSpace">
                                    {item.full_name}
                                  </td>
                                  <td>{item.role_profile_name}</td>
                                  <td className="align-left nowrapWhiteSpace">
                                    {item.birth_date
                                      ? moment(item.birth_date).format(
                                          "DD-MM-yyyy"
                                        )
                                      : ""}
                                  </td>
                                  <td>{item.mobile_no}</td>
                                  <td>{item.email}</td>
                                  <td className="pointer">
                                    <img
                                      src={pen}
                                      alt="pen"
                                      onClick={() => handleEditUser(item.name)}
                                    />
                                  </td>
                                  <td className="pointer">
                                    <a
                                      onClick={() =>
                                        handleDeleteUser(item.name)
                                      }
                                    >
                                      Delete
                                    </a>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <table>
                          <tbody>
                            <tr>
                              <td className="p-3">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </>
                  ) : (
                    <label className="ms-1">No Users found</label>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default AllUserList;
