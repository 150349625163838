import React from "react";

import preFunction from "../component/common/CommonFunction";

import string from "../string";
import PageHeader from "./pageHeader";

const FAQ = () => {
  return (
    <>
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="outerpadding">
              <h4>FAQ</h4>
              <p>
                <b>What is {string.websiteName}?</b>
              </p>
              <p>
                {string.websiteName} is a cloud-based healthcare platform that
                provides secure, convenient access to medical records and
                services for patients, doctors, and other healthcare
                professionals. It enables users to manage their health
                information in one place, securely share it with providers, and
                receive personalized care from anywhere.
              </p>
              <p>
                <b>How does {string.websiteName} work?</b>
              </p>
              <p>
                {string.websiteName} works by connecting patients with their
                healthcare providers through an online portal where they can
                view their medical records, schedule appointments, communicate
                with their doctor or nurse practitioner via video chat or
                messaging service, refill prescriptions electronically, pay
                bills online and more. The platform also allows providers to
                store patient data securely in the cloud so that it can be
                accessed anytime from any device.
              </p>
              <p>
                <b>How to book an appointment?</b>
              </p>
              <p>
                To book an appointment a.) Download{" "}
                <b>{string.patientAppName}</b> ("The Patient App") that allow
                customers to easily book appointments on their smartphones or
                tablets without having to visit the physical location of the
                Doctor's clinic in person. b.) Customers can call the customer
                care team and speak with a representative who will help them
                schedule an appointment over the phone.
              </p>
              <p>
                <b>What payment methods can I use?</b>
              </p>
              <p>
                Direct and Online payment facility are available on our website.
                Online payment facility is a secure and convenient way to make
                payments for your purchases. Our online payment system uses the
                latest encryption technology to ensure that all of your personal
                information remains safe and secure. We accept major credit
                cards, debit cards, PayPal, Apple Pay, Google Pay and other
                popular payment methods. With just a few clicks you can complete
                your purchase quickly and securely.
              </p>
              <p>
                <b>Is my data safe?</b>
              </p>
              <p>
                Yes! All data stored on the <b>{string.websiteName}</b> ("The
                Site"), <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App") platform are
                encrypted using industry-standard security protocols so your
                personal information remains private and secure at all times.
              </p>
              <p>
                <b>Can I use my mobile device?</b>
              </p>
              <p>
                Absolutely! You can download our free app{" "}
                <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App"), which will
                allow you access all of your patient records directly from your
                smartphone or tablet anytime, anywhere! Plus, we offer an
                intuitive user interface designed specifically for mobile
                devices making navigation quick and easy no matter what type of
                device you're using.
              </p>
              <p>
                <b>
                  Can I cancel my booked appointment anytime before its due date
                  ?
                </b>
              </p>
              <p>
                Cancel your booked appointment anytime before its due date
                without incurring any charges provided cancellation request is
                made 24 hours prior to scheduled start time else applicable
                cancellation fee may apply as mentioned in terms & conditions
                section while making the booking itself
              </p>
              <p>
                <b>
                  Is there any limit on number of appointments I can make per
                  day ?
                </b>
              </p>
              <p>
                No ,there is no limit on number of appointments you can make per
                day but please note that each appointment should not exceed 1
                hour duration unless otherwise specified by physician while
                booking the same.
              </p>
              <p>
                <b>What type of data security measures we offer?</b>
              </p>
              <p>
                <b>{string.websiteName}</b> ("The Site"),{" "}
                <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App") takes data
                security very seriously and has implemented several layers of
                protection including encryption technology , two factor
                authentication , role based access control , audit trails &
                logging capabilities etc.,to ensure that sensitive patient data
                remains safe & secure at all times
              </p>
              <p>
                <b>
                  Are there any special considerations for elderly patients
                  using Telemedicine?
                </b>
              </p>
              <p>
                Yes, elderly patients should ensure that they have access to the
                necessary technology and that they understand how to use it. It
                is also important to make sure that their healthcare provider is
                aware of any special needs they may have.
              </p>
              <p>
                <b>Can Telemedicine replace an in-person visit?</b>
              </p>
              <p>
                In some cases, telemedicine can replace an in-person visit.
                However, it is important to discuss this with your healthcare
                provider to determine if telemedicine is appropriate for your
                particular situation.
              </p>
              <p>
                <b>What is video-based Telemedicine?</b>
              </p>
              <p>
                Video-based telemedicine is a type of telemedicine that allows
                patients and providers to communicate via video conference.
              </p>
              <p>
                <b>What are the requirements for using Telemedicine?</b>
              </p>
              <p>
                The requirements for using telemedicine vary depending on the
                provider and the type of service. Generally, patients will need
                access to the internet, a device with a camera and microphone,
                and a valid prescription.
              </p>
              <p>
                <b>
                  What types of health conditions can be treated through
                  Telemedicine?
                </b>
              </p>
              <p>
                Telemedicine can be used to treat a variety of health
                conditions, including infections, mental health issues, chronic
                illnesses, and acute illnesses.
              </p>
              <p>
                <b>What are the benefits of Telemedicine?</b>
              </p>
              <p>
                Some of the benefits of telemedicine include improved access to
                healthcare, increased efficiency, cost savings, and improved
                quality of care
              </p>
              <p>
                <b>
                  Can I access {string.patientAppName} information from
                  anywhere?
                </b>
              </p>
              <p>
                Yes, you can access your health information from any device with
                an internet connection. <b>{string.websiteName}</b> ("The
                Site"), <b>{string.doctorAppName}</b> ("The App") and{" "}
                <b>{string.patientAppName}</b> ("The Patient App") allows you to
                securely store, access, and share your health information from
                anywhere.
              </p>
              <p>
                <b>What is an online consultation app?</b>
              </p>
              <p>
                An online consultation app is a platform that enables patients
                to connect with doctors or other healthcare professionals
                virtually for medical consultations and advice.
              </p>
              <p>
                <b>How does an online consultation app work?</b>
              </p>
              <p>
                An online consultation app typically allows patients to schedule
                appointments with healthcare professionals, conduct virtual
                consultations via video or chat, and receive medical advice and
                treatment plans.
              </p>
              <p>
                <b>
                  What are the benefits of using an online consultation app?
                </b>
              </p>
              <p>
                The benefits of using an online consultation app include
                convenience, accessibility, cost savings, and the ability to
                receive medical advice and treatment from the comfort of your
                own home.
              </p>
              <p>
                <b>Is it safe to use an online consultation app?</b>
              </p>
              <p>
                Yes, as long as you use a reputable and secure online
                consultation app that adheres to privacy and security
                regulations and standards.
              </p>
              <p>
                <b>
                  Can I get a prescription through an online consultation app?
                </b>
              </p>
              <p>
                Yes, you can able to download prescription after conducting a
                consultation and assessing the patient's medical history and
                symptoms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FAQ;
