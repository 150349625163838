import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import doctorRegApi from "../api/doctorRegApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorMessage from "../component/common/ErrorMessage";
import ErrorLog from "../component/common/ErrorLog";

// import logo from "../assests/png/Cloud_Clinic_White.png";
import string from "../string";
import storage from "../auth/storage";
import useAuth from "../auth/useAuth";
import blankProfile from "../assests/png/blank-profile-picture.png";
import PageHeader from "./../pages/pageHeader";

const prefixerror = "DoctorRegisterOTP";

function DoctorRegisterOTP() {
  //#region const
  const navigate = useNavigate();
  const authLogin = useAuth();
  const [mobile, setMobile] = useState();
  const [showOTP, setShowOTP] = useState(false);
  const [otp, setOTP] = useState();
  const [otpCheck, setOtpCheck] = useState();
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [load, setLoad] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [showOtpMsg, setShowOtpMsg] = useState(false);
  const [data, setData] = useState([]);
  //#endregion

  const handleOTP = async () => {
    try {
      if (!mobile) {
        setShowMobile(true);
        // document.getElementById("mobile").className += " form-control-error";
        document.getElementById("mobile").focus();
        return;
      } else {
        setShowMobile(false);
        // document.getElementById("mobile").className = " form-control";
      }

      setLoad(true);
      // console.log(mobile);
      if (!mobile.match("[0-9]{10}")) {
        setShowMobile(true);
        setMessage("Enter a valid mobile number");
      } else {
        setShowMobile(false);
        const res = await doctorRegApi.mobileOtpDoctor(mobile);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleOTP " + "mobileOtpDoctor",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "mobile:" + mobile
          );
          return;
        }
        console.log("otpres---", res);
        console.log("otp---", res.data.message.otp);

        if (res.data.message == "Healthcare Practitioner does not exist") {
          setMessage("Kindly visit Patient website to login");
          setOpen(true);
          setLoad(false);
          return;
        }
        if (res.data.message.length > 0) {
          if (
            res.data.message[0].owner_status &&
            res.data.message[0].owner_status == "Disabled"
          ) {
            navigate("/success");
            return;
          }
          setShowOTP(true);
          setOTP(res.data.message[0].otp);
          setData(res.data.message[0]);
          // console.log("otp--", res.data.message[0].otp);
        } else {
          if (
            res.data.message.owner_status &&
            res.data.message.owner_status == "Disabled"
          ) {
            navigate("/success");
            return;
          }
          setShowOTP(true);
          setOTP(res.data.message.otp);
          setData(res.data.message);
          // console.log("otp--", res.data.message.otp);
        }

        document.getElementById("otpid") &&
          document.getElementById("otpid").focus();
        // }
      }
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "handleOTP ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleSubmit = async () => {
    try {
      // console.log(otpCheck, otp);
      if (!otpCheck) {
        setShowOtpMsg(true);
        // document.getElementById("otpid").className += " form-control-error";
        document.getElementById("otpid").focus();
        return;
      } else {
        setShowOtpMsg(false);
        // document.getElementById("otpid").className = " form-control";
      }
      if (otpCheck == otp) {
        if (data.email != "") {
          console.log("data---", data);
          let role;
          if (data.role_profile_name != "") role = data.role_profile_name;
          else role = data.interest;
          console.log("role--", role);
          // console.log("role--", role);

          let owner = "";
          let ownerSecretKey = "";
          let doctorID = "";
          let company = "";
          let company_name = "";
          let serviceUnit = "";
          let fname = "";
          let lname = "";
          let staffName = "";
          let dp = blankProfile;
          let staffdp = blankProfile;

          if (
            role !== "Practitioner Permission" &&
            role !== "SuperAdmin" &&
            role !== "Ad Manager"
          ) {
            owner = data.owner;
            ownerSecretKey = owner + ":" + data.owner_api_secret;
            await storage.storeToken(ownerSecretKey);
            await storage.storeStaffToken(data.email + ":" + data.api_secret);
            staffName = data.first_name + " " + data.last_name;
          } else {
            owner = data.email;
            ownerSecretKey = data.email + ":" + data.api_secret;
            await storage.storeToken(ownerSecretKey);
            await storage.storeStaffToken("");
            staffName = "";
            // console.log(company);
          }
          // console.log("owner--", owner);
          // console.log("secretkey--", ownerSecretKey);
          if (
            role === "SuperAdmin" ||
            role === "Practitioner Permission" ||
            role === "Ad Manager"
          ) {
            doctorID = data.name;
            company = data.hospital;
            company_name = data.company_name;
            serviceUnit = data.service_unit;
            dp = data.profile_photo
              ? string.testbaseUrl + data.profile_photo
              : dp;
            fname = data.first_name;
            lname = data.last_name;
          } else {
            doctorID = owner;
            const doctorDetailRes = await doctorRegApi.getDetilsBasicInfo(
              doctorID
            );
            if (!doctorDetailRes.ok) {
              ErrorLog(
                prefixerror + "handleSubmit " + "getDetilsBasicInfo",
                JSON.stringify(doctorDetailRes),
                "We are facing some issues.Please try again after sometime.",
                "doctorID:" + doctorID
              );
              return;
            }
            console.log("doctorDetailRes--", doctorDetailRes);
            company = doctorDetailRes.data.data.hospital;
            const companyRes = await doctorRegApi.getCompanyByName(company);
            if (!companyRes.ok) {
              ErrorLog(
                prefixerror + "handleSubmit " + "getCompanyByName",
                JSON.stringify(companyRes),
                "We are facing some issues.Please try again after sometime.",
                "company:" + company
              );
              return;
            }
            company_name = companyRes.data.data.company_name;
            const serviceUnitRes = await adminApi.getServiceUnit(doctorID);
            console.log("serviceUnitRes---", serviceUnitRes);
            serviceUnit = serviceUnitRes.data.data[0].name;
            dp = data.profile_photo
              ? string.testbaseUrl + data.profile_photo
              : dp;
            fname = doctorDetailRes.data.data.first_name;
            lname = doctorDetailRes.data.data.last_name;
          }
          await storage.storeStaus(data ? data.status : "");
          console.log("doctorID--", doctorID);
          console.log("company--", company);
          console.log("fname--", fname);
          console.log("lname--", lname);
          console.log("serviceUnit--", serviceUnit);
          console.log("dp--", dp);
          await storage.storeId(
            doctorID,
            fname,
            lname,
            data.email,
            role,
            owner,
            ownerSecretKey,
            staffName,
            company,
            company_name,
            serviceUnit,
            dp,
            staffdp
          );
          authLogin.logIn(
            doctorID,
            fname,
            lname,
            data.email,
            role,
            owner,
            ownerSecretKey,
            staffName,
            company,
            company_name,
            serviceUnit,
            dp,
            staffdp
          );
          // console.log("role--", role);

          if (role == "SuperAdmin") {
            navigate("/doctorlist");
          } else if (role == "Ad Manager") {
            navigate("/reportutmparam");
          } else {
            const profileComplete = data.profile_completeness;
            console.log("profileComplete--", profileComplete);
            await storage.storeProfileComplete(profileComplete);

            if (role == "Practitioner Permission") {
              const profileComplete = data.profile_completeness;
              console.log("profileComplete--", profileComplete);
              await storage.storeProfileComplete(profileComplete);

              // const oldUser = await storage.getOldUser();
              // console.log(oldUser, "oldUser");
              // if (!oldUser) {
              //   navigate("/configuration");
              //   await storage.storeOldUser(true);
              //   return;
              // }
              if (data.status == "Active") {
                navigate("/doctordashboard");
              } else {
                navigate("/basicinformation");
              }
            } else {
              navigate("/doctordashboard");
            }
          }
          window.location.reload();
          setLoad(false);
        } else {
          navigate("/register", {
            state: {
              mobile: mobile,
            },
          });
        }
      } else {
        setShowOtpMsg(true);
        setMessage("Enter the valid OTP");
      }
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "handlesubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const checkLogin = async () => {
    const doctorID = await storage.getID();
    const role = await storage.getRole();
    if (doctorID) {
      if (role == "SuperAdmin") navigate("/doctorlist");
      else navigate("/doctordashboard");
      return;
    }
  };

  useEffect(() => {
    checkLogin();
    !showOTP
      ? document.getElementById("mobile") &&
        document.getElementById("mobile").focus()
      : document.getElementById("otpid") &&
        document.getElementById("otpid").focus();
  }, [showOTP]);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row mb-5">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row login-right">
                <div className="col-lg-7 p-0">
                  <div className="loginbluebgbox">
                    <div className="loginlefttext">
                      Revolutionize <br />
                      your clinical experience <br />
                      with MyClinic!
                    </div>
                    <div className="streamtext">
                      Streamlines electronic patient
                      <br /> record keeping.
                    </div>
                    <div className="row no-gutters">
                      <div className="col-lg-4 mt-4">
                        <button
                          className="aboutbut"
                          type="submit"
                          title="About Us"
                          onClick={(e) => navigate("/aboutus")}
                        >
                          About Us
                        </button>
                      </div>
                      <div className="col-lg-5 mt-4">
                        <button
                          className="privacybut"
                          type="submit"
                          title="Privacy Policy"
                          onClick={(e) => navigate("/privacypolicy")}
                        >
                          Privacy Policy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5  p-0">
                  <div className="logbox">
                    <div className="logheader">Register</div>

                    {!showOTP && (
                      <>
                        <div className="logtext">Phone Number</div>
                        <div className="row no-gutters">
                          <div className="col-2 text91">+91</div>
                          <div className="col-10">
                            <CustomTextInput
                              type="tel"
                              name="mobile"
                              id="mobile"
                              maxlength="10"
                              className="logintextinput"
                              value={mobile}
                              disabled={showOTP ? "disabled" : ""}
                              placeholder="Phone Number"
                              onChange={(e) => {
                                setOpen(false);
                                setShowMobile(false);
                                setMobile(e.target.value);
                                setShowOTP(false);
                              }}
                              onClick={() => setOpen(false)}
                              onKeyUp={(e) => e.keyCode == 13 && handleOTP()}
                            />
                            <ErrorMessage
                              visible={showMobile}
                              error={"Please enter valid Phone Number"}
                            />
                            <ErrorMessage visible={open} error={message} />
                          </div>
                        </div>
                        <div className="text-center mt-3">
                          <button
                            className="otpbut"
                            type="submit"
                            title="Get OTP"
                            onClick={handleOTP}
                          >
                            Get OTP
                          </button>
                        </div>
                        <div className="mt-1 logtext">
                          Have an account? <a href="/doctormobileotp">Login!</a>
                        </div>
                      </>
                    )}
                    {showOTP && (
                      <div>
                        <div className="logtext mt-3">OTP</div>
                        <div className="">
                          <CustomTextInput
                            type="text"
                            name="otpid"
                            id="otpid"
                            maxlength="4"
                            value={otpCheck}
                            autoFocus
                            className="logintextinput"
                            placeholder="Enter the OTP"
                            onChange={(e) => {
                              setOtpCheck(e.target.value);
                              setShowOtpMsg(false);
                              setOpen(false);
                            }}
                            onClick={() => setOpen(false)}
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                          />
                        </div>
                        <ErrorMessage
                          visible={showOtpMsg}
                          error={"Please enter the valid OTP"}
                        />
                        <div className="mt-1 logtext">
                          Didn't receive OTP?{" "}
                          <a href="javascript:void(0)" onClick={handleOTP}>
                            Send again
                          </a>
                        </div>
                        <div className="form-group text-center mt-2">
                          <button
                            type="submit"
                            className="welcomebut"
                            title="Login"
                            onClick={handleSubmit}
                          >
                            <i className="icofont-login"></i> Register
                          </button>
                        </div>
                        <div className="mt-1 logtext">
                          Have an account? <a href="/doctormobileotp">Login!</a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorRegisterOTP;
