import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import moment from "moment";

import adminApi from "../api/adminApi";
import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import EmptyComponent from "../component/common/EmptyComponent";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import CustomButton from "./../component/common/CustomButton";
import ShowMessage from "./../component/common/ShowMessage";
import ErrorMessage from "./../component/common/ErrorMessage";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
const prefixerror = "DoctorList";

function DoctorList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  let sno = limitStart + 1;
  const [doctors, setDoctors] = useState([]);
  const [doctorSel, setDoctorSel] = useState([]);
  const [errorOpen, setErrorOpen] = useState(false);
  const [searchString, setSearchString] = useState("");
  //#endregion

  const handleDeleteDoctor = async (doctorid) => {
    try {
      setLoad(true);
      console.log("doctorid--", doctorid);
      const delConfigRes = await adminApi.deleteFromDoctype(
        "Cloud Clinic Configuration",
        doctorid
      );
      console.log("delConfigRes---", delConfigRes);
      const paymentRes = await adminApi.getFromDoctype(
        "Payment Entry",
        doctorid
      );
      console.log("paymentRes---", paymentRes);
      for (let i = 0; i < paymentRes.data.data.length; i++) {
        const cancelPaymentRes = await adminApi.cancelDoctype(
          "Payment Entry",
          paymentRes.data.data[i].name.toString()
        );
        console.log("cancelPaymentRes---", cancelPaymentRes);
        if (cancelPaymentRes.ok) {
          const delPaymentRes = await adminApi.deleteFromDoctype(
            "Payment Entry",
            paymentRes.data.data[i].name
          );
          console.log("delPaymentRes---", delPaymentRes);
        }
      }

      const invoiceRes = await adminApi.getFromDoctype(
        "Sales Invoice",
        doctorid
      );
      console.log("invoiceRes---", invoiceRes);
      for (let i = 0; i < invoiceRes.data.data.length; i++) {
        const cancelInvoiceRes = await adminApi.cancelDoctype(
          "Sales Invoice",
          invoiceRes.data.data[i].name
        );
        console.log("cancelInvoiceRes---", cancelInvoiceRes);
        if (cancelInvoiceRes.ok) {
          const delInvoiceRes = await adminApi.deleteFromDoctype(
            "Sales Invoice",
            invoiceRes.data.data[i].name
          );
          console.log("delInvoiceRes---", delInvoiceRes);
        }
      }

      const consultationRes = await adminApi.getConsultation(
        "Patient Encounter",
        doctorid
      );
      console.log("consultationRes---", consultationRes);

      for (let i = 0; i < consultationRes.data.data.length; i++) {
        if (consultationRes.data.data[i].docstatus == 1) {
          const cancelConsultationeRes = await adminApi.cancelDoctype(
            "Patient Encounter",
            consultationRes.data.data[i].name
          );
          console.log("cancelConsultationeRes---", cancelConsultationeRes);
          if (cancelConsultationeRes.ok) {
            const delConsultationRes = await adminApi.deleteFromDoctype(
              "Patient Encounter",
              consultationRes.data.data[i].name
            );
            console.log("delConsultationRes---", delConsultationRes);
          }
        } else {
          const delConsultationRes = await adminApi.deleteFromDoctype(
            "Patient Encounter",
            consultationRes.data.data[i].name
          );
          console.log("delConsultationRes---", delConsultationRes);
        }
      }

      const vitalRes = await adminApi.getFromDoctype("Vital Signs", doctorid);
      console.log("vitalRes--", vitalRes);

      for (let i = 0; i < vitalRes.data.data.length; i++) {
        if (vitalRes.data.data[i].docstatus == 1) {
          const cancelvitalRes = await adminApi.cancelDoctype(
            "Vital Signs",
            vitalRes.data.data[i].name
          );
          console.log("cancelvitalRes---", cancelvitalRes);
          if (cancelvitalRes.ok) {
            const delvitalRes = await adminApi.deleteFromDoctype(
              "Vital Signs",
              vitalRes.data.data[i].name
            );
            console.log("delvitalRes---", delvitalRes);
          }
        } else {
          const delvitalRes = await adminApi.deleteFromDoctype(
            "Vital Signs",
            vitalRes.data.data[i].name
          );
          console.log("delvitalRes---", delvitalRes);
        }
      }
      const appRes = await adminApi.getFromAppointment(
        "Patient Appointment",
        doctorid
      );
      console.log("appRes---", appRes);

      for (let i = 0; i < appRes.data.data.length; i++) {
        const delappRes = await adminApi.deleteFromDoctype(
          "Patient Appointment",
          appRes.data.data[i].name
        );
        console.log("delappRes---", delappRes);
      }

      const treatMentRes = await adminApi.getFromDoctype(
        "Treatment Plan Template",
        doctorid
      );
      console.log("treatMentRes---", treatMentRes);

      for (let i = 0; i < treatMentRes.data.data.length; i++) {
        const deltreatMentRes = await adminApi.deleteFromDoctype(
          "Treatment Plan Template",
          treatMentRes.data.data[i].name
        );
        console.log("deltreatMentRes---", deltreatMentRes);
      }

      const complaintRes = await adminApi.getFromDoctype("Complaint", doctorid);
      console.log("complaintRes---", complaintRes);

      for (let i = 0; i < complaintRes.data.data.length; i++) {
        const delcomplaintRes = await adminApi.deleteFromDoctype(
          "Complaint",
          complaintRes.data.data[i].name
        );
        console.log("delcomplaintRes---", delcomplaintRes);
      }

      const diagnosisRes = await adminApi.getFromDoctype("Diagnosis", doctorid);
      console.log("diagnosisRes---", diagnosisRes);

      for (let i = 0; i < diagnosisRes.data.data.length; i++) {
        const deldiagnosisRes = await adminApi.deleteFromDoctype(
          "Diagnosis",
          diagnosisRes.data.data[i].name
        );
        console.log("deldiagnosisRes---", deldiagnosisRes);
      }

      const labTemplateRes = await adminApi.getFromDoctype(
        "Lab Test Template",
        doctorid
      );
      console.log("labTemplateRes---", labTemplateRes);

      for (let i = 0; i < labTemplateRes.data.data.length; i++) {
        const dellabTemplateRes = await adminApi.deleteFromDoctype(
          "Lab Test Template",
          labTemplateRes.data.data[i].name
        );
        console.log("dellabTemplateRes---", dellabTemplateRes);
      }

      const medicineRes = await adminApi.getFromDoctype("Item", doctorid);
      console.log("medicineRes---", medicineRes);

      for (let i = 0; i < medicineRes.data.data.length; i++) {
        const delmedicineRes = await adminApi.deleteFromDoctype(
          "Item",
          medicineRes.data.data[i].name
        );
        console.log("delmedicineRes---", delmedicineRes);
      }
      const contactRes = await adminApi.getFromDoctype("Contact", doctorid);
      console.log("contactRes---", contactRes);

      for (let i = 0; i < contactRes.data.data.length; i++) {
        const delcontactRes = await adminApi.deleteFromDoctype(
          "Contact",
          contactRes.data.data[i].name
        );
        console.log("delcontactRes---", delcontactRes);
      }

      const patientRes = await adminApi.getFromDoctype("Patient", doctorid);
      console.log("patientRes---", patientRes);

      for (let i = 0; i < patientRes.data.data.length; i++) {
        const delpatientAddrRes = await adminApi.deleteFromDoctype(
          "Address",
          patientRes.data.data[i].name + "-Office"
        );
        console.log("delpatientAddrRes---", delpatientAddrRes);

        const contactRes = await adminApi.getContact(
          patientRes.data.data[i].name
        );
        console.log("contactRes---", contactRes);
        if (contactRes.data.data.length > 0) {
          for (let i = 0; i < contactRes.data.data.length; i++) {
            const delcontactRes = await adminApi.deleteFromDoctype(
              "Contact",
              contactRes.data.data[i].name
            );
            console.log("delcontactRes---", delcontactRes);
          }
        }
        const delpatientRes = await adminApi.deleteFromDoctype(
          "Patient",
          patientRes.data.data[i].name
        );
        console.log("delpatientRes---", delpatientRes);
      }

      const fileRes = await adminApi.getFromDoctype("File", doctorid);
      console.log("fileRes---", fileRes);

      for (let i = 0; i < fileRes.data.data.length; i++) {
        const delfileRes = await adminApi.deleteFromDoctype(
          "File",
          fileRes.data.data[i].name
        );
        console.log("delfileRes---", delfileRes);
      }

      const permissionRes = await adminApi.getPermission(
        "User Permission",
        doctorid
      );
      console.log("permissionRes---", permissionRes);

      for (let i = 0; i < permissionRes.data.data.length; i++) {
        const delpermissionRes = await adminApi.deleteFromDoctype(
          "User Permission",
          permissionRes.data.data[i].name
        );
        console.log("delpermissionRes---", delpermissionRes);
      }

      const delpractitionerRes = await adminApi.deleteFromDoctype(
        "Healthcare Practitioner",
        doctorid
      );
      console.log("delpractitionerRes---", delpractitionerRes);

      const scheduleeRes = await adminApi.getFromDoctype(
        "Practitioner Schedule",
        doctorid
      );
      console.log("scheduleeRes---", scheduleeRes);

      for (let i = 0; i < scheduleeRes.data.data.length; i++) {
        const delscheduleeRes = await adminApi.deleteFromDoctype(
          "Practitioner Schedule",
          scheduleeRes.data.data[i].name
        );
        console.log("delscheduleeRes---", delscheduleeRes);
      }
      const userRes = await adminApi.getUser("User", doctorid);
      console.log("userRes---", userRes);

      for (let i = 0; i < userRes.data.data.length; i++) {
        const deluserRes = await adminApi.deleteFromDoctype(
          "User",
          userRes.data.data[i].name
        );
        console.log("deluserRes---", deluserRes);
      }
      getDoctorList(0, "");
      setLoad(false);
      return;
    } catch (ex) {
      setLoad(false);
      console.log("ex---", ex);
    }
  };

  const handleAddress = async (email, hospital, phone) => {
    try {
      const doctorAddressRes = await doctorRegApi.getAddress(email);
      console.log("doctorAddressRes--", doctorAddressRes);
      if (doctorAddressRes.data.data.length > 0) {
        const addressRes = await adminApi.getAddress(hospital);
        if (!addressRes.ok) {
          ErrorLog(
            prefixerror + "handleAddress " + "getAddress",
            JSON.stringify(addressRes),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + hospital
          );
          return;
        }
        if (addressRes.data.data.length == 0) {
          const addAddressRes = await adminApi.addAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          if (!addAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "addAddress",
              JSON.stringify(addAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "email:" +
                email +
                "phone:" +
                phone +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
          console.log("addAddressRes--", addAddressRes);
        } else {
          const updateAddressRes = await adminApi.updateAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          if (!updateAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "updateAddress",
              JSON.stringify(updateAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "email:" +
                email +
                "phone:" +
                phone +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
          console.log("updateAddressRes--", updateAddressRes);
        }
      }
    } catch (error) {
      ErrorLog(
        prefixerror + " handleAddress ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      console.log(error);
    }
  };

  const handleStatus = async (doctorData) => {
    try {
      console.log("doctorData---", doctorData);
      let approvalOn = "";
      if (doctorData.approval_on && doctorData.approval_on != "")
        approvalOn = doctorData.approval_on;
      else approvalOn = moment(new Date()).format("yyyy-MM-DD");
      console.log("approvalOn--", approvalOn);

      const changeres = await adminApi.changeStatus(
        doctorData.name,
        "Active",
        approvalOn
      );
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleStatus " + "changeStatus",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" +
            doctorData.name +
            "Status:Active" +
            "approvalOn:" +
            approvalOn
        );
        return;
      }
      console.log("changeres--", changeres);
      setMessage(`Activated Successfully`);
      console.log("phone--", doctorData.mobile_phone);
      const messageRes = await adminApi.sendMessageToMobile(
        doctorData.mobile_phone
      );
      if (!messageRes.ok) {
        ErrorLog(
          prefixerror + "handleStatus sendMessageToMobile",
          JSON.stringify(messageRes),
          "We are facing some issues.Please try again after sometime.",
          "phone:" + doctorData.mobile_phone
        );
        return;
      }
      console.log("mobile messageRes", messageRes);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleStatus ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      console.log(error);
    }
  };

  const handleDisable = async (doctorid) => {
    try {
      setLoad(true);
      const changeres = await adminApi.changeStatusDisable(doctorid);
      console.log("changeres--", changeres);
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleDisable " + "changeStatusDisable",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid
        );
        return;
      }

      const generateKeysRes = await adminApi.generateKeys(doctorid);
      console.log("generateKeysRes--", generateKeysRes);
      if (!generateKeysRes.ok) {
        ErrorLog(
          prefixerror + "handleDisable " + "generateKeysRes",
          JSON.stringify(generateKeysRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid
        );
        return;
      }

      setMessage(`Disabled Successfully`);
      setOpen(true);
      getDoctorList(0, "");
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleDisable ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
    }
  };

  const handleServiceUnit = async (doctorid, hospital) => {
    try {
      console.log("doctorid---", doctorid);
      let serviceUnitRes = await adminApi.getServiceUnit(doctorid);

      console.log("serviceUnitRes---", serviceUnitRes);

      let serviceUnitName;
      if (serviceUnitRes.data.data.length == 0) {
        console.log(hospital, doctorid);
        const createServiceUnit = await adminApi.createServiceUnit(
          hospital,
          doctorid
        );
        if (!createServiceUnit.ok) {
          ErrorLog(
            prefixerror + "handleServiceUnit " + "createServiceUnit",
            JSON.stringify(createServiceUnit),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + hospital + "doctorid:" + doctorid
          );
          return;
        }
        console.log("createServiceUnit---", createServiceUnit);
        if (createServiceUnit.ok) {
          serviceUnitName = createServiceUnit.data.data.name;
          const assignSchedule = await doctorRegApi.assignSchedule(
            doctorid,
            doctorid + " Schedule",
            serviceUnitName
          );
          if (!assignSchedule.ok) {
            ErrorLog(
              prefixerror + "handleServiceUnit " + "assignSchedule",
              JSON.stringify(assignSchedule),
              "We are facing some issues.Please try again after sometime.",
              "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
            );
            return;
          }
          console.log("assignSchedule---", assignSchedule);
        }
      } else {
        serviceUnitName = serviceUnitRes.data.data[0].name;
        const assignSchedule = await doctorRegApi.assignSchedule(
          doctorid,
          doctorid + " Schedule",
          serviceUnitName
        );
        console.log("assignSchedule---", assignSchedule);
        if (!assignSchedule.ok) {
          ErrorLog(
            prefixerror + "handleServiceUnit " + "assignSchedule",
            JSON.stringify(assignSchedule),
            "We are facing some issues.Please try again after sometime.",
            "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
          );
          return;
        }
      }
    } catch (error) {
      ErrorLog(
        prefixerror + " handleServiceUnit ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
    }
    return;
  };

  const handleActivate = async (doctorData) => {
    try {
      setLoad(true);
      console.log(
        doctorData.name,
        "hospital---",
        doctorData.hospital,
        doctorData.mobile_phone
      );
      if (
        doctorData.name == "" ||
        doctorData.email == "" ||
        !doctorData.hospital ||
        doctorData.hospital == "" ||
        doctorData.mobile_phone == ""
      ) {
        setMessage("Profile not complete. Please complete profile and Approve");
        setErrorOpen(true);
        setTimeout(() => {
          setErrorOpen(false);
        }, 3000);
        setLoad(false);
        return;
      }
      let companyRes = {};
      if (doctorData.hospital.indexOf("HLC-COM-") == -1) {
        const companyAbbrRes = await doctorRegApi.getCompanyByAbbr(
          doctorData.name
        );
        console.log("companyAbbrRes---", companyAbbrRes);
        if (!companyAbbrRes.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "createCompany",
            JSON.stringify(companyAbbrRes),
            "We are facing some issues.Please try again after sometime.",
            "email:" + doctorData.name
          );
          return;
        }
        if (companyAbbrRes.data.data.length > 0) {
          companyRes = {
            data: {
              data: companyAbbrRes.data.data[0],
            },
          };
        } else {
          companyRes = await doctorRegApi.createCompany(
            doctorData.hospital,
            doctorData.name
          );
          console.log("companyRes---", companyRes);
          if (!companyRes.ok) {
            ErrorLog(
              prefixerror + "handleActivate " + "createCompany",
              JSON.stringify(companyRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" + doctorData.hospital
            );
            return;
          }
        }
        console.log("companyRes---", companyRes);
        const companyName = companyRes.data.data.name;
        console.log("companyName---", companyName);

        const fileres = await doctorRegApi.attachImage(doctorData.name);
        console.log("certificateRes--", fileres);

        let letterhead = "";
        if (fileres.data.data.length > 0) {
          fileres.data.data.map((item, index) => {
            if (item.file_name.indexOf("-letterhead") != -1) {
              letterhead = fileres.data.data[index].file_url;
              console.log("letterhead--", letterhead);
            }
          });
          if (letterhead != "") {
            const letterheadRes = await doctorRegApi.createLetterHead(
              companyName,
              letterhead
            );
            console.log("letterheadRes--", letterheadRes);
          }
        }
        await handleServiceUnit(doctorData.name, companyName);

        const updateDoctor = await doctorRegApi.updateCompanyDoctor(
          doctorData.name,
          companyName
        );
        console.log("updateDoctor---", updateDoctor);
        await handleAddress(
          doctorData.name,
          companyName,
          doctorData.office_phone
        );

        const res1 = await doctorRegApi.userPermission(
          doctorData.name,
          companyName
        );
        console.log("res1----", res1);
        if (!res1.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "userPermission",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "email:" + doctorData.name + "hospital:" + companyName
          );
          return;
        }
      } else {
        await handleServiceUnit(doctorData.name, doctorData.hospital);
        const logoRes = await doctorRegApi.getLetterPad(doctorData.hospital);
        console.log("logoRes==", logoRes);

        if (logoRes.data.data.length === 0) {
          const fileres = await doctorRegApi.attachImage(doctorData.name);
          console.log("certificateRes--", fileres);

          let letterhead = "";
          if (fileres.data.data.length > 0) {
            fileres.data.data.map((item, index) => {
              if (item.file_name.indexOf("-letterhead") != -1) {
                letterhead = fileres.data.data[index].file_url;
              }
            });
            if (letterhead != "") {
              const letterheadRes = await doctorRegApi.createLetterHead(
                doctorData.hospital,
                letterhead
              );
              console.log("letterheadRes--", letterheadRes);
            }
          }
        }
      }

      await handleStatus(doctorData);

      setOpen(true);
      getDoctorList(0, "");
      setTimeout(() => {
        setOpen(false);
      }, 3000);

      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleActivate ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
      console.log(error);
    }
  };

  const handleEditDoctor = (id) => {
    console.log("id==", id);
    navigate("/basicinformation", { state: { id } });
  };

  const getDoctorList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      setData([]);
      console.log("pageSize----", pageSize);
      const role = await storage.getRole();
      console.log("role--", role);
      if (role !== "SuperAdmin") {
        navigate("/");
        window.location.reload();
        return;
      }
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const res = await adminApi.getAllDoctorDetails(
        searchString,
        pageSize,
        limitStart
      );
      console.log("res==", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDoctorList " + "getAllDoctorDetails",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" +
            searchString +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      setTotalCount(res.data.message[1].total_record);
      setData(res.data.message[0]);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " getDoctorList ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
      console.log(error);
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getDoctorList(lmtst, searchString);
  };

  const handleViewDoctor = (doctorid) => {
    navigate("/viewdoctor", { state: { doctorid } });
  };

  const handleSearchDoctor = async (value) => {
    try {
      console.log("value---", value);
      setDoctors([]);
      if (value.length > 2) {
        const res = await adminApi.getSearchDoctor(value);
        console.log("searchRes--", res.data.data);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchDoctor " + "getSearchDoctor",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            let age = "";
            if (res.data.data[i].date_of_birth) {
              age =
                moment().diff(res.data.data[i].date_of_birth, "years") +
                " years-";
            }
            var myObject = {
              name: res.data.data[i].name,
              practitioner_name:
                res.data.data[i].practitioner_name +
                "-" +
                age +
                res.data.data[i].mobile_phone,
            };
            arr.push(myObject);
          }
          console.log("arr---", arr);
          setDoctors(arr);
        }
      }
    } catch (error) {
      ErrorLog(
        prefixerror + " handleSearchDoctor ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      console.log(error);
    }
  };

  const handleRemove = () => {
    setDoctorSel([]);
    setSearchString("");
    setDoctors([]);

    getDoctorList(0, "");
  };

  const handleSelectDoctor = (e) => {
    console.log(e, "e");
    setDoctorSel([]);
    setDoctors([]);
    if (e && e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        e[i].practitioner_name = e[i].practitioner_name.split("-")[0];
      }
      setDoctorSel(e);
      setSearchString(e[0].practitioner_name.split("-")[0]);
      getDoctorList(0, e[0].practitioner_name.split("-")[0]);

      // setSearchString(e[0].name);
      // getDoctorList(0, e[0].name);
      setDoctors(e);
      document.getElementById("doctorId_input").blur();
    }
  };

  const handleVerifyEmail = async (mail, recipientName) => {
    setLoad(true);
    try {
      const mailRes = await doctorRegApi.sendEmailVerification(
        recipientName,
        mail
      );
      if (!mailRes.ok) {
        ErrorLog(
          prefixerror + "handleVerifyEmail " + "sendEmailVerification",
          JSON.stringify(mailRes),
          "We are facing some issues.Please try again after sometime.",
          "recipientName:" + recipientName + "mail:" + mail
        );
        return;
      }
      console.log("mailRes---", mailRes);
      setMessage(`Verification Email Sent Successfully.`);
      setOpen(true);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setLoad(false);
      return;
    } catch (error) {
      ErrorLog(
        prefixerror + " handleVerifyEmail ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
    }
  };

  useEffect(() => {
    getDoctorList(limitStart, "");
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="row no-gutters">
              <div className="mb-4">
                <div className="pd">
                  <h1 className="content-heading mb-4">Doctor List</h1>
                  <div className="row no-gutters form-group">
                    <div className="col-lg-4 ">
                      <Multiselect
                        name="doctor"
                        id="doctorId"
                        options={doctors}
                        showArrow={true}
                        selectedValues={doctorSel}
                        onSelect={handleSelectDoctor}
                        onRemove={handleRemove}
                        placeholder={"Doctor Name/Mobile"}
                        hidePlaceholder={true}
                        displayValue="practitioner_name"
                        avoidHighlightFirstOption={true}
                        selectionLimit={1}
                        customCloseIcon={" "}
                        customArrow={
                          <span
                            className="icofont-search"
                            style={{
                              fontSize: "15px",
                            }}
                          ></span>
                        }
                        onSearch={(e) => {
                          handleSearchDoctor(
                            document.getElementById("doctorId_input").value
                          );
                          handleSelectDoctor("");
                        }}
                        emptyRecordMsg={
                          <div className="notFoundSearch">Type to search</div>
                        }
                      />
                    </div>
                  </div>
                  <ShowMessage view={open} Message={message} />
                  <ErrorMessage visible={errorOpen} error={message} />
                  <div id="report" className="mt-4">
                    <div className="row border p-3">
                      <div className="row totcntstyle">
                        A total of {totalCount} doctor identified.
                      </div>
                      {data.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table ">
                              <thead>
                                <tr>
                                  <th width="1%">No</th>
                                  <th width="15%">Name</th>
                                  <th width="5%">Age</th>
                                  <th width="5%">Mobile</th>
                                  <th width="20%">Email</th>
                                  <th>Clinic</th>
                                  <th width="5%">OS</th>
                                  <th width="5%">Profile (%)</th>
                                  <th width="5%">Status</th>
                                  {/* <th width="5%">Update Status</th> */}
                                  <th width="5%">Profile</th>
                                  <th width="5%">View</th>
                                  {/* <th>Delete</th> */}
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td align="center">{sno++}</td>
                                      <td>{item.practitioner_name}</td>
                                      <td>
                                        {item.date_of_birth &&
                                          moment().diff(
                                            item.date_of_birth,
                                            "years"
                                          )}
                                      </td>
                                      <td>{item.mobile_phone}</td>
                                      <td>
                                        {item.email}
                                        <br />
                                        {item.email ? (
                                          <>
                                            {!item.email_verification ? (
                                              <a
                                                className="pointer"
                                                type="submit"
                                                onClick={() =>
                                                  handleVerifyEmail(
                                                    item.email,
                                                    item.practitioner_name
                                                  )
                                                }
                                              >
                                                Send Verification Email
                                              </a>
                                            ) : (
                                              "( Verified )"
                                            )}
                                          </>
                                        ) : null}
                                      </td>
                                      <td>{item.company_name}</td>
                                      <td>{item.location}</td>
                                      <td>{item.profile_completeness}</td>
                                      <td>{item.status}</td>

                                      {/* <td>
                                      {item.status === "Active" ? (
                                        <CustomButton
                                          className="btnAdmin bg-pink-dark"
                                          type="submit"
                                          title="Disable"
                                          onClick={() =>
                                            handleDisable(item.name)
                                          }
                                        />
                                      ) : (
                                        <CustomButton
                                          className="btnAdmin"
                                          type="submit"
                                          title="Activate"
                                          onClick={() => handleActivate(item)}
                                        />
                                      )}
                                    </td> */}
                                      <td className="App">
                                        <CustomButton
                                          className="btnAdmin"
                                          onClick={() =>
                                            handleEditDoctor(item.name)
                                          }
                                          title="Update"
                                        />
                                      </td>
                                      <td>
                                        <button
                                          type="submit"
                                          className="btn-3"
                                          title="View Prescription"
                                          onClick={() =>
                                            handleViewDoctor(item.name)
                                          }
                                        >
                                          <span className="icofont-prescription"></span>
                                          View
                                        </button>
                                      </td>
                                      {/* <td className="App pointer">
                                        <a
                                          onClick={() =>
                                            handleDeleteDoctor(item.name)
                                          }
                                        >
                                          Delete
                                        </a>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {totalCount > pageSize && (
                            <table>
                              <tbody>
                                <tr>
                                  <td>
                                    <Pagination
                                      itemsCount={totalCount}
                                      pageSize={pageSize}
                                      currentPage={currentPage}
                                      onPageChange={(page) =>
                                        handlePageChange(page)
                                      }
                                      className="pointer"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DoctorList;
