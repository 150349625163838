import React, { useEffect, useContext, useState } from "react";
import { Tab, Col, Nav, Row } from "react-bootstrap";
import DatePicker from "react-datepicker";
import moment from "moment";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";
import { months } from "../component/common/CommonArray";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
import AuthContext from "../auth/context";

import ConfigGeneral from "../assests/svg/config_general.svg";
import ConfigAppointment from "../assests/svg/config_appointment.svg";
import ConfigConsultation from "../assests/svg/config_consultation.svg";
import ConfigLeave from "../assests/svg/config_leave.svg";
import ConfigPayment from "../assests/svg/config_payment.svg";
import ConfigPrescription from "../assests/svg/config_prescription.svg";
import ConfigPatient from "../assests/svg/patientlist.svg";
import string from "../string";
import { useNavigate } from "react-router-dom";

const prefixerror = "Configuration";

function Configuration() {
  //#region const
  const {
    prescriptionHeightConfig,
    setPrescriptionHeightConfig,
    prescriptionWeightConfig,
    setPrescriptionWeightConfig,
    prescriptionBMIConfig,
    setPrescriptionBMIConfig,
    prescriptionBPConfig,
    setPrescriptionBPConfig,
    prescriptionSymptomConfig,
    setPrescriptionSymptomConfig,
    prescriptionSymptomDurationConfig,
    setPrescriptionSymptomDurationConfig,
    prescriptionDiagnosisConfig,
    setPrescriptionDiagnosisConfig,
    prescriptionPastHistoryConfig,
    setPrescriptionPastHistoryConfig,
    prescriptionExaminationConfig,
    setPrescriptionExaminationConfig,
    prescriptionPersonalHabitConfig,
    setPrescriptionPersonalHabitConfig,
    prescriptionLabTestConfig,
    setPrescriptionLabTestConfig,
    prescriptionScanConfig,
    setPrescriptionScanConfig,
    prescriptionPatientNotesConfig,
    setPrescriptionPatientNotesConfig,
    prescriptionDoctorNotesConfig,
    setPrescriptionDoctorNotesConfig,
    heightConfig,
    setHeightConfig,
    weightConfig,
    setWeightConfig,
    bloodPressureConfig,
    setBloodPressureConfig,
    durationConfig,
    setDurationConfig,
    pastHistoryConfig,
    setPastHistoryConfig,
    examinationConfig,
    setExaminationConfig,
    personalHabitConfig,
    setPersonalHabitConfig,
    labTestConfig,
    setLabTestConfig,
    scanConfig,
    setScanConfig,
    patientNoteConfig,
    setPatientNoteConfig,
    doctorNoteConfig,
    setDoctorNoteConfig,
    paymentConfig,
    setPaymentConfig,
    whatsAppConfig,
    setWhatsAppConfig,
    gMeetConfig,
    setGMeetConfig,
    attachLabReportConfig,
    setAttachLabReportConfig,
    patientAddressConfig,
    setPatientAddressConfig,
    patientEmailConfig,
    setPatientEmailConfig,
    // smartAssistant,
    // setSmartAssistant,
    // otpPatientConfig,
    // setOtpPatientConfig,
  } = useContext(AuthContext);
  const [load, setLoad] = useState(false);
  const [allowedAppointments, setAllowedAppointments] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [showFromDateError, setShowFromDateError] = useState(false);
  const [showToDateError, setShowToDateError] = useState(false);
  const [showDateMismatchError, setShowDateMismatchError] = useState(false);
  const [showLeaveSuccess, setShowLeaveSuccess] = useState(false);
  const [appointmentCount, setAppointmentCount] = useState(0);
  const [showAppMsg, setShowAppMsg] = useState(false);
  const date = new Date();
  const years = preFunction.range(
    date.getFullYear(),
    date.getFullYear() + 1,
    1
  );
  const DateCustomInput = preFunction.DateCustomInput;
  const navigate = useNavigate();
  //#endregion

  const getOverLap = async () => {
    const role = await storage.getRole();
    if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
      navigate("/");
      return;
    }
    try {
      setLoad(true);
      const serviceUnit = await storage.getServiceUnit();
      const Res = await doctorApi.getOverLap(serviceUnit);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "getOverLap " + "getOverLap",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "practitioner:" + serviceUnit
        );
        return;
      }
      console.log("generalUpdateRes-------------", Res);
      setAllowedAppointments(Res.data.data.service_unit_capacity);
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getOverLap ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    return;
  };

  const getLeaveDetails = async () => {
    const doctorID = await storage.getID();
    const Res = await doctorRegApi.getDetilsBasicInfo(doctorID);
    console.log("Res-------------", Res);
    if (!Res.ok) {
      ErrorLog(
        prefixerror + "getLeaveDetails " + "getDetilsBasicInfo",
        JSON.stringify(Res),
        "We are facing some issues.Please try again after sometime.",
        "practitioner:" + doctorID
      );
      return;
    }
    Res.data.data.leave_from_date &&
      setFromDate(new Date(Res.data.data.leave_from_date));
    Res.data.data.leave_to_date &&
      setToDate(new Date(Res.data.data.leave_to_date));
  };

  const handleOverLap = async (params) => {
    try {
      setLoad(true);
      const serviceUnit = await storage.getServiceUnit();
      const Res = await doctorApi.updateOverLap(serviceUnit, params);
      console.log("Res---", Res);
      if (!Res.ok) {
        ErrorLog(
          prefixerror + "handleOverLap " + "updateOverLap",
          JSON.stringify(Res),
          "We are facing some issues.Please try again after sometime.",
          "serviceUnit:" + serviceUnit + "params:" + params
        );
        return;
      }
      setAllowedAppointments(Res.data.data.service_unit_capacity);
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleOverLap ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    return;
  };

  const handleSubmit = async (parameter) => {
    try {
      setLoad(true);
      const doctorEmail = await storage.getEmail();
      console.log("parameter--", parameter);

      const generalUpdateRes = await doctorApi.updateConfiguration(
        doctorEmail,
        parameter
      );
      if (!generalUpdateRes.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "updateConfiguration",
          JSON.stringify(generalUpdateRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorEmail:" + doctorEmail + "parameter:" + parameter
        );
        return;
      }
      console.log("generalUpdateRes---", generalUpdateRes);
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    return;
  };

  const handleLeaveCancel = async () => {
    setShowFromDateError(false);
    setShowToDateError(false);
    setShowDateMismatchError(false);
    setShowAppMsg(false);

    try {
      setLoad(true);
      const doctorID = await storage.getID();
      const leaveUpdateRes = await doctorRegApi.doctorLeaveUpdate(
        doctorID,
        "",
        ""
      );
      console.log("leaveUpdateRes---", leaveUpdateRes);
      if (!leaveUpdateRes.ok) {
        ErrorLog(
          prefixerror + "handleLeaveUpdte " + "doctorLeaveUpdate",
          JSON.stringify(leaveUpdateRes),
          "We are facing some issues.Please try again after sometime.",
          "practitioner:" + doctorID + "fromDate:" + "toDate:"
        );
        return;
      }
      setFromDate("");
      setToDate("");
      setShowLeaveSuccess(true);
      setLoad(false);
      setTimeout(() => {
        setShowLeaveSuccess(false);
      }, 3000);
    } catch (error) {
      ErrorLog(
        prefixerror + "handleLeaveUpdte ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleLeaveUpdte = async () => {
    setShowFromDateError(false);
    setShowToDateError(false);
    setShowDateMismatchError(false);
    setShowAppMsg(false);

    let err = false;
    if (fromDate == "") {
      setShowFromDateError(true);
      err = true;
    }
    if (toDate == "") {
      setShowToDateError(true);
      err = true;
      return;
    }
    if (fromDate > toDate) {
      setShowDateMismatchError(true);
      err = true;
    }
    if (err) return;

    try {
      setLoad(true);
      const doctorID = await storage.getID();
      const appointmentWithDateRangeRes =
        await doctorApi.appointmentWithDateRange(
          doctorID,
          moment(fromDate).format("yyyy-MM-DD"),
          moment(toDate).format("yyyy-MM-DD")
        );
      console.log(
        "appointmentWithDateRangeRes----",
        appointmentWithDateRangeRes
      );
      if (appointmentWithDateRangeRes.data.data.length > 0) {
        setAppointmentCount(appointmentWithDateRangeRes.data.data.length);
        setShowAppMsg(true);
        setLoad(false);
      } else {
        const leaveUpdateRes = await doctorRegApi.doctorLeaveUpdate(
          doctorID,
          moment(fromDate).format("yyyy-MM-DD"),
          moment(toDate).format("yyyy-MM-DD")
        );
        console.log("leaveUpdateRes---", leaveUpdateRes);
        if (!leaveUpdateRes.ok) {
          ErrorLog(
            prefixerror + "handleLeaveUpdte " + "doctorLeaveUpdate",
            JSON.stringify(leaveUpdateRes),
            "We are facing some issues.Please try again after sometime.",
            "practitioner:" +
              doctorID +
              "fromDate:" +
              fromDate +
              "toDate:" +
              toDate
          );
          return;
        }
        setShowLeaveSuccess(true);
        setLoad(false);
        setTimeout(() => {
          setShowLeaveSuccess(false);
        }, 3000);
      }
    } catch (error) {
      ErrorLog(
        prefixerror + "handleLeaveUpdte ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getOverLap();
    getLeaveDetails();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row no-gutters" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10 p-lg-5">
                <h1>Configuration</h1>
                <div className="row no-gutters border mb-4">
                  <Tab.Container defaultActiveKey="ConfigGeneralKey">
                    <Row className="no-gutters">
                      <Col
                        sm={3}
                        className="pt-5 pb-5"
                        style={{ backgroundColor: "#F2F6FF" }}
                      >
                        <Nav variant="tabs" className="flex-column">
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigGeneralKey">
                              <img
                                src={ConfigGeneral}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              General
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigPaymentKey">
                              <img
                                src={ConfigPayment}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Payment
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigConsultationKey">
                              <img
                                src={ConfigConsultation}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Consultation
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigPrescriptionKey">
                              <img
                                src={ConfigPrescription}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Prescription
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigAppointmentKey">
                              <img
                                src={ConfigAppointment}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Appointment
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigPatientKey">
                              <img
                                src={ConfigPatient}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Patient
                            </Nav.Link>
                          </Nav.Item>
                          <Nav.Item>
                            <Nav.Link eventKey="ConfigLeaveKey">
                              <img
                                src={ConfigLeave}
                                width={26}
                                height={26}
                                style={{ paddingRight: "10px" }}
                              />
                              Leave Details
                            </Nav.Link>
                          </Nav.Item>
                        </Nav>
                      </Col>
                      <Col
                        sm={9}
                        className="p-5"
                        style={{ backgroundColor: "#fff" }}
                      >
                        <Tab.Content defaultActiveKey="ConfigGeneralKey">
                          <Tab.Pane eventKey="ConfigGeneralKey">
                            <div className="row">
                              <h4>General Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="col-11">
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={whatsAppConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          whatsapp_prescription:
                                            !whatsAppConfig,
                                        });
                                        setWhatsAppConfig(!whatsAppConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <label>Message in WhatsApp</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={gMeetConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          google_meet: !gMeetConfig,
                                        });
                                        setGMeetConfig(!gMeetConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <label>Online Consultation</label>
                                </div>
                                {/* <div className="row no-gutters form-group">
                              <div className="col-1">
                                <label className="switch">
                                  <input
                                    type="checkbox"
                                    checked={smartAssistant ? "checked" : ""}
                                    onClick={(e) => {
                                      handleSubmit({
                                        smart_assistant: !smartAssistant,
                                      });
                                      setSmartAssistant(!smartAssistant);
                                    }}
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </div>
                              <label className="col-11">Smart Assistant</label>
                                  </div> */}
                              </div>
                              <div className="col-1"></div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigPaymentKey">
                            <div className="row">
                              <h4>Payment Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="col-11">
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={paymentConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          payment: !paymentConfig,
                                        });
                                        setPaymentConfig(!paymentConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Print Invoice</label>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigConsultationKey">
                            <div className="row">
                              <h4>Consultation Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="col-11">
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={heightConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          height: !heightConfig,
                                        });
                                        setHeightConfig(!heightConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Height</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={weightConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          weight: !weightConfig,
                                        });
                                        setWeightConfig(!weightConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Weight</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        bloodPressureConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          blood_pressure: !bloodPressureConfig,
                                        });
                                        setBloodPressureConfig(
                                          !bloodPressureConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Blood Pressure</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={durationConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          duration_in_symptom: !durationConfig,
                                        });
                                        setDurationConfig(!durationConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Duration in Symptoms</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        pastHistoryConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          past_history: !pastHistoryConfig,
                                        });
                                        setPastHistoryConfig(
                                          !pastHistoryConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Past History</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        examinationConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          examination: !examinationConfig,
                                        });
                                        setExaminationConfig(
                                          !examinationConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Examination</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        personalHabitConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          personal_habit: !personalHabitConfig,
                                        });
                                        setPersonalHabitConfig(
                                          !personalHabitConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Personal Habit</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={labTestConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({
                                          lab_test: !labTestConfig,
                                        });
                                        setLabTestConfig(!labTestConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Lab Test</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={scanConfig ? "checked" : ""}
                                      onClick={(e) => {
                                        handleSubmit({ scan: !scanConfig });
                                        setScanConfig(!scanConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Scan</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        patientNoteConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          note_for_patient: !patientNoteConfig,
                                        });
                                        setPatientNoteConfig(
                                          !patientNoteConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Patient Notes</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        doctorNoteConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          note_for_doctor: !doctorNoteConfig,
                                        });
                                        setDoctorNoteConfig(!doctorNoteConfig);
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Doctor Notes</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        attachLabReportConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          attach_lab_report:
                                            !attachLabReportConfig,
                                        });
                                        setAttachLabReportConfig(
                                          !attachLabReportConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Attach Lab Report</label>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigPrescriptionKey">
                            <div className="row">
                              <h4>Prescription Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="col-11">
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionHeightConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_height:
                                            !prescriptionHeightConfig,
                                        });
                                        setPrescriptionHeightConfig(
                                          !prescriptionHeightConfig
                                        );

                                        console.log(heightConfig, "height");
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Height</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionWeightConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_weight:
                                            !prescriptionWeightConfig,
                                        });
                                        setPrescriptionWeightConfig(
                                          !prescriptionWeightConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Weight</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionBMIConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_bmi:
                                            !prescriptionBMIConfig,
                                        });
                                        setPrescriptionBMIConfig(
                                          !prescriptionBMIConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>BMI</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionBPConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_blood_pressure:
                                            !prescriptionBPConfig,
                                        });
                                        setPrescriptionBPConfig(
                                          !prescriptionBPConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Blood Pressure</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionSymptomConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_symptom:
                                            !prescriptionSymptomConfig,
                                        });
                                        setPrescriptionSymptomConfig(
                                          !prescriptionSymptomConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Symptom</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionSymptomDurationConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_symptom_duration:
                                            !prescriptionSymptomDurationConfig,
                                        });
                                        setPrescriptionSymptomDurationConfig(
                                          !prescriptionSymptomDurationConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Duration in Symptoms</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionDiagnosisConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_diagnosis:
                                            !prescriptionDiagnosisConfig,
                                        });
                                        setPrescriptionDiagnosisConfig(
                                          !prescriptionDiagnosisConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Diagnosis</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionPastHistoryConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_past_history:
                                            !prescriptionPastHistoryConfig,
                                        });
                                        setPrescriptionPastHistoryConfig(
                                          !prescriptionPastHistoryConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Past History</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionExaminationConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_examination:
                                            !prescriptionExaminationConfig,
                                        });
                                        setPrescriptionExaminationConfig(
                                          !prescriptionExaminationConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Examination</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionPersonalHabitConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_personal_habit:
                                            !prescriptionPersonalHabitConfig,
                                        });
                                        setPrescriptionPersonalHabitConfig(
                                          !prescriptionPersonalHabitConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Personal Habit</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionLabTestConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_labt_test:
                                            !prescriptionLabTestConfig,
                                        });
                                        setPrescriptionLabTestConfig(
                                          !prescriptionLabTestConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Lab Test</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionScanConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_scan:
                                            !prescriptionScanConfig,
                                        });
                                        setPrescriptionScanConfig(
                                          !prescriptionScanConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Scan</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionPatientNotesConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_patient_note:
                                            !prescriptionPatientNotesConfig,
                                        });
                                        setPrescriptionPatientNotesConfig(
                                          !prescriptionPatientNotesConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Patient Notes</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        prescriptionDoctorNotesConfig
                                          ? "checked"
                                          : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          prescription_doctor_note:
                                            !prescriptionDoctorNotesConfig,
                                        });
                                        setPrescriptionDoctorNotesConfig(
                                          !prescriptionDoctorNotesConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>
                                  <label>Doctor Notes</label>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigAppointmentKey">
                            <div className="row">
                              <h4>Appointment Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="row ">
                                <label className="control-label col-lg-5">
                                  Maximum number of appointments per hour :
                                </label>
                                <div className="col-lg-5 p-0">
                                  <div className="form-group col-lg-2">
                                    <CustomTextInput
                                      type="number"
                                      id="allowedAppointments"
                                      className="form-control"
                                      name="allowedAppointments"
                                      value={allowedAppointments}
                                      onChange={(e) => {
                                        e.target.value =
                                          e.target.value < 0
                                            ? 0
                                            : e.target.value.length > 2
                                            ? e.target.value.slice(0, 2)
                                            : e.target.value;
                                        setAllowedAppointments(e.target.value);
                                        handleOverLap(e.target.value);
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigPatientKey">
                            <div className="row">
                              <h4>Patient Setting</h4>
                            </div>
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="col-11">
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        patientAddressConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          patient_address:
                                            !patientAddressConfig,
                                        });
                                        setPatientAddressConfig(
                                          !patientAddressConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <label>Patient Address</label>
                                </div>
                                <div className="form-group">
                                  <label className="switch">
                                    <input
                                      type="checkbox"
                                      checked={
                                        patientEmailConfig ? "checked" : ""
                                      }
                                      onClick={(e) => {
                                        handleSubmit({
                                          patient_email: !patientEmailConfig,
                                        });
                                        setPatientEmailConfig(
                                          !patientEmailConfig
                                        );
                                      }}
                                    />
                                    <span className="slider round"></span>
                                  </label>

                                  <label>Patient Email</label>
                                </div>
                              </div>
                              <div className="col-1"></div>
                            </div>
                          </Tab.Pane>
                          <Tab.Pane eventKey="ConfigLeaveKey">
                            <div className="row">
                              <h4>Leave Setting</h4>
                            </div>
                            <ShowMessage
                              view={showLeaveSuccess}
                              Message={"Leave details updated successfully"}
                            />
                            <div className="row mt-3">
                              <div className="col-1"></div>
                              <div className="row">
                                <label className="control-label col-lg-3">
                                  <span className="star">*</span> Leave From :
                                </label>
                                <div className="col-5 p-0">
                                  <div className="form-group col-lg-5">
                                    <DatePicker
                                      name="fromDate"
                                      id="fromDate"
                                      openToDate={fromDate}
                                      selected={fromDate}
                                      onChange={(date) => {
                                        setFromDate(date);
                                      }}
                                      minDate={new Date()}
                                      maxDate={
                                        new Date(
                                          (
                                            new Date().getFullYear() + 1
                                          ).toString() + "-12-31"
                                        )
                                      }
                                      dateFormat="dd-MM-yyyy"
                                      customInput={<DateCustomInput />}
                                      className="form-control datepicker"
                                      renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                      }) => (
                                        <div
                                          style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <select
                                            style={{ marginRight: "3px" }}
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) =>
                                              changeYear(value)
                                            }
                                          >
                                            {years.map((option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                          <select
                                            value={months[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                              changeMonth(months.indexOf(value))
                                            }
                                          >
                                            {months.map((option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      error={"Please select the From Date"}
                                      visible={showFromDateError}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <label className="control-label col-lg-3">
                                  <span className="star">*</span> Leave To :
                                </label>
                                <div className="col-5 p-0">
                                  <div className="form-group col-lg-5">
                                    <DatePicker
                                      name="toDate"
                                      id="toDate"
                                      openToDate={toDate}
                                      selected={toDate}
                                      onChange={(date) => setToDate(date)}
                                      minDate={fromDate ? fromDate : new Date()}
                                      maxDate={
                                        new Date(
                                          (
                                            new Date().getFullYear() + 1
                                          ).toString() + "-12-31"
                                        )
                                      }
                                      dateFormat="dd-MM-yyyy"
                                      customInput={<DateCustomInput />}
                                      className="form-control datepicker"
                                      renderCustomHeader={({
                                        date,
                                        changeYear,
                                        changeMonth,
                                      }) => (
                                        <div
                                          style={{
                                            margin: 10,
                                            display: "flex",
                                            justifyContent: "center",
                                          }}
                                        >
                                          <select
                                            style={{ marginRight: "3px" }}
                                            value={date.getFullYear()}
                                            onChange={({ target: { value } }) =>
                                              changeYear(value)
                                            }
                                          >
                                            {years.map((option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                          <select
                                            value={months[date.getMonth()]}
                                            onChange={({ target: { value } }) =>
                                              changeMonth(months.indexOf(value))
                                            }
                                          >
                                            {months.map((option) => (
                                              <option
                                                key={option}
                                                value={option}
                                              >
                                                {option}
                                              </option>
                                            ))}
                                          </select>
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      error={"Please select the To Date"}
                                      visible={showToDateError}
                                    />
                                    <ErrorMessage
                                      error={"Please select valid Dates"}
                                      visible={showDateMismatchError}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <label className="control-label col-lg-3"></label>
                                <div className="col-5 p-0">
                                  <ErrorMessage
                                    style={{ marginBottom: "10px" }}
                                    visible={showAppMsg}
                                    error={
                                      "You have " +
                                      appointmentCount +
                                      " appointments on these dates. "
                                    }
                                  />
                                  <div className="form-group col-lg-9">
                                    <button
                                      className="btn"
                                      onClick={(e) => handleLeaveUpdte()}
                                    >
                                      Submit
                                    </button>{" "}
                                    <button
                                      className="btn bg-pink-dark"
                                      onClick={(e) => handleLeaveCancel()}
                                    >
                                      Cancel Leave
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Configuration;
