import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import { months } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import AuthContext from "../auth/context";
import storage from "../auth/storage";
import string from "../string";
const prefixerror = "ConsultationHistory";

function ConsultationHistory() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const todyaDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todyaDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todyaDate);
  const [role, setRole] = useState("");
  const date = new Date();
  const { attachLabReportConfig } = useContext(AuthContext);
  const years = preFunction.range(2022, date.getFullYear(), 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const handleAddInvoice = async (
    patientId,
    fullName,
    consultationName,
    practitioner
  ) => {
    console.log("practitioner--", practitioner);
    navigate("/invoice", {
      state: { patientId, fullName, consultationName, practitioner },
    });
  };

  const getConsultationList = async () => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      const company = await storage.getCompany();
      setRole(role);

      if (
        location.state &&
        location.state.dash &&
        location.state.dash != "all"
      ) {
        var dash = location.state.dash;
        setDateByDash(dash);
        return;
      }

      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      console.log("dates----------", from, to);

      let res;
      if (role != string.DOCTOR_ROLE) {
        res = await doctorApi.getAllConsultationHistoryByCompany(
          company,
          from,
          to
        );
        console.log("res--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getConsultationList " + "getAllConsultationHistory",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to:" + to
          );
          return;
        }
      } else {
        res = await doctorApi.getAllConsultationHistory(from, to);
        console.log("res--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getConsultationList " + "getAllConsultationHistory",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to:" + to
          );
          return;
        }
      }
      if (res.data.data.length > 0) {
        setData(res.data.data);
      } else {
        setData([]);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getConsultationList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleAddReprot = (consultationname) => {
    // console.log("name--", consultationname);
    navigate("../addreport", { state: { consultationname } });
    return;
  };

  const handleClick = (name) => {
    setLoad(true);
    const consultationName = name;
    // console.log(consultationName);
    setLoad(false);
    navigate("/endconsultation", { state: { consultationName } });
  };

  const setDateByDash = async (dash) => {
    const role = await storage.getRole();
    console.log("dash----", dash);
    var fromDate;
    var toDate = new Date();
    if (dash == "day") {
      fromDate = new Date();
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    } else if (dash == "week") {
      fromDate = new Date(moment().startOf("week"));
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    } else if (dash == "month") {
      fromDate = new Date(moment().startOf("month"));
      setFromDate(fromDate);
      setToDate(toDate);
      location.state.dash = "all";
    }

    const from = moment(fromDate).format("yyyy-MM-DD");
    const to = moment(toDate).format("yyyy-MM-DD");
    try {
      let res;
      console.log("dash rold--", role);
      if (role != string.DOCTOR_ROLE) {
        console.log("dash rold company--", role);
        const company = await storage.getCompany();
        res = await doctorApi.getAllConsultationHistoryByCompany(
          company,
          from,
          to
        );
        console.log("res--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getConsultationList " + "getAllConsultationHistory",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to:" + to
          );
          return;
        }
      } else {
        console.log("dash rold doctor--", role);
        res = await doctorApi.getAllConsultationHistory(from, to);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "setDateByDash " + "getAllConsultationHistory",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "from:" + from + "to:" + to
          );
          return;
        }
      }
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "setDateByDash ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getConsultationList();
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row mb-2">
                  <h1>Consultation History</h1>
                </div>
                <div className="col-lg-10">
                  <div className="row form-group">
                    <div className="col-lg-3 p-0 mt-2">
                      <div className="row">
                        <label
                          className="col-lg-1 mt-2 pe-0"
                          style={{ width: "65px" }}
                        >
                          <span className="star">*</span> From :
                        </label>
                        <div
                          className="col-lg-2 p-0"
                          style={{ width: "154px" }}
                        >
                          <DatePicker
                            name="fromDate"
                            id="fromDate"
                            openToDate={fromDate}
                            selected={fromDate}
                            onChange={(date) => {
                              setFromDate(date);
                            }}
                            minDate={new Date("2022-01-01")}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 p-0 mt-2">
                      <div className="row">
                        <label
                          className="col-lg-1 pe-0 mt-2"
                          style={{ width: "48px" }}
                        >
                          <span className="star">*</span> To :
                        </label>
                        <div
                          className="col-lg-2 p-0"
                          style={{ width: "170px" }}
                        >
                          <DatePicker
                            name="toDate"
                            id="toDate"
                            openToDate={toDate}
                            selected={toDate}
                            onChange={(date) => {
                              setToDate(date);
                            }}
                            minDate={fromDate}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {data.length} consultation identified.
                  </div>
                  {data.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table">
                        <thead>
                          <tr>
                            <th width="1%">No</th>
                            <th width="10%">Practitioner</th>
                            <th width="10%">Date</th>
                            <th width="10%">Time</th>
                            <th>Patient Name</th>
                            <th width="15%">View</th>
                            {role != "SuperAdmin" ? (
                              <>
                                {attachLabReportConfig ? (
                                  <th width="10%">Lab report</th>
                                ) : null}
                                {role != "Receptionist" && (
                                  <th width="10%">Payment</th>
                                )}
                              </>
                            ) : null}
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => {
                            const time = item.encounter_time.split(":");
                            const selectedTime = time[0] + ":" + time[1];
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.practitioner_name}</td>
                                <td className="nowrapWhiteSpace">
                                  {moment(item.encounter_date).format(
                                    "DD-MM-yyyy"
                                  )}
                                </td>
                                <td>{selectedTime}</td>
                                <td className="nowrapWhiteSpace">
                                  {item.patient_name}
                                </td>
                                <td>
                                  <button
                                    type="submit"
                                    className="btn-3"
                                    title="View Prescription"
                                    onClick={() => handleClick(item.name)}
                                  >
                                    <span className="icofont-prescription"></span>
                                    View Prescription
                                  </button>
                                </td>
                                {role != "SuperAdmin" ? (
                                  <>
                                    {attachLabReportConfig ? (
                                      <td>
                                        {item.docstatus ? (
                                          <button
                                            type="submit"
                                            className="btn"
                                            title="View Prescription"
                                            onClick={() =>
                                              handleAddReprot(item.name)
                                            }
                                          >
                                            <span className="icofont-prescription"></span>
                                            Attach
                                          </button>
                                        ) : null}
                                      </td>
                                    ) : null}
                                    {role != "Receptionist" && (
                                      <td>
                                        {item.invoiced ? (
                                          "Paid"
                                        ) : (
                                          <button
                                            type="submit"
                                            className="btn"
                                            title="Add Invoice"
                                            onClick={() =>
                                              handleAddInvoice(
                                                item.patient,
                                                item.patient_name,
                                                item.name,
                                                item.practitioner
                                              )
                                            }
                                          >
                                            <span className="icofont-pay"></span>
                                            Generate Invoice
                                          </button>
                                        )}
                                      </td>
                                    )}
                                  </>
                                ) : null}
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ConsultationHistory;
