import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import EmptyComponent from "../component/common/EmptyComponent";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import storage from "../auth/storage";
import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
const prefixerror = "AllPatient";

function AllPatient() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [patient, setPatient] = useState([]);
  const [patientSel, setPatientSel] = useState([]);
  const [searchString, setSearchString] = useState("[]");
  const navigate = useNavigate();
  let sno = limitStart + 1;
  //#endregion

  const handleDeletePatient = async (name, patientId) => {
    setLoad(true);
    console.log("patientId----", patientId);
    const paymentRes = await adminApi.getPaymentEntryByPatient(patientId);
    console.log("paymentRes---", paymentRes);

    for (let i = 0; i < paymentRes.data.data.length; i++) {
      console.log(
        "paymentRes.data.data[i].name---",
        paymentRes.data.data[i].name
      );
      const cancelPaymentRes = await adminApi.cancelDoctype(
        "Payment Entry",
        paymentRes.data.data[i].name.toString()
      );
      console.log("cancelPaymentRes---", cancelPaymentRes);
      if (cancelPaymentRes.ok) {
        const delPaymentRes = await adminApi.deleteFromDoctype(
          "Payment Entry",
          paymentRes.data.data[i].name
        );
        console.log("delPaymentRes---", delPaymentRes);
      }
    }

    const invoiceRes = await adminApi.getDetailsByPatient(
      "Sales Invoice",
      patientId
    );
    console.log("invoiceRes---", invoiceRes);
    for (let i = 0; i < invoiceRes.data.data.length; i++) {
      console.log("id---", invoiceRes.data.data[i].name);
      if (invoiceRes.data.data[i].docstatus == 1) {
        const cancelInvoiceRes = await adminApi.cancelDoctype(
          "Sales Invoice",
          invoiceRes.data.data[i].name
        );
        console.log("cancelInvoiceRes---", cancelInvoiceRes);
        if (cancelInvoiceRes.ok) {
          const delInvoiceRes = await adminApi.deleteFromDoctype(
            "Sales Invoice",
            invoiceRes.data.data[i].name
          );
          console.log("delInvoiceRes---", delInvoiceRes);
        }
      } else {
        const delInvoiceRes = await adminApi.deleteFromDoctype(
          "Sales Invoice",
          invoiceRes.data.data[i].name
        );
        console.log("delInvoiceRes---", delInvoiceRes);
      }
    }

    const consultationRes = await adminApi.getDetailsByPatient(
      "Patient Encounter",
      patientId
    );
    console.log("consultationRes---", consultationRes);

    for (let i = 0; i < consultationRes.data.data.length; i++) {
      console.log("id---", consultationRes.data.data[i].name);
      console.log("docStatus---", consultationRes.data.data[i].docstatus);
      if (consultationRes.data.data[i].docstatus == 1) {
        const cancelConsultationeRes = await adminApi.cancelDoctype(
          "Patient Encounter",
          consultationRes.data.data[i].name
        );
        console.log("cancelConsultationeRes---", cancelConsultationeRes);
        if (cancelConsultationeRes.ok) {
          const delConsultationRes = await adminApi.deleteFromDoctype(
            "Patient Encounter",
            consultationRes.data.data[i].name
          );
          console.log("delConsultationRes---", delConsultationRes);
        }
      } else {
        const delConsultationRes = await adminApi.deleteFromDoctype(
          "Patient Encounter",
          consultationRes.data.data[i].name
        );
        console.log("delConsultationRes---", delConsultationRes);
      }
    }

    const vitalRes = await adminApi.getDetailsByPatient(
      "Vital Signs",
      patientId
    );
    console.log("vitalRes--", vitalRes);

    for (let i = 0; i < vitalRes.data.data.length; i++) {
      console.log("id---", vitalRes.data.data[i].name);
      if (vitalRes.data.data[i].docstatus == 1) {
        const cancelvitalRes = await adminApi.cancelDoctype(
          "Vital Signs",
          vitalRes.data.data[i].name
        );
        console.log("cancelvitalRes---", cancelvitalRes);
        if (cancelvitalRes.ok) {
          const delvitalRes = await adminApi.deleteFromDoctype(
            "Vital Signs",
            vitalRes.data.data[i].name
          );
          console.log("delvitalRes---", delvitalRes);
        }
      } else {
        const delvitalRes = await adminApi.deleteFromDoctype(
          "Vital Signs",
          vitalRes.data.data[i].name
        );
        console.log("delvitalRes---", delvitalRes);
      }
    }
    const appRes = await adminApi.getDetailsByPatient(
      "Patient Appointment",
      patientId
    );
    console.log("appRes---", appRes);

    for (let i = 0; i < appRes.data.data.length; i++) {
      console.log("id---", appRes.data.data[i].name);

      const delappRes = await adminApi.deleteFromDoctype(
        "Patient Appointment",
        appRes.data.data[i].name
      );
      console.log("delappRes---", delappRes);
    }
    //Mani M-HLC-PAT-2022-00084
    const contactRes = await adminApi.getContact(patientId);
    console.log("name---", name);
    console.log("contactRes---", contactRes);
    if (contactRes.data.data.length > 0) {
      for (let i = 0; i < contactRes.data.data.length; i++) {
        const delcontactRes = await adminApi.deleteFromDoctype(
          "Contact",
          contactRes.data.data[i].name
        );
        console.log("delcontactRes---", delcontactRes);
      }
    }
    const customerRes = await adminApi.getPatientByDoctype(
      "Customer",
      patientId
    );
    console.log("customerRes---", customerRes);
    if (customerRes.ok) {
      for (let i = 0; i < customerRes.data.data.length; i++) {
        const delcustomerRes = await adminApi.deleteFromDoctype(
          "Customer",
          customerRes.data.data[i].name
        );
        console.log("delcustomerRes---", delcustomerRes);
      }
    }
    // const patientRes = await adminApi.getPatientByDoctype("Patient", patientId);
    // console.log("patientRes---", patientRes);

    // if (patientRes.ok) {
    console.log("id---", patientId);

    const delpatientAddrRes = await adminApi.deleteFromDoctype(
      "Address",
      patientId + "-Office"
    );
    console.log("delpatientAddrRes---", delpatientAddrRes);

    const delpatientRes = await adminApi.deleteFromDoctype(
      "Patient",
      patientId
    );
    console.log("delpatientRes---", delpatientRes);
    setLoad(false);
    getPatientList(limitStart, "[]");
  };

  const getPatientList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      if (role != "SuperAdmin") {
        navigate("/");
        return;
      }
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const allRes = await doctorApi.getTotalCountByDocType(
        "Patient",
        searchString
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + "getPatientList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "Patient:Patient" + "searchString" + searchString
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);

      const res = await doctorApi.getAllPatient(
        searchString,
        limitStart,
        pageSize
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getPatientList " + "getAllPatient",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" +
            searchString +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      console.log("patient res==", res);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }

      setLoad(false);
    } catch (ex) {
      setLoad(false);
      console.log(ex);
    }
  };

  const handleViewPatient = (id) => {
    navigate("/viewpatient", { state: { id } });
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getPatientList(lmtst, searchString);
  };

  useEffect(() => {
    getPatientList(limitStart, searchString);
    document.getElementById("patient") &&
      document.getElementById("patient").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>
        <div className="col-lg-10">
          <DoctorHeader />
          <div className="mb-4 pd" onClick={preFunction.hideNavbar}>
            <h1 className="mb-4">Patients List</h1>
            <div className="row">
              {data.length > 0 ? (
                <>
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th width="1%">No</th>
                          <th width="15%">Name</th>
                          <th width="5%">Age</th>
                          <th width="10%">Mobile</th>
                          <th>Email Address</th>
                          <th width="10%">Doctor</th>
                          <th width="10%">Patient App Id</th>
                          <th width="10%">Date</th>
                          <th width="5%">Delete</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((item) => (
                          <tr key={item.name}>
                            <td align="center">{sno++}</td>
                            <td>
                              <a
                                className="pointer"
                                onClick={() => handleViewPatient(item.name)}
                              >
                                {item.patient_name}
                              </a>
                            </td>
                            <td>{moment().diff(item.dob, "years")}</td>
                            <td>{item.mobile}</td>
                            <td>{item.email}</td>
                            <td>{item.owner}</td>
                            <td>{item.user_id}</td>
                            <td>
                              {moment(item.creation).format("DD-MM-yyyy")}
                            </td>
                            <td className="App pointer">
                              <a
                                onClick={() =>
                                  handleDeletePatient(
                                    item.patient_name,
                                    item.name
                                  )
                                }
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  {totalCount > pageSize ? (
                    <div className="text-center">
                      <table
                        style={{
                          borderBottom: 0,
                          backgroundColor: "#f6f4f4",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td className="pt-4">
                              <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={(page) => handlePageChange(page)}
                                style={{
                                  cursor: "pointer",
                                }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  ) : null}
                </>
              ) : (
                <EmptyComponent title="No patients added" />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllPatient;
