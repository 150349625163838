import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "./../component/common/ShowMessage";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import pen from "../assests/svg/pen.svg";
import string from "../string";
import storage from "../auth/storage";
import { useNavigate } from "react-router-dom";
const prefixerror = "DiagnosisList";

function DiagnosisList() {
  //#region const
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [diag, setDiag] = useState([]);
  const [diagnosisSel, setDiagnosisSel] = useState([]);
  const [searchString, setSearchString] = useState("[]");
  const [practitioner, setPractitioner] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [saveOpenModal, setSaveOpenModal] = useState(false);
  const [diagnosisError, setDiagnosisError] = useState(false);
  const [diagnosisSuccess, setDiagnosisSuccess] = useState(false);
  const [diagnosis, setDiagnosis] = useState("");
  const [diagnosisName, setDiagnosisName] = useState("");
  const [message, setMessage] = useState("");
  let sno = limitStart + 1;
  const [showDeleteFailure, setShowDeleteFailure] = useState(false);
  const [role, setRole] = useState("");
  //#endregion

  const closeErrors = () => {
    setShowDeleteSuccess(false);
    setDiagnosisError(false);
    setDiagnosisSuccess(false);
  };

  const handleMove = async (diagnosistId) => {
    try {
      const editRes = await doctorApi.editDiagnosisOwner(diagnosistId);
      if (!editRes.ok) {
        ErrorLog(
          prefixerror + "handleMove " + "editDiagnosisOwner",
          JSON.stringify(editRes),
          "We are facing some issues.Please try again after sometime.",
          "diagnosistId:" + diagnosistId
        );
        return;
      }
      console.log("editRes---", editRes);
      setMessage("Diagniosis moved to global list successfully");
      setShowDeleteSuccess(true);
      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 3000);
      getDiagnosisList(0, "[]");
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleMove ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSaveDiagnosis = async () => {
    closeErrors();
    if (diagnosis == "") {
      setDiagnosisError(true);
      return;
    }
    console.log("diagnosisName---", diagnosisName);
    console.log("diagnosis---", diagnosis);
    handleDiagnosisAdd(diagnosisName, diagnosis);
  };

  const handleDeleteDiagnosis = async (diagnosisName) => {
    try {
      setLoad(true);
      closeErrors();
      const delRes = await doctorApi.deletediagnosis(diagnosisName);
      console.log("delRes---", delRes);
      if (!delRes.ok) {
        ErrorLog(
          prefixerror + "handleDeleteDiagnosis " + "deletediagnosis",
          JSON.stringify(delRes),
          "We are facing some issues.Please try again after sometime.",
          "diagnosisName:" + diagnosisName
        );
        setShowDeleteFailure(true);
        setTimeout(() => {
          setShowDeleteFailure(false);
        }, 3000);
        setLoad(false);
        return;
      }

      setMessage("Disease / Condition deleted successfully");
      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 3000);
      setShowDeleteSuccess(true);
      getDiagnosisList(0, "[]");

      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDeleteDiagnosis ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getDiagnosisList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      setRole(role);

      if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
        navigate("/");
        return;
      }
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const practitioner = await storage.getID();

      setPractitioner(practitioner);
      let or_filters = "[]";
      if (role != "SuperAdmin") {
        or_filters = `[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]`;
      }
      const allRes = await doctorApi.getTotalCountByDocType(
        "Diagnosis",
        searchString,
        or_filters
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + "getDiagnosisList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString + "or_filters:" + or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);
      const res = await doctorApi.getDiagnosis(
        searchString,
        or_filters,
        limitStart,
        pageSize
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDiagnosisList " + "getDiagnosis",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" +
            searchString +
            "or_filters:" +
            or_filters +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      console.log("data==", res.data.data);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getDiagnosisList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handlePageChange = (page) => {
    closeErrors();
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    console.log("page change searchString==", searchString);
    getDiagnosisList(lmtst, searchString);
  };

  const handleSelectDiagnosis = (e) => {
    console.log(e, "e");
    closeErrors();
    if (e && e.length > 0) {
      setDiagnosisSel(e);
      setSearchString('[["name","=","' + e[0].name + '"]]');
      getDiagnosisList(limitStart, '[["name","=","' + e[0].name + '"]]');
    } else {
      setDiagnosisSel();
      setSearchString("[]");
    }
    setDiag([]);
  };

  const handleDiagnosisAdd = async (diagnosisId, diagnosisName) => {
    try {
      setLoad(true);
      let Res;
      closeErrors();
      console.log("diagnosisId--", diagnosisId);
      console.log("diagnosisName--", diagnosisName);
      if (diagnosisId != "") {
        Res = await doctorApi.editDiagnosis(diagnosisId, diagnosisName);
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleDiagnosisAdd " + "editDiagnosis",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "diagnosisId:" + diagnosisId + "diagnosisName:" + diagnosisName
          );
          return;
        }
        setMessage("Disease / Condition updated successfully");
      } else {
        Res = await doctorApi.createDiagnosis(diagnosisName);
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleDiagnosisAdd " + "createDiagnosis",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "diagnosisName:" + diagnosisName
          );
          return;
        }
        setMessage("Disease / Condition added successfully");
      }
      console.log("Res----", Res.data.data);
      getDiagnosisList(0, "[]");
      setDiagnosisSuccess(true);
      setTimeout(() => {
        setSaveOpenModal(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDiagnosisAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleSearchDiagnosis = async (value) => {
    try {
      console.log("value---", value);
      setDiag([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchDiagnosis(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchDiagnosis " + "searchDiagnosis",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res----", res.data.message);
        setDiag(res.data.message);
      } else if (value.length == "") {
        getDiagnosisList(0, "[]");
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchDiagnosis ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getDiagnosisList(0, "[]");
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Disease / Condition List</h1>
                </div>
                <div className="row no-gutters form-group">
                  <div className="col-lg-4">
                    <Multiselect
                      name="diagnosis"
                      id="diagnosis"
                      options={diag}
                      showArrow={true}
                      selectedValues={diagnosisSel}
                      placeholder={"Diagnosis"}
                      avoidHighlightFirstOption={true}
                      onSelect={handleSelectDiagnosis}
                      onRemove={(e) => {
                        getDiagnosisList(0, "[]");
                        return;
                      }}
                      displayValue="diagnosis"
                      customCloseIcon={" "}
                      hidePlaceholder={true}
                      selectionLimit={1}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      emptyRecordMsg={
                        document.getElementById("diagnosis_input") &&
                        document.getElementById("diagnosis_input").value
                          .length > 2 ? (
                          <div className="notFoundAdd">
                            Do you want to add?&nbsp;&nbsp;&nbsp;
                            <button
                              className="btn-2"
                              title="Add"
                              onClick={(e) => {
                                setDiagnosis(
                                  document.getElementById("diagnosis_input")
                                    .value
                                );
                                setSaveOpenModal(true);
                              }}
                            >
                              <span className="icofont icofont-plus"></span>
                              Add
                            </button>
                          </div>
                        ) : (
                          <div className="notFoundSearch">Type to search</div>
                        )
                      }
                      onSearch={(e) => {
                        handleSearchDiagnosis(
                          document.getElementById("diagnosis_input").value
                        );
                        handleSelectDiagnosis("");
                      }}
                    />
                  </div>
                </div>
                <ShowMessage view={showDeleteSuccess} Message={message} />
                <ErrorMessage
                  error={
                    "Couldn't delete because its already added to a patient consultation"
                  }
                  visible={showDeleteFailure}
                />
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} disease / condition identified.
                  </div>
                  {data.length > 0 && (
                    <div id="report">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th>Diagnosis</th>
                              {role == "SuperAdmin" && (
                                <>
                                  <th width="12%">Created By</th>
                                  <th width="12%">Move to Global list</th>
                                </>
                              )}
                              <th width="5%">Edit</th>
                              <th width="5%">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{sno++}</td>
                                  <td className="align-left">
                                    {item.diagnosis}
                                  </td>
                                  {role == "SuperAdmin" && (
                                    <>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail
                                          ? item.owner
                                          : ""}
                                      </td>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail ? (
                                          <a
                                            onClick={(e) => {
                                              handleMove(item.name);
                                            }}
                                          >
                                            Move
                                          </a>
                                        ) : null}
                                      </td>
                                    </>
                                  )}
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          closeErrors();
                                          setDiagnosisName(item.name);
                                          setDiagnosis(item.diagnosis);
                                          setSaveOpenModal(true);
                                        }}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Edit
                                      </span>
                                    )}
                                  </td>
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          handleDeleteDiagnosis(item.name);
                                          setDiagnosisSel(0);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize ? (
                        <table
                          style={{
                            borderBottom: 0,
                            backgroundColor: "#f6f4f4",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-4">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      ) : null}
                    </div>
                  )}
                </div>
              </div>

              <Modal show={saveOpenModal}>
                <Modal.Header>
                  <Modal.Title>Disease / Condition</Modal.Title>
                  <span
                    className="icofont-close-line pointer"
                    style={{ fontSize: "25px", fontWeight: "bold" }}
                    onClick={(e) => {
                      setSaveOpenModal(false);
                      getDiagnosisList(0, "[]");
                    }}
                  ></span>
                </Modal.Header>
                <Modal.Body style={{ width: "100%" }}>
                  <div id="modelElement">
                    <div className="row no-gutters form-group">
                      <label className="control-label col-lg-4">
                        <span className="star">*</span> Disease / Condition:
                      </label>
                      <div className="col-lg-6">
                        <CustomTextInput
                          type="text"
                          name="diagnosis"
                          id="diagnosis"
                          maxlength="140"
                          className="form-control"
                          value={diagnosis}
                          onChange={(e) => {
                            setDiagnosisError(false);
                            setDiagnosis(e.target.value);
                          }}
                          placeholder="Disease / Condition"
                          onKeyUp={(e) =>
                            e.keyCode == 13 && handleSaveDiagnosis()
                          }
                        />
                        <ErrorMessage
                          error={"Please enter the Disease / Condition"}
                          visible={diagnosisError}
                        />
                      </div>
                    </div>
                    <div className="row no-gutters App">
                      <ShowMessage view={diagnosisSuccess} Message={message} />
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    className="btn"
                    title="Close"
                    onClick={() => {
                      handleSaveDiagnosis();
                      setDiagnosisSel(0);
                    }}
                  >
                    <span className="icofont-save"></span>
                    Save
                  </button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default DiagnosisList;
