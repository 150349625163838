import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

function ApproveDoctor() {
  //#region const
  const navigate = useNavigate();
  //#endregion

  const parseParams = async (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj = {};
    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
      console.log("key---", key);
      if (key == "email") {
        const email = obj[key];
        console.log("email--", email);

        navigate("/viewdoctor", { state: { doctorid: email } });
        window.location.reload();
      }
    }
    return obj;
  };

  useEffect(() => {
    let params = parseParams(window.location.search);
  }, []);
}

export default ApproveDoctor;
