import client from "./client";
import superadminclient from "./superadminclient";

const getDoctorReport = async (
  from_date,
  to_date,
  registration_status,
  status
) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.doctor_report`,
    {
      from_date,
      to_date,
      registration_status,
      status,
    }
  );
  return data;
};

const getAllDoctorDetails = async (
  name_mobile,
  limit_page_length,
  limit_start
) => {
  const data = await client.get(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.practitioner_search`,
    {
      name_mobile,
      limit_page_length,
      limit_start,
    }
  );
  return data;
};

const getAllCompanyDetails = async () => {
  const data = await superadminclient.get(
    `/api/resource/Company?fields=["name"]&limit_page_length=None`
  );
  return data;
};

const getCompanyDetails = async (pageSize, limitStart) => {
  const data = await client.get(
    `/api/resource/Company?fields=["*"]&limit_start=${limitStart}&limit_page_length=${pageSize}&order_by=creation desc`
  );
  return data;
};

const getAllDoctor = async (searchString, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner?fields=["*"]&filters=[${searchString}]&limit_start=${limitStart}&limit_page_length=${pageSize}&order_by=creation desc`
  );
  return data;
};

const getSearchDoctor = async (searchName) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner?fields=["practitioner_name", "name","date_of_birth","mobile_phone"]&filters=[["registration_status","!=","Cancelled"]]&or_filters=[["practitioner_name","like","${searchName}%"], ["mobile_phone","like","${searchName}%"]]&limit_page_length=None`
  );
  return data;
};

const getDetilsBasicInfo = async (doctorID) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner/${doctorID}`
  );
  return data;
};

const getSearchPatientAppUser = async (searchName) => {
  const data = await client.get(
    `/api/resource/User?fields=["full_name", "name","birth_date","mobile_no"]&filters=[["role_profile_name","=","Patient"]]&or_filters=[["full_name","like","${searchName}%"], ["mobile_no","like","${searchName}%"]]&limit_page_length=None`
  );
  return data;
};

const patientStatusChange = async (patientID, enabled) => {
  const data = await client.put(`/api/resource/User/${patientID}`, {
    enabled,
  });
  return data;
};

const saveComment = async (doctorID, comments) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      comments,
    }
  );
  return data;
};

const changeDisable = async (doctorID, comments) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      status: "Disabled",
      comments,
    }
  );
  return data;
};

const changeStatusDisable = async (doctorID) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      status: "Disabled",
    }
  );
  return data;
};

const changeStatus = async (doctorID, status, approval_on, comments) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      status,
      approval_on,
      registration_status: "Completed",
      comments,
    }
  );
  return data;
};

const changeRegStatus = async (doctorID, comments, approval_on) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      comments,
      registration_status: "Cancelled",
      approval_on,
    }
  );
  return data;
};

const generateKeys = async (doctorID) => {
  const data = await client.post(
    `/api/method/frappe.core.doctype.user.user.generate_keys?user=${doctorID}`
  );
  return data;
};

const updateDoctor = async (
  doctorID,
  gender,
  date_of_birth,
  degree,
  department,
  work_experience,
  op_consulting_charge,
  hospital,
  registered_practitioner_number
) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      gender,
      date_of_birth,
      degree,
      department,
      work_experience,
      op_consulting_charge,
      hospital,
      registered_practitioner_number,
    }
  );
  return data;
};

const deleteCertificate = async (cerName) => {
  const data = await client.delete(`/api/resource/File/${cerName}`);
  return data;
};

const addAddress = async (
  Company,
  email,
  phone,
  address_line1,
  address_line2,
  city,
  state,
  pincode
) => {
  const data = await client.post(`/api/resource/Address`, {
    address_title: Company,
    email,
    phone,
    address_type: "Office",
    address_line1,
    address_line2,
    city,
    state,
    country: "India",
    pincode,
    is_your_company_address: 1,
    links: [
      {
        link_doctype: "Company",
        link_name: Company,
      },
    ],
  });
  return data;
};

const getAddress = async (Company) => {
  const data = await client.get(
    `/api/resource/Address?filters=[["address_title","=","${Company}"]]&fields=["*"]`
  );
  return data;
};

const updateAddress = async (
  Company,
  email_id,
  phone,
  address_line1,
  address_line2,
  city,
  state,
  pincode
) => {
  const data = await client.put(`/api/resource/Address/${Company}-Office`, {
    email_id,
    phone,
    address_line1,
    address_line2,
    city,
    state,
    pincode,
  });
  return data;
};

const sendMessageToMobile = async (mobile_number) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_activation_message",
    {
      mobile_number,
    }
  );
  return data;
};

const getServiceUnit = async (unitName) => {
  const data = client.get(
    `/api/resource/Healthcare Service Unit?filters=[["healthcare_service_unit_name","=","${unitName}"]]`
  );
  return data;
};

const createServiceUnit = async (company, healthcare_service_unit_name) => {
  const data = client.post(`/api/resource/Healthcare Service Unit`, {
    company,
    healthcare_service_unit_name,
    service_unit_type: "General Medicine-OPD",
    allow_appointments: 1,
    overlap_appointments: 1,
    service_unit_capacity: 12,
  });
  return data;
};

const getCompanyDetail = async (company) => {
  const data = client.get(`/api/resource/Company/${company}`);
  return data;
};

const getConsultation = async (docType, doctorID) => {
  const data = client.get(
    `/api/resource/${docType}?filters=[["practitioner","=","${doctorID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getPermission = async (docType, doctorID) => {
  const data = client.get(
    `/api/resource/${docType}?filters=[["user","=","${doctorID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getDoctor = async (doctorID) => {
  const data = client.get(`/api/resource/Healthcare Practitioner/${doctorID}`);
  return data;
};

const getUser = async (docType, doctorID) => {
  const data = client.get(
    `/api/resource/${docType}?filters=[["email","=","${doctorID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getFromDoctype = async (docType, doctorID) => {
  const data = client.get(
    `/api/resource/${docType}?filters=[["owner","=","${doctorID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getFromAppointment = async (docType, doctorID) => {
  const data = client.get(
    `/api/resource/${docType}?filters=[["practitioner","=","${doctorID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const cancelDoctype = async (doctype, name) => {
  console.log(doctype, name);
  const data = client.post(`/api/method/frappe.desk.form.save.cancel`, {
    doctype,
    name,
  });
  return data;
};

const deleteFromDoctype = async (docType, id) => {
  const data = superadminclient.delete(`/api/resource/${docType}/${id}`);
  return data;
};

const getPaymentEntryByPatient = async (patientID) => {
  const data = client.get(
    `/api/resource/Payment Entry?filters=[["party","=","${patientID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getDetailsByPatient = async (doctype, patientID) => {
  const data = client.get(
    `/api/resource/${doctype}?filters=[["patient","=","${patientID}"]]&fields=["*"]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const getPatientByDoctype = async (doctype, patientID) => {
  const data = client.get(
    `/api/resource/${doctype}?filters=[["name","=","${patientID}"]]`
  );
  return data;
};
const getContact = async (patientID) => {
  const data = client.get(
    `/api/resource/Contact?filters=[["name","like","%${patientID}"]]`
  );
  return data;
};

const getAllUTM = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/MyClinic LeadSource?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAllAppointment = async (from_date, to_date) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.appointment_report`,
    {
      from_date,
      to_date,
    }
  );
  return data;
};

const getAllTreatmentTemplate = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/Treatment Plan Template?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAllMedicine = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/Item?fields=["*"]&filters=[["item_group","=","Drug"], ["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAllLabTest = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/Lab Test Template?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAllSymptom = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/Complaint?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAllDiagnosis = async (from_date, to_date) => {
  const data = await client.get(
    `/api/resource/Diagnosis?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getAdminConf = async () => {
  const data = client.get(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_smartassistant_status`
  );
  return data;
};

const setAdminConf = async (value) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.set_smartassistant_status`,
    {
      value,
    }
  );
  return data;
};

const getEmailRecipient = async () => {
  const data = await client.get(
    `/api/resource/MyClinic Config?filters=[["field","=","to_email"],["is_active","=","1"]]&fields=["value"]`
  );
  return data;
};

const setEmailRecipient = async (value) => {
  const data = await client.put(`/api/resource/MyClinic Config/MCC-00002`, {
    value,
  });
  return data;
};

const getAllPatientAppUserList = async (searchString, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/User?filters=[["role_profile_name","=","Patient"]${searchString}]&fields=["*"]&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getDetilsByFormLoad = async (doctorID) => {
  const data = await client.get(
    `/api/method/frappe.desk.form.load.getdoc?doctype=Healthcare Practitioner&name=${doctorID}`
  );
  return data;
};

const getConfigurationByDoctor = async (doctorID) => {
  const data = await client.get(
    `api/resource/Cloud Clinic Configuration?fields=["*"]&filters=[["name","=","${doctorID}"]]`
  );
  return data;
};

const deleteDemoRequest = async (id) => {
  const data = await client.delete(`api/resource/Demo Request/${id}`);
  return data;
};

const getAllDemoRequest = async (from_date, to_date) => {
  const data = await client.get(
    `api/resource/Demo Request?fields=["*"]&filters=[["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&limit_page_length=None&order_by=creation desc`
  );
  return data;
};

const getDemoById = async (id) => {
  const data = await client.get(`api/resource/Demo Request/${id}`);
  return data;
};

const updateDemoRequest = async (
  id,
  names,
  email,
  mobile_number,
  status,
  comments
) => {
  const data = client.put(`/api/resource/Demo Request/${id}`, {
    names,
    email,
    mobile_number,
    status,
    comments,
  });
  return data;
};

export default {
  getAllDoctor,
  getSearchDoctor,
  getDetilsBasicInfo,
  changeStatus,
  updateDoctor,
  deleteCertificate,
  addAddress,
  getAddress,
  updateAddress,
  sendMessageToMobile,
  getFromDoctype,
  deleteFromDoctype,
  cancelDoctype,
  getConsultation,
  getPermission,
  getUser,
  getServiceUnit,
  createServiceUnit,
  getCompanyDetail,
  getDoctor,
  getPaymentEntryByPatient,
  getDetailsByPatient,
  getPatientByDoctype,
  getFromAppointment,
  getAllAppointment,
  getAdminConf,
  setAdminConf,
  getEmailRecipient,
  setEmailRecipient,
  generateKeys,
  getAllDoctorDetails,
  patientStatusChange,
  getSearchPatientAppUser,
  getAllPatientAppUserList,
  getDoctorReport,
  getDetilsByFormLoad,
  getConfigurationByDoctor,
  getAllCompanyDetails,
  getCompanyDetails,
  changeRegStatus,
  getContact,
  changeDisable,
  saveComment,
  changeStatusDisable,
  getAllDemoRequest,
  getDemoById,
  updateDemoRequest,
  deleteDemoRequest,
  getAllTreatmentTemplate,
  getAllMedicine,
  getAllLabTest,
  getAllSymptom,
  getAllDiagnosis,
  getAllUTM,
};
