import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import ErrorMessage from "../component/common/ErrorMessage";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import string from "../string";
import storage from "../auth/storage";
import file from "../assests/svg/file.svg";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
const prefixerror = "AddReport";

function AddReport() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [load, setLoad] = useState(false);
  const [reportFiles, setReportFiles] = useState([]);
  const [showReportError, setShowReportError] = useState(false);
  const [showFileError, setShowFileError] = useState(false);
  const [image, setImage] = useState();
  const [fileName, setFileName] = useState();
  const [report, setReport] = useState();
  const [consultationHistory, setConsultationHistory] = useState([]);
  const [showAddError, setShowAddError] = useState(false);
  const [reports, setReports] = useState(false);
  const [creationDate, setCreationDate] = useState(false);
  const today = new Date();
  //#endregion
  const handleLabReport = () => {
    closeErrors();
    let err = false;
    if (!report) {
      setShowReportError(true);
      err = true;
    } else {
      setShowReportError(false);
    }
    if (!fileName) {
      setShowFileError(true);
      err = true;
    } else {
      setShowFileError(false);
    }
    if (err) {
      return;
    }
    let reports = reportFiles;
    reports = [
      ...reportFiles,
      {
        id: reportFiles.length + 1,
        name: report,
        fileName: fileName,
        image: image,
      },
    ];
    setReportFiles(reports);
    console.log("reportFiles--", reports);
    setReport("");
    document.getElementById("reportFile").value = null;
    setFileName("");
    return;
  };

  const handleImage = (e) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const handleDeleteReport = (item) => {
    const data = reportFiles.filter((m) => m !== item);
    setReportFiles(data);
  };

  const getPatientDetails = async () => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      if (role === "SuperAdmin") {
        navigate("/doctorlist");
        return;
      }
      if (location.state === null) {
        setLoad(false);
        navigate("/upcomingappointment");
        return;
      }
      const resPatientRecord = await doctorApi.consultationSummary(
        location.state.consultationname
      );
      if (!resPatientRecord.ok) {
        ErrorLog(
          prefixerror + "getPatientDetails " + "consultationSummary",
          JSON.stringify(resPatientRecord),
          "We are facing some issues.Please try again after sometime.",
          "consultationname:" + location.state.consultationname
        );
      }
      setConsultationHistory(resPatientRecord.data.data);
      setCreationDate(
        moment(resPatientRecord.data.data.creation).format("DD-MM-yyyy")
      );
      const res2 = await doctorApi.getRecord(location.state.consultationname);
      if (res2.data.data.length > 0) {
        const resRecords = await doctorApi.getReport(res2.data.data[0].name);
        if (!resRecords.ok) {
          ErrorLog(
            prefixerror + "getPatientDetails " + "getReport",
            JSON.stringify(resRecords),
            "We are facing some issues.Please try again after sometime.",
            "name:" + res2.data.data[0].name
          );
        }
        setReports(resRecords.data.data);
      }
      document.getElementById("report").focus();

      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getPatientDetails ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSubmit = async () => {
    try {
      console.log("reportFiles---", reportFiles);
      if (reportFiles.length == 0) {
        setShowAddError(true);
        return;
      }
      setLoad(true);
      const res2 = await doctorApi.getRecord(location.state.consultationname);
      console.log("res2---", res2);
      let res3 = { ok: false };
      let uploaded = 0;
      reportFiles.map(async (item) => {
        res3 = await doctorApi.attachRecord(
          res2.data.data[0].name,
          item.name +
            "-" +
            res2.data.data[0].name +
            "-" +
            moment(today).format("DD-MM-yyyy h:mm:ss") +
            "." +
            item.fileName.split(".")[1],
          item.image
        );
        if (!res3.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "attachRecord",
            JSON.stringify(res3),
            "We are facing some issues.Please try again after sometime.",
            "name:" +
              res2.data.data[0].name +
              "filename:" +
              item.name +
              "-" +
              res2.data.data[0].name +
              "-" +
              moment(today).format("DD-MM-yyyy h:mm:ss") +
              "." +
              item.fileName.split(".")[1] +
              "Image:" +
              item.image
          );
          return;
        }
        console.log("attachres3--", res3);

        setLoad(true);
        uploaded++;

        if (uploaded == reportFiles.length) {
          setLoad(true);
          setTimeout(() => {
            setLoad(false);
            navigate("../addreport", {
              state: { consultationname: location.state.consultationname },
            });
            window.location.reload();
          }, 2000);
        }
      });
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const closeErrors = () => {
    setShowReportError(false);
    setShowFileError(false);
    setShowAddError(false);
  };

  useEffect(() => {
    getPatientDetails();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <h4 className="content-heading">
                  Patient Consultation Reports
                </h4>
                <div className="col-7">
                  <label className="con_sum_lbl">Patient Name :</label>
                  <label className="control-label ms-1">
                    {consultationHistory.patient_name}
                  </label>
                  <label className="control-label ms-5">
                    {consultationHistory.patient_sex} /
                  </label>
                  <label className="control-label">
                    {consultationHistory.patient_age}
                  </label>
                  <label className="control-label con_sum_lbl ms-5">
                    Date :
                  </label>
                  <label className="control-label ms-1">{creationDate}</label>
                </div>
                <div className="mt-3 mb-2">
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th width="5%">No.</th>
                        <th width="40%">Report Name</th>
                        <th>Attachment</th>
                        <th width="5%">Update</th>
                      </tr>
                    </thead>
                    <tbody>
                      {reports.length > 0
                        ? reports.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                  {item.file_name.split(".")[0]
                                    ? item.file_name.split("-")[0]
                                    : null}
                                </td>
                                <td>
                                  <a
                                    className="text-muted"
                                    href={string.testbaseUrl + item.file_url}
                                    target="_blank"
                                  >
                                    <img src={file} width="15" height="15" />
                                    {item.file_name.split("-")[0]}
                                  </a>
                                </td>
                                <td></td>
                              </tr>
                            );
                          })
                        : null}
                      {reportFiles.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{reports.length + index + 1}</td>
                            <td>{item.name}</td>
                            <td
                              onClick={() =>
                                window.open(item.fileName, "_blank")
                              }
                            >
                              {item.name + "." + item.fileName.split(".")[1]}
                            </td>
                            <td>
                              <a
                                onClick={() => handleDeleteReport(item)}
                                className="pointer"
                              >
                                Delete
                              </a>
                            </td>
                          </tr>
                        );
                      })}
                      <tr>
                        <td>{reports.length + reportFiles.length + 1}</td>
                        <td valign="middle">
                          <CustomTextInput
                            type="text"
                            name="report"
                            id="report"
                            className="form-control"
                            value={report}
                            maxlength="30"
                            placeholder="Report Name"
                            onChange={(e) => {
                              setReport(e.target.value);
                              closeErrors();
                            }}
                          />
                          <ErrorMessage
                            error={"Lab report name is required"}
                            visible={showReportError}
                          />
                        </td>
                        <td valign="middle">
                          <CustomTextInput
                            type="file"
                            id="reportFile"
                            className="form-control mt-2"
                            name="reportFile"
                            accept="image/*, .pdf"
                            placeholder="Attach Report"
                            value={fileName}
                            onChange={(e) => {
                              setFileName(e.target.value);
                              closeErrors();
                              handleImage(e);
                            }}
                          />
                          <ErrorMessage
                            error={"Lab report file is required"}
                            visible={showFileError}
                          />
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn mt-2"
                            onClick={handleLabReport}
                          >
                            <span className="icofont icofont-plus"></span>
                            Add
                          </button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ErrorMessage
                    error={"Please add Files to list"}
                    visible={showAddError}
                  />
                </div>
                <div className="text-center mt-3">
                  <button
                    className="btn mt-2"
                    title="Submit Report"
                    onClick={handleSubmit}
                  >
                    <span className="icofont-save"></span>Submit Report
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default AddReport;
