import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import CustomTextInput from "./../component/common/CustomTextInput";
import ErrorMessage from "../component/common/ErrorMessage";
import ErrorLog from "../component/common/ErrorLog";
import preFunction from "../component/common/CommonFunction";

import doctorApi from "../api/doctorApi";

import redright from "../assests/svg/redright.svg";
import greyright from "../assests/png/greyright.png";
import grayleft from "../assests/svg/greyleft.svg";
import redleft from "../assests/png/redleft.png";
import blueright from "../assests/svg/blueright.svg";

import PageHeader from "./pageHeader";
import string from "../string";
import storage from "../auth/storage";
import PageFooter from "../pages/PageFooter";

const prefixerror = "Welcome";
const Welcome = () => {
  //#region const
  const [load, setLoad] = useState(false);
  const [isTimeDet, setIsTimeDet] = useState(true);
  const [isConvenienceDet, setIsConvenienceDet] = useState(false);
  const [isCostDet, setIsCostDet] = useState(false);
  const [isSpecialistDet, setIsSpecialistDet] = useState(false);
  const [demoEmail, setDemoEmail] = useState("");
  const [demoRequestError, setDemoRequestError] = useState(false);
  const [index, setIndex] = useState(0);
  const [testimony, setTestimony] = useState([]);
  const [otpReceived, setOtpReceived] = useState("");
  const [otpEntered, setOtpEntered] = useState("");
  const [otpShow, setOtpShow] = useState(false);
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [message, setMessage] = useState("");
  const [leadSource, setLeadSource] = useState("");

  const navigate = useNavigate();
  //#endregion

  const handleTestimony = (val) => {
    if (val == 1) {
      if (index < testimony.length - 1) setIndex(index + 1);
    } else {
      if (index > 0) setIndex(index - 1);
    }
  };

  const checkLogin = async () => {
    const doctorID = await storage.getID();
    const role = await storage.getRole();
    if (doctorID) {
      if (role == "SuperAdmin") navigate("/doctorlist");
      else navigate("/doctordashboard");
      return;
    }
    try {
      const res = await doctorApi.getTestimony();
      console.log("testi res----", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "checkLogin " + "getTestimony",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime."
        );
        return;
      }
      setTestimony(res.data.message);
    } catch (error) {
      ErrorLog(
        prefixerror + "checkLogin",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleDemoEmail = async () => {
    let err = false;
    if (demoEmail == "") {
      err = true;
    } else if (
      !preFunction.isEmail(demoEmail) &&
      !preFunction.isMobile(demoEmail)
    ) {
      err = true;
    }

    if (err) {
      setMessage("Please enter valid email / mobile number");
      setDemoRequestError(true);
      setTimeout(() => {
        setDemoRequestError(false);
      }, 3000);
      return;
    }
    setLoad(true);
    let otpReceive = "";
    try {
      let email = "",
        mob = "";
      if (preFunction.isEmail(demoEmail)) email = demoEmail;
      else mob = demoEmail;

      const checkDemoRes = await doctorApi.checkDemoRequest(demoEmail);
      console.log("checkDemoRes---", checkDemoRes);
      if (checkDemoRes.data.data.length > 0) {
        setMessage("You are already requeted. Please wait for the demo.");
        setDemoRequestError(true);
        setTimeout(() => {
          setDemoRequestError(false);
        }, 3000);
        setLoad(false);
        return;
      }

      if (email != "") {
        setEmail(email);
        const emailRes = await doctorApi.sendDemoEmail(email);
        console.log("emailRes----------", emailRes);
        if (!emailRes.ok) {
          ErrorLog(
            prefixerror + "Welcome " + "sendDemoEmail",
            JSON.stringify(emailRes),
            "We are facing some issues.Please try again after sometime.",
            "email:" + email
          );
          setLoad(false);
          return;
        }
        otpReceive = emailRes.data.message.otp;
      } else {
        setMob(mob);
        const otpRes = await doctorApi.sendDemoOtp(mob);
        console.log("otpRes----------", otpRes);

        if (!otpRes.ok) {
          ErrorLog(
            prefixerror + "Welcome " + "sendDemoOtp",
            JSON.stringify(otpRes),
            "We are facing some issues.Please try again after sometime.",
            "mob:" + mob
          );
          setLoad(false);
          return;
        }

        otpReceive = otpRes.data.message.otp;
      }
    } catch (error) {
      ErrorLog(
        prefixerror + "Welcome ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    setLoad(false);
    setOtpReceived(otpReceive);
    setOtpShow(true);
    return;
  };

  const handleOtp = async () => {
    console.log("otpEntered----", otpEntered, "otpReceived---", otpReceived);
    if (otpEntered == "") {
      setMessage("Please enter valid OTP");
      setDemoRequestError(true);
      setTimeout(() => {
        setDemoRequestError(false);
      }, 3000);
      return;
    }
    if (otpReceived != otpEntered) {
      setMessage("Please enter valid OTP");
      setDemoRequestError(true);
      setTimeout(() => {
        setDemoRequestError(false);
      }, 3000);
      return;
    }
    setLoad(true);
    try {
      const demoRes = await doctorApi.postDemoRequest(email, mob);
      console.log("demoRes---", demoRes);
      if (!demoRes.ok) {
        setMessage("You are already requeted. Please wait for the demo.");
        setDemoRequestError(true);
        setTimeout(() => {
          setDemoRequestError(false);
        }, 3000);
        return;
      }

      const res = await doctorApi.sendDemoMail(demoEmail);
      console.log("res---", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "Welcome " + "handleDemoEmail",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "demoEmail:" + demoEmail
        );
        setLoad(false);
        return;
      }
    } catch (error) {
      ErrorLog(
        prefixerror + "Welcome ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
    setLoad(false);
    navigate("/demo");
  };

  const parseParams = async (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);
    let obj = "";
    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj += params.getAll(key);
      } else {
        obj += params.get(key);
      }
      obj += "|";
    }
    console.log("leadsource---", obj);
    setLeadSource(obj);
    await storage.storeLeadSource(obj);
  };

  useEffect(() => {
    checkLogin();
    parseParams(window.location.search);
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row no-gutters servebox">
            <div className="col-lg-7">
              <div className="bluebgtext">
                Need to serve online consultation?
              </div>
              <div className="bluebgbluetext">
                You reached the{" "}
                <span
                  style={{ borderBottom: "8px solid rgb(54, 176, 243, 0.5)" }}
                >
                  right place.
                </span>
              </div>
              <div className="bluebgbox">
                <div className="demoheadingtext">Get your free Demo</div>
                <div className="mailbox p-0 row mt-2">
                  <div className="col-lg-7 p-0">
                    <CustomTextInput
                      className="emailtextinput"
                      type="text"
                      maxlength="140"
                      placeholder="Your Email / Mobile No"
                      value={demoEmail}
                      onChange={(e) => {
                        setDemoEmail(e.target.value);
                      }}
                      onKeyUp={(e) => e.keyCode == 13 && handleDemoEmail()}
                      disabled={otpShow ? "disabled" : ""}
                    />

                    <div className="emailtextinputdetail">
                      Your details will not be disclosed to external entities.
                    </div>
                  </div>
                  {!otpShow ? (
                    <div className="col-lg-5 p-0">
                      <button
                        className="welcomebut"
                        onClick={(e) => handleDemoEmail()}
                      >
                        Get My Demo OTP
                      </button>
                    </div>
                  ) : null}
                </div>
                {otpShow ? (
                  <div className="mailbox p-0 row mt-2">
                    <div className="col-lg-7 p-0">
                      <CustomTextInput
                        className="emailtextinput"
                        type="text"
                        placeholder="OTP"
                        value={otpEntered}
                        maxlength="4"
                        onChange={(e) => {
                          setOtpEntered(e.target.value);
                        }}
                        onKeyUp={(e) => e.keyCode == 13 && handleOtp()}
                      />
                    </div>
                    <div className="col-lg-5 p-0">
                      <button
                        className="welcomebut"
                        onClick={(e) => handleOtp()}
                      >
                        Get My Demo
                      </button>
                    </div>
                  </div>
                ) : null}
                <ErrorMessage error={message} visible={demoRequestError} />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="toptext">
                <img
                  src={require("../assests/png/welcomedoctorbg.png")}
                  alt={string.websiteName + " Home"}
                />
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6 p-0 mt-5">
              <img
                src={require("../assests/png/welcomepgimg.png")}
                alt={string.websiteName + " Home"}
                width="100%"
              />
            </div>
            <div className="col-lg-6 mt-5 welcomemyclinictext">
              <div className="welcomesideheadtext">
                {string.websiteName}
                <span className="stethoscope">
                  <img
                    src={require("../assests/png/stethoscope.png")}
                    alt={string.websiteName + " Home"}
                    width="60"
                  />
                </span>
              </div>
              <p className="ptext mt-3">
                <b>{string.websiteName}</b> is an online platform that
                facilitates connections between healthcare professionals and
                patients, enabling healthcare services to be provided remotely
                from the comfort of the professional's home.
              </p>
              <p className="ptext">
                {string.websiteName} provides the features of appointment
                booking, user management, patient health record, treatment
                template, AI assistants, and other features to facilitate
                streamlined healthcare service delivery.
              </p>
              <div className="footerspace">
                <a
                  className="downbutton"
                  href="https://play.google.com/store/apps/details?id=com.smv.myclinic"
                  rel="noreferrer"
                  target="_blank"
                >
                  Download App &nbsp;&nbsp;
                  <img
                    src={require("../assests/png/arrow_right_alt.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="benefitsheading footerspace">
              Unlock Amazing Benefits with {string.websiteName}!
            </div>
            <div className="row">
              <div className="col-5"></div>
              <div className="col-2 blueunderline"></div>
              <div className="col-5"></div>
            </div>
          </div>
          <div
            id="benefitId"
            className="row no-gutters mt-4"
            style={{ overflowX: "auto" }}
          >
            <table
              cellPadding="10"
              style={{ backgroundColor: "inherit", border: "none" }}
            >
              <tr>
                <td className="leftBlue">
                  <div style={{ marginTop: "145px" }}>
                    <img
                      className="pointer"
                      src={require("../assests/png/greyleftblue.png")}
                      alt={string.websiteName + " Home"}
                      onClick={(e) => {
                        document.getElementById("benefitId").scrollLeft -= 250;
                      }}
                    />
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px" }}>
                    <div className="p-3 welcomeimgborder">
                      <img
                        src={require("../assests/png/welcomeappointment.png")}
                        alt={string.websiteName + " Home"}
                        width="100%"
                      />
                      <div className="p-2">
                        <div className="subhead">Appointment</div>
                        <p className="subcontent">
                          Makes appointment management simple! You can view
                          upcoming appointments, book new ones for in-person or
                          online, and easily reschedule or cancel appointments
                          as needed.
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px" }}>
                    <div className="p-3 welcomeimgborder">
                      <img
                        src={require("../assests/png/welcomephr.png")}
                        alt={string.websiteName + " Home"}
                        width="100%"
                      />
                      <div className="p-2">
                        <div className="subhead">Patient Health Record</div>
                        <p className="subcontent">
                          Our app Patient Health Record module makes it easy to
                          view and update a patient's health information quickly
                          and efficiently. Try it today and see the difference
                          it can make for your practice!
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px" }}>
                    <div className="p-3 welcomeimgborder">
                      <img
                        src={require("../assests/png/welcomeai.png")}
                        width="100%"
                        alt={string.websiteName + " Home"}
                      />
                      <div className="p-2">
                        <div className="subhead">AI Assistant</div>
                        <p className="subcontent">
                          It is an AI-powered chatbot module. It leverages a
                          large language model to provide doctors with accurate
                          and quick medical advice. Try it today to see the
                          difference it can make for your practice!
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px" }}>
                    <div className="p-3 welcomeimgborder">
                      <img
                        src={require("../assests/png/welcomett.png")}
                        alt={string.websiteName + " Home"}
                        width="100%"
                      />
                      <div className="p-2">
                        <div className="subhead">Treatment Template</div>
                        <p className="subcontent">
                          The treatment Template module of our clinic management
                          app lets users manage their clinic's predefined
                          treatment plans easily. The module helps streamline
                          your treatment.
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <div style={{ width: "300px" }}>
                    <div className="p-3 welcomeimgborder">
                      <img
                        src={require("../assests/png/welcomeuser.png")}
                        alt={string.websiteName + " Home"}
                        width="100%"
                      />
                      <div className="p-2">
                        <div className="subhead">User Management</div>
                        <p className="subcontent">
                          This feature enables you to view and manage users and
                          their credentials effectively. You can add, edit,
                          delete, and search for users and more.
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="rightblue">
                  <div style={{ marginTop: "145px" }}>
                    <img
                      className="pointer"
                      src={blueright}
                      alt={string.websiteName + " Home"}
                      onClick={(e) => {
                        document.getElementById("benefitId").scrollLeft += 250;
                      }}
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
          {testimony.length > 0 ? (
            <div className="row no-gutters testimonybg mt-5">
              <table className="mt-5" style={{ backgroundColor: "#fdf5f9" }}>
                <tr>
                  <td>
                    <div className="testimonytext">{testimony[index].text}</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <hr className="horizontalline" />
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <b className="testimonyauthortext">
                      {testimony[index].author}
                    </b>
                  </td>
                </tr>
                <tr>
                  <td align="center">
                    <div className="mt-4 mb-5">
                      {index == 0 ? (
                        <>
                          <img
                            src={grayleft}
                            className="grayleft"
                            alt={string.websiteName + " Home"}
                          />
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      ) : (
                        <>
                          <a
                            href="javascript:void(0)"
                            onClick={(e) => handleTestimony(-1)}
                          >
                            <img
                              src={redleft}
                              className="grayleft"
                              alt={string.websiteName + " Home"}
                            />
                          </a>
                          &nbsp;&nbsp;&nbsp;&nbsp;
                        </>
                      )}
                      {index == testimony.length - 1 ? (
                        <img
                          src={greyright}
                          className="grayleft"
                          alt={string.websiteName + " Home"}
                        />
                      ) : (
                        <a
                          href="javascript:void(0)"
                          onClick={(e) => handleTestimony(1)}
                        >
                          <img
                            src={redright}
                            className="grayleft"
                            alt={string.websiteName + " Home"}
                          />
                        </a>
                      )}
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          ) : null}
          <div className="row no-gutters">
            <div className="benefitsheading mt-5">Feeling Unwell?</div>
            <div className="row">
              <div className="col-5"></div>
              <div className="col-2 blueunderline"></div>
              <div className="col-5"></div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="col-lg-6 mt-5">
              <img
                src={require("../assests/png/welcomemyhealthbg.png")}
                alt={string.websiteName + " Home"}
                width="100%"
              />
            </div>
            <div className="col-lg-6 mt-5 welcomemyclinictext">
              <div className="welcomesideheadtext">
                {string.patientAppName}
                <span className="stethoscope">
                  <img
                    src={require("../assests/png/personal_injury.png")}
                    alt={string.websiteName + " Home"}
                    width="60"
                  />
                </span>
              </div>
              <p className="ptext mt-3">
                <b>{string.patientAppName}</b> is designed to empower patients
                to take control of their healthcare experience. It enables them
                to manage their appointments and consultations from the comfort
                of their own homes, enabling them to check their medical history
                and records, receive reminders for upcoming appointments.
              </p>
              <p className="ptext">
                Furthermore, {string.patientAppName} provides users with
                valuable information about doctors and specialties, as well as
                health tips and advice.
              </p>
              <div className="mt-5">
                <a
                  href="https://play.google.com/store/apps/details?id=com.smv.myhealth"
                  target="_blank"
                  rel="noreferrer"
                  className="downbutton"
                >
                  Download App &nbsp;&nbsp;
                  <img
                    src={require("../assests/png/arrow_right_alt.png")}
                    alt={string.websiteName + " Home"}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="row no-gutters">
            <div className="benefitsheading footerspace">
              Benefits to our patients.
            </div>
            <div className="row">
              <div className="col-5"></div>
              <div className="col-2 blueunderline"></div>
              <div className="col-5"></div>
            </div>
          </div>
          <div className="row no-gutters footerspace">
            <div className="col-lg-1"></div>
            <div className="col-lg-10">
              <div className="row no-gutters">
                <div className="col-md-6 col-lg-3">
                  <div
                    className={
                      isTimeDet ? "benefitmenubgselected" : "benefitmenubg"
                    }
                    onClick={(e) => {
                      setIsTimeDet(true);
                      setIsConvenienceDet(false);
                      setIsCostDet(false);
                      setIsSpecialistDet(false);
                    }}
                  >
                    <div>
                      <img
                        src={require("../assests/png/time.png")}
                        alt={string.websiteName + " Home"}
                      />
                      <div
                        className={
                          isTimeDet
                            ? "benefitmenubgtextselected"
                            : "benefitmenubgtext"
                        }
                      >
                        Time Saving
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className={
                      isConvenienceDet
                        ? "benefitmenubgselected"
                        : "benefitmenubg"
                    }
                    onClick={(e) => {
                      setIsTimeDet(false);
                      setIsConvenienceDet(true);
                      setIsCostDet(false);
                      setIsSpecialistDet(false);
                    }}
                  >
                    <div>
                      <img
                        src={require("../assests/png/convenience.png")}
                        alt={string.websiteName + " Home"}
                      />
                      <div
                        className={
                          isConvenienceDet
                            ? "benefitmenubgtextselected"
                            : "benefitmenubgtext"
                        }
                      >
                        Convenience
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className={
                      isCostDet ? "benefitmenubgselected" : "benefitmenubg"
                    }
                    onClick={(e) => {
                      setIsTimeDet(false);
                      setIsConvenienceDet(false);
                      setIsCostDet(true);
                      setIsSpecialistDet(false);
                    }}
                  >
                    <div>
                      <img
                        src={require("../assests/png/cost.png")}
                        alt={string.websiteName + " Home"}
                      />
                      <div
                        className={
                          isCostDet
                            ? "benefitmenubgtextselected"
                            : "benefitmenubgtext"
                        }
                      >
                        Cost Effective
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3">
                  <div
                    className={
                      isSpecialistDet
                        ? "benefitmenubgselected"
                        : "benefitmenubg"
                    }
                    onClick={(e) => {
                      setIsTimeDet(false);
                      setIsConvenienceDet(false);
                      setIsCostDet(false);
                      setIsSpecialistDet(true);
                    }}
                  >
                    <div>
                      <img
                        src={require("../assests/png/special.png")}
                        alt={string.websiteName + " Home"}
                      />
                      <div
                        className={
                          isSpecialistDet
                            ? "benefitmenubgtextselected"
                            : "benefitmenubgtext"
                        }
                      >
                        Specialists
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-1"></div>
          </div>
          {isTimeDet ? (
            <div className="row">
              <div className="sideheadtext">Time Saving</div>
              <p className="ptext">
                The app allows patients to schedule their appointments
                conveniently and efficiently, without the need for manual
                coordination.
              </p>
              <p className="ptext">
                It provides a user-friendly interface where patients can select
                available time slots based on their preferences and the
                availability of healthcare providers.
              </p>
              <p className="ptext">
                In case of online consultaion, instead of physically waiting at
                a clinic or hospital, patients can join a virtual waiting room
                within the app. Reducing wait times and allowing them to utilize
                their time more effectively.
              </p>
            </div>
          ) : null}
          {isConvenienceDet ? (
            <div className="row">
              <div className="sideheadtext">Convenience</div>
              <p className="ptext">
                Avoid long waiting times at the clinic by implementing a more
                efficient scheduling system.
              </p>
              <p className="ptext">
                You can consult with a doctor from your home as a result of
                advancements in technology and telemedicine services. Many
                medical practices now offer virtual visits. Avoid long waiting
                times at the clinic by implementing a more efficient scheduling
                system.
              </p>
              <p className="ptext">
                Utilize technological advancements and telemedicine services to
                provide convenient access to healthcare for your patients,
                allowing them to consult with a doctor from the comfort of their
                own home.
              </p>
            </div>
          ) : null}
          {isCostDet ? (
            <div className="row">
              <div className="sideheadtext">Cost Effective</div>
              <p className="ptext">
                The app outlines the fees or charges associated with different
                types of consultations or services before scheduling an
                appointment.
              </p>
              <p className="ptext">
                The app offers diverse payment options to accommodate different
                patients' preferences and financial situations. This may include
                accepting various payment methods such as credit/debit cards,
                digital wallets, or even health insurance claims for covered
                services.
              </p>
              <p className="ptext">
                Instead of requiring additional in-person appointments for
                follow-up consultations, the app enables healthcare providers to
                conduct virtual follow-ups. This reduces the need for patients
                to travel and incur additional costs, making follow-up care more
                convenient and cost-effective.
              </p>
            </div>
          ) : null}
          {isSpecialistDet ? (
            <div className="row">
              <div className="sideheadtext">Specialists</div>
              <p className="ptext">
                The app categorized healthcare providers based on their areas of
                specialization. Patients easily identify the appropriate
                specialists.
              </p>
              <p className="ptext">
                The app includes search filters such as medical specialty,
                sub-specialty, location, availability, and patient reviews.
                These filters help patients narrow down their search and find
                the most suitable specialists based on their preferences and
                requirements.
              </p>
              <p className="ptext">
                The app allows patients to schedule appointments with
                specialists directly from within the app. It provides
                availability information, allowing patients to select a
                convenient time slot for the consultation.
              </p>
            </div>
          ) : null}
          <div className="row footerspace">
            <div className="col-md-9">
              <div className="sideheadtext">Secure</div>
              <p className="ptext">
                <b>{string.websiteName}</b> uses 256-bit encryption, is a
                powerful security technology that uses a long key to protect
                data as it travels between a server and a client. It is used in
                many security protocols, including AES and TLS/SSL.
              </p>
              <p className="ptext">
                <b>256 bit SSL encryption</b> is actually considered the
                standard when it comes to website/app security. But when it
                comes to understanding 256 bit security in terms of its
                certificate, hashing algorithm, and keys, there's a surprising
                amount you need to know. It's not just about the certificate
                itself — that's only part of the equation when it comes to
                website/app security.
              </p>
            </div>
            <div className="col-md-3" style={{ textAlign: "center" }}>
              <img
                className="securelock"
                src={require("../assests/png/securelock.png")}
                alt={string.websiteName + " Home"}
              />
            </div>
          </div>
        </div>
      </div>
      <PageFooter />
    </>
  );
};

export default Welcome;
