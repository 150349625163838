import React, { useEffect, useState } from "react";
import Lottie from "lottie-react";
import $ from "jquery";

// import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
// import ErrorMessage from "../component/common/ErrorMessage";
import preFunction from "../component/common/CommonFunction";
// import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import ChatBotJson from "../assests/json/chat-bot.json";
// import Loader from "../assests/json/chat-loader.json";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
import "../chatbotstyle.css";

const prefixerror = "SmartAssistant";

function SmartAssistant() {
  //#region const
  // const [conversationArray, setConversationArray] = useState([]);
  const [question, setQuestion] = useState("");
  const [showQuestion, setShowQuestion] = useState(false);
  const [load, setLoad] = useState(false);
  const [answerLoad, setAnswerLoad] = useState(false);
  const [showError, setShowError] = useState(false);
  const [promptQuestion, setPromptQuestion] = useState("");
  // let chats = [];
  var typeElement =
    '<li class="chat-bubble"><div class="typing"><div class="dot"></div><div class="dot"></div><div class="dot"></div></div></li>';
  $("#user-input").keyup(function (event) {
    var userInput = document.getElementById("user-input").value;
    if (event.keyCode === 13 && userInput != "") {
      $("#send-message").click();
    }
  });

  //#endregion

  const firstCallOpenAI = async () => {
    // const doctorId = await storage.getID();
    let promptqn =
      "\nHuman: Assume as you are a Indian doctor and reply my question\nAI: Hello, how can I help you? I am an Indian doctor and I will be happy to answer any questions you have.\n";
    setPromptQuestion(promptqn);
  };

  const handleSubmit = async () => {
    try {
      setShowError(false);
      if (question == "") {
        setShowQuestion(true);
        document.getElementById("question").focus();
        return;
      } else {
        setShowQuestion(false);
      }

      setAnswerLoad(true);

      // const checkResp = await doctorApi.checkQnOpenAIApi(question);
      // console.log("checkResp---", checkResp);
      // if (!checkResp.ok) {
      //   ErrorLog(
      //     prefixerror + " handleSubmit " + "checkQnOpenAIApi",
      //     JSON.stringify(checkResp),
      //     "We are facing some issues.Please try again after sometime.",
      //     "question:" + question
      //   );
      //   return;
      // }
      // if (checkResp.data.results[0].flagged == true) {
      //   setShowError(true);
      // } else {
      //   let logAns = "";
      //   let promptqn = promptQuestion + "Human: " + question.trim() + "\nAI:";
      // const AIResponse = await doctorApi.askOpenAIApi(promptqn);
      // console.log("AIResponse---", AIResponse);
      // if (!AIResponse.ok) {
      //   ErrorLog(
      //     prefixerror + " handleSubmit " + "checkQnOpenAIApi",
      //     JSON.stringify(AIResponse),
      //     "We are facing some issues.Please try again after sometime.",
      //     "promptqn:" + promptqn
      //   );
      //   return;
      // }

      // if (AIResponse.data.choices.length > 0) {
      //   let chatresp = AIResponse.data.choices[0].text.replace("\n", "").trim();
      //   const temp =
      //     AIResponse.data.choices[0].text == ""
      //       ? "I'm sorry, I'm not sure what you're asking. Could you please provide more context or ask a specific question?"
      //       : chatresp;
      //   promptqn += temp + "\n";
      // }
      // setPromptQuestion(promptqn);
      // if (AIResponse.data.choices.length > 0) {
      //   let arr = conversationArray;
      //   logAns = AIResponse.data.choices[0].text;
      //   let ans =
      //     AIResponse.data.choices[0].text == ""
      //       ? "AI: I'm sorry, I'm not sure what you're asking. Could you please provide more context or ask a specific question?"
      //       : AIResponse.data.choices[0].text.replace("\n", "");
      //   // ans = ans.substring(ans.indexOf(":") + 1);
      //   console.log("ans-------", ans);
      //   var myObject = {
      //     question: question,
      //     answer: ans,
      //   };
      //   arr.push(myObject);
      //   setConversationArray(arr);
      //   setQuestion("");
      //   window.scrollTo(0, document.body.scrollHeight);
      //   document.getElementById("question").focus();
      //   // }

      //   const logRes = await doctorApi.logSmartAssistant(question, logAns);
      //   if (!logRes.ok) {
      //     ErrorLog(
      //       prefixerror + " handleSubmit " + "logSmartAssistant",
      //       JSON.stringify(logRes),
      //       "We are facing some issues.Please try again after sometime.",
      //       "question:" + question + "logAns:" + logAns
      //     );
      //     return;
      //   }
      //   console.log("logRes---", logRes);
      // }
      let chats = [];
      appendMessage(question);
      document.getElementById("user-input").value = "";
      $("#chat-messages").append(typeElement);

      //       https://aiassist.indbazaar.com/api/myclinic

      // {
      //   "content":"list of cource",
      //   "chats":[]
      // }
      // Live : https://aichatbot.indbazaar.com/
      console.log("question--", question);
      fetch("https://aichatbot.indbazaar.com/api/myclinic", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Credentials": "true",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "GET, PUT, POST",
          "Access-Control-Allow-Headers":
            "Origin, X-Requested-With, Content-Type, Accept",
        },
        body: JSON.stringify({
          content: question,
          chats,
        }),
      })
        .then(async (response) => {
          console.log("response---", response);
          let tempMessage = "";

          let reader = response.body.getReader();
          let decoder = new TextDecoder("utf-8");
          const chunks = [];
          var chatMessages = document.getElementById("chat-messages");
          var messageElement = document.createElement("li");
          var preTag = document.createElement("pre");
          messageElement.appendChild(preTag);
          $(".chat-bubble").remove();
          chatMessages.appendChild(messageElement);
          reader.read().then(function processText({ done, value }) {
            if (done) {
              setAnswerLoad(false);
              $("#user-input").focus();
              return;
            }
            const msg = decoder.decode(value);

            tempMessage += msg;
            console.log("msg---", tempMessage);
            preTag.innerHTML = tempMessage;
            chatMessages.scrollTop = chatMessages.scrollHeight;
            return reader.read().then(processText);
          });
        })
        .catch((error) => console.error("Error:", error));
      setQuestion("");
    } catch (error) {
      setLoad(false);
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const appendMessage = (message) => {
    var chatMessages = document.getElementById("chat-messages");
    var messageElement = document.createElement("li");
    messageElement.innerHTML = message;
    chatMessages.appendChild(messageElement);
    chatMessages.scrollTop = chatMessages.scrollHeight;
  };

  useEffect(() => {
    // firstCallOpenAI();
    document.getElementById("question") &&
      document.getElementById("question").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row ">
                  <div className="row no-gutters mb-1">
                    <table
                      style={{
                        backgroundColor: "inherit",
                        borderBottom: "0px",
                      }}
                    >
                      <tr>
                        <td width={"5%"}>
                          <div
                            style={{
                              width: "60px",
                              height: "60px",
                            }}
                          >
                            <Lottie animationData={ChatBotJson} loop={true} />
                          </div>
                        </td>
                        <td style={{ verticalAlign: "middle" }}>
                          <h1 className="mt-3">Smart Assistant</h1>
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div className="row col-lg-7 wrapper">
                    <div>
                      <ul id="chat-messages"></ul>
                    </div>
                    <div class="chat__conversation-panel">
                      <div class="chat__conversation-panel__container">
                        <input
                          class="chat__conversation-panel__input panel-item"
                          id="user-input"
                          onChange={(e) => setQuestion(e.target.value)}
                          placeholder="Type a message..."
                          onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                        />
                        {answerLoad ? (
                          <img
                            src={require("../assests/png/disble_send.png")}
                            alt="send_message"
                            id="disabledbtn"
                          />
                        ) : (
                          <img
                            src={require("../assests/png/Sendbutton.png")}
                            alt="send_message"
                            id="btn_img"
                            style={{ cursor: "pointer " }}
                            onClick={(e) => {
                              handleSubmit();
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  {/* <div className="row">
                    {conversationArray.length > 0 && (
                      <div className="mb-2">
                        {conversationArray.map((item, index) => {
                          return (
                            <>
                              <div className="row no-gutters" key={index}>
                                <label className="col-lg-1 control-label">
                                  Question :
                                </label>
                                <div className="col-lg-10 pt-2">
                                  {item.question}
                                </div>
                              </div>
                              <div className="row no-gutters">
                                <label className="col-lg-1 control-label">
                                  Answer :
                                </label>
                                <div className="col-lg-10 pt-2">
                                  <pre>{item.answer.trim()}</pre>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    )}
                    <div className="row">
                      <label className="control-label col-lg-1 ms-1">
                        <span className="star">*</span> Question :
                      </label>
                      <div className="col-lg-5 ps-2">
                        <CustomTextInput
                          type="text"
                          name="question"
                          maxlength="140"
                          id="question"
                          value={question}
                          className="form-control"
                          placeholder="Question"
                          onChange={(e) => {
                            setShowError(false);
                            setQuestion(e.target.value);
                          }}
                          onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                        />
                        <ErrorMessage
                          error={
                            "I am sorry,  I am not able to answer your question."
                          }
                          visible={showError}
                        />
                        <ErrorMessage
                          visible={showQuestion}
                          error="Please enter the Question"
                        ></ErrorMessage>
                      </div>
                      <div className="col-lg-2">
                        <button
                          type="submit"
                          className="btn smartButton"
                          onClick={handleSubmit}
                          title="Ask"
                        >
                          <span className="icofont-question"></span>Ask
                        </button>
                      </div>
                    </div>
                    <div className="row">
                      <label className="col-lg-1"></label>
                      <div className="col-5" style={{ width: "7%" }}>
                        {answerLoad && (
                          <Lottie
                            animationData={Loader}
                            loop={true}
                            style={{ marginLeft: "5px" }}
                          />
                        )}
                      </div>
                    </div>
                    <div className="row m-5"></div>
                  </div> */}
                </div>
                <div className="col-lg-7"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SmartAssistant;
