import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
import pen from "../assests/svg/pen.svg";
import string from "../string";
import { useNavigate } from "react-router-dom";
const prefixerror = "MedicineList";

function MedicineList() {
  //#region const
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [page, setPage] = useState(1);
  const [drugs, setMedi] = useState([]);
  const [medicineSel, setMedicineSel] = useState([]);
  const [addMedicineModal, setAddMedicineModal] = useState(false);
  const [searchString, setSearchString] = useState("[]");
  const [medicineNameToAdd, setMedicineNameToAdd] = useState("");
  const [genericNameToAdd, setGenericNameToAdd] = useState("");
  const [medicineNameError, setMedicineNameError] = useState(false);
  const [genericNameError, setGenericNameError] = useState(false);
  const [medicineSuccess, setMedicineSuccess] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [practitioner, setPractitioner] = useState();
  const [medicineId, setMedicineId] = useState();
  const [message, setMessage] = useState();
  const [showDeleteFailure, setShowDeleteFailure] = useState(false);
  const [role, setRole] = useState("");
  let sno = (page - 1) * pageSize + 1;
  //#endregion

  const handleMove = async (medicineId) => {
    try {
      setLoad(true);
      const editRes = await doctorApi.editMedicineOwner(medicineId);
      if (!editRes.ok) {
        ErrorLog(
          prefixerror + "handleMove " + "editMedicineOwner",
          JSON.stringify(editRes),
          "We are facing some issues.Please try again after sometime.",
          "medicineId:" + medicineId
        );
        return;
      }
      console.log("editRes---", editRes);
      setShowSuccess(true);
      setMessage("Medicine moved to global list successfully");
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      getMedicineList(0, `[["item_group", "=", "Drug"]]`);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + "handleMove ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleDeleteMedicine = async (name) => {
    try {
      setLoad(true);
      console.log("name--", name);
      const delRes = await doctorApi.deleteMedicine(name);
      console.log("delRes---", delRes);
      if (!delRes.ok) {
        ErrorLog(
          prefixerror + "handleDeleteMedicine " + "deleteMedicine",
          JSON.stringify(delRes),
          "We are facing some issues.Please try again after sometime.",
          "name:" + name
        );
        setShowDeleteFailure(true);
        setTimeout(() => {
          setShowDeleteFailure(false);
        }, 3000);
        setLoad(false);
        return;
      }

      setShowSuccess(true);
      setMessage("Medicine deleted successfully");
      getMedicineList(0, `[["item_group", "=", "Drug"]]`);
      setTimeout(() => {
        setShowSuccess(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleDeleteMedicine ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const getMedicineList = async (limitStart, searchString) => {
    const role = await storage.getRole();
    if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
      navigate("/");
      return;
    }
    try {
      setLoad(true);
      setSearchString(searchString);
      const pagesize = await storage.getPageSize();
      const practitioner = await storage.getID();
      setPractitioner(practitioner);
      setPageSize(pagesize);

      const oldUser = await storage.getOldUser();
      console.log("oldUser----", oldUser);
      console.log("page----", page);
      console.log("searchString----", searchString);
      const role = await storage.getRole();
      setRole(role);
      console.log("role---", role);
      let or_filters = "[]";
      if (role != "SuperAdmin") {
        or_filters = `[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]`;
      }
      const allRes = await doctorApi.getTotalCountByDocType(
        "Item",
        searchString,
        or_filters
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + "getMedicineList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString + "or_filters:" + or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);
      const medicineRes = await doctorApi.getMedicine(
        searchString,
        or_filters,
        limitStart,
        pageSize
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + "getMedicineList " + "getMedicine",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" +
            searchString +
            "or_filters:" +
            or_filters +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      console.log("medicineRes---", medicineRes);
      if (medicineRes.data.data.length > 0) {
        setData(medicineRes.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getMedicineList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const closeErrors = () => {
    setGenericNameError(false);
    setMedicineNameError(false);
    setMedicineSuccess(false);
    document.getElementById("genericName").className = " form-control";
    document.getElementById("medicineName").className = " form-control";
  };

  const handleMedicineAdd = async () => {
    try {
      closeErrors();
      let err = false;
      if (genericNameToAdd == "") {
        setGenericNameError(true);
        document.getElementById("genericName").className +=
          " form-control-error";
        document.getElementById("genericName").focus();
        err = true;
      }
      if (medicineNameToAdd == "") {
        setMedicineNameError(true);
        document.getElementById("medicineName").className +=
          " form-control-error";
        document.getElementById("medicineName").focus();
        err = true;
      }
      if (err) {
        return;
      }
      console.log("medicineId---", medicineId);
      let Res;
      if (medicineId && medicineId != "") {
        Res = await doctorApi.editMedicine(
          medicineId,
          medicineNameToAdd,
          genericNameToAdd
        );
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleMedicineAdd " + "editMedicine",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "medicineId:" +
              medicineId +
              "medicineNameToAdd:" +
              medicineNameToAdd +
              "genericNameToAdd:" +
              genericNameToAdd
          );
          return;
        }
        setMessage("Medicine updated successfully");
      } else {
        Res = await doctorApi.createMedicine(
          medicineNameToAdd,
          genericNameToAdd
        );
        if (!Res.ok) {
          ErrorLog(
            prefixerror + "handleMedicineAdd " + "editMedicine",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "medicineNameToAdd:" +
              medicineNameToAdd +
              "genericNameToAdd:" +
              genericNameToAdd
          );
          return;
        }
        setMessage("Medicine added successfully");
      }
      console.log("Res---", Res);
      setMedicineSuccess(true);
      getMedicineList(0, `[["item_group", "=", "Drug"]]`);
      setTimeout(() => {
        setMedicineId("");
        setMedicineNameToAdd("");
        setGenericNameToAdd("");
        setAddMedicineModal(false);
        setMedicineSuccess(false);
      }, 3000);
    } catch (error) {
      ErrorLog(
        prefixerror + "handleMedicineAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handlePageChange = (page) => {
    setPage(page);
    console.log("page---", page);
    const lmtst = (page - 1) * pageSize;
    console.log("lmtst---", lmtst);
    getMedicineList(lmtst, searchString);
    setCurrentPage(page);
  };

  const handleSelectMedicine = (e) => {
    console.log("e---", e);
    if (e && e.length > 0) {
      getMedicineList(
        0,
        `[["item_group", "=", "Drug"], ["name", "=", "${e[0].name}"]]`
      );
      setMedicineSel(e);
    } else {
      setMedicineSel();
    }
    setMedi([]);
  };

  const handleSearchMedicine = async (value) => {
    try {
      console.log("value---", value);
      // setLoad(true);
      setMedi([]);
      if (value.length > 2) {
        const res = await doctorApi.searchMedicine(practitioner, value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchMedicine " + "searchMedicine",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "practitioner:" + practitioner + "value:" + value
          );
          return;
        }
        console.log("searchres--", res);
        if (res.data.data.length > 0) {
          setMedi(res.data.data);
        }
      } else if (value == "" && value.length == 0) {
        console.log("llllllllllll");
        getMedicineList(0, `[["item_group", "=", "Drug"]]`);
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSearchMedicine ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getMedicineList(0, `[["item_group", "=", "Drug"]]`);
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Medicine List</h1>
                </div>
                <div className="row form-group">
                  <div className="col-lg-4">
                    <Multiselect
                      name="medicine"
                      id="medicine"
                      options={drugs}
                      placeholder={"Medicine"}
                      showArrow={true}
                      avoidHighlightFirstOption={true}
                      onSelect={handleSelectMedicine}
                      onRemove={(e) => {
                        getMedicineList(0, `[["item_group", "=", "Drug"]]`);
                        return;
                      }}
                      selectedValues={medicineSel}
                      displayValue="item_name"
                      selectionLimit={1}
                      customCloseIcon={" "}
                      hidePlaceholder={true}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      emptyRecordMsg={
                        document.getElementById("medicine_input") &&
                        document.getElementById("medicine_input").value.length >
                          2 ? (
                          <div className="notFoundAdd">
                            Do you want to add?&nbsp;&nbsp;&nbsp;
                            <button
                              className="btn-2"
                              title="Add"
                              onClick={(e) => {
                                setAddMedicineModal(true);
                              }}
                            >
                              <span
                                className="icofont icofont-plus mr-1"
                                style={{ fontSize: "1em" }}
                              ></span>
                              Add
                            </button>
                          </div>
                        ) : (
                          <div className="notFoundSearch">Type to search</div>
                        )
                      }
                      onSearch={(e) => {
                        handleSearchMedicine(
                          document.getElementById("medicine_input").value
                        );
                        setMedicineNameToAdd(
                          document.getElementById("medicine_input").value
                        );
                        handleSelectMedicine();
                      }}
                    />
                  </div>
                </div>
                <ShowMessage view={showSuccess} Message={message} />
                <ErrorMessage
                  error={
                    "Couldn't delete because its already prescribed to a patient"
                  }
                  visible={showDeleteFailure}
                />
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} medicine identified.
                  </div>
                  {data.length > 0 && (
                    <div id="report">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th width="20%">Medicine Name</th>
                              <th>Generic Name</th>
                              {role == "SuperAdmin" && (
                                <>
                                  <th width="12%">Created By</th>
                                  <th width="12%">Move to Global list</th>
                                </>
                              )}
                              <th width="5%"> Edit</th>
                              <th width="5%">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{sno++}</td>
                                  <td className="nowrapWhiteSpace">
                                    {item.item_name}
                                  </td>
                                  <td className="nowrapWhiteSpace">
                                    {preFunction.capitalizeFirst(
                                      item.generic_name
                                    )}
                                  </td>
                                  {role == "SuperAdmin" && (
                                    <>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail
                                          ? item.owner
                                          : ""}
                                      </td>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail && (
                                          <a
                                            onClick={(e) => {
                                              handleMove(item.name);
                                            }}
                                          >
                                            Move
                                          </a>
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          setMedicineId(item.name);
                                          setMedicineNameToAdd(item.item_name);
                                          setGenericNameToAdd(
                                            item.generic_name
                                          );
                                          setAddMedicineModal(true);
                                          // closeErrors();
                                        }}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Edit
                                      </span>
                                    )}
                                  </td>
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          handleDeleteMedicine(item.name);
                                          setMedicineSel(0);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <table
                          style={{
                            borderBottom: 0,
                            backgroundColor: "#f6f4f4",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-4">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  )}
                </div>
                <Modal show={addMedicineModal}>
                  <Modal.Header>
                    <Modal.Title>Add Medicine</Modal.Title>
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        setAddMedicineModal(false);
                        getMedicineList(0, `[["item_group", "=", "Drug"]]`);
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body style={{ width: "100%" }}>
                    <div id="modelElement">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-4">
                          <span className="star">*</span> Medicine Name:
                        </label>
                        <div className="col-lg-6">
                          <CustomTextInput
                            type="text"
                            name="medicineName"
                            id="medicineName"
                            className="form-control"
                            value={medicineNameToAdd}
                            maxlength="140"
                            onChange={(e) => {
                              setGenericNameError(false);
                              setMedicineNameError(false);
                              setMedicineNameToAdd(e.target.value);
                            }}
                            placeholder="Medicine Name"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleMedicineAdd()
                            }
                          />
                          <ErrorMessage
                            error={"Please enter the Medicine Name"}
                            visible={medicineNameError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-4">
                          <span className="star">*</span> Generic Name :
                        </label>
                        <div className="col-lg-6">
                          <CustomTextInput
                            type="text"
                            name="genericName"
                            id="genericName"
                            className="form-control"
                            value={genericNameToAdd}
                            maxlength="140"
                            onChange={(e) => {
                              setGenericNameError(false);
                              setMedicineNameError(false);
                              setGenericNameToAdd(e.target.value);
                            }}
                            placeholder="Generic Name"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleMedicineAdd()
                            }
                          />
                          <ErrorMessage
                            error={"Please enter the Generic Name"}
                            visible={genericNameError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters App">
                        <ShowMessage view={medicineSuccess} Message={message} />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn"
                      title="Close"
                      onClick={() => {
                        handleMedicineAdd();
                        setMedicineSel(0);
                      }}
                    >
                      <span className="icofont-save"></span>
                      Save
                    </button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default MedicineList;
