import React from "react";
import { useNavigate } from "react-router-dom";

import useAuth from "../auth/useAuth";
import storage from "../auth/storage";

import registerApi from "../api/register";

import PageHeader from "../pages/pageHeader";

function Success() {
  const navigate = useNavigate();
  const authLogin = useAuth();

  const handleSuccess = async () => {
    await storage.removeToken();
    await storage.removeId();
    await storage.removeScheduleName();
    authLogin.logOut();
    await registerApi.logout();
    navigate("/doctormobileotp");
    window.location.reload();
  };

  return (
    <>
      <div className="container-fluid welcome-font">
        <div className="row outermargin">
          <PageHeader />
          <div className="row mb-5">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div className="row login-right">
                <div className="col-lg-7 p-0">
                  <div className="loginbluebgbox">
                    <div className="loginlefttext">
                      Revolutionize <br />
                      your clinical experience <br />
                      with MyClinic!
                    </div>
                    <div className="streamtext">
                      Streamlines electronic patient
                      <br /> record keeping.
                    </div>
                    <div className="row no-gutters">
                      <div className="col-lg-4 mt-4">
                        <button
                          className="aboutbut"
                          type="submit"
                          title="About Us"
                          onClick={(e) => navigate("/aboutus")}
                        >
                          About Us
                        </button>
                      </div>
                      <div className="col-lg-5 mt-4">
                        <button
                          className="privacybut"
                          type="submit"
                          title="Privacy Policy"
                          onClick={(e) => navigate("/privacypolicy")}
                        >
                          Privacy Policy
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 p-0">
                  <div className="logbox">
                    <h3 className="mt-2 text-center">
                      Successfully Registered
                    </h3>
                    <h6 className="text-center">
                      Your profile has been forwarded to our team and will be
                      approved shortly. Please wait for the activation message.
                    </h6>
                    <div className="mt-3 text-center">
                      <button className="welcomebut" onClick={handleSuccess}>
                        Login
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Success;
