import React from "react";

function EmptyComponent({ title }) {
  return (
    <div className="row ms-3">
      <label>{title}</label>
    </div>
  );
}

export default EmptyComponent;
