import React, { useEffect, useState } from "react";
import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
import storage from "../auth/storage";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "../component/common/ShowMessage";
import string from "../string";
import { useNavigate } from "react-router-dom";

const prefixerror = "BankInformation";

function BankInformation() {
  const navigate = useNavigate();

  const [load, setLoad] = useState(false);
  const [allowedAppointments, setAllowedAppointments] = useState(0);
  const [bankName, setBankName] = useState("");
  const [branch, setBranch] = useState("");
  const [ifscCode, setIFSCCode] = useState("");
  const [accNo, setAccNo] = useState("");
  const [showBankNameError, setShowBankNameError] = useState(false);
  const [showBankBranchError, setShowBankBranchError] = useState(false);
  const [showIFSCError, setShowIFSCError] = useState(false);
  const [showAccNoError, setShowAccNoError] = useState(false);
  const [showMessage, setShowMessage] = useState(false);

  const closeErrors = () => {
    setShowAccNoError(false);
    setShowIFSCError(false);
    setShowBankBranchError(false);
    setShowBankNameError(false);
  };

  const handleBankDetails = async () => {
    closeErrors();
    let err = false;
    if (accNo == "") {
      setShowAccNoError(true);
      document.getElementById("accNo").focus();
      err = true;
    }
    if (ifscCode == "") {
      setShowIFSCError(true);
      document.getElementById("ifscCode").focus();
      err = true;
    }
    if (branch == "") {
      setShowBankBranchError(true);
      document.getElementById("branch").focus();
      err = true;
    }
    if (bankName == "") {
      setShowBankNameError(true);
      document.getElementById("bankName").focus();
      err = true;
    }

    if (err) return;

    try {
      setLoad(true);
      const doctorEmail = await storage.getID();
      const updateConfRes = await doctorApi.updateBankDetails(
        doctorEmail,
        bankName,
        branch,
        ifscCode,
        accNo
      );
      console.log("updateConfRes---", updateConfRes);
      if (!updateConfRes.ok) {
        ErrorLog(
          prefixerror + "handleBankDetails " + "updateBankDetails",
          JSON.stringify(updateConfRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorEmail:" +
            doctorEmail +
            "bankName:" +
            bankName +
            "branch:" +
            branch +
            "ifscCode:" +
            ifscCode +
            "accNo:" +
            accNo
        );
        return;
      }
      setLoad(false);
      setShowMessage(true);
      setTimeout(() => {
        setShowMessage(false);
      }, 3000);
    } catch (error) {
      ErrorLog(
        prefixerror + "handleBankDetails ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
    }
  };

  const getBankDetails = async () => {
    const role = await storage.getRole();
    if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
      navigate("/");
      return;
    }
    const doctorEmail = await storage.getID();
    const confRes = await doctorApi.getConfiguration(doctorEmail);
    console.log("confRes---", confRes);
    if (!confRes.ok) {
      ErrorLog(
        prefixerror + "getBankDetails " + "getConfiguration",
        JSON.stringify(confRes),
        "We are facing some issues.Please try again after sometime.",
        "doctorEmail:" + doctorEmail
      );
      return;
    }
    confRes.data.data[0].bank_name &&
      setBankName(confRes.data.data[0].bank_name);
    confRes.data.data[0].branch && setBranch(confRes.data.data[0].branch);
    confRes.data.data[0].ifsc_code &&
      setIFSCCode(confRes.data.data[0].ifsc_code);
    confRes.data.data[0].account_number &&
      setAccNo(confRes.data.data[0].account_number);
  };

  useEffect(() => {
    getBankDetails();
  }, []);
  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row mb-4" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row no-gutters">
                  <h1>Bank Information</h1>
                </div>
                <div className="row">
                  <div
                    className="col-lg-5 p-5 border"
                    style={{ borderRadius: "6px" }}
                  >
                    <div className="">
                      <ShowMessage
                        Message="Bank details updated successfully"
                        view={showMessage}
                      />
                      <div className="row mt-3">
                        <div className="row">
                          <label className="control-label col-lg-5">
                            <span className="star">*</span> Bank Name :
                          </label>
                          <div className="col-lg-5 form-group p-0">
                            <CustomTextInput
                              type="text"
                              id="bankName"
                              maxlength="140"
                              className="form-control"
                              name="bankName"
                              placeholder="Bank Name"
                              value={bankName}
                              onChange={(e) => {
                                setBankName(e.target.value);
                              }}
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleBankDetails()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter the Bank Name"}
                              visible={showBankNameError}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="control-label col-lg-5">
                            <span className="star">*</span> Branch :
                          </label>
                          <div className="col-lg-5 form-group p-0">
                            <CustomTextInput
                              type="text"
                              placeholder="Branch"
                              id="branch"
                              maxlength="140"
                              className="form-control"
                              name="branch"
                              value={branch}
                              onChange={(e) => {
                                setBranch(e.target.value);
                              }}
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleBankDetails()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter the Bank Branch Name"}
                              visible={showBankBranchError}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="control-label col-lg-5">
                            <span className="star">*</span> IFSC code :
                          </label>
                          <div className="col-lg-5 form-group p-0">
                            <CustomTextInput
                              type="text"
                              placeholder="IFSC Code"
                              id="ifscCode"
                              className="form-control"
                              name="ifscCode"
                              maxLength="12"
                              value={ifscCode}
                              onChange={(e) => {
                                setIFSCCode(e.target.value);
                              }}
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleBankDetails()
                              }
                            />
                            <ErrorMessage
                              error={"Please enter the Bank IFSC Code"}
                              visible={showIFSCError}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="control-label col-lg-5">
                            <span className="star">*</span> Account Number :
                          </label>
                          <div className="col-lg-5 form-group p-0">
                            <CustomTextInput
                              type="number"
                              placeholder="Account Number"
                              id="accNo"
                              className="form-control"
                              name="accNo"
                              value={accNo}
                              onKeyUp={(e) =>
                                e.keyCode == 13 && handleBankDetails()
                              }
                              onChange={(e) => {
                                closeErrors();
                                e.target.value =
                                  e.target.value < 0
                                    ? 0
                                    : e.target.value.length > 20
                                    ? e.target.value.slice(0, 20)
                                    : e.target.value;
                                setAccNo(e.target.value);
                              }}
                            />
                            <ErrorMessage
                              error={"Please enter the Account Number"}
                              visible={showAccNoError}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <label className="control-label col-lg-5"></label>
                          <div className="col-lg-5 p-0">
                            <div className="form-group col-lg-6">
                              <button
                                className="btn"
                                title="Update"
                                onClick={(e) => {
                                  handleBankDetails();
                                }}
                              >
                                Update
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default BankInformation;
