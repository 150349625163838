import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import { months } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import adminApi from "../api/adminApi";

import storage from "../auth/storage";
import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import "react-datepicker/dist/react-datepicker.css";
const prefixerror = "ReportAppointment";

function ReportAppointment() {
  //#region const
  const [upcomingApp, setUpcomingApp] = useState([]);
  const [load, setLoad] = useState(false);
  const navigate = useNavigate();
  const todyaDate = new Date();
  const oneWeekBefore = new Date(); // Create a new Date object
  oneWeekBefore.setTime(todyaDate.getTime() - 7 * 24 * 60 * 60 * 1000);
  const [fromDate, setFromDate] = useState(oneWeekBefore);
  const [toDate, setToDate] = useState(todyaDate);
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear() + 2, 1);
  const DateCustomInput = preFunction.DateCustomInput;
  const [csvData, setCsvData] = useState([]);
  //#endregion

  const getAppointmentList = async () => {
    try {
      const role = await storage.getRole();
      if (role != "SuperAdmin") {
        navigate("/logout");
        return;
      }
      setLoad(true);
      setUpcomingApp([]);
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      const res = await adminApi.getAllAppointment(from, to);
      console.log("res----", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getAppointmentList " + "getAllAppointment",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "date:" + date
        );
        return;
      }
      let csvData = [
        [
          "No",
          "Appointment Date",
          "Doctor Name",
          "Clinic Id",
          "Clinic Name",
          "Patient Name",
          "Consultation Type",
          "Consultation Status",
        ],
      ];
      let i = 0;
      for (i = 0; i < res.data.message.length; i++) {
        csvData[i + 1] = [
          i + 1,
          moment(res.data.message[i].appointment_date).format("DD-MM-yyyy"),
          res.data.message[i].practitioner_name,
          res.data.message[i].company,
          res.data.message[i].company_name,
          res.data.message[i].patient_name,
          res.data.message[i].consultation_type,
          res.data.message[i].status,
        ];
      }
      setCsvData(csvData);
      setUpcomingApp(res.data.message);
      setLoad(false);
      return;
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getAppointmentList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getAppointmentList();
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>

        <div className="col-lg-10">
          <DoctorHeader />
          <div className="mb-4 pd" onClick={preFunction.hideNavbar}>
            <h1 className="content-heading mb-4">Appointment</h1>
            <div className="row mb-5">
              <div className="col-lg-2">
                <label className="col-lg-3 mt-2 p-0">From :</label>
                <div className="col-lg-2 p-0">
                  <DatePicker
                    name="fromDate"
                    id="fromDate"
                    openToDate={fromDate}
                    selected={fromDate}
                    onChange={(date) => {
                      setFromDate(date);
                      // getDoctorList(date, toDate);
                    }}
                    minDate={new Date("2022-01-01")}
                    maxDate={toDate}
                    dateFormat="dd-MM-yyyy"
                    customInput={<DateCustomInput />}
                    className="form-control datepicker"
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          style={{ marginRight: "3px" }}
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  />
                </div>
              </div>
              <div className="col-lg-2">
                <label className="col-lg-3 mt-2 p-0">To :</label>
                <div className="col-lg-2 p-0">
                  <DatePicker
                    name="toDate"
                    id="toDate"
                    openToDate={toDate}
                    selected={toDate}
                    onChange={(date) => {
                      setToDate(date);
                      // getDoctorList(fromDate, date);
                    }}
                    minDate={fromDate}
                    maxDate={new Date(date.getFullYear() + 2 + "-12-31")}
                    dateFormat="dd-MM-yyyy"
                    customInput={<DateCustomInput />}
                    className="form-control datepicker"
                    renderCustomHeader={({ date, changeYear, changeMonth }) => (
                      <div
                        style={{
                          margin: 10,
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <select
                          style={{ marginRight: "3px" }}
                          value={date.getFullYear()}
                          onChange={({ target: { value } }) =>
                            changeYear(value)
                          }
                        >
                          {years.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                        <select
                          value={months[date.getMonth()]}
                          onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                          }
                        >
                          {months.map((option) => (
                            <option key={option} value={option}>
                              {option}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  />
                </div>
              </div>
              {upcomingApp.length > 0 && (
                <div className="col-lg-8 mt-2" style={{ textAlign: "right" }}>
                  <br />
                  <CSVLink
                    filename={
                      "Appointment Report " +
                      moment(fromDate).format("DD-MM-yyyy") +
                      " to " +
                      moment(toDate).format("DD-MM-yyyy") +
                      ".csv"
                    }
                    data={csvData}
                  >
                    <button className="btn-2">
                      <span className="icofont icofont-download"></span> Export
                      Report
                    </button>
                  </CSVLink>
                </div>
              )}
            </div>
            <div className="row">
              <div className="row border p-3">
                <div className="row totcntstyle">
                  A total of {upcomingApp.length} appointment identified.
                </div>
                {upcomingApp.length > 0 ? (
                  <div id="report" className="table-responsive">
                    <table className="table mt-1">
                      <thead>
                        <tr>
                          <th width="10%">Date</th>
                          <th width="15%">Doctor Name</th>
                          <th width="15%">Clinic Id</th>
                          <th>Clinic Name</th>
                          <th width="15%">Patient Name</th>
                          <th width="10%">Consultation Type</th>
                          <th width="10%">Consultation Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {upcomingApp.map((item, index) => (
                          <tr key={index}>
                            <td>
                              {moment(item.appointment_date).format(
                                "DD-MM-yyyy"
                              )}
                            </td>
                            <td>{item.practitioner_name}</td>
                            <td>{item.company}</td>
                            <td>{item.company_name}</td>
                            <td>{item.patient_name}</td>
                            <td>{item.consultation_type}</td>
                            <td>{item.status}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportAppointment;
