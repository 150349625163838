import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import string from "../string";
const prefixerror = "InvoiceList";

function InvoiceList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [searchString, setSearchString] = useState();
  const [patient, setPatient] = useState([]);
  const [patientSel, setPatientSel] = useState([]);
  const [role, setRole] = useState();
  const navigate = useNavigate();
  let sno = limitStart + 1;
  //#endregion

  const getInvoiceList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const r = await storage.getRole();
      setRole(r);
      const pagesize = await storage.getPageSize();
      console.log("searchString--", searchString);
      setPageSize(pagesize);
      const role = await storage.getRole();
      console.log("role---", role);
      let searchStr;
      const company = await storage.getCompany();
      if (!searchString) {
        searchStr = `[["company", "=", "${company}"], ["status","=","Paid"], ["patient_encounter","!=",""]]`;
        setSearchString(searchStr);
      } else {
        searchStr = searchString;
      }
      let allRes;
      if (role != string.DOCTOR_ROLE) {
        allRes = await doctorApi.getTotalCountSuperAdminKey(
          "Sales Invoice",
          searchStr
        );
        console.log("allRes----", allRes);
        if (!allRes.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getTotalCountByDocType",
            JSON.stringify(allRes),
            "We are facing some issues.Please try again after sometime.",
            "searchStr:" + searchStr
          );
          return;
        }
      } else {
        allRes = await doctorApi.getTotalCountByDocType(
          "Sales Invoice",
          searchStr
        );
        console.log("allRes----", allRes);
        if (!allRes.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getTotalCountByDocType",
            JSON.stringify(allRes),
            "We are facing some issues.Please try again after sometime.",
            "searchString:" + searchStr
          );
          return;
        }
      }
      setTotalCount(allRes.data.message);
      let res;
      if (role != string.DOCTOR_ROLE) {
        res = await doctorApi.getAllInvoiceByCompany(
          searchStr,
          limitStart,
          pageSize
        );
        console.log("data==--------", res.data.data);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getAllInvoice",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "searchString:" +
              searchStr +
              "limitStart:" +
              limitStart +
              "pageSize:" +
              pageSize
          );
          return;
        }
      } else {
        res = await doctorApi.getAllInvoice(searchStr, limitStart, pageSize);
        console.log("data==--------", res.data.data);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getInvoiceList " + "getAllInvoice",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "searchString:" +
              searchStr +
              "limitStart:" +
              limitStart +
              "pageSize:" +
              pageSize
          );
          return;
        }
      }
      if (res.data.data.length > 0) {
        setData(res.data.data);
      } else {
        setData([]);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getInvoiceList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = (invoiceId) => {
    navigate("/viewInvoice", { state: { invoiceId } });
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getInvoiceList(lmtst, searchString);
  };

  const handleSelectPatient = async (e) => {
    console.log(e, "e");
    for (let i = 0; i < e.length; i++) {
      e[i].patient = e[i].patient.split("-")[0];
    }
    setPatientSel(e);
    const company = await storage.getCompany();
    setSearchString(
      `[["company", "=", "${company}"], ["patient","=","${e[0].id}"],["status","=","Paid"], ["patient_encounter","!=",""]]`
    );
    getInvoiceList(
      limitStart,
      `[["company", "=", "${company}"], ["patient","=","${e[0].id}"],["status","=","Paid"], ["patient_encounter","!=",""]]`
    );
    setPatient(e);
    document.getElementById("patient_input").blur();
  };

  const handleSearchPatient = async (value) => {
    try {
      console.log("value---", value);
      const company = await storage.getCompany();
      setLoad(true);
      setPatient();
      if (value.length > 2) {
        const res = await doctorApi.getSearchPatient(value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchPatient " + "getSearchPatient",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        console.log("searchRes--", res.data.data);

        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              patient:
                res.data.data[i].patient_name +
                " - " +
                moment().diff(res.data.data[i].dob, "years") +
                " years - " +
                res.data.data[i].mobile,
              id: res.data.data[i].name,
            };
            arr.push(myObject);
          }
          setPatient(arr);
        }
      } else {
        setSearchString(
          `[["company", "=", "${company}"], ["status","=","Paid"], ["patient_encounter","!=",""]]`
        );
        getInvoiceList(
          limitStart,
          `[["company", "=", "${company}"], ["status","=","Paid"], ["patient_encounter","!=",""]]`
        );
        setPatient([]);
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "handleSearchPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  useEffect(() => {
    getInvoiceList(limitStart, searchString);
    document.getElementById("patient") &&
      document.getElementById("patient").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row no-gutters">
          <div className="col-lg-2">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd">
                <div className="row no-gutters mb-3">
                  <h1>Payment</h1>
                </div>
                <div className="row no-gutters">
                  <div className="form-group col-lg-4">
                    <Multiselect
                      name="patient"
                      id="patient"
                      options={patient}
                      placeholder={"Patient Name"}
                      showArrow={true}
                      selectedValues={patientSel}
                      avoidHighlightFirstOption={true}
                      onSelect={handleSelectPatient}
                      displayValue="patient"
                      selectionLimit={1}
                      customCloseIcon={" "}
                      hidePlaceholder={true}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      onSearch={(e) => {
                        handleSearchPatient(
                          document.getElementById("patient_input").value
                        );
                        handleSelectPatient("");
                      }}
                      emptyRecordMsg={
                        <div className="notFoundSearch">Type to search</div>
                      }
                    />
                  </div>
                </div>
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} payment identified.
                  </div>
                  {data.length > 0 ? (
                    <div id="report">
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              {role != string.DOCTOR_ROLE && (
                                <th width="25%">Practitioner</th>
                              )}
                              <th width="10%">Date</th>
                              <th width="15%">Invoice No</th>
                              <th>Name</th>
                              <th width="5%">Amount (₹)</th>
                              <th width="10%">Status</th>
                              <th width="10%">View</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={item.name}>
                                  <td>{index + 1}</td>
                                  {role != string.DOCTOR_ROLE && (
                                    <td>{item.ref_practitioner}</td>
                                  )}
                                  <td className="nowrapWhiteSpace">
                                    {moment(item.posting_date).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                  <td className="nowrapWhiteSpace">
                                    {item.name}
                                  </td>
                                  <td className="nowrapWhiteSpace">
                                    {item.patient_name}
                                  </td>
                                  <td align="right">{item.grand_total}</td>

                                  <td>{item.status}</td>
                                  <td>
                                    <button
                                      className="btn-3"
                                      onClick={() =>
                                        navigate("/viewInvoice", {
                                          state: { invoiceId: item.name },
                                        })
                                      }
                                    >
                                      <span className="icofont icofont-prescription"></span>
                                      View Invoice
                                    </button>
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      {totalCount > pageSize && (
                        <table
                          style={{
                            borderBottom: 0,
                            backgroundColor: "#f6f4f4",
                          }}
                        >
                          <tbody>
                            <tr>
                              <td className="pt-4">
                                <Pagination
                                  itemsCount={totalCount}
                                  pageSize={pageSize}
                                  currentPage={currentPage}
                                  onPageChange={(page) =>
                                    handlePageChange(page)
                                  }
                                  style={{ cursor: "pointer" }}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  ) : (
                    <label className="ps-1">No Invoices found</label>
                  )}
                </div>
                <div className="row mt-5">
                  <iframe
                    height={500}
                    src="http://localhost:4005/request/request-list"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default InvoiceList;
