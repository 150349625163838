import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import doctorRegApi from "../api/doctorRegApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import CustomButton from "../component/common/CustomButton";
import ShowMessage from "../component/common/ShowMessage";
import ErrorMessage from "./../component/common/ErrorMessage";
import ErrorLog from "../component/common/ErrorLog";

import string from "../string";
import storage from "../auth/storage";
import pdfLogo from "../assests/jpg/pdflogo.jpg";
import fileUpload from "../assests/svg/file_upload.svg";
import DoctorNavBar from "./../doctorHome/DoctorNavBar";
import DoctorHeader from "./../doctorHome/DoctorHeader";
import blankProfile from "../assests/png/blank-profile-picture.png";
import moment from "moment";
import doctorApi from "../api/doctorApi";

const prefixerror = "ViewDoctor";

const ViewDoctor = () => {
  //#region const
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [data, setData] = useState([]);
  const [image, setImage] = useState(blankProfile);
  const [fileType1, setFileType1] = useState();
  const [fileType3, setFileType3] = useState();
  const [fileType4, setFileType4] = useState();
  const [data1, setData1] = useState("");
  const [data2, setData2] = useState("");
  const [data3, setData3] = useState("");
  const [data4, setData4] = useState("");
  const [message, setMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [timeSlots, setTimeSlots] = useState([]);
  const [address, setAddress] = useState([]);
  const [displayImage, setDisplayImage] = useState("");
  const [errorOpen, setErrorOpen] = useState(false);
  const [bankInfo, setBankInfo] = useState([]);
  const [comment, setComment] = useState("");
  const [commentError, setCommentError] = useState(false);
  //#endregion

  const handleServiceUnit = async (doctorid, hospital) => {
    let serviceUnitRes;
    serviceUnitRes = await adminApi.getServiceUnit(doctorid);
    console.log("serviceUnitRes---", serviceUnitRes);
    let serviceUnitName;
    if (serviceUnitRes.data.data.length == 0) {
      const createServiceUnit = await adminApi.createServiceUnit(
        hospital,
        doctorid
      );
      console.log("createServiceUnit---", createServiceUnit);
      if (!createServiceUnit.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "createServiceUnit",
          JSON.stringify(createServiceUnit),
          "We are facing some issues.Please try again after sometime.",
          "hospital:" + hospital + "doctorid:" + doctorid
        );
        return;
      }
      serviceUnitName = createServiceUnit.data.data.name;
      const assignSchedule = await doctorRegApi.assignSchedule(
        doctorid,
        doctorid + " Schedule",
        serviceUnitName
      );
      console.log("assignSchedule---", assignSchedule);
      if (!assignSchedule.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "assignSchedule",
          JSON.stringify(assignSchedule),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
        );
        return;
      }
    } else {
      serviceUnitName = serviceUnitRes.data.data[0].name;
      const assignSchedule = await doctorRegApi.assignSchedule(
        doctorid,
        doctorid + " Schedule",
        serviceUnitName
      );
      console.log("assignSchedule---", assignSchedule);
      if (!assignSchedule.ok) {
        ErrorLog(
          prefixerror + "handleServiceUnit " + "assignSchedule",
          JSON.stringify(assignSchedule),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "serviceUnitName:" + serviceUnitName
        );
        return;
      }
    }
    return;
  };

  const handleAddress = async (email, hospital, phone) => {
    try {
      const doctorAddressRes = await doctorRegApi.getAddress(email);
      console.log("doctorAddressRes--", doctorAddressRes);
      if (doctorAddressRes.data.data.length > 0) {
        const addressRes = await adminApi.getAddress(hospital);
        if (addressRes.data.data.length == 0) {
          const addAddressRes = await adminApi.addAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          console.log("addAddressRes--", addAddressRes);
          if (!addAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "addAddress",
              JSON.stringify(addAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "phone:" +
                phone +
                "email:" +
                email +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
        } else {
          const updateAddressRes = await adminApi.updateAddress(
            hospital,
            email,
            phone,
            doctorAddressRes.data.data[0].address_line1,
            doctorAddressRes.data.data[0].address_line2,
            doctorAddressRes.data.data[0].city,
            doctorAddressRes.data.data[0].state,
            doctorAddressRes.data.data[0].pincode
          );
          console.log("updateAddressRes--", updateAddressRes);
          if (!updateAddressRes.ok) {
            ErrorLog(
              prefixerror + "handleAddress " + "updateAddress",
              JSON.stringify(updateAddressRes),
              "We are facing some issues.Please try again after sometime.",
              "hospital:" +
                hospital +
                "phone:" +
                phone +
                "email:" +
                email +
                "address_line1:" +
                doctorAddressRes.data.data[0].address_line1 +
                "address_line2:" +
                doctorAddressRes.data.data[0].address_line2 +
                "city:" +
                doctorAddressRes.data.data[0].city +
                "state:" +
                doctorAddressRes.data.data[0].state +
                "pincode:" +
                doctorAddressRes.data.data[0].pincode
            );
            return;
          }
        }
      }
      return true;
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleAddress ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleStatus = async (doctorData) => {
    try {
      console.log("doctorData---", doctorData);
      let approvalOn = "";
      if (doctorData.approval_on && doctorData.approval_on != "")
        approvalOn = doctorData.approval_on;
      else approvalOn = moment(new Date()).format("yyyy-MM-DD");
      const doctorid = location.state.doctorid;

      const changeres = await adminApi.changeStatus(
        doctorid,
        "Active",
        approvalOn,
        comment
      );
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleStatus " + "changeStatus",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "Status:Active" + "approvalOn:" + approvalOn
        );
        return;
      }
      console.log("changeres--", changeres);
      setMessage(`Activated Successfully`);
      const messageRes = await adminApi.sendMessageToMobile(
        doctorData.mobile_phone
      );
      console.log("mobile messageRes", messageRes);
      if (!messageRes.ok) {
        ErrorLog(
          prefixerror + "handleStatus " + "sendMessageToMobile",
          JSON.stringify(messageRes),
          "We are facing some issues.Please try again after sometime.",
          "phone:" + doctorData.mobile_phone
        );
        return;
      }
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleStatus ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleSaveComment = async () => {
    try {
      setCommentError(false);

      if (!comment || comment == "") {
        setCommentError(true);
        return;
      }

      setLoad(true);
      const doctorid = location.state.doctorid;
      const changeres = await adminApi.saveComment(doctorid, comment);
      console.log("changeres--", changeres);
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleSaveComment " + "saveComment",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "comment:" + comment
        );
        return;
      }

      setMessage(`Comment Saved Successfully`);
      setOpen(true);
      getDoctorInformation();
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setLoad(false);
      return true;
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDisable ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleDisable = async () => {
    try {
      setLoad(true);
      const doctorid = location.state.doctorid;
      const changeres = await adminApi.changeDisable(doctorid, comment);
      console.log("changeres--", changeres);
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleDisable " + "changeDisable",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid + "comment:" + comment
        );
        return;
      }

      setMessage(`Disabled Successfully`);
      setOpen(true);
      getDoctorInformation();
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setLoad(false);
      return true;
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleDisable ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return false;
    }
  };

  const handleActivate = async (doctorData) => {
    try {
      if (
        doctorData.email == "" ||
        !doctorData.hospital ||
        doctorData.hospital == "" ||
        doctorData.mobile_phone == ""
      ) {
        setMessage("Profile not complete.");
        setErrorOpen(true);
        setTimeout(() => {
          setErrorOpen(false);
        }, 3000);

        return;
      }
      setLoad(true);
      let companyName = data.company_name;
      let hospital;
      if (data.company_name == "") {
        const companyRes = await doctorRegApi.createCompany(
          doctorData.hospital,
          doctorData.email
        );
        console.log("companyRes---", companyRes);
        if (!companyRes.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "createCompany",
            JSON.stringify(companyRes),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + doctorData.hospital + "email:" + doctorData.email
          );
          return;
        }
        companyName = companyRes.data.data.name;
        hospital = companyRes.data.data.name;
        const fileres = await doctorRegApi.attachImage(doctorData.email);
        console.log("certificateRes--", fileres);

        let letterhead = "";
        if (fileres.data.data.length > 0) {
          fileres.data.data.map((item, index) => {
            if (item.file_name.indexOf("-letterhead") != -1) {
              letterhead = fileres.data.data[index].file_url;
            }
          });
          if (letterhead != "") {
            const letterheadRes = await doctorRegApi.createLetterHead(
              companyName,
              letterhead
            );
          }
        }
        await handleServiceUnit(location.state.doctorid, companyName);

        const updateDoctor = await doctorRegApi.updateCompanyDoctor(
          doctorData.email,
          companyName
        );
        console.log("updateDoctor---", updateDoctor);

        await handleAddress(
          doctorData.email,
          companyName,
          doctorData.office_phone
        );

        const res1 = await doctorRegApi.userPermission(
          doctorData.email,
          companyName
        );
        if (!res1.ok) {
          ErrorLog(
            prefixerror + "handleActivate " + "userPermission",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "email:" + doctorData.email + "companyName:" + companyName
          );
          return;
        }
      } else {
        await handleServiceUnit(location.state.doctorid, doctorData.hospital);
        hospital = doctorData.hospital;

        const email = await storage.getID();
        const logoRes = await doctorRegApi.getLetterPad(hospital);
        console.log("logoRes==", logoRes);
        if (logoRes.data.data.length === 0) {
          const fileres = await doctorRegApi.attachImage(doctorData.email);
          console.log("certificateRes--", fileres);
          let letterhead = "";
          if (fileres.data.data.length > 0) {
            fileres.data.data.map((item, index) => {
              if (item.file_name.indexOf("-letterhead") != -1) {
                letterhead = fileres.data.data[index].file_url;
              }
            });
            if (letterhead != "") {
              const letterheadRes = await doctorRegApi.createLetterHead(
                hospital,
                letterhead
              );
              console.log("letterheadRes--", letterheadRes);
            }
          }
        }
      }

      const getDoctorByHospitalRes = await doctorApi.getDoctorByHospital(
        hospital
      );
      console.log("getDoctorByHospitalRes---------", getDoctorByHospitalRes);
      let docArr = [];
      for (let i = 0; i < getDoctorByHospitalRes.data.data.length; i++) {
        if (getDoctorByHospitalRes.data.data[i].name != doctorData.email)
          docArr.push(getDoctorByHospitalRes.data.data[i].name);
      }

      const getDocXUserRes = await doctorApi.getDocXUserByDoctor(docArr);
      console.log("getDocXUserRes----", getDocXUserRes);

      let userArr = [];
      for (let i = 0; i < getDocXUserRes.data.data.length; i++) {
        if (!userArr.includes(getDocXUserRes.data.data[i].user_id)) {
          userArr.push(getDocXUserRes.data.data[i].user_id);
        }
      }
      console.log("userArr------", userArr);
      for (let i = 0; i < userArr.length; i++) {
        const checkDoctorXUserRes = await doctorApi.getDoctorXUser(
          userArr[i],
          doctorData.email
        );
        console.log("checkDoctorXUserRes-----------", checkDoctorXUserRes);
        if (checkDoctorXUserRes.data.data.length === 0) {
          const assignDoctorXUserRes = await doctorApi.assignDoctorXUser(
            userArr[i],
            doctorData.email
          );
          console.log("assignDoctorXUserRes-----------", assignDoctorXUserRes);
        }
      }
      await handleStatus(doctorData);
      setMessage("Activated Successfully");
      setOpen(true);
      getDoctorInformation();
      setTimeout(() => {
        setOpen(false);
      }, 3000);

      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleActivate ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getDoctorInformation = async () => {
    try {
      setLoad(true);

      const role = await storage.getRole();
      if (role !== "SuperAdmin") {
        navigate("/");
        return;
      }
      if (!location.state) {
        navigate("/doctorlist");
        return;
      }
      const doctorid = location.state.doctorid;
      console.log("doctorid--", doctorid);

      const res = await adminApi.getDetilsByFormLoad(doctorid);
      console.log("docRes----------------------", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getDoctorInformation " + "getDetilsBasicInfo",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorid:" + doctorid
        );
        return;
      }
      if (
        res.data.docs[0].hospital &&
        res.data.docs[0].hospital.indexOf("HLC-COM-") != -1
      ) {
        const companyRes = await doctorRegApi.getCompany(
          res.data.docs[0].hospital
        );
        console.log("companyres---", companyRes);
        if (!companyRes.ok) {
          ErrorLog(
            prefixerror + "getDoctorInformation " + "getCompany",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + res.data.docs[0].hospital
          );
          return;
        }
        res.data.docs[0].company_name = companyRes.data.data[0].company_name;
      } else {
        res.data.docs[0].company_name = "";
      }
      setComment(res.data.docs[0].comments);
      setData(res.data.docs[0]);

      if (res.data.docs[0].image) {
        setImage(string.testbaseUrl + res.data.docs[0].image);
      }

      const scheduleRes = await doctorRegApi.appointmentInfo(
        doctorid + " schedule"
      );
      if (scheduleRes.ok && scheduleRes.data.data.time_slots.length > 0) {
        setTimeSlots(scheduleRes.data.data.time_slots);
      }
      let letterhead = "";
      if (res.data.docinfo.attachments.length > 0) {
        res.data.docinfo.attachments.map((item, index) => {
          if (item.file_name.indexOf("-letterhead") != -1) {
            setDisplayImage(string.testbaseUrl + item.file_url);
          }
          if (item.file_name.indexOf("idproof") != -1) {
            setData1(item.file_url);
            setFileType1(
              item.file_name.split(".")[item.file_name.split(".").length - 1]
            );
          }
          if (item.file_name.indexOf("esign") != -1) {
            setData2(item.file_url);
          }
          if (item.file_name.indexOf("degree") != -1) {
            setData3(item.file_url);
            setFileType3(
              item.file_name.split(".")[item.file_name.split(".").length - 1]
            );
          }
          if (item.file_name.indexOf("council") != -1) {
            setData4(item.file_url);
            setFileType4(
              item.file_name.split(".")[item.file_name.split(".").length - 1]
            );
          }
        });
      }

      const logoRes = await doctorRegApi.getLetterPad(
        res.data.docs[0].hospital
      );
      console.log("logoRes==", logoRes);
      if (logoRes.data.data.length > 0) {
        letterhead = string.testbaseUrl + logoRes.data.data[0].image;
        setDisplayImage(string.testbaseUrl + logoRes.data.data[0].image);
      }
      setAddress(res.data.docs[0].__onload.addr_list);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "getDoctorInformation ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getBankDetails = async () => {
    const doctorEmail = await storage.getID();
    const confRes = await adminApi.getConfigurationByDoctor(
      location.state.doctorid
    );
    console.log("confRes------------+++++++-", confRes);
    if (!confRes.ok) {
      ErrorLog(
        prefixerror + "getBankDetails " + "getConfiguration",
        JSON.stringify(confRes),
        "We are facing some issues.Please try again after sometime.",
        "doctorEmail:" + doctorEmail
      );
      return;
    }
    confRes.data.data.length > 0 && setBankInfo(confRes.data.data);
  };

  const handleCancel = async () => {
    setCommentError(false);
    if (!comment || comment == "") {
      setCommentError(true);
      return;
    }
    console.log("doctorData---", data);
    let approvalOn = "";
    if (data.approval_on && data.approval_on != "")
      approvalOn = data.approval_on;
    else approvalOn = moment(new Date()).format("yyyy-MM-DD");
    console.log("approvalOn---", approvalOn);

    const changeres = await adminApi.changeRegStatus(
      location.state.doctorid,
      comment,
      approvalOn
    );
    console.log("changeres--", changeres);

    if (!changeres.ok) {
      ErrorLog(
        prefixerror + "handleCancel " + "changeRegStatus",
        JSON.stringify(changeres),
        "We are facing some issues.Please try again after sometime.",
        "doctorid:" +
          location.state.doctorid +
          "comment:" +
          comment +
          "approvalOn:" +
          approvalOn
      );
      return;
    }
    setMessage(`Registration Cancelled Successfully`);
    setOpen(true);
    getDoctorInformation();
    setTimeout(() => {
      setOpen(false);
    }, 3000);

    setLoad(false);
  };

  useEffect(() => {
    getDoctorInformation();
    getBankDetails();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <div className="row">
        <div className="col-lg-2 p-0">
          <DoctorNavBar id="DoctorNavBar" />
        </div>
        <div className="col-lg-10">
          <DoctorHeader />
          <div className="row" onClick={preFunction.hideNavbar}>
            <div className="pd">
              <div className="col-10">
                <div className="row mt-4">
                  <h1 className="content-heading">View Doctor</h1>
                  <div className="row no-gutters border p-4">
                    <div className="col-lg-7">
                      <div className="row no-gutters">
                        <h5>Basic Information</h5>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">Name :</label>
                        <div className="control-label-text col-lg-5">
                          {data.practitioner_name}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Email :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.email}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Mobile :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.mobile_phone}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">DOB :</label>
                        <div className="control-label-text col-lg-5">
                          {data.date_of_birth
                            ? moment(data.date_of_birth).format("DD-MM-yyyy")
                            : null}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Gender :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.gender}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Total Experience (years) :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.work_experience}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Registered Practioner Number :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.registered_practitioner_number}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Degree :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.degree}
                        </div>
                      </div>
                      <div className="row no-gutters">
                        <label className="control-label col-lg-7">
                          Department :
                        </label>
                        <div className="control-label-text col-lg-5">
                          {data.department}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-5 mt-5">
                      <img
                        style={{
                          height: 300,
                          width: 300,
                        }}
                        src={image}
                        alt="image"
                        id="img"
                        className="mb-3 text-center image-upload img-responsive"
                        htmlFor="input"
                      />
                    </div>
                    <div className="row no-gutters">
                      <h5>Clinic Information</h5>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">
                        Clinic Name :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {data.company_name != ""
                          ? data.company_name
                          : data.hospital}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">
                        Address :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {address.length > 0 ? (
                          <>
                            {address[0].address_line1}
                            {address[0].address_line2 !== ""
                              ? ", " + address[0].address_line2
                              : ""}
                            <br />
                            {address[0].city}
                            <br />
                            {address[0].state}-{address[0].pincode}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">
                        Consultation Fee :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {data.op_consulting_charge > 0
                          ? data.op_consulting_charge
                          : ""}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-4">
                        Letter Head :
                      </label>
                    </div>
                    <div className="form-group row no-gutters mt-2 App">
                      <div>
                        <img
                          src={displayImage == "" ? fileUpload : displayImage}
                          alt="image"
                          id="img"
                          className="text-center letter-upload"
                          htmlFor="input"
                        />
                      </div>
                    </div>
                    <div className="form-group row no-gutters">
                      {timeSlots.length >= 1 ? (
                        <>
                          <h5>Time Slots</h5>
                          <div className="table-responsive">
                            <table className="table table-bordered">
                              <thead>
                                <tr>
                                  <th>Days</th>
                                  <th>From Time</th>
                                  <th>To Time</th>
                                </tr>
                              </thead>
                              <tbody>
                                {timeSlots.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{item.day}</td>
                                      <td>
                                        {preFunction.displayTimeFormat(
                                          item.from_time
                                        )}
                                      </td>
                                      <td>
                                        {preFunction.displayTimeFormat(
                                          item.to_time
                                        )}
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="form-group row no-gutters">
                      <h5>Certificate Information</h5>
                    </div>
                    <div className="row">
                      <div className="col-lg-3 App">
                        <div className="imageupload">
                          <img
                            src={
                              data1 === ""
                                ? fileUpload
                                : fileType1 === "pdf"
                                ? pdfLogo
                                : string.testbaseUrl + data1
                            }
                            alt="image"
                            id="img"
                            className="mb-3 text-center img img-responsive"
                            htmlFor="input"
                            height="150"
                            width="150"
                            onClick={() =>
                              data1 === ""
                                ? ""
                                : window.open(
                                    string.testbaseUrl + data1,
                                    "_blank"
                                  )
                            }
                          />
                        </div>
                        <div className="mt-2 text-center">
                          <label>ID Proof (Aadhar/PAN)</label>
                        </div>
                      </div>
                      <div className="col-lg-3 App">
                        <div className="imageupload">
                          <img
                            src={
                              data3 === ""
                                ? fileUpload
                                : fileType3 === "pdf"
                                ? pdfLogo
                                : string.testbaseUrl + data3
                            }
                            alt="image"
                            id="img"
                            className="mb-3 img image-upload img-responsive"
                            htmlFor="input"
                            height="150"
                            width="150"
                            onClick={() =>
                              data3 === ""
                                ? ""
                                : window.open(
                                    string.testbaseUrl + data3,
                                    "_blank"
                                  )
                            }
                          />
                        </div>
                        <div className="mt-2  text-center">
                          <label>Degree Certificate</label>
                        </div>
                      </div>
                      <div className="col-lg-3 App">
                        <div className="imageupload">
                          <img
                            src={
                              data4 === ""
                                ? fileUpload
                                : fileType4 === "pdf"
                                ? pdfLogo
                                : string.testbaseUrl + data4
                            }
                            alt="image"
                            id="img"
                            className="mb-3 text-center img image-upload img-responsive"
                            htmlFor="input"
                            height="150"
                            width="150"
                            onClick={() =>
                              data4 === ""
                                ? ""
                                : window.open(
                                    string.testbaseUrl + data4,
                                    "_blank"
                                  )
                            }
                          />
                        </div>
                        <div className="mt-2">
                          <label className="App">
                            Medical Council Registration Certificate
                          </label>
                        </div>
                      </div>
                      <div className="col-lg-3 col-md-3 App">
                        {data2 ? (
                          <div
                            style={{
                              height: "180px",
                            }}
                          >
                            <a
                              href={string.testbaseUrl + data2}
                              target="_blank"
                            >
                              <img
                                src={string.testbaseUrl + data2}
                                alt="image"
                                id="img"
                                className="mb-3 text-center img image-upload"
                                htmlFor="input"
                                height="70"
                                width="200"
                              />
                            </a>
                          </div>
                        ) : (
                          <div className="imageupload">
                            <img
                              src={fileUpload}
                              alt="image"
                              id="img"
                              className="mb-3 text-center img image-upload img-responsive"
                              htmlFor="input"
                              height="150"
                              width="150"
                            />
                          </div>
                        )}
                        <div className="mt-2 text-center">
                          <label>Signature</label>
                        </div>
                      </div>
                    </div>
                    {bankInfo.length > 0 &&
                    bankInfo[0].bank_name &&
                    bankInfo[0].bank_name != "" ? (
                      <div className="form-group row">
                        <h5>Bank Information</h5>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            Bank Name :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {bankInfo[0].bank_name}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            Branch :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {bankInfo[0].branch}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            IFSC code :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {bankInfo[0].ifsc_code}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            Account Number :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {bankInfo[0].account_number}
                          </div>
                        </div>
                      </div>
                    ) : null}
                    {data.leave_from_date && data.leave_from_date != "" ? (
                      <>
                        <h5>Leave Information</h5>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            Leave From :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {moment(data.leave_from_date).format("DD-MM-yyyy")}
                          </div>
                        </div>
                        <div className="row no-gutters">
                          <label className="control-label col-lg-6">
                            Leave to :
                          </label>
                          <div className="control-label-text col-lg-5">
                            {moment(data.leave_to).format("DD-MM-yyyy")}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="form-group row no-gutters">
                      <h5>General Information</h5>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">
                        Registered On :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {moment(data.creation).format("DD-MM-yyyy")}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">
                        Document Submitted On :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {data.document_submit_on
                          ? moment(data.document_submit_on).format("DD-MM-yyyy")
                          : ""}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">
                        Approved On :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {data.approval_on
                          ? moment(data.approval_on).format("DD-MM-yyyy")
                          : ""}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">Status :</label>
                      <div className="control-label-text col-lg-5">
                        {data.status}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">
                        Registration Status :
                      </label>
                      <div className="control-label-text col-lg-5">
                        {data.registration_status}
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <label className="control-label col-lg-6">
                        Comment :
                      </label>
                      <div className="control-label-text col-lg-4">
                        <textarea
                          name="comment"
                          id="comment"
                          rows="4"
                          cols="70"
                          className="textArea"
                          value={comment}
                          onChange={(e) => {
                            setCommentError(false);
                            setComment(e.target.value);
                          }}
                          placeholder="Comment"
                        />
                        <ErrorMessage
                          visible={commentError}
                          error="Please enter the comments"
                        />
                      </div>
                    </div>
                    <div className="App">
                      <ShowMessage view={open} Message={message} />
                      <ErrorMessage visible={errorOpen} error={message} />
                    </div>
                    <div className="row">
                      <div className="App mt-2">
                        <CustomButton
                          className="btn"
                          title="Back"
                          onClick={() => {
                            navigate("/doctorlist");
                          }}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        <CustomButton
                          className="btn"
                          title="Save Comment"
                          onClick={handleSaveComment}
                        />
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {data.status === "Active" ? (
                          <CustomButton
                            className="btn"
                            type="submit"
                            title="Disable"
                            onClick={handleDisable}
                          />
                        ) : (
                          <CustomButton
                            className="btn"
                            type="submit"
                            title="Activate"
                            onClick={() => handleActivate(data)}
                          />
                        )}
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {data.registration_status == "In Progress" ? (
                          <CustomButton
                            className="btn btnAdmin bg-pink-dark"
                            type="submit"
                            title="Cancel Registration"
                            onClick={handleCancel}
                          />
                        ) : null}
                      </div>
                      <div className="col-lg-4"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-1"></div>
            </div>
            <div className="p-5"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewDoctor;
