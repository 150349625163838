import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Multiselect from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorMessage from "../component/common/ErrorMessage";
import ShowMessage from "./../component/common/ShowMessage";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import pen from "../assests/svg/pen.svg";
import string from "../string";
import { useNavigate } from "react-router-dom";
const prefixerror = "SymptomsList";

function SymptomsList() {
  //#region const
  const navigate = useNavigate();

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [symptom, setSymptom] = useState([]);
  const [symptomSel, setSymptomSel] = useState([]);
  const [showSymptom, setShowSymptom] = useState(false);
  const [searchString, setSearchString] = useState("[]");
  const [practitioner, setPractitioner] = useState("");
  const [showDeleteSuccess, setShowDeleteSuccess] = useState(false);
  const [saveOpenModal, setSaveOpenModal] = useState(false);
  const [complaintError, setComplaintError] = useState(false);
  const [complaintSuccess, setComplaintSuccess] = useState(false);
  const [complaint, setComplaint] = useState("");
  const [complaintName, setComplaintName] = useState("");
  const [message, setMessage] = useState("");
  let sno = limitStart + 1;
  const [showDeleteFailure, setShowDeleteFailure] = useState(false);
  const [role, setRole] = useState("");
  //#endregion

  const closeErrors = () => {
    setShowSymptom(false);
    setShowDeleteSuccess(false);
    setComplaintError(false);
    setComplaintSuccess(false);
  };

  const handleMove = async (complaintId) => {
    try {
      const editRes = await doctorApi.editSymptomOwner(complaintId);
      if (!editRes.ok) {
        ErrorLog(
          prefixerror + " handleMove " + "editSymptomOwner",
          JSON.stringify(editRes),
          "We are facing some issues.Please try again after sometime.",
          "complaintId:" + complaintId
        );
        return;
      }
      console.log("editRes---", editRes);
      setMessage("Symptom moved to global list");
      setShowDeleteSuccess(true);
      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 3000);
      getSymptomsList(0, "[]");
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " handleMove ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSaveComplaint = async () => {
    closeErrors();
    if (complaint == "") {
      setComplaintError(true);
      return;
    }
    console.log("complaintName---", complaintName);
    console.log("complaint---", complaint);
    handleSymptomsAdd(complaintName, complaint);
  };

  const handleDeleteSymptom = async (complaintName) => {
    try {
      setLoad(true);
      closeErrors();
      const delRes = await doctorApi.deleteSymptom(complaintName);
      console.log("delRes---", delRes);
      if (!delRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteSymptom " + "deleteSymptom",
          JSON.stringify(delRes),
          "We are facing some issues.Please try again after sometime.",
          "complaintName:" + complaintName
        );
        setShowDeleteFailure(true);
        setTimeout(() => {
          setShowDeleteFailure(false);
        }, 3000);
        setLoad(false);
        return;
      }
      setTimeout(() => {
        setShowDeleteSuccess(false);
      }, 3000);
      setShowDeleteSuccess(true);
      getSymptomsList(0, "[]");
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " handleDeleteSymptom ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getSymptomsList = async (limitStart, searchString) => {
    const role = await storage.getRole();
    if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
      navigate("/");
      return;
    }
    try {
      setLoad(true);
      const role = await storage.getRole();
      setRole(role);
      setData([]);
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const practitioner = await storage.getID();

      setPractitioner(practitioner);
      let or_filters = "[]";
      if (role != "SuperAdmin") {
        or_filters = `[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]`;
      }
      const allRes = await doctorApi.getTotalCountByDocType(
        "Complaint",
        searchString,
        or_filters
      );
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + " getSymptomsList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "Complaint: Complaint searchString:" +
            searchString +
            "or_filters:" +
            or_filters
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);

      const res = await doctorApi.getSymptoms(
        or_filters,
        searchString,
        limitStart,
        pageSize
      );
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getSymptomsList " + "getSymptoms",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "Complaint: Complaint searchString:" +
            searchString +
            "or_filters:" +
            or_filters +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }
      console.log("data==", res);
      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);

      ErrorLog(
        prefixerror + " getSymptomsList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handlePageChange = (page) => {
    closeErrors();
    setShowSymptom(false);
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getSymptomsList(lmtst, searchString);
  };

  const handleSelectSymptom = (e) => {
    console.log("ee-------", e);
    closeErrors();
    if (e && e.length > 0) {
      setSymptomSel(e);
      setSearchString('[["name","=","' + e[0].name + '"]]');
      getSymptomsList(limitStart, '[["name","=","' + e[0].name + '"]]');
    } else {
      setSymptomSel();
      setSearchString("[]");
    }
    setSymptom([]);
  };

  const handleSymptomsAdd = async (complaintId, symptomsName) => {
    try {
      setLoad(true);
      let Res;
      closeErrors();
      console.log("complaintId--", complaintId);
      console.log("symptomsName--", symptomsName);
      if (complaintId != "") {
        Res = await doctorApi.editSymptom(complaintId, symptomsName);
        if (!Res.ok) {
          ErrorLog(
            prefixerror + " handleSymptomsAdd " + "editSymptom",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "complaintId:" + complaintId + "symptomsName:" + symptomsName
          );
          return;
        }
        setMessage("Symptom updated successfully");
      } else {
        Res = await doctorApi.createSymptoms(symptomsName);
        if (!Res.ok) {
          ErrorLog(
            prefixerror + " handleSymptomsAdd " + "createSymptoms",
            JSON.stringify(Res),
            "We are facing some issues.Please try again after sometime.",
            "symptomsName:" + symptomsName
          );
          return;
        }
        setMessage("Symptom added successfully");
      }
      console.log("Res----", Res);

      setComplaintSuccess(true);
      getSymptomsList(0, "[]");
      setTimeout(() => {
        setSaveOpenModal(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);

      ErrorLog(
        prefixerror + " handleSymptomsAdd ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSearchSymptoms = async (value) => {
    try {
      console.log("value---", value);
      setSymptom([]);
      if (value.length > 2) {
        const practitioner = await storage.getID();
        const res = await doctorApi.searchSymptoms(value, practitioner);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchSymptoms " + "searchSymptoms",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value + "practitioner:" + practitioner
          );
          return;
        }
        console.log("res----", res);
        setSymptom(res.data.message);
      } else if (value.length == "") {
        getSymptomsList(0, "[]");
      }
    } catch (error) {
      setLoad(false);
      console.log(error);

      ErrorLog(
        prefixerror + " handleSearchSymptoms ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getSymptomsList(0, "[]");
  }, []);
  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Symptom List</h1>
                </div>
                <div className="row no-gutters form-group">
                  <div className="col-lg-4">
                    <div className="row">
                      <Multiselect
                        name="symptom"
                        id="symptom"
                        options={symptom}
                        showArrow={true}
                        selectedValues={symptomSel}
                        onSelect={handleSelectSymptom}
                        onRemove={(e) => {
                          getSymptomsList(0, "[]");
                          return;
                        }}
                        displayValue="complaints"
                        placeholder={"Symptom"}
                        customCloseIcon={" "}
                        selectionLimit={1}
                        hidePlaceholder={true}
                        customArrow={
                          <span
                            className="icofont-search"
                            style={{
                              fontSize: "15px",
                            }}
                          ></span>
                        }
                        avoidHighlightFirstOption={true}
                        emptyRecordMsg={
                          document.getElementById("symptom_input") &&
                          document.getElementById("symptom_input").value
                            .length > 2 ? (
                            <div className="notFoundAdd">
                              Do you want to add?&nbsp;&nbsp;&nbsp;
                              <button
                                className="btn-2"
                                title="Add"
                                onClick={(e) => {
                                  closeErrors();
                                  setComplaint(
                                    document.getElementById("symptom_input")
                                      .value
                                  );
                                  setSaveOpenModal(true);
                                }}
                              >
                                <span className="icofont icofont-plus"></span>
                                Add
                              </button>
                            </div>
                          ) : (
                            <div className="notFoundSearch">Type to search</div>
                          )
                        }
                        onSearch={(e) => {
                          handleSearchSymptoms(
                            document.getElementById("symptom_input").value
                          );
                          handleSelectSymptom("");
                        }}
                      />
                    </div>
                    <ErrorMessage
                      error={"Please enter Symptom"}
                      visible={showSymptom}
                    />
                  </div>
                </div>
                <ShowMessage
                  view={showDeleteSuccess}
                  Message={"Symptom deleted successfully"}
                />
                <ErrorMessage
                  error={
                    "Couldn't delete because its already added to a patient consultation"
                  }
                  visible={showDeleteFailure}
                />
                <div className="row border p-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} symptom identified.
                  </div>
                  {data.length > 0 && (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th>Symptom</th>
                              {role == "SuperAdmin" && (
                                <>
                                  <th width="12%">Created By</th>
                                  <th width="12%">Move to Global list</th>
                                </>
                              )}
                              <th width="5%">Edit</th>
                              <th width="5%">Delete</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item, index) => {
                              return (
                                <tr key={index}>
                                  <td>{sno++}</td>
                                  <td className="align-left">
                                    {item.complaints}
                                  </td>
                                  {role == "SuperAdmin" && (
                                    <>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail
                                          ? item.owner
                                          : ""}
                                      </td>
                                      <td className="pointer">
                                        {item.owner != string.adminEmail && (
                                          <a
                                            onClick={(e) => {
                                              handleMove(item.name);
                                            }}
                                          >
                                            Move
                                          </a>
                                        )}
                                      </td>
                                    </>
                                  )}
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          closeErrors();
                                          setComplaintName(item.name);
                                          setComplaint(item.complaints);
                                          setSaveOpenModal(true);
                                        }}
                                      >
                                        Edit
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Edit
                                      </span>
                                    )}
                                  </td>
                                  <td className="pointer">
                                    {item.owner == practitioner ||
                                    role == "SuperAdmin" ? (
                                      <a
                                        onClick={() => {
                                          handleDeleteSymptom(item.name);
                                          setSymptomSel(0);
                                        }}
                                      >
                                        Delete
                                      </a>
                                    ) : (
                                      <span
                                        style={{
                                          color: "gray",
                                          opacity: "0.2",
                                        }}
                                      >
                                        Delete
                                      </span>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <table
                        style={{
                          borderBottom: 0,
                          backgroundColor: "#f6f4f4",
                        }}
                      >
                        <tbody>
                          <tr>
                            <td className="pt-4">
                              <Pagination
                                itemsCount={totalCount}
                                pageSize={pageSize}
                                currentPage={currentPage}
                                onPageChange={(page) => handlePageChange(page)}
                                style={{ cursor: "pointer" }}
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
              <>
                <Modal show={saveOpenModal}>
                  <Modal.Header>
                    <Modal.Title>Symptom</Modal.Title>
                    <span
                      className="icofont-close-line pointer"
                      style={{ fontSize: "25px", fontWeight: "bold" }}
                      onClick={(e) => {
                        setSaveOpenModal(false);
                        getSymptomsList(0, "[]");
                      }}
                    ></span>
                  </Modal.Header>
                  <Modal.Body style={{ width: "100%" }}>
                    <div id="modelElement">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-lg-4">
                          <span className="star">*</span> Symptom:
                        </label>
                        <div className="col-lg-6">
                          <CustomTextInput
                            type="text"
                            maxlength="140"
                            name="complaint"
                            id="complaint"
                            className="form-control"
                            value={complaint}
                            onChange={(e) => {
                              setComplaintError(false);
                              setComplaint(e.target.value);
                            }}
                            placeholder="Symptom"
                            onKeyUp={(e) =>
                              e.keyCode == 13 && handleSaveComplaint()
                            }
                          />
                          <ErrorMessage
                            error={"Please enter the Symptom"}
                            visible={complaintError}
                          />
                        </div>
                      </div>
                      <div className="row no-gutters App">
                        <ShowMessage
                          view={complaintSuccess}
                          Message={message}
                        />
                      </div>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      className="btn"
                      title="Close"
                      onClick={() => {
                        handleSaveComplaint();
                        setSymptomSel(0);
                      }}
                    >
                      <span className="icofont-save"></span>
                      Save
                    </button>
                  </Modal.Footer>
                </Modal>
              </>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default SymptomsList;
