import client from "./client";
import superadminclient from "./superadminclient";

const verifyEmail = async (doctorID, email_verification) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      email_verification,
    }
  );
  return data;
};

const doctorLeaveUpdate = async (doctorID, leave_from_date, leave_to_date) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      leave_from_date,
      leave_to_date,
    }
  );
  return data;
};

const updatebasicInformation = async (
  doctorID,
  registered_practitioner_number,
  // first_name,
  // last_name,
  // email,
  gender,
  date_of_birth,
  degree,
  department,
  // date_of_joining,
  image,
  work_experience,
  profile_completeness
) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      registered_practitioner_number,
      // first_name,
      // last_name,
      // email,
      gender,
      date_of_birth,
      degree,
      department,
      // date_of_joining,
      image,
      work_experience,
      profile_completeness,
    }
  );
  return data;
};

const imageUpload = async (docname, filename, filedata) => {
  const data = await client.post("/api/method/upload_file", {
    cmd: "uploadfile",
    doctype: "Healthcare Practitioner",
    docname,
    filename,
    from_form: 1,
    filedata,
  });
  return data;
};

const editImageUrl = async (filename, file_url) => {
  const data = await client.put(`/api/method/upload_file/${filename}`, {
    file_url,
  });
  return data;
};

const imageUploadProfile = async (filename, filedata) => {
  const data = await superadminclient.post("/api/method/upload_file", {
    cmd: "uploadfile",
    doctype: "File",
    filename,
    from_form: 1,
    filedata,
  });
  return data;
};
const updateFile = async (doctorID, image) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      image,
    }
  );
  return data;
};

const attachImage = async (doctorID) => {
  const data = await client.get(
    `/api/resource/File?fields=["*"]&filters=[["attached_to_name",
    "=","${doctorID}"],["attached_to_field","!=","image"]]`
  );
  return data;
};

const getAttachedImageByFileName = async (doctorID, filename) => {
  const data = await client.get(
    `/api/resource/File?fields=["*"]&filters=[["attached_to_name", "=","${doctorID}"],["file_name","like","%${filename}%"],["attached_to_field","!=","image"]]&limit_page_length=1&order_by=Creation desc`
  );
  return data;
};

const getDetilsBasicInfoByAdmin = async (doctorID) => {
  const data = await superadminclient.get(
    `/api/resource/Healthcare Practitioner/${doctorID}`
  );
  return data;
};

const getDetilsBasicInfo = async (doctorID) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner/${doctorID}`
  );
  return data;
};

const getClinicInfo = async (doctorID, op_consulting_charge, hospital) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      op_consulting_charge,
      hospital,
    }
  );
  return data;
};

const updateProfileComplete = async (
  doctorID,
  profile_completeness,
  document_submit_on
) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      profile_completeness,
      document_submit_on,
    }
  );
  return data;
};

const updateCompleteProfile = async (
  doctorID,
  profile_completeness,
  status
) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      profile_completeness,
      status,
    }
  );
  return data;
};

const updateFee = async (
  doctorID,
  hospital,
  op_consulting_charge,
  profile_completeness,
  office_phone
) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      hospital,
      op_consulting_charge,
      profile_completeness,
      office_phone,
    }
  );
  return data;
};

const createSchedule = async (owner, schedule_name, time_slots) => {
  const data = await client.post(`/api/resource/Practitioner Schedule`, {
    parent: "Practitioner Schedule",
    schedule_name,
    doctype: "Practitioner Schedule",
    time_slots,
    owner,
  });
  return data;
};

const createScheduleByAdmin = async (owner, schedule_name, time_slots) => {
  const data = await client.post(`/api/resource/Practitioner Schedule`, {
    parent: "Practitioner Schedule",
    schedule_name,
    doctype: "Practitioner Schedule",
    time_slots,
    owner,
  });
  return data;
};

const createSchedulePut = async (schedule_name, time_slots) => {
  const data = await client.put(
    `/api/resource/Practitioner Schedule/${schedule_name}`,
    {
      parent: "Practitioner Schedule",
      schedule_name,
      doctype: "Practitioner Schedule",
      time_slots,
    }
  );
  return data;
};

// const assignSchedule = async (doctorID, schedule) => {
const assignSchedule = async (doctorID, schedule, service_unit) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${doctorID}`,
    {
      practitioner_schedules: [
        {
          parent: doctorID,
          parentfield: "practitioner_schedules",
          parenttype: "Healthcare Practitioner",
          schedule,
          // service_unit: "Cardiology-OPD - B",
          service_unit,
          doctype: "Practitioner Service Unit Schedule",
        },
      ],
    }
  );
  return data;
};

const appointmentInfo = async (schedule) => {
  const data = await client.get(
    `/api/resource/Practitioner Schedule/${schedule}`
  );
  return data;
};

const scheduleInfo = async (schedule) => {
  const data = await client.get(
    `/api/resource/Practitioner Schedule?fields=["*"]&filters=[["name","=","${schedule}"]]`
  );
  return data;
};

const createCompany = async (company_name, email) => {
  const data = await client.post(`/api/resource/Company`, {
    company_name,
    abbr: email,
    domain: "Healthcare",
    default_currency: "INR",
    country: "India",
    default_holiday_list: "Clinic Holiday List",
    // default_letter_head: company_name,
  });
  return data;
};

const getCompanyByName = async (name) => {
  const data = await client.get(`api/resource/Company/${name}`);
  return data;
};

const getCompanyByAbbr = async (doctorId) => {
  const data = await client.get(
    `api/resource/Company?filters=[["abbr","=","${doctorId}"]]&fields=["*"]`
  );
  return data;
};

const getCompany = async (company_name) => {
  const data = await client.get(
    `api/resource/Company?filters=[["name","=","${company_name}"]]&fields=["*"]`
  );
  return data;
};

const getCompanyDetails = async (company_name) => {
  console.log("company_name---", company_name);
  const data = await client.get(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.search_clinic_list`,
    {
      company_name,
    }
    // `api/resource/Company?filters=[["company_name","=","${company_name}"]]&fields=["*"]&order_by=creation`
  );
  return data;
};

const updateCompanyDoctor = async (email, hospital) => {
  const data = await client.put(
    `/api/resource/Healthcare Practitioner/${email}`,
    {
      hospital,
    }
  );
  return data;
};

const getUserPermission = async (user, for_value) => {
  const data = await client.get(
    `/api/resource/User Permission?filters=[["user","=","${user}"],["for_value","=","${for_value}"]]`
  );
  return data;
};

const userPermission = async (user, for_value) => {
  const data = await client.post(`/api/resource/User Permission`, {
    user,
    allow: "Company",
    for_value,
    is_default: 1,
  });
  return data;
};

const userPermissionCheck = async (user, hospital) => {
  const data =
    await client.get(`/api/resource/User Permission?fields=["user","allow","for_value"]
    &filters=[["user","=","${user}"],["allow","=","Company"],["for_value","=","${hospital}"]]`);
  return data;
};

const appointmentSettings = async (
  email,
  company_name,
  op_consulting_charge,
  schedule_name,
  time_slots
) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.create_practitioner_schedule",
    {
      email,
      company_name,
      op_consulting_charge,
      schedule_name,
      time_slots,
    }
  );
  return data;
};

const selectDepartment = async () => {
  const data = await client.get(
    `/api/resource/Medical Department?limit_page_length=None&order_by=name asc`
  );
  return data;
};

const mobileOtpDoctor = async (mobile_number) => {
  const data = await superadminclient.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.practitioner_signin",
    {
      mobile_number,
      os_name: "Web",
    }
  );
  return data;
};

const registeredMobile = async (mobile) => {
  const data = await client.get(
    `/api/resource/User?fields=["email","mobile_no"]&filters=[["mobile_no","=","${mobile}"]]
    &limit_page_length=None`
  );

  return data;
};

const checkOldMobileInDoctor = async (mobile) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner?fields=["email","mobile_phone"]&filters=[["mobile_phone","=","${mobile}"]]&limit_page_length=None`
  );

  return data;
};

const checkEmail = async (email, mobile) => {
  const data = await client.get(
    `/api/resource/Healthcare Practitioner/${email}?filters=[["mobile_no","=", "${mobile}"]]&fields=["*"]`
  );
  // const data =
  //   await client.get(`/api/resource/User?filters=[["name","=","${email}"]]
  //   &fields=["name","reset_password_key", "full_name"]`);
  return data;
};

const addAddress = async (
  doctorId,
  address_line1,
  address_line2,
  city,
  state,
  pincode,
  phone
) => {
  const data = await client.post(`/api/resource/Address`, {
    address_title: doctorId,
    address_type: "Office",
    address_line1,
    address_line2,
    city,
    state,
    country: "India",
    pincode,
    is_your_company_address: 0,
    phone,
    links: [
      {
        link_doctype: "Healthcare Practitioner",
        link_name: doctorId,
      },
    ],
  });
  return data;
};

const getAddress = async (address_title) => {
  const data = await client.get(
    `/api/resource/Address?filters=[["address_title","=","${address_title}"]]&fields=["*"]`
  );
  return data;
};

const getAddressByCompany = async (filter) => {
  const data = await client.get(
    `/api/resource/Address?or_filters=${filter}&fields=["*"]`
  );
  return data;
};

const updateAddress = async (
  doctorId,
  address_line1,
  address_line2,
  city,
  state,
  pincode,
  phone
) => {
  const data = await client.put(`/api/resource/Address/${doctorId}`, {
    address_line1,
    address_line2,
    city,
    state,
    pincode,
    phone,
  });
  return data;
};
const getPincodeDetail = async (pincode) => {
  const data = await client.get(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_pincode_info`,
    {
      pincode,
    }
  );
  return data;
};

const getLogo = async (company) => {
  const data = await client.get(
    `/api/resource/File?fields=["*"]&filters=[["attached_to_name","=","${company}"]]`
  );
  return data;
};
const createLetterHead = async (letter_head_name, image) => {
  const data = await client.post("/api/resource/Letter Head", {
    letter_head_name,
    source: "Image",
    image,
    is_default: 0,
  });
  return data;
};
const attachLetterHead = async (letter_head_name, image) => {
  const data = await client.put(
    `/api/resource/Letter Head/${letter_head_name}`,
    {
      image,
    }
  );
  return data;
};

const getLetterPad = async (company) => {
  const data = await client.get(
    `/api/resource/Letter Head?filters=[["name","=","${company}"]]&fields=["*"]`
  );
  return data;
};

const sendApprovalEmail = async (doctor_name, doctor_email) => {
  const data = await client.post(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_activation_email`,
    {
      doctor_name,
      doctor_email,
    }
  );
  return data;
};

const sendEmailVerification = async (doctor_name, doctor_email) => {
  const data = await client.post(
    "api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_verification_email",
    {
      doctor_name,
      doctor_email,
    }
  );
  return data;
};

const sendEmail = async (subject, sender, recipients, content) => {
  const data = await client.post(
    "/api/method/frappe.core.doctype.communication.email.make",
    {
      subject,
      sender,
      recipients,
      content,
      send_email: 1,
    }
  );
  return data;
};

const getAdminConfig = async () => {
  const data = await client.get(
    `/api/resource/MyClinic Config?filters=[["field","=","to_email"],["is_active","=","1"]]&fields=["value"]`
  );
  return data;
};

export default {
  updatebasicInformation,
  imageUpload,
  imageUploadProfile,
  updateFile,
  attachImage,
  getDetilsBasicInfo,
  getClinicInfo,
  createSchedule,
  createSchedulePut,
  assignSchedule,
  appointmentInfo,
  createCompany,
  userPermission,
  updateFee,
  appointmentSettings,
  selectDepartment,
  mobileOtpDoctor,
  registeredMobile,
  getCompany,
  checkOldMobileInDoctor,
  userPermissionCheck,
  checkEmail,
  scheduleInfo,
  addAddress,
  getAddress,
  updateAddress,
  getPincodeDetail,
  getLogo,
  createLetterHead,
  attachLetterHead,
  getLetterPad,
  editImageUrl,
  sendEmail,
  verifyEmail,
  updateProfileComplete,
  getAttachedImageByFileName,
  getCompanyDetails,
  getAddressByCompany,
  updateCompanyDoctor,
  getCompanyByName,
  getUserPermission,
  getAdminConfig,
  updateCompleteProfile,
  createScheduleByAdmin,
  sendApprovalEmail,
  sendEmailVerification,
  getCompanyByAbbr,
  doctorLeaveUpdate,
  getDetilsBasicInfoByAdmin,
};
