import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactEcharts from "echarts-for-react";
import {
  RadialBarChart,
  RadialBar,
  Legend,
  Tooltip,
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
} from "recharts";
import moment from "moment";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { dayShortNames } from "../component/common/CommonArray";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import storage from "../auth/storage";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";

import DashAppIcon from "../assests/svg/dashappicon.svg";
import PatientIcon from "../assests/svg/dashpatienticon.svg";
import DashConsultIcon from "../assests/svg/dashconsulticon.svg";
import DashEarnIcon from "../assests/svg/dashearningicon.svg";
import Footer from "../component/Footer";
import string from "../string";
// import ArrowDownIcon from "../assests/svg/ArrowLineDown.svg";

const firebaseConfig = {
  apiKey: "AIzaSyAqVW5ftBVM3K_-Wi35N0oY2EcZceGT_AE",
  // apiKey: "AIzaSyBV23hqQLncXY_dNO_tBGqbhK_ATufCnqg",
  authDomain: "myclinic-90f4a.firebaseapp.com",
  projectId: "myclinic-90f4a",
  storageBucket: "myclinic-90f4a.appspot.com",
  messagingSenderId: "1018834380654",
  appId: "1:1018834380654:web:9e254bddb9319ec932837f",
  measurementId: "G-ST489BY6Z6",
};

const prefixerror = "DoctorDashBoard";

function DoctorDashBoard() {
  //#region const
  const navigate = useNavigate();
  const [upcomingApp, setUpcomingApp] = useState([]);
  const [requestedApp, setRequestedApp] = useState([]);
  const [patient, setPatient] = useState();
  const [patientRecord, setPatientRecord] = useState();
  const [currentAppt, setCurrentAppt] = useState();
  const [weekChartData, setWeekChartData] = useState({});
  const [rechartData, setRechartData] = useState([]);
  const [pieGenderData, setPieGenderData] = useState([]);
  const [pieData, setPieData] = useState([]);
  const [role, setRole] = useState("");
  const [dash, setDash] = useState("day");
  const [earning, setEarning] = useState(0);
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const getTodayDate = moment(new Date()).format("yyyy-MM-DD");
  const Desktop = window.screen.width > 830 ? true : false;
  const Mobile = window.screen.width < 400 ? true : false;
  //#endregion

  const COLORS = ["#34C759", "#FAA835", "#3B82F6", "#dc3545", "#20c997"];

  const getHomePagData = async (dash) => {
    try {
      const email = await storage.getOwner();
      const company = await storage.getCompany();
      const role = await storage.getRole();

      let patientcnt = 0,
        opencnt = 0,
        closedcnt = 0,
        earning = 0;

      if (dash == "day") {
        let response;
        if (role != string.DOCTOR_ROLE) {
          response = await doctorApi.homepageDataToday("", company);
        } else {
          response = await doctorApi.homepageDataToday(email, "");
        }
        console.log("response---", response);
        if (!response.ok) {
          ErrorLog(
            prefixerror + "getHomePage " + "homepageDataToday",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "email:" + email
          );
          return;
        }
        patientcnt = response.data.message.Patient;
        opencnt = response.data.message.Open;
        closedcnt = response.data.message.Closed;
        earning = response.data.message.Earnings;
      } else if (dash == "week") {
        let response;
        if (role != string.DOCTOR_ROLE) {
          response = await doctorApi.homepageDataWeek("", company);
        } else {
          response = await doctorApi.homepageDataWeek(email, "");
        }
        console.log("response---", response);
        if (!response.ok) {
          ErrorLog(
            prefixerror + "getHomePage " + "homepageDataWeek",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "email:" + email
          );
          return;
        }
        patientcnt = response.data.message.Patient;
        opencnt = response.data.message.Open;
        closedcnt = response.data.message.Closed;
        earning = response.data.message.Earnings;
      } else {
        let response;
        if (role != string.DOCTOR_ROLE) {
          response = await doctorApi.homepageDataMonth("", company);
        } else {
          response = await doctorApi.homepageDataMonth(email, "");
        }
        console.log("response---", response);
        if (!response.ok) {
          ErrorLog(
            prefixerror + "getHomePagData " + "homepageDataMonth",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "email:" + email
          );
          return;
        }
        patientcnt = response.data.message.Patient;
        opencnt = response.data.message.Open;
        closedcnt = response.data.message.Closed;
        earning = response.data.message.Earnings;
      }

      setPatient(patientcnt);
      setCurrentAppt(opencnt);
      setPatientRecord(closedcnt);
      setEarning(earning);
    } catch (error) {
      ErrorLog(
        prefixerror + "getHomePagData ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getHomePage = async () => {
    try {
      const user = await storage.getName();
      setName(user);

      const cName = await storage.getCompanyFullName();
      setCompanyName(cName);
      const doctorID = await storage.getID();
      const company = await storage.getCompany();
      const doctorRes = await doctorRegApi.getDetilsBasicInfo(doctorID);
      console.log("doctorRes----", doctorRes);
      if (!doctorRes.ok) {
        ErrorLog(
          prefixerror + "getHomePage " + "getDetilsBasicInfo",
          JSON.stringify(doctorRes),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID
        );
        return;
      }
      if (doctorRes.data.data.status != "Active") {
        navigate("/logout");
        return;
      }
      const role = await storage.getRole();
      if (role === "SuperAdmin") {
        navigate("/doctorlist");
        return;
      }
      setRole(role);

      let appres;
      if (role != "Practitioner Permission") {
        appres = await doctorApi.getAppointmentByClinic(company, getTodayDate);
      } else {
        appres = await doctorApi.filterDateConsultation(doctorID, getTodayDate);
      }
      console.log("appres---", appres);
      if (!appres.ok) {
        ErrorLog(
          prefixerror + "getHomePage " + "filterDateConsultation",
          JSON.stringify(appres),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + doctorID + "getTodayDate:" + getTodayDate
        );
        return;
      }

      let requestedAppointment = [];
      let openAppointment = [];
      for (let i = 0; i < appres.data.data.length; i++) {
        if (
          appres.data.data[i].requested &&
          (appres.data.data[i].consultation_type == "Walk In" ||
            (appres.data.data[i].consultation_type == "Online" &&
              appres.data.data[i].paid_status))
        ) {
          requestedAppointment = [...requestedAppointment, appres.data.data[i]];
        } else if (
          appres.data.data[i].consultation_type == "Walk In" ||
          (appres.data.data[i].consultation_type == "Online" &&
            appres.data.data[i].paid_status)
        )
          openAppointment = [...openAppointment, appres.data.data[i]];
      }
      console.log("requestedAppointment--- ", requestedAppointment);
      console.log("openAppointment--- ", openAppointment);
      setUpcomingApp(openAppointment);
      setRequestedApp(requestedAppointment);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + "getHomePage ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = async (appointmentID) => {
    navigate("/startconsultation", { state: { appointmentID } });
  };

  const getPatientList = async () => {
    try {
      var todayDate = new Date();
      const doctorID = await storage.getID();
      var first = todayDate.getDate() - todayDate.getDay(); // First day is the day of the month - the day of the week
      var beforeweekfirst = first - 7;
      var firstday = new Date(todayDate.setDate(beforeweekfirst));

      const role = await storage.getRole();
      const company = await storage.getCompany();
      let res;
      if (role != string.DOCTOR_ROLE) {
        res = await doctorApi.patientDataHomePageByCompany(
          company,
          moment(firstday).format("yyyy-MM-DD")
        );
        console.log("patientDataHomePageByCompany----", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getHomePage " + "patientDataHomePageByCompany",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "date:" + moment(firstday).format("yyyy-MM-DD")
          );
          return;
        }
      } else {
        res = await doctorApi.patientDataHomePage(
          doctorID,
          moment(firstday).format("yyyy-MM-DD")
        );
        console.log("patientDataHomePage----", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "getHomePage " + "patientDataHomePage",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "date:" + moment(firstday).format("yyyy-MM-DD")
          );
          return;
        }
      }

      let i, j;
      let patientCnt = [];
      let fday = firstday;
      for (i = 0; i < 14; i++) {
        fday = moment(fday).format("yyyy-MM-DD");
        var myObject = {
          cnt: 0,
          date: fday,
        };
        patientCnt.push(myObject);
        fday = moment(firstday).add(i + 1, "days");
      }
      for (i = 0; i < res.data.data.length; i++) {
        patientCnt.map((item, index) => {
          if (item.date == res.data.data[i].encounter_date) {
            item.cnt++;
          }
        });
      }
      let data1 = [];
      let data2 = [];
      i = 0;
      j = 0;
      patientCnt.map((item, index) => {
        if (index < 7) {
          data1[i++] = item.cnt;
        } else {
          data2[j++] = item.cnt;
        }
      });

      const hospitalOptions = {
        color: ["#FF9500", "#3C82F7"],
        tooltip: {
          trigger: "none",
          axisPointer: {
            type: "cross",
          },
        },
        legend: {
          data: ["patients last week", "patients current week"],
        },
        grid: {
          left: 30,
          right: 0,
          top: 50,
          bottom: 50,
        },
        xAxis: [
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#3C82F7",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    "patients " +
                    params.value +
                    (params.seriesData.length
                      ? "：" + params.seriesData[0].data
                      : "")
                  );
                },
              },
            },
            data: dayShortNames,
          },
          {
            type: "category",
            axisTick: {
              alignWithLabel: true,
            },
            axisLine: {
              onZero: false,
              lineStyle: {
                color: "#FF9500",
              },
            },
            axisPointer: {
              label: {
                formatter: function (params) {
                  return (
                    "patients " +
                    params.value +
                    (params.seriesData.length
                      ? "：" + params.seriesData[0].data
                      : "")
                  );
                },
              },
            },
            data: dayShortNames,
          },
        ],
        yAxis: [
          {
            type: "value",
          },
        ],
        series: [
          {
            name: "patients last week",
            type: "line",
            xAxisIndex: 1,
            smooth: true,
            data: data1,
          },
          {
            name: "patients current week",
            type: "line",
            smooth: true,
            data: data2,
          },
        ],
      };
      setWeekChartData(hospitalOptions);

      const date = new Date();
      var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
      let monthres;
      if (role != string.DOCTOR_ROLE) {
        monthres = await doctorApi.getAllInvoiceWithDateByCompany(
          company,
          moment(firstDay).format("yyyy-MM-DD"),
          moment().format("yyyy-MM-DD")
        );
        console.log("monthres=-----", monthres);
        if (!monthres.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getAllInvoiceWithDateByCompany",
            JSON.stringify(monthres),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      } else {
        monthres = await doctorApi.getAllInvoiceWithDate(
          moment(firstDay).format("yyyy-MM-DD"),
          moment().format("yyyy-MM-DD")
        );
        console.log("monthres=-----", monthres);
        if (!monthres.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getAllInvoiceWithDate",
            JSON.stringify(monthres),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      }
      let monthTot = 0;
      for (let i = 0; i < monthres.data.data.length; i++) {
        monthTot += monthres.data.data[i].grand_total;
      }

      var todayDate = new Date();
      var first = todayDate.getDate() - todayDate.getDay();
      var beforeweekfirst = first - 7;
      var firstday = new Date(todayDate.setDate(beforeweekfirst));

      let invres;
      if (role != string.DOCTOR_ROLE) {
        invres = await doctorApi.getTotalInvoiceByCompany(company);
        console.log("invres---", invres);
        if (!invres.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getTotalInvoiceByCompany",
            JSON.stringify(invres),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      } else {
        invres = await doctorApi.getTotalInvoice();
        console.log("invres---", invres);
        if (!invres.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getTotalInvoice",
            JSON.stringify(invres),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      }

      let invoiceCnt = [];
      fday = firstday;
      for (let i = 0; i < 14; i++) {
        fday = moment(fday).format("yyyy-MM-DD");
        var myObject = {
          cnt: 0,
          date: fday,
        };
        invoiceCnt.push(myObject);
        fday = moment(firstday).add(i + 1, "days");
      }
      for (let i = 0; i < invres.data.data.length; i++) {
        invoiceCnt.map((item, index) => {
          if (item.date == invres.data.data[i].posting_date) {
            item.cnt = item.cnt + invres.data.data[i].grand_total;
          }
        });
      }

      data1 = [];
      data2 = [];
      let currentIncome = 0;
      let prevIncome = 0;
      i = 0;
      j = 0;
      invoiceCnt.map((item, index) => {
        if (index < 7) {
          prevIncome = prevIncome + item.cnt;
          data1[i++] = item.cnt;
        } else {
          currentIncome = currentIncome + item.cnt;
          data2[j++] = item.cnt;
        }
      });

      const rechartdata = [
        { name: "Current Week", Earnings: currentIncome, fill: "#34C759" },
        { name: "Last Week", Earnings: prevIncome, fill: "#FAA835" },
        { name: "Current Month", Earnings: monthTot, fill: "#3B82F6" },
      ];
      setRechartData(rechartdata);

      let allRes;
      if (role != string.DOCTOR_ROLE) {
        allRes = await doctorApi.getPatientByCompany(company);
        if (!allRes.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getPatientByCompany",
            JSON.stringify(allRes),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      } else {
        allRes = await doctorApi.getPatient();
        if (!allRes.ok) {
          ErrorLog(
            prefixerror + "getPatientList " + "getPatient",
            JSON.stringify(allRes),
            "We are facing some issues.Please try again after sometime."
          );
          return;
        }
      }
      let femaleCount = 0;
      let maleCount = 0;

      for (let i = 0; i < allRes.data.data.length; i++) {
        if (allRes.data.data[i].sex === "Male") maleCount++;
        else femaleCount++;
      }

      const pieGenderData = [
        { value: femaleCount, name: "Female" },
        { value: maleCount, name: "Male" },
      ];
      setPieGenderData(pieGenderData);

      let count10 = 0;
      let count20 = 0;
      let count30 = 0;
      let count40 = 0;
      let countAbove40 = 0;

      for (let i = 0; i < allRes.data.data.length; i++) {
        let age = moment().diff(allRes.data.data[i].dob, "years");
        if (age <= 10) count10++;
        else if (age > 10 && age <= 20) count20++;
        else if (age > 20 && age <= 30) count30++;
        else if (age > 30 && age <= 40) count40++;
        else if (age > 40) countAbove40++;
      }

      const pieData = [
        { value: count10, name: "0-10" },
        { value: count20, name: "10-20" },
        { value: count30, name: "20-30" },
        { value: count40, name: "30-40" },
        { value: countAbove40, name: "40+" },
      ];
      setPieData(pieData);
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getPatientList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getHomePage();
    getHomePagData("day");
    getPatientList();
    const app = initializeApp(firebaseConfig);
    // console.log("firebaseConfig----", firebaseConfig);
    const analytics = getAnalytics(app);
    // console.log("analytics----", analytics);
  }, []);

  return (
    <>
      <section>
        <div className="row no-gutters">
          <div className="col-lg-2">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd">
                {role === string.DOCTOR_ROLE ? (
                  <>
                    <div className="row dashnametext">Hello {name},</div>
                    <div className="row mb-5 dashlooktext">
                      {companyName.toUpperCase()}
                    </div>
                  </>
                ) : (
                  <div className="row dashnametext">
                    {companyName.toUpperCase()}
                  </div>
                )}

                <div className="row no-gutters">
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "day" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getHomePagData("day");
                      setDash("day");
                    }}
                  >
                    <span className="dashhead">Today</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "week" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getHomePagData("week");
                      setDash("week");
                    }}
                  >
                    <span className="dashhead">This Week</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "month" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getHomePagData("month");
                      setDash("month");
                    }}
                  >
                    <span className="dashhead">This Month</span>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div className="countbox row border p-3">
                    <div
                      onClick={() =>
                        role != "Billing" &&
                        navigate("/upcomingappointment", {
                          state: { dash },
                        })
                      }
                      className="pointer col-md-6 col-lg-3 p-3"
                      style={{
                        borderRight: Mobile ? "0px" : "1px solid #dee2e6",
                      }}
                    >
                      <div className="dashcount row no-gutters">
                        <div>
                          <span style={{ float: "left" }}>{currentAppt}</span>
                          <span style={{ float: "right" }}>
                            <img src={DashAppIcon} width={50} height={50} />
                          </span>
                        </div>
                      </div>
                      <div className="counthead row">Appointment</div>
                    </div>
                    <div
                      onClick={() =>
                        navigate("/patientlist", {
                          state: { dash },
                        })
                      }
                      className="col-md-6 col-lg-3 p-3 pointer"
                      style={{
                        borderRight: Desktop ? "1px solid #dee2e6" : "0px",
                      }}
                    >
                      <div className="dashcount row no-gutters">
                        <div>
                          <span style={{ float: "left" }}>{patient}</span>
                          <span style={{ float: "right" }}>
                            <img src={PatientIcon} width={50} height={50} />
                          </span>
                        </div>
                      </div>
                      <div className="counthead">New Patient</div>
                    </div>
                    <div
                      className="col-md-6 col-lg-3 p-3 pointer"
                      onClick={() =>
                        navigate("/consultationhistory", {
                          state: { dash },
                        })
                      }
                      style={{
                        borderRight: Mobile ? "0px" : "1px solid #dee2e6",
                      }}
                    >
                      <div className="dashcount row no-gutters">
                        <div>
                          <span style={{ float: "left" }}>{patientRecord}</span>
                          <span style={{ float: "right" }}>
                            <img src={DashConsultIcon} width={50} height={50} />
                          </span>
                        </div>
                      </div>
                      <div className="counthead">Consultation</div>
                    </div>
                    <div
                      className="col-md-6 col-lg-3 p-3 pointer"
                      onClick={() =>
                        role != "Receptionist" &&
                        navigate("/invoicereport", {
                          state: { dash },
                        })
                      }
                    >
                      <div className="dashcount row no-gutters">
                        <div>
                          <span style={{ float: "left" }}>
                            ₹ {earning ? earning : "0"}
                          </span>
                          <span style={{ float: "right" }}>
                            <img src={DashEarnIcon} width={50} height={50} />
                          </span>
                        </div>
                      </div>
                      <div className="counthead">Earning</div>
                    </div>
                  </div>
                </div>
                <div className="row border mt-5 mb-5">
                  <div className="row">
                    <div className="card-head-title mt-3">
                      Patient Analytics
                    </div>
                  </div>
                  <div className="row no-gutters ps-lg-5 pe-lg-5">
                    <ReactEcharts
                      className="chart-container container-h-400"
                      option={weekChartData}
                    />
                  </div>
                </div>
                <div className="row no-gutters">
                  <div
                    className="col-lg-3 border"
                    style={{
                      height: "420px",
                      width: Desktop ? "28%" : "100%",
                      marginRight: "8%",
                    }}
                  >
                    <div className="row card-head-title mt-2 ms-3">Income</div>
                    <div className="row no-gutters">
                      <ResponsiveContainer width="100%" height={300}>
                        <RadialBarChart
                          cx={"50%"}
                          cy={"43%"}
                          innerRadius={50}
                          outerRadius={150}
                          barSize={12}
                          data={rechartData}
                          startAngle={270}
                          endAngle={-90}
                        >
                          <RadialBar
                            minAngle={15}
                            label={{
                              position: "insideStart",
                              fill: "#fff",
                            }}
                            background
                            dataKey="Earnings"
                          />
                          <Tooltip />
                          <Legend
                            iconSize={10}
                            iconType="circle"
                            layout="horizontal"
                            verticalAlign="bottom"
                            align="center"
                          />
                        </RadialBarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 border"
                    style={{
                      width: Desktop ? "28%" : "100%",
                      marginRight: "8%",
                    }}
                  >
                    <div className="row card-head-title mt-2 ms-3">
                      Patients Age
                    </div>
                    <div className="row no-gutters">
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Pie
                            data={pieData}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            fill="#8884d8"
                          >
                            {pieData.map((entry, index) => (
                              <Cell
                                style={{ border: "0px" }}
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend iconSize={10} iconType="circle" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                  <div
                    className="col-lg-3 border"
                    style={{ width: Desktop ? "28%" : "100%" }}
                  >
                    <div className="row card-head-title mt-2 ms-3">
                      Patients Gender
                    </div>
                    <div className="row no-gutters">
                      <ResponsiveContainer width="100%" height={300}>
                        <PieChart>
                          <Pie
                            data={pieGenderData}
                            color="#000000"
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={120}
                            fill="#8884d8"
                          >
                            {pieGenderData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={COLORS[index % COLORS.length]}
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend iconSize={10} iconType="circle" />
                        </PieChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
                {/* <div className="row no-gutters">
                  <table style={{ width: "100%" }}>
                    <tr>
                      <td
                        className="border"
                        style={{
                          width: "30%",
                          height: "430px",
                          verticalAlign: "top",
                        }}
                      >
                        <div className="row card-head-title mt-1 ms-2">
                          Income
                        </div>
                        <div className="row no-gutters">
                          <ResponsiveContainer width="100%" height={300}>
                            <RadialBarChart
                              cx={"50%"}
                              cy={"43%"}
                              innerRadius={50}
                              outerRadius={150}
                              barSize={12}
                              data={rechartData}
                              startAngle={270}
                              endAngle={-90}
                            >
                              <RadialBar
                                minAngle={15}
                                label={{
                                  position: "insideStart",
                                  fill: "#fff",
                                }}
                                background
                                dataKey="Earnings"
                              />
                              <Tooltip />
                              <Legend
                                iconSize={10}
                                iconType="circle"
                                layout="horizontal"
                                verticalAlign="bottom"
                                align="center"
                              />
                            </RadialBarChart>
                          </ResponsiveContainer>
                        </div>
                      </td>
                      <td style={{ width: "5%" }}></td>
                      <td
                        className="border"
                        style={{ width: "30%", verticalAlign: "top" }}
                      >
                        <div className="row card-head-title mt-1 ms-2">
                          Patients Age
                        </div>
                        <div className="row no-gutters">
                          <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                              <Pie
                                data={pieData}
                                color="#000000"
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={120}
                                fill="#8884d8"
                              >
                                {pieData.map((entry, index) => (
                                  <Cell
                                    style={{ border: "0px" }}
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend iconSize={10} iconType="circle" />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </td>
                      <td style={{ width: "5%" }}></td>
                      <td
                        className="border"
                        style={{ width: "30%", verticalAlign: "top" }}
                      >
                        <div className="row card-head-title mt-1 ms-2">
                          Patients Gender
                        </div>
                        <div className="row no-gutters">
                          <ResponsiveContainer width="100%" height={300}>
                            <PieChart>
                              <Pie
                                data={pieGenderData}
                                color="#000000"
                                dataKey="value"
                                nameKey="name"
                                cx="50%"
                                cy="50%"
                                outerRadius={120}
                                fill="#8884d8"
                              >
                                {pieGenderData.map((entry, index) => (
                                  <Cell
                                    key={`cell-${index}`}
                                    fill={COLORS[index % COLORS.length]}
                                  />
                                ))}
                              </Pie>
                              <Tooltip />
                              <Legend iconSize={10} iconType="circle" />
                            </PieChart>
                          </ResponsiveContainer>
                        </div>
                      </td>
                    </tr>
                  </table>
                </div> */}
                <div className="row no-gutters mt-5 border">
                  <div className="row">
                    <span className="card-head-title mt-1">Appointments</span>
                  </div>
                  <div className="row no-gutters p-3 table-responsive">
                    {upcomingApp.length > 0 ? (
                      <table className="table mt-1">
                        <thead>
                          <tr>
                            <th width="1%">No</th>
                            <th width="10%">Practitioner</th>
                            <th width="10%">Date</th>
                            <th width="10%">Time</th>
                            <th width="">Name</th>
                            <th width="10%">Type</th>
                            <th width="15%">Consultation</th>
                            <th width="10%">Video Consultation</th>
                          </tr>
                        </thead>
                        <tbody>
                          {upcomingApp.map((item, index) => (
                            <tr key={index}>
                              <td>{index + 1}</td>
                              <td>{item.practitioner_name}</td>
                              <td className="nowrapWhiteSpace">
                                {moment(item.appointment_date).format(
                                  "DD-MM-yyyy"
                                )}
                              </td>
                              <td>
                                {preFunction.displayTimeFormat(
                                  item.appointment_time
                                )}
                              </td>
                              <td className="nowrapWhiteSpace">
                                {item.patient_name}
                              </td>
                              <td className="nowrapWhiteSpace">
                                {item.consultation_type}
                              </td>
                              <td>
                                {item.status == "Closed" ? (
                                  <button
                                    type="button"
                                    className="btnGreen"
                                    title="Completed"
                                  >
                                    <span className="icofont-tick-mark"></span>
                                    Completed
                                  </button>
                                ) : item.status == "Cancelled" ? (
                                  <button
                                    type="button"
                                    className="btn bg-pink-dark"
                                  >
                                    <span className="icofont-ui-block"></span>
                                    Cancelled
                                  </button>
                                ) : item.status !== "Open" ||
                                  role !== "Practitioner Permission" ? (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Scheduled"
                                  >
                                    <span className="icofont-clock-time"></span>
                                    Scheduled
                                  </button>
                                ) : (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Start Counsultation"
                                    onClick={() => handleClick(item.name)}
                                  >
                                    <span className="icofont-arrow-right"></span>
                                    Start Counsultation
                                  </button>
                                )}
                              </td>
                              <td align="center" valign="middle">
                                {item.online_link && item.online_link != "" ? (
                                  <button
                                    type="submit"
                                    className="btn"
                                    title="Start Counsultation"
                                    onClick={() =>
                                      window.open(item.online_link)
                                    }
                                  >
                                    <span className="icofont-video-cam"></span>
                                    Start GMeet
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <label>No Appointments booked today</label>
                    )}
                    {requestedApp.length > 0 && (
                      <>
                        <div className="row no-gutters mt-2">
                          <h5>Requested Appointments</h5>
                        </div>
                        <div className="table-responsive">
                          <table className="table mt-1">
                            <thead>
                              <tr>
                                <th width="1%">No</th>
                                <th width="10%">Practitioner</th>
                                <th width="10%">Date</th>
                                <th width="10%">Time Slot</th>
                                <th>Patient Name</th>
                                <th width="10%">Type</th>
                              </tr>
                            </thead>
                            <tbody>
                              {requestedApp.map((item, index) => (
                                <tr key={index}>
                                  <td>{index + 1}</td>
                                  <td>{item.practitioner_name}</td>
                                  <td>
                                    {moment(item.appointment_date).format(
                                      "DD-MM-yyyy"
                                    )}
                                  </td>
                                  <td>
                                    {preFunction.displayTimeFormat(
                                      item.appointment_time
                                    )}
                                  </td>
                                  <td>{item.patient_name}</td>
                                  <td>{item.consultation_type}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default DoctorDashBoard;
