import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import PdfComponent from "../component/PdfComponent";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import string from "../string";
import storage from "../auth/storage";
import AuthContext from "../auth/context";
const prefixerror = "ViewInvoice";

function ViewInvoice() {
  //#region const
  const { paymentConfig } = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [load, setLoad] = useState(false);
  const [printData, setPrintData] = useState("");
  const [downloadButton, setDownloadButton] = useState("");
  //#endregion

  const getInvoice = async () => {
    try {
      setLoad(true);
      if (!location.state) {
        navigate("/invoicelist");
      }
      const invoiceId = location.state.invoiceId;
      const res = await doctorApi.getInvoiceDetails(invoiceId);
      console.log("invoice Res---", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getInvoice " + "getInvoiceByName",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "invoiceId:" + invoiceId
        );
        return;
      }
      const appDate = res.data.message[0].posting_date.split("-");
      const invDate = appDate[2] + "-" + appDate[1] + "-" + appDate[0];
      const doctorName = await storage.getUser();

      let pdfContent1 = (
        <div className="row mb-4">
          <div className="col-lg-2"></div>
          <div className="col-lg-6">
            <div className="border printwidth">
              <div style={{ backgroundColor: "#fff" }}>
                <img
                  alt={string.websiteName + " Invoice"}
                  src={string.testbaseUrl + res.data.message[0].letter_image}
                  className="letter-upload"
                />
              </div>
              <div className="row m-2">
                <div className="col-lg-6">
                  <h1>Invoice</h1>
                </div>
                <div className="col-lg-6" style={{ textAlign: "right" }}>
                  <h4 style={{ textAlign: "right" }}>
                    {res.data.message[0].company_name}
                  </h4>
                </div>
              </div>
              <div className="row m-2">
                <div className="col-lg-5">
                  <b> {res.data.message[0].patient_name}</b>
                  <br />
                  {res.data.message[0].patient_address_line1 &&
                    res.data.message[0].patient_address_line1 != "" && (
                      <div>
                        {res.data.message[0].patient_address_line1}
                        {res.data.message[0].patient_address_line2
                          ? ", " + res.data.message[0].patient_address_line2
                          : ""}
                        <br />
                        {res.data.message[0].patient_city}
                        <br />
                        {res.data.message[0].patient_state} -{" "}
                        {res.data.message[0].patient_pincode}
                      </div>
                    )}
                </div>
                <div className="alignRight col-lg-7">
                  <b>{res.data.message[0].practitioner_name}</b>
                  <br />
                  {res.data.message[0].doctor_address_line1 != "" && (
                    <div>
                      {res.data.message[0].doctor_address_line1}
                      {res.data.message[0].doctor_address_line2
                        ? ", " + res.data.message[0].doctor_address_line2
                        : ""}
                      <br />
                      {res.data.message[0].doctor_city}
                      <br />
                      {res.data.message[0].doctor_state} -{" "}
                      {res.data.message[0].doctor_pincode}
                    </div>
                  )}
                </div>
              </div>
              {/* <hr style={{ margin: "1% 5px" }} /> */}
              <div className="row m-2">
                <div className="col-lg-6">
                  <label className="con_sum_lbl">No: </label> {invoiceId}
                </div>
                <div className="col-lg-6 align-right">
                  <label className="con_sum_lbl">Date: </label> {invDate}
                </div>
              </div>

              <div className="row m-2">
                <div className="table-responsive">
                  <table className="table">
                    <thead>
                      <tr>
                        <th width="1%">No</th>
                        <th>Item</th>
                        <th width="5%" className="alignRight">
                          Quantity
                        </th>
                        <th width="10%" className="alignRight">
                          Amount (₹)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {res.data.message[0].items &&
                      res.data.message[0].items.length > 0
                        ? res.data.message[0].items.map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>
                                <td>{item.item_code}</td>
                                <td align="right">{item.qty}</td>
                                <td align="right">{item.amount}</td>
                              </tr>
                            );
                          })
                        : null}
                      <tr>
                        <td colspan="3" className="alignRight">
                          Total
                        </td>
                        <td className="alignRight">
                          {res.data.message[0].total}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4" className="alignRight">
                          {res.data.message[0].in_words.replace(
                            "INR",
                            "Rupees"
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="m-3 alignRight">
                <label className="con_sum_lbl"> Signature</label>
                {res.data.message[0].esign_url &&
                res.data.message[0].esign_url != "" ? (
                  <>
                    <br />
                    <img
                      alt="Signature"
                      src={string.testbaseUrl + res.data.message[0].esign_url}
                      className="eSign"
                    />
                  </>
                ) : (
                  <>
                    <br />
                    <br />
                    <br />
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-4"></div>
        </div>
      );

      let pdfUrl = "";
      if (res.data.message[0].sales_invoice_pdf != "") {
        pdfUrl = string.testbaseUrl + res.data.message[0].sales_invoice_pdf;
      } else {
        const pdfRes = await doctorApi.generateSalesInvoicePdf(invoiceId);
        console.log("pdfRes----", pdfRes);
        // if (!pdfRes.ok) {
        //   ErrorLog(
        //     prefixerror + " getInvoice " + "generateSalesInvoicePdf",
        //     JSON.stringify(pdfRes),
        //     "We are facing some issues.Please try again after sometime.",
        //     "invoiceId:" + invoiceId
        //   );
        //   setLoad(false);
        //   return;
        // }
        if (pdfRes.ok) pdfUrl = string.testbaseUrl + pdfRes.data.message;
      }
      console.log("pdfUrl---", pdfUrl);
      setPrintData(pdfContent1);
      if (pdfUrl != "") {
        const downloadButton = (
          <a
            href={pdfUrl}
            rel="noreferrer"
            target="_blank"
            style={{ color: "#fff", textDecoration: "none" }}
          >
            <button className="btn">
              <span className="icofont-download"></span>Download
            </button>
          </a>
        );
        setDownloadButton(downloadButton);
      }
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      setLoad(false);
      ErrorLog(
        prefixerror + " getInvoice ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getInvoice();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />

      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="mb-4">
                <div className="pd">
                  <PdfComponent
                    data={printData}
                    print={paymentConfig}
                    downloadButton={downloadButton}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ViewInvoice;
