import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import string from "../string";
import DoctorHeader from "./DoctorHeader";
import file from "../assests/svg/file.svg";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
const prefixerror = "PatientHealthRecord";

function PatientHealthRecord() {
  //#region const
  const location = useLocation();
  const [patientData, setPatientData] = useState();
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [patientSel, setPatientSel] = useState([]);
  const [patient, setPatient] = useState([]);
  //#endregion

  const handleGetPatient = async (value1) => {
    try {
      const searchRes = await doctorApi.getPatientDetails(value1);
      if (!searchRes.ok) {
        ErrorLog(
          prefixerror + " handleGetPatient " + "getPatientDetails",
          JSON.stringify(searchRes),
          "We are facing some issues.Please try again after sometime.",
          "value1:" + value1
        );
        return;
      }
      console.log("Patient--", searchRes);
      setLoad(true);
      setPatientData(searchRes.data.data);
      for (let i = 0; i < searchRes.data.data.length; i++) {
        const res1 = await doctorApi.getPatientHealthRecord([
          [searchRes.data.data[0].name],
        ]);
        if (!res1.ok) {
          ErrorLog(
            prefixerror + " handleGetPatient " + "getPatientHealthRecord",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "name:" + searchRes.data.data[0].name
          );
          return;
        }
        console.log(res1, "res1");
        setData(res1.data.message);
        console.log("res1---", res1);
      }
      setLoad(false);
    } catch (error) {
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleGetPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSelectPatient = async (e) => {
    console.log(e, "---e");
    if (e == "") setPatientSel();
    if (e.length > 0) {
      const company = await storage.getCompany();
      setPatientSel(e);
      handleGetPatient(
        `&filters=[["companyid", "=", "${company}"], ["patient_name","=","${
          e[0].patient.split("-")[0]
        }"]]`
      );
      setPatient(e);
      document.getElementById("patient_input").blur();
    }
  };

  const handleSearchPatients = async (value) => {
    try {
      console.log("value---", value);
      setLoad(true);
      setPatient([]);
      setData([]);
      const company = await storage.getCompany();
      if (value.length > 2) {
        const res = await doctorApi.getPatientDetail(value, company);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchPatients " + "getPatientDetail",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        console.log("searchRes--", res.data.data);
        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              patient:
                res.data.data[i].patient_name +
                " - " +
                moment().diff(res.data.data[i].dob, "years") +
                " years - " +
                res.data.data[i].mobile,
            };
            arr.push(myObject);
          }
          setPatient(arr);
        }
      }
      setLoad(false);
    } catch (error) {
      console.log(error);
      setLoad(false);
      ErrorLog(
        prefixerror + " handleSearchPatients ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getPatient = async () => {
    try {
      console.log("location.state--", location.state);
      if (location.state && location.state.patientId !== "") {
        const company = await storage.getCompany();
        handleGetPatient(
          `&filters=[["companyid", "=", "${company}"], ["name","=","${location.state.patientId}"]]`
        );
        let obj = [{ patient: location.state.fullName }];
        setPatientSel(obj);
        setPatient(obj);
      }
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + " getPatient ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getPatient();
    document.getElementById("patient_input") &&
      document.getElementById("patient_input").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10 mb-4">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd">
                <div className="row no-gutters mb-2">
                  <h1>Patient Health Record</h1>
                </div>
                <div className="row no-gutters">
                  <div className="col-sm-10 col-lg-4">
                    <Multiselect
                      name="patient"
                      id="patient"
                      options={patient}
                      showArrow={true}
                      selectedValues={patientSel}
                      onSelect={handleSelectPatient}
                      placeholder={"Patient Name"}
                      displayValue="patient"
                      customCloseIcon={" "}
                      selectionLimit={1}
                      avoidHighlightFirstOption={true}
                      hidePlaceholder={true}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      onSearch={(e) => {
                        setPatientData("");
                        handleSearchPatients(
                          document.getElementById("patient_input").value
                        );
                        handleSelectPatient("");
                      }}
                      emptyRecordMsg={
                        <div className="notFoundSearch">Type to search</div>
                      }
                    />
                  </div>
                </div>
                {patientData &&
                  patientData.map((item, index) => {
                    return (
                      <div className="border p-2 mt-3">
                        <div className="mt-2 mb-3">
                          <div className="row">
                            <label className="con_sum_lbl">
                              Patient Name : {item.patient_name}, {item.sex} /{" "}
                              {moment().diff(item.dob, "years")}
                            </label>
                          </div>
                          <div className="row">
                            <label className="con_sum_lbl col-lg-3">
                              Mobile : {item.mobile}
                            </label>
                            {item.email && (
                              <label className="con_sum_lbl col-lg-6">
                                Email : {item.email}
                              </label>
                            )}
                          </div>
                        </div>
                        {data &&
                          data.map((item) => {
                            return (
                              <div
                                id="section1"
                                className="row no-gutters m-2"
                                style={{
                                  backgroundColor: "#fafafa",
                                  padding: "10px",
                                }}
                              >
                                <div>
                                  <label className="control-label ">
                                    Visit Date :
                                  </label>
                                  {item.encounter_date}
                                </div>
                                <div>
                                  <label className="control-label ">
                                    Practitioner :
                                  </label>
                                  {item.practitioner_name}
                                </div>
                                <div>
                                  <label className="control-label ">
                                    Symptoms :
                                  </label>
                                  {item.symptoms.replaceAll(",", ", ")}
                                </div>
                                <div>
                                  <label className="control-label">
                                    Diagnosis :
                                  </label>
                                  {item.diagnosis}
                                </div>
                                <div>
                                  <label className="control-label ">
                                    Prescribed Medicines :
                                  </label>
                                  <div className="table-responsive ms-1 me-1">
                                    <table className="table" width="100%">
                                      <thead>
                                        <tr>
                                          <th width="1%">No</th>
                                          <th>Medicine</th>
                                          <th width="10%">Consumption</th>
                                          <th width="10%">Before / After</th>
                                          <th width="10%">Duration</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {item.drug_prescription.map(
                                          (item, index) => {
                                            return (
                                              <tr>
                                                <td>{index + 1}</td>
                                                <td>{item.drug_name}</td>
                                                <td>{item.dosage}</td>
                                                <td> {item.comment}</td>
                                                <td>{item.period}</td>
                                              </tr>
                                            );
                                          }
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                {item.lab_test_prescription.length > 0 && (
                                  <div>
                                    <label className="control-label ">
                                      Lab Test :
                                    </label>
                                    <div className="table-responsive ms-1 me-1">
                                      <table className="table" width="100%">
                                        <thead>
                                          <tr>
                                            <th width="1%">No</th>
                                            <th width="30%">Lab Tests</th>
                                            <th>Comment</th>
                                          </tr>
                                        </thead>
                                        {item.lab_test_prescription.map(
                                          (item, index) => {
                                            return (
                                              <tbody>
                                                <tr>
                                                  <td>{index + 1}</td>
                                                  <td>{item.lab_test_name}</td>
                                                  <td>
                                                    {item.lab_test_comment}
                                                  </td>
                                                </tr>
                                              </tbody>
                                            );
                                          }
                                        )}
                                      </table>
                                    </div>
                                  </div>
                                )}
                                {item.notes_for_patient && (
                                  <div className="col-11">
                                    <label className="control-label">
                                      Patient Notes :
                                    </label>
                                    {item.notes_for_patient}
                                  </div>
                                )}

                                <div>
                                  {item.attachments.length > 0 && (
                                    <>
                                      <label className="control-label">
                                        Reports :
                                      </label>
                                      {item.attachments.map((data) => {
                                        return (
                                          <>
                                            <Link
                                              to=""
                                              className="link_decor me-2"
                                              onClick={() =>
                                                window.open(
                                                  string.testbaseUrl +
                                                    data.file_url,
                                                  "_blank"
                                                )
                                              }
                                            >
                                              <img
                                                src={file}
                                                alt={
                                                  string.websiteName +
                                                  " Attchments"
                                                }
                                                style={{
                                                  // marginLeft: "4px",
                                                  marginRight: "4px",
                                                }}
                                              />
                                              {data.file_name.split("-")[0]}
                                            </Link>
                                          </>
                                        );
                                      })}
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PatientHealthRecord;
