import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import MultiSelect from "react-multiple-select-dropdown-lite";
import Multiselect1 from "multiselect-react-dropdown";
import "react-multiple-select-dropdown-lite/dist/index.css";
import ReactCrop from "react-image-crop";

import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ErrorMessage from "../component/common/ErrorMessage";
import Select from "../component/common/select";
import ShowMessage from "../component/common/ShowMessage";
import { stateArray, days, timepicker } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";

import fileUpload from "../assests/svg/file_upload.svg";
import DeleteIcon from "../assests/svg/delete.svg";

import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import DoctorRegistration from "./DoctorRegistration";
import string from "../string";
import storage from "../auth/storage";
const prefixerror = "Appointment";

function Appointment() {
  //#region const
  const navigate = useNavigate();
  const location = useLocation();
  const [hospital, setHospital] = useState();
  const [hospitalAddress, setHospitalAddress] = useState();
  const [hospitalAddressLine2, setHospitalAddressLine2] = useState();
  const [showClinicAddress, setShowClinicAddress] = useState(false);
  const [showClinicArea, setShowClinicArea] = useState(false);
  const [fees, setFees] = useState();
  const [fromTime, setFromTime] = useState();
  const [toTime, setToTime] = useState();
  const [timeSlots, setTimeSlots] = useState([]);
  const [initialTimeSlots, setInitialTimeSlots] = useState([]);
  const [openModel, setOpenModal] = useState(false);
  const [openLetterModal, setOpenLetterModal] = useState(false);
  const [showToTime, setShowToTime] = useState(false);
  const [showFromTime, setShowFromTime] = useState(false);
  const [showClinic, setShowClinic] = useState(false);
  const [showFee, setShowFee] = useState(false);
  const [showSlot, setShowSlot] = useState(false);
  const [modalDay, setModalDay] = useState([]);
  const [load, setLoad] = useState(false);
  const [areaArray, setAreaArray] = useState([]);
  const [open1, setOpen1] = useState(false);
  const [message1, setMessage1] = useState("");
  const [clinicName, setClinicName] = useState();
  const [showModalDay, setShowModalDay] = useState(false);
  const [city, setCity] = useState();
  const [officePhone, setOfficePhone] = useState("");
  const [showCity, setShowCity] = useState(false);
  const [state, setState] = useState();
  const [showState, setShowState] = useState(false);
  const [pincode, setPincode] = useState();
  const [showPincode, setShowPincode] = useState(false);
  const [displayImage, setDisplayImage] = useState(fileUpload);
  const [onChangeImage, setonChangeImage] = useState(false);
  const [showImg, setShowImg] = useState(false);
  const [showimg, setshowimg] = useState("");
  const [companyArr, setCompanyArr] = useState([]);
  const [CropOpen, setCropOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [message, setMessage] = useState("");
  const [crop, setCrop] = useState({
    unit: "px",
    width: 600,
    height: 100,
    minwidth: 600,
    maxheight: 100,
    aspect: 4 / 1,
  });
  const [image, setImage] = useState(null);
  const [showSuccess, setShowSuccess] = useState(false);
  const [profileComplete, setProfileComplete] = useState(100);
  const [companyChosen, setCompanyChosen] = useState("");
  const [clinicSearch, setClinicSearch] = useState(true);
  const [logochange, setLogochange] = useState(true);
  //#endregion

  const selectImage = (file) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setShowImg(true);
      return;
    }
    let img = new Image();
    img.src = window.URL.createObjectURL(file);
    img.onload = () => {
      if (img.width < 600 || img.height < 100) {
        setShowImg(true);
        return;
      }
      setShowImg(false);
      setSrc(URL.createObjectURL(file));
      setOpenLetterModal(true);
    };
  };

  const cropImageNow = () => {
    if (crop.width === 0 || crop.height === 0) {
      setCropOpen(true);
      return;
    }
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");
    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setDisplayImage(base64Image);
    setOpenLetterModal(false);
    setonChangeImage(true);
  };

  const createTimeSlot = (fromTime, toTime, slot) => {
    let startTime = moment(fromTime, "hh:mm:ss");
    let endTime = moment(toTime, "hh:mm:ss");
    let arr = [];
    if (endTime.isBefore(startTime)) {
      endTime.add(1, "day");
    }

    while (startTime <= endTime) {
      // console.log(new moment(startTime).format("HH:mm"));
      arr.push(new moment(startTime).format("HH:mm:ss"));
      startTime.add(parseInt(slot), "minutes");
    }
    return arr;
  };

  const handleEditChange = (e) => {
    console.log("Days", modalDay, e);
    setShowModalDay(false);
    setModalDay(e ? e.split(",") : []);
  };

  const getPincodeInfo = async (code) => {
    setCity("");
    setState("");
    setAreaArray([]);
    setHospitalAddressLine2("");
    if (code.length !== 6) {
      setShowPincode(true);
      return;
    }
    try {
      const pincodeRes = await doctorRegApi.getPincodeDetail(code);
      if (!pincodeRes.ok) {
        ErrorLog(
          prefixerror + "getPincodeInfo " + "getPincodeDetail",
          JSON.stringify(pincodeRes),
          "We are facing some issues.Please try again after sometime.",
          "code:" + code
        );
        return;
      }
      console.log("pincodeRes--", pincodeRes);
      if (pincodeRes.data.message.results.length == 0) {
        setShowPincode(true);
        document.getElementById("pincode").className = "form-control";
        return;
      }
      pincodeRes.data.message.results[0].address_components.forEach(
        (component) => {
          if (component.types.includes("locality")) {
            setCity(component.long_name);
          }
          if (component.types.includes("administrative_area_level_1")) {
            setState(component.long_name);
          }
        }
      );
      if (pincodeRes.data.message.results[0].postcode_localities) {
        console.log(pincodeRes.data.message.results[0].postcode_localities);
        let i;
        let area = [];
        for (
          i = 0;
          i < pincodeRes.data.message.results[0].postcode_localities.length;
          i++
        ) {
          var myObject = {
            id: i,
            name: pincodeRes.data.message.results[0].postcode_localities[i],
          };
          area.push(myObject);
        }
        console.log("area---", area);
        setAreaArray(area);
      } else {
        setAreaArray([
          {
            id: 1,
            name: pincodeRes.data.message.results[0].address_components[1]
              .long_name,
          },
        ]);
        setHospitalAddressLine2(
          pincodeRes.data.message.results[0].address_components[1].long_name
        );
      }
      return;
    } catch (error) {
      console.log(error);
      ErrorLog(
        prefixerror + "getPincodeInfo ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const closeModalErrors = () => {
    setShowModalDay(false);
    setShowFromTime(false);
    setShowToTime(false);
    setOpen1(false);
    document.getElementById("apptfrom").className = " form-control";
    document.getElementById("apptto").className = " form-control";
  };

  const handleModalSubmit = () => {
    closeModalErrors();
    let err = false;
    const duration = 60;
    if (modalDay.length === 0) {
      setShowModalDay(true);
      err = true;
    }
    if (!fromTime) {
      setShowFromTime(true);
      err = true;
      document.getElementById("apptfrom").focus();
      document.getElementById("apptfrom").className += " form-control-error";
    }
    if (!toTime) {
      setShowToTime(true);
      err = true;
      document.getElementById("apptto").focus();
      document.getElementById("apptto").className += " form-control-error";
    }
    if (err) {
      return;
    }
    console.log("ModalSubmitted", fromTime, toTime, modalDay, duration);
    console.log("timeSlots--", timeSlots);
    let ftime = fromTime + ":00";
    let ttime = toTime + ":00";
    for (let j = 0; j < modalDay.length; j++)
      for (var i = 0; i < timeSlots.length; i++) {
        if (modalDay[j] === timeSlots[i].day) {
          if (ftime >= timeSlots[i].from_time && ftime < timeSlots[i].to_time) {
            console.log("Overlap1");
            setOpen1(true);
            setMessage1("Schedule Overlaps with the existing time slots");
            return;
          }
          if (
            ttime >= timeSlots[i].from_time &&
            ttime <= timeSlots[i].to_time
          ) {
            console.log("Overlap2");
            setOpen1(true);
            setMessage1("Schedule Overlaps with the existing time slots");
            return;
          }
        }
      }
    let startTime = moment(fromTime, "hh:mm:ss");
    let endTime = moment(toTime, "hh:mm:ss");
    console.log(startTime, endTime);
    let slots;
    if (startTime < endTime) {
      slots = createTimeSlot(fromTime, toTime, duration);
      console.log(slots);
    } else {
      setOpen1(true);
      setMessage1("Choose valid time");
      return;
    }

    let dat = [];
    let date;

    for (let index = 0; index < modalDay.length; index++) {
      for (let index1 = 0; index1 < slots.length - 1; index1++) {
        date = [
          {
            day: modalDay[index],
            from_time: slots[index1],
            to_time: slots[index1 + 1],
          },
        ];
        dat = [...dat, ...date];
      }
    }
    setTimeSlots([...timeSlots, ...dat]);

    setModalDay([]);
    setFromTime();
    setToTime();
    setOpenModal(false);
    // setAddNew(false);
  };

  const closeErrors = () => {
    setShowFee(false);
    setShowState(false);
    setShowCity(false);
    setShowClinicArea(false);
    setShowPincode(false);
    setShowClinicAddress(false);
    setShowImg(false);
    setShowClinic(false);
    setShowSlot(false);
    document.getElementById("fees").className = "form-control";
    document.getElementById("state").className = "form-control select-control";
    document.getElementById("city").className = "form-control";
    document.getElementById("clinicaddressline2").className =
      "form-control select-control";
    document.getElementById("pincode").className = "form-control";
    const c = document.getElementById("clinicname")
      ? (document.getElementById("clinicname").className = "form-control")
      : "";
    document.getElementById("clinicaddress").className = "form-control";
  };

  const handleSubmit = async () => {
    try {
      closeErrors();
      let err = false;
      if (!fees || fees == 0) {
        setShowFee(true);
        err = true;
        document.getElementById("fees").focus();
        document.getElementById("fees").className += " form-control-error";
      } else if (parseInt(fees) < 1) {
        setShowFee(true);
        err = true;
        document.getElementById("fees").focus();
        document.getElementById("fees").className += " form-control-error";
      }
      if (!state) {
        setShowState(true);
        err = true;
        document.getElementById("state").focus();
        document.getElementById("state").className += " form-control-error";
      } else {
        setShowState(false);
      }
      if (!city) {
        setShowCity(true);
        document.getElementById("city").focus();
        document.getElementById("city").className += " form-control-error";
        err = true;
      } else {
        setShowCity(false);
      }
      if (!hospitalAddressLine2) {
        setShowClinicArea(true);
        document.getElementById("clinicaddressline2").focus();
        document.getElementById("clinicaddressline2").className +=
          " form-control-error";
        err = true;
      } else {
        setShowClinicArea(false);
      }
      if (!pincode) {
        setShowPincode(true);
        err = true;
        document.getElementById("pincode").focus();
        document.getElementById("pincode").className += " form-control-error";
      } else {
        setShowPincode(false);
      }
      if (!hospitalAddress) {
        setShowClinicAddress(true);
        err = true;
        document.getElementById("clinicaddress").focus();
        document.getElementById("clinicaddress").className +=
          " form-control-error";
      } else {
        setShowClinicAddress(false);
      }
      if (displayImage === fileUpload) {
        setShowImg(true);
        err = true;
      } else {
        setShowImg(false);
      }
      if (!hospital) {
        setShowClinic(true);
        setMessage("Please enter Clinic Name");
        err = true;
        document.getElementById("clinic_input").focus();
        document.getElementById("clinic_input").className +=
          " form-control-error";
      } else {
        setShowClinic(false);
      }
      if (timeSlots.length === 0) {
        setShowSlot(true);
        err = true;
      }
      console.log("err", err);
      if (err) {
        return;
      }
      setShowClinic(false);
      setShowClinicArea(false);

      setShowFee(false);
      setLoad(true);
      // const user = await storage.getEmail();

      const doctorID =
        location.state && location.state.id
          ? location.state.id
          : await storage.getID();
      console.log(showimg, displayImage);
      if (showimg != displayImage) {
        const fileName =
          doctorID + "-letterhead." + displayImage.split(";")[0].split("/")[1];
        console.log("fileName--", doctorID, fileName, displayImage);
        const response = await doctorRegApi.imageUpload(
          doctorID,
          fileName,
          displayImage
        );
        console.log("fileUpload--", response);
        if (!response.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "imageUpload",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "doctorID:" +
              doctorID +
              " fileName:" +
              fileName +
              " displayImage:" +
              displayImage
          );
          return;
        }
      }

      let profComp = 100;
      console.log("profileComplete----", profileComplete);
      if (profileComplete != 100) {
        profComp = 84;
        if (!location.state || !location.state.id)
          await storage.storeProfileComplete(84);
      }
      console.log("profileComplete----", profComp);
      console.log("hospital----", hospital);
      console.log("companyChosen----", companyChosen);
      const comp = companyChosen != "" ? companyChosen : hospital;
      console.log("comp---", comp);

      const storeCompany = await storage.getCompany();
      console.log("storeCompany----", storeCompany);
      if (!storeCompany) {
        await storage.storeCompanyFullName(hospital);
        await storage.storeCompany(companyChosen);
      }
      const res = await doctorRegApi.updateFee(
        doctorID,
        comp,
        fees,
        profComp,
        officePhone
      );
      console.log("res----", res);

      if (!res.ok) {
        ErrorLog(
          prefixerror + "handleSubmit " + "updateFee",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" +
            doctorID +
            "hospital:" +
            hospital +
            "fees:" +
            fees +
            "profComp" +
            profComp
        );
        return;
      }
      console.log("update res-----", res);

      const addressRes = await doctorRegApi.getAddress(doctorID);
      console.log("addressRes--", addressRes);
      if (addressRes.data.data.length === 0) {
        const addAddressRes = await doctorRegApi.addAddress(
          doctorID,
          hospitalAddress,
          hospitalAddressLine2,
          city,
          state,
          pincode,
          officePhone
        );
        if (!addAddressRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "addAddress",
            JSON.stringify(addAddressRes),
            "We are facing some issues.Please try again after sometime.",
            "doctorID:" +
              doctorID +
              "hospitalAddress:" +
              hospitalAddress +
              "hospitalAddressLine2:" +
              hospitalAddressLine2 +
              "city:" +
              city +
              "state:" +
              state +
              "pincode:" +
              pincode +
              "phone:" +
              officePhone
          );
          return;
        }
        console.log("addAddressRes--", addAddressRes);
      } else {
        const updateAddressRes = await doctorRegApi.updateAddress(
          addressRes.data.data[0].name,
          hospitalAddress,
          hospitalAddressLine2,
          city,
          state,
          pincode,
          officePhone
        );
        if (!updateAddressRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "updateAddress",
            JSON.stringify(updateAddressRes),
            "We are facing some issues.Please try again after sometime.",
            "doctorID:" +
              doctorID +
              "hospitalAddress:" +
              hospitalAddress +
              "hospitalAddressLine2:" +
              hospitalAddressLine2 +
              "city:" +
              city +
              "state:" +
              state +
              "pincode:" +
              pincode +
              "officePhone:" +
              officePhone
          );
          return;
        }
        console.log("updateAddressRes--", updateAddressRes);
      }

      if (clinicName && hospital.indexOf("HLC-COM-") != -1) {
        const updateCompanyAddressRes = await doctorRegApi.updateAddress(
          hospital + "-Office",
          hospitalAddress,
          hospitalAddressLine2,
          city,
          state,
          pincode,
          officePhone
        );
        console.log("updateCompanyAddressRes--", updateCompanyAddressRes);
        if (!updateCompanyAddressRes.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "updateCompanyAddressRes",
            JSON.stringify(updateCompanyAddressRes),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" +
              hospital +
              "hospitalAddress:" +
              hospitalAddress +
              "hospitalAddressLine2:" +
              hospitalAddressLine2 +
              "city:" +
              city +
              "state:" +
              state +
              "pincode:" +
              pincode +
              "officePhone:" +
              officePhone
          );
          return;
        }
      }

      const schedule_name = doctorID + " Schedule";
      if (!location.state || !location.state.id)
        await storage.storeScheduleName(schedule_name);
      if (initialTimeSlots.length === 0) {
        const res = await doctorRegApi.createSchedule(
          doctorID,
          schedule_name,
          timeSlots
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSubmit " + "createSchedule",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "schedule_name:" + schedule_name + "timeSlots:" + timeSlots
          );
          return;
        }
        console.log(res, "Response1");
      } else {
        if (timeSlots.length > 0) {
          const res = await doctorRegApi.createSchedulePut(
            schedule_name,
            timeSlots
          );
          if (!res.ok) {
            ErrorLog(
              prefixerror + "handleSubmit " + "createSchedulePut",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "schedule_name:" + schedule_name + "timeSlots:" + timeSlots
            );
            return;
          }
          console.log(res, "Response21");
        }
      }
      setLoad(false);
      setShowSuccess(true);
      setTimeout(() => {
        if (location.state && location.state.id)
          navigate("/education", { state: { id: location.state.id } });
        else navigate("/education");
      }, 3000);
    } catch (error) {
      console.log("Error1", error);
      ErrorLog(
        prefixerror + "handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      setLoad(false);
      return;
    }
  };

  const handleAddNewClinic = (val) => {
    console.log("val---", val);
    setHospital(val);
    setClinicSearch(false);
  };

  const handleSelectClinic = async (e) => {
    console.log("e---", e);
    if (e && e.length > 0) {
      const logoRes = await doctorRegApi.getLetterPad(e[0].address_title);
      console.log("logoRes==", logoRes);
      if (logoRes.data.data.length > 0) {
        setshowimg(string.testbaseUrl + logoRes.data.data[0].image);
        setDisplayImage(string.testbaseUrl + logoRes.data.data[0].image);
        setLogochange(false);
      }
      setCompanyChosen(e[0].address_title);
      setHospitalAddress(e[0].address_line1);
      setAreaArray([{ id: 1, name: e[0].address_line2 }]);
      setHospitalAddressLine2(e[0].address_line2);
      setCity(e[0].city);
      setState(e[0].state);
      setPincode(e[0].pincode);
      document.getElementById("clinic_input").value = "";
      setHospital(e[0].company_name);
      setClinicSearch(false);
    } else {
      setshowimg("");
      setDisplayImage("");
      setLogochange(true);
      setCompanyChosen("");
      setHospitalAddress("");
      setHospitalAddressLine2("");
      setCity("");
      setState("");
      setPincode("");
    }
    setCompanyArr([]);
  };

  const handleSearchClinic = async (val) => {
    setCompanyArr([]);
    setCompanyChosen("");
    setHospitalAddress("");
    setPincode("");
    setHospitalAddressLine2("");
    setCity("");
    setState("");
    setAreaArray([]);
    console.log("val----", val);
    if (val.length > 2) {
      try {
        const companyRes = await doctorRegApi.getCompanyDetails(val);
        console.log("companyRes---", companyRes);

        if (companyRes.data.message.length > 0) {
          for (let i = 0; i < companyRes.data.message.length; i++) {
            companyRes.data.message[i].clinicDetails =
              companyRes.data.message[i].company_name +
              ", " +
              companyRes.data.message[i].address_line1 +
              ", " +
              companyRes.data.message[i].address_line2 +
              ", " +
              companyRes.data.message[i].city +
              ", " +
              companyRes.data.message[i].state +
              " - " +
              companyRes.data.message[i].pincode;
          }
          setCompanyArr(companyRes.data.message);
        }
      } catch (error) {
        console.log("error--", error);
      }
    }
  };

  const getBasicInformation = async () => {
    setLoad(true);
    const role = await storage.getRole();
    if (role != string.DOCTOR_ROLE && role != string.SUPERADMIN_ROLE) {
      navigate("/");
      return;
    }
    const doctorID =
      location.state && location.state.id
        ? location.state.id
        : await storage.getID();
    const res = await doctorRegApi.getDetilsBasicInfo(doctorID);
    if (!res.ok) {
      ErrorLog(
        prefixerror + "getBasicInformation " + "getDetilsBasicInfo",
        JSON.stringify(res),
        "We are facing some issues.Please try again after sometime.",
        "doctorID:" + doctorID
      );
      return;
    }
    console.log("doctor res-----------", res);
    console.log(
      "completeness res-----------",
      res.data.data.profile_completeness
    );
    if (res.data.data.profile_completeness != 100) {
      const prof = res.data.data.profile_completeness
        ? res.data.data.profile_completeness
        : 45;
      setProfileComplete(prof);
      if (!location.state || !location.state.id)
        await storage.storeProfileComplete(prof);
    }
    if (res.data.data.hospital) {
      setHospital(res.data.data.hospital);
      const companyFullName = await storage.getCompanyFullName();
      console.log("companyFullName---", companyFullName);

      if (companyFullName && companyFullName != "undefined") {
        setClinicName(companyFullName);
      } else if (res.data.data.hospital.indexOf("HLC-COM-") == -1) {
        setClinicName(res.data.data.hospital);
      } else {
        const companyRes = await doctorRegApi.getCompanyByName(
          res.data.data.hospital
        );
        console.log("companyres---------------------", companyRes);
        if (!companyRes.ok) {
          ErrorLog(
            prefixerror + "getBasicInformation " + "getCompanyByName",
            JSON.stringify(companyRes),
            "We are facing some issues.Please try again after sometime.",
            "hospital:" + res.data.data.hospital
          );
          return;
        }
        setClinicName(companyRes.data.data.company_name);
      }
      const logoRes = await doctorRegApi.getLetterPad(res.data.data.hospital);
      console.log("logoRes==", logoRes);
      if (logoRes.data.data.length > 0) {
        setshowimg(string.testbaseUrl + logoRes.data.data[0].image);
        setDisplayImage(string.testbaseUrl + logoRes.data.data[0].image);
        setLogochange(false);
      } else {
        const fileres = await doctorRegApi.getAttachedImageByFileName(
          doctorID,
          "letterhead"
        );
        if (!fileres.ok) {
          ErrorLog(
            prefixerror + "getBasicInformation " + "getAttachedImageByFileName",
            JSON.stringify(fileres),
            "We are facing some issues.Please try again after sometime.",
            "doctorID:" + doctorID + "letterhead:letterhead"
          );
          return;
        }
        console.log("fileres==", fileres);
        if (fileres.data.data.length > 0) {
          fileres.data.data.map((item) => {
            if (item.file_name.indexOf("letterhead") != -1) {
              console.log("item.file_url---------", item.file_url);
              setshowimg(string.testbaseUrl + item.file_url);
              setDisplayImage(string.testbaseUrl + item.file_url);
            }
          });
        }
      }
    }
    if (res.data.data.op_consulting_charge !== 0)
      setFees(res.data.data.op_consulting_charge);
    setOfficePhone(res.data.data.office_phone);
    const scheduleName = doctorID + " schedule"; //await storage.getScheduleName();
    console.log("ScheduleName", scheduleName);

    const scheduleRes = await doctorRegApi.appointmentInfo(scheduleName);
    console.log("scheduleRes---", scheduleRes);
    if (scheduleRes.ok) {
      if (scheduleRes.data.data.time_slots.length > 0) {
        for (let i = 0; i < scheduleRes.data.data.time_slots.length; i++) {
          if (scheduleRes.data.data.time_slots[i].from_time.length < 8) {
            scheduleRes.data.data.time_slots[i].from_time =
              "0" + scheduleRes.data.data.time_slots[i].from_time;
          }

          if (scheduleRes.data.data.time_slots[i].to_time.length < 8) {
            scheduleRes.data.data.time_slots[i].to_time =
              "0" + scheduleRes.data.data.time_slots[i].to_time;
          }
        }
      }
      setTimeSlots(scheduleRes.data.data.time_slots);
      setInitialTimeSlots(scheduleRes.data.data.time_slots);
    }

    const addressRes = await doctorRegApi.getAddress(doctorID);
    if (!addressRes.ok) {
      ErrorLog(
        prefixerror + "getBasicInformation " + "getAddress",
        JSON.stringify(addressRes),
        "We are facing some issues.Please try again after sometime.",
        "doctorID:" + doctorID
      );
      return;
    }
    console.log("addressRes--", addressRes);
    if (addressRes.data.data.length > 0) {
      setHospitalAddress(addressRes.data.data[0].address_line1);
      let area = [];
      var myObject = {
        id: 1,
        name: addressRes.data.data[0].address_line2,
      };
      area.push(myObject);
      setAreaArray(area);
      setHospitalAddressLine2(addressRes.data.data[0].address_line2);
      setCity(addressRes.data.data[0].city);
      setState(addressRes.data.data[0].state);
      setPincode(addressRes.data.data[0].pincode);
    }

    !res.data.data.hospital
      ? document.getElementById("clinic_input") &&
        document.getElementById("clinic_input").focus()
      : document.getElementById("clinicaddress") &&
        document.getElementById("clinicaddress").focus();

    setLoad(false);
  };

  useEffect(() => {
    getBasicInformation();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <>
        <section>
          <div className="row">
            <div className="col-lg-2 p-0">
              <DoctorNavBar id="DoctorNavBar" />
            </div>

            <div className="col-lg-10">
              <DoctorHeader />
              <div className="row" onClick={preFunction.hideNavbar}>
                <div className="pd">
                  <div className="row mb-5">
                    <h1>Clinic Information</h1>
                  </div>
                  <div className="row border">
                    <div className="col-lg-3 border">
                      <DoctorRegistration />
                    </div>
                    <div className="col-lg-8">
                      <div className="row p-lg-5">
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            {!clinicName ? (
                              <span className="star">*</span>
                            ) : null}
                            Clinic Name :
                          </label>
                          <div className="col-lg-6">
                            {!clinicName && clinicSearch ? (
                              <Multiselect1
                                name="clinic"
                                id="clinic"
                                options={companyArr}
                                placeholder={"Clinic Name"}
                                showArrow={false}
                                avoidHighlightFirstOption={true}
                                onSelect={handleSelectClinic}
                                onRemove={handleSelectClinic}
                                displayValue="clinicDetails"
                                selectionLimit={1}
                                customCloseIcon={" "}
                                hidePlaceholder={true}
                                customArrow={" "}
                                emptyRecordMsg={
                                  document.getElementById("clinic_input") &&
                                  document.getElementById("clinic_input").value
                                    .length > 2 ? (
                                    <div className="notFoundAdd">
                                      Do you want to add as new
                                      Clinic?&nbsp;&nbsp;&nbsp;
                                      <button
                                        className="btn-2"
                                        title="Add"
                                        onClick={(e) => {
                                          handleAddNewClinic(
                                            document.getElementById(
                                              "clinic_input"
                                            ).value
                                          );
                                        }}
                                      >
                                        <span
                                          className="icofont icofont-save mr-1"
                                          style={{ fontSize: "1em" }}
                                        ></span>
                                        Yes
                                      </button>
                                    </div>
                                  ) : (
                                    <div className="notFoundSearch">
                                      Type to search
                                    </div>
                                  )
                                }
                                onSearch={(e) => {
                                  handleSearchClinic(
                                    document.getElementById("clinic_input")
                                      .value
                                  );
                                  handleSelectClinic();
                                }}
                              />
                            ) : !clinicName && !clinicSearch ? (
                              <div className="mt-2">
                                {hospital}&nbsp;&nbsp;&nbsp;&nbsp;
                                <a
                                  style={{ textDecoration: "underline" }}
                                  className="pointer"
                                  onClick={(e) => {
                                    setshowimg("");
                                    setDisplayImage("");
                                    setLogochange(true);
                                    setCompanyChosen("");
                                    setHospitalAddress("");
                                    setHospitalAddressLine2("");
                                    setCity("");
                                    setState("");
                                    setPincode("");
                                    setClinicSearch(true);
                                    document
                                      .getElementById("clinic_input")
                                      .focus();
                                  }}
                                >
                                  Change
                                </a>
                              </div>
                            ) : (
                              <div className="mt-2">{clinicName}</div>
                            )}
                            <ErrorMessage
                              error={message}
                              visible={showClinic}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Company Letter Head
                            :
                          </label>
                          <div className="col-lg-6">
                            {logochange ? (
                              <div className="label">
                                <input
                                  type="file"
                                  accept="image/*"
                                  name="letter-upload"
                                  id="input"
                                  onChange={(e) => {
                                    closeErrors();
                                    selectImage(e.target.files[0]);
                                  }}
                                  onClick={(e) => {
                                    e.target.value = "";
                                  }}
                                  style={{ display: "none" }}
                                />

                                <label htmlFor="input" className="pointer">
                                  <img
                                    src={
                                      displayImage
                                        ? displayImage
                                        : showimg
                                        ? showimg
                                        : fileUpload
                                    }
                                    alt={
                                      string.websiteName + " Profile Picture"
                                    }
                                    id="img"
                                    className="text-center letter-upload"
                                    htmlFor="input"
                                  />
                                </label>
                              </div>
                            ) : (
                              <div className="label">
                                <img
                                  src={
                                    onChangeImage
                                      ? displayImage
                                      : showimg
                                      ? showimg
                                      : displayImage
                                  }
                                  alt={string.websiteName + " Profile Picture"}
                                  id="img"
                                  className="text-center letter-upload"
                                  htmlFor="input"
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        {showImg ? (
                          <div className="row no-gutters mb-2">
                            <label className="control-label col-lg-3"></label>
                            <div className="col-lg-6">
                              <ErrorMessage
                                error={
                                  "Please select letter head image (minimum size is 600X100)"
                                }
                                visible={showImg}
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="row no-gutters form-group mt-4">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Clinic address :
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="clinicaddress"
                              id="clinicaddress"
                              maxLength={120}
                              className="form-control indent"
                              placeholder="Clinic address"
                              value={hospitalAddress}
                              onChange={(e) => {
                                setHospitalAddress(e.target.value);
                                closeErrors();
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please enter Clinic address"}
                              visible={showClinicAddress}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Pincode :
                          </label>
                          <div className="col-lg-2">
                            <CustomTextInput
                              type="number"
                              name="pincode"
                              id="pincode"
                              className="form-control"
                              placeholder="Pincode"
                              value={pincode}
                              onChange={(e) => {
                                e.target.value =
                                  e.target.value < 0
                                    ? 0
                                    : e.target.value.length > 6
                                    ? e.target.value.slice(0, 6)
                                    : e.target.value;
                                setPincode(e.target.value);
                                closeErrors();
                                getPincodeInfo(e.target.value);
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please enter valid Pincode"}
                              visible={showPincode}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Area :
                          </label>
                          <div className="col-lg-6">
                            <Select
                              name="clinicaddressline2"
                              id="clinicaddressline2"
                              value={hospitalAddressLine2}
                              className={
                                hospitalAddressLine2
                                  ? "form-control select-control"
                                  : "form-control select-control opacity"
                              }
                              placeholder="Area"
                              onChange={(item) => {
                                setHospitalAddressLine2(item.target.value);
                                closeErrors();
                              }}
                              options={areaArray}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please select Area"}
                              visible={showClinicArea}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> City :
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="city"
                              id="city"
                              maxlength="140"
                              className="form-control indent"
                              placeholder="City"
                              value={city}
                              onChange={(e) => {
                                setCity(e.target.value);
                                closeErrors();
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please enter city"}
                              visible={showCity}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> State :
                          </label>
                          <div className="col-lg-6">
                            <Select
                              id="state"
                              name="state"
                              className={
                                state
                                  ? "form-control select-control"
                                  : "form-control select-control opacity"
                              }
                              placeholder="State"
                              value={state}
                              options={stateArray}
                              onChange={(item) => {
                                setState(item.target.value);
                                closeErrors();
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please select State"}
                              visible={showState}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            Clinic Phone Number :
                          </label>
                          <div className="col-lg-6">
                            <CustomTextInput
                              type="text"
                              name="officePhone"
                              id="officePhone"
                              className="form-control indent"
                              placeholder="Clinic Phone Number"
                              value={officePhone}
                              onChange={(e) => {
                                console.log("e.target.value--", e.target.value);
                                e.target.value =
                                  e.target.value.length > 30
                                    ? e.target.value.substr(0, 30)
                                    : e.target.value;
                                setOfficePhone(e.target.value);
                                closeErrors();
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please select State"}
                              visible={showState}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Consultation Fees :
                          </label>
                          <div className="col-lg-2">
                            <CustomTextInput
                              type="number"
                              maxlength="4"
                              className="form-control"
                              name="fees"
                              id="fees"
                              placeholder="Consultation Fees"
                              value={fees}
                              onChange={(e) => {
                                e.target.value =
                                  e.target.value < 0
                                    ? 0
                                    : e.target.value.length > 4
                                    ? e.target.value.slice(0, 4)
                                    : e.target.value;
                                setFees(e.target.value);
                                closeErrors();
                              }}
                              onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            />
                            <ErrorMessage
                              error={"Please enter Consultation Fee"}
                              visible={showFee}
                            />
                          </div>
                        </div>
                        <div className="row no-gutters form-group">
                          <label className="control-label col-lg-3">
                            <span className="star">*</span> Time Slots :
                          </label>
                          <div className="col-lg-6">
                            {timeSlots.length >= 1 && (
                              <div
                                id="slotsID"
                                className="border table-responsive"
                              >
                                <table className="table">
                                  <thead>
                                    <tr>
                                      <th>Days</th>
                                      <th>From Time</th>
                                      <th>To Time</th>
                                      <th>Delete</th>
                                    </tr>
                                  </thead>
                                  {timeSlots.length >= 1 && (
                                    <tbody>
                                      {timeSlots.map((item, index) => {
                                        return (
                                          <tr key={index}>
                                            <td>{item.day}</td>
                                            <td>
                                              {preFunction.displayTimeFormat(
                                                item.from_time
                                              )}
                                            </td>
                                            <td>
                                              {preFunction.displayTimeFormat(
                                                item.to_time
                                              )}
                                            </td>
                                            <td>
                                              <a
                                                title="Delete"
                                                type="button"
                                                onClick={() => {
                                                  setTimeSlots(
                                                    timeSlots.filter(
                                                      (e) => e !== item
                                                    )
                                                  );
                                                }}
                                              >
                                                <img src={DeleteIcon} />
                                              </a>
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  )}
                                </table>
                              </div>
                            )}
                          </div>
                          <ShowMessage
                            view={showSuccess}
                            Message={"Updated successfully"}
                          />
                          <div className="row no-gutters mt-3">
                            <div className="col-lg-3"></div>
                            <div className="col-lg-6 align-left">
                              <div className="row">
                                <div className="col-6 align-left">
                                  <button
                                    type="button center"
                                    className="btn"
                                    onClick={() => {
                                      setOpenModal(true);
                                      setShowSlot(false);
                                      setFromTime();
                                      setToTime();
                                    }}
                                  >
                                    <span className="icofont icofont-plus"></span>
                                    Add Slots
                                  </button>

                                  <ErrorMessage
                                    error={"Please enter Time Slots"}
                                    visible={showSlot}
                                  />
                                </div>
                                <div className="col-6 align-right">
                                  <button
                                    className="btn"
                                    type="submit"
                                    title="Next"
                                    onClick={handleSubmit}
                                  >
                                    <span className="icofont-arrow-right"></span>
                                    Next
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <>
                  <Modal show={openModel}>
                    <Modal.Header>
                      <Modal.Title>Appointment Slots</Modal.Title>
                      <span
                        className="icofont-close-line pointer"
                        style={{ fontSize: "25px", fontWeight: "bold" }}
                        onClick={(e) => {
                          setOpenModal(false);
                        }}
                      ></span>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="row" style={{ width: "500px" }}>
                        <div className="form-horizontal">
                          <div className="row ">
                            <label className="control-label col-lg-3">
                              <span className="star">*</span> Days:
                            </label>
                            <div className="form-group col-lg-6">
                              <MultiSelect
                                type="text"
                                name="days"
                                id="days"
                                width="50%"
                                options={days}
                                onChange={handleEditChange}
                                placeholder="Select a Day"
                              />

                              <ErrorMessage
                                error={"Please select day"}
                                visible={showModalDay}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="control-label col-lg-3">
                              <span className="star">*</span> From :
                            </label>
                            <div className="form-group col-lg-6">
                              <Select
                                name="apptfrom"
                                id="apptfrom"
                                className={
                                  fromTime
                                    ? "form-control select-control"
                                    : "form-control select-control opacity"
                                }
                                options={timepicker}
                                value={fromTime}
                                onChange={(e) => {
                                  setShowFromTime(false);
                                  setFromTime(e.target.value);
                                }}
                                onClick={() => setOpen1(false)}
                                placeholder="From Time"
                              />
                              <ErrorMessage
                                error={"Please select From Time"}
                                visible={showFromTime}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="control-label col-lg-3">
                              <span className="star">*</span> To :
                            </label>
                            <div className="form-group col-lg-6">
                              <Select
                                name="apptto"
                                id="apptto"
                                className={
                                  toTime
                                    ? "form-control select-control"
                                    : "form-control select-control opacity"
                                }
                                options={timepicker}
                                value={toTime}
                                onChange={(e) => {
                                  setShowToTime(false);
                                  setToTime(e.target.value);
                                }}
                                onClick={() => setOpen1(false)}
                                placeholder="To Time"
                              />
                              <ErrorMessage
                                error={"Please select To Time"}
                                visible={showToTime}
                              />
                            </div>
                          </div>

                          <ErrorMessage error={message1} visible={open1} />
                        </div>
                      </div>
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        type="button"
                        className="btn"
                        onClick={handleModalSubmit}
                      >
                        <span className="icofont icofont-plus"></span>
                        Add
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
                <>
                  <Modal className="modalwidth" show={openLetterModal}>
                    <Modal.Body>
                      <div style={{ width: "900px", height: "100%" }}>
                        {src && (
                          <div>
                            <ReactCrop
                              src={src}
                              onImageLoaded={setImage}
                              crop={crop}
                              onChange={setCrop}
                              minHeight={100}
                              minWidth={600}
                              keepSelection={true}
                              onComplete={(crop, pixel) => {
                                console.log(crop, pixel);
                              }}
                            />
                            <br />
                          </div>
                        )}
                      </div>
                      <ErrorMessage
                        error={"Please click on image to crop"}
                        visible={CropOpen}
                      />
                    </Modal.Body>
                    <Modal.Footer>
                      <button
                        className="btn"
                        onClick={cropImageNow}
                        title="Crop"
                      >
                        <span className="icofont-crop"></span>Crop
                      </button>
                    </Modal.Footer>
                  </Modal>
                </>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
}

export default Appointment;
