import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ShowMessage from "./../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import storage from "../auth/storage";
import pen from "../assests/svg/pen.svg";
import string from "../string";

const prefixerror = "UserList";

function UserList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  //#endregion

  const navigate = useNavigate();
  let sno = limitStart + 1;

  const handleCreateDoctorXUser = async () => {
    const doctorID = await storage.getID();
    const clinicId = await storage.getCompany();
    console.log("clinicId--------", clinicId);
    const getAllCompany = await adminApi.getAllCompanyDetails();
    console.log("getAllCompany----", getAllCompany);
    // return;
    for (let a = 0; a < getAllCompany.data.data.length; a++) {
      const getDoctorsByHospital = await doctorApi.getDoctorByHospital(
        getAllCompany.data.data[a].name
      );
      console.log(
        "getAllCompany.data.data[a].name------",
        getAllCompany.data.data[a].name
      );
      console.log("getDoctorsByHospital------", getDoctorsByHospital);

      for (let l = 0; l < getDoctorsByHospital.data.data.length; l++) {
        const getUserListRes = await doctorApi.getUserList(
          getDoctorsByHospital.data.data[l].name
        );
        console.log("getUserListRes------", getUserListRes);

        for (let j = 0; j < getUserListRes.data.data.length; j++) {
          for (let i = 0; i < getDoctorsByHospital.data.data.length; i++) {
            const getDoctorXUserRes = await doctorApi.getDoctorXUser(
              getUserListRes.data.data[j].name,
              getDoctorsByHospital.data.data[i].name
            );
            console.log("getDoctorXUserRes--------", getDoctorXUserRes);
            if (getDoctorXUserRes.data.data.length === 0) {
              const assignDoctorXUserRes = await doctorApi.assignDoctorXUser(
                getUserListRes.data.data[j].name,
                getDoctorsByHospital.data.data[i].name
              );
              console.log("assignDoctorXUserRes----", assignDoctorXUserRes);
            }
          }
        }
      }
      // for (let i = 0; i < getDoctorsByHospital.data.data.length; i++) {
      //   const getPatientByDoctorRes = await doctorApi.getPatientByDoctor(
      //     getDoctorsByHospital.data.data[i].name
      //   );
      //   // console.log("getPatientByDoctorRes---", getPatientByDoctorRes);
      //   for (let j = 0; j < getPatientByDoctorRes.data.data.length; j++) {
      //     console.log(
      //       "getPatientByDoctorRes---",
      //       getPatientByDoctorRes.data.data[j]
      //     );
      //     const setCompanyIdRes = await doctorApi.setCompanyId(
      //       getPatientByDoctorRes.data.data[j].name,
      //       clinicId
      //     );
      //     console.log("setCompanyIdRes----", setCompanyIdRes);
      //   }
      // }
    }
  };
  const handleDeleteUser = async (userId) => {
    try {
      setLoad(true);
      const userRes = await doctorApi.getStaff(userId);
      if (!userRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteUser " + "getStaff",
          JSON.stringify(userRes),
          "We are facing some issues.Please try again after sometime.",
          "userId:" + userId
        );
        return;
      }
      console.log("userRes---", userRes);

      const fileRes = await doctorApi.getReport(userId);
      if (!fileRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteUser " + "getReport",
          JSON.stringify(fileRes),
          "We are facing some issues.Please try again after sometime.",
          "userId:" + userId
        );
        return;
      }
      console.log("fileRes---", fileRes);

      for (let i = 0; i < fileRes.data.data.length; i++) {
        console.log("id---", fileRes.data.data[i].name);

        const delfileRes = await adminApi.deleteFromDoctype(
          "File",
          fileRes.data.data[i].name
        );
        if (!delfileRes.ok) {
          ErrorLog(
            prefixerror + " handleDeleteUser " + "deleteFromDoctype",
            JSON.stringify(delfileRes),
            "We are facing some issues.Please try again after sometime.",
            "file name:" + fileRes.data.data[i].name
          );
          return;
        }
        console.log("delfileRes---", delfileRes);
      }

      const getUserByUserRes = await doctorApi.getUserByUser(
        userRes.data.data.name
      );
      console.log("getUserByUserRes------", getUserByUserRes);

      for (let i = 0; i < getUserByUserRes.data.data.length; i++) {
        const deluserXDocRes = await adminApi.deleteFromDoctype(
          "DoctorXUser",
          getUserByUserRes.data.data[i].name
        );
        console.log("deluserXDocRes------", deluserXDocRes);
      }

      const deluserRes = await adminApi.deleteFromDoctype(
        "User",
        userRes.data.data.name
      );
      console.log("deluserRes---", deluserRes);
      if (!deluserRes.ok) {
        setLoad(false);
        ErrorLog(
          prefixerror + " handleDeleteUser " + "deleteFromDoctype",
          JSON.stringify(deluserRes),
          "We are facing some issues.Please try again after sometime.",
          "name:" + userRes.data.data.name
        );
        return;
      }

      setOpen(true);
      setMessage("User deleted successfully");
      setTimeout(() => {
        setOpen(false);
      }, 3000);

      setData([]);
      getUsersList(0);
      setLoad(false);
      return;
    } catch (error) {
      setLoad(false);
      console.log("ex---", error);
      ErrorLog(
        prefixerror + " handleDeleteUser ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getUsersList = async (limitStart) => {
    try {
      setLoad(true);
      const role = await storage.getRole();
      if (
        role != string.DOCTOR_ROLE &&
        role != "Admin" &&
        role != string.SUPERADMIN_ROLE
      ) {
        navigate("/");
        return;
      }
      const doctorID = await storage.getID();
      const pagesize = await storage.getPageSize();

      const getUserByDoctorRes = await doctorApi.getUserByDoctor(doctorID);
      console.log("getUserByDoctorRes------", getUserByDoctorRes);

      let userArr = [];
      for (let i = 0; i < getUserByDoctorRes.data.data.length; i++) {
        userArr.push(getUserByDoctorRes.data.data[i].user_id);
      }
      console.log("userArr--------", userArr);

      setPageSize(pagesize);
      const searchString = `[["name","in", "${userArr}"], ["role_profile_name","!=","Practitioner Permission"]]`;
      const allRes = await doctorApi.getTotalCountSuperAdminKey(
        "User",
        searchString
      );
      if (!allRes.ok) {
        setLoad(false);
        ErrorLog(
          prefixerror + " getUsersList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString
        );
        return;
      }
      console.log("allRes----", allRes);
      setTotalCount(allRes.data.message);

      const res = await doctorApi.getUserListByList(
        searchString,
        limitStart,
        pageSize
      );
      console.log("data.length==", res);

      // const res = await doctorApi.getUserList(doctorID, limitStart, pageSize);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getUsersList " + "getUserList",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" +
            doctorID +
            "limitStart:" +
            limitStart +
            "pageSize:" +
            pageSize
        );
        return;
      }

      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " getUsersList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getUsersList(lmtst);
  };

  const handleEditUser = (staff) => {
    navigate("/newuser", { state: { staff: staff } });
  };

  useEffect(() => {
    getUsersList(0);
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="row">
                  <h1>Employee</h1>
                </div>
                <div className="row">
                  <div className="form-group text-right">
                    <button
                      title="Add User"
                      className="btn-2"
                      onClick={(e) => {
                        // handleCreateDoctorXUser();
                        navigate("/newuser");
                      }}
                    >
                      <span className="icofont icofont-plus"></span>
                      Add Employee
                    </button>
                  </div>
                  <ShowMessage view={open} Message={message} />
                  <div className="row border p-3">
                    <div className="row totcntstyle">
                      A total of {totalCount} employee identified.
                    </div>
                    {data.length > 0 ? (
                      <>
                        <div className="table-responsive">
                          <table className="table">
                            <thead>
                              <tr>
                                <th width="1%">No</th>
                                <th width="15%">Name</th>
                                <th width="10%">Role</th>
                                <th width="10%">DOB</th>
                                <th width="10%">Mobile</th>
                                <th>Email</th>
                                <th width="1%">Update</th>
                                <th width="1%">Delete</th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td>{sno++}</td>
                                    <td className="nowrapWhiteSpace">
                                      {item.full_name}
                                    </td>
                                    <td>{item.role_profile_name}</td>
                                    <td className="align-left nowrapWhiteSpace">
                                      {item.birth_date
                                        ? moment(item.birth_date).format(
                                            "DD-MM-yyyy"
                                          )
                                        : ""}
                                    </td>
                                    <td>{item.mobile_no}</td>
                                    <td>{item.email}</td>
                                    <td className="pointer">
                                      <img
                                        src={pen}
                                        alt={string.websiteName + " Users"}
                                        onClick={() =>
                                          handleEditUser(item.name)
                                        }
                                      />
                                    </td>
                                    <td className="pointer">
                                      <a
                                        onClick={() =>
                                          handleDeleteUser(item.name)
                                        }
                                      >
                                        Delete
                                      </a>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                        {totalCount > pageSize && (
                          <table>
                            <tbody>
                              <tr>
                                <td className="p-3">
                                  <Pagination
                                    itemsCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={(page) =>
                                      handlePageChange(page)
                                    }
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        )}
                      </>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default UserList;
