import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import { months } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";
import ShowMessage from "../component/common/ShowMessage";
import ErrorMessage from "../component/common/ErrorMessage";

import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import "react-datepicker/dist/react-datepicker.css";

const prefixerror = "InvoiceReport";

function OnlineInvoiceReport() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const todayDate = new Date();
  const [fromDate, setFromDate] = useState(todayDate);
  const [toDate, setToDate] = useState(todayDate);
  const navigate = useNavigate();
  let sno = 1;
  let total = 0;
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear(), 1);
  const DateCustomInput = preFunction.DateCustomInput;
  const [doctors, setDoctors] = useState([]);
  const [doctorSel, setDoctorSel] = useState([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [successOpen, setSuccessOpen] = useState(false);
  const [showInvoiceError, setShowInvoiceError] = useState(false);
  //#endregion

  const handleSearchDoctor = async (value) => {
    try {
      console.log("value---", value);
      setDoctors([]);
      if (value.length > 2) {
        const res = await adminApi.getSearchDoctor(value);
        console.log("searchRes--", res.data.data);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchDoctor " + "getSearchDoctor",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              practitioner_name:
                res.data.data[i].practitioner_name +
                "-" +
                moment().diff(res.data.data[i].date_of_birth, "years") +
                " years-" +
                res.data.data[i].mobile_phone,
              name: res.data.data[i].name,
            };
            arr.push(myObject);
          }
          console.log("arr---", arr);
          setDoctors(arr);
        }
      }

      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleSearchDoctor ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      console.log(error);
      setLoad(false);
    }
  };

  const handleRemove = () => {
    setDoctorSel([]);
    setDoctors([]);
    getInvoiceList(selectedDoctor);
  };

  const handleSelectDoctor = (e) => {
    console.log(e, "e");
    setDoctorSel([]);
    setDoctors([]);
    if (e && e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        e[i].practitioner_name = e[i].practitioner_name.split("-")[0];
      }
      setDoctorSel(e);
      setDoctors(e);
      const email = e[0].name;
      setSelectedDoctor(email);
      getInvoiceList(email);
      document.getElementById("doctorId_input").blur();
    }
  };

  const getInvoiceList = async (email) => {
    try {
      console.log("fromDate---", fromDate, toDate);
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      setData([]);
      setLoad(true);
      console.log("Dates---", from, to);
      console.log("email---", email);
      const res = await doctorApi.getOnlineInvoice(from, to, email);
      console.log("res--", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getInvoiceList " + "getAllInvoiceWithDate",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "from:" + from + "to" + to
        );
        return;
      }
      if (res.data.message.length > 0) {
        setData(res.data.message);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getInvoiceList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = (invoiceId) => {
    navigate("/viewInvoice", { state: { invoiceId } });
  };

  const handleSettle = async () => {
    let invoiceIds = [];
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    checkboxes.forEach((checkbox) => {
      if (checkbox.id != "selectAll" && checkbox.checked) {
        invoiceIds.push(checkbox.value);
      }
    });
    console.log("invoiceId---", invoiceIds);
    if (invoiceIds.length == 0) {
      setShowInvoiceError(true);
      setTimeout(() => {
        setShowInvoiceError(false);
      }, 3000);
      return;
    }
    try {
      setLoad(true);
      const date = moment(new Date()).format("yyyy-MM-DD");
      for (let i = 0; i < invoiceIds.length; i++) {
        const res = await doctorApi.updateInvoiceSettlement(
          invoiceIds[i],
          date
        );
        console.log("updateInvoiceSettlement--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSettle " + "updateInvoiceSettlement",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "invoiceId:" + invoiceIds[i]
          );
          return;
        }
      }
      setSuccessOpen(true);
      setTimeout(() => {
        setSuccessOpen(false);
      }, 3000);
      console.log("selectedDoctor---", fromDate, toDate, selectedDoctor);
      getInvoiceList(selectedDoctor);
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + "handleSettle",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      return;
    }
  };

  const checkAll = () => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');
    if (document.getElementById("selectAll").checked) {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = true;
      });
    } else {
      checkboxes.forEach((checkbox) => {
        checkbox.checked = false;
      });
    }
  };

  useEffect(() => {
    getInvoiceList(selectedDoctor);
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="mb-10">
                <div className="pd">
                  <div className="row">
                    <h1>Online Payment</h1>
                  </div>
                  <div className="row mb-5">
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">From :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="fromDate"
                          id="fromDate"
                          openToDate={fromDate}
                          selected={fromDate}
                          onChange={(date) => {
                            setFromDate(date);
                          }}
                          minDate={new Date("2022-01-01")}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-2">
                      <label className="col-lg-3 mt-2 p-0">To :</label>
                      <div className="col-lg-2 p-0">
                        <DatePicker
                          name="toDate"
                          id="toDate"
                          openToDate={toDate}
                          selected={toDate}
                          onChange={(date) => {
                            setToDate(date);
                          }}
                          minDate={fromDate}
                          maxDate={new Date()}
                          dateFormat="dd-MM-yyyy"
                          customInput={<DateCustomInput />}
                          className="form-control datepicker"
                          renderCustomHeader={({
                            date,
                            changeYear,
                            changeMonth,
                          }) => (
                            <div
                              style={{
                                margin: 10,
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <select
                                style={{ marginRight: "3px" }}
                                value={date.getFullYear()}
                                onChange={({ target: { value } }) =>
                                  changeYear(value)
                                }
                              >
                                {years.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                              <select
                                value={months[date.getMonth()]}
                                onChange={({ target: { value } }) =>
                                  changeMonth(months.indexOf(value))
                                }
                              >
                                {months.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                                ))}
                              </select>
                            </div>
                          )}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4 mt-2">
                      <br />
                      <Multiselect
                        name="patient"
                        id="doctorId"
                        options={doctors}
                        showArrow={true}
                        selectedValues={doctorSel}
                        onSelect={handleSelectDoctor}
                        onRemove={handleRemove}
                        placeholder={"Doctor Name/Mobile"}
                        hidePlaceholder={true}
                        displayValue="practitioner_name"
                        avoidHighlightFirstOption={true}
                        selectionLimit={1}
                        customCloseIcon={" "}
                        customArrow={
                          <span
                            className="icofont-search"
                            style={{
                              fontSize: "15px",
                            }}
                          ></span>
                        }
                        onSearch={(e) => {
                          handleSearchDoctor(
                            document.getElementById("doctorId_input").value
                          );
                          handleSelectDoctor("");
                        }}
                        emptyRecordMsg={
                          <div className="notFoundSearch">Type to search</div>
                        }
                      />
                    </div>
                  </div>

                  <ShowMessage
                    view={successOpen}
                    Message="Settlement updated successfully"
                  />
                  <ErrorMessage
                    error="Please choose atleast one invoice to settle"
                    visible={showInvoiceError}
                  />
                  <div className="row">
                    <div className="row border p-3">
                      <div className="row totcntstyle">
                        A total of {data.length} online payment identified.
                      </div>
                      <div className="table-responsive mt-1">
                        {data.length > 0 ? (
                          <table className="table">
                            <thead>
                              <tr>
                                <th width="1%">No</th>
                                <th width="10%">Invoice No</th>
                                <th width="15%">Patient</th>
                                <th width="15%">Doctor</th>
                                <th>Clinic</th>
                                <th width="10%">Date</th>
                                <th width="5%">Status</th>
                                <th width="7%">Amount (₹)</th>
                                <th width="10%">Settlement Date</th>
                                <th width="1%">
                                  <input
                                    type="checkbox"
                                    name="selectAll"
                                    id="selectAll"
                                    onClick={(e) => checkAll()}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {data.map((item) => {
                                total += item.grand_total;
                                return (
                                  <tr key={item.name}>
                                    <td align="center">{sno++}</td>
                                    <td className="nowrapWhiteSpace">
                                      <a
                                        className="pointer"
                                        onClick={() => handleClick(item.name)}
                                      >
                                        {item.name}
                                      </a>
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.patient_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.practitioner_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {item.company_name}
                                    </td>
                                    <td className="nowrapWhiteSpace">
                                      {moment(item.posting_date).format(
                                        "DD-MM-yyyy"
                                      )}
                                    </td>
                                    <td>{item.status}</td>
                                    <td align="right">{item.grand_total}</td>
                                    <td>
                                      {item.settled
                                        ? moment(item.settlement_date).format(
                                            "DD-MM-yyyy"
                                          )
                                        : "Pending"}
                                    </td>
                                    <td>
                                      {!item.settled ? (
                                        <input
                                          type="checkbox"
                                          name="settle"
                                          id="settle"
                                          value={item.name}
                                          onChange={(e) => {}}
                                        />
                                      ) : null}
                                    </td>
                                  </tr>
                                );
                              })}
                              <tr>
                                <td colSpan="7" className="alignRight">
                                  Total
                                </td>
                                <td align="right">{total}</td>
                                <td colSpan="2" align="right">
                                  <button
                                    className="btn"
                                    onClick={(e) => handleSettle()}
                                  >
                                    Update Settlement
                                  </button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OnlineInvoiceReport;
