import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import moment from "moment";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";

import DoctorNavBar from "../doctorHome/DoctorNavBar";
import DoctorHeader from "../doctorHome/DoctorHeader";
import storage from "../auth/storage";
import CustomButton from "../component/common/CustomButton";
const prefixerror = "UserList";

function PatientUserList() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [patients, setPatients] = useState([]);
  const [patientSel, setPatientSel] = useState([]);
  const navigate = useNavigate();
  let sno = limitStart + 1;
  //#endregion

  const handleDeleteUser = async (userId) => {
    try {
      setLoad(true);
      const userRes = await doctorApi.getStaff(userId);
      console.log("userRes---", userRes);

      if (!userRes.ok) {
        ErrorLog(
          prefixerror + " handleDeleteUser " + "getStaff",
          JSON.stringify(userRes),
          "We are facing some issues.Please try again after sometime.",
          "userId:" + userId
        );
        return;
      }
      const patientRes = await doctorApi.getPatientByEmail(userId);
      console.log("patientRes---", patientRes);

      for (let i = 0; i < patientRes.data.data.length; i++) {
        const delpatientAddrRes = await adminApi.deleteFromDoctype(
          "Address",
          patientRes.data.data[i].name + "-Office"
        );
        console.log("delpatientAddrRes---", delpatientAddrRes);

        const paymentRes = await adminApi.getPaymentEntryByPatient(
          patientRes.data.data[i].name
        );
        console.log("paymentRes---", paymentRes);

        for (let i = 0; i < paymentRes.data.data.length; i++) {
          console.log(
            "paymentRes.data.data[i].name---",
            paymentRes.data.data[i].name
          );
          const cancelPaymentRes = await adminApi.cancelDoctype(
            "Payment Entry",
            paymentRes.data.data[i].name.toString()
          );
          console.log("cancelPaymentRes---", cancelPaymentRes);
          if (cancelPaymentRes.ok) {
            const delPaymentRes = await adminApi.deleteFromDoctype(
              "Payment Entry",
              paymentRes.data.data[i].name
            );
            console.log("delPaymentRes---", delPaymentRes);
          }
        }

        const invoiceRes = await adminApi.getDetailsByPatient(
          "Sales Invoice",
          patientRes.data.data[i].name
        );
        console.log("invoiceRes---", invoiceRes);
        for (let i = 0; i < invoiceRes.data.data.length; i++) {
          console.log("id---", invoiceRes.data.data[i].name);
          if (invoiceRes.data.data[i].docstatus == 1) {
            const cancelInvoiceRes = await adminApi.cancelDoctype(
              "Sales Invoice",
              invoiceRes.data.data[i].name
            );
            console.log("cancelInvoiceRes---", cancelInvoiceRes);
            if (cancelInvoiceRes.ok) {
              const delInvoiceRes = await adminApi.deleteFromDoctype(
                "Sales Invoice",
                invoiceRes.data.data[i].name
              );
              console.log("delInvoiceRes---", delInvoiceRes);
            }
          } else {
            const delInvoiceRes = await adminApi.deleteFromDoctype(
              "Sales Invoice",
              invoiceRes.data.data[i].name
            );
            console.log("delInvoiceRes---", delInvoiceRes);
          }
        }

        const consultationRes = await adminApi.getDetailsByPatient(
          "Patient Encounter",
          patientRes.data.data[i].name
        );
        console.log("consultationRes---", consultationRes);

        for (let i = 0; i < consultationRes.data.data.length; i++) {
          console.log("id---", consultationRes.data.data[i].name);
          console.log("docStatus---", consultationRes.data.data[i].docstatus);
          if (consultationRes.data.data[i].docstatus == 1) {
            const cancelConsultationeRes = await adminApi.cancelDoctype(
              "Patient Encounter",
              consultationRes.data.data[i].name
            );
            console.log("cancelConsultationeRes---", cancelConsultationeRes);
            if (cancelConsultationeRes.ok) {
              const delConsultationRes = await adminApi.deleteFromDoctype(
                "Patient Encounter",
                consultationRes.data.data[i].name
              );
              console.log("delConsultationRes---", delConsultationRes);
            }
          } else {
            const delConsultationRes = await adminApi.deleteFromDoctype(
              "Patient Encounter",
              consultationRes.data.data[i].name
            );
            console.log("delConsultationRes---", delConsultationRes);
          }
        }

        const vitalRes = await adminApi.getDetailsByPatient(
          "Vital Signs",
          patientRes.data.data[i].name
        );
        console.log("vitalRes--", vitalRes);

        for (let i = 0; i < vitalRes.data.data.length; i++) {
          console.log("id---", vitalRes.data.data[i].name);
          if (vitalRes.data.data[i].docstatus == 1) {
            const cancelvitalRes = await adminApi.cancelDoctype(
              "Vital Signs",
              vitalRes.data.data[i].name
            );
            console.log("cancelvitalRes---", cancelvitalRes);
            if (cancelvitalRes.ok) {
              const delvitalRes = await adminApi.deleteFromDoctype(
                "Vital Signs",
                vitalRes.data.data[i].name
              );
              console.log("delvitalRes---", delvitalRes);
            }
          } else {
            const delvitalRes = await adminApi.deleteFromDoctype(
              "Vital Signs",
              vitalRes.data.data[i].name
            );
            console.log("delvitalRes---", delvitalRes);
          }
        }
        const appRes = await adminApi.getDetailsByPatient(
          "Patient Appointment",
          patientRes.data.data[i].name
        );
        console.log("appRes---", appRes);

        for (let i = 0; i < appRes.data.data.length; i++) {
          console.log("id---", appRes.data.data[i].name);

          const delappRes = await adminApi.deleteFromDoctype(
            "Patient Appointment",
            appRes.data.data[i].name
          );
          console.log("delappRes---", delappRes);
        }

        const contactRes = await adminApi.getContact(
          patientRes.data.data[i].name
        );
        console.log("contactRes---", contactRes);
        if (contactRes.data.data.length > 0) {
          for (let i = 0; i < contactRes.data.data.length; i++) {
            const delcontactRes = await adminApi.deleteFromDoctype(
              "Contact",
              contactRes.data.data[i].name
            );
            console.log("delcontactRes---", delcontactRes);
          }
        }

        const customerRes = await adminApi.getPatientByDoctype(
          "Customer",
          patientRes.data.data[i].name
        );
        console.log("customerRes---", customerRes);
        if (customerRes.ok) {
          for (let i = 0; i < customerRes.data.data.length; i++) {
            const delcustomerRes = await adminApi.deleteFromDoctype(
              "Customer",
              customerRes.data.data[i].name
            );
            console.log("delcustomerRes---", delcustomerRes);
          }
        }

        const delPatientRes = await adminApi.deleteFromDoctype(
          "Patient",
          patientRes.data.data[i].name
        );
        console.log("delPatientRes--", delPatientRes);
      }

      const fileRes = await doctorApi.getReport(userId);
      console.log("fileRes---", fileRes);

      for (let i = 0; i < fileRes.data.data.length; i++) {
        console.log("id---", fileRes.data.data[i].name);

        const delfileRes = await adminApi.deleteFromDoctype(
          "File",
          fileRes.data.data[i].name
        );
        if (!delfileRes.ok) {
          ErrorLog(
            prefixerror + " handleDeleteUser " + "deleteFromDoctype",
            JSON.stringify(delfileRes),
            "We are facing some issues.Please try again after sometime.",
            "file name:" + fileRes.data.data[i].name
          );
          return;
        }
        console.log("delfileRes---", delfileRes);
      }

      const deluserRes = await adminApi.deleteFromDoctype(
        "User",
        userRes.data.data.name
      );
      console.log("deluserRes---", deluserRes);
      if (!deluserRes.ok) {
        setLoad(false);
        ErrorLog(
          prefixerror + " handleDeleteUser " + "deleteFromDoctype",
          JSON.stringify(deluserRes),
          "We are facing some issues.Please try again after sometime.",
          "name:" + userRes.data.data.name
        );
        return;
      }

      setOpen(true);
      setMessage("User deleted successfully");
      setTimeout(() => {
        setOpen(false);
      }, 3000);

      setData([]);
      getPatientList(0, "");
      setLoad(false);
      return;
    } catch (error) {
      setLoad(false);
      console.log("ex---", error);
      ErrorLog(
        prefixerror + " handleDeleteUser ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handleSearchPatient = async (value) => {
    try {
      console.log("value---", value);
      setLoad(true);
      setPatients([]);
      if (value.length > 1) {
        const res = await adminApi.getSearchPatientAppUser(value);
        console.log("searchRes--", res);
        if (!res.ok) {
          ErrorLog(
            prefixerror + "handleSearchPatient " + "getSearchDoctor",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              full_name:
                res.data.data[i].full_name +
                "-" +
                moment().diff(res.data.data[i].birth_date, "years") +
                " years-" +
                res.data.data[i].mobile_no,
            };
            arr.push(myObject);
          }
          console.log("arr---", arr);
          setPatients(arr);
        }
      }
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleSearchPatient ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      console.log(error);
      setLoad(false);
    }
  };

  const handleRemove = () => {
    setPatientSel([]);
    setPatients([]);
    getPatientList(0, "");
  };

  const handleSelectPatient = (e) => {
    console.log(e, "e");
    setPatientSel([]);
    setPatients([]);
    if (e && e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        e[i].full_name = e[i].full_name.split("-")[0];
      }
      setPatientSel(e);
      getPatientList(0, `,["full_name","=","${e[0].full_name.split("-")[0]}"]`);
      setPatients(e);
      document.getElementById("patient_input").blur();
    }
  };

  const getPatientList = async (limitStart, searchString) => {
    try {
      setLoad(true);
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const allRes = await adminApi.getAllPatientAppUserList(
        searchString,
        0,
        "None"
      );
      console.log("allRes----", allRes);
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + " getUsersList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString
        );
        return;
      }

      setTotalCount(allRes.data.data.length);

      const res = await adminApi.getAllPatientAppUserList(
        searchString,
        limitStart,
        pageSize
      );
      console.log("res---------", res);

      if (!res.ok) {
        ErrorLog(
          prefixerror + " getUsersList " + "getAllPatientAppUserList",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "limitStart:" + limitStart + "pageSize:" + pageSize
        );
        return;
      }

      if (res.data.data.length > 0) {
        setData(res.data.data);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " getUsersList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getPatientList(lmtst, "");
  };

  const handleActivate = async (patientid) => {
    const changeres = await adminApi.patientStatusChange(patientid, 1);
    console.log("changeres--", changeres);
    if (!changeres.ok) {
      ErrorLog(
        prefixerror + "handleActivate " + "patientStatusChange",
        JSON.stringify(changeres),
        "We are facing some issues.Please try again after sometime.",
        "patientid:" + patientid + "enabled:1"
      );
      return;
    }
    getPatientList(0, "");
  };

  const handleDisable = async (patientid) => {
    try {
      setLoad(true);
      const changeres = await adminApi.patientStatusChange(patientid, 0);
      console.log("changeres--", changeres);
      if (!changeres.ok) {
        ErrorLog(
          prefixerror + "handleDisable " + "patientStatusChange",
          JSON.stringify(changeres),
          "We are facing some issues.Please try again after sometime.",
          "patientid:" + patientid + "enabled:0"
        );
        return;
      }

      const generateKeysRes = await adminApi.generateKeys(patientid);
      console.log("generateKeysRes--", generateKeysRes);
      if (!generateKeysRes.ok) {
        ErrorLog(
          prefixerror + "handleDisable " + "generateKeysRes",
          JSON.stringify(generateKeysRes),
          "We are facing some issues.Please try again after sometime.",
          "patientid:" + patientid
        );
        return;
      }
      setMessage(`Disabled Successfully`);
      setOpen(true);
      getPatientList(0, "");
      setTimeout(() => {
        setOpen(false);
      }, 3000);
      setLoad(false);
    } catch (error) {
      ErrorLog(
        prefixerror + " handleDisable ",
        error,
        "We are facing some issues.Please try again after sometime.",
        ""
      );
      setLoad(false);
    }
  };

  useEffect(() => {
    getPatientList(0, "");
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="mb-4">
                <div className="pd">
                  <div className="row">
                    <h1>Patient App User List</h1>
                  </div>
                  <div className="row form-group">
                    <div className="col-lg-4 ">
                      <Multiselect
                        name="patient"
                        id="patient"
                        options={patients}
                        showArrow={true}
                        selectedValues={patientSel}
                        onSelect={handleSelectPatient}
                        onRemove={handleRemove}
                        placeholder={"Patient Name/Mobile"}
                        hidePlaceholder={true}
                        displayValue="full_name"
                        avoidHighlightFirstOption={true}
                        selectionLimit={1}
                        customCloseIcon={" "}
                        customArrow={
                          <span
                            className="icofont-search"
                            style={{
                              fontSize: "15px",
                            }}
                          ></span>
                        }
                        onSearch={(e) => {
                          handleSearchPatient(
                            document.getElementById("patient_input").value
                          );
                          handleSelectPatient("");
                        }}
                        emptyRecordMsg={
                          <div className="notFoundSearch">Type to search</div>
                        }
                      />
                    </div>
                  </div>
                  <div className="row">
                    <ShowMessage view={open} Message={message} />
                    <div className="row border p-3">
                      <div className="row totcntstyle">
                        A total of {totalCount} patient app user identified.
                      </div>
                      {data.length > 0 ? (
                        <>
                          <div className="table-responsive">
                            <table className="table">
                              <thead>
                                <tr>
                                  <th width="1%">No</th>
                                  <th width="10%">Name</th>
                                  <th width="5%">Age</th>
                                  <th width="5%">Mobile</th>
                                  <th>Email</th>
                                  <th width="5%">Reg. On</th>
                                  <th width="8%">OS</th>
                                  <th width="5%">Status</th>
                                  <th width="5%">Update</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((item, index) => {
                                  return (
                                    <tr key={index}>
                                      <td>{sno++}</td>
                                      <td className="nowrapWhiteSpace">
                                        {item.full_name}
                                      </td>
                                      <td>
                                        {moment().diff(
                                          item.birth_date,
                                          "years"
                                        )}
                                      </td>
                                      <td>{item.mobile_no}</td>
                                      <td>{item.email}</td>
                                      <td className="nowrapWhiteSpace">
                                        {moment(item.creation).format(
                                          "DD-MM-yyyy"
                                        )}
                                      </td>
                                      <td>{item.location}</td>
                                      <td>
                                        {item.enabled ? "Active" : "Disabled"}
                                      </td>
                                      <td>
                                        {item.enabled ? (
                                          <CustomButton
                                            className="btnAdmin bg-pink-dark"
                                            type="submit"
                                            title="Disable"
                                            onClick={() =>
                                              handleDisable(item.name)
                                            }
                                          />
                                        ) : (
                                          <CustomButton
                                            className="btnAdmin"
                                            type="submit"
                                            title="Activate"
                                            onClick={() =>
                                              handleActivate(item.name)
                                            }
                                          />
                                        )}
                                      </td>
                                      {/* <td width="5%">
                                        <a
                                          title="Delete"
                                          className="pointer"
                                          onClick={() =>
                                            handleDeleteUser(item.name)
                                          }
                                        >
                                          Delete
                                        </a>
                                      </td> */}
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                          {totalCount > pageSize && (
                            <div className="text-center">
                              <table>
                                <tbody>
                                  <tr>
                                    <td className="p-3">
                                      <Pagination
                                        itemsCount={totalCount}
                                        pageSize={pageSize}
                                        currentPage={currentPage}
                                        onPageChange={(page) =>
                                          handlePageChange(page)
                                        }
                                        style={{ cursor: "pointer" }}
                                      />
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          )}
                        </>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
export default PatientUserList;
