import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import moment from "moment";
import ReactCrop from "react-image-crop";
import DatePicker from "react-datepicker";

import doctorApi from "../api/doctorApi";
import doctorRegApi from "../api/doctorRegApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import RadioInput from "../component/common/RadioInput";
import Select from "../component/common/select";
import ErrorMessage from "../component/common/ErrorMessage";
import preFunction from "../component/common/CommonFunction";
import CustomTextInput from "../component/common/CustomTextInput";
import ShowMessage from "../component/common/ShowMessage";
import ErrorLog from "../component/common/ErrorLog";
import {
  genderOption,
  roleOptions,
  months,
} from "../component/common/CommonArray";

import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import string from "../string";
import storage from "../auth/storage";
import photo from "../assests/png/camera.png";
import blankProfile from "../assests/png/blank-profile-picture.png";
import "react-datepicker/dist/react-datepicker.css";
import "react-image-crop/dist/ReactCrop.css";
const prefixerror = "NewUser";

function NewUser() {
  //#region const
  const location = useLocation();
  const navigate = useNavigate();
  const [displayImage, setDisplayImage] = useState(blankProfile);
  const [role, setRole] = useState();
  const [openDate, setOpenDate] = useState();
  const [showUser, setShowUser] = useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState();
  const [showImg, setShowImg] = useState(false);
  const [onChangeImage, setonChangeImage] = useState(false);
  const [showimg, setshowimg] = useState("");
  const [url, setUrl] = useState();
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [dob, setDOB] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [gender, setGender] = useState("");
  const [load, setLoad] = useState(false);
  const [loggedEmail, setLoggedEmail] = useState();
  const [openModel, setOpenModal] = useState(false);
  const [CropOpen, setCropOpen] = useState(false);
  const [src, setSrc] = useState(null);
  const [crop, setCrop] = useState({
    unit: "px",
    width: 150,
    height: 150,
    minwidth: 150,
    minheight: 150,
    maxwidth: 300,
    maxheight: 3000,
    aspect: 1,
  });
  const [image, setImage] = useState(null);
  const [output, setOutput] = useState(null);
  const date = new Date();
  const years = preFunction.range(
    date.getFullYear() - 100,
    date.getFullYear() - 17,
    1
  );
  const [showDOB, setshowDOB] = useState(false);
  const [showFirstName, setShowFirstName] = useState(false);
  const [showLastName, setShowLastName] = useState(false);
  const [showMobile, setshowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showGender, setshowGender] = useState(false);
  const DateCustomInput = preFunction.DateCustomInput;
  const [showSuccess, setShowSuccess] = useState(false);
  //#endregion

  const selectImage = (file) => {
    if (file.type !== "image/png" && file.type !== "image/jpeg") {
      setShowImg(true);
      return;
    }
    setShowImg(false);
    setSrc(URL.createObjectURL(file));
    setOpenModal(true);
  };

  const cropImageNow = () => {
    if (crop.width === 0 || crop.height === 0) {
      setCropOpen(true);
      return;
    }
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    const pixelRatio = window.devicePixelRatio;
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Converting to base64
    const base64Image = canvas.toDataURL("image/jpeg");
    setOutput(base64Image);
    setDisplayImage(base64Image);
    setOpenModal(false);
    setonChangeImage(true);
  };

  const closeErrors = () => {
    setShowImg(false);
    setShowUser(false);
    setShowFirstName(false);
    setShowLastName(false);
    setShowEmail(false);
    setshowMobile(false);
    setshowDOB(false);
    setshowGender(false);
    setOpen(false);
    document.getElementById("input").className = "form-control ";
    // document.getElementById("role").className = "form-control select-control";
    document.getElementById("firstname").className = "form-control";
    document.getElementById("lastname").className = "form-control";
    document.getElementById("email").className = "form-control";
    document.getElementById("mobile").className = "form-control datepicker";
    document.getElementById("dobButton").className = "form-control datepicker";
  };

  const handleSubmit = async () => {
    try {
      let err = false;
      setshowGender(false);
      if (gender == "") {
        setshowGender(true);
        err = true;
      }
      const dt = !dob ? "" : moment(dob).format("yyyy-MM-DD");

      if (dt == "") {
        setshowDOB(true);
        err = true;
      }
      setshowMobile(false);
      if (mobile == "" || mobile.length != 10 || isNaN(mobile)) {
        setshowMobile(true);
        document.getElementById("mobile").className += " form-control-error";
        document.getElementById("mobile").focus();
        err = true;
      }

      setShowEmail(false);
      if (email == "" || !email.match(/.+@.+/)) {
        setShowEmail(true);
        document.getElementById("email").className += " form-control-error";
        document.getElementById("email").focus();
        err = true;
      }

      setShowLastName(false);
      if (lastname == "" || lastname.length > 100) {
        setShowLastName(true);
        document.getElementById("lastname").className += " form-control-error";
        document.getElementById("lastname").focus();
        err = true;
      }

      setShowFirstName(false);
      if (firstname == "" || firstname.length < 3 || firstname.length > 100) {
        setShowFirstName(true);
        document.getElementById("firstname").className += " form-control-error";
        document.getElementById("firstname").focus();
        err = true;
      }

      setShowUser(false);
      if (!role) {
        setShowUser(true);
        document.getElementById("role").className += " form-control-error";
        document.getElementById("role").focus();
        err = true;
      }

      setShowImg(false);
      if (displayImage === blankProfile) {
        setShowImg(true);
        document.getElementById("input").className += " form-control-error";
        document.getElementById("input").focus();
        err = true;
      }

      if (err) {
        return;
      }
      setLoad(true);
      const fileName = "profile";

      if (location.state && location.state.staff) {
        const res = await doctorApi.updateUser(
          email,
          firstname,
          lastname,
          gender,
          mobile,
          dt,
          role,
          url
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "updateUser",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "email:" +
              email +
              "firstname:" +
              firstname +
              "lastname:" +
              lastname +
              "gender:" +
              gender +
              "mobile:" +
              mobile +
              "dt:" +
              dt +
              "role:" +
              role +
              "url:" +
              url
          );
          setLoad(false);
          setOpen(true);
          setMessage(
            JSON.parse(JSON.parse(res.data._server_messages)[0]).message
          );
          return;
        }

        if (onChangeImage) {
          const response = await doctorRegApi.imageUploadProfile(
            fileName,
            displayImage
          );
          if (!response.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "imageUploadProfile",
              JSON.stringify(response),
              "We are facing some issues.Please try again after sometime.",
              "fileName:" + fileName + "displayImage:" + displayImage
            );
            return;
          }
          console.log("response---", response);
          setUrl(response.data.message.file_url);
          const res1 = await doctorApi.updateFile(
            email,
            response.data.message.file_url
          );
          if (!res1.ok) {
            ErrorLog(
              prefixerror + " handleSubmit " + "updateFile",
              JSON.stringify(res1),
              "We are facing some issues.Please try again after sometime.",
              "email:" + email + "file_url:" + response.data.message.file_url
            );
            return;
          }
          console.log(res1);
          if (email == loggedEmail) {
            const dp = string.testbaseUrl + res1.data.data.user_image;
            await storage.storeStaffDp(
              string.testbaseUrl + res1.data.data.user_image
            );
            console.log("dp---", dp);
          }
        }
        setLoad(false);
        if (email != loggedEmail) {
          setShowSuccess(true);
          setTimeout(() => {
            navigate("/userlist");
          }, 3000);
        } else {
          setShowSuccess(true);
          setMessage("Updated Successfully");
          setTimeout(() => {
            setShowSuccess(false);
          }, 3000);
        }
      } else {
        // const res = await doctorApi.createUser(
        //   email,
        //   firstname,
        //   lastname,
        //   gender,
        //   mobile,
        //   dt,
        //   role,
        //   url,
        //   mobile
        // );
        // if (!res.ok) {
        //   ErrorLog(
        //     prefixerror + " handleSubmit " + "createUser",
        //     JSON.stringify(res),
        //     "We are facing some issues.Please try again after sometime.",
        //     "email:" +
        //       email +
        //       "firstname:" +
        //       firstname +
        //       "lastname:" +
        //       lastname +
        //       "gender:" +
        //       gender +
        //       "mobile:" +
        //       mobile +
        //       "dt:" +
        //       dt +
        //       "role:" +
        //       role +
        //       "url:" +
        //       url
        //   );
        //   setLoad(false);
        //   setOpen(true);
        //   setMessage(
        //     JSON.parse(JSON.parse(res.data._server_messages)[0]).message
        //   );
        //   return;
        // }

        // console.log("New User---", res);
        // const res1 = await doctorApi.assignUser(email);
        // if (!res1.ok) {
        //   ErrorLog(
        //     prefixerror + " handleSubmit " + "assignUser",
        //     JSON.stringify(res1),
        //     "We are facing some issues.Please try again after sometime.",
        //     "email:" + email
        //   );
        //   return;
        // }
        // console.log(res1);

        const clinicId = await storage.getCompany();
        console.log(
          email,
          firstname,
          lastname,
          gender,
          mobile,
          dt,
          role,
          clinicId,
          "web"
        );
        const res = await doctorApi.createUser(
          email,
          firstname,
          lastname,
          gender,
          mobile,
          dt,
          role,
          clinicId,
          "web"
        );
        console.log("res---------", res);
        if (res.data.message.error) {
          setMessage(res.data.message.error);
          setLoad(false);
          setOpen(true);
          return;
        }
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "createUser",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "email:" +
              email +
              "firstname:" +
              firstname +
              "lastname:" +
              lastname +
              "gender:" +
              gender +
              "mobile:" +
              mobile +
              "dt:" +
              dt +
              "role:" +
              role +
              "clinicId:" +
              clinicId
          );
        }
        setLoad(false);
        setOpen(true);
        // setMessage(
        //   JSON.parse(JSON.parse(res.data._server_messages)[0]).message
        // );

        const response = await doctorRegApi.imageUploadProfile(
          fileName,
          displayImage
        );
        if (!response.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "imageUploadProfile",
            JSON.stringify(response),
            "We are facing some issues.Please try again after sometime.",
            "fileName:" + fileName + "displayImage:" + displayImage
          );
          return;
        }
        setUrl(response.data.message.file_url);
        const res1 = await doctorApi.updateFile(
          email,
          response.data.message.file_url
        );
        if (!res1.ok) {
          ErrorLog(
            prefixerror + " handleSubmit " + "updateFile",
            JSON.stringify(res1),
            "We are facing some issues.Please try again after sometime.",
            "email:" + email + "file_url:" + response.data.message.file_url
          );
          return;
        }
        // const clinicId = await storage.getCompany();
        // console.log("clinicId--------", clinicId);

        // const getDoctorsByHospital = await doctorApi.getDoctorByHospital(
        //   clinicId
        // );
        // console.log("getDoctorsByHospital------", getDoctorsByHospital);
        // for (let i = 0; i < getDoctorsByHospital.data.data.length; i++) {
        //   const assignDoctorXUserRes = await doctorApi.assignDoctorXUser(
        //     email,
        //     getDoctorsByHospital.data.data[i].name
        //   );
        //   console.log("assignDoctorXUserRes----", assignDoctorXUserRes);
        // }
        navigate("/userlist");
      }

      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log("Error", error);
      ErrorLog(
        prefixerror + " handleSubmit ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  const getStaff = async () => {
    try {
      setLoad(true);
      var today = new Date();
      var opendate = new Date(today.getFullYear() - 30, 0, 1);
      setOpenDate(opendate);
      setLoggedEmail(await storage.getEmail());
      if (location.state && location.state.staff) {
        const res = await doctorApi.getStaff(location.state.staff);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " getStaff " + "getStaff",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "staff:" + location.state.staff
          );
          return;
        }
        console.log("res------------", res);
        const gen = res.data.data.gender
          ? (document.getElementById(res.data.data.gender).checked = true)
          : "";
        setFirstname(res.data.data.first_name);
        setLastname(res.data.data.last_name);
        setEmail(res.data.data.email);

        setDOB(new Date(res.data.data.birth_date));
        setGender(res.data.data.gender);
        setMobile(res.data.data.mobile_no);
        setRole(res.data.data.role_profile_name);
        if (res.data.data.user_image) {
          setshowimg(string.testbaseUrl + res.data.data.user_image);
          setDisplayImage(string.testbaseUrl + res.data.data.user_image);
        }
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      ErrorLog(
        prefixerror + " getStaff ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
    }
  };

  useEffect(() => {
    getStaff();
    document.getElementById("role")?.focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{ height: 100, alignSelf: "center" }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>
          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row mb-4" onClick={preFunction.hideNavbar}>
              <div className="pd mb-4">
                <div className="col-lg-8">
                  <div className="row no-gutters">
                    <h1 className="content-heading mb-4">
                      Employee Registration
                    </h1>
                  </div>
                  <div
                    className="row mb-4 border p-lg-5"
                    style={{ borderRadius: "6px" }}
                  >
                    <div className="col-lg-9">
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Role :
                        </label>

                        {email == loggedEmail ? (
                          <div className="col-sm-7 mt-2"> {role} </div>
                        ) : (
                          <div className="col-sm-7">
                            <Select
                              name="role"
                              id="role"
                              options={roleOptions}
                              placeholder="Role"
                              value={role}
                              className={
                                role
                                  ? "form-control select-control"
                                  : "form-control select-control opacity"
                              }
                              onClick={() => setOpen(false)}
                              onChange={(e) => {
                                closeErrors();
                                setRole(e.target.value);
                              }}
                              // disabled={email == loggedEmail ? true : false}
                            />
                            <ErrorMessage
                              visible={showUser}
                              error="Please select the Role"
                            ></ErrorMessage>
                          </div>
                        )}
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> First Name :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="text"
                            name="firstname"
                            id="firstname"
                            maxLength={40}
                            className="form-control"
                            placeholder="First Name"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            value={firstname}
                            onClick={() => setOpen(false)}
                            onChange={(e) => {
                              closeErrors();
                              setFirstname(
                                preFunction.capitalizeFirst(e.target.value)
                              );
                            }}
                          />
                          <ErrorMessage
                            visible={showFirstName}
                            error="Please enter the First Name"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Last Name :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="text"
                            name="lastname"
                            id="lastname"
                            maxLength={40}
                            value={lastname}
                            className="form-control"
                            placeholder="Last Name"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            onClick={() => setOpen(false)}
                            onChange={(e) => {
                              closeErrors();
                              setLastname(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            visible={showLastName}
                            error="Please enter the Last Name"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Email :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="email"
                            name="email"
                            id="email"
                            maxLength={80}
                            value={email}
                            className="form-control"
                            placeholder="Email"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            onClick={() => setOpen(false)}
                            onChange={(e) => {
                              closeErrors();
                              setEmail(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            visible={showEmail}
                            error="Please enter the valid Email"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Mobile Number :
                        </label>
                        <div className="col-sm-7">
                          <CustomTextInput
                            type="number"
                            name="mobile"
                            id="mobile"
                            maxlength="10"
                            value={mobile}
                            className="form-control"
                            placeholder="Mobile Number"
                            onKeyUp={(e) => e.keyCode == 13 && handleSubmit()}
                            onClick={() => setOpen(false)}
                            onChange={(e) => {
                              closeErrors();
                              e.target.value =
                                e.target.value.length > 10
                                  ? e.target.value.substr(0, 10)
                                  : e.target.value;
                              setMobile(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            visible={showMobile}
                            error="Please enter the valid Mobile Number"
                          />
                        </div>
                      </div>
                      <div className="row no-gutters form-group">
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Date of Birth :
                        </label>
                        <div className="col-sm-7">
                          <DatePicker
                            name="dob"
                            id="dob"
                            openToDate={dob ? dob : openDate}
                            selected={dob}
                            onChange={(date) => {
                              setDOB(date);
                              closeErrors();
                            }}
                            minDate={moment().subtract(100, "years")._d}
                            maxDate={moment().subtract(18, "years")._d}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            error={"Please select the Date of Birth"}
                            visible={showDOB}
                          />
                        </div>
                      </div>
                      <div
                        className="row no-gutters"
                        style={{ marginBottom: "1rem" }}
                      >
                        <label className="control-label col-sm-3">
                          <span className="star">*</span> Gender :
                        </label>
                        <div className="radio-group col-sm-7">
                          <RadioInput
                            name="gender"
                            data={genderOption}
                            onChange={(e) => {
                              closeErrors();
                              setGender(e.target.value);
                            }}
                          />
                          <ErrorMessage
                            visible={showGender}
                            error="Please select the Gender"
                          />
                        </div>
                      </div>
                      <ShowMessage
                        view={showSuccess}
                        Message={"Updated successfully"}
                      />
                      <ErrorMessage error={message} visible={open} />
                    </div>
                    <div className="col-lg-3">
                      <div className="label text-center">
                        <input
                          type="file"
                          accept="image/*"
                          name="image-upload"
                          id="input"
                          onChange={(e) => {
                            closeErrors();
                            selectImage(e.target.files[0]);
                          }}
                          onClick={(e) => {
                            e.target.value = "";
                          }}
                          style={{ display: "none" }}
                        />
                        <img
                          src={
                            output ? output : showimg ? showimg : displayImage
                          }
                          alt={string.websiteName + " Profile Picture"}
                          id="img"
                          className="text-center img-thumbnail image-upload"
                          htmlFor="input"
                        />
                        <label className="image-upload pointer" htmlFor="input">
                          <img
                            src={photo}
                            alt={string.websiteName + " Profile Picture"}
                            height="40"
                            style={{
                              position: "absolute",
                              marginTop: 65,
                              marginLeft: -25,
                            }}
                          />
                        </label>
                        <ErrorMessage
                          error={"Please select the Profile Image"}
                          visible={showImg}
                        />
                      </div>
                    </div>
                    <div className="row no-gutters">
                      <div className="mt-2 text-center">
                        <button
                          type="submit"
                          className="btn"
                          onClick={handleSubmit}
                          title={"Save"}
                        >
                          <span className="icofont-save"></span>Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <>
                    <Modal className="modalwidth" show={openModel}>
                      <Modal.Body>
                        <div>
                          {src && (
                            <div>
                              <ReactCrop
                                src={src}
                                onImageLoaded={setImage}
                                crop={crop}
                                onChange={setCrop}
                                minHeight={50}
                                minWidth={50}
                              />
                              <br />
                            </div>
                          )}
                        </div>
                        <ErrorMessage
                          error={"Click on image to crop"}
                          visible={CropOpen}
                        />
                      </Modal.Body>
                      <Modal.Footer>
                        <button
                          className="btn"
                          onClick={cropImageNow}
                          title="Crop"
                        >
                          <span className="icofont-crop"></span>Crop
                        </button>
                      </Modal.Footer>
                    </Modal>
                  </>
                </div>
                <div className="col-lg-4"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default NewUser;
