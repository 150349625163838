import React, { useEffect, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import doctorRegApi from "../api/doctorRegApi";

import AuthContext from "../auth/context";
import storage from "../auth/storage";

import ErrorLog from "../component/common/ErrorLog";
const prefixerror = "DoctorRegistration";
function DoctorRegistration() {
  //#region const
  const location = useLocation();
  const navigate = useNavigate();
  const { profile, setProfile } = useContext(AuthContext);
  //#endregion

  const getUser = async () => {
    if (location.state && location.state.id) {
      console.log("id---", location.state.id);
      const res = await doctorRegApi.getDetilsBasicInfo(location.state.id);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getUser " + "getDetilsBasicInfo",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "doctorID:" + location.state.id
        );
        return;
      }
      console.log("doctor res-----------", res);
      console.log("profileComplete--", res.data.data.profile_completeness);
      setProfile(Math.round(res.data.data.profile_completeness));
    } else {
      const profileComplete = await storage.getProfileComplete();
      console.log("profileComplete--", profileComplete);
      setProfile(Math.round(profileComplete));
    }
  };

  useEffect(() => {
    getUser();
  }, []);

  return (
    <>
      <section>
        <div className="row no-gutters p-4">
          <div className="pt-lg-5">
            <div className="row no-gutters pt-5">
              <div
                className={
                  window.location.href.indexOf("basicinformation") != -1
                    ? "col-1 pt-1 basicinfoselected"
                    : "col-1 pt-1 basicinfounselected"
                }
              >
                <span
                  className="icofont-user ps-1"
                  style={{
                    color:
                      window.location.href.indexOf("basicinformation") != -1
                        ? "#fff"
                        : "#ccddfc",
                  }}
                ></span>
              </div>
              <div
                className="col-10 profilesetting ps-2 pointer"
                onClick={() => navigate("/basicinformation")}
                style={{
                  opacity:
                    window.location.href.indexOf("basicinformation") != -1
                      ? 1
                      : 0.4,
                }}
              >
                Basic Information
              </div>
            </div>
          </div>
          <div className="pt-5">
            <div className="row no-gutters">
              <div
                className={
                  window.location.href.indexOf("appointment") != -1
                    ? "col-1 pt-1 basicinfoselected"
                    : "col-1 pt-1 basicinfounselected"
                }
              >
                <span
                  className="icofont-hospital ps-1"
                  style={{
                    color:
                      window.location.href.indexOf("appointment") != -1
                        ? "#fff"
                        : "#ccddfc",
                  }}
                ></span>
              </div>
              <div
                className="col-10 profilesetting ps-2 pointer"
                onClick={() =>
                  profile >= 45 ? navigate("/appointment") : null
                }
                style={{
                  opacity:
                    window.location.href.indexOf("appointment") != -1 ? 1 : 0.4,
                }}
              >
                Clinic Information
              </div>
            </div>
          </div>
          <div className="pt-5 pb-lg-5">
            <div className="row no-gutters pb-5">
              <div
                className={
                  window.location.href.indexOf("education") != -1
                    ? "col-1 pt-1 basicinfoselected"
                    : "col-1 pt-1 basicinfounselected"
                }
              >
                <span
                  className="icofont-certificate ps-1"
                  style={{
                    color:
                      window.location.href.indexOf("education") != -1
                        ? "#fff"
                        : "#ccddfc",
                  }}
                ></span>
              </div>
              <div
                className="col-10 profilesetting ps-2 pointer"
                onClick={() => (profile >= 78 ? navigate("/education") : null)}
                style={{
                  opacity:
                    window.location.href.indexOf("education") != -1 ? 1 : 0.4,
                }}
              >
                Certificate Information
              </div>
            </div>
          </div>
        </div>
        {/* <div className="row no-gutters mb-3">
          <div className="col-lg-2"></div>
          <div className="col-lg-8">
            <div
              className="row no-gutters"
              style={{
                width: "100%",
                padding: "2%",
              }}
            >
              <div className="col-2 text-center">
                <span className="icofont-live-messenger regBreadCrumb"></span>
              </div>
              <div className="col-3 text-center">
                <button className="menushow2">
                  <span className="mobileviewmenuicon1"></span>
                </button>
              </div>
              <div className="col-2 App">
                <span
                  className={`icofont-hospital ${
                    profile > 44 ? "regBreadCrumb" : "regBreadCrumbGray"
                  }`}
                ></span>
              </div>
              <div className="col-3 App">
                <button className="menushow2">
                  <span className="mobileviewmenuicon1"></span>
                </button>
              </div>
              <div className="col-2 App">
                <span
                  className={`icofont-certificate-alt-1 ${
                    profile == 100 ? "regBreadCrumb" : "regBreadCrumbGray"
                  }`}
                ></span>
              </div>
            </div>
          </div>
          <div className="col-lg-2"></div>
        </div> */}
      </section>
    </>
  );
}

export default DoctorRegistration;
