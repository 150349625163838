import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import doctorRegApi from "../api/doctorRegApi";
import ErrorLog from "../component/common/ErrorLog";
const prefixerror = "VerifyEmail";

function VerifyEmail() {
  const navigate = useNavigate();
  const parseParams = async (querystring) => {
    // parse query string
    const params = new URLSearchParams(querystring);
    const obj = {};
    // iterate over all keys
    for (const key of params.keys()) {
      if (params.getAll(key).length > 1) {
        obj[key] = params.getAll(key);
      } else {
        obj[key] = params.get(key);
      }
      console.log("key---", key);
      if (key == "email") {
        const email = obj[key];
        console.log("email--", email);
        try {
          const res = await doctorRegApi.verifyEmail(email, 1);
          if (!res.ok) {
            ErrorLog(
              prefixerror + " VerifyEmail " + "verifyEmail",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "email:" + email
            );
            return;
          }

          navigate("/basicinformation");
        } catch (error) {
          ErrorLog(
            prefixerror + " VerifyEmail ",
            error,
            "We are facing some issues.Please try again after sometime."
          );
        }
      }
    }
    return obj;
  };

  useEffect(() => {
    parseParams(window.location.search);
  }, []);
}

export default VerifyEmail;
