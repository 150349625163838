import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import LinkGroup from "../component/common/LinkGroup";
import ErrorLog from "../component/common/ErrorLog";

import doctorRegApi from "../api/doctorRegApi";

import storage from "../auth/storage";
import AuthContext from "../auth/context";
import string from "../string";

import DashboardIcon from "../assests/svg/team_dashboard.svg";
import AppointmentIcon from "../assests/svg/appointment.svg";
import PatientIcon from "../assests/svg/patientlist.svg";
import PHRIcon from "../assests/svg/phr.svg";
import ConsultationIcon from "../assests/svg/consultation_history.svg";
import PaymentIcon from "../assests/svg/payment.svg";
import PaymentReportIcon from "../assests/svg/payment_report.svg";
import OnlinePaymentIcon from "../assests/svg/online_payment.svg";
import TemplateIcon from "../assests/svg/template.svg";
import MedicineIcon from "../assests/svg/pill.svg";
import LabIcon from "../assests/svg/labs.svg";
import SymptomIcon from "../assests/svg/symptoms.svg";
import DiagnosisIcon from "../assests/svg/diagnosis.svg";
import UserIcon from "../assests/svg/users.svg";
import BasicIcon from "../assests/svg/bank_info.svg";
import ClinicIcon from "../assests/svg/clinic_info.svg";
import CertificateIcon from "../assests/svg/certificate_info.svg";
import BankIcon from "../assests/svg/bank_info.svg";
import ConfigIcon from "../assests/svg/settings.svg";
import LogoutIcon from "../assests/svg/logout.svg";

const prefixerror = "DoctorNavBar";

function DoctorNavBar() {
  //#region const
  const [role, setRole] = useState();
  const { profile, setProfile, smartAssistant, gMeetConfig } =
    useContext(AuthContext);
  const [status, setStatus] = useState();
  const navigate = useNavigate();
  //#endregion

  const getInformation = async () => {
    try {
      const doctorID = await storage.getID();
      console.log("doctorID--", doctorID);
      if (!doctorID) {
        navigate("/logout");
        return;
      }
      const role = await storage.getRole();
      const status = await storage.getStatus();
      console.log("status---", status);
      setStatus(status);
      setRole(role);
      console.log("role sidebar--", role);
      const user = await storage.getUser();
      // console.log("Role", role, status);

      if (!user) {
        navigate("/");
      }
      if (role != "SuperAdmin" && role != "Ad Manager") {
        const prof = await storage.getProfileComplete();
        console.log("prof---", prof);
        if (!prof || prof == 0) {
          const res = await doctorRegApi.getDetilsBasicInfo(doctorID);
          console.log(res, "-----------------------BasicInformation");
          if (!res.ok) {
            ErrorLog(
              prefixerror + "getInformation " + "getDetilsBasicInfo",
              JSON.stringify(res),
              "We are facing some issues.Please try again after sometime.",
              "doctorID:" + doctorID
            );
            return;
          }
          const profileComplete = res.data.data.profile_completeness;
          console.log("profileComplete--", profileComplete);
          await storage.storeProfileComplete(profileComplete);
          setProfile(Math.round(Math.round(profileComplete)));
        } else {
          setProfile(prof);
        }
      }
    } catch (error) {
      ErrorLog(
        prefixerror + " getInformation ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  useEffect(() => {
    getInformation();
  }, []);

  return (
    <div id="DoctorNavBar" className="menulistshow navbarvertical border">
      <div className="row mb-5 m-3">
        <a href="/">
          <img
            src={string.testbaseUrl + "/files/Cloud_Clinic.png"}
            width={220}
            height={60}
            alt={string.websiteName}
          />
        </a>
      </div>
      {role == "SuperAdmin" ? (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <div className="group-title">General</div>
            {/* <div>
              <LinkGroup
                to="/admindashboard"
                title="Dashboard"
                className="link-icon icofont-home"
              />
            </div> */}
            <div>
              <LinkGroup
                to="/doctorlist"
                title="Doctor List"
                className="link-icon icofont-doctor"
              />
            </div>
            <div>
              <LinkGroup
                to="/patientuserlist"
                title="Patient App User List"
                // className="link-icon icofont-crutch"
                src={PatientIcon}
              />
            </div>
            {/* <div>
                <LinkGroup
                  to="/companylist"
                  title="Clinic List"
                  className="link-icon icofont-hospital"
                />
              </div>
               <div>
                <LinkGroup
                  to="/allpatient"
                  title="Patient"
                  className="link-icon icofont-paralysis-disability"
                />
              </div>
              <div>
                <LinkGroup
                  to="/userlist"
                  title="Users"
                  className="link-icon icofont-doctor-alt"
                />
              </div> */}
            <div>
              <LinkGroup
                to="/allonlineinvoicereport"
                title="Online Payment"
                // className="link-icon icofont-pay"
                src={OnlinePaymentIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/allonlineinvoicereturnreport"
                title="Online Return Payment"
                // className="link-icon icofont-pay"
                src={OnlinePaymentIcon}
              />
            </div>
            <div className="group-title mt-3">Demo</div>
            <div>
              <LinkGroup
                to="/demolist"
                title="Demo Request"
                // className="link-icon icofont-doctor"
                src={UserIcon}
              />
            </div>
            <div className="group-title mt-3">Report</div>
            <div>
              <LinkGroup
                to="/reportdoctor"
                title="Doctor"
                className="link-icon icofont-doctor"
              />
            </div>
            <div>
              <LinkGroup
                to="/reportappointment"
                title="Appointment"
                // className="link-icon icofont-stethoscope-alt"
                src={AppointmentIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/reportutmparam"
                title="UTM Parameter"
                className="link-icon icofont-google-plus"
                // src={AppointmentIcon}
              />
            </div>
            <div className="group-title mt-3">Templates</div>
            <div>
              <LinkGroup
                to="/treatmenttemplatelist"
                title="Treatment Template"
                // className="link-icon icofont-capsule"
                src={TemplateIcon}
              />
            </div>
            {smartAssistant ? (
              <>
                <div className="group-title mt-3">Smart Assistance</div>
                <div>
                  <LinkGroup
                    to="/smartassistant"
                    title="Smart Assistant"
                    className="link-icon icofont-robot"
                  />
                </div>
              </>
            ) : null}
            <div className="group-title mt-3">Products</div>
            <div>
              <LinkGroup
                to="/medicinelist"
                title="Medicine"
                // className="link-icon icofont-capsule"
                src={MedicineIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/labtestlist"
                title="Lab Test"
                // className="link-icon icofont-laboratory"
                src={LabIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/symptomslist"
                title="Symptom"
                // className="link-icon icofont-heart-beat"
                src={SymptomIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/diagnosislist"
                title="Disease / Condition"
                // className="link-icon icofont-stethoscope-alt"
                src={DiagnosisIcon}
              />
            </div>
            <div className="group-title mt-3">Settings</div>
            <div>
              <LinkGroup
                to="/adminconfiguration"
                title="Configuration"
                // className="link-icon icofont-robot"
                src={ConfigIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/logout"
                title="Logout"
                // className="link-icon icofont-settings-alt"
                src={LogoutIcon}
              />
            </div>
          </nav>
        </div>
      ) : role == "Ad Manager" ? (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <div className="group-title mt-3">Report</div>
            <div>
              <LinkGroup
                to="/reportutmparam"
                title="UTM Information"
                className="link-icon icofont-google-plus"
              />
            </div>

            <div className="group-title mt-3">Settings</div>

            <div>
              <LinkGroup
                to="/logout"
                title="Logout"
                className="link-icon icofont-settings-alt"
              />
            </div>
          </nav>
        </div>
      ) : role == "Admin" ? (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <div className="group-title mt-3">Medicine</div>
            <div>
              <LinkGroup
                to="/doctordashboard"
                title="Dashboard"
                // className="link-icon icofont-thermometer-alt"
                src={MedicineIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/upcomingappointment"
                title="Appointment"
                // className="link-icon icofont-stethoscope-alt"
                src={AppointmentIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/patientlist"
                title="Patient"
                // className="link-icon icofont-paralysis-disability"
                src={PatientIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/patientrecord"
                title="Patient Health Record"
                // className="link-icon icofont-prescription"
                src={PHRIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/consultationhistory"
                title="Consultation History"
                // className="link-icon icofont-medical-sign"
                src={ConsultationIcon}
              />
            </div>
            {/* <div>
              <LinkGroup
                to="/invoiceList"
                title="Payment"
                // className="link-icon icofont-pay"
                src={PaymentIcon}
              />
            </div> */}
            <div>
              <LinkGroup
                to="/invoicereport"
                title="Payment Report"
                // className="link-icon icofont-pay"
                src={PaymentReportIcon}
              />
            </div>
            {gMeetConfig ? (
              <div>
                <LinkGroup
                  to="/onlineinvoicereport"
                  title="Online Payment"
                  // className="link-icon icofont-pay"
                  src={OnlinePaymentIcon}
                />
              </div>
            ) : null}
            <div className="group-title mt-3">Admin</div>
            <div>
              <LinkGroup
                to="/userlist"
                title="Employee"
                // className="link-icon icofont-doctor-alt"
                src={UserIcon}
              />
            </div>
            <div className="group-title mt-3">Settings</div>
            <div>
              <LinkGroup
                to="/logout"
                title="Logout"
                // className="link-icon icofont-logout"
                src={LogoutIcon}
              />
            </div>
          </nav>
        </div>
      ) : role === "Billing" ? (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <div className="group-title mt-3">Medicine</div>
            <div>
              <LinkGroup
                to="/doctordashboard"
                title="Dashboard"
                // className="link-icon icofont-thermometer-alt"
                src={DashboardIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/patientlist"
                title="Patient"
                // className="link-icon icofont-paralysis-disability"
                src={PatientIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/consultationhistory"
                title="Consultation History"
                // className="link-icon icofont-medical-sign"
                src={ConsultationIcon}
              />
            </div>
            {/* <div>
              <LinkGroup
                to="/invoiceList"
                title="Payment"
                // className="link-icon icofont-pay"
                src={PaymentIcon}
              />
            </div> */}
            <div>
              <LinkGroup
                to="/invoicereport"
                title="Payment Reports"
                // className="link-icon icofont-pay"
                src={PaymentReportIcon}
              />
            </div>
            {gMeetConfig ? (
              <div>
                <LinkGroup
                  to="/onlineinvoicereport"
                  title="Online Payment"
                  // className="link-icon icofont-pay"
                  src={OnlinePaymentIcon}
                />
              </div>
            ) : null}
            <div>
              <div className="group-title mt-3">Settings</div>
              <div>
                <LinkGroup
                  to="/logout"
                  title="Logout"
                  // className="link-icon icofont-logout"
                  src={LogoutIcon}
                />
              </div>
            </div>
          </nav>
        </div>
      ) : role == "Receptionist" ? (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            <div className="group-title mt-3">Medicine</div>
            <div>
              <LinkGroup
                to="/doctordashboard"
                title="Dashboard"
                // className="link-icon icofont-thermometer-alt"
                src={DashboardIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/upcomingappointment"
                title="Appointment"
                // className="link-icon icofont-stethoscope-alt"
                src={AppointmentIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/patientlist"
                title="Patient"
                // className="link-icon icofont-paralysis-disability"
                src={PatientIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/patientrecord"
                title="Patient Health Record"
                // className="link-icon icofont-prescription"
                src={PHRIcon}
              />
            </div>
            <div>
              <LinkGroup
                to="/consultationhistory"
                title="Consultation History"
                // className="link-icon icofont-medical-sign"
                src={ConsultationIcon}
              />
            </div>
            <div>
              <div className="group-title mt-3">Settings</div>
              <div>
                <LinkGroup
                  to="/logout"
                  title="Logout"
                  // className="link-icon icofont-logout"
                  src={LogoutIcon}
                />
              </div>
            </div>
          </nav>
        </div>
      ) : (
        <div className="main-menu">
          <nav className="main-menu-wrap">
            {status == "Active" ? (
              <>
                <div className="group-title mt-3">Medicine</div>
                <div>
                  <LinkGroup
                    to="/doctordashboard"
                    title="Dashboard"
                    src={DashboardIcon}
                    // className="link-icon icofont-thermometer-alt"
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/upcomingappointment"
                    title="Appointment"
                    // className="link-icon icofont-stethoscope-alt"
                    src={AppointmentIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/patientlist"
                    title="Patient"
                    // className="link-icon icofont-paralysis-disability"
                    src={PatientIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/patientrecord"
                    title="Patient Health Record"
                    // className="link-icon icofont-prescription"
                    src={PHRIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/consultationhistory"
                    title="Consultation History"
                    // className="link-icon icofont-medical-sign"
                    src={ConsultationIcon}
                  />
                </div>
                {/* <div>
                  <LinkGroup
                    to="/invoiceList"
                    title="Payment"
                    // className="link-icon icofont-pay"
                    src={PaymentIcon}
                  />
                </div> */}
                <div>
                  <LinkGroup
                    to="/invoicereport"
                    title="Payment Report"
                    // className="link-icon icofont-pay"
                    src={PaymentReportIcon}
                  />
                </div>
                {gMeetConfig ? (
                  <div>
                    <LinkGroup
                      to="/onlineinvoicereport"
                      title="Online Payment"
                      // className="link-icon icofont-pay"
                      src={OnlinePaymentIcon}
                    />
                  </div>
                ) : null}
                <div className="group-title mt-3">Templates</div>
                <div>
                  <LinkGroup
                    to="/treatmenttemplatelist"
                    title="Treatment Template"
                    // className="link-icon icofont-capsule"
                    src={TemplateIcon}
                  />
                </div>
                <div className="group-title mt-3">Products</div>
                <div>
                  <LinkGroup
                    to="/medicinelist"
                    title="Medicine"
                    // className="link-icon icofont-capsule"
                    src={MedicineIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/labtestlist"
                    title="Lab Test"
                    // className="link-icon icofont-laboratory"
                    src={LabIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/symptomslist"
                    title="Symptom"
                    // className="link-icon icofont-heart-beat"
                    src={SymptomIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/diagnosislist"
                    title="Disease / Condition"
                    // className="link-icon icofont-stethoscope-alt"
                    src={DiagnosisIcon}
                  />
                </div>
                {smartAssistant ? (
                  <>
                    <div className="group-title mt-3">Smart Assistance</div>
                    <div>
                      <LinkGroup
                        to="/smartassistant"
                        title="Smart Assistant"
                        className="link-icon icofont-robot"
                      />
                    </div>
                  </>
                ) : null}
                <div className="group-title mt-3">Admin</div>
                <div>
                  <LinkGroup
                    to="/userlist"
                    title="Employee"
                    // className="link-icon icofont-doctor-alt"
                    src={UserIcon}
                  />
                </div>
              </>
            ) : null}
            <div className="group-title mt-3">Settings</div>
            <div>
              <LinkGroup
                to="/basicinformation"
                title="Basic Information"
                // className="link-icon icofont-live-messenger"
                src={BasicIcon}
              />
            </div>
            <div>
              {profile >= 45 ? (
                <LinkGroup
                  to="/appointment"
                  title="Clinic Information"
                  // className="link-icon icofont-hospital"
                  src={ClinicIcon}
                />
              ) : (
                <LinkGroup title="Clinic Information" src={ClinicIcon} />
              )}
            </div>
            <div>
              {profile >= 78 ? (
                <LinkGroup
                  to="/education"
                  title="Certificate Information"
                  // className="link-icon icofont-certificate-alt-1"
                  src={CertificateIcon}
                />
              ) : (
                <LinkGroup
                  title="Certificate Information"
                  src={CertificateIcon}
                />
              )}
            </div>
            {status == "Active" && (
              <>
                <div>
                  <LinkGroup
                    to="/bankinformation"
                    title="Bank Information"
                    className="link-icon icofont-bank-alt"
                    src={BankIcon}
                  />
                </div>
                <div>
                  <LinkGroup
                    to="/configuration"
                    title="Configuration"
                    // className="link-icon icofont-settings-alt"
                    src={ConfigIcon}
                  />
                </div>
              </>
            )}
            <div>
              <LinkGroup
                to="/logout"
                title="Logout"
                // className="link-icon icofont-logout"
                src={LogoutIcon}
              />
            </div>
          </nav>
        </div>
      )}
    </div>
  );
}

export default DoctorNavBar;
