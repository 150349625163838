import client from "./client";
import superadminclient from "./superadminclient";
import openAIClient from "./openAIClient";
import string from "../string";

const newPatient = async (
  companyid,
  first_name,
  last_name,
  dob,
  sex,
  email,
  mobile,
  blood_group,
  patient_relation
) => {
  const data = await client.post("/api/resource/Patient", {
    companyid,
    first_name,
    last_name,
    dob,
    sex,
    email,
    mobile,
    blood_group,
    patient_relation,
    invite_user: 0,
  });
  return data;
};

const newCustomer = async (customer_name) => {
  const data = await client.post("/api/resource/Customer", {
    customer_name,
    customer_type: "Individual",
    customer_group: "All Customer Groups",
    territory: "All Territories",
  });
  return data;
};

const getPatientByEmail = async (email) => {
  const data = await client.get(
    `/api/resource/Patient?fields=["*"]&filters=[["email","=","${email}"]]&limit_page_length=None`
  );
  return data;
};

const getPatientByDoctor = async (doctorId) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["name","patient_name","first_name","email","dob","mobile","owner"]&filters=[["owner", "=", "${doctorId}"]]&limit_page_length=None`
  );
  return data;
};

const patient = async (companyid) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["name","patient_name","first_name","email","dob","mobile"]&filters=[["companyid", "=", "${companyid}"]]&limit_page_length=None`
  );
  return data;
};

const pausedConsultationWithDatesByCompany = async (
  company,
  fromDate,
  toDate
) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["company","=","${company}"],["consultation_status", "=", "0"], ["docstatus", "=", "0"],["encounter_date",">=","${fromDate}"],["encounter_date","<=","${toDate}"]]&order_by=encounter_date asc&limit_page_length=None`
  );
  return data;
};

const pausedConsultationWithDates = async (doctorID, fromDate, toDate) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["practitioner","=","${doctorID}"],["consultation_status", "=", "0"], ["docstatus", "=", "0"],["encounter_date",">=","${fromDate}"],["encounter_date","<=","${toDate}"]]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const pausedConsultationByCompany = async (company, date) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["company","=","${company}"],["consultation_status", "=", "0"], ["docstatus", "=", "0"],["encounter_date","=","${date}"]]&order_by=encounter_date asc&limit_page_length=None`
  );
  return data;
};

const pausedConsultation = async (doctorID, date) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["practitioner","=","${doctorID}"],["consultation_status", "=", "0"], ["docstatus", "=", "0"],["encounter_date","=","${date}"]]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const consultationHistory = async (doctorID) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["name","patient","patient_name","encounter_date","encounter_time", "docstatus"]&filters=[["docstatus", "=", "1"],["practitioner","=","${doctorID}"]]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const patientDataHomePageByCompany = async (company, date) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Encounter?fields=["name","encounter_date", "practitioner"]&filters=[["company","=","${company}"], ["encounter_date",">","${date}"]]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const patientDataHomePage = async (doctorID, date) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["name","encounter_date"]&filters=[["practitioner","=","${doctorID}"], ["encounter_date",">","${date}"]]&order_by=creation desc&limit_page_length=None`
  );
  return data;
};

const consultationPrescription = async (encounter_id) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.patient_encounter_details`,
    {
      encounter_id,
    }
  );
  return data;
};

const consultationSummary = async (consultationName) => {
  const data = await client.get(
    `/api/resource/Patient Encounter/${consultationName}`
  );
  return data;
};

const filterDate = async (doctorID, date) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["name","patient","patient_name",
    "encounter_date","encounter_time"]&filters=[["docstatus", "=", "1"],["practitioner","=","${doctorID}"],["encounter_date","=","${date}"]]`
  );
  return data;
};

const appointmentWithDateRange = async (doctorID, fromDate, toDate) => {
  const data = await client.get(
    `/api/resource/Patient Appointment?fields=["name", "appointment_date"]&filters=[["practitioner","=","${doctorID}"],["status","!=","Closed"],["status","!=", "Cancelled"],["appointment_date",">=","${fromDate}"],["appointment_date","<=","${toDate}"]]&order_by=appointment_date, appointment_time&limit_page_length=None`
  );
  return data;
};

const appointmentByDatesByCompany = async (company, fromDate, toDate) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Appointment?fields=["*"]&filters=[["company","=","${company}"] ,["appointment_date",">=","${fromDate}"], ["appointment_date","<=","${toDate}"]]&order_by=appointment_date, appointment_time&limit_page_length=None`
  );
  return data;
};

const appointmentByDates = async (doctorID, fromDate, toDate) => {
  const data = await client.get(
    `/api/resource/Patient Appointment?fields=["*"]&filters=[["practitioner","=","${doctorID}"] ,["appointment_date",">=","${fromDate}"], ["appointment_date","<=","${toDate}"]]&order_by=appointment_date, appointment_time&limit_page_length=None`
  );
  return data;
};

const getAppointmentByClinic = async (company, date) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Appointment?fields=["*"]&filters=[["company","=","${company}"],["appointment_date","=","${date}"]]&order_by=appointment_date, appointment_time&limit_page_length=None`
  );
  return data;
};

const filterDateConsultation = async (doctorID, date) => {
  const data = await client.get(
    `/api/resource/Patient Appointment?fields=["*"]&filters=[["practitioner","=","${doctorID}"],["appointment_date","=","${date}"]]&order_by=appointment_date, appointment_time&limit_page_length=None`
  );
  return data;
};

const selectSymptoms = async () => {
  const data = await client.get(`/api/resource/Complaint`);
  return data;
};

const selectDiagnosis = async () => {
  const data = await client.get(`/api/resource/Diagnosis`);
  return data;
};

const getInvoiceItems = async () => {
  const data = await client.get(
    `/api/resource/Item?filters=[["item_group","=", "All Item Groups"]]
    &fields=["name","item_name","generic_name"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const getConsumption = async () => {
  const data = await client.get(`/api/resource/Prescription Dosage`);
  return data;
};

const getSlots = async (schedule) => {
  const data = await client.get(
    `/api/resource/Practitioner Schedule/${schedule}`
  );
  return data;
};

const availableSlotWithLeaveDetails = async (practitioner_id, date) => {
  const data = await client.get(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_practitioner_available_slots`,
    {
      practitioner_id,
      date,
    }
  );
  return data;
};

const availableSlot = async (practitioner, date) => {
  console.log("date---", date);
  const data = await client.get(
    `api/method/erpnext.healthcare.doctype.patient_appointment.patient_appointment.get_availability_data`,
    {
      practitioner,
      date,
    }
  );
  return data;
};

const getScheduledSlots = async (doctorID, date) => {
  const data =
    await client.get(`/api/resource/Patient Appointment?fields=["appointment_date",
    "appointment_time"]&filters=[["practitioner","=","${doctorID}"],["status","!=",
    "Closed"],["status","!=", "Cancelled"],["appointment_date","=","${date}"]]`);
  return data;
};

const getDosageForm = async () => {
  const data = await client.get(`/api/resource/Dosage Form`);
  return data;
};

const searchLab = async (keyword, practitioner) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.search_lab`,
    {
      keyword,
      practitioner,
    }
  );
  return data;
};

const getDiagnosisByArray = async (filter) => {
  const data = await client.get(
    `/api/resource/Diagnosis?or_filters=${filter}&fields=["diagnosis","name"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const getSymptomByArray = async (filter) => {
  const data = await client.get(
    `/api/resource/Complaint?or_filters=${filter}&fields=["complaints","name"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const getLabTestByArray = async (filter) => {
  const data = await client.get(
    `/api/resource/Lab Test Template?or_filters=${filter}&fields=["*"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const getAppointmentID = async (patient, date) => {
  const data = await client.get(
    `/api/resource/Patient Appointment?fields=["name"]&filters=[
      ["name","=","${patient}"],["status","!=","Closed"],["status","!=", "Cancelled"],["appointment_date","=","${date}"]]`
  );
  return data;
};

const getDuration = async () => {
  const data = await client.get(
    `/api/resource/Prescription Duration?limit_page_length=None`
  );
  return data;
};

const deleteTreatmentTemplate = async (treatmentName) => {
  const data = await client.delete(
    `/api/resource/Treatment Plan Template/${treatmentName}`
  );
  return data;
};

const getTreatmentTemplate = async (practitioner, age) => {
  const data = await client.get(
    `/api/resource/Treatment Plan Template?or_filters=[["owner","=","${practitioner}"], ["owner","=","${string.adminEmail}"]]&filters=[["patient_age_from","<=","${age}"], ["patient_age_to",">=","${age}"]]&fields=["name"]&order_by=creation desc`
  );
  return data;
};

const getTreatmentTemplateByName = async (value) => {
  const data = await client.get(
    `/api/resource/Treatment Plan Template/${value}`
  );
  return data;
};

const editTreatmentTemplate = async (
  name,
  template_name,
  description,
  complaints,
  diagnosis,
  items,
  drugs,
  patient_age_from,
  patient_age_to
) => {
  const data = client.put(`/api/resource/Treatment Plan Template/${name}`, {
    template_name,
    description,
    patient_age_from,
    patient_age_to,
    complaints,
    diagnosis,
    items,
    drugs,
  });
  return data;
};

const addTreatmentTemplate = async (
  template_name,
  description,
  practitioners,
  complaints,
  diagnosis,
  items,
  drugs,
  patient_age_from,
  patient_age_to
) => {
  const data = client.post(`/api/resource/Treatment Plan Template`, {
    template_name,
    description,
    patient_age_from,
    patient_age_to,
    disabled: 0,
    medical_department: "",
    doctype: "Treatment Plan Template",
    practitioners,
    complaints,
    diagnosis,
    items,
    drugs,
  });
  return data;
};

const getAllTreatmentTemplate = async (
  searchString,
  or_filters,
  limitStart,
  pageSize
) => {
  const data = await client.get(
    `/api/resource/Treatment Plan Template?or_filters=${or_filters}&filters=${searchString}&fields=["*"]&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const searchTreatmentTemplate = async (keyword, practitioner) => {
  const data = await client.get(
    `/api/resource/Treatment Plan Template?fields=["*"]&filters=[["template_name","like","${keyword}%"]]&or_filters=[["owner", "=", "${practitioner}"], ["owner", "=", "${string.adminEmail}"]]&fileds=["*"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const newAppointmentWithSMS = async (
  patient_id,
  practitioner_id,
  appointment_date,
  appointment_time,
  consultation_type,
  company_id,
  online_link,
  mobile_number
) => {
  console.log(
    "---------",
    patient_id,
    practitioner_id,
    appointment_date,
    appointment_time,
    consultation_type,
    company_id,
    online_link,
    mobile_number
  );
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.book_appointment`,
    {
      patient_id,
      practitioner_id,
      appointment_date,
      appointment_time,
      consultation_type,
      company_id,
      online_link,
      mobile_number,
    }
  );
  return data;
};

const newAppointment = async (
  patient,
  practitioner,
  appointment_date,
  appointment_time,
  consultation_type,
  company,
  service_unit,
  online_link,
  paid_status
) => {
  const data = client.post(`/api/resource/Patient Appointment`, {
    patient,
    practitioner,
    appointment_date,
    appointment_time,
    consultation_type,
    company,
    service_unit,
    online_link,
    paid_status,
  });
  return data;
};

const getPatientAppointment = async (appointment) => {
  const data = superadminclient.get(
    `/api/resource/Patient Appointment?fields=["name","patient","patient_name","patient_sex","dob","practitioner","practitioner_name"]&filters=[["name",
    "=","${appointment}"]]`
  );
  return data;
};

const patientConsultation = async (
  company,
  appointment,
  patient,
  patient_name,
  patient_sex,
  patient_age,
  practitioner,
  practitioner_name,
  symptoms,
  _duration_in_symptoms,
  diagnosis,
  drug_prescription,
  lab_test_prescription,
  past_history,
  examination,
  personal_habits,
  notes_for_patient,
  notes_for_doctor,
  scan,
  department,
  revisit_date,
  consultation_status
) => {
  const data = client.post(`/api/resource/Patient Encounter`, {
    company,
    appointment,
    patient,
    patient_name,
    patient_sex,
    patient_age,
    practitioner,
    practitioner_name,
    medical_department: department,
    invoiced: 0,
    symptoms_in_print: 1,
    diagnosis_in_print: 1,
    doctype: "Patient Encounter",
    symptoms,
    _duration_in_symptoms,
    diagnosis,
    drug_prescription,
    lab_test_prescription,
    past_history,
    examination,
    personal_habits,
    notes_for_patient,
    notes_for_doctor,
    scan,
    revisit_date,
    consultation_status,
  });
  return data;
};

const editVitalSigns = async (
  vitalId,
  temperature,
  nutrition_note,
  height,
  weight,
  bmi,
  bp_systolic,
  bp_diastolic,
  bp
) => {
  const data = client.put(`/api/resource/Vital Signs/${vitalId}`, {
    temperature,
    nutrition_note,
    height,
    weight,
    bmi,
    bp_systolic,
    bp_diastolic,
    bp,
  });
  return data;
};

const addVitalSigns = async (
  appointment,
  patient,
  patient_name,
  company,
  temperature,
  nutrition_note,
  height,
  weight,
  bmi,
  bp_systolic,
  bp_diastolic,
  bp
) => {
  const data = client.post(`/api/resource/Vital Signs`, {
    appointment,
    patient,
    patient_name,
    company,
    temperature,
    nutrition_note,
    height,
    weight,
    bmi,
    bp_systolic,
    bp_diastolic,
    bp,
  });
  return data;
};

const submitVitalSigns = async (vitalId) => {
  const data = client.put(`/api/resource/Vital Signs/${vitalId}`, {
    docstatus: 1,
  });
  return data;
};

const getVitalSigns = async (appointmentId) => {
  const data = client.get(
    `/api/resource/Vital Signs?filters=[["appointment","=","${appointmentId}"]]&fields=["*"]`
  );
  return data;
};

const preConsultation = async (
  appointment,
  patient,
  patient_name,
  dob,
  patient_sex,
  patient_age,
  company,
  encounter_date,
  practitioner,
  practitioner_name,
  medical_department
) => {
  const data = client.post(`/api/resource/Patient Encounter`, {
    appointment,
    patient,
    patient_name,
    dob,
    patient_sex,
    patient_age,
    company,
    encounter_date,
    practitioner,
    practitioner_name,
    medical_department,
  });
  return data;
};

const editPatientConsultation = async (
  consultationName,
  symptoms,
  _duration_in_symptoms,
  diagnosis,
  drug_prescription,
  lab_test_prescription,
  past_history,
  examination,
  personal_habits,
  notes_for_patient,
  notes_for_doctor,
  scan,
  revisit_date,
  consultation_status
) => {
  const data = client.put(
    `/api/resource/Patient Encounter/${consultationName}`,
    {
      symptoms,
      _duration_in_symptoms,
      diagnosis,
      drug_prescription,
      lab_test_prescription,
      past_history,
      examination,
      personal_habits,
      notes_for_patient,
      notes_for_doctor,
      scan,
      revisit_date,
      consultation_status,
    }
  );
  return data;
};

const savePatientConsultation = async (consultationName) => {
  const data = superadminclient.put(
    `/api/resource/Patient Encounter/${consultationName}`,
    {
      invoiced: 1,
      docstatus: 1,
    }
  );
  return data;
};

const getOpenAppointment = async (doctorID) => {
  const data = client.get(
    `/api/resource/Patient Appointment?fields=["name"]&filters=
    [["practitioner","=","${doctorID}"],["status","!=","Closed"],["status","!=","Cancelled"]]&limit_page_length=None`
  );
  return data;
};

const getAppointment = async (doctorID) => {
  const data = client.get(
    `/api/resource/Patient Appointment?fields=["name","status"]
    &filters=[["practitioner","=","${doctorID}"]]&limit_page_length=None`
  );
  return data;
};

const getPatientDetail = async (mob_name, companyid) => {
  const data = superadminclient.get(
    `/api/resource/Patient?filters=[["companyid", "=", "${companyid}"]]&or_filters=[["mobile","like", "${mob_name}%"],["first_name","like","${mob_name}%"]]&fields=["name","first_name","last_name","patient_name","email","mobile","dob","sex"]`
  );
  return data;
};

const getPatientDetails = async (filter) => {
  const data = superadminclient.get(
    `/api/resource/Patient?fields=["name","first_name","last_name","patient_name","email","mobile","dob","sex"]${filter}`
  );
  return data;
};

const getPatientHealthRecord = async (patient_ids) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_patient_health_record`,
    {
      patient_ids,
    }
  );
  return data;
};

const getPatientRecord = async (patient) => {
  const data = client.get(
    `/api/resource/Patient Medical Record?filters=[["patient","=","${patient}"],["reference_doctype", "=", "Patient Encounter"]]&fields=["*"]`
  );
  return data;
};

const getRecord = async (consultationID) => {
  const data = client.get(
    `/api/resource/Patient Medical Record?filters=[["reference_name","=",
    "${consultationID}"]]&fields=["name"]`
  );
  return data;
};

const attachRecord = async (docname, filename, filedata) => {
  const data = client.post(`/api/method/upload_file`, {
    cmd: "uploadfile",
    doctype: "Patient Medical Record",
    docname,
    filename,
    from_form: 1,
    filedata,
  });
  return data;
};

const getReport = async (name) => {
  const data = client.get(
    `/api/resource/File?filters=[["attached_to_name","=","${name}"]]
    &fields=["name","file_name","file_url"]&order_by=creation desc`
  );
  return data;
};

const homepageDataMonth = async (email, company_id) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.homepage_data_formonth`,
    {
      email,
      company_id,
    }
  );
  return data;
};

const homepageDataWeek = async (email, company_id) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.homepage_data_forweek`,
    {
      email,
      company_id,
    }
  );
  return data;
};

const homepageDataToday = async (email, company_id) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.homepage_data_today`,
    {
      email,
      company_id,
    }
  );
  return data;
};

const getAllPatient = async (searchString, limitStart, pageSize) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["name", "first_name", "last_name", "patient_name","email","mobile","dob","sex", "blood_group", "creation", "owner", "user_id"]&filters=${searchString}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getAllPatientByDate = async (companyid, from_date, to_date) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["*"]&filters=[["companyid", "=", "${companyid}"], ["creation",">=", "${from_date} 00:00:00.000000"], ["creation", "<=", "${to_date} 23:59:59.000000"]]&order_by=creation desc`
  );
  return data;
};

const getNewPatient = async (date) => {
  const data = await client.get(
    `/api/resource/Patient?filters=[["creation","like", "${date} %"]]&fields=["name", "first_name", "last_name", "patient_name","email","mobile","dob","sex", "blood_group", "creation"]&order_by=creation desc&limit_page_length=none`
  );
  return data;
};

const getPatientByCompany = async (companyid) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["name", "first_name", "last_name", "patient_name","email","mobile","dob","sex", "blood_group", "creation"]&filters=[["companyid", "=", "${companyid}"]]&limit_page_length=none`
  );
  return data;
};

const getPatient = async () => {
  const data = await client.get(
    `/api/resource/Patient?fields=["name", "first_name", "last_name", "patient_name","email","mobile","dob","sex", "blood_group", "creation"]&limit_page_length=none`
  );
  return data;
};

const getSearchPatient = async (search) => {
  const data = await client.get(
    `/api/resource/Patient?or_filters=[["patient_name","like","%${search}%"], ["mobile","like","${search}%"]]&
	fields=["name", "first_name", "last_name", "patient_name","email","mobile","dob","sex", "blood_group"]
	&limit_page_length=None&order_by=patient_name asc`
  );
  return data;
};

const checkOldPatient = async (patientName, mobile) => {
  const data = await client.get(
    `/api/resource/Patient?fields=["name"]&filters=[["patient_name","=","${patientName}"], ["mobile","=","${mobile}"]]&limit_page_length=None`
  );
  return data;
};

const updatePatientRelation = async (patientId, patient_relation) => {
  const data = await client.put(`/api/resource/Patient/${patientId}`, {
    patient_relation,
  });
  return data;
};

const updatePatient = async (
  patientId,
  first_name,
  last_name,
  dob,
  sex,
  email,
  mobile,
  blood_group
) => {
  const data = await superadminclient.put(
    `/api/resource/Patient/${patientId}`,
    {
      first_name,
      last_name,
      dob,
      sex,
      email,
      mobile,
      blood_group,
    }
  );
  return data;
};

const checkPatientByCompany = async (patientId, companyid) => {
  const data = await superadminclient.get(
    `/api/resource/Patient?fields=["*"]&filters=[["name", "=", "${patientId}"], ["companyid", "=", "${companyid}"]]`
  );
  return data;
};

const getPatientById = async (patientId) => {
  const data = await superadminclient.get(`/api/resource/Patient/${patientId}`);
  return data;
};

const deleteAppointmentById = async (appointmentID) => {
  const data = await client.delete(
    `api/resource/Patient Appointment/${appointmentID}`
  );
  return data;
};

const getAppointmentById = async (appointmentID) => {
  const data = await client.get(
    `api/resource/Patient Appointment/${appointmentID}`
  );
  return data;
};

const createPatientFromPatient = async (patient_id, companyid) => {
  const data = await client.post(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.create_patient_from_patient_app`,
    {
      patient_id,
      companyid,
    }
  );
  return data;
};

const confirmAppointment = async (appointmentID) => {
  const data = await client.put(
    `api/resource/Patient Appointment/${appointmentID}`,
    {
      requested: 0,
    }
  );
  return data;
};

const rescheduleAppointment = async (
  appointmentID,
  appointment_date,
  appointment_time,
  consultation_type
) => {
  const data = await client.put(
    `api/resource/Patient Appointment/${appointmentID}`,
    {
      appointment_date,
      appointment_time,
      consultation_type,
    }
  );
  return data;
};

const cancelAppointment = async (appointment_name) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.appointment_status_cancel`,
    {
      appointment_name,
    }
  );
  return data;
};

const getConsultationName = async (doctorID, appointmentID) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["name","patient"]&filters=[["practitioner","=","${doctorID}"],
    ["appointment","=","${appointmentID}"]]`
  );
  return data;
};

const appointmentList = async (doctorID, date) => {
  const data = await client.get(
    `/api/resource/Patient Appointment?fields=["name","patient","patient_name","status","appointment_date",
    "appointment_time"]&filters=[["practitioner","=","${doctorID}"]${date}]&order_by=appointment_date desc&limit_page_length=None`
  );
  return data;
};

const mobileOtpPatient = async (mobile_number) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.patient_otp",
    {
      mobile_number,
    }
  );
  return data;
};

const searchMedicine = async (practitioner, search_term) => {
  const data = await client.get(
    `/api/resource/Item?filters=[["item_group","=", "Drug"],["item_name","like","${search_term}%"]]&or_filters=[["owner", "=", "${string.adminEmail}"], ["owner", "=", "${practitioner}"]]&fields=["name","item_name","generic_name"]&limit_page_length=None&order_by=name`
  );
  return data;
};

const deleteMedicine = async (name) => {
  const data = await client.delete(`/api/resource/Item/${name}`);
  return data;
};

const deleteLabTest = async (name) => {
  const data = await client.delete(`/api/resource/Lab Test Template/${name}`);
  return data;
};

const getMedicine = async (search_term, or_filters, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/Item?fields=["name","item_name","generic_name", "owner"]&filters=${search_term}&or_filters=${or_filters}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getLabTest = async (
  practitioner,
  search_term,
  or_filters,
  limitStart,
  pageSize
) => {
  const data = await client.get(
    `/api/resource/Lab Test Template?fields=["name","lab_test_code","lab_test_name","lab_test_group","department","owner"]&filters=${search_term}&or_filters=${or_filters}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getLabTemplateById = async (labid) => {
  const data = await client.get(`/api/resource/Lab Test Template/${labid}`);
  return data;
};

const createUser = async (
  email,
  first_name,
  last_name,
  gender,
  mobile_no,
  birth_date,
  role,
  company_id,
  os_name
) => {
  const data = await client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.create_staff`,
    {
      email,
      first_name,
      last_name,
      gender,
      mobile_no,
      birth_date,
      role,
      company_id,
      os_name,
    }
  );
  return data;
};

// const createUser = async (
//   email,
//   first_name,
//   last_name,
//   gender,
//   mobile_no,
//   birth_date,
//   role_profile_name,
//   user_image
// ) => {
//   const data = await client.post("/api/resource/User", {
//     email,
//     first_name,
//     last_name,
//     gender,
//     mobile_no,
//     birth_date,
//     role_profile_name,
//     user_image,
//     user_type: "System User",
//     api_key: email,
//     interest: "Staff",
//     send_welcome_email: 0,
//   });
//   return data;
// };

const updateUser = async (
  email,
  first_name,
  last_name,
  gender,
  mobile_no,
  birth_date,
  role_profile_name,
  user_image
) => {
  const data = await superadminclient.put(`/api/resource/User/${email}`, {
    email,
    first_name,
    last_name,
    gender,
    mobile_no,
    birth_date,
    role_profile_name,
    user_image,
  });
  return data;
};

const assignUser = async (email) => {
  const data = await client.post(
    `/api/method/frappe.core.doctype.user.user.generate_keys?user=${email}`
  );
  return data;
};

const createInvoice = async (
  customer,
  company,
  patient,
  ref_practitioner,
  items,
  patient_encounter
  // due_date,
  // posting_date
) => {
  const data = await client.post(`/api/resource/Sales Invoice`, {
    customer,
    company,
    patient,
    ref_practitioner,
    currency: "INR",
    conversion_rate: "1",
    plc_conversion_rate: "1",
    docstatus: 1,
    items,
    patient_encounter,
    // due_date: moment(new Date()).format("yyyy-MM-DD"),
    // posting_date: moment(new Date()).format("yyyy-MM-DD"),
  });
  return data;
};

const createPaymentEntry = async (
  company,
  abbr,
  party,
  fees,
  reference_name,
  paid_to
) => {
  const data = await client.post(`/api/resource/Payment Entry`, {
    company,
    mode_of_payment: "Cash",
    party_type: "Customer",
    party,
    paid_to,
    paid_to_account_currency: "INR",
    paid_amount: fees,
    received_amount: fees,
    docstatus: 1,
    references: [
      {
        reference_doctype: "Sales Invoice",
        reference_name,
        exchange_rate: 1,
        total_amount: fees,
        allocated_amount: fees,
      },
    ],
  });
  return data;
};

const getCompanyInfo = async (company) => {
  const data = await client.get(`/api/resource/Company/${company}`);
  return data;
};

const getAllInvoiceByCompany = async (searchString, limitStart, pageSize) => {
  const data = await superadminclient.get(
    `/api/resource/Sales Invoice?fields=["*"]&filters=${searchString}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getAllInvoice = async (searchString, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/Sales Invoice?fields=["*"]&filters=${searchString}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getInvoiceByAppointment = async (appId) => {
  const data = await client.get(
    `/api/resource/Sales Invoice?fields=["*"]&filters=[["patient_appointment","=","${appId}"]]`
  );
  return data;
};

const updateInvoiceSettlement = async (invId, settlement_date) => {
  const data = await client.put(`/api/resource/Sales Invoice/${invId}`, {
    settled: 1,
    settlement_date,
  });
  return data;
};

const updateInvoice = async (appId, patient_encounter) => {
  const data = await client.put(`/api/resource/Sales Invoice/${appId}`, {
    patient_encounter,
  });
  return data;
};

const updateInvoiceAppointmentId = async (
  appId,
  patient_appointment,
  patient
) => {
  const data = await client.put(`/api/resource/Sales Invoice/${appId}`, {
    patient_appointment,
    patient,
  });
  return data;
};

const getOnlineInvoice = async (from_date, to_date, doctor_email) => {
  const data = await client.post(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.dashboard_payment_details`,
    {
      doctor_email,
      from_date,
      to_date,
    }
  );
  return data;
};

const getOnlineRefundInvoice = async (from_date, to_date, doctor_email) => {
  const data = await client.post(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.dashboard_refund_details`,
    {
      doctor_email,
      from_date,
      to_date,
    }
  );
  return data;
};

const getAllInvoiceWithDateByCompany = async (company, from, to) => {
  const data = await superadminclient.get(
    `/api/resource/Sales Invoice?fields=["*"]&filters=[["company", "=", "${company}"], ["posting_date", ">=", "${from}"],["posting_date", "<=", "${to}"], ["status","=","Paid"], ["patient_encounter","!=",""]]&order_by=creation desc&limit_page_length=none`
  );
  return data;
};

const getAllInvoiceWithDate = async (from, to) => {
  const data = await client.get(
    `/api/resource/Sales Invoice?fields=["*"]&filters=[["posting_date", ">=", "${from}"],["posting_date", "<=", "${to}"], ["status","=","Paid"], ["patient_encounter","!=",""]]&order_by=creation desc&limit_page_length=none`
  );
  return data;
};

const getAllConsultationHistoryByCompany = async (company, from, to) => {
  const data = await superadminclient.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["company", "=", "${company}"], ["encounter_date", ">=", "${from}"],["encounter_date", "<=", "${to}"],["consultation_status", "=", "1"]]&order_by=creation desc&limit_page_length=none`
  );
  return data;
};

const getAllConsultationHistory = async (from, to) => {
  const data = await client.get(
    `/api/resource/Patient Encounter?fields=["*"]&filters=[["encounter_date", ">=", "${from}"],["encounter_date", "<=", "${to}"],["consultation_status", "=", "1"]]&order_by=creation desc&limit_page_length=none`
  );
  return data;
};

const getTotalInvoiceByCompany = async (company) => {
  const data = await superadminclient.get(
    `/api/resource/Sales Invoice?fields=["name", "patient_name","grand_total","posting_date","status"]&filters=[["company", "=", "${company}"], ["status","=","Paid"], ["patient_encounter","!=",""]]&limit_page_length=none`
  );
  return data;
};

const getTotalInvoice = async () => {
  const data = await client.get(
    `/api/resource/Sales Invoice?fields=["name", "patient_name","grand_total","posting_date","status"]&filters=[["status","=","Paid"], ["patient_encounter","!=",""]]&limit_page_length=none`
  );
  return data;
};

const getInvoiceByName = async (invoiceId) => {
  const data = await client.get(`/api/resource/Sales Invoice/${invoiceId}`);
  return data;
};

const getInvoiceDetails = async (invoice_id) => {
  const data = await client.post(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.sales_invoice_details`,
    { invoice_id }
  );
  return data;
};

const getAllUserList = async (limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/User?or_filters=[["role_profile_name","=","Billing"],["role_profile_name","=","Receptionist"], ["role_profile_name","=","Admin"]]&fields=["name", "full_name","role_profile_name","birth_date", "mobile_no","email"]&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getUserListByList = async (
  searchString,
  limitStart = 0,
  pageSize = "None"
) => {
  const data = await superadminclient.get(
    `/api/resource/User?filters=${searchString}&fields=["name", "full_name","role_profile_name","birth_date", "mobile_no","email"]&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getUserList = async (doctorID, limitStart = 0, pageSize = "None") => {
  const data = await superadminclient.get(
    `/api/resource/User?filters=[["owner","=", "${doctorID}"], ["role_profile_name","!=","Practitioner Permission"]]&fields=["name", "full_name","role_profile_name","birth_date", "mobile_no","email"]&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getStaff = async (email) => {
  const data = await superadminclient.get(`/api/resource/User/${email}`);
  return data;
};

const updateFile = async (email, user_image) => {
  const data = await superadminclient.put(`/api/resource/User/${email}`, {
    user_image,
  });
  return data;
};

const checkOldPasswordApi = async (email, secretpass) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.healthcare_practitioner_signin",
    {
      email,
      secretpass,
    }
  );
  return data;
};

const changePasswordApi = async (email, new_password) => {
  const data = await client.put(`/api/resource/User/${email}`, {
    new_password,
  });
  return data;
};

const getRecordByConsultation = async (ConsultationId) => {
  const data = client.get(
    `/api/resource/Patient Medical Record?filters=[["reference_name","=","${ConsultationId}"]]
    &fields=["*"]`
  );
  return data;
};

const getAddressByName = async (name) => {
  const data = await client.get(`/api/resource/Address/${name}`);
  return data;
};

const getCompanyAddress = async (Company) => {
  const data = await client.get(
    `/api/resource/Address?filters=[["address_title","=","${Company}"]]&fields=["*"]`
  );
  return data;
};

const addPatientAddress = async (
  patientId,
  email_id,
  phone,
  address_line1,
  address_line2,
  city,
  state,
  pincode
) => {
  const data = await client.post(`/api/resource/Address`, {
    address_title: patientId,
    address_type: "Office",
    address_line1,
    address_line2,
    email_id,
    phone,
    city,
    state,
    country: "India",
    pincode,
    is_your_company_address: 0,
    links: [
      {
        link_doctype: "Patient",
        link_name: patientId,
      },
    ],
  });
  return data;
};

const getPatientAddress = async (patientId) => {
  const data = await client.get(
    `/api/resource/Address?filters=[["address_title","=","${patientId}"]]&fields=["*"]`
  );
  return data;
};

const updatePatientAddress = async (
  patientId,
  email_id,
  phone,
  address_line1,
  address_line2,
  city,
  state,
  pincode
) => {
  const data = await client.put(`/api/resource/Address/${patientId}-Office`, {
    email_id,
    phone,
    address_line1,
    address_line2,
    city,
    state,
    pincode,
  });
  return data;
};

const sendMessageToMobile = async (mobile_number) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_activation_message",
    {
      mobile_number,
    }
  );
  return data;
};

const deleteSymptom = async (complaints) => {
  const data = await client.delete(`/api/resource/Complaint/${complaints}`);
  return data;
};

const deletediagnosis = async (diagnosis) => {
  const data = await client.delete(`/api/resource/Diagnosis/${diagnosis}`);
  return data;
};

const getSymptoms = async (or_filters, searchString, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/Complaint?fields=["*"]&filters=${searchString}&or_filters=${or_filters}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const getDiagnosis = async (searchString, or_filters, limitStart, pageSize) => {
  const data = await client.get(
    `/api/resource/Diagnosis?fields=["*"]&filters=${searchString}&or_filters=${or_filters}&limit_start=${limitStart}&order_by=creation desc&limit_page_length=${pageSize}`
  );
  return data;
};

const editTemplateOwner = async (templateId) => {
  const data = await client.put(
    `/api/resource/Treatment Plan Template/${templateId}`,
    {
      owner: string.adminEmail,
    }
  );
  return data;
};

const editDiagnosisOwner = async (diagnosisId) => {
  const data = await client.put(`/api/resource/Diagnosis/${diagnosisId}`, {
    owner: string.adminEmail,
  });
  return data;
};

const editSymptomOwner = async (complaintId) => {
  const data = await client.put(`/api/resource/Complaint/${complaintId}`, {
    owner: string.adminEmail,
  });
  return data;
};

const editLabOwner = async (labId) => {
  const data = await client.put(`/api/resource/Lab Test Template/${labId}`, {
    owner: string.adminEmail,
  });
  return data;
};

const editMedicineOwner = async (medicineId) => {
  const data = await client.put(`/api/resource/Item/${medicineId}`, {
    owner: string.adminEmail,
  });
  return data;
};

const editSymptom = async (complaintId, complaints) => {
  const data = await client.put(`/api/resource/Complaint/${complaintId}`, {
    complaints,
  });
  return data;
};

const editDiagnosis = async (diagnosisId, diagnosis) => {
  const data = await client.put(`/api/resource/Diagnosis/${diagnosisId}`, {
    diagnosis,
  });
  return data;
};

const createSymptoms = async (complaints) => {
  const data = await client.post(`/api/resource/Complaint`, {
    complaints,
  });
  return data;
};

const selectDepartment = async () => {
  const data = await client.get(
    `/api/resource/Medical Department?order_by=name asc`
  );
  return data;
};

const createDiagnosis = async (diagnosis) => {
  const data = await client.post(`/api/resource/Diagnosis`, {
    diagnosis,
  });
  return data;
};

const searchSymptoms = async (keyword, practitioner) => {
  console.log(keyword, practitioner);
  const data = await client.get(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.search_complaint`,
    {
      keyword,
      practitioner,
    }
  );
  return data;
};

const searchDiagnosis = async (keyword, practitioner) => {
  console.log("keyword, practitioner---", keyword, practitioner);
  const data = await client.get(
    `api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.search_diagnosis`,
    {
      keyword,
      practitioner,
    }
  );
  return data;
};

const getMedicineById = async (name) => {
  const data = await client.get(`/api/resource/Item/${name}`);
  return data;
};

const editMedicine = async (name, item_name, generic_name) => {
  const data = await client.put(`/api/resource/Item/${name}`, {
    item_name,
    generic_name,
    item_code: item_name,
  });
  return data;
};

const editLab = async (name, lab_test_name, lab_test_code) => {
  const data = await client.put(`/api/resource/Lab Test Template/${name}`, {
    lab_test_name,
    lab_test_code,
  });
  return data;
};

const createMedicine = async (item_name, generic_name) => {
  const data = await client.post(`/api/resource/Item`, {
    item_name,
    generic_name,
    item_code: item_name,
    item_group: "Drug",
    stock_uom: "Nos",
  });
  return data;
};

const createLabTest = async (lab_test_name, lab_test_code) => {
  const data = await client.post(`/api/resource/Lab Test Template`, {
    lab_test_name,
    lab_test_code,
    lab_test_group: "Laboratory",
    department: "General Medicine",
    lab_test_rate: 500,
  });
  return data;
};

const updateConfiguration = async (practitioner, params) => {
  const data = client.put(
    `/api/resource/Cloud Clinic Configuration/${practitioner}`,
    params
  );
  return data;
};

const updateBankDetails = async (
  doctorId,
  bank_name,
  branch,
  ifsc_code,
  account_number
) => {
  const data = await client.put(
    `api/resource/Cloud Clinic Configuration/${doctorId}`,
    {
      bank_name,
      branch,
      ifsc_code,
      account_number,
    }
  );
  return data;
};

const getConfiguration = async () => {
  const data = await client.get(
    `api/resource/Cloud Clinic Configuration?fields=["*"]`
  );
  return data;
};

const getTotalCountSuperAdminKey = async (doctype, filters) => {
  const data = await superadminclient.post(
    `/api/method/frappe.desk.reportview.get_count`,
    {
      doctype,
      filters,
    }
  );
  return data;
};

const getTotalCountByDocType = async (doctype, filters, or_filters = "[]") => {
  const data = await client.post(
    `/api/method/frappe.desk.reportview.get_count`,
    {
      doctype,
      filters,
      or_filters,
    }
  );
  return data;
};

const createConfiguration = async (practitioner, smart_assistant) => {
  const data = await client.post(`/api/resource/Cloud Clinic Configuration`, {
    practitioner,
    otp_patient: false,
    payment: true,
    whatsapp_prescription: false,
    google_meet: false,
    attach_lab_report: true,
    attch_lab_report: true,
    height: true,
    weight: true,
    blood_pressure: true,
    duration_in_symptom: true,
    past_history: false,
    examination: false,
    personal_habit: false,
    lab_test: true,
    scan: false,
    note_for_patient: false,
    note_for_doctor: false,
    prescription_height: false,
    prescription_weight: false,
    prescription_bmi: false,
    prescription_blood_pressure: false,
    prescription_symptom: true,
    prescription_symptom_duration: true,
    prescription_diagnosis: true,
    prescription_past_history: false,
    prescription_examination: false,
    prescription_personal_habit: false,
    prescription_labt_test: true,
    prescription_scan: false,
    prescription_patient_note: false,
    prescription_doctor_note: false,
    smart_assistant,
    patient_address: false,
    patient_email: false,
  });
  return data;
};

const checkQnOpenAIApi = async (input) => {
  const data = await openAIClient.post(`/v1/moderations`, {
    input,
  });
  return data;
};

const askOpenAIApi = async (prompt) => {
  console.log("prompt--", prompt);

  let data;
  data = await openAIClient.post(`/v1/completions`, {
    model: "text-davinci-003",
    prompt,
    max_tokens: 2000,
    temperature: 0.9,
    top_p: 1,
    frequency_penalty: 0.0,
    presence_penalty: 0.6,
    stream: false,
    stop: [" Human:", " AI:"],
  });
  return data;
};

const getPrintView = async (doctype, name, letterhead) => {
  const data = await client.get(`/printview`, {
    doctype,
    name,
    format: "Standard",
    no_letterhead: 0,
    letterhead,
    settings: {},
    _lang: "en",
  });
  return data;
};

const sendWhatAppMessage = async (to, message, media_url) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_whatsapp_message",
    {
      to,
      message,
      media_url,
    }
  );
  return data;
};

const generateSalesInvoicePdf = async (invoice_id) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.generate_sales_invoice",
    {
      invoice_id,
    }
  );
  return data;
};

const prescriptionPdfCreation = async (encounter_id) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.generate_prescription",
    {
      encounter_id,
    }
  );
  return data;
};
const htmlPdfPrescription = async (
  file_data,
  file_name,
  attached_to_name,
  doctype
) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.file_attach_as_pdf",
    {
      file_data,
      file_name,
      attached_to_name,
      doctype,
    }
  );
  return data;
};

const logSmartAssistant = async (question, answer) => {
  const data = await client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.log_for_smart_assistant",
    {
      question,
      answer,
    }
  );
  return data;
};

const getOverLap = async (practitioner) => {
  const data = client.get(
    `/api/resource/Healthcare Service Unit/${practitioner}`
  );
  return data;
};

const updateOverLap = async (practitioner, params) => {
  const data = client.put(
    `/api/resource/Healthcare Service Unit/${practitioner}`,
    {
      allow_appointments: 1,
      overlap_appointments: 1,
      service_unit_capacity: params,
    }
  );
  return data;
};

const getAdminConf = async () => {
  const data = client.get(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_smartassistant_status`
  );
  return data;
};

const getSuperAdminKey = async () => {
  const data = client.post(
    "/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_secret",
    {
      app_id: "myclinicapp",
      key: "cdsO-.FDe#dwxc#g",
    }
  );
  return data;
};

const updateAAppointment = async (name, online_link) => {
  const data = client.put(`/api/resource/Patient Appointment/${name}`, {
    online_link,
  });
  return data;
};

const sendAAppointmentMsg = async (
  mobile_number,
  clinic_name,
  doctor_name,
  date_time
) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.remainder_sms`,
    {
      mobile_number,
      clinic_name,
      doctor_name,
      date_time,
    }
  );
  return data;
};

const sendDemoMail = async (doctor_email) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_demo_email`,
    {
      doctor_email,
    }
  );
  return data;
};

const getTestimony = async () => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.get_testimony`
  );
  return data;
};

const checkDemoRequest = async (value) => {
  const data = client.get(
    `/api/resource/Demo Request?fields=["*"]&or_filters=[["email","=","${value}"], ["mobile_number","=","${value}"]]`
  );
  return data;
};

const postDemoRequest = async (email, mobile_number) => {
  const data = client.post(`/api/resource/Demo Request`, {
    email,
    mobile_number,
    status: "In Progress",
  });
  return data;
};

const sendPushNotification = async (appointment_id) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_online_consultation_notification`,
    {
      appointment_id,
    }
  );
  return data;
};

const sendDemoOtp = async (mobile_number) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.demo_otp`,
    {
      mobile_number,
    }
  );
  return data;
};

const sendDemoEmail = async (doctor_email) => {
  const data = client.post(
    `/api/method/erpnext.healthcare.doctype.healthcare_practitioner.api.send_demo_email_otp`,
    {
      doctor_email,
    }
  );
  return data;
};

const getDocXUserByDoctor = async (doctor_id) => {
  const data = await superadminclient.get(
    `/api/resource/DoctorXUser?fields=["user_id"]&filters=[["doctor_id", "in", "${doctor_id}"]]`
  );
  return data;
};

const getDoctorXUser = async (user_id, doctor_id) => {
  const data = await superadminclient.get(
    `/api/resource/DoctorXUser?fields=["*"]&filters=[["user_id", "=", "${user_id}"], ["doctor_id", "=", "${doctor_id}"]]`
  );
  return data;
};

const assignDoctorXUser = async (user_id, doctor_id) => {
  const data = await superadminclient.post("/api/resource/DoctorXUser", {
    user_id,
    doctor_id,
  });
  return data;
};

const getDoctorByHospital = async (hospital) => {
  const data = await superadminclient.get(
    `/api/resource/Healthcare Practitioner?fields=["name", "practitioner_name"]&filters=[["hospital", "=", "${hospital}"], ["status", "=", "Active"]]`
  );
  return data;
};

const getUserByUser = async (user_id) => {
  const data = await superadminclient.get(
    `/api/resource/DoctorXUser?fields=["*"]&filters=[["user_id", "=", "${user_id}"]]`
  );
  return data;
};

const getUserByDoctor = async (doctor_id) => {
  const data = await superadminclient.get(
    `/api/resource/DoctorXUser?fields=["*"]&filters=[["doctor_id", "=", "${doctor_id}"]]`
  );
  return data;
};

const setCompanyId = async (id, companyid) => {
  const data = superadminclient.put(`/api/resource/Patient/${id}`, {
    companyid,
  });
  return data;
};

export default {
  newPatient,
  patient,
  consultationHistory,
  consultationSummary,
  filterDate,
  filterDateConsultation,
  selectSymptoms,
  selectDiagnosis,
  searchMedicine,
  searchLab,
  patientConsultation,
  preConsultation,
  newAppointment,
  getAppointmentID,
  getPatientAppointment,
  getConsumption,
  getDuration,
  getDosageForm,
  getSlots,
  getScheduledSlots,
  savePatientConsultation,
  getOpenAppointment,
  getAppointment,
  getPatientDetail,
  getPatientRecord,
  getRecord,
  attachRecord,
  getReport,
  homepageDataToday,
  getAllPatient,
  getNewPatient,
  getPatient,
  checkOldPatient,
  getSearchPatient,
  updatePatient,
  rescheduleAppointment,
  cancelAppointment,
  getConsultationName,
  appointmentList,
  mobileOtpPatient,
  getMedicine,
  getLabTest,
  createUser,
  updateUser,
  assignUser,
  createInvoice,
  getAllInvoice,
  getTotalInvoice,
  getInvoiceByName,
  getCompanyInfo,
  getUserList,
  getStaff,
  updateFile,
  checkOldPasswordApi,
  changePasswordApi,
  getRecordByConsultation,
  createPaymentEntry,
  getCompanyAddress,
  addPatientAddress,
  updatePatientAddress,
  getPatientAddress,
  sendMessageToMobile,
  getPatientDetails,
  getSymptoms,
  getDiagnosis,
  createSymptoms,
  selectDepartment,
  createDiagnosis,
  searchSymptoms,
  searchDiagnosis,
  patientDataHomePage,
  createMedicine,
  createLabTest,
  pausedConsultation,
  editPatientConsultation,
  createConfiguration,
  getConfiguration,
  updateConfiguration,
  getPatientById,
  updatePatientRelation,
  askOpenAIApi,
  getInvoiceItems,
  checkQnOpenAIApi,
  getTotalCountByDocType,
  getPrintView,
  sendWhatAppMessage,
  htmlPdfPrescription,
  getPatientHealthRecord,
  logSmartAssistant,
  addVitalSigns,
  submitVitalSigns,
  getVitalSigns,
  getTreatmentTemplate,
  getTreatmentTemplateByName,
  getLabTestByArray,
  addTreatmentTemplate,
  getAllTreatmentTemplate,
  searchTreatmentTemplate,
  editTreatmentTemplate,
  deleteTreatmentTemplate,
  getAppointmentById,
  editVitalSigns,
  getAllConsultationHistory,
  getAllInvoiceWithDate,
  deleteSymptom,
  editSymptom,
  editMedicine,
  deleteMedicine,
  deletediagnosis,
  editDiagnosis,
  deleteLabTest,
  editLab,
  confirmAppointment,
  newCustomer,
  createPatientFromPatient,
  deleteAppointmentById,
  updateOverLap,
  availableSlot,
  getOverLap,
  getSymptomByArray,
  getDiagnosisByArray,
  getMedicineById,
  getLabTemplateById,
  editSymptomOwner,
  editMedicineOwner,
  editLabOwner,
  editDiagnosisOwner,
  editTemplateOwner,
  getAdminConf,
  getSuperAdminKey,
  updateAAppointment,
  getAddressByName,
  sendAAppointmentMsg,
  getInvoiceByAppointment,
  updateInvoice,
  updateInvoiceAppointmentId,
  getInvoiceDetails,
  consultationPrescription,
  getAllUserList,
  generateSalesInvoicePdf,
  updateBankDetails,
  getOnlineInvoice,
  updateInvoiceSettlement,
  prescriptionPdfCreation,
  availableSlotWithLeaveDetails,
  appointmentWithDateRange,
  getPatientByEmail,
  sendDemoMail,
  getTestimony,
  getOnlineRefundInvoice,
  postDemoRequest,
  sendPushNotification,
  sendDemoOtp,
  checkDemoRequest,
  sendDemoEmail,
  homepageDataWeek,
  homepageDataMonth,
  getAllPatientByDate,
  appointmentByDates,
  pausedConsultationWithDates,
  assignDoctorXUser,
  getDoctorByHospital,
  getDoctorXUser,
  getUserByDoctor,
  getUserListByList,
  getPatientByDoctor,
  setCompanyId,
  newAppointmentWithSMS,
  getAppointmentByClinic,
  getTotalCountSuperAdminKey,
  getUserByUser,
  pausedConsultationByCompany,
  appointmentByDatesByCompany,
  pausedConsultationWithDatesByCompany,
  patientDataHomePageByCompany,
  getAllInvoiceWithDateByCompany,
  getTotalInvoiceByCompany,
  getPatientByCompany,
  getAllConsultationHistoryByCompany,
  getAllInvoiceByCompany,
  getDocXUserByDoctor,
  checkPatientByCompany,
};
