import React, { useEffect, useState, useContext } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";
import adminApi from "../api/adminApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import EmptyComponent from "../component/common/EmptyComponent";
import Pagination from "../component/Pagination";
import preFunction from "../component/common/CommonFunction";
import ErrorLog from "../component/common/ErrorLog";

import storage from "../auth/storage";
import EditIcon from "../assests/svg/edit.svg";
import DoctorHeader from "./DoctorHeader";
import DoctorNavBar from "./DoctorNavBar";
import AuthContext from "../auth/context";
import string from "../string";
const prefixerror = "PatientList";

function PatientList() {
  //#region Const
  const location = useLocation();
  const { patientEmailConfig } = useContext(AuthContext);

  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [currentPage, setCurrentPage] = useState(1);
  const [limitStart, setLimitStart] = useState(0);
  const [patient, setPatient] = useState([]);
  const [patientSel, setPatientSel] = useState([]);
  const [searchString, setSearchString] = useState("[]");
  const [role, setRole] = useState("");
  const [dash, setDash] = useState("all");
  const [companyId, setCompanyId] = useState();
  const navigate = useNavigate();
  let sno = limitStart + 1;
  //#endregion

  const handleDeletePatient = async (patient_name, patientId) => {
    setLoad(true);
    console.log("patientId----", patientId);
    const paymentRes = await adminApi.getPaymentEntryByPatient(patientId);

    console.log("paymentRes---", paymentRes);

    for (let i = 0; i < paymentRes.data.data.length; i++) {
      console.log(
        "paymentRes.data.data[i].name---",
        paymentRes.data.data[i].name
      );
      const cancelPaymentRes = await adminApi.cancelDoctype(
        "Payment Entry",
        paymentRes.data.data[i].name.toString()
      );
      console.log("cancelPaymentRes---", cancelPaymentRes);
      if (cancelPaymentRes.ok) {
        const delPaymentRes = await adminApi.deleteFromDoctype(
          "Payment Entry",
          paymentRes.data.data[i].name
        );
        console.log("delPaymentRes---", delPaymentRes);
      }
    }

    const invoiceRes = await adminApi.getDetailsByPatient(
      "Sales Invoice",
      patientId
    );
    console.log("invoiceRes---", invoiceRes);
    for (let i = 0; i < invoiceRes.data.data.length; i++) {
      console.log("id---", invoiceRes.data.data[i].name);
      if (invoiceRes.data.data[i].docstatus == 1) {
        const cancelInvoiceRes = await adminApi.cancelDoctype(
          "Sales Invoice",
          invoiceRes.data.data[i].name
        );
        console.log("cancelInvoiceRes---", cancelInvoiceRes);
        if (cancelInvoiceRes.ok) {
          const delInvoiceRes = await adminApi.deleteFromDoctype(
            "Sales Invoice",
            invoiceRes.data.data[i].name
          );
          console.log("delInvoiceRes---", delInvoiceRes);
        }
      } else {
        const delInvoiceRes = await adminApi.deleteFromDoctype(
          "Sales Invoice",
          invoiceRes.data.data[i].name
        );
        console.log("delInvoiceRes---", delInvoiceRes);
      }
    }

    const consultationRes = await adminApi.getDetailsByPatient(
      "Patient Encounter",
      patientId
    );
    console.log("consultationRes---", consultationRes);

    for (let i = 0; i < consultationRes.data.data.length; i++) {
      console.log("id---", consultationRes.data.data[i].name);
      console.log("docStatus---", consultationRes.data.data[i].docstatus);
      if (consultationRes.data.data[i].docstatus == 1) {
        const cancelConsultationeRes = await adminApi.cancelDoctype(
          "Patient Encounter",
          consultationRes.data.data[i].name
        );
        console.log("cancelConsultationeRes---", cancelConsultationeRes);
        if (cancelConsultationeRes.ok) {
          const delConsultationRes = await adminApi.deleteFromDoctype(
            "Patient Encounter",
            consultationRes.data.data[i].name
          );
          console.log("delConsultationRes---", delConsultationRes);
        }
      } else {
        const delConsultationRes = await adminApi.deleteFromDoctype(
          "Patient Encounter",
          consultationRes.data.data[i].name
        );
        console.log("delConsultationRes---", delConsultationRes);
      }
    }

    const vitalRes = await adminApi.getDetailsByPatient(
      "Vital Signs",
      patientId
    );
    console.log("vitalRes--", vitalRes);

    for (let i = 0; i < vitalRes.data.data.length; i++) {
      console.log("id---", vitalRes.data.data[i].name);
      if (vitalRes.data.data[i].docstatus == 1) {
        const cancelvitalRes = await adminApi.cancelDoctype(
          "Vital Signs",
          vitalRes.data.data[i].name
        );
        console.log("cancelvitalRes---", cancelvitalRes);
        if (cancelvitalRes.ok) {
          const delvitalRes = await adminApi.deleteFromDoctype(
            "Vital Signs",
            vitalRes.data.data[i].name
          );
          console.log("delvitalRes---", delvitalRes);
        }
      } else {
        const delvitalRes = await adminApi.deleteFromDoctype(
          "Vital Signs",
          vitalRes.data.data[i].name
        );
        console.log("delvitalRes---", delvitalRes);
      }
    }
    const appRes = await adminApi.getDetailsByPatient(
      "Patient Appointment",
      patientId
    );
    console.log("appRes---", appRes);

    for (let i = 0; i < appRes.data.data.length; i++) {
      console.log("id---", appRes.data.data[i].name);

      const delappRes = await adminApi.deleteFromDoctype(
        "Patient Appointment",
        appRes.data.data[i].name
      );
      console.log("delappRes---", delappRes);
    }

    const contactRes = await adminApi.getContact(patientId);
    console.log("contactRes---", contactRes);
    if (contactRes.data.data.length > 0) {
      for (let i = 0; i < contactRes.data.data.length; i++) {
        const delcontactRes = await adminApi.deleteFromDoctype(
          "Contact",
          contactRes.data.data[i].name
        );
        console.log("delcontactRes---", delcontactRes);
      }
    }

    const customerRes = await adminApi.getPatientByDoctype(
      "Customer",
      patientId
    );
    console.log("customerRes---", customerRes);
    if (customerRes.data.data.length > 0) {
      const delcustomerRes = await adminApi.deleteFromDoctype(
        "Customer",
        customerRes.data.data[0].name
      );
      console.log("delcustomerRes---", delcustomerRes);
    }
    // const patientRes = await adminApi.getPatientByDoctype("Patient", patientId);
    // console.log("patientRes---", patientRes);

    // if (patientRes.data.data.length > 0) {
    // console.log("id---", patientRes.data.data.name);
    const addressRes = await adminApi.getPatientByDoctype(
      "Address",
      patientId + "-Office"
    );
    console.log("addressRes---", addressRes);
    if (addressRes.data.data.length > 0) {
      for (let i = 0; i < addressRes.data.data.length; i++) {
        const delpatientAddrRes = await adminApi.deleteFromDoctype(
          "Address",
          addressRes.data.data[i].name
        );
        console.log("delpatientAddrRes---", delpatientAddrRes);
      }

      const delpatientRes = await adminApi.deleteFromDoctype(
        "Patient",
        patientId
      );
      console.log("delpatientRes---", delpatientRes);
    } else {
      const delpatientRes = await adminApi.deleteFromDoctype(
        "Patient",
        patientId
      );
      console.log("delpatientRes---", delpatientRes);
    }
    // }
    getPatientList(0, `[["companyid", "=", "${companyId}"]]`);
    setLoad(false);
  };

  const getPatientByDate = async (dash) => {
    try {
      setData([]);
      setTotalCount(0);
      const role = await storage.getRole();
      setRole(role);
      let res;
      var fromDate;
      var toDate;
      if (dash == "day") {
        fromDate = moment().format("yyyy-MM-DD");
        toDate = moment().format("yyyy-MM-DD");
      } else if (dash == "week") {
        fromDate = moment().startOf("week").format("yyyy-MM-DD");
        toDate = moment().endOf("week").format("yyyy-MM-DD");
      } else if (dash == "month") {
        fromDate = moment().startOf("month").format("yyyy-MM-DD");
        toDate = moment().endOf("month").format("yyyy-MM-DD");
      }
      setLoad(true);
      const company = await storage.getCompany();
      console.log("company------", company);
      res = await doctorApi.getAllPatientByDate(company, fromDate, toDate);
      console.log("res---", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + " getPatientList " + "getNewPatient",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "fromDate:" + fromDate + "toDate:" + toDate
        );
        return;
      }

      if (res.data.data.length > 0) {
        setData(res.data.data);
        setTotalCount(res.data.data.length);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " getPatientList ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const getPatientList = async (limitStart, searchString) => {
    try {
      setData([]);
      setTotalCount(0);
      console.log("dash-------", dash);
      setLoad(true);
      const role = await storage.getRole();
      setRole(role);
      const pagesize = await storage.getPageSize();
      setPageSize(pagesize);
      const allRes = await doctorApi.getTotalCountSuperAdminKey(
        "Patient",
        searchString
      );
      console.log("searchString----", searchString);
      console.log("allRes----", allRes);
      if (!allRes.ok) {
        ErrorLog(
          prefixerror + " getPatientList " + "getTotalCountByDocType",
          JSON.stringify(allRes),
          "We are facing some issues.Please try again after sometime.",
          "searchString:" + searchString
        );
        return;
      }
      setTotalCount(allRes.data.message);
      setData([]);
      if (allRes.data.message > 0) {
        console.log("searchString-------", searchString);
        const res = await doctorApi.getAllPatient(
          searchString,
          limitStart,
          pageSize
        );
        if (!res.ok) {
          ErrorLog(
            prefixerror + " getPatientList " + "getAllPatient",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "searchString:" +
              searchString +
              "limitStart:" +
              limitStart +
              "pageSize:" +
              pageSize
          );
          return;
        }
        if (res.data.data.length > 0) {
          setData(res.data.data);
        }
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + " getPatientList ",
        JSON.stringify(error),
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = (patientId, fullName) => {
    navigate("/newappointment", { state: { patientId, fullName } });
  };

  const handleViewRecord = (patientId, fullName) => {
    navigate("/patientrecord", { state: { patientId, fullName } });
  };

  const handleViewPatient = (id) => {
    navigate("/viewpatient", { state: { id } });
  };

  const handlePageChange = (page) => {
    const lmtst = (page - 1) * pageSize;
    setLimitStart(lmtst);
    setCurrentPage(page);
    getPatientList(lmtst, searchString);
  };

  const handleEditPatient = (id) => {
    // console.log("id==", id);
    navigate("/newpatient", { state: { id } });
  };

  const handleSelectPatient = (e) => {
    console.log(e, "e");
    if (e && e.length > 0) {
      for (let i = 0; i < e.length; i++) {
        e[i].patient = e[i].patient.split("-")[0];
      }
      setPatientSel(e);
      getPatientList(
        limitStart,
        `[["patient_name","=", "${
          e[0].patient.split("-")[0]
        }"], ["companyid", "=", "${companyId}"]]`
      );
      setPatient(e);
      document.getElementById("patient_input").blur();
    } else {
      setPatientSel([]);
      getPatientList(limitStart, `[["companyid", "=", "${companyId}"]]`);
      setPatient([]);
    }
  };

  const setCompany = async () => {
    const role = await storage.getRole();
    if (role === string.SUPERADMIN_ROLE) {
      getPatientList(limitStart, ``);
      setSearchString(`[]`);
      getPatientList(limitStart, `[]`);
    } else {
      const cId = await storage.getCompany();
      setCompanyId(cId);
      setSearchString(`[["companyid", "=", "${cId}"]]`);
      getPatientList(limitStart, `[["companyid", "=", "${cId}"]]`);
    }
  };

  const handleSearchPatient = async (value) => {
    try {
      console.log("value---", value);
      // setPatient();
      if (value.length > 2) {
        setLoad(true);
        const res = await doctorApi.getSearchPatient(value);
        if (!res.ok) {
          ErrorLog(
            prefixerror + " handleSearchPatient " + "getSearchPatient",
            JSON.stringify(res),
            "We are facing some issues.Please try again after sometime.",
            "value:" + value
          );
          return;
        }
        console.log("searchRes--", res.data.data);

        let i,
          arr = [];
        if (res.data.data.length > 0) {
          for (i = 0; i < res.data.data.length; i++) {
            var myObject = {
              patient:
                res.data.data[i].patient_name +
                " - " +
                moment().diff(res.data.data[i].dob, "years") +
                " years - " +
                res.data.data[i].mobile,
            };
            arr.push(myObject);
          }
          setPatient(arr);
          setLoad(false);
        }
        // } else {
        // setSearchString("[]");
        // getPatientList(limitStart, "[]");
        // setPatient([]);
      }
    } catch (ex) {
      console.log(ex);
      setLoad(false);
    }
  };

  useEffect(() => {
    if (location.state && location.state.dash && location.state.dash != "all") {
      getPatientByDate(location.state.dash);
      setDash(location.state.dash);
    } else {
      setCompany();
    }
    document.getElementById("patient_input") &&
      document.getElementById("patient_input").focus();
  }, []);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Patient</h1>
                </div>
                <div className="row">
                  <div className="form-group col-lg-4">
                    <Multiselect
                      className="me-3"
                      name="patient"
                      id="patient"
                      options={patient}
                      placeholder={"Patient Name"}
                      avoidHighlightFirstOption={true}
                      showArrow={true}
                      selectedValues={patientSel}
                      onSelect={handleSelectPatient}
                      displayValue="patient"
                      customCloseIcon={" "}
                      hidePlaceholder={true}
                      selectionLimit={1}
                      customArrow={
                        <span
                          className="icofont-search"
                          style={{
                            fontSize: "15px",
                          }}
                        ></span>
                      }
                      onSearch={(e) => {
                        handleSearchPatient(
                          document.getElementById("patient_input").value
                        );
                        handleSelectPatient("");
                      }}
                      emptyRecordMsg={
                        <div className="notFoundSearch">Type to search</div>
                      }
                    />
                  </div>
                  <div className="col-lg-7"></div>
                  <div className="form-group col-lg-1 text-right">
                    <button
                      title="Add Patient"
                      className="btn-2"
                      onClick={(e) => {
                        navigate("/newpatient");
                      }}
                    >
                      <span className="icofont icofont-plus"></span>
                      Add Patient
                    </button>
                  </div>
                </div>
                <div className="row no-gutters">
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "all" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getPatientList(0, `[["companyid", "=", "${companyId}"]]`);
                      setDash("all");
                    }}
                  >
                    <span className="dashhead">All</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "day" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getPatientByDate("day");
                      setDash("day");
                    }}
                  >
                    <span className="dashhead">Today</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "week" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getPatientByDate("week");
                      setDash("week");
                    }}
                  >
                    <span className="dashhead">This Week</span>
                  </div>
                  <div
                    className="col-lg-2 pointer border p-2 text-center"
                    style={{
                      backgroundColor: dash == "month" ? "#FAA835" : "#fff",
                    }}
                    onClick={() => {
                      getPatientByDate("month");
                      setDash("month");
                    }}
                  >
                    <span className="dashhead">This Month</span>
                  </div>
                </div>
                <div className="row border pe-3 ps-3">
                  <div className="row totcntstyle">
                    A total of {totalCount} patient identified.
                  </div>
                  {data.length > 0 ? (
                    <>
                      <div className="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th width="1%">No</th>
                              <th width="15%">Name</th>
                              <th width="5%">Age</th>
                              <th width="10%">Mobile</th>
                              {patientEmailConfig ? <th>Email Address</th> : ""}
                              {role != "Billing" && (
                                <th width="15%">Book Appointment</th>
                              )}
                              <th width="15%">View Health Record</th>
                              <th width="5%">View</th>
                              <th width="5%">Update</th>
                            </tr>
                          </thead>
                          <tbody>
                            {data.map((item) => (
                              <tr key={item.name}>
                                <td>{sno++}</td>
                                <td className="nowrapWhiteSpace">
                                  {item.patient_name}
                                </td>
                                <td>{moment().diff(item.dob, "years")}</td>
                                <td>{item.mobile}</td>
                                {patientEmailConfig ? (
                                  <td>{item.email}</td>
                                ) : (
                                  ""
                                )}
                                {role != "Billing" && (
                                  <td>
                                    <button
                                      type="submit"
                                      className="btn"
                                      title="Book&nbsp;Appointment"
                                      onClick={() =>
                                        handleClick(
                                          item.name,
                                          item.patient_name
                                        )
                                      }
                                    >
                                      <span className="icofont icofont-plus"></span>
                                      Book&nbsp;Appointment
                                    </button>
                                  </td>
                                )}
                                <td>
                                  <button
                                    type="submit"
                                    className="btn-3"
                                    title="View&nbsp;Health&nbsp;Record"
                                    onClick={() =>
                                      handleViewRecord(
                                        item.name,
                                        item.patient_name +
                                          " - " +
                                          moment().diff(item.dob, "years") +
                                          " years - " +
                                          item.mobile
                                      )
                                    }
                                  >
                                    <span className="icofont-prescription"></span>
                                    View Health Record
                                  </button>
                                </td>
                                <td className="nowrapWhiteSpace">
                                  <button
                                    type="submit"
                                    className="btn-3"
                                    onClick={() => handleViewPatient(item.name)}
                                  >
                                    <span className="icofont-prescription"></span>
                                    View Patient
                                  </button>
                                </td>
                                <td className="App pointer">
                                  <img
                                    src={EditIcon}
                                    alt={string.websiteName + " Patients"}
                                    onClick={() => handleEditPatient(item.name)}
                                  />
                                </td>
                                {/* <td className="App pointer">
                                  <a
                                    onClick={() =>
                                      handleDeletePatient(
                                        item.patient_name,
                                        item.name
                                      )
                                    }
                                  >
                                    Delete
                                  </a>
                                </td> */}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      {totalCount > pageSize && (
                        <div className="text-center">
                          <table
                            style={{
                              borderBottom: 0,
                              backgroundColor: "#f6f4f4",
                            }}
                          >
                            <tbody>
                              <tr>
                                <td className="pt-4">
                                  <Pagination
                                    itemsCount={totalCount}
                                    pageSize={pageSize}
                                    currentPage={currentPage}
                                    onPageChange={(page) =>
                                      handlePageChange(page)
                                    }
                                    style={{
                                      cursor: "pointer",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default PatientList;
