import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Multiselect } from "multiselect-react-dropdown";

import doctorApi from "../api/doctorApi";

import CustomActivityIndicator from "../component/common/CustomActivityIndicator";
import preFunction from "../component/common/CommonFunction";
import { months } from "../component/common/CommonArray";
import ErrorLog from "../component/common/ErrorLog";

import DoctorNavBar from "./DoctorNavBar";
import DoctorHeader from "./DoctorHeader";
import "react-datepicker/dist/react-datepicker.css";
import storage from "../auth/storage";
const prefixerror = "InvoiceReport";

function OnlineInvoiceReport() {
  //#region const
  const [data, setData] = useState([]);
  const [load, setLoad] = useState(false);
  const todayDate = new Date();
  const [fromDate, setFromDate] = useState(todayDate);
  const [toDate, setToDate] = useState(todayDate);
  const navigate = useNavigate();
  let total = 0;
  const date = new Date();
  const years = preFunction.range(2022, date.getFullYear(), 1);
  const DateCustomInput = preFunction.DateCustomInput;
  //#endregion

  const getInvoiceList = async () => {
    try {
      const email = await storage.getID();
      const from = moment(fromDate).format("yyyy-MM-DD");
      const to = moment(toDate).format("yyyy-MM-DD");
      setData([]);
      setLoad(true);
      console.log("Dates---", from, to);
      console.log("email---", email);
      const res = await doctorApi.getOnlineInvoice(from, to, email);
      console.log("res--", res);
      if (!res.ok) {
        ErrorLog(
          prefixerror + "getInvoiceList " + "getAllInvoiceWithDate",
          JSON.stringify(res),
          "We are facing some issues.Please try again after sometime.",
          "from:" + from + "to" + to
        );
        return;
      }
      if (res.data.message.length > 0) {
        setData(res.data.message);
      }
      setLoad(false);
    } catch (error) {
      setLoad(false);
      console.log(error);
      ErrorLog(
        prefixerror + "getInvoiceList ",
        error,
        "We are facing some issues.Please try again after sometime."
      );
      return;
    }
  };

  const handleClick = (invoiceId) => {
    navigate("/viewInvoice", { state: { invoiceId } });
  };

  useEffect(() => {
    getInvoiceList();
  }, [fromDate, toDate]);

  return (
    <>
      <CustomActivityIndicator
        style={{
          height: 100,
          alignSelf: "center",
        }}
        visible={load}
      />
      <section>
        <div className="row">
          <div className="col-lg-2 p-0">
            <DoctorNavBar id="DoctorNavBar" />
          </div>

          <div className="col-lg-10">
            <DoctorHeader />
            <div className="row" onClick={preFunction.hideNavbar}>
              <div className="pd mb-10">
                <div className="row">
                  <h1>Online Payment</h1>
                </div>
                <div className="col-sm-10">
                  <div className="row form-group">
                    <div className="col-lg-3 p-0 mt-2">
                      <div className="row">
                        <label
                          className="col-lg-1 mt-2 pe-0"
                          style={{ width: "65px" }}
                        >
                          <span className="star">*</span> From :
                        </label>
                        <div
                          className="col-lg-2 p-0"
                          style={{ width: "154px" }}
                        >
                          <DatePicker
                            name="fromDate"
                            id="fromDate"
                            openToDate={fromDate}
                            selected={fromDate}
                            onChange={(date) => {
                              setFromDate(date);
                            }}
                            minDate={new Date("2022-01-01")}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 p-0 mt-2">
                      <div className="row">
                        <label
                          className="col-lg-1 pe-0 mt-2"
                          style={{ width: "48px" }}
                        >
                          <span className="star">*</span> To :
                        </label>
                        <div
                          className="col-lg-2 p-0"
                          style={{ width: "170px" }}
                        >
                          <DatePicker
                            name="toDate"
                            id="toDate"
                            openToDate={toDate}
                            selected={toDate}
                            onChange={(date) => {
                              setToDate(date);
                            }}
                            minDate={fromDate}
                            maxDate={new Date()}
                            dateFormat="dd-MM-yyyy"
                            customInput={<DateCustomInput />}
                            className="form-control datepicker"
                            renderCustomHeader={({
                              date,
                              changeYear,
                              changeMonth,
                            }) => (
                              <div
                                style={{
                                  margin: 10,
                                  display: "flex",
                                  justifyContent: "center",
                                }}
                              >
                                <select
                                  style={{ marginRight: "3px" }}
                                  value={date.getFullYear()}
                                  onChange={({ target: { value } }) =>
                                    changeYear(value)
                                  }
                                >
                                  {years.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                <select
                                  value={months[date.getMonth()]}
                                  onChange={({ target: { value } }) =>
                                    changeMonth(months.indexOf(value))
                                  }
                                >
                                  {months.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive mt-1">
                    {data.length > 0 ? (
                      <table className="table">
                        <thead>
                          <tr>
                            <th width="1%">NO</th>
                            <th width="20%">Invoice No</th>
                            <th>Patient</th>
                            <th width="10%">Date</th>
                            <th width="10%">Status</th>
                            <th width="5%">Amount (₹)</th>
                            <th width="10%">Settlement Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.map((item, index) => {
                            total += item.grand_total;
                            return (
                              <tr key={item.name}>
                                <td>{index + 1}</td>
                                <td className="nowrapWhiteSpace">
                                  <a
                                    className="pointer"
                                    onClick={() => handleClick(item.name)}
                                  >
                                    {item.name}
                                  </a>
                                </td>
                                <td className="nowrapWhiteSpace">
                                  {item.patient_name}
                                </td>
                                <td className="nowrapWhiteSpace">
                                  {moment(item.posting_date).format(
                                    "DD-MM-yyyy"
                                  )}
                                </td>
                                <td>{item.status}</td>
                                <td align="right">{item.grand_total}</td>
                                <td>
                                  {item.settled
                                    ? moment(item.settlement_date).format(
                                        "DD-MM-yyyy"
                                      )
                                    : "Pending"}
                                </td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan="5" className="alignRight">
                              Total
                            </td>
                            <td align="right">{total}</td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    ) : (
                      <label className="ps-1">No payments on these dates</label>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default OnlineInvoiceReport;
